import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	h4: {
		width: '100%',
		fontSize: 18,
		fontWeight: 600,
		lineHeight: 1.25,
		letterSpacing: -0.08,
		marginBottom: theme.spacing(6),
		color: theme.colors.neutral.C000,
	},
}));

export default ({ children, ...props }) => <Typography classes={useStyles()} variant="h4" {...props}>{children}</Typography>;
