import React from 'react';
import { makeStyles } from '@material-ui/core';
import Image from './Image';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(16),
		marginLeft: theme.spacing(6.5),
		marginRight: theme.spacing(6.5),
		position: 'relative',
		zIndex: 3,
	},
}));

export default () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Image picture={1} />
			<Image picture={2} />
			<Image picture={3} />
		</div>
	);
};
