import Form from 'rds/components/Layouts/Form';
import React from 'react';
import { Link } from 'react-router-dom';
import useRdsTheme from 'rds/theme/useRdsTheme';
import SubmitButton from '../components/SubmitButton/SubmitButton';
import PageLayout from '../components/layout/PageLayout';

export default () => {
	const theme = useRdsTheme('dark');

	return (
		<PageLayout
			theme={theme}
			title="Password has been reset"
		>
			<Form>
				<Link to="/landing">
					<SubmitButton>
						OK
					</SubmitButton>
				</Link>
			</Form>

		</PageLayout>
	);
};
