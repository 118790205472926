import React from 'react';
import { useSelector } from 'react-redux';
import { getAlbumsOpen, getEnabled } from 'app/slices/content-creation/create/device-library/state';
import {
	getErrors, getMedia, // getLoading,
} from 'app/slices/content-creation/create/device-library/media';
// import LoadingSkeletonPage from 'rds/components/Skeletons/SecondaryPage';
import Error from './Error';
import NoMedia from './NoMedia';
import PermissionDeniedContent from './PermissionDeniedContent';
import MediaGrid from './Grid/MediaGrid';
import Albums from './Albums';

export default () => {
	const enabled = useSelector(getEnabled);
	const errors = useSelector(getErrors);
	const media = useSelector(getMedia);
	// const loading = useSelector(getLoading);
	const albumsOpen = useSelector(getAlbumsOpen);

	if (errors) {
		return <Error />;
	}

	if (!enabled) {
		return <PermissionDeniedContent />;
	}

	// if (!media?.length && loading) {
	// 	return <LoadingSkeletonPage />;
	// }

	if (albumsOpen) {
		return <Albums />;
	}

	if (!media?.length) {
		return <NoMedia />;
	}

	return (
		<MediaGrid />
	);
};
