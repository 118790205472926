import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { control } from 'app/slices/destinations-search/form';
import Result from './Result';

export default ({ onSelect }) => {
	const dispatch = useDispatch();
	const fields = control.get(dispatch);
	const search = useSelector(fields.search.selector);

	return (
		<div>
			<Result
				title={search}
				subtitle="Custom Destination"
				onMouseDown={() => {
					onSelect({ title: search }, true);
				}}
			/>
		</div>
	);
};
