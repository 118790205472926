/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from 'rds/components/TextField';
import { makeStyles } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import { control, resetForm } from 'app/slices/search-explore/form.js';
import {
	requestSearchUsers,
	requestSearchDestinations,
	setLoading,
	getLoading,
} from 'app/slices/search-explore/search';
import {
	setTab,
} from 'app/slices/search-explore/layout';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import clsx from 'clsx';
import { useQueryParam } from 'app/hooks/useQueryParams';
import { useNavbarState } from 'app/hooks/useNavbarState';
import { useNavigate } from 'react-router-dom';

const DEBOUNCE_SEARCH = 250;
const TIME_OUT = 300;

const useStyles = makeStyles((theme) => ({
	fixedContainer: {
		position: 'absolute',
		left: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		right: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		top: theme.spacing(14),
		zIndex: 1,
	},
	inActive: {
		opacity: 0.3,
	},
	rootSecondary: {
		top: theme.spacing(26),
	},
	textfield: {
		'&:focus': {
			border: `1px solid ${theme.colors.neutral.C200}`,
		},
	},
}));

export default ({
	searchPage,
	setIsSearching,
	isFilerModalOpen,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const searchRef = useRef();
	const fields = control.get(dispatch);
	const search = useSelector(fields.search.selector);
	const searchErr = useSelector(fields.search.err);
	const [focus, setFocus] = useState(false);
	const show = useSelector(getIsUpdatedVersionAvailable);
	const headerClass = show ? clsx(classes.fixedContainer, classes.rootSecondary) : clsx(classes.fixedContainer);
	const loading = useSelector(getLoading);
	const isItViaShared = useQueryParam('shared');
	const navbarState = useNavbarState();
	const navigate = useNavigate();
	const inputRef = useRef();

	useEffect(() => {
		if (!searchPage && inputRef && inputRef.current) {
			inputRef?.current.blur();
		}
	}, [searchPage, inputRef]);

	useEffect(() => {
		let timer;
		if (searchPage && inputRef && inputRef.current) {
			timer = setTimeout(() => {
				inputRef?.current.focus();
				navbarState.close('high-priority');
				setFocus(true);
			}, TIME_OUT);
			return () => clearTimeout(timer);
		}
	}, [searchPage, inputRef, inputRef.current]);

	useEffect(() => {
		dispatch(setLoading(false));
	}, []);

	useEffect(() => {
		let promiseOne;
		let promiseTwo;

		if (searchRef) {
			clearTimeout(searchRef.current);

			if (!search) {
				dispatch(setLoading(false));
			} else {
				if (!loading) {
					dispatch(setLoading(true));
				}
				searchRef.current = setTimeout(async () => {
					if (search.startsWith('+')) {
						promiseOne = dispatch(requestSearchDestinations());
					} else if (search.startsWith('@')) {
						promiseTwo = dispatch(requestSearchUsers());
					} else {
						promiseOne = dispatch(requestSearchDestinations());
						promiseTwo = dispatch(requestSearchUsers());
					}

					await promiseOne;
					await promiseTwo;

					setLoading(false);
				}, DEBOUNCE_SEARCH);
			}
		}

		return () => {
			clearTimeout(searchRef.current);
			promiseOne?.abort();
			promiseTwo?.abort();
		};
	}, [search, searchRef]);

	if (isItViaShared === 'true') {
		return null;
	}

	const style = {};

	if (isFilerModalOpen) {
		style.opacity = 0.3;
	}

	return (
		<div
			className={headerClass}
			onClick={(e) => {
				e.preventDefault();
				dispatch(setIsSearching(true));
				if (!searchPage) {
					navigate('/search');
				}
			}}
			style={style}
		>
			<TextField
				value={search}
				err={searchErr}
				forwardRef={searchRef}
				inputRef={inputRef}
				searchField
				variant="glass"
				classes={[classes.textfield, 'hide-on-slide']}
				searchFieldFocused={focus}
				poweredByGoogle="dark"
				onChange={fields.search.change}
				onFocusCb={() => {
					if (!searchPage) {
						inputRef.current.blur();
					}
				}}
				onBlurCb={() => {
					setFocus(false);
					if (searchPage) {
						navbarState.open('high-priority', 350);
					}
				}}
				onKeyDown={(e) => {
					if (e.keyCode === 13) {
						setFocus(false);
						e.target.blur();
					}
				}}
				icon={{
					icon: 'Search',
					size: 20,
				}}
				right={(
					<div onMouseDown={(e) => { e.preventDefault(); }}>
						{
							focus
								? (
									<div
										onClick={() => {
											dispatch(resetForm());
											dispatch(setTab(0));
										}}
									>
										<IconV3
											icon="XCircleFilledLayers"
											size={20}
											containerSize={22}
											gredient
										/>
									</div>
								)
								: null
						}
					</div>
				)}
				requesting={loading}
				placeholder="Search @username or +Destination"
			/>
		</div>
	);
};
