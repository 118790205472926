import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	change,
	requestBlockUser,
	getMyFollowing,
	getTheirProfileUuid,
	getMyProfileUuid,
	getTheirFollowing,
} from 'app/slices/user-account-v2/accounts';
import { requestToggleFollow } from 'app/slices/user-account-v2/following-actions';
import BlockMenu from './BlockMenu';

export default ({ isMe, open }) => {
	const dispatch = useDispatch();
	const uuid = useSelector(isMe ? getMyProfileUuid : getTheirProfileUuid);
	const following = useSelector(isMe ? getMyFollowing : getTheirFollowing);

	return (
		<BlockMenu
			title="Block"
			subtitle="Once blocked, they won’t be able to
    engage with you or be notified that you blocked them."
			label="Block"
			buttonColor="error"
			buttonVariant="contained"
			onClick={() => {
				if (following) {
					dispatch(requestToggleFollow({ uuid, type: 'unfollow' }));
				}
				dispatch(requestBlockUser(uuid));
				dispatch(change({
					menu: null,
				}));
			}}
			open={open}
		/>
	);
};
