/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	requestChangeName,
	requestChangeBio,
	requestChangeAvatar,
} from 'app/slices/user-account-v2/accounts';
import handleSliceError from 'utils/handle-slice-error';

export const requestEditProfile = createAsyncThunk(
	'requestEditProfile',
	async (formData, { dispatch, rejectWithValue, getState }) => {
		const { bio, displayName, avatarFormData } = getState().editProfile;

		try {
			dispatch(requestChangeName(displayName));
			dispatch(requestChangeBio(bio));
			if (avatarFormData) {
				await dispatch(requestChangeAvatar(avatarFormData));
			}
			return null;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);
