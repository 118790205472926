import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Transition } from 'react-transition-group';

const TIME_OUT = 300;
const useStyles = makeStyles((theme) => ({
	ine: {
		position: 'fixed',
		zIndex: 60,
		bottom: 0,
		left: 0,
		right: 0,
		top: 0,
		transition: `transform ${TIME_OUT}ms ease-in-out`,
		backgroundColor: theme.palette.background.page,
		transformOrigin: 'center',
	},
	entering: {
		transform: 'translateY(100%)',
	},
	entered: {
		transform: 'translateY(0)',
	},
	exiting: {
		transform: 'translateY(100%)',
	},
	exited: {
		transform: 'translateY(100%)',
	},
}));

export default ({
	open,
	children,
	...props
}) => {
	const classes = useStyles();

	return (
		<Transition in={open} timeout={TIME_OUT} mountOnEnter unmountOnExit>
			{(state) => (
				<div className={`${classes.ine} ${ classes[state]}`} {...props}>
					{children}
				</div>
			)}
		</Transition>
	);
};
