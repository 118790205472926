import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';

export const followMultiDestinations = createAsyncThunk(
	'requestFollowMulti',
	async (destinations, { rejectWithValue }) => {
		try {
			const response = await api.post('destinations/multi-follow', {
				follow: destinations,
			});

			return response.data;
		} catch (error) {
			const errors = getErrorsFromRejectedRequest(error);
			return rejectWithValue(errors);
		}
	},
);

export const onboardingFollowSlice = createSlice({
	name: 'follow',
	initialState: {
		destinations: [],
		multiDestinations: [],
		errors: null,
		loading: false,
	},
	reducers: {
		follow: (state, action) => {
			const destination = { ...action.payload };
			const d = state.destinations.slice();
			d.push(destination);
			state.destinations = d;
			const id = action.payload.uid;
			const ds = state.multiDestinations.slice();
			ds.push(id);
			state.multiDestinations = ds;
		},
		unfollow: (state, action) => {
			const destination = { ...action.payload };
			const d = state.destinations.slice();
			d.splice(d.findIndex((i) => i.uid === destination.uid), 1);
			state.destinations = d;
			const id = action.payload.uid;
			const ds = state.multiDestinations.slice();
			ds.splice(ds.findIndex((i) => i === id), 1);
			state.multiDestinations = ds;
		},
	},
	extraReducers: {
		[followMultiDestinations.fulfilled]: (state) => {
			state.loading = false;
			state.errors = null;
		},
		[followMultiDestinations.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = onboardingFollowSlice;
const { follow, unfollow } = actions;
export { follow, unfollow };
export const getSlice = (state) => state[name];
export const getDestinations = createSelector(getSlice, (slice) => slice?.destinations);
export const getMultiDestinations = createSelector(getSlice, (slice) => slice?.multiDestinations);
injectReducer(name, reducer);
