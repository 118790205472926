import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

function useScrollState(on, pathname, debounceDelay = 50) {
	const [scrollYMap, setScrollYMap] = useState({});

	useEffect(() => {
		const pageDiv = document.querySelector('.page-root');
		let handleScroll;

		if (on) {
			if (pageDiv) {
				handleScroll = debounce(() => {
					const next = { ...scrollYMap };
					next[pathname] = pageDiv ? pageDiv.scrollTop : 0;
					setScrollYMap(next);
				}, debounceDelay);

				pageDiv.addEventListener('scroll', handleScroll);
			}
		}

		// Cleanup function to remove the event listener
		return () => {
			pageDiv?.removeEventListener('scroll', handleScroll);
		};
	}, [on, debounceDelay, pathname, scrollYMap]);

	return {
		scrollY: scrollYMap[pathname] || 0,
		setScrollTop: (top) => {
			const pageDiv = document.querySelector('.page-root');
			if (on && pageDiv) {
				pageDiv.parentElement.style.minHeight = `${top + window.innerHeight}px`;
				setTimeout(() => {
					pageDiv.scrollTop = top;
				}, 1);
			}
			if (!on && pageDiv) {
				pageDiv.style.removeProperty('min-height');
			}
		},
	};
}

export default useScrollState;
