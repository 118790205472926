import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectExperienceDestination,
	setExperienceDestination,
} from 'app/slices/content-creation/experience';
import SearchModal from 'routes/DestinationSearchModal/Search/SearchModal';
import { getModal } from 'app/slices/destinations-search/layout';
import SliderModal from 'rds/components/SliderModal';

export default ({ path }) => {
	const dispatch = useDispatch();
	const destination = useSelector(selectExperienceDestination);
	const open = useSelector(getModal);

	if (path !== 'details') {
		return null;
	}

	const handleSelect = (d) => {
		dispatch(setExperienceDestination(d));
	};

	return (
		<SliderModal open={open}>
			<SearchModal
				onSelect={handleSelect}
				selectedDestination={destination}
				onRemoveSelected={() => { dispatch(setExperienceDestination(null)); }}
				showSelected
			/>
		</SliderModal>
	);
};
