import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import { fetchAlbums } from 'app/android-service';
import { requestMedia } from './media';

const initialState = {
	loading: false,
	errors: null,
	selected: null,
	albums: undefined,
};

export const requestAlbums = createAsyncThunk(
	'requestAlbums',
	async (_, { dispatch, getState, rejectWithValue }) => {
		try {
			const state = getState();
			const { selected } = state.deviceLibraryAlbums;

			const albums = await fetchAlbums();

			if (!selected) {
				dispatch(selectAlbum(albums[0]));
			}

			return albums;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const selectAlbum = createAsyncThunk(
	'selectAlbum',
	async (album, { dispatch, rejectWithValue }) => {
		try {
			return await dispatch(requestMedia(album));
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const dlSlice = createSlice({
	name: 'deviceLibraryAlbums',
	initialState: { ...initialState },
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
	},
	extraReducers: {
		[requestAlbums.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestAlbums.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestAlbums.fulfilled]: (state, action) => {
			state.loading = false;
			state.errors = null;
			state.albums = action.payload;
		},
		[selectAlbum.pending]: (state, action) => {
			state.selected = action.meta.arg;
		},
	},
});

const { name, reducer, actions } = dlSlice;
const { reset } = actions;
export { reset };

export const getSlice = (state) => state[name];
export const getSelected = createSelector(getSlice, (slice) => slice?.selected);
export const getAlbums = createSelector(getSlice, (slice) => slice?.albums);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);

injectReducer(name, reducer);
