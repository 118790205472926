/* eslint-disable jsx-a11y/media-has-caption */
import React, {
	useEffect,
	useCallback,
	useRef,
	useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import ErrorPane from 'features/ExperienceModal/ErrorPane';
import getTouchState from 'lib/touch-tracker';
import emitter from '../../emitter';

const useStyles = makeStyles({
	view: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		touchAction: 'none',
	},
});

const FPS = 33;

export default ({
	active,
	activeClip,
	activeExperience,
	play,
	src,
	poster,
	preload,
	time = 0,
	onEnd,
	playCount,
	onTimeUpdate,
	onLoadedMetadata,
	onError,
}) => {
	const classes = useStyles();
	const ref = useRef();
	const [error, setError] = useState(null);
	const retries = [0, 300, 900, 5000];
	const [retryCount, setRetryCount] = useState(0);
	let timeout;

	const loadCallback = useCallback(() => {
		if (!ref.current) {
			return;
		}
		ref.current.currentTime = time ? time / 1000 : 0;

		emitter.emit('active', {
			height: ref.current.videoHeight,
			width: ref.current.videoWidth,
		});
		onLoadedMetadata && onLoadedMetadata();
	}, [ref, active, time]);

	const reloadVideo = () => {
		ref?.current?.load();
	};

	useEffect(() => {
		if (active && activeClip) {
			emitter.addListener('reloadClip', reloadVideo);
		}
		return () => {
			emitter.removeListener('reloadClip', reloadVideo);
		};
	}, [active, activeClip]);

	useEffect(() => {
		let t;

		const destroy = () => {
			clearInterval(t);
			clearTimeout(timeout);
			if (ref?.current) {
				if (!activeClip) {
					ref.current.currentTime = 0;
					ref.current.src = '';
					ref.current.load();
				}

				ref.current.ontimeupdate = null;
				ref.current.onend = null;
			}
		};

		if (!ref?.current) {
			return destroy;
		}

		const isActiveAndPlaying = () => {
			if (ref.current.PAUSED) {
				return;
			}

			if (getTouchState()) {
				emitter.emit('pause');
			}

			if (src !== ref.current.src) {
				ref.current.src = src;
			}
			const promise = ref.current.play();

			if (promise) {
				promise.catch(() => {
					if (ref?.current) {
						// IDEAL IF WE SHOW A PLAY AUDIO INDICATOR
						ref.current.muted = true;
						if (ref.current.PAUSED) {
							return;
						}
						if (src !== ref.current.src) {
							ref.current.src = src;
						}
						ref.current.play();
					}
				});
			}
		};

		const isInactive = () => {
			ref.current.currentTime = 0;
			ref.current.pause();
		};

		ref.current.onplay = () => {
			emitter.emit('play');
			t = setInterval(() => {
				if (ref?.current) {
					onTimeUpdate && onTimeUpdate(ref.current.currentTime * 1000, ref.current.duration);
				} else {
					clearInterval(t);
				}
			}, FPS);
		};

		ref.current.onpause = () => {
			clearInterval(t);
		};

		ref.current.ontimeupdate = () => {
			if (getTouchState()) {
				emitter.emit('pause');
			}
			if (ref?.current) {
				onTimeUpdate && onTimeUpdate(ref.current.currentTime * 1000, ref.current.duration);
			}
		};

		ref.current.onended = () => {
			onEnd && onEnd();
		};

		if (active) {
			emitter.emit('active', {
				height: ref.current.videoHeight,
				width: ref.current.videoWidth,
			});
		}

		if (!play) {
			ref.current.PAUSED = true;
			ref.current.pause();
		} else {
			ref.current.PAUSED = false;
		}

		if (active) {
			if (play) {
				if (ref.current.playCount && playCount !== ref.current.playCount) {
					ref.current.currentTime = 0;
				}
				isActiveAndPlaying();
				ref.current.playCount = playCount;
			}
		} else if (!activeClip) {
			isInactive();
		}

		return destroy;
	}, [ref, src, active, activeClip, play, playCount]);

	const handleVideoError = (e) => {
		if (e.target.error.code === 4) {
			return;
		}

		if (retryCount < retries.length - 1) {
			setRetryCount(retryCount + 1);
			timeout = setTimeout(() => {
				if (ref?.current) {
					ref.current.load();
				}
			}, retries[retryCount + 1]);
		} else {
			setError([{ text: 'Something went wrong, Refresh the page' }]);
			onError();
		}
	};

	return (
		<>
			{!error && (activeExperience || activeClip)
				&& (
					<video
						style={{ opacity: activeClip ? 1 : 0 }}
						src={src}
						playsInline
						preload={preload ? 'metadata' : 'none'}
						className={classes.view}
						poster={poster}
						onLoadedMetadata={loadCallback}
						onError={handleVideoError}
						ref={ref}
						type="video/mp4"
					/>
				)}
			{active && error
				&& <ErrorPane errors={error} />}
		</>
	);
};
