/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import localStore from 'store';
import webpushEvents, { disableNotifications, enableNotifications } from 'features/webpush-notifications';
import store, { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';

const getPrefs = () => localStore.get('notifications') || { on: true };
const prefs = getPrefs();

if (prefs.version !== 'v4') {
	prefs.on = true;
	prefs.version = 'v4';
}

export const togglePushNotifications = createAsyncThunk(
	'togglePushNotifications',
	async (inOn, { rejectWithValue, getState }) => {
		const state = getState();
		const { uuid } = state.session.session;

		const throwError = typeof inOn === 'undefined';

		const on = typeof inOn === 'undefined'
			? state.accountSettingsPushNotifications.on
			: !inOn;

		if (on) {
			prefs.on = false;
			localStore.set('notifications', prefs);
			await disableNotifications(uuid, prefs.targetId);
			return null;
		}

		try {
			prefs.targetId = await enableNotifications(uuid);
			prefs.on = true;
			localStore.set('notifications', prefs);
			return prefs.targetId;
		} catch (err) {
			if (throwError) {
				if (confirm('Please enable notifications for this app. Press OK to go to settings.')) {
					window.location.href = 'app-settings:com.roamrhino.dev.app.twa';
				}
				return rejectWithValue(handleSliceError(err));
			}
			return null;
		}
	},
);

export const initPushNotifications = createAsyncThunk(
	'initPushNotifications',
	async (_, { getState, dispatch }) => {
		const { on } = getState().accountSettingsPushNotifications;
		try {
			await dispatch(togglePushNotifications(on));
			return true;
		} catch (err) {
			return true;
		}
	},
);

const initialState = {
	inited: false,
	initComplete: false,
	on: prefs.on,
	targetId: prefs.targetId,
	loading: false,
	errors: null,
};

const accountSettingsPushNotificationsSlice = createSlice({
	name: 'accountSettingsPushNotifications',
	initialState: { ...initialState },
	reducers: {
		clearPush: (state) => {
			state.on = false;
			state.targetId = null;
			prefs.on = false;
			prefs.targetId = null;
			disableNotifications();
			localStore.set('notifications', prefs);
		},
		toggle: (state, action) => {
			state.on = action.payload;
			prefs.on = state.on;
			localStore.set('notifications', prefs);
		},
		clearTargetId: (state) => {
			state.targetId = null;
			prefs.targetId = null;
			localStore.set('notifications', prefs);
		},
	},
	extraReducers: {
		[initPushNotifications.pending]: (state) => {
			state.inited = true;
		},

		[initPushNotifications.fulfilled]: (state) => {
			state.initComplete = true;
		},

		[togglePushNotifications.fulfilled]: (state, action) => {
			if (!action.payload) {
				state.on = false;
				return;
			}

			state.on = true;
			state.targetId = action.payload;
		},
	},
});

const { name, reducer, actions } = accountSettingsPushNotificationsSlice;
const { clearPush, toggle, clearTargetId } = actions;
export {
	name, clearPush, toggle, clearTargetId,
};

webpushEvents.on('revoke', () => {
	store.dispatch(toggle(false));
});

export const getSlice = (state) => state[name];
export const getPushNotificationsOn = createSelector(getSlice, (slice) => slice?.on);
export const getPushNotificationsInited = createSelector(getSlice, (slice) => slice?.inited);
export const getPushNotificationsInitComplete = createSelector(getSlice, (slice) => slice?.initComplete);
export default accountSettingsPushNotificationsSlice;

injectReducer(name, reducer);
