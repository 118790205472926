import { lazy } from 'react'

//exportconst doughnuts = lazy(() => import('./doughnuts.webp'))

import doughnuts from './doughnuts.webp'
import ship from './ship.webp'
import travel from './travel.webp'
import mexicoPoster from './mexico_poster.webp'
import waterPoster from './water_poster.webp'

export default {
	doughnuts,
	ship,
	travel,
	mexicoPoster,
	waterPoster
}