import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import neutral from 'rds/colors/neutral';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(3),
		flexGrow: 1,
		justifyContent: 'left',
		alignItems: 'center',
		border: `1px solid ${neutral.c000}`,
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : null,
		marginBottom: theme.spacing(2),
		boxShadow: '0px 1px 1px rgba(50, 56, 62, 0.1)',
	},
	content: {
		paddingLeft: theme.spacing(2),
		flexDirection: 'column',
	},
	title: {
		textOverflow: 'ellipsis',
		width: '80vw',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
}));

export default ({
	onMouseDown,
	subtitle,
	title,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root} onMouseDown={onMouseDown}>
			<div>
				<IconV3
					icon="DestinationTag"
					size={16}
					containerSize={16}
				/>
			</div>
			<div className={classes.content}>
				<Typography className={classes.title} variant="subtitle2">{title}</Typography>
				<Typography variant="caption">{subtitle}</Typography>
			</div>
		</div>
	);
};
