/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PageSpinner from 'rds/components/Spinners/Page';
import IconV3 from 'rds/components/IconV3';
import dispatchError from 'routes/ContentCreation/errors';
import { notifyErrors } from 'app/slices/notifications/notifications';
import { useNavigate } from 'react-router-dom';
import { clear, setImage } from './store';

export const ACCEPTED_IMAGES = ['.bmp', '.png', '.jpg', '.jpeg'];

const useStyles = makeStyles((theme) => ({
	iconButton: {
		position: 'absolute',
		bottom: theme.spacing(2),
		left: theme.spacing(),
		width: 48,
		height: 48,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 2140000008,
		pointerEvents: 'all',
	},
	library: {
		width: 24,
		height: 24,
	},
}));

const SelectFromLibrary = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const ref = useRef();

	const checkImage = (file) => new Promise((resolve, reject) => {
		const img = document.createElement('img');

		img.onload = () => {
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			const viewPortHeight = window.innerHeight;
			const viewPortWidth = window.innerWidth;
			const viewPortAr = viewPortHeight / viewPortWidth;

			const { width: imageWidth, height: imageHeight } = img;
			const imageAr = imageHeight / imageWidth;

			let drawWidth;
			let drawHeight;
			let offsetHeight;
			let offsetWidth;
			let offsetX = 0;
			let offsetY = 0;

			if (viewPortAr > imageAr) {
				drawWidth = Math.min(imageWidth, 1080);
				drawHeight = drawWidth * (viewPortHeight / viewPortWidth);

				offsetWidth = imageHeight * (viewPortWidth / viewPortHeight);
				offsetX = (imageWidth - offsetWidth) / 2;
			} else {
				drawHeight = Math.min(imageHeight, 1080);
				drawWidth = drawHeight * (viewPortWidth / viewPortHeight);

				offsetHeight = imageWidth * (viewPortHeight / viewPortWidth);
				offsetY = (imageHeight - offsetHeight) / 2;
			}

			canvas.height = drawHeight;
			canvas.width = drawWidth;

			context.drawImage(
				img,
				offsetX,
				offsetY,
				imageWidth - (offsetX * 2),
				imageHeight - (offsetY * 2),
				0,
				0,
				canvas.width,
				canvas.height,
			);

			const output = new Image();
			output.onload = () => {
				resolve(output);
			};

			output.onerror = () => {
				clear();
				reject();
			};
			const src = canvas.toDataURL('image/png');
			output.src = src;
		};
		img.onerror = () => {
			reject();
		};
		img.src = URL.createObjectURL(file);
	});

	const processImage = async (file, fileType) => {
		try {
			setLoading(true);
			const img = await checkImage(file);
			setImage(img);
			navigate('/my-account/avatar/edit');
		} catch (err) {
			notifyErrors([{
				text: `${fileType} is not a supported image format.`,
			}]);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = async (e) => {
		const { files } = e.target;
		const parts = files[0].name.split('.');
		const extension = `.${parts[parts.length - 1]}`;

		if (ACCEPTED_IMAGES.indexOf(extension.toLowerCase()) > -1) {
			await processImage(files[0], extension);
		} else {
			const Err = `${extension} is not a supported ${files[0].type.slice(0, -extension.length)} format.`;
			dispatchError(dispatch, Err);
		}
	};

	return (
		<form>
			{ loading && <PageSpinner /> }
			<label
				htmlFor="file-upload"
			>
				<div className={classes.iconButton}>
					<IconV3
						icon="PhotoLibrary"
						dropShadow
						size={32}
					/>
					<div className={classes.circle} />
				</div>
			</label>
			<input
				type="file"
				name="media"
				id="file-upload"
				onChange={(e) => handleChange(e)}
				style={{ display: 'none' }}
				ref={ref}
			/>
		</form>
	);
};

export default SelectFromLibrary;
