import AssetFile from './asset-file';

let assetId = 0;

const getTypeFromBlob = (blob) => (blob.type ? blob.type.split('/')[0] : 'unknown');

class AssetManager {
	constructor() {
		this.files = {};
	}

	get(key) {
		return this.files[key];
	}

	set(blob) {
		const key = assetId++;

		this.files[key] = new AssetFile({
			key,
			blob,
		});

		return this.files[key];
	}

	async fetch(uri) {
		if (this.files[uri]) {
			return this.files[uri];
		}

		const response = await this.fetch(uri);
		const blob = await response.blob();
		this.set(uri, {
			blob,
			type: getTypeFromBlob(blob),
		});
		return this.get(uri);
	}
}

export default AssetManager;
