import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import Avatar from 'rds/components/Avatar';

const DEFAULT_DESTINATION_IMAGE = 'https://cdn.dev.roamrhino.com/images/d/default-destination_128w.jpg';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	destinationTitle: {
		marginLeft: theme.spacing(2),
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		color: '#FFFFFF',
	},
	destinationTitleV2: {
		...theme.typography.subtitle3,
		marginLeft: theme.spacing(),
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		color: '#FFFFFF',
	},

}));

export default ({
	destination,
	onClick,
	isLargeScreen,
}) => {
	const classes = useStyles();

	if (!destination) {
		return null;
	}

	return (
		<div
			className={classes.root}
			data-ignoreslider="true"
			onClick={() => {
				if (!destination?.custom && !!destination?.title.length) {
					onClick && onClick();
				}
			}}
		>
			<Avatar
				src={destination?.thumbnail?.small?.jpg || DEFAULT_DESTINATION_IMAGE}
				size={isLargeScreen ? 'xxxSmall' : 'xxxxSmall'}
				alt="destinationAccount"
			/>
			<Typography className={isLargeScreen ? classes.destinationTitle : classes.destinationTitleV2} variant={isLargeScreen ? 'subtitle2' : 'caption'}>{destination?.title}</Typography>
		</div>
	);
};
