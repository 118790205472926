import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestSession, isSessionAuthorized, hasSession } from 'app/slices/authentication/session/session';
import { requestUserPrivateProfile } from '../../app/slices/account-settings';

const useSession = () => {
	const dispatch = useDispatch();

	const session = useSelector(hasSession);
	const authorized = useSelector(isSessionAuthorized);

	useEffect(() => {
		if (session) {
			return;
		}

		if (authorized) {
			dispatch(requestSession());
			dispatch(requestUserPrivateProfile());
		}
	}, [session, dispatch, authorized]);

	const loading = useMemo(() => authorized && !session, [session, authorized]);

	return useMemo(() => [loading], [loading]);
};

export default useSession;
