import { registerRoute } from 'Router/registry';
import RegistrationMainScreen from './MainScreen';

registerRoute({
	authorization: false,
	Component: RegistrationMainScreen,
	routeOptions: {
		path: '/landing',
	},
	transition: 'slide',
});
