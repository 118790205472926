import React, { useEffect, useState, useRef } from 'react';
import ECRLink from 'features/ExperienceModal/ECRLink';
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Avatar from 'rds/components/Avatar';
import useUserAccountLink from 'app/hooks/useUserAccountLink';

const COLLAPSE_HEIGHT_MAX = 68;
const COLLAPSE_HEIGHT_MIN = 68;

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'left',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(6),
		background: theme.colors.neutral.C900,
		marginRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
	},
	title: {
		...theme.typography.body2,
		fontWeight: 600,
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		whiteSpace: 'pre-wrap',
		wordBreak: 'break-word',
	},
	caption: {
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		whiteSpace: 'pre-wrap',
		color: theme.colors.neutral.C100,
		wordBreak: 'break-word',
	},
	captionCollapsed: {
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: theme.colors.neutral.C100,
		wordBreak: 'break-word',
		whiteSpace: 'pre-wrap',
		lineClamp: 4,
		display: 'box',
		boxOrient: 'vertical',
	},
	container: {
		marginTop: theme.spacing(10),
	},
	ellipsis: {
		lineClamp: 4,
	},
	avatarContainer: {
		position: 'relative',
		textAlign: 'center',
		top: -16,
	},
	avatarLinkContainer: {
		display: 'inline-block',
	},
	border: {
		borderBottom: `1px solid ${theme.colors.neutral.C800}`,
		marginBottom: theme.spacing(5.5),
	},
	username: {
		marginTop: theme.spacing(),
		textAlign: 'center',
		color: theme.colors.neutral.C000,
	},

}));

export default ({
	children,
	experience,
	showAvatar,
	border,
}) => {
	const classes = useStyles();
	const [collapsedHeight, setCollapsedHeight] = useState(1);
	const [open, setOpen] = useState(false);
	const ref = useRef();
	let caption = '';

	useEffect(() => {
		if (!caption.length) { return; }

		if (ref?.current) {
			const { height } = ref.current.getBoundingClientRect();
			if (height > COLLAPSE_HEIGHT_MAX) {
				setCollapsedHeight(COLLAPSE_HEIGHT_MAX);
			} else {
				setCollapsedHeight(Math.max(height, COLLAPSE_HEIGHT_MIN));
			}
		}
	}, [ref, ref.current, caption]);

	try {
		caption = JSON.parse(experience?.captionRtml)?.text || '';
	} catch (_) {
		//
	}

	const to = useUserAccountLink(experience.user.username);
	if (!experience?.captionRtml && !experience?.title) {
		if (showAvatar) {
			return (
				<div className={classes.container}>
					<div className={classes.avatarContainer} data-ignoreslider="true">
						<ECRLink to={to} className={classes.avatarLinkContainer}>
							<Avatar
								src={experience?.user && experience?.user?.avatar?.large?.jpg}
								size="large"
								alt="Username"
							/>
						</ECRLink>
						<Typography className={classes.username} variant="subtitle2">{experience?.user.username}</Typography>
						{children}
					</div>
				</div>
			);
		}
		return null;
	}

	return (
		<>
			<div className={classes.root} data-ignoreslider="true" onClick={() => { setOpen(!open); }}>
				<Typography className={classes.title} variant="subtitle1">{experience?.title}</Typography>
				<Collapse collapsedSize={collapsedHeight} in={open}>
					<Typography
						id="captionRtml"
						className={open ? clsx(classes.caption) : clsx(classes.caption, classes.captionCollapsed)}
						variant="body2"
						ref={ref}
					>
						{caption}
					</Typography>
				</Collapse>
			</div>
			<div className={classes.avatarContainer} data-ignoreslider="true">
				<ECRLink to={to} className={classes.avatarLinkContainer}>
					<Avatar
						src={experience?.user && experience?.user?.avatar?.large?.jpg}
						size="large"
						alt="Username"
					/>
					<Typography className={classes.username} variant="subtitle2">{experience?.user.username}</Typography>
				</ECRLink>
				{children}
			</div>
			{border && <div className={classes.border} />}
		</>
	);
};
