import {
	getExploreExperiences,
	updateExperiences,
	getLoading,
	requestExploreContents,
	requestLoadMoreExploreContents,
	getHasMoreToLoad,
} from './explore';

export default {
	selector: getExploreExperiences,
	requestExperiences: requestExploreContents,
	loadingSelector: getLoading,
	requestMore: requestLoadMoreExploreContents,
	update: updateExperiences,
	hasMoreToLoad: getHasMoreToLoad,
};
