import darkRegion1 from './dark-1.png';
import darkRegion2 from './dark-2.png';
import darkRegion3 from './dark-3.png';
import lightRegion1 from './light-1.png';
import lightRegion2 from './light-2.png';
import lightRegion3 from './light-3.png';

export default {
  darkRegion1,
  darkRegion2,
  darkRegion3,
  lightRegion1,
  lightRegion2,
  lightRegion3,
};
