import { registerRoute } from 'Router/registry';
import OnboardingSplash from 'routes/Onboarding/Splash';
import OnboardingActivities from 'routes/Onboarding/Activities';
import OnboardingDestinations from 'routes/Onboarding/Destinations';
import OnboardingRegions from 'routes/Onboarding/Regions';
import onboardingSplashBackground from 'routes/Onboarding/assets/splash/yosemite_3.jpg';

registerRoute({
	authorization: true,
	Component: OnboardingSplash,
	Background: onboardingSplashBackground,
	routeOptions: {
		path: '/onboarding/splash',
	},
	transition: 'slide',
});

registerRoute({
	authorization: true,
	Component: OnboardingActivities,
	routeOptions: {
		path: '/onboarding/activities',
	},
	transition: 'slide',
});

registerRoute({
	authorization: true,
	Component: OnboardingDestinations,
	routeOptions: {
		path: '/onboarding/destinations',
		exact: true,
	},
	transition: 'slide',
});

registerRoute({
	authorization: true,
	Component: OnboardingRegions,
	routeOptions: {
		path: '/onboarding/regions',
	},
	transition: 'slide',
});
