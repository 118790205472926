import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import {
	getTheirInteractions,
	getMyFollowing,
	getTheirFollowing,
	getMyListing,
	getTheirListing,
} from 'app/slices/user-account-v2/accounts';
import IconV3 from 'rds/components/IconV3';
import Blocked from './Blocked';
import Experiences from './Experiences';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(6),
	},
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	subtitle: {
		marginLeft: theme.spacing(3),
	},
}));

export default ({ isMe }) => {
	const classes = useStyles();
	const interactions = useSelector(getTheirInteractions);
	const blocked = isMe ? false : interactions?.callerIsBlocking;
	const following = useSelector(isMe ? getMyFollowing : getTheirFollowing);
	const listing = useSelector(isMe ? getMyListing : getTheirListing);

	if (!following && listing === 'private' && !isMe) {
		return (
			<>
				<div className={classes.container}>
					<IconV3
						icon="Lock"
					/>
					<Typography className={classes.subtitle} variant="body1">
						This account is private.
					</Typography>
				</div>
			</>
		);
	}

	return (
		<div className={classes.root}>
			{
				blocked
					? <Blocked />
					: <Experiences isMe={isMe} />
			}
		</div>
	);
};
