import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';
import { injectReducer } from 'services/store';

export const requestNotifyServerOnDestinationSelection = createAsyncThunk(
	'requestNotifyServerOnDestinationSelection',
	async (id, { rejectWithValue }) => {
		try {
			const response = await api.post('destinations/destination-chosen', {
				id,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const notifyServerSlice = createSlice({
	name: 'ch',
	initialState: {
		loading: false,
		errors: null,
		response: null,
	},

	extraReducers: {
		[requestNotifyServerOnDestinationSelection.fulfilled]: (state, action) => {
			state.response = action.payload;
			state.loading = false;
			state.errors = null;
		},
		[requestNotifyServerOnDestinationSelection.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},

	},
});

const { name, reducer } = notifyServerSlice;

export const getSlice = (state) => state[name];
export const getResponse = createSelector(getSlice, (slice) => slice?.response);
injectReducer(name, reducer);
