import React from 'react';
import useRdsTheme from 'rds/theme/useRdsTheme';
import PageLayout from '../components/layout/PageLayout';
import FailureOverlay from './FailureOverlay';
import SignUpForm from './SignUpForm';

export default () => {
	const theme = useRdsTheme('dark');
	return (
		<>
			<PageLayout
				theme={theme}
				close="/"
				title={(
					<>
						explore.
						<br />
						connect. discover.
					</>
				)}
			>
				<SignUpForm broken />
			</PageLayout>
			<FailureOverlay />
		</>
	);
};
