const look = (value, index, direction = -1, regex) => {
	const i = index + direction;

	if (i < 0) {
		return 0;
	}

	if (i >= value.length) {
		return i - 1;
	}

	if (regex.test(value[i])) {
		return i + (-direction);
	}

	return look(value, i, direction, regex);
};

const getMatch = (str, index, triggers, regex) => {
	if (regex.test(str[index])) {
		return null;
	}

	const wordStart = look(str, index, -1, regex);
	if (triggers.indexOf(str[wordStart]) !== -1) {
		const wordEnd = look(str, wordStart, +1, regex);

		return {
			prefix: str[wordStart],
			position: {
				end: wordEnd + 1,
				start: wordStart + 1,
			},
			word: str.slice(wordStart + 1, wordEnd + 1),
		};
	}

	return null;
};

export {
	look as default,
	getMatch,
};
