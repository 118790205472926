import React from 'react';
import {
	makeStyles,
	Typography,
} from '@material-ui/core';

const PUBLISHING_TEXT = 'Publishing...';
const ERROR_TEXT = 'Experience failed to publish.';
const IN_PROGRESS_TEXT = 'Publishing in progress. Please do not close the app.';

const useStyles = makeStyles((theme) => ({
	subtitle: {
		color: theme.colors.neutral.C400,
	},
	error: {
		color: theme.colors.error.C400,
	},
}));

export default ({
	status,
	percentage,
}) => {
	const classes = useStyles();

	let s = status;

	if (status !== 'failed') {
		s = percentage >= 75 ? 'publishing' : status;
	}

	const PublishingLabel = () => {
		switch (s) {
		case 'publishing':
			return PUBLISHING_TEXT;
		case 'failed':
			return ERROR_TEXT;
		default:
			return IN_PROGRESS_TEXT;
		}
	};

	return (
		<Typography variant="body2" className={status === 'failed' ? classes.error : classes.subtitle}>{PublishingLabel()}</Typography>
	);
};
