import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		backdropFilter: 'blur(4px) brightness(0.65)',
		margin: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'fixed',
		alignItems: 'center',
		justifyContent: 'center',
		bottom: 0,
		top: 0,
		left: 0,
		right: 0,
		zIndex: 100,
		opacity: 1,
		transition: 'transform 0.65s ease, background-image 0.05s ease',
	},

	circle: {
		stroke: theme.colors.primary.C500,
	},

	header: {
		marginBottom: theme.spacing(4),
	},
}));

export default ({ message, style }) => {
	const classes = useStyles();

	return (
		<div className={classes.root} style={style}>
			{message && <Typography className={classes.header} variant="h4">{message}</Typography>}
			<CircularProgress
				classes={{
					circle: classes.circle,
				}}
				size={50}
				thickness={3.2}
			/>
		</div>
	);
};
