export const ADD_OBJECT = 'add-object';
export const BACKGROUND_OBJECT = 'background-object';
export const CANVAS_OBJECT_MOVE = 'canvas-object-move';
export const CANVAS_OBJECT_UP = 'canvas-object-up';
export const COMPILE = 'compile';
export const COMPILING = 'compiling';
export const COMPILE_HALTED = 'compile-halted';
export const DRAW = 'canvas-draw';
export const ERROR = 'error';
export const LOCK_OBJECT = 'lock-object';
export const LOG = 'log';
export const PREVIEW = 'preview';
export const REMOVE_OBJECT = 'remove-object';
export const ROOT_OBJECT = 'root-object';
export const UNLOCK_OBJECT = 'unlock-object';
export const UPDATE_CANVAS = 'update-canvas';
export const UPDATE_FILTER = 'update-filter';
export const UPDATE_OBJECT = 'update-object';
export const SELECT_CANVAS = 'select-canvas';
export const SELECT_OBJECT = 'select-object';
