import React, { Suspense } from 'react';
import RequestProvider from 'rds/providers/Request';
import {
	getConstants,
	requestApp,
	getLoading as getAppLoading,
	getErrors,
} from 'app/slices/app/app';
import {
	requestUserIdentifiers,
	getUserIdentifiers,
	getLoading as getIdentifierLoading,
	getErrors as getIdentifierErrors,
} from 'app/slices/experience-view/user-identifiers';
import Activities from './Activities';
import ClipTray from './ClipTray';
import DestinationTag from './DestinationTag';
import TitleAndCaptionContainer from './TitleAndCaptionContainer';
import RecommendedFor from './RecommendedFor';
import Identifiers from './Identifiers';

export default ({ ...props }) => (
	<RequestProvider
		requests={{
			constants: {
				action: requestApp,
				selector: getConstants,
				loading: getAppLoading,
				errors: getErrors,
			},
			identifiers: {
				action: requestUserIdentifiers,
				selector: getUserIdentifiers,
				loading: getIdentifierLoading,
				errors: getIdentifierErrors,
			},
		}}
	>
		<Suspense fallback={null}>
			<ClipTray {...props} />
			<DestinationTag />
			<TitleAndCaptionContainer {...props} />
			<Activities {...props} />
			<RecommendedFor {...props} />
			<Identifiers {...props} />
		</Suspense>
	</RequestProvider>
);
