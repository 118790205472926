import React from 'react';
import { Link } from 'react-router-dom';
import Form from 'rds/components/Layouts/Form';
import useRdsTheme from 'rds/theme/useRdsTheme';
import SubmitButton from '../components/SubmitButton/SubmitButton';
import PageLayout from '../components/layout/PageLayout';

export default () => {
	const theme = useRdsTheme('dark');

	return (
		<PageLayout
			theme={theme}
			title="Check your email"
			caption="Password reset instructions will be sent to your inbox if there is an account associated with this email."
		>
			<Form>
				<Link to="/landing">
					<SubmitButton>
						Back to login
					</SubmitButton>
				</Link>
			</Form>
		</PageLayout>

	);
};
