import React from 'react';
import {
	CircularProgress,
	makeStyles,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'inline-flex',
	},
	circle: {
		color: theme.colors.primary.C200,
	},
	circleEmpty: {
		position: 'absolute',
		color: theme.colors.primary.C400,
		opacity: 0.1,
	},
	label: {
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.colors.primary.C400,
	},

}));

export default ({ value, ...props }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress
				variant="determinate"
				className={classes.circleEmpty}
				{...props}
				value={100}
			/>
			<CircularProgress
				className={classes.circle}
				variant="determinate"
				value={value}
				{...props}
			/>
			<Typography
				className={classes.label}
				variant="caption"
			>
				{`${Math.round(value)}%`}
			</Typography>
		</div>
	);
};
