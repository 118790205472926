import React from 'react';
import { useSelector } from 'react-redux';
import {
	addTitle,
	selectTitle,
	addCaption,
	selectCaption,
} from 'app/slices/content-creation/experience';
import { makeStyles } from '@material-ui/core';
import TextModal from 'components/TextModal';
import { getConstants } from 'app/slices/app/app';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(6, 3),
	},
}));

export default () => {
	const classes = useStyles();
	const title = useSelector(selectTitle);
	const caption = useSelector(selectCaption);
	const constanst = useSelector(getConstants);
	const { config } = constanst;

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<TextModal
					type="title"
					value={title}
					title="Add Title"
					placeholder="Add an experience title"
					modalPlaceHolder="Add a title to this experience"
					maxLength={config?.maxTitleCharacters}
					addAction={addTitle}
				/>
				<TextModal
					type="caption"
					value={caption}
					title="Add Caption"
					placeholder="Add a caption"
					modalPlaceHolder="Add a caption or other information regarding the experience. (Ex. links, things to bring, best time to go, recommended length of time to visit)."
					maxLength={config?.maxCaptionCharacters}
					addAction={addCaption}
				/>
			</div>
		</div>
	);
};
