import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	content: {
		position: 'relative',
	},
});

export default ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.content}>
			{children}
		</div>
	);
};
