import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
	h1: {
		width: '100%',
		fontSize: '64px',
		lineHeight: 1.15,
		fontWeight: 600,
		margin: 0,

		'@media (max-width: 1048px)': {
			fontSize: '38px',
		},
	},
});

export default ({ children, ...props }) => <Typography {...props} classes={useStyles()} variant="h1">{children}</Typography>;
