import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	spacer: {
		paddingTop: theme.spacing(14),
	},
}));

export default () => {
	const classes = useStyles();

	return (
		<Grid item xs={12}>
			<div className={classes.spacer} />
		</Grid>
	);
};
