import { registerRoute } from 'Router/registry';
import SignOut from './SignOut';

registerRoute({
	Component: SignOut,
	routeOptions: {
		path: '/signout',
	},
	transition: 'dissolve',
});
