import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import { notifyErrors } from 'app/slices/notifications/notifications';

export const map = {};

const constraints = {
	aspectRatio: 1.7777777777777777,
	frameRate: 24,
	height: { min: 576, ideal: 720, max: 1080 },
	width: { min: 1024, ideal: 1280, max: 1920 },
};

let cancel = false;

const findDevices = async () => {
	const devices = await navigator.mediaDevices.enumerateDevices();
	const videoDevices = devices.filter((d) => d.kind === 'videoinput');
	const front = videoDevices.filter((d) => d.label.match('front'));
	const back = videoDevices.filter((d) => d.label.match('back'));
	let primaryBackIndex = 0;

	try {
		for (let i = 0; i <= back.length; i++) {
			// looking for camera2 0 ,facingback
			if (parseInt(back[i].label.split(',')[0].split(/\s/)[1], 10) === 0) {
				primaryBackIndex = i;
			}
		}
	} catch (err) {
		// stifle error
	}
	return {
		primaryBackCamera: back[primaryBackIndex] || videoDevices[0],
		primaryFrontCamera: front[0] || videoDevices[0],
	};
};

export const requestUserMedia = createAsyncThunk(
	'requestUserMedia',
	async (options = {}, { getState, dispatch, rejectWithValue }) => {
		const previousStream = getState().usermedia.streamId;

		if (previousStream) {
			dispatch(endStream());
		}

		try {
			cancel = false;
			const { primaryFrontCamera, primaryBackCamera } = await findDevices();
			const { audio = true, facingMode } = options;

			const audioConstraint = audio ? {
				echoCancellation: true,
				autoGainControl: true,
				noiseSuppression: true,
				sampleRate: 96000,
			} : false;

			const stream = await navigator.mediaDevices.getUserMedia(
				{
					audio: audioConstraint,
					video: {
						deviceId: facingMode === 'user' || getState().usermedia.facingMode === 'user'
							? primaryFrontCamera.deviceId
							: primaryBackCamera.deviceId,
						...constraints,
					},
				},
			);

			if (cancel) {
				stream.getTracks().forEach((track) => {
					track.stop();
				});
				throw new Error('stream canceled');
			}

			let photoCapabilities;
			if (typeof window.ImageCapture !== 'undefined') {
				const track = stream.getVideoTracks()[0];
				const capture = new ImageCapture(track);
				photoCapabilities = await capture.getPhotoCapabilities();
			}

			map[stream.id] = stream;

			return {
				streamId: stream.id,
				photoCapabilities,
				facingMode,
			};
		} catch (error) {
			const errors = getErrorsFromRejectedRequest(error);
			if (error.message !== 'stream canceled') {
				notifyErrors([{ text: 'Could not access device camera' }]);
			}
			return rejectWithValue(errors);
		}
	},
);

export const usermediaSlice = createSlice({
	name: 'usermedia',
	initialState: {
		streamId: null,
		photoCapabilities: null,
		errors: null,
		loading: false,
		facingMode: 'environment',
		flash: 'off',
	},
	reducers: {
		endStream: (state) => {
			const stream = map[state.streamId];
			cancel = true;

			if (!stream) {
				return;
			}

			stream.getTracks().forEach((track) => {
				track.stop();
				delete map[state.streamId];
			});
		},
		setFlash: (state, action) => {
			state.flash = action.payload;
		},
		setFacingMode: (state, action) => {
			state.facingMode = action.payload;
		},
	},
	extraReducers: {
		[requestUserMedia.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestUserMedia.fulfilled]: (state, action) => {
			state.loading = false;
			state.errors = null;
			if (action.payload.streamId) {
				state.streamId = action.payload.streamId;
			}
			state.photoCapabilities = action.payload.photoCapabilities;
		},
		[requestUserMedia.rejected]: (state, action) => {
			state.signedIn = false;
			state.loading = false;
			state.errors = action.payload;
			state.streamId = null;
			state.photoCapabilities = null;
		},
	},

});

const { name, reducer, actions } = usermediaSlice;
const {
	endStream,
	setFlash,
	setFacingMode,
} = actions;

export {
	name,
	endStream,
	setFlash,
	setFacingMode,
};

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getMirror = createSelector(getSlice, (slice) => slice?.facingMode === 'user');
export const getStream = createSelector(getSlice, (slice) => map[slice?.streamId]);
export const getFlash = createSelector(getSlice, (slice) => slice?.flash);
export const getFacingMode = createSelector(getSlice, (slice) => slice?.facingMode);
export const getPhotoCapabilities = createSelector(getSlice, (slice) => slice?.photoCapabilities);
injectReducer(name, reducer);
