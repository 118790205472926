import { canvasToBlob } from 'lib/asset-manager/create-image-object-from-blob';

const URL = window.URL || window.webkitURL;

const MAX_SIZE = 2028;

const loadImage = (file) => new Promise((resolve, reject) => {
	const img = new Image();
	img.src = URL.createObjectURL(file);

	img.onload = async () => {
		resolve(img);
	};
	img.onerror = () => {
		URL.revokeObjectURL(img.src);
		reject();
	};
});

export const readFile = (file) => new Promise((resolve, reject) => {
	const reader = new FileReader();

	reader.onload = async () => {
		resolve(new Uint8Array(reader.result));
	};

	reader.onerror = (err) => {
		try {
			if (err.currentTarget.error.message.match('permission')) {
				reject(new Error('Insufficient permissions. Download the file before selecting it'));
			}
		} catch (_) {
			reject(new Error('Could not process message'));
		}
	};

	reader.readAsArrayBuffer(file);
});

export const getImage = async (file) => {
	const img = await loadImage(file);

	const { width, height } = img;

	let mod = 1;

	if (width > height && width > MAX_SIZE) {
		mod = width / MAX_SIZE;
	} else if (height > MAX_SIZE) {
		mod = height / MAX_SIZE;
	}

	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	canvas.height = height / mod;
	canvas.width = width / mod;
	context.drawImage(img,
		0,
		0,
		canvas.width,
		canvas.height);

	const blob = await canvasToBlob(canvas);
	URL.revokeObjectURL(img.src);
	return {
		blob,
		height: canvas.height,
		width: canvas.width,
	};
};

export const getVideoDetails = (file) => new Promise((resolve, reject) => {
	const video = document.createElement('video');

	video.onloadedmetadata = () => {
		const { duration } = video;
		URL.revokeObjectURL(video.src);
		video.remove();

		if (!duration) {
			reject(new Error('Video has no duration'));
			return;
		}

		resolve({
			duration,
			height: video.videoHeight,
			width: video.videoWidth,
		});
	};
	video.enerror = () => {
		URL.revokeObjectURL(video.src);
		video.remove();
		reject(new Error('Video source could not be loaded'));
	};
	video.src = URL.createObjectURL(file);
});
