const WORKER_PATH = '/js/ffmpeg-worker-mp4.js';

export default (path = WORKER_PATH) => new Promise((resolve, reject) => {
	const worker = new Worker(path);

	worker.onerror = (err) => {
		reject(err);
	};

	worker.onmessage = (e) => {
		const message = e.data;

		if (message.type === 'ready') {
			worker.onerror = null;
			worker.onmessage = null;
			resolve(worker);
		}
	};

	return worker;
});
