import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		overflowY: 'scroll',
		zIndex: 3,
	},
});

export default ({
	children,
	path,
}) => {
	const classes = useStyles();
	const divRef = useRef();

	useEffect(() => {
		setTimeout(() => {
			if (divRef?.current) {
				divRef.current.scrollTo(0, 0);
			}
		}, 15);
	}, [divRef, path]);

	return <div ref={divRef} className={classes.root}>{children}</div>;
};
