import { createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

const initialState = {
	username: null,
	followModal: false,
	followingsList: [],
	nextFollowingLink: null,
	loadingFollowersFollowing: false,
	followersList: [],
	nextFollowersLink: null,
	followTab: null,
};

const followingActionsSlice = createSlice({
	name: 'userAcccountV2FollowingActions',
	initialState: { ...initialState },
	reducers: {
		setUsername: (state, action) => {
			state.username = action.payload;
		},
		change: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase('requestToggleFollow/pending', (state, { meta }) => {
				const { remove, type } = meta.arg;
				if (remove && type === 'unfollow') {
					state.followingsList = state.followingsList.filter((item) => item.entity.uuid !== meta.arg.uuid);
				} else {
					state.following = false;
				}
			})
			.addCase('requestUsersFollowingFollowers/fulfilled', (state, { payload }) => {
				const { data, list } = payload;
				const link = data?._links?.next?.href || data?.links?.next?.href;

				if (list === 'followers') {
					state.followersList = data.users.map((user) => ({ entity: user, type: 'user' }));
					state.nextFollowersLink = link || null;
				} else {
					state.followingsList = data.context.find((item) => item.type === 'section').entity.entities;
					state.nextFollowingLink = link || null;
				}
			})
			.addCase('requestLoadMoreFollowingFollowers/pending', (state) => {
				state.loadingFollowersFollowing = true;
			})
			.addCase('requestLoadMoreFollowingFollowers/fulfilled', (state, { payload }) => {
				const { data, list } = payload;
				const link = data?._links?.next?.href || data?.links?.next?.href;

				if (list === 'followers') {
					state.followersList = [...state.followersList, ...data.users.map((user) => ({ entity: user, type: 'user' }))];
					state.nextFollowersLink = link || null;
				} else {
					state.followingsList = [...state.followingsList, ...data.context.find((item) => item.type === 'section').entity.entities];
					state.nextFollowingLink = link || null;
				}
				state.loadingFollowersFollowing = false;
			})
			.addCase('requestLoadMoreFollowingFollowers/rejected', (state, { payload }) => {
				state.loadingFollowersFollowing = false;
				state.errors = payload;
			})
			.addCase('requestRemoveFollower/pending', (state, { meta }) => {
				state.followersList = state.followersList.filter((item) => item.entity.uuid !== meta.arg);
			})
			.addCase('requestFollowUnFollowTheirFollowings/pending', (state, { meta }) => {
				const { user, type } = meta.arg;
				state.followersList = state.followersList.map((item) => {
					if (item.entity.uuid === user.uuid) {
						item.entity.interactions.callerIsFollowing = type === 'follow';
					}
					return item;
				});
				state.followingsList = state.followingsList.map((item) => {
					if (item.entity.uuid === user.uuid) {
						item.entity.interactions.callerIsFollowing = type === 'follow';
					}
					return item;
				});
			})
			.addCase('requestFollowUnFollowDestinationAccount/pending', (state, { meta }) => {
				const { uid, type } = meta.arg;
				state.followingsList = state.followingsList.map((item) => {
					if (item.type === 'destination' && item.entity.uid === uid) {
						item.entity.interactions.callerIsFollowing = type === 'follow';
					}
					return item;
				});
			});
	},
});

const { name, reducer, actions } = followingActionsSlice;
const { change, reset, setUsername } = actions;

export {
	name,
	change,
	reset,
	setUsername,
};

injectReducer(name, reducer);

export default followingActionsSlice;
