export const getErrorArrayFromData = (data = {}) => {
	const { reasons } = data;

	if (Array.isArray(reasons)) {
		return reasons;
	}

	return [];
};

export default (error, defaultMessage = 'An error occured while attempting to fufill your request') => {
	const { isAxiosError, message } = error;

	if (!isAxiosError) {
		return [{
			text: message || defaultMessage,
		}];
	}

	const { response = {} } = error;
	const { data = {} } = response;

	let reasons;
	if (data.message) {
		reasons = [{ text: data.message }];
	} else {
		reasons = getErrorArrayFromData(data);
	}

	if (!reasons.length) {
		reasons.push({
			text: defaultMessage,
		});
	}

	return reasons;
};
