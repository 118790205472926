import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import {
	setErrors,
} from 'app/slices/authentication/signIn/sign-in-form.js';
import requestFormRequest from 'services/request-form-request';
import GAEvent from 'components/GoogleAnalytics/event';
import { getFormPayload } from './sign-in-form';
import { reset, startSession } from '../session/session';

export const requestGrantPassword = createAsyncThunk(
	'requestGrantPassword',
	async (_, options) => {
		const [response, success, payload] = await requestFormRequest(options, {
			method: 'POST',
			route: '/accounts/grant-password/token',
			getFormPayload,
			setErrors,
		});

		if (success) {
			GAEvent({ name: 'pwa_registration_page_button_signin', uuid: response.data?.accountUuid });
			await options.dispatch(reset());
			await options.dispatch(startSession({
				...response.data,
				expires: !payload?.staySignedIn,
			}));
		}

		return response.data;
	},
);

export const grantPasswordSlice = createSlice({
	name: 'grantPassword',
	initialState: {
		signedIn: false,
		errors: null,
		loading: false,
	},
	extraReducers: {
		[requestGrantPassword.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestGrantPassword.fulfilled]: (state) => {
			state.signedIn = true;
			state.loading = false;
			state.errors = null;
		},

		[requestGrantPassword.rejected]: (state, action) => {
			state.signedIn = false;
			state.loading = false;
			state.errors = action.payload;
		},
	},

});

const { name, reducer } = grantPasswordSlice;
export { name };

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);

injectReducer(name, reducer);
