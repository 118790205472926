/* eslint-disable */

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
	const { search } = useLocation();
	const params = useMemo(() => new URLSearchParams(search), [search]);

	return params;
};

export const useQueryParam = (param) => {
	const params = useQueryParams();
	const value = useMemo(() => params.get(param), [params, param]);

	return value;
};

export const useQueryParamsObject = () => {
	const params = useQueryParams();;

	const result = useMemo(() => {
		const r = {};

		for (const pair of params.entries()) {
			r[pair[0]] = pair[1];
		}

		return r;
	});

	return { ...result };
};

export const useFormUrl = () => {
	const { pathname } = useLocation();
	const params = useQueryParamsObject();

	return ({
		path = pathname,
		params: newParams,
	} = {}) => {
		newParams = newParams || {};
		Object.keys(newParams).forEach((k) => {
			if (typeof newParams[k] === 'undefined') {
				delete params[k];
			} else {
				params[k] = newParams[k];
			}
		});

		const ssa = Object.keys(params).map((k) => `${k}=${params[k]}`);

		if (!ssa.length) {
			return path;
		}

		return `${path}?${ssa.join('&')}`;
	};
};

export default useQueryParams;
