import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	error: {
		textAlign: 'left',
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(6),
		paddingTop: theme.spacing(),
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	errorTitle: {
		color: theme.colors.error.C700,
		marginBottom: theme.spacing(),
		marginTop: theme.spacing(0.5),
	},
	errorSubtitle: {
		color: theme.colors.error.C700,
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		background: theme.colors.error.C000,
	},
	header: {
		display: 'flex',
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(3),
		paddingBottom: theme.spacing(2),
		alignItems: 'flex-start',
	},
	button: {
		...theme.typography.subtitle2,
		textAlign: 'right',
		color: theme.colors.error.C700,
		padding: theme.spacing(3, 4),
	},
	texts: {
		paddingTop: theme.spacing(0.5),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	cancelIcon: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
}));

export default ({
	buttons = [{
		label: 'OK',
		link: null,
	}],
	description,
	title,
	onClose,
}) => {
	const classes = useStyles();

	if (typeof description !== 'string') {
		description = 'Internal server error';
	}

	return (
		<div className={classes.errorContainer}>
			<div className={classes.header}>
				<div className={classes.iconContainer}>
					<IconV3
						icon="AlertCircleUnfilled"
						size={24}
						color="errorC700"
						containerSize={20}
						dropShadow
					/>
				</div>
				<div className={classes.texts}>
					{title &&	<Typography className={classes.errorTitle} variant="subtitle2">{title}</Typography>}
					<Typography className={classes.errorSubtitle} variant="body1">
						{description}
					</Typography>
				</div>
			</div>
			<div className={classes.buttonContainer}>
				{
					buttons.map(({
						label,
						link,
					}, i) => (
						<div key={i}>
							<Typography
								className={classes.button}
								onClick={async () => {
									await onClose();
									if (link) {
										window.location.href = link;
									}
								}}
							>
								{label}
							</Typography>
						</div>
					))
				}
			</div>
		</div>
	);
};
