import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';

export const requestSoloExperiences = createAsyncThunk(
	'requestSoloExperiences',
	async () => {},
);

export const requestMoreSoloExperiences = createAsyncThunk(
	'requestMoreSoloExperiences',
	async () => {},
);

export const requestIndexExperiences = createAsyncThunk(
	'requestIndexExperiences',
	async (_, { rejectWithValue }) => {
		try {
			const response = await api.get('content/v0/home');
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestLoadMoreIndexExperiences = createAsyncThunk(
	'requestLoadMoreIndexExperiences',
	async (_, { rejectWithValue, getState }) => {
		const { next } = getState().index;

		if (!next) {
			return null;
		}

		try {
			const response = await api.get(`${next}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const initialState = {
	experiences: [],
	next: null,
	loading: false,
	errors: null,
	headerText: null,
	seenBefore: {},
};

const indexSlice = createSlice({
	name: 'index',
	initialState: { ...initialState },
	reducers: {
		update: (state, action) => {
			const previousState = [...state.experiences];
			const newState = [];
			for (let i = 0; i < previousState.length; i++) {
				if (previousState[i].uid === action.payload.uid) {
					previousState[i] = action.payload;
				}
				newState.push(previousState[i]);
			}

			state.experiences = newState;
		},
	},
	extraReducers: {
		[requestIndexExperiences.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestIndexExperiences.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestIndexExperiences.fulfilled]: (state, action) => {
			state.loading = false;
			state.errors = null;
			const experiences = [];
			const { context, links } = action.payload;
			state.next = links?.next?.href || null;
			for (let i = 0; i < context.length; i++) {
				const data = context[i].entity;
				if (context[i].type === 'section') {
					for (let j = 0; j < data.entities.length; j++) {
						if (data.entities[j].type === 'experience' && !state.seenBefore[data.entities[j].entity.uid]) {
							experiences.push(data.entities[j].entity);
							state.seenBefore[data.entities[j].entity.uid] = true;
						}
						if (data.entities[j].type === 'notice') {
							state.headerText = data.entities[j].entity.title;
						} else {
							state.headerText = null;
						}
					}
				}
			}
			state.experiences = experiences;
		},
		[requestLoadMoreIndexExperiences.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestLoadMoreIndexExperiences.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestLoadMoreIndexExperiences.fulfilled]: (state, action) => {
			state.errors = null;
			state.loading = false;

			if (!action.payload) {
				return;
			}

			const add = (e) => {
				if (!state.seenBefore[e.uid]) {
					state.experiences.push(e);
					state.seenBefore[e.uid] = true;
				}
			};

			state.experiences.forEach((e) => {
				add(e);
			});

			const { context, links } = action.payload;
			for (let i = 0; i < context.length; i++) {
				const data = context[i].entity;
				if (context[i].type === 'section') {
					for (let j = 0; j < data.entities.length; j++) {
						if (data.entities[j].type === 'experience') {
							add(data.entities[j].entity);
						}
					}
				}
			}

			state.next = links?.next?.href || null;
		},
	},
});

const { name, reducer, actions } = indexSlice;
const { update } = actions;
export { name, update };

export const getSlice = (state) => state[name];
export const getHeaderText = createSelector(getSlice, (slice) => slice?.headerText);
export const getIndexExperiences = createSelector(getSlice, (slice) => slice?.experiences);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getNext = createSelector(getSlice, (slice) => slice?.next);
export const getSoloExperiences = createSelector(getSlice, () => []);
export const getSoloLoading = createSelector(getSlice, () => false);
export const getHasMoreToLoad = createSelector(getSlice, (slice) => !!slice?.next);
export default indexSlice;

injectReducer(name, reducer);
