import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EachSticker from './EachSticker';

const useStyles = makeStyles((theme) => ({
	grid: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(10.5),
		flexWrap: 'wrap',
		justifyContent: 'center',
		overflowY: 'scroll',
		overflow: 'hidden',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	item: {
		flex: '1 0 20%',
		margin: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export default ({ group, params }) => {
	const classes = useStyles();

	if (!group) {
		return null;
	}

	return (
		<div className={classes.grid}>
			{group.entities.map((each, i) => (
				<div className={classes.item} key={i}>
					<EachSticker params={params} {...each} />
				</div>
			))}
		</div>
	);
};
