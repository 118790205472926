import React from 'react';
import { useSelector } from 'react-redux';
import {
	getCurrentUsername,
} from 'app/slices/user-account-v2/accounts/selectors';
import UserAccountHeader from './Header';

const UserAccountMainHeader = () => {
	const username = useSelector(getCurrentUsername);

	if (!username) {
		return null;
	}

	return <UserAccountHeader isMe={false} />;
};

export default UserAccountMainHeader;
