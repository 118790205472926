import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		textShadow: '0px 1px 10px rgba(0, 0, 0, 0.75)',
		...theme.typography.link,
	},
}));

export default ({
	children, to, variant, style = {}, ...rest
}) => {
	const classes = useStyles();

	if (variant === 'strong') {
		style.fontWeight = 700;
		style.textDecoration = 'underline';
	}

	return (
		<Link className={classes.root} to={to} style={style} {...rest}>
			{children}
		</Link>
	);
};
