import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { change } from 'app/slices/user-account-v2/edit-profile/slice';
import Cropper from 'lib/cropper';
import LoadingPage from 'rds/components/Spinners/Page';
import { notifyErrors } from 'app/slices/notifications/notifications';
import { useNavigate } from 'react-router-dom';
import { clear, getImage } from './store';
import TopControls from './TopControls';

const useStyles = makeStyles((theme) => ({
	viewport: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		touchAction: 'none',
	},
	circle: {
		content: '""',
		position: 'fixed',
		top: '20vw',
		height: '100vw',
		left: 0,
		right: 0,
		borderRadius: '50%',
		boxShadow: `0px 0px 0px 2000px ${theme.colors.neutral.C900}90`,
		touchAction: 'none',
	},
	image: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const imageRef = useRef();
	const containerRef = useRef();
	const circleRef = useRef();
	const editingAvatar = getImage();

	useEffect(() => {
		if (!editingAvatar) {
			navigate('/my-account');
		}
	}, [editingAvatar]);

	useEffect(() => {
		if (editingAvatar && circleRef?.current && containerRef?.current && imageRef?.current && !containerRef.current.cropper) {
			const sourceDimensions = {
				height: editingAvatar.height,
				width: editingAvatar.width,
			};

			const containerRect = containerRef.current.getBoundingClientRect();
			if (editingAvatar.height > containerRect.height) {
				imageRef.current.style.height = `${containerRect.height}px`;
			}

			if (editingAvatar.width > containerRect.width) {
				imageRef.current.style.width = `${containerRect.width}px`;
			}

			const options = {
				container: containerRef.current,
				croppingRectangle: circleRef.current.getBoundingClientRect(),
				image: imageRef.current,
				sourceDimensions,
			};

			containerRef.current.cropper = new Cropper(options);
		}
		return () => {
			containerRef?.current?.cropper?.destroy();
		};
	}, [circleRef, containerRef, imageRef]);

	const crop = async () => {
		try {
			setLoading(true);
			const blob = await containerRef.current.cropper.crop();
			const formData = new FormData();
			formData.append('avatar', blob);

			await dispatch(change({ avatarFormData: formData, avatar: URL.createObjectURL(blob) }));
			navigate('/my-account');
			clear();
		} catch (_) {
			notifyErrors([{
				text: 'Failed to save avatar',
			}]);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={classes.viewport} ref={containerRef}>
				<img
					alt="Potential user avatar"
					className={classes.image}
					ref={imageRef}
					src={editingAvatar && editingAvatar.src}
				/>
				<TopControls
					buttons={[{
						text: 'Back',
						ds: true,
						disabled: 'false',
						onClick: () => {
							navigate(-1);
						},
						style: { zIndex: 10 },
					}, {
						text: 'Save',
						ds: true,
						disabled: 'false',
						style: { zIndex: 10 },
						onClick: () => {
							if (!containerRef?.current?.cropper) {
								return;
							}
							crop();
						},

					}]}
				/>
				<div className={classes.circle} ref={circleRef} />
				{loading && (
					<LoadingPage />
				)}
			</div>
		</>
	);
};
