export const canvasToBlob = (canvas) => new Promise((resolve) => {
	canvas.toBlob(resolve);
});

export const blobToImage = (blob) => new Promise((resolve, reject) => {
	const image = new Image();
	const uri = window.URL.createObjectURL(blob);

	const onLoaded = (err) => {
		window.URL.revokeObjectURL(uri);

		image.onload = null;
		image.onerror = null;

		if (err) {
			reject(err);
			return;
		}
		image.remove();

		resolve(image);
	};

	image.onload = () => onLoaded(null);
	image.onerror = () => onLoaded(new Error('failed to load image'));
	image.src = uri;
	image.style.visibility = 'hidden';
	image.style.position = 'absolute';
});

const getVideoFrameData = (video, timestamp) => new Promise((resolve, reject) => {
	const onVideoError = () => {
		video.removeEventListener('error', onVideoError);
		video.removeEventListener('seeked', eitherOr);
		video.removeEventListener('play', eitherOr);
		reject(video.error);
	};

	const eitherOr = () => {
		video.pause();
		video.removeEventListener('error', onVideoError);
		video.removeEventListener('seeked', eitherOr);

		const { videoWidth, videoHeight } = video;

		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');

		canvas.style.visibility = 'hidden';

		canvas.width = videoWidth;
		canvas.height = videoHeight;

		context.drawImage(video, 0, 0, videoWidth, videoHeight);

		canvas.toBlob((b) => {
			canvas.remove();
			resolve(b);
			// document.body.removeChild(video);
		});
	};

	// document.body.appendChild(video);
	video.addEventListener('error', onVideoError);
	video.addEventListener('loadedmetadata', eitherOr);
	video.currentTime = timestamp;
	video.muted = true;
	video.play().catch(() => { });
});

const createImageHelper = async (video, blob, timestamp) => {
	if (!(blob instanceof Blob)) {
		throw new Error('cannot create image object from non-blob types');
	}

	if (video) {
		const frame = await getVideoFrameData(video, timestamp);
		const image = await createImageHelper(null, frame);
		return image;
	}

	const image = await blobToImage(blob);
	return image;
};

const SCALE = 0.6;
const createImageObjectFromBlob = async ({
	blob,
	alpha = 0.8,
	background = '#fff',
	blur = '200px',
	timestamp,
	video,
}) => {
	const image = await createImageHelper(video, blob, timestamp);
	const modifiedCanvas = document.createElement('canvas');
	const modifiedContext = modifiedCanvas.getContext('2d');

	// apply blur & alpha modifiers
	// const { width, height } = image;
	const height = image.height * SCALE;
	const width = image.width * SCALE;
	modifiedCanvas.height = height;
	modifiedCanvas.width = width;
	modifiedContext.fillStyle = background;
	modifiedContext.fillRect(0, 0, width, height);
	modifiedContext.filter = `blur(${blur})`;
	modifiedContext.globalAlpha = alpha;
	modifiedContext.drawImage(image, 0, 0);

	// zoom in to reduce outside lighting
	const canvas = document.createElement('canvas');
	canvas.height = height;
	canvas.width = width;
	const context = canvas.getContext('2d');
	context.drawImage(modifiedCanvas,
		width * SCALE,
		height * SCALE,
		width - ((width * SCALE) * 2),
		height - ((height * SCALE) * 2),
		0,
		0,
		width,
		height);

	const outBlob = await canvasToBlob(canvas);
	return outBlob;
};

export default createImageObjectFromBlob;
