import EventEmitter from 'events';

export default class Store extends EventEmitter {
	constructor() {
		super();
		this._NODE_ID_SERIAL = 0;
		this._originalString = '';
		this._nodes = [];
		this._cursorRange = null;
	}

	// cursor positions
	get cursorRange() {
		return this._cursorRange;
	}

	set cursorRange(c) {
		this._cursorRange = c;
		this._triggerCursorMove();
	}

	_triggerUpdate() {
		this.emit('update');
	}

	_triggerCursorMove() {
		this.emit('cursor');
	}

	// nodes
	addNode({
		type,
		contents,
		data,
	}) {
		this._nodes.push({
			contents,
			data,
			type,
			id: ++this._NODE_ID_SERIAL,
		});
		this._triggerUpdate();
	}

	removeNode(id) {
		const index = this._nodes.findIndex((n) => n.id === id);
		this._nodes.splice(index, 1);
		this._triggerUpdate();
	}

	set string(s) {
		this._originalString = s;
		this._triggerUpdate();
	}

	matchIndex(txt = '', regex, count = 0) {
		const iter = txt.matchAll(regex);

		let tmp = null;

		for (let i = 0; i <= count; i++) {
			tmp = iter.next();
		}

		return tmp.value;
	}

	validateNodes(txt = this._originalString) {
		const contentsMatches = {};
		for (let i = 0; i < this._nodes.length; i++) {
			const n = this._nodes[i];
			const match = this.matchIndex(txt, n.contents, contentsMatches[n.contents]);

			if (!contentsMatches[n.contents]) {
				contentsMatches[n.contents] = 0;
			}

			if (!match) {
				this._nodes.splice(i, 1);
				i--;
			} else {
				contentsMatches[n.contents]++;
				n.start = match.index;
			}
		}

		this._nodes = this._nodes.sort((a, b) => a.start - b.start);
	}

	getNodeById(id) {
		return this._nodes.find((n) => n.id === id);
	}

	// node output
	get nodes() {
		const outputNodes = [];

		let cursor = 0;

		const pushText = (end) => {
			const contents = this._originalString.slice(cursor, end);

			if (contents.length) {
				outputNodes.push({
					type: 'text',
					start: cursor,
					end,
					contents,
				});
			}
		};

		const contentsMatches = {};
		for (let i = 0; i < this._nodes.length; i++) {
			const n = this._nodes[i];

			if (!contentsMatches[n.contents]) {
				contentsMatches[n.contents] = 0;
			}

			const match = this.matchIndex(this._originalString, n.contents, contentsMatches[n.contents]);
			if (!match) {
				continue;
			}

			contentsMatches[n.contents]++;

			const start = match.index;
			const end = start + n.contents.length;

			// is there a space or nothing after
			if (this._originalString[end] && !this._originalString[end].match(/\s/)) {
				continue;
			}

			pushText(start);
			outputNodes.push({
				start,
				end,
				...n,
			});
			cursor = end;
		}

		pushText(this._originalString.length);
		return outputNodes;
	}

	destroy() {
		this.removeAllListeners();
	}
}
