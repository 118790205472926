import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filters from 'rds/components/Filters';
import FiltersModal from 'rds/components/Filters/FiltersModal';
import {
	setOpenModalState,
	addFilters,
	getFilters,
	removeFilters,
	requestExploreContents,
	getFilterHasBeenChanged,
	resetToPreviousState,
	getOpeningFilterState,
} from 'app/slices/search-explore/explore';

export default ({ modalOptions, setModalOptions }) => {
	const dispatch = useDispatch();
	const filters = useSelector(getFilters);
	const openingFilterState = useSelector(getOpeningFilterState);
	const filterHasBeenChanged = useSelector(getFilterHasBeenChanged);

	const handleSelect = (filter) => {
		if (!filters[modalOptions?.title].some((f) => f.slug === filter.slug)) {
			dispatch(addFilters({ group: modalOptions?.title, filter }));
		} else {
			dispatch(removeFilters({ group: modalOptions?.title, filter }));
		}
	};

	return (
		<>
			<Filters
				modalOptions={modalOptions}
				setModalOptions={setModalOptions}
				filters={filters}
				onOpen={setOpenModalState}
				onReset={() => { dispatch(resetToPreviousState()); }}
			/>
			<FiltersModal
				explore
				show={!!modalOptions}
				title={modalOptions?.title}
				options={modalOptions?.options}
				filters={filters}
				openingFilterState={openingFilterState}
				filterHasBeenChanged={filterHasBeenChanged}
				onClose={() => {
					setModalOptions(null);
				}}
				onSubmit={() => {
					dispatch(requestExploreContents());
				}}
				handleSelect={handleSelect}
				onReset={() => { dispatch(resetToPreviousState()); }}
			/>
		</>
	);
};
