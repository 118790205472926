import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from 'rds/components/Layouts/Form';
import TextField from 'rds/components/TextField';
import {
	control, resetForm,
} from 'app/slices/authentication/forgot-password/forgot-password-form';
import { getLoading, requestForgotPassword } from 'app/slices/authentication/forgot-password/forgot-password';
import useRdsTheme from 'rds/theme/useRdsTheme';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../components/SubmitButton/SubmitButton';

export default () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector(getLoading);
	const fields = control.get(dispatch);
	const [email, emailErr, emailTmpErr] = fields.email.values;
	const theme = useRdsTheme('dark');

	const onSubmit = useCallback(
		(e) => {
			e.preventDefault();
			if (loading) {
				return;
			}
			dispatch(requestForgotPassword(navigate, { redirect: '/forgot-password/sent' }));
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(resetForm());
	}, [dispatch]);

	const disabled = !!emailTmpErr || !email;

	return (
		<>
			<Form onSubmit={onSubmit}>
				<TextField
					theme={theme}
					value={email}
					err={emailErr}
					tmpErr={emailTmpErr}
					onChange={fields.email.change}
					variant="glass"
					formItem
					placeholder="Enter email address"
					fullWidth
					icon={{
						icon: 'Email',
						containerSize: 24,
						width: 18,
						height: 18,
					}}
				/>
				<SubmitButton style={{ marginTop: 12 }} loading={loading} disabled={disabled}>Submit</SubmitButton>
			</Form>

		</>

	);
};
