import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import CustomSelect from 'rds/components/CustomSelect.js';
import { requestSendReport } from 'app/slices/reports';
import SupportModal from 'rds/components/ReportModal/SupportModal';
import Header from 'rds/components/ReportModal/Header';
import buttons from 'rds/components/ReportModal/ReportOptions';
import useNavigateReportModal from 'app/hooks/useNavigateReportModal';
import { set, getRoute } from 'app/slices/reports/layout';

const useStyles = makeStyles((theme) => ({
	subtitle: {
		width: '100%',
		textAlign: 'left',
		marginBottom: theme.spacing(4),
	},
	textEditor: {
		width: '100%',
	},
	form: {
		width: '100%',
		marginBottom: theme.spacing(6),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'left',
	},
	header: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: '100%',
		height: '100%',
	},
}));

export default ({
	label,
	type,
	title,
	formTitle,
	id,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [value, setValue] = useState('');
	const route = useSelector(getRoute);
	const reportNavigate = useNavigateReportModal();

	const handleSubmit = () => {
		const resourceType = type;
		const resourceId = id;
		const reason = formTitle?.enum;
		const details = value;
		dispatch(requestSendReport({
			resourceType,
			resourceId,
			reason,
			details,
		}));
		setValue('');
		reportNavigate.exit();
	};

	useEffect(() => {
		if (route !== 'details') {
			setValue('');
		}
	}, [route]);

	const handleChange = (v) => {
		dispatch(set({ formTitle: v }));
	};

	return (
		<SupportModal
			submit
			form
			title={title}
			type={type}
		>
			<Header
				label={label}
				onSubmit={handleSubmit}
				disableSubmit={!value.length}
				notificationTitle="Report submitted."
				submit
			/>
			<CustomSelect options={buttons} value={formTitle} onChange={handleChange} />
			<Typography className={classes.subtitle} variant="body1">Please provide additional details.</Typography>
			<TextField
				onChange={(e) => {
					setValue(e.target.value);
				}}
				multiline
				minRows={10}
				className={classes.textEditor}
				variant="outlined"
			/>
		</SupportModal>
	);
};
