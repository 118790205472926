import { EventEmitter } from 'events';
import {
	// CANVAS_SVG_FAILED_RENDER,
	INVALID_TYPE,
	NODE_NOT_FOUND,
} from '../errors';
import {
	ADD_OBJECT,
	CANVAS_OBJECT_MOVE,
	CANVAS_OBJECT_UP,
	COMPILE,
	COMPILING,
	COMPILE_HALTED,
	DRAW,
	ERROR,
	LOCK_OBJECT,
	PREVIEW,
	REMOVE_OBJECT,
	ROOT_OBJECT,
	UNLOCK_OBJECT,
	UPDATE_CANVAS,
	UPDATE_FILTER,
	UPDATE_OBJECT,
	SELECT_CANVAS,
	SELECT_OBJECT,
} from '../event-types';
import createElement from '../utils/create-element';
import getDefaultOptions from '../utils/get-default-options';
import {
	AUDIO, HTML, GIF, GOP, IMAGE, VIDEO,
} from '../types';

import applyGestures from './gestures/apply';
import Debug from './debug';
import Grid from './grid';
import Recorder from './recorder';
import AudioNode from './nodes/audio';
import FilterCollectionNode from './nodes/filter-collection';
import GifNode from './nodes/gif';
import GOPNode from './nodes/gop';
import ImageNode from './nodes/image';
import HtmlNode from './nodes/html';
import VideoNode from './nodes/video';

const defaultOptions = { fps: 30 };

/**
 * Canvas class
 *
 * @param {object} container container element
 * @param {object} store store object
 * @param {object} [options]
 * @param {number} [options.fps] defaults to 45 frames per second
 */
export default class Canvas extends EventEmitter {
	constructor(container, store, options) {
		super();

		this._rect = null;
		this.compileContext = {
			compiling: false,
		};
		this.drawContext = {};
		this.filters = [];
		this.fonts = options.fonts;
		this.nodes = [];
		this.preview = options.preview || null;
		this.ignoreGestures = false;

		this.container = container;
		this.store = store;
		this.debug = new Debug(this);
		this.options = getDefaultOptions(options, defaultOptions);
		this.fpsTimeout = 1000 / this.options.fps;
		this.grid = new Grid(this);

		if (this.options.filterCollection) {
			this.filterCollection = this.options.filterCollection;
			this.filterCollection.render(container);
			this.filterCollection.on(UPDATE_FILTER, () => {
				this.emit(UPDATE_CANVAS);
			});
		}

		[this.gestureStore, this.destroyGestures] = applyGestures(this);

		this.store.on(ADD_OBJECT, async (id, item) => {
			const node = this.createNode(item);

			if (this.drawContext.playing) {
				await node.setupDraw();
			}

			this.nodes.push(node);
			this.emit(UPDATE_CANVAS);
		});

		this.store.on(REMOVE_OBJECT, (id) => {
			const [n] = this.nodes.splice(
				this.nodes.findIndex((x) => x.id === id),
				1,
			);
			n.destroy();
			this.emit(UPDATE_CANVAS);
		});

		const applyToNode = (id, fn) => {
			const node = this.nodes.find((n) => n.id === id);
			if (!node) {
				this.emit(ERROR, NODE_NOT_FOUND, new Error(NODE_NOT_FOUND));
				return;
			}
			fn(node);
			this.emit(UPDATE_CANVAS);
		};
		this.store.on(UPDATE_OBJECT, async (id, item) => {
			applyToNode(id, (node) => {
				node.update(item);
			});
		});

		this.store.on(ROOT_OBJECT, (id) => {
			applyToNode(id, (node) => {
				node.makeRoot();
			});
		});

		this.store.on(LOCK_OBJECT, (id) => {
			applyToNode(id, (node) => {
				node.lock();
			});
		});

		this.store.on(UNLOCK_OBJECT, (id) => {
			applyToNode(id, (node) => {
				node.unlock();
			});
		});

		this.gestureStore.on('update', (id, item) => {
			this.store.update(id, undefined, item.styles);
		});
	}

	onObjectMove(e, params) {
		this.emit(CANVAS_OBJECT_MOVE, e, params);
	}

	onObjectUp(e, params) {
		this.emit(CANVAS_OBJECT_UP, e, params);
	}

	onSelect(e, object) {
		this.emit(object && !object?.background ? SELECT_OBJECT : SELECT_CANVAS, e, object);
	}

	/**
	 * Getter to return rendering data for nodes
	 *
	 * */
	async getNodeRenderingData(options = {}) {
		const { outputWidth = 1080 } = options;
		const [nodes] = this.createNodes();

		const fileData = [];
		const shadowContainer = this.container.cloneNode(true);
		shadowContainer.style.visibility = 'hidden';
		document.body.appendChild(shadowContainer);
		const rect = shadowContainer.getBoundingClientRect();
		const { width } = rect;
		const modifier = outputWidth / width;

		for (let i = 0; i < nodes.length; i++) {
			const data = await nodes[i].getFileData({
				count: i,
				modifier,
				shadowContainer,
			});

			if (data) {
				fileData.push(data);
			}
		}

		shadowContainer.remove();

		return { modifier, nodes: fileData };
	}

	/**
	 * Internal function to return the container rectangle size
	 * TODO: remove on window resize
	 */
	get rect() {
		if (this._rect) {
			return this._rect;
		}
		this._rect = this.container.getBoundingClientRect();
		return this._rect;
	}

	/**
	 * Internal function to retrieve audio nodes
	 * @param {array} nodes
	 */
	getAudio(nodes) {
		const audio = [];

		for (let i = 0; i < nodes.length; i++) {
			if (nodes[i].audio) {
				audio.push(nodes[i].audio);
			}
		}

		return audio;
	}

	/**
	 * Internal function to create a node based on item data
	 *
	 * @param {object} item node metadata
	 */
	createNode(item) {
		let node;

		switch (item.type) {
		case AUDIO:
			node = new AudioNode(this, item);
			break;
		case HTML:
			node = new HtmlNode(this, item);
			break;
		case GIF:
			node = new GifNode(this, item);
			break;
		case GOP:
			node = new GOPNode(this, item);
			break;
		case IMAGE:
			node = new ImageNode(this, item);
			break;
		case VIDEO:
			node = new VideoNode(this, item);
			break;
		default:
			this.emit(ERROR, INVALID_TYPE, new Error(INVALID_TYPE));
			break;
		}

		return node;
	}

	createNodes(options = {}) {
		const { includeNodeIds, filterNodeIds } = options;
		const nodes = [];

		let type = IMAGE;

		const filteredItems = filterNodeIds
			? this.store.items.filter((item) => filterNodeIds.indexOf(item.id) === -1)
			: includeNodeIds
				? this.store.items.filter((item) => includeNodeIds.indexOf(item.id) !== -1)
				: this.store.items;

		const items = filteredItems.sort((a, b) => a.z - b.z);
		for (let i = 0; i < items.length; i++) {
			const node = this.createNode(items[i]);
			nodes.push(node);
			if (node.type === VIDEO || node.type === GIF || node.type === GOP) {
				type = VIDEO;
			}

			if (node.data.root && this.filterCollection) {
				this.filterCollection.setZ(items[i].styles['z-index'] + 1);
				nodes.push(new FilterCollectionNode(this, node, this.filterCollection));
			}
		}

		return [nodes, type];
	}

	/**
	 * Internal function to clean up nodes
	 */
	clearNodes() {
		for (let i = 0; i < this.nodes.length; i++) {
			this.nodes[i].destroy();
			this.nodes[i] = null;
		}
		this.nodes = [];
	}

	/**
	 * Internal function to call an abstract render function on a framerate interval
	 *
	 * @param {string} key compile vs draw
	 * @param {function} fn render function
	 * @param {object} context contextual data for the rendering job
	 * @param {int} start starting time to derive appropriate render frame
	 * @param {int} i number of iterations rendered
	 * @param {int} compileStart time in ms to start the compile
	 * @param {int} compileEnd time in ms to end the compile
	 * @param {function} cb called when compiling has completed
	 */
	async render(key, fn, context, start, i, compileStart, compileEnd, cb) {
		const begin = Date.now();
		const t = begin - start;

		if (this.compileHalted && key === 'compile') {
			if (cb) {
				cb(t, true);
			}
			return;
		}

		await fn(t);

		if (key !== 'draw' && (!compileEnd || t >= compileEnd)) {
			if (cb) {
				cb(t);
			}
			return;
		}

		if (key === 'draw' && this.drawHalted) {
			return;
		}

		requestAnimationFrame(() => {
			const done = Date.now();
			const nextT = start + (this.fpsTimeout * i);
			const elapsed = done - begin;
			const wait = Math.max(0, nextT - done - elapsed);

			// drop a frame
			if (elapsed >= this.fpsTimeout) {
				context.framedrops++;
				i++;
			}
			context.framesdrawn++;
			setTimeout(() => {
				this.render(key, fn, context, start, i + 1, compileStart, compileEnd, cb);
			}, wait);
		});
	}

	/**
	 * Render based on framerate instead of time
	 *
	 * @param {function} fn render function
	 * @param {object} context contextual data for the rendering job
	 * @param {int} frame current frame
	 * @param {int} compileEnd time in ms to end the compile
	 * @param {function} cb called when compiling has completed
	 */
	async preciseRender(fn, context, frame, end, cb) {
		const t = frame * this.fpsTimeout;

		if (this.compileHalted) {
			if (cb) {
				cb(t, true);
			}
			return;
		}

		if (t >= end) {
			if (cb) {
				cb(t);
			}
			return;
		}

		await fn(t);
		context.framesdrawn++;
		requestAnimationFrame(() => {
			this.preciseRender(fn, context, frame + 1, end, cb);
		});
	}

	/**
	 * Draws canvas items for all of the components in the items in the store
	 */
	async draw() {
		this.drawHalted = false;

		if (this.drawContext.playing) {
			for (let i = 0; i < this.nodes.length; i++) {
				await this.nodes[i].restart();
			}
			return;
		}

		this.drawContext = {
			playing: true,
			framesdrawn: 0,
			framedrops: 0,
			start: Date.now(),
		};

		if (!this.drawIsSetup) {
			for (let i = 0; i < this.nodes.length; i++) {
				await this.nodes[i].setupDraw();
			}
		} else {
			for (let i = 0; i < this.nodes.length; i++) {
				await this.nodes[i].restart();
			}
		}
		this.drawIsSetup = true;

		this.render('draw', async (t) => {
			for (let i = 0; i < this.nodes.length; i++) {
				await this.nodes[i].drawFrame(t);
			}
			this.emit(DRAW);
		}, this.drawContext, Date.now(), 1, 0);
	}

	stop() {
		this.drawHalted = true;
		this.drawContext.playing = false;
	}

	/**
	 * Helper function to compile a group of images
	 * @param {object} options
	 */
	compileGop(options) {
		return new Promise((resolve) => {
			const {
				canvas,
				end,
				nodes,
				modifier,
				start,
			} = options;

			const getBlobFromFrame = () => new Promise((resolveBlob) => {
				canvas.toBlob((blob) => {
					resolveBlob(blob);
				}, 'image/png');
			});
			const frames = [];
			this.preciseRender(async (t) => {
				for (let i = 0; i < nodes.length; i++) {
					if (!nodes[i]) {
						continue;
					}
					await nodes[i].renderFrame(t, modifier);
				}
				const blob = await getBlobFromFrame();
				frames.push(blob);
			}, this.compileContext, 0, end, (videoTime, halted) => {
				this.compileContext.end = Date.now();

				this.compileContext.compiling = false;
				if (halted) {
					this.compileHalted = false;
					this.emit(COMPILE_HALTED);
				} else {
					this.emit(COMPILE, {
						audio: this.getAudio(nodes),
						elapsed: Date.now() - start,
						fps: this.options.fps,
						frames,
						type: 'gop',
					});
				}

				resolve();
			});
		});
	}

	/**
	 * Helper function to compile a video
	 * @param {object} options
	 */
	compileVideo(options) {
		return new Promise((resolve) => {
			const {
				frameCanvas,
				context,
				canvas,
				end,
				nodes,
				modifier,
				shadowContainer,
				start,
				muted,
			} = options;

			const stream = new MediaStream();
			const canvasStream = canvas.captureStream(this.options.fps);

			canvasStream.getVideoTracks().forEach((v) => {
				stream.addTrack(v.clone());
			});

			if (!muted) {
				this.avNodes.forEach((a) => {
					const s = a.el.captureStream();
					if (s.getAudioTracks().forEach((at) => {
						stream.addTrack(at.clone());
					}));
				});
			}

			let elapsed = 0;
			const recorder = new Recorder(stream, {
				audio: true,
				video: true,
			});

			let finished = false;
			const onEnd = (blob) => {
				this.compileContext.end = Date.now();
				this.compileContext.compiling = false;
				this.emit(COMPILE, { video: blob }, elapsed);
			};
			const onElapsed = () => {
				finished = true;
			};
			recorder.addListener('end', onEnd);
			recorder.addListener('timeelapsed', onElapsed);

			let started = false;
			this.render('compile', async (t) => {
				for (let i = 0; i < nodes.length; i++) {
					if (!nodes[i]) {
						continue;
					}

					await nodes[i].renderFrame(t, modifier);
				}
				context.drawImage(frameCanvas, 0, 0);
				if (started) {
					return;
				}
				started = true;
				recorder.start(end);
				if (!muted) {
					this.playAudio();
				}
			}, this.compileContext, Date.now(), 1, start, end + 500, (timeElapsed, halted) => {
				if (halted) {
					this.compileContext.end = Date.now();

					if (!finished) {
						recorder.removeListener('timeelapsed', onElapsed);
						recorder.removeListener('end', onEnd);
						this.compileContext.compiling = false;
						this.emit(COMPILE_HALTED);
					}
				}

				this.compileHalted = false;
				recorder.stop();
				elapsed = timeElapsed;
				shadowContainer.remove();
				canvas.remove();
				resolve();
			});
		});
	}

	/**
	 * Helper function to compile an image
	 * @param {object} options
	 */
	compileImage(options) {
		return new Promise((resolve, reject) => {
			const {
				frameCanvas,
				context,
				canvas,
				nodes,
				modifier,
				shadowContainer,
			} = options;

			this.render('compile', async (t) => {
				//
				for (let i = 0; i < nodes.length; i++) {
					if (!nodes[i]) {
						continue;
					}

					await nodes[i].renderFrame(t, modifier);
				}
			}, this.compileContext, Date.now(), 1, 0, 0, (timeElapsed, halted) => {
				this.compileContext.end = Date.now();
				this.compileContext.compiling = false;

				if (halted) {
					this.compileHalted = false;
					this.emit(COMPILE_HALTED);
					reject(new Error('compile halted'));
				} else {
					context.drawImage(frameCanvas, 0, 0);
					canvas.toBlob((blob) => {
						if (!blob) {
							reject(new Error('no blob'));
							return;
						}
						const img = new Image();
						img.src = URL.createObjectURL(blob);
						this.preview = img;
						this.emit(PREVIEW, this.preview, blob);

						this.emit(COMPILE, { image: blob, preview: this.compileContext.preview }, timeElapsed);
						resolve();
					}, 'image/png');
				}

				shadowContainer.remove();
			});
		});
	}

	/**
	 * Compiles a mediastream
	 */
	async _compile(options) {
		if (this.compileHalted) {
			if (this.compileContext.compiling) {
				return;
			}
			this.compileHalted = false;
		}

		await this.haltCompile();
		const {
			end,
			preview,
			start,
			muted,
			includeNodeIds,
			filterNodeIds,
			outputWidth = 1080,
		} = options;

		this.compileContext = {
			compiling: true,
			preview,
			playing: true,
			framesdrawn: 0,
			framedrops: 0,
			start: Date.now(),
			end: null,
		};
		this.emit(COMPILING);

		const resp = this.createNodes({ includeNodeIds, filterNodeIds });
		const nodes = resp[0];
		let type = resp[1];

		if (preview) {
			type = IMAGE;
		}

		let fn = this.compileImage.bind(this);

		if (type === VIDEO) {
			if (this.options.mode === 'gop') {
				fn = this.compileGop.bind(this);
			} else {
				fn = this.compileVideo.bind(this);
			}
		}

		const canvas = createElement({ tagName: 'canvas' });
		const context = canvas.getContext('2d');

		const frameCanvas = createElement({ tagName: 'canvas' });
		const frameContext = frameCanvas.getContext('2d');

		const shadowContainer = this.container.cloneNode(true);
		document.body.appendChild(shadowContainer);
		const { height, width } = shadowContainer.getBoundingClientRect();
		const modifier = outputWidth / width;
		canvas.height = height * modifier;
		canvas.width = width * modifier;

		frameCanvas.height = height * modifier;
		frameCanvas.width = width * modifier;

		shadowContainer.style.visibility = 'hidden';

		for (let i = 0; i < nodes.length; i++) {
			await nodes[i].setupCompile(frameCanvas, frameContext, shadowContainer, start, end, preview);
		}

		try {
			await fn({
				canvas,
				context,
				frameCanvas,
				frameContext,
				end,
				nodes,
				modifier,
				shadowContainer,
				start,
				muted,
			});
		} catch (err) {
			// ignore this error
		}

		shadowContainer.remove();
	}

	/**
	 * Compiles a preview
	 */
	async compilePreview() {
		await this._compile({
			preview: true,
		});
	}

	/**
	 * Halts the active compile job
	 */
	haltCompile() {
		return new Promise((resolve) => {
			if (!this.compileContext.compiling) {
				resolve();
				this.compileHalted = false;
				return;
			}

			this.once(COMPILE_HALTED, resolve);
			this.compileHalted = true;
		});
	}

	/**
	 * Compiles a video
	 * @param {number} start
	 * @param {number} end
	 */
	async compile(start, end, options = {}) {
		await this._compile({
			end,
			start,
			...options,
		});
	}

	get avNodes() {
		return this.nodes.filter((n) => n.type === AUDIO || n.type === VIDEO);
	}

	playAudio(currentTime) {
		this.avNodes.forEach((av) => av.playAudio(currentTime));
	}

	pauseAudio(currentTime) {
		this.avNodes.forEach((av) => av.pauseAudio(currentTime));
	}

	pauseVideo() {
		this.avNodes.forEach((av) => {
			if (av?.type === 'video') {
				av?.el?.pause();
			}
		});
	}

	/**
	 *	Destroy and clean up canvas element
	 */
	destroy() {
		for (let i = 0; i < this.nodes.length; i++) {
			this.nodes[i].destroy();
		}
		this.destroyGestures();
	}
}
