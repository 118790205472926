import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	txt: {
		textAlign: 'center',
		whiteSpace: 'normal',
		width: 288,
		marginBottom: theme.spacing(6),
	},
	type: {
		marginBottom: theme.spacing(2),
	},
}));

export default () => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.txt}>
				<Typography variant="body1" className={classes.type}>
					An identity influences how we see and experience the world. Your identity is honored and celebrated here.
				</Typography>
				<Typography variant="body1" gutter>
					Tap to select or create your own:
				</Typography>
			</div>
		</>
	);
};
