import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import {
	addCollections,
	selectCollections,
	selectUserIdentifiers,
} from 'app/slices/content-creation/experience';
import EachIdentifier from './EachIdentifier';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(3),
		marginTop: theme.spacing(4),
	},
	titles: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(4),
	},
	left: {
		display: 'flex',

	},
	right: {
		color: theme.colors.neutral.C600,
		marginRight: theme.spacing(4),
	},
	container: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(7),
		overflowX: 'auto',
		overflow: 'hidden',
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const identifiers = useSelector(selectUserIdentifiers);
	const collections = useSelector(selectCollections);

	useEffect(() => {
		if (!identifiers.length) {
			return;
		}
		if (!collections.length) {
			for (let i = 0; i < identifiers.length; i++) {
				dispatch(addCollections(identifiers[i]));
			}
		}
	}, [identifiers]);

	if (!identifiers.length) {
		return null;
	}

	return (
		<div className={classes.root}>
			<div className={classes.titles}>
				<div className={classes.left}>
					<IconV3
						icon="Masonry"
						style={{
							marginRight: 12,
						}}
						size={20}
						containerSize={22}
					/>
					<Typography variant="subtitle1">Share to communities</Typography>

				</div>
				<div className={classes.right}>
					<Typography variant="body1">
						{collections.length}
						{' '}
						of
						{' '}
						{identifiers.length}
					</Typography>
				</div>
			</div>
			<div className={classes.container}>
				{identifiers.map((idn, i) => (
					<EachIdentifier key={i} group={idn} />
				))}
			</div>

		</div>
	);
};
