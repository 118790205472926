import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';
import { requestIndexExperiences } from 'app/slices/index';
import { updateFollowStatus } from '../accounts';

const followingUrl = 'content/v0/following';
const followersUrl = 'accounts/followers';

export const requestToggleFollow = createAsyncThunk(
	'requestToggleFollow',
	async ({ uuid, type, remove }, { rejectWithValue, dispatch }) => {
		const key = remove ? 'followingTotal' : 'followers';

		dispatch(updateFollowStatus({
			key,
			amount: type === 'follow' ? 1 : -1,
		}));

		try {
			const apiAction = type === 'follow' ? 'put' : 'delete';
			const response = await api[apiAction](`accounts/follow/${uuid}`);
			dispatch(requestIndexExperiences());
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestUsersFollowingFollowers = createAsyncThunk(
	'requestUsersFollowingFollowers',
	async ({ uuid, list }, { rejectWithValue }) => {
		try {
			const url = list === 'followers' ? followersUrl : followingUrl;
			const response = await api.get(`${url}/${uuid}`);

			return { data: response.data, list };
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestLoadMoreFollowingFollowers = createAsyncThunk(
	'requestLoadMoreFollowingFollowers',
	async (list, { rejectWithValue, getState }) => {
		const { nextFollowingLink, nextFollowersLink } = getState().userAcccountV2FollowingActions;
		const next = list === 'followers' ? nextFollowersLink : nextFollowingLink;
		try {
			const response = await api.get(next);
			return { data: response.data, list };
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestFollowUnFollowTheirFollowings = createAsyncThunk(
	'requestFollowUnFollowTheirFollowings',
	async ({ user, type, isMe }, { dispatch, rejectWithValue }) => {
		if (isMe) {
			dispatch(updateFollowStatus({ key: 'followingTotal', amount: type === 'follow' ? 1 : -1 }));
		}
		try {
			const apiAction = type === 'follow' ? 'put' : 'delete';
			const response = await api[apiAction](`accounts/follow/${user?.uuid}`);
			return response.data;
		} catch (error) {
			if (isMe) {
				dispatch(updateFollowStatus({ key: 'followingTotal', amount: type === 'follow' ? -1 : 1 }));
			}
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestFollowUnFollowDestinationAccount = createAsyncThunk(
	'requestFollowUnFollowDestinationAccount',
	async ({ uid, type, isMe }, { dispatch, rejectWithValue }) => {
		if (isMe) {
			dispatch(updateFollowStatus({ key: 'followingTotal', amount: type === 'follow' ? 1 : -1 }));
		}
		try {
			const apiAction = type === 'follow' ? 'post' : 'delete';
			const response = await api[apiAction](`destinations/${uid}/follow`);
			return response.data;
		} catch (error) {
			if (isMe) {
				dispatch(updateFollowStatus({ key: 'followingTotal', amount: type === 'follow' ? -1 : 1 }));
			}
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestRemoveFollower = createAsyncThunk(
	'requestRemoveFollower',
	async (uuid, { rejectWithValue, dispatch }) => {
		dispatch(updateFollowStatus({ key: 'followers', amount: -1 }));
		try {
			const response = await api.delete(`accounts/follow/force/${uuid}`);
			return response.data;
		} catch (error) {
			dispatch(updateFollowStatus({ key: 'followers', amount: 1 }));
			return rejectWithValue(handleSliceError(error));
		}
	},
);
