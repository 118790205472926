import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';

export const requestRecommendations = createAsyncThunk(
	'requestRecommendations',
	async (match, { rejectWithValue }) => {
		try {
			if (match.word) {
				const response = await api.get(`content/v0/users/search?q=${match.word}`);
				return response.data;
			}
			return null;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},

);

export const recommendationsSlice = createSlice({
	name: 'recommendation',
	initialState: {
		recommendations: null,
		errors: null,
	},
	extraReducers: {
		[requestRecommendations.fulfilled]: (state, action) => {
			state.recommendations = action.payload;
		},
		[requestRecommendations.rejected]: (state, action) => {
			state.errors = action.payload;
		},
	},
});

const { name, reducer } = recommendationsSlice;

export const getSlice = (state) => state[name];
export const getRecommendations = createSelector(getSlice, (slice) => slice?.recommendations);
injectReducer(name, reducer);
