/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import useTheme from 'rds/theme/useRdsTheme';
import counter from 'utils/str-counter';
import MaxLengthCaption from './MaxLengthCaption';
import styles from './styles';

const TIME_OUT = 650;

const getDimensions = (textarea) => {
	const shadowDiv = textarea.cloneNode();
	const lineDiv = document.createElement('div');
	const parent = textarea.parentElement;
	const rect = parent.getBoundingClientRect();

	const targetStyles = window.getComputedStyle(textarea);
	lineDiv.style.fontSize = targetStyles.fontSize;
	lineDiv.style.fontFamily = targetStyles.fontFamily;
	lineDiv.style.fontWeight = targetStyles.fontWeight;
	lineDiv.style.fontStyle = targetStyles.fontStyle;
	lineDiv.style.letterSpacing = targetStyles.letterSpacing;
	lineDiv.style.textTransform = targetStyles.textTransform;
	lineDiv.style.width = `${rect.width}px`;
	lineDiv.style.display = 'inline';
	lineDiv.style.position = 'relative';
	lineDiv.style.visibility = 'hidden';
	lineDiv.style.overflow = 'scroll';
	lineDiv.style.whiteSpace = 'pre-wrap';
	lineDiv.style.height = null;
	lineDiv.innerText = textarea.value || textarea.getAttribute('placeholder');
	parent.appendChild(lineDiv);
	const width = lineDiv.getBoundingClientRect().width + 8;
	lineDiv.remove();

	shadowDiv.value = shadowDiv.value || shadowDiv.getAttribute('placeholder');
	shadowDiv.setAttribute('rows', 1);
	shadowDiv.style.position = 'fixed';
	shadowDiv.style.visibility = 'hidden';
	shadowDiv.style.height = null;
	shadowDiv.style.width = `${width}px`;
	parent.appendChild(shadowDiv);

	const rowHeight = shadowDiv.clientHeight;
	shadowDiv.style.height = '1px';
	const absoluteHeight = shadowDiv.scrollHeight - 1;
	shadowDiv.remove();
	const rows = Math.ceil(absoluteHeight / rowHeight);

	return {
		rows,
		width,
	};
};

const resizeTextarea = (textarea) => {
	if (!textarea) {
		return;
	}
	const { rows, width } = getDimensions(textarea);
	textarea.setAttribute('rows', rows);
	textarea.style.width = `${width}px`;
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		marginTop: theme.spacing(-2),
	},
	container: styles.root(theme),
	input: styles.input(theme),
	input_contrast: styles.inputContrast(theme),
	input_secondary: styles.inputSecondary(theme),
	input_primary: styles.inputPrimary(theme),
}));

export default ({
	onChange,
	value,
	color = 'primary',
	disableUnderline,
	maxLength,
	fixedMaxLengthCaption = false,
	blur,
	open,
	...rest
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const divRef = useRef();

	const containerKlass = clsx(classes.container, classes[`container_${color}`]);
	const inputKlass = clsx(classes.input, classes[`input_${color}`]);
	const [focused, setFocused] = useState(false);
	const [isKeyboardOpen, setIsKeyboardOpen] = useState(true);

	const initialInnerHeight = window.innerHeight;

	useEffect(() => {
		if (open) {
			window.addEventListener('resize', () => {
				if (window.innerHeight < initialInnerHeight) {
					setIsKeyboardOpen(true);
				} else {
					setIsKeyboardOpen(false);
				}
			});
		}
	}, [open]);

	const containerStyle = {};
	if (disableUnderline) {
		containerStyle.borderBottomWidth = 0;
	}

	useEffect(() => {
		if (open && isKeyboardOpen) {
			const interval = setInterval(() => {
				if (divRef.current && document.activeElement !== divRef.current) {
					divRef.current.focus();
				}
			}, TIME_OUT);

			return () => {
				clearInterval(interval);
			};
		}
		divRef.current.blur();

		return null;
	}, [open, isKeyboardOpen]);

	useEffect(() => {
		if (divRef?.current) {
			resizeTextarea(divRef.current);
			setTimeout(() => {
				resizeTextarea(divRef.current);
			}, 10);
		}
	}, [value, divRef?.current]);

	return (
		<div className={classes.root}>
			{fixedMaxLengthCaption && focused
				&& (
					<MaxLengthCaption
						color={color}
						fixed={fixedMaxLengthCaption}
						maxLength={maxLength}
						value={counter(value)}
						bottom={-theme.spacing(4.5)}
					/>
				)}
			<div className={containerKlass} style={containerStyle}>
				<textarea
					ref={divRef}
					style={{ resize: 'none !important' }}
					rows={1}
					value={value}
					onClick={(e) => {
						setIsKeyboardOpen(true);
						if (!e.target.focused) {
							e.target.selectionStart = e.target.value.length;
							e.target.selectionEnd = e.target.value.length;
						}
					}}
					onBlur={(e) => {
						e.preventDefault();
						e.target.focused = false;
						if (blur) {
							blur();
						}
						setFocused(false);
					}}
					onFocus={(e) => {
						e.stopPropagation();
						setTimeout(() => {
							e.target.focused = true;
							e.target.setSelectionRange(e.target.value.length, e.target.value.length);
							setFocused(true);
							e.target.focus();
						}, 1);
					}}
					onChange={(e) => {
						e.target.value = e.target.value.replace(/^\s*[\r\n]/gm, '');
						onChange(e);
					}}
					type="text"
					maxLength={maxLength}
					className={inputKlass}
					{...rest}
				/>
			</div>
		</div>
	);
};
