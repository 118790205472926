/* eslint-disable no-console */
import EventEmitter from 'events';
import { convertHtmlToPng } from './utils/html-to-image';

const canvasToBlob = (canvas) => new Promise((resolve) => canvas.toBlob(resolve));

export default class BaseNode extends EventEmitter {
	constructor(canvas, item) {
		super();

		this.canvas = canvas;

		if (!item) {
			return;
		}

		this.id = item.id;
		this.data = item.data;
		this.ignoreStyles = this.data.ignoreStyles || [];
		this.styles = item.styles || {};
		this.locked = item.locked;
		this.background = item.isBackground();
		this.root = item.isRoot();
		this.z = item.z;
	}

	get dimensions() {
		const rect = this.canvas.container.getBoundingClientRect();

		return {
			height: `${rect.height}px`,
			width: `${rect.width}px`,
		};
	}

	async getFileData({
		count,
		modifier,
		shadowContainer,
	}) {
		const el = this.el.cloneNode(true);

		shadowContainer.appendChild(el);
		const angle = this.getRotation(el);
		const scale = this.getScale(el);

		const size = {};
		const position = {};

		el.style.removeProperty('transform');
		const rect = el.getBoundingClientRect();
		el.style.transform = `rotate(${angle}deg)`;

		const containerRect = shadowContainer.getBoundingClientRect();

		// true center
		const centerPercent = {
			x: (rect.x + (rect.width / 2)) / containerRect.width,
			y: (rect.y + (rect.height / 2)) / containerRect.height,
		};

		// center point
		const center = {
			x: (containerRect.width * modifier) * centerPercent.x,
			y: (containerRect.height * modifier) * centerPercent.y,
		};

		// find the top left
		const topLeftCorner = {
			x: center.x - ((rect.width * scale * modifier) / 2),
			y: center.y - ((rect.height * scale * modifier) / 2),
		};

		let trueHeight = rect.height;
		let trueWidth = rect.width;

		if (this.data.frames) {
			const naturalHeight = this?.data?.frames
				? this.data.frames[0].dims.height
				: rect.height;
			const naturalWidth = this?.data?.frames
				? this.data.frames[0].dims.width
				: rect.width;

			// if wider than tall
			if (naturalWidth > naturalHeight) {
				trueWidth = rect.width;
				trueHeight = rect.height * (naturalHeight / naturalWidth);
				topLeftCorner.y += Math.round((rect.height - trueHeight) / 2);
			} else {
				trueHeight = rect.height;
				trueWidth = rect.width * (naturalWidth / naturalHeight);
				topLeftCorner.x += Math.round((rect.width - trueWidth) / 2);
			}
		}

		size.height = Math.round(trueHeight * modifier * scale);
		size.width = Math.round(trueWidth * modifier * scale);
		position.x = Math.round(topLeftCorner.x);
		position.y = Math.round(topLeftCorner.y);

		if (size.height % 2 === 1) { size.height += 1; }
		if (size.width % 2 === 1) { size.width += 1; }

		const filename = `${count}-${this.type}.${this.ext}`;

		let sourceData;

		const rotation = angle * (Math.PI / 180);

		if (this?.data?.asset) {
			sourceData = this.data.asset.blob;
		} else if (this?.data?.blob) {
			sourceData = this.data.blob;
		} else if (this?.data?.src) {
			const response = await fetch(this.data.src);
			sourceData = await response.blob();
		} else {
			el.style.removeProperty('transform');
			const f = this.fonts[el.style['font-family']];
			const fonts = f ? [f] : [];

			if (!size.width || !size.height) {
				return null;
			}

			let image = await convertHtmlToPng(
				el, {
					fonts,
				},
			);

			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			canvas.width = image.width * modifier * scale;
			canvas.height = image.height * modifier * scale;
			context.drawImage(image, 0, 0, canvas.width, canvas.height);
			image.remove();
			image = null;
			sourceData = await canvasToBlob(canvas);
		}

		if (!size.width || !size.height) {
			return null;
		}

		return {
			asset: this.data.asset,
			start: this.data.start,
			stop: this.data.end,
			background: this.background,
			filename,
			size,
			rotation,
			position,
			sourceData,
			type: this.type,
		};
	}

	restart() { }

	setElAttributes() {
		if (this?.el && this?.data?.attributes) {
			Object.keys(this.data.attributes).forEach((key) => {
				this.el.setAttribute(key, this.data.attributes[key]);
			});
		}
	}

	setAttributes() {
		this.locked ? this.lock() : this.unlock();
		this.root ? this.makeRoot() : null;
		this.background || this.canvas.store.background === this.id ? this.makeBackground() : null;
	}

	makeBackground() {
		this.el.setAttribute('background', true);
	}

	makeRoot() {
		this.el.setAttribute('root', true);
	}

	lock() {
		this.el.setAttribute('locked', true);
	}

	unlock() {
		this.el.removeAttribute('locked');
	}

	update(item) {
		['data', 'styles', 'z'].forEach((k) => {
			if (typeof item[k] !== 'undefined') {
				this[k] = item[k];
			}
		});

		Object.keys(this.styles).forEach((k) => {
			if (this.ignoreStyles.indexOf(k) === -1) {
				this.el.style[k] = this.styles[k];
			}
		});
	}

	drawFrame() {
		const item = this.canvas.store.get(this.id);
		if (this.el.style.zIndex !== item.z) {
			this.z = item.z;
			this.el.style.zIndex = item.z;
		}
		if (this.drawn) {
			return;
		}

		this.el.setAttribute('data-object-id', this.id);
		this.canvas.container.appendChild(this.el);
		this.drawn = true;
	}

	getScale(el) {
		const parts = el.style.transform.split(' ');
		let scale;

		for (let i = 0; i < parts.length; i++) {
			if (parts[i].slice(0, 5) === 'scale') {
				scale = parseFloat(parts[i].slice(6, -1));
			}
		}

		return scale || 1;
	}

	getRotation(el) {
		const parts = el.style.transform.split(' ');
		let angle;

		for (let i = 0; i < parts.length; i++) {
			if (parts[i].slice(0, 6) === 'rotate') {
				angle = parseFloat(parts[i].slice(7, -4));
			}
		}

		return angle || 0;
	}

	rotateAndDraw(context, img, el, x, y, w, h, multiplier = 1) {
		const angle = this.getRotation(el);
		const scale = this.getScale(el);
		x *= multiplier;
		y *= multiplier;
		w *= multiplier;
		h *= multiplier;

		const rads = angle * (Math.PI / 180);
		context.save();
		context.translate(x, y);
		context.translate(w / 2, h / 2);
		context.rotate(rads);
		context.drawImage(img,
			0,
			0,
			img.width,
			img.height,
			(-w * scale) / 2,
			(-h * scale) / 2,
			w * scale,
			h * scale);

		context.restore();
	}

	destroy() {
		this.el.remove();
	}

	renderFrame() { }

	setupDraw() { }

	async setupCompile(canvas, context, shadowContainer) {
		this.compileContext = {
			canvas,
			context,
			shadowContainer,
		};
	}
}
