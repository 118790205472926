import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';

export const requestCreateCustomDestination = createAsyncThunk(
	'requestCreateCustomDestination',
	async (destination, { rejectWithValue }) => {
		try {
			const response = await api.post('destinations/custom', { title: destination.title });
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},

);

export const customDestinationSlice = createSlice({
	name: 'customDestination',
	initialState: {

	},
});

const { name, reducer } = customDestinationSlice;

export const getSlice = (state) => state[name];

injectReducer(name, reducer);
