import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import api from 'services/api';

export const requestReactionCounts = createAsyncThunk(
	'requestReactionCounts',
	async (uid, { rejectWithValue }) => {
		const testUid = '0x346137';
		try {
			const response = await api.get(`experiences/${testUid}/reactions/counts`);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorsFromRejectedRequest(error));
		}
	},
);

export const requestReactionUserList = createAsyncThunk(
	'requestReactionUserList',
	async (uid, { rejectWithValue }) => {
		const testUid = '0x346137';
		try {
			const response = await api.get(`experiences/${testUid}/reactions`);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorsFromRejectedRequest(error));
		}
	},
);

export const requestDeleteExperience = createAsyncThunk(
	'requestDeleteExperience',
	async (uid, { rejectWithValue }) => {
		try {
			const response = await api.delete(`experiences/${uid}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorsFromRejectedRequest(error));
		}
	},
);

export const requestCollection = createAsyncThunk(
	'requestCollection',
	async (uid, { rejectWithValue }) => {
		const collection = '6ef720a0-8244-412a-b2f5-395a775fe0a3';
		try {
			const response = await api.get(`experiences/users/${collection}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorsFromRejectedRequest(error));
		}
	},
);

const initialState = {
	collections: [],
	currentExperience: 0,
	activities: null,
	clips: null,
	collection: null,
	coverClip: null,
	lastModified: null,
	published: null,
	closedCaption: false,
	publishedHuman: null,
	captionRtml: null,
	route: null,
	isCreator: false,
	loading: false,
	errors: null,
	destinations: null,
	currentClipType: 'image',
	formTitle: null,
	reportedComment: null,
	reactionsCount: null,
};

const getCurrentActiveExperience = (collections, uid) => {
	const activeExperience = collections.map((experience) => {
		if (experience.uid === uid) {
			return activeExperience;
		}
		return null;
	});
};

const experienceViewSlice = createSlice({
	name: 'experienceView',
	initialState: { ...initialState },
	reducers: {
		change: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
	},
	extraReducers: {
		[requestDeleteExperience.fulfilled]: (state) => {
			state.loading = false;
			state.errors = null;
		},
		[requestDeleteExperience.rejected]: (state, action) => {
			state.errors = action.payload;
		},
		[requestReactionCounts.fulfilled]: (state, action) => {
			state.reactionsCount = action.payload;
		},
		[requestReactionCounts.rejected]: (state, action) => {
			state.errors = action.payload;
		},
		[requestCollection.fulfilled]: (state, action) => {
			state.collections = action.payload.experiences;
		},
		[requestCollection.rejected]: (state, action) => {
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = experienceViewSlice;
const { change, reset } = actions;

export { name, change, reset };

export const getSlice = (state) => state[name];
export const getCollections = (index) => createSelector(getSlice, (slice) => {
	const previous = slice?.collections[index - 1];
	const current = slice?.collections[index];
	const next = slice?.collections[index + 1];
	if (!previous && !current && !next) {
		return null;
	}

	if (!previous) {
		return [current, next];
	}
	return [previous, current, next];
});
export const getExperienceTitle = (uid) => createSelector(getSlice, (slice) => {
	const { title } = getCurrentActiveExperience(slice?.collections, uid);
	return title;
});
export const getExperienceUser = (uid) => createSelector(getSlice, (slice) => {
	const { user } = getCurrentActiveExperience(slice?.collections, uid);
	return user;
});
export const getExperienceUid = (id) => createSelector(getSlice, (slice) => {
	const { uid } = getCurrentActiveExperience(slice?.collections, id);
	return uid;
});
export const getExperienceActivities = (uid) => createSelector(getSlice, (slice) => {
	const { activities } = getCurrentActiveExperience(slice?.collections, uid);
	return activities;
});
export const getExperienceDestination = (uid) => createSelector(getSlice, (slice) => {
	const { destinations } = getCurrentActiveExperience(slice?.collections, uid);
	return destinations;
});
export const getExperienceRecommendedFor = (uid) => createSelector(getSlice, (slice) => {
	const { recommendedFor } = getCurrentActiveExperience(slice?.collections, uid);
	return recommendedFor;
});
export const getExperienceIdentifiers = (uid) => createSelector(getSlice, (slice) => {
	const { identifiers } = getCurrentActiveExperience(slice?.collections, uid);
	return identifiers;
});
export const getExperienceClips = (uid) => createSelector(getSlice, (slice) => {
	const { clips } = getCurrentActiveExperience(slice?.collections, uid);
	return clips;
});
export const getExperienceLastModified = (uid) => createSelector(getSlice, (slice) => {
	const { lastModified } = getCurrentActiveExperience(slice?.collections, uid);
	return lastModified;
});
export const getPublishedHuman = (uid) => createSelector(getSlice, (slice) => {
	const { publishedHuman } = getCurrentActiveExperience(slice?.collections, uid);
	return publishedHuman;
});

export const getRoute = createSelector(getSlice, (slice) => slice?.route);
export const getIsCreator = createSelector(getSlice, (slice) => slice?.isCreator);
export const getExperienceCaption = createSelector(getSlice, (slice) => slice?.captionRtml);
export const getExperienceClosedCaption = createSelector(getSlice, (slice) => slice?.closedCaption);
export const getFormTitle = createSelector(getSlice, (slice) => slice?.formTitle);
export const getReportedComment = createSelector(getSlice, (slice) => slice?.reportedComment);
export const getReactionsCounts = createSelector(getSlice, (slice) => slice?.reactionsCount);
export const getCurrentExperience = createSelector(getSlice, (slice) => slice?.currentExperience);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);

export default experienceViewSlice;

injectReducer(name, reducer);
