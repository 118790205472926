import React, { useState } from 'react';
import Buffering from './Buffering';
import Image from './Image';
import TouchRegions from './TouchRegions';
import Video from './Video';

export default ({
	active,
	activeClip,
	activeExperience,
	experienceUid,
	clip,
	play,
	playCount,
	preload,
	onTimeUpdate,
	onEnd,
	time,
}) => {
	const [buffered, setBuffered] = useState(false);

	const asset = (clip.type === 'photo') ? (
		<Image
			experienceUid={experienceUid}
			active={active}
			activeClip={activeClip}
			activeExperience={activeExperience}
			play={play}
			src={clip?.outputs?.photo?.large?.jpg}
			placeHolder={clip?.outputs?.photo?.xs?.jpg}
			id={clip.clipUid}
			time={time}
			onTimeUpdate={onTimeUpdate}
			onEnd={onEnd}
			playCount={playCount}
			onError={() => {
				setBuffered(true);
			}}
		/>
	) : (
		<Video
			experienceUid={experienceUid}
			active={active}
			activeClip={activeClip}
			activeExperience={activeExperience}
			play={play}
			playCount={playCount}
			preload={preload}
			onError={() => {
				setBuffered(true);
			}}
			src={clip?.outputs?.video?.large?.mp4}
			poster={clip?.outputs?.thumbnail?.large?.webp}
			id={clip.clipUid}
			time={time}
			onTimeUpdate={onTimeUpdate}
			onEnd={onEnd}
		/>
	);

	return (
		<div>
			{active && <TouchRegions {...clip} />}
			{asset}

			{
				activeClip && activeExperience
					? (
						<Buffering
							clipUid={clip.clipUid}
							buffered={buffered}
							play={play}
							time={time}
						/>
					) : null
			}

		</div>
	);
};
