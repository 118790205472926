import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import longPressScale from 'lib/long-press-scale';
import store, { injectReducer } from 'services/store';

let contextMenuRef = null;

export const applyContextMenu = (el, options, { done, ignoreWidth = false }) => {
	longPressScale(el, (sa) => {
		const cl = el.cloneNode(true);
		const rect = el.getBoundingClientRect();
		cl.style.position = 'fixed';
		cl.style.left = `${rect.left}px`;
		cl.style.top = `${rect.top}px`;
		if (!ignoreWidth) {
			cl.style.width = `${rect.width}px`;
			cl.style.height = `${rect.height}px`;
		}
		store.dispatch(open({
			el,
			clonedElement: cl,
			options,
		}));
		sa.snapback();
	}, (opts) => {
		done && done(opts);
	});
};

export const open = createAsyncThunk(
	'openContextMenu',
	async ({
		el,
		clonedElement,
		options,
	}) => {
		contextMenuRef = {
			el,
			clonedElement,
			options,
		};
	},
);

export const contextMenuSlice = createSlice({
	name: 'contextMenu',
	initialState: {
		open: false,
	},
	reducers: {
		close: (state) => {
			state.open = false;
		},
	},
	extraReducers: {
		[open.fulfilled]: (state) => {
			state.open = true;
		},
	},
});

const { name, reducer, actions } = contextMenuSlice;
const { close } = actions;

export { close };

export const getSlice = (state) => state[name];
export const get = createSelector(getSlice, (slice) => (slice?.open ? contextMenuRef : false));

injectReducer(name, reducer);
