import {
	createAsyncThunk,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import { change } from 'app/slices/experience-view';
import { updateExperience } from 'app/slices/experience-modal';
import { notify } from 'app/slices/notifications/notifications';
import handleSliceError from 'utils/handle-slice-error';

export const requestUpdateExperienceDestination = createAsyncThunk(
	'requestUpdateExperienceDestination',
	async ({
		experience,
		destination,
		custom,
	}, { rejectWithValue, dispatch }) => {
		const { uid } = experience;
		try {
			let customDes;
			if (custom) {
				customDes = await api.post('destinations/custom', { title: destination.title });
			}
			const updatedExperience = { ...experience };
			updatedExperience.destinations = [custom ? customDes.data : destination];
			dispatch(change({ route: null }));
			dispatch(updateExperience(updatedExperience));
			await api.post(`experiences/${uid}/destination`, {
				destination: custom ? customDes.data.uid : destination ? destination.uid : null,
			});
			return uid;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestUpdateExperience = createAsyncThunk(
	'requestUpdateExperience',
	async ({ uid, clips }, { rejectWithValue, getState, dispatch }) => {
		const {
			activities,
			recommendedFor,
			title,
			caption,
			collections,
		} = getState().editExperience;

		try {
			const response = await api.patch(`experiences/${uid}`, {
				activities: activities.map((a) => a.uid),
				recommendedFor: recommendedFor.map((a) => a.uid),
				title: title || '',
				caption: caption || '',
				identifiers: collections,
				clips: clips.map((c) => c.clipUid),
				publish: true,
				temporaryFlagPreRendered: false,
			});
			dispatch(change({ route: null }));
			dispatch(updateExperience(response.data));
			dispatch(notify({
				severity: 'info',
				description: 'Experience details updated.',
			}));
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error, { defaultMessage: 'Failed to save experience' }));
		}
	},
);

const initialState = {
	errors: null,
	loading: false,
	destination: null,
	title: '',
	caption: '',
	activities: [],
	recommendedFor: [],
	closeCaptioning: false,
	hasBeenEdited: false,
	showActivitiesRequiredError: false,
	collections: [],
	identifiers: null,
	modifiedExperienceId: null,
};

export const editExperienceSlice = createSlice({
	name: 'editExperience',
	initialState,
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		changeTitle: (state, action) => {
			state.title = action.payload;
			state.hasBeenEdited = true;
		},
		changeCaption: (state, action) => {
			state.caption = action.payload;
			state.hasBeenEdited = true;
		},
		changeDestination: (state, action) => {
			state.destination = action.payload;
			state.hasBeenEdited = true;
		},
		addActivities: (state, action) => {
			if (state.activities.length < 5) {
				state.activities = state.activities.concat(action.payload);
			}
			state.hasBeenEdited = true;
		},
		removeActivities: (state, action) => {
			state.activities = state.activities.filter((activity) => activity.title
				!== action.payload.title);
			state.hasBeenEdited = true;
		},
		showActivitySection: (state, action) => {
			state.showActivities = action.payload;
		},

		addRecommendedFor: (state, action) => {
			state.recommendedFor = state.recommendedFor.concat(action.payload);
			state.hasBeenEdited = true;
		},
		removeRecommendedFor: (state, action) => {
			state.recommendedFor = state.recommendedFor.filter((rm) => rm.title
				!== action.payload.title);
			state.hasBeenEdited = true;
		},
		addCollections: (state, action) => {
			state.collections = state.collections.concat(action.payload);
			state.hasBeenEdited = true;
		},
		removeCollections: (state, action) => {
			state.collections = state.collections.filter((rm) => rm
				!== action.payload);
			state.hasBeenEdited = true;
		},
		toggleCloseCaptioning: (state) => {
			state.closeCaptioning = !state.closeCaptioning;
			state.hasBeenEdited = true;
		},
	},
	extraReducers: {
		[requestUpdateExperience.pending]: (state) => {
			state.loading = true;
		},
		[requestUpdateExperience.fulfilled]: (state) => {
			state.loading = false;
		},
		[requestUpdateExperience.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestUpdateExperienceDestination.fulfilled]: (state, action) => {
			state.modifiedExperienceId = action.payload;
		},
	},
});

const { name, reducer, actions } = editExperienceSlice;
const {
	set,
	addActivities,
	removeActivities,
	changeCaption,
	changeTitle,
	changeDestination,
	addRecommendedFor,
	removeRecommendedFor,
	addCollections,
	removeCollections,
	reset,
	toggleCloseCaptioning,
} = actions;
export {
	set,
	addActivities,
	removeActivities,
	changeCaption,
	changeTitle,
	changeDestination,
	addRecommendedFor,
	removeRecommendedFor,
	addCollections,
	removeCollections,
	reset,
	toggleCloseCaptioning,
};

export const getSlice = (state) => state[name];

export const getDestination = createSelector(getSlice, (slice) => slice?.destination);
export const getSelectedActivities = createSelector(getSlice, (slice) => slice?.activities);
export const getSelectedRecommendedFor = createSelector(getSlice, (slice) => slice?.recommendedFor);
export const getEditedTitle = createSelector(getSlice, (slice) => slice?.title);
export const getEditedCaption = createSelector(getSlice, (slice) => slice?.caption);
export const selectCloseCaptioning = createSelector(getSlice, (slice) => slice?.closeCaptioning);
export const getSelectedCollections = createSelector(getSlice, (slice) => slice?.collections);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getHasBeenEdited = createSelector(getSlice, (slice) => slice?.hasBeenEdited);
export const getModifiedExperienceId = createSelector(getSlice, (slice) => slice?.modifiedExperienceId);
export const getShowActivitiesRequiredError = createSelector(getSlice, (slice) => slice?.showActivitiesRequiredError);
injectReducer(name, reducer);
