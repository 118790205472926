import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import api from 'services/api';
import { notify, notifyErrors } from 'app/slices/notifications/notifications';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import { useNavigate, useParams } from 'react-router-dom';

export default () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token: paramsToken } = useParams();

	const token = paramsToken;
	useEffect(async () => {
		if (token) {
			try {
				const response = await api.patch('accounts/emails/unsubscribe', {
					token,
				});
				dispatch(notify({
					title: response.data.message,
				}));
				navigate('/');
			} catch (err) {
				const errors = getErrorsFromRejectedRequest(err);
				notifyErrors(errors);
				navigate('/account');
			}
		}
	}, []);

	return null;
};
