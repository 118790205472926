const SCROLL_THRESHOLD = 50;

export default class Scrolling {
	constructor(container) {
		this.container = container;
		this._scrollLeft = 0;
		this.container.addEventListener('scroll', this._updateScrollLeft.bind(this), { passive: true });
		window.addEventListener('resize', this._updateScrollLeft.bind(this), { passive: true });
		this.__ticking = false;
	}

	get _innerWidth() {
		if (this.__innerWidth) {
			return this.__innerWidth;
		}

		this.__innerWidth = window.innerWidth;
		return this.__innerWidth;
	}

	_updateScrollLeft() {
		const { width } = this.container.getBoundingClientRect();
		this._scrollLeft = this.container.scrollLeft;
		this._containerWidth = width;
	}

	_tick(direction) {
		if (this.__paused) {
			return;
		}

		if (direction < 0 && this._scrollLeft <= 0) {
			return;
		}

		this.container.scrollLeft += direction;
		setTimeout(() => {
			this._tick(direction);
		}, this.__distance);
	}

	resume(direction, distance) {
		this.__paused = false;
		this.__distance = Math.abs(Math.ceil(distance * 2));

		if (this.__ticking === direction) {
			return;
		}

		this._tick(direction);
	}

	pause() {
		this.__paused = true;
	}

	update(clientX) {
		if (this._scrollLeft > 0 && clientX < SCROLL_THRESHOLD) {
			this.resume(-1, clientX - 0);
		} else if (this._innerWidth - clientX < SCROLL_THRESHOLD) {
			this.resume(1, this._innerWidth - clientX);
		} else {
			this.pause();
		}
	}

	destroy() {
		window.removeEventListener('resize', this._updateScrollLeft);
		this.container.removeEventListener('scroll', this._updateScrollLeft);
	}
}
