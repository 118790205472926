import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	errorRoot: {
		background: theme.colors.neutral.C900,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		marginTop: theme.spacing(3),
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	header: {
		marginTop: theme.spacing(3),
		color: theme.colors.neutral.C400,
	},
	retry: {
		marginLeft: theme.spacing(2),
		color: theme.colors.primary.C600,
	},
	refresh: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
}));

export default ({
	show,
	title,
	onRetry,
	size,
}) => {
	const classes = useStyles();

	if (!show) {
		return null;
	}

	const style = {};

	if (size === 'small') {
		style.height = '12vh';
	} else {
		style.height = '24vh';
	}

	return (
		<div className={classes.errorRoot} style={style}>
			<div className={classes.container}>
				<Typography className={classes.header} variant="body2">
					{title}
				</Typography>
				<div
					className={classes.refresh}
					onClick={() => {
						if (onRetry) {
							onRetry();
						}
					}}
				>
					<IconV3
						icon="Refresh"
						color="primaryC600"
						size={12}
						containerSize={12}
					/>
					<Typography
						variant="body2"
						className={classes.retry}
					>
						Retry
					</Typography>
				</div>
			</div>
		</div>
	);
};
