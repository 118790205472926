import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SELECT_CANVAS, SELECT_OBJECT } from 'lib/editing-canvas/src/event-types';
import { HTML } from 'lib/editing-canvas/src/types';
import { setEditingNode } from 'app/slices/content-creation/create/text-component';
import { selectEditingCanvas } from 'app/slices/content-creation/editing-canvas';
import createElement from 'utils/create-element';
import Modal from './Modal';
import Recommendations from './Recommendations';
import StylePicker from './StylePicker';
import { COMMON_STYLES } from './styles';

export default () => {
	const dispatch = useDispatch();
	const editingCanvas = useSelector(selectEditingCanvas);

	const selectCanvas = async (e) => {
		const { clientX, clientY } = e.lastEvent.touches[0];

		const div = createElement({
			tagName: 'div',
			attributes: {
				contenteditable: 'true',
				autocomplete: 'off',
				placeholder: 'Tap to type text',
				class: 'tap',
			},
		});

		const styles = {
			...COMMON_STYLES,
			left: `${clientX}px`,
			top: `${clientY}px`,
		};

		Object.keys(styles).forEach((key) => {
			div.style[key] = styles[key];
		});

		const node = await editingCanvas.add('html', {
			el: div,
		}, {
			// ignoreStyles: ['width'],
		});
		editingCanvas.compilePreview();

		const el = await editingCanvas.waitForEl(node.item.id);

		dispatch(
			setEditingNode({
				el,
				id: node.item.id,
			}),
		);
	};

	const selectObject = (e, object) => {
		const { el, id, node } = object;

		if (node.type === HTML) {
			dispatch(
				setEditingNode({
					el,
					id,
				}),
			);
		}
	};

	useEffect(() => {
		if (!editingCanvas) {
			return () => { };
		}

		editingCanvas.addListener(SELECT_CANVAS, selectCanvas);
		editingCanvas.addListener(SELECT_OBJECT, selectObject);

		return () => {
			editingCanvas.removeListener(SELECT_CANVAS, selectCanvas);
			editingCanvas.removeListener(SELECT_OBJECT, selectObject);
		};
	}, [editingCanvas]);

	return (
		<>
			<Modal />
			<Recommendations />
			<StylePicker />
		</>
	);
};
