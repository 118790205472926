import eventEmitter from 'events';

const GRACE_THRESHOLD = 100;

export default class Store extends eventEmitter {
	constructor() {
		super();
		this.down = 0;
		this.downEvent = null;
		this.hasInteracted = false;
		this.lastEvent = null;
		this.start = null;
		this.target = null;
	}

	get interacting() {
		return this.down > 0;
	}

	interacted() {
		this.hasInteracted = true;
	}

	withinGracePeriod() {
		if (this.start) {
			return false;
		}

		return Date.now() - this.start <= GRACE_THRESHOLD;
	}

	addGesture(target, e) {
		if (e.touches.length > 1) {
			this.interacted();
		}

		if (!this.lastEvent) {
			this.lastEvent = e;
		}

		this.down++;

		if (this.down === 1) {
			this.start = Date.now();
			this.target = target;
			this.target.originalPosition = this.target.getBoundingClientRect();
		}

		this.emit('add', target);
	}

	checkInteraction(e) {
		if (this.hasInteracted) {
			return;
		}

		const cp = e.target.getBoundingClientRect();
		const op = this.target.originalPosition;
		const dx = cp.left - op.left;
		const dy = cp.top - op.top;
		const distance = Math.sqrt((dx * dx) + (dy * dy));
		if (distance > 1) {
			this.interacted();
		}
	}

	removeGesture() {
		if (this.target) {
			this.target.filterMove = false;
		}

		this.target = null;
		this.lastEvent = null;
		this.down = 0;
		if (!this.down) {
			this.reset();
		}
		this.emit('remove');
	}

	reset() {
		this.start = null;
		this.down = 0;
		this.target = null;
		this.hasInteracted = false;
		this.emit('reset');
	}

	getId(target) {
		return parseInt(target.getAttribute('data-object-id'), 10);
	}

	update(target) {
		const id = this.getId(target);
		if (!id) {
			return;
		}

		const styles = {};
		const parts = target.style.cssText.split(';');

		for (let i = 0; i < parts.length; i++) {
			if (!parts[i]) {
				continue;
			}
			const style = parts[i].split(':');
			styles[style[0].trim()] = style[1].trim();
		}

		this.emit('update', id, { styles });
	}
}
