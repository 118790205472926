import React from 'react';
import {
	makeStyles, Typography,
} from '@material-ui/core';
import Form from 'rds/components/Layouts/Form';
import SubmitButton from 'routes/Auth/components/SubmitButton/SubmitButton';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		marginTop: '25vh',
		margin: '0 auto',
		maxWidth: 320,
		zIndex: 3,
	},
	title: {
		marginBottom: theme.spacing(5),
		textAlign: 'center',
	},
}));

const NotFound = () => {
	const classes = useStyles();

	return (
		<div>
			<Form className={classes.root}>
				<Typography className={classes.title} variant="h1">Oops, it’s a 404</Typography>
				<Link to="/">
					<SubmitButton>
						Home
					</SubmitButton>
				</Link>
			</Form>
		</div>
	);
};

export default NotFound;
