import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Header from 'components/Header';
import IconV3 from 'rds/components/IconV3';
import { useNavigate } from 'react-router-dom';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	header: {
		position: 'fixed',
		zIndex: 4,
		top: 0,
		left: 0,
		right: 0,
	},
	iconContainer: {
		position: 'absolute',
		top: 0,
		right: window.innerWidth < 390 ? 0 : theme.spacing(2),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 60,
		height: 60,
		zIndex: 4,
	},
	secondary: {
		top: 48,
	},
}));

export default () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const showUpdateBanner = useSelector(getIsUpdatedVersionAvailable);
	const klass = showUpdateBanner ? clsx(classes.iconContainer, classes.secondary) : clsx(classes.iconContainer);
	return (
		<div className={classes.header}>
			<Header title="Notifications" />
			{/* TODO, turn into RDS icon */}
			<div
				className={klass}
				onClick={() => {
					navigate('my-account/settings/notifications');
				}}
			>
				<IconV3
					icon="Settings"
					dropShadow
				/>
			</div>
		</div>
	);
};
