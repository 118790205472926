import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { selectEditingNode } from 'app/slices/content-creation/create/text-component';
import { getConstants } from 'app/slices/app/app';
import DrawerPicker from 'rds/components/DrawerPicker/DrawerPicker';
import { selectEditingCanvas } from 'app/slices/content-creation/editing-canvas';
import fontsMap from 'utils/fonts-map';
import PickerLayout from './PickerLayout';

const OFFSET = 58;

const useStyles = makeStyles({
	container: {
		position: 'relative',
		width: '100%',
	},
});

export default ({ editingCanvas }) => {
	const classes = useStyles();
	const ref = useRef();
	const constants = useSelector(getConstants);
	const editingNode = useSelector(selectEditingNode);
	editingCanvas = editingCanvas || useSelector(selectEditingCanvas);
	const getItems = () => {
		const arr = [];
		constants.fonts.forEach((f) => {
			arr.push(f);
		});
		return arr;
	};

	useEffect(() => {
		const fontsArray = getItems();
		ref.current.picker = new DrawerPicker(ref.current, fontsArray, 'font', OFFSET);
		ref.current.picker.on('selected', (item) => {
			if (!editingNode) {
				return;
			}

			const { el, id } = editingNode;
			el.style['font-family'] = item.label;
			el.setAttribute('font-uid', item.uid);

			editingCanvas.updateStyle(id, 'font-family', item.label);
			editingCanvas.updateStyle(id, 'font-family-encoded', fontsMap[item.label]);
		});

		if (editingNode) {
			ref.current.picker.selectItem(editingNode.el.getAttribute('font-uid'));
		}

		return () => {
			if (ref.current) {
				ref.current.picker.destroy();
				ref.current.picker = null;
			}
		};
	}, [ref, constants, editingNode]);

	if (!constants) {
		return null;
	}

	return (
		<PickerLayout>
			<div ref={ref} className={classes.container} />
		</PickerLayout>
	);
};
