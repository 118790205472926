import BasePattern from './base-pattern.js';

export default class SmallPattern extends BasePattern {
	reset() {
		this.cols = [{
			index: 1,
			size: 4,
			height: 0,
			itemCount: 0,
			type: 'just-experiences',
		}, {
			index: 2,
			size: 8,
			height: 0,
			itemCount: 0,
			type: 'mixed',
		}];
	}

	_getNextItem(col, experiences, destinations) {
		if (col.type === 'just-experiences') {
			return this._shiftHelper(col, 'experience', experiences, 'standard');
		}

		if (col.itemCount % 3 === 1) {
			if (!destinations?.length) {
				return this._shiftHelper(col, 'experience', experiences, 'big');
			}
			return this._shiftHelper(col, 'destination', destinations, 'wide');
		}

		if (col.itemCount % 3 === 0) {
			return this._shiftHelper(col, 'experience', experiences, 'big');
		}

		return this._shiftHelper(col, 'experience', experiences, 'buddy');
	}
}
