const error = require('./error');
const information = require('./information');
const neutral = require('./neutral');
const primary = require('./primary');
const secondary = require('./secondary');

module.exports = {
	error,
	information,
	neutral,
	primary,
	secondary,
};
