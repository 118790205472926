const easeInOutCubic = (x) => (x < 0.5 ? 4 * x * x * x : 1 - ((-2 * x + 2) ** 3) / 2);

export default class ScrollAnimation {
	constructor({
		maxDuration,
		el,
		left,
		done,
	}) {
		this._el = el;

		const distance = Math.abs(left - this._el.scrollLeft);
		const duration = Math.max(Math.floor(distance / 2), maxDuration);

		this.tick({
			distance,
			duration,
			startTime: window.performance.now(),
			startPosition: this._el.scrollLeft,
			left,
			done,
		});
	}

	tick(options) {
		const {
			distance,
			duration,
			left,
			startPosition,
			startTime,
			done,
		} = options;

		if (this._destroyed) {
			done && done();
			return;
		}

		const now = window.performance.now() - startTime;

		if (now >= duration) {
			this._el.scrollLeft = left;
			done && done();
			return;
		}

		const p = easeInOutCubic(now / duration);

		const move = p * distance;
		let final = (left > this._el.scrollLeft)
			? startPosition + move
			: startPosition - move;

		if (startPosition > left && final < left) {
			final = left;
		} else if (startPosition < left && final > left) {
			final = left;
		}

		this._el.scrollLeft = final;

		requestAnimationFrame(() => {
			this.tick(options);
		});
	}

	destroy() {
		this._destroyed = true;
	}
}
