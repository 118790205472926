import { createSelector } from '@reduxjs/toolkit';

export const getSlice = (state) => state.userAcccountV2InProgressExperiences;

export const getResponse = createSelector(getSlice, (slice) => slice.response);

export const getExpecting = createSelector(getSlice, (slice) => slice.expecting);

export const getInProgressExperiences = createSelector(getSlice, (slice) => {
	const experiencesByUid = {};

	slice.inProgressExperiences.forEach((experience) => {
		experiencesByUid[experience.uid] = experience;
	});

	slice.failedExperiences.forEach((experience) => {
		experiencesByUid[experience.uid] = { failed: true, ...experience };
	});

	const dedupedExperiences = Object.values(experiencesByUid);
	return dedupedExperiences.sort((a, b) => b.createdAt - a.createdAt);
});
