import React, { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import Thumbnail from 'rds/components/Thumbnail';
import clsx from 'clsx';
import Avatar from 'rds/components/Avatar';
import PaginationSkeleton from 'rds/components/Skeletons/PaginationSkeleton';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(16),
	},
	item: {
		position: 'relative',
		'&::before': {
			content: '""',
			display: 'block',
			paddingBottom: '177%',
			position: 'relative',
		},
	},
	itemContents: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		top: 0,
		paddingBottom: theme.spacing(2),
	},
	spacer: {
		marginBottom: theme.spacing(12),
	},
	avatarContainer: {
		position: 'absolute',
		top: theme.spacing(2),
		left: theme.spacing(2),
		boxShadow: '0px 2px 8px 1px rgba(0, 0, 0, 0.4)',
		borderRadius: '50%',
	},
	con: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
}));

export default ({
	height,
	items,
	gutters,
	width,
	xs = 4,
	longPressedOptions,
	title,
	titleSkeleton,
	avatar,
	requestLoadMore,
	loading,
	onClick,
	onTap,
	SkeletonComponent,
	destination,
	myIdentifiers,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const observer = useRef();

	const lastExperienceRef = useCallback((node) => {
		if (!requestLoadMore) {
			return;
		}
		if (observer?.current) {
			observer.current.disconnect();
		} else {
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					if (!loading) {
						dispatch(requestLoadMore());
					}
				}
			});
		}
		if (node) {
			observer.current.observe(node);
		}
	}, [requestLoadMore]);

	const klass = (title && !destination) ? clsx(classes.item, classes.spacer) : clsx(classes.item);

	return (
		<>
			<Grid container rowspacing={2} className={classes.container}>
				{items.map((item, i) => {
					if (items.length === i + 1) {
						return (
							<Grid key={item.uid} item xs={xs} className={klass}>
								<div className={classes.itemContents} ref={lastExperienceRef}>
									{avatar
									&& (
										<div className={classes.avatarContainer}>
											<Avatar
												src={item.user.avatar.large.jpg}
												size="small"
												alt="Username"
											/>
										</div>
									)}
									<Thumbnail
										height={height}
										gutters={gutters}
										src={item.image}
										uid={item.uid}
										width={width}
										onClick={onClick}
										onTap={onTap}
										longPressedOptions={longPressedOptions}
										title={title ? item.title : null}
										titleSkeleton={titleSkeleton}
										destination={destination}
										myIdentifiers={myIdentifiers}
										user={item.user}
									/>
								</div>

							</Grid>
						);
					}
					return (
						<Grid item key={item.uid} xs={xs} className={klass}>
							<div className={classes.itemContents}>
								{avatar
								&& (
									<div className={classes.avatarContainer}>
										<Avatar
											src={item.user.avatar.large.jpg}
											size="small"
											alt="Username"
										/>
									</div>
								)}
								<Thumbnail
									height={height}
									gutters={gutters}
									src={item.image}
									uid={item.uid}
									width={width}
									onClick={onClick}
									onTap={onTap}
									longPressedOptions={longPressedOptions}
									title={title ? item.title : null}
									titleSkeleton={titleSkeleton}
									destination={destination}
									myIdentifiers={myIdentifiers}
									user={item.user}
								/>
							</div>
						</Grid>
					);
				})}

			</Grid>
			{loading
			&& (
				SkeletonComponent
					? <SkeletonComponent />
					: (
						<PaginationSkeleton
							style={{
								paddingBottom: 64,
								marginTop: -64,
							}}
						/>
					)
			)}

		</>
	);
};
