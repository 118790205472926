import React from 'react';
import { makeStyles } from '@material-ui/core';
import Actions from './Actions';
import Stats from './Stats';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginTop: theme.spacing(7.5),
		marginBottom: theme.spacing(9.5),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
}));

export default () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Stats />
			<Actions />
		</div>
	);
};
