import { notifyErrors } from 'app/slices/notifications/notifications';

const errorsMessages = [
	{ name: 'NotAllowedError', message: 'Change your device permissions to enable this feature.' },
	{ name: 'TypeError', message: 'Try again later.' },
	{ name: 'NotSupportedError', message: 'Your device does not support this feature.' }];

const notifyError = (error) => {
	notifyErrors([{
		title: 'Feature unavailable',
		text: error,
		severity: 'info',
	}]);
};

const getErrorMessage = (errorName) => errorsMessages.find((error) => error.name === errorName)?.message;

export default ({
	onClose,
	...props
}) => {
	if (navigator.canShare) {
		if (navigator.canShare({ ...props })) {
			navigator
				.share({
					...props,
				})
				.then(() => {
					onClose();
				})
				.catch((error) => {
					switch (error.name) {
					case 'AbortError':
						onClose();
						break;
					case 'NotAllowedError':
						notifyError(getErrorMessage('NotAllowedError'));
						break;
					case 'TypeError':
						notifyError(getErrorMessage('TypeError'));
						break;
					case 'NotSupportedError':
						notifyError(getErrorMessage('NotSupportedError'));
						break;
					default:
						notifyError(getErrorMessage('TypeError'));
						break;
					}
				});
		}
	} else {
		notifyError(getErrorMessage('NotSupportedError'));
	}
};
