import React from 'react';
import Header from '../Header';
import PushNotifications from './PushNotifications/PushNotifications';
import DataLoader from './DataLoader';
// import EmailNotifications from './EmailNotifications/EmailNotifications';

export default () => (
	<DataLoader>
		<Header title="Notifications" />
		<PushNotifications />
		{/* <EmailNotifications /> */}
	</DataLoader>
);
