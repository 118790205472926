const timeoutMap = {
	dissolve: 300,
	slide: 300,
};

class RouteMetadata {
	constructor(opts = {}) {
		this.validate(opts);

		this.root = !!opts.root;
		this.authorization = opts.authorization;
		this.authorizePage = opts.authorizePage;
		this.ecrSelectorName = opts.ecrSelectorName;
		this.transition = opts.transition || 'slide';
		this.timeout = timeoutMap[opts.transition] || 0;
		this.Background = opts.Background || null;
		this.Component = opts.Component;
		this.Header = opts.Header || null;
		this.Skeleton = opts.Skeleton || null;
		this.routeOptions = opts.routeOptions;
		this.path = this.routeOptions.path;
		this.errors = opts.errors;
		this.options = opts.options;
		this.loadData = opts.loadData;
		this.navigationMenu = opts.navigationMenu;
		this.rootRedirect = opts.rootRedirect;
		this.preserveScrollState = typeof opts.preserveScrollState === 'undefined' ? true : !!opts.preserveScrollState;
		this.skipReloads = !!opts.skipReloads;
	}

	validate(opts) {
		if (!opts.Component) {
			// eslint-disable-next-line
            console.error("RouteMetadata Error: A route must have a Component defined.");
		}

		if (!opts.routeOptions || Object.keys(opts.routeOptions).length === 0) {
			// eslint-disable-next-line
            console.error("RouteMetadata Error: A route must have routeOptions defined.");
		}
	}
}

export default RouteMetadata;
