import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import api from 'services/api';
import { notify, notifyErrors } from 'app/slices/notifications/notifications';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import { useNavigate, useParams } from 'react-router-dom';

export default () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token: paramsToken } = useParams();
	const token = paramsToken;

	useEffect(async () => {
		try {
			const response = await api.patch(`accounts/emails/verify/${token}`);
			dispatch(notify({
				severity: 'info',
				title: response.data.message,
				autoHideDuration: 2000,
			}));
			navigate('/');
		} catch (err) {
			const errors = getErrorsFromRejectedRequest(err);

			notifyErrors(errors);
			navigate('/my-account');
		}
	}, []);

	return null;
};
