import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import {
	set,
	selectStyleRoute,
	selectEditingNode,
	selectMatch,
} from 'app/slices/content-creation/create/text-component';
import { Button } from '@material-ui/core';
import ColorPicker from './ColorPicker';
import FontPicker from './FontPicker';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		height: 72,
		zIndex: 2140000004,
		background: 'linear-gradient(180deg, rgba(27, 36, 45, 0) -9.13%, rgba(27, 36, 45, 0.2) 26.08%, rgba(27, 36, 45, 0.4) 62.03%, rgba(27, 36, 45, 0.6) 96.51%, rgba(27, 36, 45, 0.8) 131.73%)',
	},
	left: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		width: 58,
		zIndex: 2140000006,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	right: {
		width: '100%',
		paddingLeft: 58,
		marginTop: 20,
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	hide: {
		visibility: 'hidden',
	},
	circle: {
		width: 28,
		height: 28,
		borderRadius: '50%',
		backgroundColor: theme.colors.neutral.C000,
	},
}));

export default ({ editingCanvas }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const node = useSelector(selectEditingNode);
	const styleRoute = useSelector(selectStyleRoute);
	const match = useSelector(selectMatch);
	if (!node) { return null; }

	const changeStyleNavigation = (route) => (e) => {
		e.preventDefault();
		dispatch(set({
			styleRoute: route,
		}));
	};

	if (match && match.word) { return null; }

	return (
		<>
			<div className={classes.root}>
				<div className={classes.right}>
					{styleRoute === 'font'
						&& (
							<div>
								<FontPicker editingCanvas={editingCanvas} />
							</div>
						)}
					{styleRoute === 'color' && (
						<div>
							<ColorPicker editingCanvas={editingCanvas} />
						</div>
					)}

				</div>
				<div className={classes.left}>
					{styleRoute === 'color' ? (
						<Button size="large" className={classes.button} onMouseDown={changeStyleNavigation('font')}>Aa</Button>
					)
						: <div className={classes.circle} onMouseDown={changeStyleNavigation('color')} />}
				</div>

			</div>

		</>
	);
};
