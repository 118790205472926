import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { openBottomMenu } from 'app/slices/user-account-v2/layout/slice';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(8),
		textAlign: 'center',
	},
	button: {
		paddingLeft: theme.spacing(12),
		paddingRight: theme.spacing(12),
		'&:hover': {
			backgroundColor: theme.colors.neutral.C900,
			color: theme.colors.neutral.C400,
		},
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				color="secondary"
				onClick={() => {
					dispatch(openBottomMenu('unBlock'));
				}}
				variant="contained"
			>
				Unblock
			</Button>
		</div>
	);
};
