import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	button: {
		borderRadius: 2,
		border: `1px solid ${theme.colors.primary.C900}`,
		color: theme.colors.primary.C600,
		marginRight: theme.spacing(2),
		padding: theme.spacing(2, 4),
	},
	background: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: theme.palette.background.page,
		opacity: 0.8,
		zIndex: 1,
	},
	active: {
		color: theme.colors.neutral.C000,
		background: theme.colors.primary.C600,
	},
}));

export default ({
	title,
	active,
	onClick,
	filters,
}) => {
	const classes = useStyles();

	const style = {};

	if (active) {
		style.zIndex = 2;
	}

	const klass = active || (filters && !!filters[title].length) ? clsx(classes.button, classes.active) : classes.button;

	return (
		<>
			<Typography
				variant="subtitle2"
				className={klass}
				style={style}
				onClick={onClick}
			>
				{title}
			</Typography>
		</>
	);
};
