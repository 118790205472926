import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	footer: {
		position: 'fixed',
		width: '100%',
		bottom: '16px',
		zIndex: 3,
		display: 'flex',
		flexDirection: 'column',
	},
});
export default ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.footer}>
			{children}
		</div>

	);
};
