/* eslint-disable no-console */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import { notify } from 'app/slices/notifications/notifications';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';
import dayjs from 'dayjs';
import { updateExperience } from 'app/slices/experience-modal';

const formatTimestamp = (timestamp) => {
	const now = dayjs();
	const createdAt = dayjs(timestamp);
	const diffMinutes = now.diff(createdAt, 'minute');

	if (diffMinutes === 0) {
		return 'now';
	} if (diffMinutes === 1) {
		return '1m';
	}
	return dayjs(timestamp).fromNow();
};

const mapMessagesWithTimestamp = (messages) => messages.map((m) => ({
	...m,
	meta: {
		...m.meta,
		createdAt: formatTimestamp(m?.meta?.createdAt),
	},
}));

async function fetchCommentIfAbsent(comment, commentsResponse, uuid, dispatch) {
	// flatten replies.comments into a single level array with messages
	try {
		const allComments = [
			...commentsResponse.data.messages,
			...commentsResponse.data.messages.flatMap((message) => message.replies?.comments || []),
		];

		const commentExists = allComments.some(({ id }) => id === comment);

		if (comment && !commentExists) {
			const singleResponse = await api.get(`messages-v2/conversations/${uuid}/messages/${comment}`, { apiType: 'comment' });
			commentsResponse.data.messages.unshift(singleResponse.data);
			commentsResponse.data.users = { ...commentsResponse.data.users, ...singleResponse.data.users };
		}
		commentsResponse.data.messages = mapMessagesWithTimestamp(commentsResponse.data.messages);
		commentsResponse.data.messages.forEach((c) => {
			if (c.replies.comments && c.replies.comments.length > 0) {
				c.replies.comments = mapMessagesWithTimestamp(c.replies.comments);
			}
		});
	} catch (err) {
		if (err?.response?.status === 404) {
			dispatch(notify({
				severity: 'info',
				description: 'Comment could not be found.',
			}));
		}
	}
}

export const requestExperienceComments = createAsyncThunk(
	'requestExperienceComments',
	async ({
		uid,
		comment,
	}, { rejectWithValue, dispatch }) => {
		try {
			const response = await api.post('messages-v2/conversations', { targetId: uid, targetSchemaType: 'Experience', conversationType: 'comment' });
			const { uuid, count } = response.data;
			const commentsResponse = await api.get(`messages-v2/conversations/${uuid}/messages`);
			const res = await fetchCommentIfAbsent(comment, commentsResponse, uuid, dispatch);
			if (res) {
				dispatch(notify({
					severity: 'info',
					description: 'Comment deleted.',
				}));
				return null;
			}
			return {
				commentsResponse: commentsResponse.data,
				conversationId: uuid,
				experienceUid: uid,
				totalCount: count,
				comment,
			};
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestLoadMoreComments = createAsyncThunk(
	'requestLoadMoreComments',
	async (_, { getState, rejectWithValue }) => {
		const { next, loading } = getState().commentsSlice;

		if (loading) {
			return null;
		}

		let response;
		if (next) {
			try {
				response = await api.get(next);
			} catch (error) {
				return rejectWithValue(handleSliceError(error));
			}
		}
		return response.data;
	},
);

export const requestLoadMoreReplies = createAsyncThunk(
	'requestLoadMoreReplies',
	async ({ next, parentId }, { rejectWithValue }) => {
		try {
			const response = await api.get(next);
			return { data: response.data, parentId };
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestSendComment = createAsyncThunk(
	'requestSendComment',
	async ({ message, type, experience }, { getState, rejectWithValue, dispatch }) => {
		const { conversationId } = getState().commentsSlice;
		let updatedExperience = { ...experience, stats: { ...experience.stats, comments: experience.stats.comments + 1 } };
		dispatch(updateExperience(updatedExperience));
		try {
			const { commentToReply } = getState().commentsSlice;
			const parentId = commentToReply?.parentId || commentToReply?.id;
			const response = await api.post(`messages-v2/conversations/${conversationId}/messages`, {
				parentId,
				contents: message,
			});
			return { response, type };
		} catch (error) {
			updatedExperience = { ...experience, stats: { ...experience.stats, comments: experience.stats.comments - 1 } };
			dispatch(updateExperience(updatedExperience));
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestLikeComment = createAsyncThunk(
	'requestLikeComment',
	async ({ uid }, { getState, rejectWithValue }) => {
		const { conversationId } = getState().commentsSlice;
		try {
			const response = await api.post(`messages-v2/conversations/${conversationId}/messages/${uid}/reactions`, {
				type: 'like',
			});
			return response;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestUnLikeComment = createAsyncThunk(
	'requestUnLikeComment',
	async ({ uid }, { getState, rejectWithValue }) => {
		const { conversationId } = getState().commentsSlice;
		try {
			const response = await api.delete(`messages-v2/conversations/${conversationId}/messages/${uid}/reactions`, {
				data: {
					type: 'like',
				},
			});
			return response;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const waitFor = (t) => new Promise((resolve) => setTimeout(resolve), t);

export const requestDeleteComment = createAsyncThunk(
	'requestDeleteComment',
	async ({ comment, parentId, experience }, { getState, dispatch, rejectWithValue }) => {
		const { conversationId } = getState().commentsSlice;
		const { id, interactions } = comment;
		let updatedExperience = { ...experience, stats: { ...experience.stats, comments: experience.stats.comments - (interactions.replyCount + 1) } };
		dispatch(updateExperience(updatedExperience));
		try {
			await api.delete(`messages-v2/conversations/${conversationId}/messages/${id}`);

			dispatch(notify({
				severity: 'info',
				description: 'Comment deleted.',
			}));

			// Wait for it to be rmeoved
			await waitFor(600);
			return { id, parentId };
		} catch (error) {
			updatedExperience = { ...experience, stats: { ...experience.stats, comments: experience.stats.comments + (interactions.replyCount + 1) } };
			dispatch(updateExperience(updatedExperience));
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const findCommentIndex = (comments, commentId) => comments.findIndex((comment) => comment.id === commentId);

const handleCommentLike = (state, action, isLiked) => {
	const { uid, type, commentId } = action.meta.arg;
	const commentIndex = findCommentIndex(state.comments, commentId);
	const previousComments = [...state.comments];
	const updatedComments = [];

	if (type === 'reply') {
		if (commentIndex !== -1) {
			const { replies } = state.comments[commentIndex];
			const replyIndex = findCommentIndex(replies.comments, uid);
			if (replyIndex !== -1) {
				replies.comments[replyIndex].interactions.isLiked = isLiked;
				replies.comments[replyIndex].interactions.numLikes += isLiked ? 1 : -1;
			}
		}
	} else {
		for (let i = 0; i < previousComments.length; i++) {
			if (previousComments[i].id === uid) {
				previousComments[i].interactions.isLiked = isLiked;
				previousComments[i].interactions.numLikes += isLiked ? 1 : -1;
			}
			updatedComments.push(previousComments[i]);
		}
		state.comments = updatedComments;
	}

	state.loading = isLiked;
	state.errors = isLiked ? null : action.payload;
};

const initialState = {
	open: false,
	comments: null,
	experienceUid: null,
	loading: false,
	errors: null,
	newCommentId: null,
	activeComment: null,
	conversationId: null,
	next: null,
	loadingComments: false,
	postingComment: false,
	counts: null,
	commentToReply: null,
	users: null,
	offset: 5,
	loadingMoreReplies: false,
};

const commentsSlice = createSlice({
	name: 'commentsSlice',
	initialState: { ...initialState },
	reducers: {
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		resetNewCommentId: (state) => {
			state.newCommentId = null;
		},
	},
	extraReducers: {
		[requestExperienceComments.pending]: (state, action) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
			state.experienceUid = action.meta.arg.uid;
			state.loadingComments = true;
			state.errors = null;
		},
		[requestExperienceComments.fulfilled]: (state, action) => {
			const {
				commentsResponse,
				conversationId,
				totalCount,
				comment,
				experienceUid,
			} = action.payload;
			state.loadingComments = false;
			state.errors = null;
			state.conversationId = conversationId;
			const { links, messages, users } = commentsResponse;
			state.comments = messages;
			state.experienceUid = experienceUid;
			state.users = users;
			state.counts = totalCount;
			state.newCommentId = comment?.id;
			if (links) {
				state.next = links?.next;
			} else {
				state.next = null;
			}
		},
		[requestExperienceComments.rejected]: (state, action) => {
			state.loadingComments = false;
			state.errors = action.payload;
		},
		[requestLoadMoreComments.pending]: (state) => {
			state.loadingComments = true;
			state.errors = null;
		},
		[requestLoadMoreComments.fulfilled]: (state, action) => {
			if (!action.payload) {
				// already loading response
				return;
			}
			const { links, messages, users } = action.payload;
			state.loadingComments = false;
			state.errors = null;
			if (messages) {
				const c = state.comments || [];

				const commentIdSet = new Set(c.map((comment) => comment.id));
				const uniqueMessages = messages.filter((message) => !commentIdSet.has(message.id));
				uniqueMessages.forEach((cc) => {
					if (cc.replies.comments && cc.replies.comments.length > 0) {
						cc.replies.comments = mapMessagesWithTimestamp(cc.replies.comments);
					}
				});
				const updatedMessages = mapMessagesWithTimestamp(uniqueMessages);
				state.comments = [...c, ...updatedMessages];
				state.users = { ...state.users, ...users };
			}
			if (links?.next) {
				state.next = links?.next;
			} else {
				state.next = null;
			}
		},
		[requestLoadMoreComments.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestLoadMoreReplies.pending]: (state) => {
			state.errors = null;
			state.loadingMoreReplies = true;
		},
		[requestLoadMoreReplies.fulfilled]: (state, action) => {
			const { data, parentId } = action.payload;
			const commentIndex = findCommentIndex(state.comments, parentId);

			if (commentIndex !== -1) {
				const { replies } = state.comments[commentIndex];
				const replyIdSet = new Set(replies.comments.map((reply) => reply.id));
				const uniqueReplies = data.messages.filter((message) => !replyIdSet.has(message.id));
				const updatedReplies = mapMessagesWithTimestamp(uniqueReplies);
				replies.comments = [...updatedReplies, ...replies.comments];
				replies.users = { ...data.users, ...replies.users };
				replies.links = data?.links || null;
			}
			state.errors = null;
			state.loadingMoreReplies = false;
		},

		[requestLoadMoreReplies.rejected]: (state, action) => {
			state.errors = action.payload;
			state.loadingMoreReplies = false;
		},
		[requestSendComment.pending]: (state) => {
			state.postingComment = true;
		},
		[requestSendComment.fulfilled]: (state, action) => {
			const { response, type } = action.payload;
			const { message } = response.data;
			state.postingComment = false;
			state.errors = null;
			state.users = { ...state.users, ...response.data?.message?.users };

			const copyMessage = { ...response.data.message };
			copyMessage.meta.createdAt = formatTimestamp(copyMessage.meta.createdAt);

			if (type === 'reply') {
				for (let i = 0; i < state.comments.length; i++) {
					const comment = state.comments[i];
					if (comment.id === message.parentId) {
						comment.interactions.replyCount += 1;
						comment?.replies?.comments.push(copyMessage);
					}
				}
			} else {
				state.comments = [copyMessage, ...state.comments];
			}
			state.activeComment = null;
			state.commentToReply = null;
			state.newCommentId = message.id;
		},
		[requestSendComment.rejected]: (state, action) => {
			state.postingComment = false;
			state.errors = action.payload;
		},
		[requestDeleteComment.pending]: (state, payload) => {
			const { id, parentId } = payload.meta.arg;

			if (parentId) {
				const commentIndex = findCommentIndex(state.comments, parentId);
				if (commentIndex !== -1) {
					const { replies } = state.comments[commentIndex];
					const replyIndex = findCommentIndex(replies.comments, id);
					if (replyIndex !== -1) {
						replies.comments[replyIndex].deleted = true;
					}
				}
			} else {
				const commentIndex = findCommentIndex(state.comments, id);
				if (commentIndex !== -1) {
					state.comments[commentIndex].deleted = true;

					if (state.comments[commentIndex]?.replies?.comments) {
						state.comments[commentIndex].replies.comments.forEach((r) => {
							r.deleted = true;
						});
						state.comments.interactions.replyCount -= 1;
					}
				}
			}
			state.loading = true;
			state.errors = null;
		},
		[requestDeleteComment.fulfilled]: (state, action) => {
			state.loading = false;

			const { id, parentId } = action.payload;

			if (parentId) {
				const commentIndex = findCommentIndex(state.comments, parentId);
				if (commentIndex !== -1) {
					const { replies } = state.comments[commentIndex];
					const replyIndex = findCommentIndex(replies.comments, id);
					if (replyIndex !== -1) {
						replies.comments.splice(replyIndex, 1);
					}
				}
			} else {
				const commentIndex = findCommentIndex(state.comments, id);
				state.comments.splice(commentIndex, 1);
			}

			state.errors = null;
		},
		[requestDeleteComment.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestLikeComment.pending]: (state, payload) => {
			handleCommentLike(state, payload, true);
			state.loading = true;
			state.errors = null;
		},

		[requestLikeComment.rejected]: (state, action) => {
			handleCommentLike(state, action, false);
			state.loading = false;
			state.errors = action.payload;
		},
		[requestLikeComment.fulfilled]: (state) => {
			state.loading = false;
			state.errors = null;
		},
		[requestUnLikeComment.pending]: (state, payload) => {
			handleCommentLike(state, payload, false);
			state.loading = true;
			state.errors = null;
		},
		[requestUnLikeComment.fulfilled]: (state) => {
			state.loading = false;
			state.errors = null;
		},
		[requestUnLikeComment.rejected]: (state, action) => {
			handleCommentLike(state, action, true);
			state.loading = false;
			state.errors = action.payload;
		},

	},
});

const { name, reducer, actions } = commentsSlice;
const {
	set,
	reset,
	resetNewCommentId,
} = actions;

export {
	name,
	set,
	reset,
	resetNewCommentId,
};

export const getSlice = (state) => state[name];
export const getIsCommentsModalOpen = createSelector(getSlice, (slice) => slice?.open);
export const getNewCommentId = createSelector(getSlice, (slice) => slice.newCommentId);
export const getListOfComments = createSelector(getSlice, (slice) => slice.comments);
export const getCommentsUsers = createSelector(getSlice, (slice) => slice?.users);
export const getCommentsCount = createSelector(getSlice, (slice) => slice.counts);
export const getConversationId = createSelector(getSlice, (slice) => slice?.conversationId);
export const getConversationEuid = createSelector(getSlice, (slice) => slice?.experienceUid);
export const getActiveComment = createSelector(getSlice, (slice) => slice?.activeComment);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getLoadingMoreReplies = createSelector(getSlice, (slice) => slice.loadingMoreReplies);
export const getLoadingComments = createSelector(getSlice, (slice) => slice?.loadingComments);
export const getPostingComment = createSelector(getSlice, (slice) => slice?.postingComment);
export const getCommentToReply = createSelector(getSlice, (slice) => slice?.commentToReply);
export const getNext = createSelector(getSlice, (slice) => slice?.next);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export default commentsSlice;

injectReducer(name, reducer);
