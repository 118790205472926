import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getTheirProfileUuid,
	getMyProfileUuid,
	getLoading,
} from 'app/slices/user-account-v2/accounts/selectors';
import { requestCheckIsUserBlocked } from 'app/slices/user-account-v2/accounts/thunks';
import EditProfileModal from 'routes/UserAccount/Header/EditProfile/EditProfileModal';
import InProgressExperiences from '../InProgressExperiences';
import Identifiers from '../Identifiers';
import Content from '../Content';
import UserNotFound from '../UserNotFound';
import Profile from '../Header/Profile';
import Stats from '../Header/Stats';
import Actions from '../Header/Actions';

export default ({
	isMe = false,
}) => {
	const dispatch = useDispatch();
	const userAccountUuid = useSelector(isMe ? getMyProfileUuid : getTheirProfileUuid);
	const loading = useSelector(getLoading);

	useEffect(() => {
		if (userAccountUuid && !isMe) {
			dispatch(requestCheckIsUserBlocked(userAccountUuid));
		}
	}, [userAccountUuid, isMe]);

	if (!userAccountUuid && !loading) {
		return (
			<>
				<UserNotFound />
			</>
		);
	}

	return (
		<>
			<Profile isMe={isMe} />

			<Stats isMe={isMe} />
			{
				isMe
					? null
					: <Actions />
			}
			<Identifiers isMe={isMe} />
			{
				isMe
					? <InProgressExperiences />
					: null
			}
			<Content isMe={isMe} />
			<EditProfileModal />
		</>
	);
};
