import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	timerBox: {
		display: 'inline-block',
		height: 4,
		background: theme.colors.neutral.C700,
		opacity: 0.7,
		borderRadius: 10,
		marginRight: theme.spacing(0.5),
		marginLeft: theme.spacing(0.5),
		width: '100%',
		position: 'relative',
	},
	timerFill: {
		position: 'absolute',
		left: 0,
		height: '100%',
		borderRadius: 10,
		top: 0,
		width: 0,
		background: theme.colors.primary.C500,
	},
}));

export default ({ current, index }) => {
	const classes = useStyles();
	const style = {};

	if (index < current) {
		style.width = '100%';
	}
	return (
		<div className={classes.timerBox}>
			<div className={classes.timerFill} style={style} />
		</div>
	);
};
