import React from 'react';
import {
	Button, Grid, makeStyles,
} from '@material-ui/core';
import Typography from 'rds/components/Typography';
import Avatar from 'rds/components/Avatar';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowY: 'auto',
		overflowX: 'hidden',
		maxHeight: '100vh',
		marginTop: theme.spacing(4),
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	people: {
		marginTop: theme.spacing(),
	},
	person: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginRight: theme.spacing(4),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	name: {
		color: theme.colors.neutral.C400,
	},
	info: {
		display: 'flex',
		flexDirection: 'row',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: theme.spacing(3),
		justifyContent: 'space-evenly',
		textAlign: 'left',
	},
	actions: {
		width: 100,
		marginRight: theme.spacing(),
	},
	button: {
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5),
		width: 119,
		backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
			color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		},
	},
}));

export default ({ users, onClose }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<div className={classes.root}>
			<Grid container className={classes.people}>
				{users && users.map((p, i) => (
					<Grid key={i} item xs={12} className={classes.person}>
						<div
							className={classes.info}
							onClick={() => {
								navigate(`/${p.username}`);
								onClose();
							}}
						>
							<Avatar size="medium" alt={p.username} src={p.avatar.small.jpg} />
							<div className={classes.details}>
								<Typography variant="body1">{p.username}</Typography>
								<Typography className={classes.name} variant="caption">{p.displayName}</Typography>
							</div>
						</div>
						<div className={classes.actions}>
							<Button
								variant="contained"
								disableRipple
								fullWidth
								disableFocusRipple
								className={classes.button}
								onClick={() => {
									// asd
								}}
							>
								Follow
							</Button>
						</div>
					</Grid>
				))}
			</Grid>

		</div>
	);
};
