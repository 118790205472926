import createElement from '../../utils/create-element';
import { GIF } from '../../types';
import BaseNode from './base';

export default class GifNode extends BaseNode {
	constructor(canvas, item) {
		super(canvas, item);
		this.type = GIF;
		this.ext = 'gif';
		this.el = createElement({
			attributes: {
				src: item.data.src,
			},
			tagName: 'img',
		});
		this.el.style.position = 'absolute';
		this.el.style.zIndex = item.z;
		this.el.style.height = `${item.data.height}px`;
		this.el.style.width = `${item.data.width}px`;
		Object.keys(this.styles).forEach((k) => {
			this.el.style[k] = this.styles[k];
		});

		this.frameTimes = [];

		for (let i = 0, t = 0; item.data && i < item.data.frames.length; i++) {
			t += item.data.frames[i].delay;
			this.frameTimes.push(t);
		}

		this.setAttributes(this.el);
	}

	get dto() {
		return this.data.src;
	}

	createCanveses() {
		const firstFrame = this.data.frames[0];
		const { height, width } = firstFrame.dims;
		const canvas = createElement({
			attributes: {
				'data-object-id': this.id,
			},
			tagName: 'canvas',
		});
		const context = canvas.getContext('2d');
		canvas.setAttribute('height', `${this.data.height || height}px`);
		canvas.setAttribute('width', `${this.data.width || width}px`);
		canvas.style.position = 'absolute';
		canvas.style.zIndex = this.z;

		return [canvas, context];
	}

	async renderFrame(t, multiplier) {
		const playTime = t % this.frameTimes[this.frameTimes.length - 1];
		let i = 0;
		for (; i <= this.frameTimes.length; i++) {
			if (playTime < this.frameTimes[i]) {
				break;
			}
		}

		const {
			context,
			el,
			gifCanvas,
			gifContext,
			tmpCanvas,
			tmpContext,
			offset,
			shadowContainer,
		} = this.compileContext;
		shadowContainer.appendChild(el);

		let { frameImageData } = this.compileContext;
		const frame = this.data.frames[i];
		const {
			height,
			width,
		} = frame.dims;

		const scale = this.getScale(el);
		const angle = this.getRotation(el);
		const rads = angle * (Math.PI / 180);
		const size = {};
		const position = {};
		const containerRect = shadowContainer.getBoundingClientRect();

		// true center
		const centerPercent = {
			x: (offset.x + (offset.width / 2)) / containerRect.width,
			y: (offset.y + (offset.height / 2)) / containerRect.height,
		};

		// center point
		const center = {
			x: (containerRect.width * multiplier) * centerPercent.x,
			y: (containerRect.height * multiplier) * centerPercent.y,
		};

		// find the top left
		const topLeftCorner = {
			x: center.x - ((offset.width * scale * multiplier) / 2),
			y: center.y - ((offset.height * scale * multiplier) / 2),
		};

		if (
			!frameImageData
			|| width !== frameImageData.width
			|| height !== frameImageData.height
		) {
			tmpCanvas.width = width;
			tmpCanvas.height = height;
			frameImageData = tmpContext.createImageData(width, height);
			this.compileContext.frameImageData = frameImageData;
		}

		frameImageData.data.set(frame.patch);
		tmpContext.putImageData(frameImageData, 0, 0);

		const naturalHeight = this.data.frames[0].dims.height;
		const naturalWidth = this.data.frames[0].dims.width;

		size.height = Math.round(offset.height * multiplier * scale);
		size.width = Math.round(offset.width * multiplier * scale);
		position.x = Math.round(topLeftCorner.x);
		position.y = Math.round(topLeftCorner.y);

		let containModHeight;
		let containModWidth;
		if (naturalHeight > naturalWidth) {
			containModHeight = gifCanvas.height;
			containModWidth = (gifCanvas.height * naturalWidth) / naturalHeight;
		} else {
			containModHeight = (gifCanvas.width * naturalHeight) / naturalWidth;
			containModWidth = gifCanvas.width;
		}

		gifContext.drawImage(tmpCanvas,
			0,
			0,
			tmpCanvas.width,
			tmpCanvas.height,
			(gifCanvas.width - containModWidth) / 2,
			(gifCanvas.height - containModHeight) / 2,
			containModWidth,
			containModHeight);

		context.save();
		context.translate(position.x, position.y);
		context.translate(size.width / 2, size.height / 2);
		context.rotate(rads);
		context.drawImage(gifCanvas,
			0,
			0,
			gifCanvas.width,
			gifCanvas.height,
			(-size.width / 2),
			(-size.height / 2),
			size.width,
			size.height);

		tmpContext.clearRect(0, 0, width * 2, height * 2);
		gifContext.clearRect(0, 0, width * 2, height * 2);
		context.restore();
	}

	async setupCompile(canvas, context, shadowContainer) {
		const el = this.el.cloneNode(true);
		const [tmpCanvas, tmpContext] = this.createCanveses();
		const [gifCanvas, gifContext] = this.createCanveses();

		shadowContainer.appendChild(el);

		const offset = {
			x: parseFloat(el.style.left.slice(0, -2)) || 0,
			y: parseFloat(el.style.top.slice(0, -2)) || 0,
			width: el.clientWidth,
			height: el.clientHeight,
		};

		gifCanvas.width = offset.width;
		gifCanvas.height = offset.height;

		this.compileContext = {
			canvas,
			context,
			shadowContainer,
			tmpCanvas,
			tmpContext,
			gifCanvas,
			gifContext,
			offset,
			el,
		};
	}
}
