import { createSlice, createSelector } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

export const destinationsLayoutSlice = createSlice({
	name: 'destinationsLayout',
	initialState: {
		modal: false,
	},

	reducers: {
		change: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
	},
});

const { name, reducer, actions } = destinationsLayoutSlice;
const { change } = actions;

export { change as changeDestinationLayout };

export const getSlice = (state) => state[name];
export const getModal = createSelector(getSlice, (slice) => slice?.modal);

injectReducer(name, reducer);
