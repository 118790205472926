import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ProgressiveThumbnail from 'rds/components/ProgressiveThumbnail';
import useClick from './useClick';
import ExperienceInfo from './ExperienceInfo';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
	},
	expansionContainer: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
	},
	eachSide: {
		width: '100%',
		height: '100%',
		marginRight: 3,
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		position: 'relative',
	},
	info: {
		position: 'absolute',
		bottom: 4,
		left: 0,
		paddingRight: theme.spacing(),
		zIndex: 2,
	},
	skeleton: {
		background: 'linear-gradient(90deg, rgba(51, 59, 67, 0) 0%, #333B43 100%)',
		marginBottom: theme.spacing(),
	},
}));

const EachSide = ({
	background,
	avatar,
	title,
	type,
	destination,
	loaded,
	onLoad,
	uid,
}) => {
	const classes = useStyles();
	const onClick = useClick(uid, background, type);

	return (
		<div
			className={classes.eachSide}
			onClick={onClick}
		>
			<div
				className={classes.expansionContainer}
				data-experience-modal-id={uid}
			>
				<ProgressiveThumbnail
					src={background}
					animation="wave"
					gradient
					onLoad={onLoad}
				/>
				<div className={classes.info}>
					<ExperienceInfo
						avatar={avatar}
						avatarSize="xxxSmall"
						title={title}
						titleVariant="subtitle2"
						destination={destination}
						destinationVariant="body2"
						rootStyle={{ alignItems: 'start' }}
						skeletonWidth={24}
						skeletonHeight={24}
						pattern="standard"
						loaded={loaded}
					/>
				</div>
			</div>
		</div>
	);
};

export default ({
	avatar,
	background,
	destination,
	title,
	buddy,
	uid,
}) => {
	const classes = useStyles();
	const [loadedFistImage, setLoadedFirstImage] = useState(false);
	const [loadedSecondImage, setLoadedSecondImage] = useState(false);

	return (
		<>
			<div className={classes.root}>
				<EachSide
					background={background}
					avatar={avatar}
					title={title}
					destination={destination}
					loaded={loadedFistImage}
					uid={uid}
					onLoad={() => { setLoadedFirstImage(true); }}
					left
				/>

				<EachSide
					background={buddy.background}
					avatar={buddy.avatar}
					title={buddy.title}
					destination={buddy.destination}
					loaded={loadedSecondImage}
					uid={buddy.uid}
					onLoad={() => { setLoadedSecondImage(true); }}
				/>
			</div>
		</>
	);
};
