import { EventEmitter } from 'events';
import createElement from 'utils/create-element';
import Scrolling from './scrolling';
import ScrollAnimation from './scroll-animation';

export const EVENTS = {
	add: 'add',
	remove: 'remove',
	trayTap: 'tray-tap',
	hide: 'hide',
};

const DATA_TRAY_NAME = 'data-tray-id';
const HIGHLIGHTED_ATTRIBUTE = 'data-tray-highlighted';
const HIGHLIGHT_CLASS = 'tray_highlight';
const DRAG_CLASS = 'tray_drag';
const REPOSITION_DEBOUNCE = 20;
const SCROLL_TRESHOLD = 70;
const GHOST_TIMEOUT = 100;
const TAP_THRESHOLD = 200; // 200ms
const AUTOHIDE_TIMEOUT = 20000;
const EDITOR_PATHNAME = '/experience/create/editor';
const CLIP_DIMENSIONS = {
	width: 80,
	height: 140,
};

export default class Tray extends EventEmitter {
	constructor(target, elements, {
		containerClassName = 'tray',
		containerInnerClassName = 'tray-inner',
		gradientClassName,
		elementOptions,
		highlightFunction,
		autoHide,
		boundaryY = null,
		onChange = () => { },
		onDrag = () => { },
		onDrop = () => { },
		onHighlight = () => { },
		offHighlight = () => { },
		onSelect = () => { },
	}) {
		super();

		this.target = target;
		this.containerClassName = containerClassName;
		this.containerInnerClassName = containerInnerClassName;
		this.gradientClassName = gradientClassName;
		this.elementOptions = elementOptions;
		this.boundaryY = boundaryY;
		this.timeout = null;
		this.autoHide = autoHide;
		this.dragging = false;
		this.boundaries = null;

		[this.container, this.containerInner] = this._createContainer();

		this.elements = elements.map((el, order) => ({
			el,
			order,
		}));

		this.highlightFunction = highlightFunction;
		this.onChange = onChange;
		this.onDrop = (el) => {
			el.classList.remove(DRAG_CLASS);
			onDrop(el);
		};
		this.onDrag = (el) => {
			el.classList.add(DRAG_CLASS);
			onDrag(el);
		};
		this.onHighlight = onHighlight;
		this.offHighlight = offHighlight;
		this.onSelect = (el, persist = true) => {
			if (el !== this._selected) {
				if (persist) {
					this._selected = el;
				}

				onSelect(el);
			}
		};

		this._render();
	}

	get _slots() {
		this.__slots = this.elements.map(({ el }) => {
			const { left, right } = el.getBoundingClientRect();
			return { left, right };
		}).sort((a, b) => a.left - b.left);

		return this.__slots;
	}

	get _orderedElements() {
		return this.elements.map(({ el }) => {
			const { left } = el.getBoundingClientRect();
			return { el, left };
		}).sort((a, b) => a.left - b.left);
	}

	get _selectedId() {
		return this._selected ? this._selected.getAttribute(DATA_TRAY_NAME) : null;
	}

	get _scrollLeft() {
		return this.containerInner.scrollLeft;
	}

	get _firstElementScrollLeft() {
		if (!this.containerInner.children.length) {
			return 0;
		}

		const rect = this._orderedElements[0].el.getBoundingClientRect();
		return rect.left + this.containerInner.scrollLeft;
	}

	get hidden() {
		return this.target.style.display === 'none';
	}

	get ids() {
		return this._orderedElements.map(({ el }) => el.getAttribute(DATA_TRAY_NAME));
	}

	disablePointerEvents() {
		this.containerInner.style.pointerEvents = 'none';
	}

	enablePointerEvents() {
		this.containerInner.style.pointerEvents = 'all';
	}

	_applyStyles(el, styles) {
		Object.keys(styles).forEach((style) => {
			el.style[style] = styles[style];
		});
	}

	_onChange() {
		const ids = this._orderedElements.map(({ el }) => el.getAttribute(DATA_TRAY_NAME));

		if (JSON.stringify(ids) !== this._lastIds) {
			this.onChange(ids, this._selected);
			this._setIds();
		}
	}

	_setIds() {
		const ids = this._orderedElements.map(({ el }) => el.getAttribute(DATA_TRAY_NAME));
		this._lastIds = JSON.stringify(ids);
	}

	_createContainer() {
		const container = createElement({
			className: this.containerClassName,
			tagName: 'div',
		});

		const containerInner = createElement({
			className: this.containerInnerClassName,
			tagName: 'div',
		});

		const gradient = createElement({
			className: this.gradientClassName,
			tagName: 'div',
		});

		container.appendChild(containerInner);
		container.appendChild(gradient);

		return [container, containerInner];
	}

	_find(el, attr) {
		let look = el;

		while (look?.getAttribute) {
			if (look.getAttribute(attr)) {
				return look;
			}
			look = look.parentNode;
		}

		return false;
	}

	_findHighlighted(el) {
		return this._find(el, HIGHLIGHTED_ATTRIBUTE);
	}

	_findTrayElement(el) {
		return this._find(el, DATA_TRAY_NAME);
	}

	_onTouchStart(e) {
		if (this.dragging) {
			this._dropElement(true);
		}
		this._resetTouches();

		clearTimeout(this.timeout);

		this._touching = true;
		this._touchStarted = window.performance.now();
		[this._firstTouch] = e.touches;

		const trayEl = this._findTrayElement(e.target);
		if (!trayEl) {
			if (!this._highlighted) {
				this._touchAway = true;
			}
		}

		const target = this._find(e.target, DATA_TRAY_NAME);
		if (this.highlightedEl === target) {
			return;
		}

		this._snapback();
	}

	_onScroll() {
		clearTimeout(this._scrollTimeout);
		this._scrollTimeout = setTimeout(() => {
			if (this._touching) {
				return;
			}
			const center = (window.innerWidth / 2) - ((CLIP_DIMENSIONS.width / 2) + 8);

			let nearestEl = null;
			let nearestDiff = 10000;

			for (let i = 0; i < this._orderedElements.length; i++) {
				const { el, left } = this._orderedElements[i];
				const diff = Math.abs(center - left);
				if (diff < nearestDiff) {
					nearestDiff = diff;
					nearestEl = el;
				}
			}
			this.onSelect(nearestEl);
			// this._centerEl(nearestEl);
		}, SCROLL_TRESHOLD);
	}

	_reposition(x, reordering) {
		if (this._repositionTo) {
			this._repositionNext = { x, reordering };
			return;
		}

		try {
			let reorderOrder = 0;

			for (let i = 0; i < this._slots.length; i++) {
				const { left } = this._slots[i];
				if (x >= left) {
					reorderOrder = i;
				}
			}

			const orderedElements = this._orderedElements.slice();
			const reorderingIndex = orderedElements.findIndex(({ el }) => el === reordering);
			const [item] = orderedElements.splice(reorderingIndex, 1);

			orderedElements.splice(reorderOrder, 0, item);
			orderedElements.forEach((n, i) => {
				n.order = i;
				n.el.style.order = i;
			});

			this.elements = orderedElements;

			this._repositionTo = setTimeout(() => {
				this._repositionTo = null;

				// this is a leading & trailing debounce implementation
				if (this._repositionNext) {
					this._reposition(this._repositionNext.x, this._repositionNext.reordering);
					this._repositionNext = null;
				}
			}, REPOSITION_DEBOUNCE);
		} catch (_) {
			// stifle the error
			// its destroyed
		}
	}

	_hide(el) {
		el.style.opacity = 0;
		el.style.removeProperty('transform');
	}

	_show(el) {
		el.style.opacity = 1;
	}

	_preventThru(e) {
		e.preventDefault();
	}

	_snapback(options) {
		if (this.highlightedEl) {
			this.highlightedEl.highlightFn?.snapback(options, () => {
				this.highlightedEl?.classList.remove(HIGHLIGHT_CLASS);
				this.highlightedEl?.removeAttribute(HIGHLIGHTED_ATTRIBUTE);
			});
		}
	}

	_onTouchMove(e) {
		const [touch] = e.touches;
		this._lastTouch = touch;

		if (!this._startingTouchOffset) {
			const { left, top } = e.target.getBoundingClientRect();
			this._startingTouchOffset = {
				startX: e.touches[0].clientX,
				offsetX: e.touches[0].clientX - left,
				offsetY: e.touches[0].clientY - top,
			};
		}

		if (!this._findHighlighted(e.target)) {
			return;
		}

		e.preventDefault();

		/** Create the ghost * */
		if (!this._startingTouchOffset.ghost) {
			const target = this._find(e.target, DATA_TRAY_NAME);
			this._startingTouchOffset.ghost = target.cloneNode(true);
			this._startingTouchOffset.ghost.classList.add('ghost');
			this._startingTouchOffset.ghost.ref = target;
			this._startingTouchOffset.ghost.style.zIndex = 200001;
			this._startingTouchOffset.ghost.style.position = 'fixed';
			this.onDrag(target);
			setTimeout(() => {
				document.body.appendChild(ghost);
			}, 1);
		}

		const { ghost } = this._startingTouchOffset;

		/** apply position to element * */

		// hide OG
		this._hide(ghost.ref);

		const { offsetX, offsetY } = this._startingTouchOffset;

		const x = touch.clientX - offsetX;

		let y = touch.clientY - offsetY;

		if (this.boundaryY) {
			y = Math.min(y, this.boundaryY);
			y = Math.max(2, y);
		}

		ghost.style.left = `${x}px`;
		ghost.style.top = `${y}px`;

		/** check elements position and modify scrollLeft based on proximity to edge * */
		this._scrolling?.update(touch.clientX);

		/** reposition elements in list based on X offset * */
		this._reposition(touch.clientX, e.target.reorderParent);
	}

	_centerEl(el) {
		if (this.centering) {
			this.centering.destroy();
		}

		const { width, left } = el.getBoundingClientRect();
		const center = (window.innerWidth / 2) - (width / 2);
		const diff = left - center;
		const deltaLeft = this._scrollLeft + diff;
		this.centering = new ScrollAnimation({
			el: this.containerInner.parentElement,
			left: deltaLeft,
			maxDuration: 100,
		});
	}

	_dropElement(immediate = false) {
		const ghost = this._startingTouchOffset?.ghost;

		this._scrolling?.pause();

		if (ghost?.ref?.originalPosition) {
			ghost.style.transition = 'top 0.1s linear, left 0.1s linear';
			const { top, left } = ghost.ref.getBoundingClientRect();
			this._snapback();
			ghost.style.top = `${top}px`;
			ghost.style.left = `${left}px`;
			// this._centerEl(ghost.ref);
			const target = this._find(ghost.ref, DATA_TRAY_NAME);
			this.onDrop(target);
		}

		if (this._highlighted && this._selected) {
			this._centerEl(this._selected);
		}

		this._onChange();

		const remove = () => {
			if (ghost) {
				this._show(ghost.ref);
				ghost.remove();
			}
			this._snapback();
		};

		if (immediate) {
			remove();
		} else {
			setTimeout(remove, GHOST_TIMEOUT);
		}
	}

	_resetTouches() {
		setTimeout(() => {
			this._lastTouch = null;
			this._lastMargin = null;
			this._startingTouchOffset = null;
		}, REPOSITION_DEBOUNCE);
	}

	_end() {
		this._dropElement();
		this._resetTouches();
	}

	_onTouchEnd(e) {
		const now = window.performance.now();
		this._touching = false;
		this.dragging = false;

		const target = this._find(e.target, DATA_TRAY_NAME);
		const isTapTime = now - this._touchStarted <= TAP_THRESHOLD;
		const isTapPosition = this._firstTouch && !this._lastTouch; // generally means the user hasn't moved their touch
		const isTap = isTapTime && isTapPosition;

		if (this.highlightedEl === target) {
			this._end();
		}

		if (this._touchAway && isTap) {
			setTimeout(() => {
				this.emit(EVENTS.trayTap);
			}, 1);
		}
		clearTimeout(this.timeout);
		this._autoHide();

		if (target && isTap) {
			if (target.getAttribute('data-delete')) {
				return;
			}
			this.onSelect(target, false);
		}
	}

	_autoHide() {
		if (this.autoHide === 'false' || window.location.pathname !== EDITOR_PATHNAME) {
			return;
		}
		this.timeout = setTimeout(() => {
			this.emit(EVENTS.hide);
			this.hide();
		}, AUTOHIDE_TIMEOUT);
	}

	_renderItem({ el, order, sortable }) {
		el.style.order = order;
		const sortableEl = el.querySelector('[data-sortable=true]');

		if (sortableEl) {
			sortableEl.reorderParent = el;
		}

		if (!sortable) {
			this.containerInner.appendChild(el);
			return;
		}

		this.highlightFunction(el, (highlightFn) => {
			el.highlightFn = highlightFn;
			this.highlightedEl = el;
			this.highlightedEl.classList.add(HIGHLIGHT_CLASS);

			el.setAttribute(HIGHLIGHTED_ATTRIBUTE, true);
			this.onHighlight(el);
			this._highlighted = true;
		}, ({ scrollCancel, ms }) => {
			if (!scrollCancel && ms < TAP_THRESHOLD) {
				this._centerEl(el);
			}
			this.containerInner.removeEventListener('touchmove', this._preventThru);
			this._highlighted = false;
		}, {
			scrollCancelEl: this.containerInner,
			onTouchStart: () => {
				this.containerInner.addEventListener('touchmove', this._preventThru);
				const image = el.children[0];
				if (image) {
					const target = this._find(image, DATA_TRAY_NAME);
					target.originalPosition = image.getBoundingClientRect();
				}
			},
		});

		this.containerInner.appendChild(el);
	}

	_setAfterRender() {
		this._setIds();
	}

	_render() {
		this.elements.forEach((this._renderItem.bind(this)));

		this.containerInner.addEventListener('touchstart', this._onTouchStart.bind(this), { passive: true });
		this.containerInner.addEventListener('touchmove', this._onTouchMove.bind(this), { passive: false });
		this.containerInner.addEventListener('touchend', this._onTouchEnd.bind(this), { passive: true });
		this.target.appendChild(this.container);

		this._setAfterRender();
	}

	hide() {
		this.target.style.display = 'none';
		if (this._scrolling) {
			this._scrolling.destroy();
			this._scrolling = null;
		}
		clearTimeout(this.timeout);
	}

	scrollListen() {
		if (this._scrolling) {
			this._scrolling.destroy();
			this._scrolling = null;
		}
		if (this.container.parentNode) {
			this._scrolling = new Scrolling(this.container.parentNode);
		}
	}

	show(options = {}) {
		if (options.fullscreen) {
			this.containerInner.classList.add('fullscreen');
		} else {
			this.containerInner.classList.remove('fullscreen');
		}

		this.target.style.display = 'block';
		this._autoHide();
	}

	controlAutoHide(activate) {
		this.autoHide = activate;
	}

	add(id, el, options = {}) {
		const order = options.order || (this.elements.length ? this.elements[this.elements.length - 1].order + 1 : 0);
		const sortable = typeof options.sortable === 'undefined' ? true : options.sortable;
		el.style.order = order;
		el.setAttribute(DATA_TRAY_NAME, id);

		if (sortable) {
			this.elements.push({
				el,
				order,
			});
		}

		this._renderItem({
			el,
			order,
			sortable,
		});

		this.__slots = null;
		this._setAfterRender();
		this.newItem = el;
		this.emit(EVENTS.add, id, el);
	}

	addToEnd(id, el) {
		this.add(id, el, {
			sortable: false,
			order: 9999999,
		});
	}

	scrollToEnd() {
		if (!this.elements.length) {
			return;
		}
		const parent = this.container.parentElement;
		const elementRect = this.elements[0].el.getBoundingClientRect();
		const offset = window.innerWidth + (window.innerWidth / 2) - ((elementRect.width / 2) + 8);
		parent.scrollLeft = parent.scrollWidth - offset;
	}

	remove(id) {
		const index = this.elements.findIndex(({ el }) => el.getAttribute(DATA_TRAY_NAME) === String(id));
		const [element] = this.elements.splice(index, 1);

		if (!element) {
			return;
		}

		const { el } = element;

		el.style.opacity = 0;
		setTimeout(() => {
			el.remove();
		}, 200);

		this._onChange();
		this.emit(EVENTS.remove, id);
	}

	removeSelected() {
		const removeId = this._selectedId;
		this.remove(this._selectedId);
		return removeId;
	}

	destroy() {
		document.removeEventListener('touchstart', this._onTouchStart);
		this.container?.remove();
		clearTimeout(this.timeout);
	}
}
