import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		height: 64,
		top: 0,
		left: 0,
		right: 0,
		zIndex: 4,
		background: theme.palette.background.page,
	},
	header: {
		marginRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		fontWeight: 600,
		color: '#ffffff',
	},
	rootSecondary: {
		top: theme.spacing(12),
	},
}));

export default ({ title }) => {
	const classes = useStyles();
	const show = useSelector(getIsUpdatedVersionAvailable);
	const headerClass = show ? clsx(classes.root, classes.rootSecondary) : clsx(classes.root);

	return (
		<div className={headerClass}>
			<Typography className={classes.header} variant="h5">{title}</Typography>
		</div>
	);
};
