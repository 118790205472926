import React from 'react';
import {
	Button, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 200001,
	},
	background: {
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		zIndex: 200001,
		background: theme.colors.neutral.C900,
		opacity: 0.8,
	},
	container: {
		position: 'fixed',
		background: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		paddingBottom: theme.spacing(2),
		borderRadius: 14,
		right: theme.spacing(6),
		left: theme.spacing(6),
		zIndex: 200001,
	},
	texts: {
		textAlign: 'left',
		marginLeft: theme.spacing(6),
		marginRight: theme.spacing(6),
	},
	title: {
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(5),
	},
	subtitle: {
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		paddingBottom: theme.spacing(7),
	},
	buttons: {
		textAlign: 'right',
		paddingTop: theme.spacing(2),
	},
	button: {
		...theme.typography.subtitle1,
		color: theme.colors.neutral.C000,
		paddingRight: theme.spacing(4),
		paddingLeft: theme.spacing(4),
	},
}));

export default ({
	title,
	subtitle,
	onClick,
	open,
	onClose,
	leftButton,
	rightButton,
	background,
	rightButtonStyle,
}) => {
	const classes = useStyles();

	if (!open) {
		return null;
	}

	return (
		<div className={classes.root}>
			{background
			&& <div className={classes.background} />}
			<div className={classes.container}>
				<div className={classes.texts}>
					<Typography className={classes.title} variant="subtitle1">{title}</Typography>
					<Typography className={classes.subtitle} variant="body1">{subtitle}</Typography>
				</div>
				<div className={classes.buttons}>
					<Button variant="text" onClick={onClose} className={classes.button}>{leftButton}</Button>
					<Button
						variant="text"
						onClick={onClick}
						className={classes.button}
						style={rightButtonStyle}
					>
						{rightButton}
					</Button>
				</div>
			</div>

		</div>

	);
};
