import { registerRoute } from 'Router/registry';
import Terms from './Terms';

registerRoute({
	authorization: undefined,
	Component: Terms,
	routeOptions: {
		path: '/terms',
	},
	transition: 'slide',
});
