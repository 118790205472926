import { createAsyncThunk } from '@reduxjs/toolkit';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';

const constructUrlWithParams = (creatorId, uid, getState) => {
	const { trustedCreators } = getState().trustedCreators;
	const baseUrl = `destinations/${uid}/trusted-experiences/${creatorId}`;
	let urlWithParams = baseUrl;

	const uidsString = trustedCreators.map((item) => item.uid).join(',');
	if (uidsString) {
		urlWithParams += `?nextUsers=${uidsString}`;
	}

	const otherParams = ['limit=15', 'offset=0'];
	if (otherParams.length > 0) {
		urlWithParams += urlWithParams.includes('?') ? '&' : '?';
		urlWithParams += otherParams.join('&');
	}

	return urlWithParams;
};

export const requestTrustedCreators = createAsyncThunk(
	'requestTrustedCreators',
	async (uid, { rejectWithValue }) => {
		try {
			const response = await api.get(`experiences/destinations/${uid}/trusted-creators`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error, { notifyUser: false }));
		}
	},
);

export const requestTrustedExperiencesByCreator = createAsyncThunk(
	'requestTrustedExperiencesByCreator',
	async ({ uid, creatorId }, { getState, rejectWithValue }) => {
		const urlWithParams = constructUrlWithParams(creatorId, uid, getState);
		try {
			const response = await api.get(`experiences/${urlWithParams}`);
			return { data: response.data, creatorId };
		} catch (error) {
			return rejectWithValue(handleSliceError(error, { notifyUser: false }));
		}
	},
);
