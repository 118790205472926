import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	button: {
		width: 69,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

});

export default ({
	label,
	onClick,
	background,
	color,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.button} style={{ background, color }} onClick={onClick}>{label}</div>
		</div>
	);
};
