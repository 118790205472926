import React, {
	createContext,
	useContext,
	useState,
	Suspense,
	useEffect,
} from 'react';

const SuspenseContext = createContext();

export default ({ fallback, children }) => {
	const [suspended, setSuspended] = useState(true);

	const Fallback = (props) => {
		useEffect(() => {
			setSuspended(true);
			return () => setSuspended(false);
		}, []);

		return props.children;
	};

	return (
		<Suspense fallback={<Fallback>{fallback}</Fallback>}>
			<SuspenseContext.Provider value={suspended}>
				{children}
			</SuspenseContext.Provider>
		</Suspense>
	);
};

export function useSuspended() {
	return useContext(SuspenseContext);
}
