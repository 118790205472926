import React from 'react';
import { useNavigate } from 'react-router-dom';
import SignInForm from './SignInForm';
import PageLayout from '../components/layout/PageLayout';

export default () => {
	const navigate = useNavigate();

	return (
		<PageLayout
			title={(
				<div>
					explore.
					<br />
					{' '}
					connect. discover.
				</div>
			)}
			close={() => {
				navigate(-1);
			}}
		>
			<SignInForm />
		</PageLayout>
	);
};
