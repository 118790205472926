import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import FormItem from 'rds/components/FormItem';
import ButtonSpinner from 'rds/components/Spinners/Button';

const useStyles = makeStyles((theme) => ({
	button: {
		marginTop: theme.spacing(5),
		'&:hover': {
			backgroundColor: theme.colors.neutral.C200,
			color: theme.colors.neutral.C900,
		},
	},
}));

export default ({
	loading,
	children,
	size,
	...rest
}) => {
	const classes = useStyles();

	return (
		<FormItem>
			<Button
				className={classes.button}
				fullWidth
				size={size || 'small'}
				color="primary"
				type="submit"
				variant="contained"
				{...rest}
			>
				{ loading ? <ButtonSpinner size={12} /> : children}
			</Button>
		</FormItem>
	);
};
