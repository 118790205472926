import { createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

export const exploreLayoutSlice = createSlice({
	name: 'searchExploreLayout',
	initialState: {
		tab: 0,
		scroll: 0,
		isSearching: false,
	},
	reducers: {
		saveScroll: (state, action) => {
			state.scroll = action.payload || 0;
		},
		setTab: (state, action) => {
			state.tab = action.payload;
		},
		setIsSearching: (state, action) => {
			state.isSearching = action.payload;
		},
	},
});

const { name, reducer, actions } = exploreLayoutSlice;
const {
	saveScroll,
	setTab,
	setIsSearching,
} = actions;

export {
	saveScroll,
	setTab,
	setIsSearching,
};

export const getSlice = (state) => state[name];
export const getCurrentTab = createSelector(getSlice, (slice) => slice?.tab);
export const getScroll = createSelector(getSlice, (slice) => slice?.scroll);
export const getIsSearching = createSelector(getSlice, (slice) => slice?.isSearching);

injectReducer(name, reducer);
