import React, {
	useMemo,
	useEffect,
	useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
	getExploreData,
	requestLoadMoreExploreContents,
	getLoading,
	getLoadingMore,
	getIsFirstLoad,
} from 'app/slices/search-explore/explore';
import PaginationSkeleton from 'rds/components/Skeletons/PaginationSkeleton';
import EndOfContents from 'rds/components/EndOfContents';
import Column from './Column';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowX: 'hidden',
		transition: 'transform 0.3s ease-in-out',
		transform: 'translateX(0%)',
		paddingBottom: 300,
	},
	offScreenLeft: {
		transform: 'translateX(-100%)',
	},
	gridBox: {
		marginTop: theme.spacing(0),
		marginRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		paddingTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(3),
		alignItems: 'flex-start',
		overflowX: 'hidden',
	},
	column: {
		display: 'flex',
		flexDirection: 'row',
		flexFlow: 'row wrap',
	},
	item: {
		width: '100%',
		marginBottom: theme.spacing(5),
	},
	entity: {
		position: 'relative',
	},
	entityContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	loadingSkeleton: {
		marginBottom: theme.spacing(20),
	},
	end: {
		// height: 2,
		// width: '100%',
		// background: '#fff777',
	},
	noResult: {
		marginTop: theme.spacing(20),
		marginLeft: theme.spacing(4),
	},
}));

const ExploreComponent = ({ show }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const endRef = useRef();
	const loading = useSelector(getLoading);
	const loadingMore = useSelector(getLoadingMore);
	const isItFirstLoad = useSelector(getIsFirstLoad);

	const {
		cols,
		items,
		links,
	} = useSelector(getExploreData);

	const showNoResultFound = items && !items[0].length && !loading && !loadingMore && !isItFirstLoad;

	const observer = useMemo(
		() => new IntersectionObserver(([intersection]) => {
			if (!intersection.isIntersecting || !links || loadingMore || loading) {
				return;
			}
			dispatch(requestLoadMoreExploreContents());
		}),
		[links, loadingMore, loading],
	);

	useEffect(() => {
		if (endRef) {
			if (show) {
				observer.observe(endRef?.current);
			}
		}
		return () => observer.disconnect();
	}, [show, endRef, observer]);

	if (showNoResultFound) {
		return (
			<>
				<Typography className={classes.noResult} variant="body2">No filtered results found.</Typography>
				<div className={classes.end} ref={endRef} />
			</>
		);
	}

	return (
		<div className={classes.root}>
			<Box className={classes.gridBox}>
				{ items.map((c, i) => (
					<Column
						key={i}
						c={c}
						cols={cols}
						classes={classes}
						i={i}
					/>
				)) }
			</Box>
			{loadingMore
				&& (
					<div className={classes.loadingSkeleton}>
						<PaginationSkeleton />
					</div>
				)}
			<div className={classes.end} ref={endRef} />

			{!loadingMore && !loading && !links
			&& <EndOfContents />}
		</div>
	);
};

const arePropsEqual = (prevProps, nextProps) => {
	const equal = (
		JSON.stringify(prevProps.items) === JSON.stringify(nextProps.items)
		&& prevProps.show === nextProps.show
		&& prevProps.isSearching === nextProps.isSearching
	);

	return equal;
};

const MemoizedExploreComponent = React.memo(ExploreComponent, arePropsEqual);

export default MemoizedExploreComponent;
