import api, { CancelToken } from 'services/api';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';

const requestFormRequest = async ({
	dispatch, signal, rejectWithValue, getState,
}, {
	method, route, getFormPayload, setErrors,
}) => {
	const state = getState();
	const payload = getFormPayload(state);
	const source = CancelToken.source();

	signal.addEventListener('abort', () => {
		source.cancel();
	});

	try {
		const response = await api[method.toLowerCase()](route, payload, {
			cancelToken: source.token,
		});
		return [response, true, payload];
	} catch (error) {
		const errors = getErrorsFromRejectedRequest(error);
		dispatch(setErrors(errors));
		const value = rejectWithValue(errors);
		return [value, false, payload];
	}
};

export default requestFormRequest;
