import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Dialog, Typography } from '@material-ui/core';
import clsx from 'clsx';
import FixedTopControls from 'routes/ContentCreation/Components/Layout/FixedTopControls';
import counter from 'utils/str-counter';
import Counter from 'rds/components/Counter';
import { notifyErrors } from 'app/slices/notifications/notifications';

const TITLE_COUNT_DOWN = 15;
const CAPTION_COUNT_DOWN = 50;
const HARD_LIMIT = 50000;
const TIME_OUT = 300;

const useStyles = makeStyles((theme) => ({
	ExperienceTitle: {
		...theme.typography.subtitle1,
		maxHeight: 100,
		border: 'none',
		textAlign: 'left',
		marginBottom: theme.spacing(4),
		paddingRight: theme.spacing(4),
		color:
			theme.palette.type === 'dark'
				? theme.colors.neutral.C000
				: theme.colors.neutral.C900,
		outline: 'none',
		whiteSpace: 'pre-wrap',
		lineClamp: 2,
		display: 'box',
		overflow: 'hidden',
		wordBreak: 'break-word',
		textOverflow: 'ellipsis',
		boxOrient: 'vertical',
	},
	caption: {
		...theme.typography.body1,
		maxHeight: 100,
		border: 'none',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'pre',
		textAlign: 'left',
		marginBottom: theme.spacing(4),
		paddingRight: theme.spacing(4),
		color:
			theme.palette.type === 'dark'
				? theme.colors.neutral.C000
				: theme.colors.neutral.C900,
		outline: 'none',
	},
	captionM: {
		...theme.typography.body1,
		border: 'none',
		textAlign: 'left',
		marginRight: theme.spacing(4),
		marginLeft: theme.spacing(4),
		marginTop: theme.spacing(4),
		color:
			theme.palette.type === 'dark'
				? theme.colors.neutral.C000
				: theme.colors.neutral.C900,
		background: 'none',
		outline: 'none',
		whiteSpace: 'pre-wrap',
		resize: 'none',
		height: 'calc(100% - 110px)',
		'&::placeholder': {
			color: theme.colors.neutral.C300,
		},
	},
	titleM: {
		...theme.typography.subtitle1,
	},
	modal: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		right: 0,
		left: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		zIndex: 1000,
	},
	modalHeader: {
		display: 'flex',
		height: 48,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		paddingBottom: theme.spacing(4),
		paddingTop: theme.spacing(4),
		borderTop: 'none',
		background: theme.palette.background.page,
	},
	counter: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		left: 0,
	},
	hide: {
		display: 'none',
	},
}));

export default ({
	type,
	value,
	title,
	placeholder,
	modalPlaceHolder,
	maxLength,
	addAction,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const ref = useRef();
	const modalText = useRef();

	useEffect(() => {
		if (ref?.current) {
			ref.current.textContent = value;
		}
	}, [ref, value]);

	useEffect(() => {
		if (!modalText) {
			return;
		}

		if (!value.length && modalText.current) {
			modalText.current.placeholder = modalPlaceHolder;
		}
	}, [modalText, value]);

	const handleChange = (e) => {
		const inputValue = e.target.value;
		const updatedValue = inputValue.replace(/\n/g, '');
		dispatch(addAction(updatedValue));
	};

	const threshold = type === 'title' ? maxLength - TITLE_COUNT_DOWN : maxLength - CAPTION_COUNT_DOWN;

	return (
		<>
			<div
				contentEditable="true"
				ref={ref}
				className={
					type === 'title'
						? clsx(classes.ExperienceTitle, 'title')
						: clsx(classes.caption, 'caption')
				}
				onClick={(e) => {
					e.target.blur();
					setOpen(true);
				}}
				type="text"
				border="none"
				readOnly
				placeholder={placeholder}
			/>
			<Dialog
				fullScreen
				open={open}
				PaperProps={{ style: { background: theme.palette.background.page } }}
			>
				<div className={classes.modal}>
					<div className={classes.modalHeader}>
						<div className={classes.title}>
							<Typography variant="body1">{title}</Typography>
						</div>
						<FixedTopControls
							buttons={[
								{},
								{
									text: 'Done',
									style: { height: 48 },
									onClick: () => {
										if (counter(value) > maxLength) {
											setTimeout(() => {
												notifyErrors([{ title: `Unable to save ${type}`, text: 'Max character count exceeded.' }]);
											}, TIME_OUT);
											return;
										}
										dispatch(addAction(modalText.current.textContent));
										setOpen(false);
									},
								},
							]}
						/>
					</div>
					<textarea
						className={type === 'title' ? clsx(classes.captionM, classes.titleM) : classes.captionM}
						type="text"
						value={value}
						onKeyDown={(e) => {
							e.target.prevValue = e.target.value;
						}}
						onInput={(e) => {
							if (counter(e.target.value) > HARD_LIMIT) {
								e.target.value = e.target.prevValue;
							}
						}}
						onChange={(e) => {
							if (type === 'title') {
								handleChange(e);
							} else {
								dispatch(addAction(e.target.value));
							}
						}}
						onFocus={(e) => {
							const previousValue = e.target.value;
							e.target.value = '';
							e.target.value = previousValue;
						}}
						ref={modalText}
						placeholder={modalPlaceHolder}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus
					/>
				</div>
				<div className={classes.counter}>
					<Counter
						value={value}
						threshold={threshold}
						maxLength={maxLength}
					/>
				</div>
			</Dialog>
		</>
	);
};
