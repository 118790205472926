import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import { getSession } from 'app/slices/authentication/session/session';
import { useNavigate, useLocation } from 'react-router-dom';
import emEmitter from 'features/ExperienceModal/emitter';
import DestinationTag from '../DestinationTag';
import Comment from '../Comment';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		left: 0,
		zIndex: 10,
	},
	background: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		left: 0,
		height: '25%',
		background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);',
		zIndex: 2,
		pointerEvents: 'none',
	},
	info: {
		position: 'absolute',
		bottom: theme.spacing(21),
		left: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: '70vw',
	},
	title: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: '60vw',
		marginTop: theme.spacing(2),
	},
}));

export default ({
	destinations,
	openPanel,
	user,
	title,
	captionRtml,
}) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const session = useSelector(getSession);
	const experienceCreator = user.uuid;
	const signedInUser = session?.uuid;
	const location = useLocation();
	const isCreator = experienceCreator === signedInUser;
	let caption = '';

	const handleClick = () => {
		const parts = location?.pathname.split('/');
		if (destinations[0]?.uid === parts[2]) {
			emEmitter.emit('dismiss');
		} else {
			navigate(`/destination/${destinations[0]?.uid}`);
		}
	};

	try {
		caption = JSON.parse(captionRtml).text || '';
	} catch (_) {
		//
	}

	return (
		<div className="ecr-dismiss-quickfade">
			<div className={classes.root}>
				<div className={classes.info}>
					<DestinationTag
						destination={destinations[0]}
						onClick={handleClick}
						isCreator={isCreator}
					/>
					{title && (
						<Typography
							className={classes.title}
							variant="subtitle2"
							data-ignoreslider="true"
							onClick={() => { openPanel('details'); }}
						>
							{title}
						</Typography>
					)}
					{caption && (
						<Typography
							className={classes.caption}
							variant="caption"
							data-ignoreslider="true"
							onClick={() => { openPanel('details'); }}
						>
							{caption}
						</Typography>
					)}
				</div>
				<Comment openPanel={openPanel} />
			</div>
			<div className={classes.background} />
		</div>
	);
};
