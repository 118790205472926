import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import {
	getListOfComments,
	getNewCommentId,
	getNext,
	requestLoadMoreComments,
	getLoadingComments,
	resetNewCommentId,
} from 'app/slices/comments';
import { getDirection } from 'app/slices/layout';
import PaginationSkeleton from 'rds/components/Skeletons/PaginationSkeleton';
import clsx from 'clsx';
import Comment from './Comment';
import AddComment from './AddComment';
import EmptyContent from './EmptyContent';

const useStyles = makeStyles((theme) => ({
	root: {
		overscrollBehavior: 'contain',
	},
	container: {
		marginTop: theme.spacing(4),
		overflow: 'hidden',
		paddingBottom: 132,
		overscrollBehavior: 'contain',
	},
	listItem: {
		width: '100%',
		padding: 0,
		flexDirection: 'column',
	},
	skeleton: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: theme.spacing(2),
	},
	animation: {
		transition: 'opacity 10000ms',
	},
	buffer: {
		height: 300,
		display: 'block',
		width: '100%',
	},
}));

export default ({ find, uid, ...props }) => {
	const classes = useStyles();
	const observer = useRef();
	const ref = useRef();
	const dispatch = useDispatch();
	const [found, setFound] = useState(null);
	const direction = useSelector(getDirection);
	const listOfComments = useSelector(getListOfComments);
	const newCommentId = useSelector(getNewCommentId);
	const next = useSelector(getNext);
	const loading = useSelector(getLoadingComments);
	const isEmpty = !loading && !listOfComments.length;

	const lastCommentRef = useCallback(
		(node) => {
			if (!next) {
				return;
			}
			if (observer.current) {
				observer.current.disconnect();
			} else {
				observer.current = new IntersectionObserver((entries) => {
					if (entries[0].isIntersecting) {
						if (!loading) {
							dispatch(requestLoadMoreComments());
						}
					}
				});
			}
			if (node && observer.current) {
				observer.current.observe(node);
			}
		},
		[next],
	);

	useEffect(() => {
		const i = listOfComments.findIndex((c) => c.id === find);

		if (i !== -1) {
			setFound(find);
		}
	}, [uid, find, listOfComments]);

	useEffect(() => {
		if (newCommentId) {
			setFound(newCommentId);
		}
	}, [newCommentId]);

	useEffect(() => () => {
		setFound(-1);
		dispatch(resetNewCommentId());
	}, []);

	const klass = clsx(classes.container, classes.animation);

	return (
		<div className={classes.root} data-ignoreslider="true">
			{isEmpty && <EmptyContent />}
			<div
				ref={ref}
				className={klass}
				data-ignoreslider="true"
			>
				<List className={classes.list}>
					{listOfComments.map((c) => (
						<ListItem key={c.id} id={`comment-${c.id}`} className={classes.listItem}>
							<Comment
								find={find}
								highlight={direction !== 'back' && c.id === found}
								expand={c.new}
								hidden={direction === 'back'}
								scrollTo={newCommentId === c.id || c.id === found}
								scrollBehavior={
									direction === 'back'
										? 'auto'
										: 'smooth'
								}
								comment={c}
							/>
						</ListItem>
					))}
					{loading && (
						<ListItem className={`${classes.skeleton} fade-enter-active`}>
							<PaginationSkeleton />
						</ListItem>
					)}
				</List>
				<div ref={lastCommentRef} />
				{!isEmpty && <div className={classes.buffer} />}
			</div>
			<AddComment {...props} />
		</div>
	);
};
