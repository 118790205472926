import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getFirstVisit } from 'app/slices/user-account-v2/accounts';
import clsx from 'clsx';
import DisplayIdentifiers from './Display';
import FirstVisit from './FirstVisit';

const useStyles = makeStyles((theme) => ({
	content: {
		marginTop: theme.spacing(5),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		display: 'flex',
		justifyContent: 'center',
	},
	container: {
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	spacer: {
		paddingLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
	},
}));

export default ({ isMe }) => {
	const classes = useStyles();
	const firstVisit = useSelector(getFirstVisit);

	const klass = !firstVisit ? clsx(classes.container, classes.spacer) : clsx(classes.container);

	return (
		<>
			<div className={classes.content}>
				{
					isMe && firstVisit ? <FirstVisit />
						: null
				}
			</div>
			<div className={klass}>
				<DisplayIdentifiers isMe={isMe} />
			</div>
		</>
	);
};
