import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api, { CancelToken } from 'services/api';
import { startSession } from 'app/slices/authentication/session/session';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import { google as googleConfig } from 'config';
import { notifyErrors } from 'app/slices/notifications/notifications';
import GAEvent from 'components/GoogleAnalytics/event';

const JS = 'https://accounts.google.com/gsi/client';
let client;

const initialState = {
	loaded: false,
	loading: false,
	requesting: false,
	errors: [],
};

export const googleGrantSlice = createSlice({
	name: 'googleGrant',
	initialState: { ...initialState },
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
	},
});

const { name, reducer, actions } = googleGrantSlice;
const { reset, set } = actions;
export { reset, set };

export const loadGapis = createAsyncThunk(
	'loadGapis',
	(_, {
		dispatch,
	}) => {
		dispatch(set({
			errors: [],
			loading: true,
		}));

		const script = document.createElement('script');
		script.onerror = () => {
			dispatch(set({
				errors: [{ text: 'Failed to load google apis' }],
				loading: false,
			}));
		};
		script.onload = () => {
			client = window.google.accounts.oauth2.initCodeClient({
				client_id: googleConfig.clientId,
				scope: `https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/${googleConfig.scope}`,
				ux_mode: 'popup',
			});
			dispatch(set({
				loaded: true,
				loading: false,
			}));
		};
		script.setAttribute('src', JS);
		document.body.appendChild(script);
	},
);

export const googleSignIn = createAsyncThunk(
	'googleSignIn',
	async ({ redirect }, {
		dispatch, rejectWithValue,
	}) => {
		try {
			dispatch(set({
				errors: [],
				requesting: true,
			}));

			const result = await new Promise((resolve) => {
				try {
					client.callback = (response) => {
						if (response.error !== undefined) {
							resolve({ error: response.error });
							return;
						}
						resolve({ code: response.code });
					};
					client.error_callback = () => {
						dispatch(set({
							errors: [{ text: 'Google Sign-In was cancelled' }],
							loading: false,
							requesting: false,
						}));
					};

					client.requestCode();
				} catch (err) {
					resolve({ error: err });
				}
			});

			if (!result.code) {
				const errors = [{ text: 'Could not sign in with google' }];
				dispatch(set({
					errors,
					loading: false,
					requesting: false,
				}));
				notifyErrors(errors);
				return rejectWithValue(errors);
			}

			const source = CancelToken.source();
			const response = await api.post('/accounts/grant-google', { code: result.code }, {
				cancelToken: source.token,
			});
			GAEvent({ name: 'pwa_registration_page_button_google', uuid: response.data?.accountUuid });
			dispatch(startSession({ ...response.data, redirect }));
			return response.data;
		} catch (error) {
			const errors = getErrorsFromRejectedRequest(error, 'Could not sign in with google');
			dispatch(set({
				errors,
				loading: false,
				requesting: false,
			}));
			notifyErrors(errors);
			return rejectWithValue(errors);
		}
	},
);

export const getSlice = (state) => state[name];
export const getLoaded = createSelector(getSlice, (slice) => slice.loaded);
export const getLoading = createSelector(getSlice, (slice) => slice.loading);
export const getRequesting = createSelector(getSlice, (slice) => slice.requesting);
export const getResponse = createSelector(getSlice, (slice) => slice.response);
export const getErrors = createSelector(getSlice, (slice) => slice.errors);

injectReducer(name, reducer);
