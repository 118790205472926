import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectClips } from 'app/slices/content-creation/experience';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import useNavigateCC from 'app/hooks/useNavigateCC';
import FixedTopControls from '../Components/Layout/FixedTopControls';

export default ({ onClick, loading }) => {
	const [disabled, setDisabled] = useState(false);
	const navigateCC = useNavigateCC();

	const show = useSelector(getIsUpdatedVersionAvailable);
	const clips = useSelector(selectClips);

	return (
		<FixedTopControls
			buttons={[{
			}, {
				text: 'Done',
				ds: true,
				style: { top: show ? 48 : 0 },
				disabled: disabled || !clips.length,
				onClick: async () => {
					setDisabled(true);
					if (!loading) {
						await onClick();
						navigateCC('details');
					}
				},
			}]}
		/>
	);
};
