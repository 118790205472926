import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { buildVersion, devMode } from 'config';
import OverflowMenu from 'rds/components/OverflowMenu';
import { change, getRoute } from 'app/slices/experience-view';
import { useTheme } from '@material-ui/styles';
import CancelAlert from 'rds/components/CancelAlert';
import { getSession } from 'app/slices/authentication/session/session';
import {
	dismiss,
	deleteExperience,
	getVisibleExperience,
	getExperienceToModify,
	setExperienceToModify,
} from 'app/slices/experience-modal';
import { useFormUrl } from 'app/hooks/useQueryParams';
import emEmitter from 'features/ExperienceModal/emitter';
import getDeviceShareModal from 'utils/get-native-share';
import api from 'services/api';
import { notify } from 'app/slices/notifications/notifications';
import { getModifiedExperienceId, set } from 'app/slices/experience-view/edit';
import queueFetch from 'services/queue-fetch';
import useNavigateReportModal from 'app/hooks/useNavigateReportModal';
import { getIsModalOpen } from 'app/slices/reports/layout';

const ROUTES = [
	'overflow',
	'shareExperience',
	'editExperience',
	'deleteExperience',
];

const SHORT_POLL_TIMEOUT = 30000;

// same origin download
function downloadFile(url, filename) {
	// Create an invisible "a" element
	const element = document.createElement('a');
	element.setAttribute('href', url);
	element.setAttribute('download', filename);

	// Style the "a" element to be invisible
	element.style.display = 'none';

	// Append the "a" element to the body
	document.body.appendChild(element);

	// Simulate a click on the "a" element
	element.click();

	// Remove the "a" element from the body
	try {
		document.body.removeChild(element);
	} catch (_) {
		// safe to ignore
	}
}

// cross origin download file
// async function downloadFile(url, filename, onDownloadComplete) {
// 	// Fetch the resource
// 	const response = await fetch(url);

// 	// Error handling
// 	if (!response.ok) {
// 		throw new Error(`HTTP error! status: ${response.status}`);
// 	}

// 	// Get the blob from the response
// 	const blob = await response.blob();

// 	// Create a blob URL
// 	const blobUrl = URL.createObjectURL(blob);

// 	// Create a link element
// 	const link = document.createElement('a');
// 	link.href = blobUrl;
// 	link.download = filename;

// 	// Append the link to the body
// 	document.body.appendChild(link);

// 	// Simulate a click
// 	link.click();

// 	// Remove the link after a timeout
// 	setTimeout(() => {
// 		document.body.removeChild(link);
// 		URL.revokeObjectURL(blobUrl);
// 		onDownloadComplete();
// 	}, 100);
// }

export default ({
	slider,
}) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const session = useSelector(getSession);
	const route = useSelector(getRoute);
	const formUrl = useFormUrl();
	const visibleExperience = useSelector(getVisibleExperience);
	const isCreator = visibleExperience?.user?.uuid === session?.uuid;
	const overflowMenuItems = [];
	const selectedExperienceToModify = useSelector(getExperienceToModify);
	const { uid, title, user } = visibleExperience;
	const modifiedExperienceId = useSelector(getModifiedExperienceId);
	const [download, setDownload] = useState({ uid: null, url: null });
	const navigate = useNavigate();
	const isReportModalOpen = useSelector(getIsModalOpen);

	const navigateReport = useNavigateReportModal();

	useEffect(() => {
		if (uid === modifiedExperienceId) {
			setDownload(null);
			dispatch(set({ modifiedExperienceId: null }));
		}
	}, [uid, modifiedExperienceId]);

	useEffect(async () => {
		let canceled = false;
		let timeout;
		const shortPoll = async () => {
			if (canceled) {
				return;
			}
			try {
				const response = await api.get(`/experiences/${uid}/download`);
				const { url, status } = response.data;
				setDownload({ url, uid });
				if (status === 'notRequested') {
					api.post(`/experiences/${uid}/download`);
				}
				if (url) {
					canceled = true;
				}
			} catch (error) {
				// ignore
			} finally {
				timeout = setTimeout(shortPoll, SHORT_POLL_TIMEOUT);
			}
		};

		if (route === 'overflow') {
			shortPoll();
		}

		return () => {
			canceled = true;
			clearTimeout(timeout);
		};
	}, [uid, route]);

	useEffect(() => {
		if ((slider && ROUTES.indexOf(route) !== -1) || isReportModalOpen) {
			emEmitter.emit('pause');
			slider.disableGesture();
		} else {
			emEmitter.emit('play');
			slider?.enableGesture();
		}
	}, [route, slider, isReportModalOpen]);

	if (visibleExperience?.share) {
		overflowMenuItems.push(
			{
				label: 'Share',
				onClick: () => {
					dispatch(change({ route: 'shareExperience' }));
					getDeviceShareModal({
						text: visibleExperience?.share?.title,
						url: visibleExperience?.share?.shareUrl,
						onClose: () => { dispatch(change({ route: null })); },
					});
				},
			},
		);
	}

	const downloadCallback = useCallback(() => {
		if (!download?.url) {
			dispatch(notify({
				title: 'Feature unavailable',
				description: 'Try again later.',
				severity: 'info',
			}));
			dispatch(change({ route: null }));
			return;
		}
		try {
			downloadFile(download.url, `${title || 'RhinoExperience'}.mp4`);
			dispatch(change({ route: null }));
			dispatch(notify({
				severity: 'info',
				description: 'Download in progress',
			}));
		} catch (err) {
			queueFetch('/log/pwa', {
				method: 'POST',
				json: [{
					level: 'error',
					payload: {
						errorMessage: `downloadError-${err}`,
						stack: err.stack,
						buildVersion,
						environment: devMode ? 'dev' : 'prod',
					},
				}],
			});
			dispatch(notify({
				title: 'Unable to download experience',
				description: 'Try again later.',
				severity: 'info',
			}));
			dispatch(change({ route: null }));
		}
	}, [dispatch, uid, title, download]);

	overflowMenuItems.push({
		label: 'Download',
		disabledOnClick: () => {
			dispatch(notify({
				title: 'Feature unavailable',
				description: 'Try again later.',
				severity: 'info',
			}));
			dispatch(change({ route: null }));
		},
		disabled: !download?.url || download?.uid !== uid,
		onClick: downloadCallback,
	});

	if (isCreator) {
		overflowMenuItems.push({
			label: 'Edit Details',
			onClick: () => {
				dispatch(change({ route: 'editExperience' }));
			},
		},
		{
			label: 'Delete Experience',
			onClick: () => {
				dispatch(change({ route: 'deleteExperience' }));
			},
			color: theme.colors.error.C300,
		});
	} else {
		overflowMenuItems.push({
			label: 'Report',
			onClick: () => {
				dispatch(change({ route: null }));
				navigateReport.enter({
					type: 'experience',
					label: 'Report Experience',
					title: user?.username,
					id: uid,
				});
			},
			color: theme.colors.error.C300,
		});
	}

	return (
		<>
			<div data-ignoreslider="true">
				<OverflowMenu
					onClose={() => {
						dispatch(change({ route: null }));
						dispatch(setExperienceToModify(null));
					}}
					closeOnSelect={false}
					keepOnSelect
					show={route === 'overflow'}
					items={overflowMenuItems}
				/>
				<CancelAlert
					title="Are you sure?"
					subtitle="Deleting an experience cannot be undone."
					open={route === 'deleteExperience'}
					data-ignoreslider="true"
					onClick={async () => {
						await dispatch(deleteExperience(selectedExperienceToModify));
						const url = formUrl({
							params: {
								activity: undefined,
								community: undefined,
								experience: undefined,
							},
						});
						dispatch(dismiss());
						navigate(url);
						dispatch(change({ route: null }));
					}}
					leftButton="Cancel"
					rightButton="Delete"
					onClose={() => {
						dispatch(change({ route: null }));
					}}
				/>
			</div>
		</>
	);
};
