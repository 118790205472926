function calculateTrueBoxDimensions(element) {
	if (!element) return null;

	// Get the bounding box dimensions and position
	const bounding = element.getBoundingClientRect();

	// Get computed styles
	const style = window.getComputedStyle(element);

	// Extract border and border-radius properties
	const borderTopWidth = parseFloat(style.borderTopWidth);
	const borderBottomWidth = parseFloat(style.borderBottomWidth);
	const borderLeftWidth = parseFloat(style.borderLeftWidth);
	const borderRightWidth = parseFloat(style.borderRightWidth);
	const { borderRadius } = style;

	// Calculate width and height considering the borders
	const width = bounding.width - borderLeftWidth - borderRightWidth;
	const height = bounding.height - borderTopWidth - borderBottomWidth;

	return {
		top: bounding.top,
		left: bounding.left,
		width,
		height,
		borderWidth: {
			top: borderTopWidth,
			bottom: borderBottomWidth,
			left: borderLeftWidth,
			right: borderRightWidth,
		},
		borderRadius,
	};
}

export default calculateTrueBoxDimensions;
