import {
	getIndexExperiences,
	update,
	getLoading,
	requestIndexExperiences,
	requestLoadMoreIndexExperiences,
	getHasMoreToLoad,
} from './index';

export default {
	selector: getIndexExperiences,
	requestExperiences: requestIndexExperiences,
	requestMore: requestLoadMoreIndexExperiences,
	loadingSelector: getLoading,
	hasMoreToLoad: getHasMoreToLoad,
	update,
};
