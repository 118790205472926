import createElement from '../utils/create-element';

// const POSITION_GRAVITY = 0.45; // px
const POSITION_GRID = 20;
// const ROTATION_GRAVITY = 20; // degrees
// const ROTATION_GRID = 4;
const HIGHLIGHT_THRESHOLD = 2; // px

const klasses = {
	line: {
		position: 'absolute',
		'z-index': 2140000000,
		visibility: 'hidden',
	},
	horizontal: {
		'border-radius': '100%',
		'background-position': 'bottom',
		'background-size': '3px 3px',
		'background-repeat': 'repeat-x',
	},
	vertical: {
		'border-radius': '100%',
		'background-position': 'right',
		'background-size': '3px 3px',
		'background-repeat': 'repeat-y',
	},
	diagonal: {
	},
};

const getBgImage = (direction, highlight) => {
	const color = highlight ? '#EEF1F7' : '#EEF1F766';
	const prefix = direction === 'horizontal' ? 'to right, ' : '';
	return `linear-gradient(${prefix}${color} 50%, rgba(0,0,0,0) 0%)`;
};

const parseTransform = (transform) => {
	const parts = transform.split(' ');
	const t = {};

	for (let i = 0; i < parts.length; i++) {
		const tParts = parts[i].split('(');

		if (tParts.length < 2) {
			continue;
		}

		const key = tParts[0].trim();
		const value = tParts[1].split(')')[0].trim();

		t[key] = value;
	}

	return t;
};

const getRotation = (el) => {
	if (!el.style.transform) {
		return 0;
	}

	const t = parseTransform(el.style.transform);
	return (parseInt(t.rotate, 10) % 360) || 0;
};

export default class Grid {
	constructor(canvas) {
		this.canvas = canvas;
		this.fixed = {};
		this.flexible = {};

		const create = (klass, styles) => {
			const s = { ...klasses.line, ...klasses[klass], ...styles };

			let style = '';
			Object.keys(s).forEach((k) => {
				style += `${k}: ${s[k]}; `;
			});

			return createElement({
				attributes: { style },
				tagName: 'div',
			});
		};

		this.fixed.horizontal = create('horizontal', {
			left: 0,
			right: 0,
			top: 'calc(50% - 1px)',
			height: '2px',
			'background-image': 'linear-gradient(#EEF1F7 12.5%, rgba(0,0,0,0) 0%)',
		});

		this.fixed.vertical = create('vertical', {
			top: 0,
			bottom: 0,
			left: 'calc(50% - 1px)',
			width: '2px',
			'background-image': 'linear-gradient(to right, #EEF1F7 12.5%, rgba(0,0,0,0) 0%)',
		});

		this.fixed.rotateHorizontal = create('rotateHorizontal', {
			left: 0,
			right: 0,
			top: 'calc(50% - 1px)',
			height: '2px',
			background: 'blue',
		});

		this.flexible.horizontal = create('horizontal', {
			left: 0,
			right: 0,
			top: 'calc(50% - 1px)',
			height: '2px',
			background: '#1BB6B6',
			// 'background-image': 'linear-gradient(to right, #ffff00b3 67.5%, rgba(0,0,0,0) 0%)',
		});

		this.flexible.vertical = create('vertical', {
			top: 0,
			bottom: 0,
			left: 'calc(50% - 1px)',
			width: '2px',
			background: '#1BB6B6',
			// 'background-image': 'linear-gradient(#ffff00b3 67.5%, rgba(0,0,0,0) 0%)',
		});

		this.flexible.diagonal = create('diagonal', {
			height: '2px',
			width: `${window.innerHeight}px`,
			'margin-left': `${-window.innerHeight / 2}px`,
			background: '#1BB6B6',
		});

		const container = createElement({ tagName: 'div' });
		container.appendChild(this.fixed.vertical);
		container.appendChild(this.fixed.rotateHorizontal);
		container.appendChild(this.fixed.horizontal);
		container.appendChild(this.flexible.horizontal);
		container.appendChild(this.flexible.vertical);
		container.appendChild(this.flexible.diagonal);
		this.canvas.container.appendChild(container);
	}

	hide() {
		['fixed', 'flexible'].forEach((t) => {
			Object.keys(this[t]).forEach((k) => {
				this[t][k].style.visibility = 'hidden';
			});
		});
	}

	hideFlexible(direction) {
		this.flexible[direction].style.visibility = 'hidden';
	}

	showFlexible(direction, position, rotation = 0) {
		this.flexible[direction].style.visibility = 'visible';

		Object.keys(position).forEach((k) => {
			this.flexible[direction].style[k] = `${position[k]}px`;
		});

		if (rotation) {
			this.flexible[direction].style.transform = `rotate(${rotation}deg)`;
		}
	}

	hideFixed(direction) {
		this.fixed[direction].style.visibility = 'hidden';
	}

	showFixed(direction, highlight) {
		let bgimg;
		if (highlight) {
			bgimg = getBgImage(direction, highlight);
		} else {
			bgimg = getBgImage(direction, highlight);
		}

		this.fixed[direction].style['background-image'] = bgimg;
		this.fixed[direction].style.visibility = 'visible';
	}

	showRotateHorizontal() {
		this.showFixed('rotateHorizontal');
	}

	hideRotateHorizontal() {
		this.hideFixed('rotateHorizontal');
	}

	getCenters(el, rect) {
		const positionOffset = {
			height: el.offsetTop,
			width: el.offsetLeft,
		};

		const rotationOffset = {
			height: (el.offsetHeight - rect.height) / 2,
			width: (el.offsetWidth - rect.width) / 2,
		};

		const horizontalCenter = this.canvas.rect.height / 2;
		const verticalCenter = this.canvas.rect.width / 2;
		const objectHorizontalCenter = ((rect.height / 2) + positionOffset.height)
			+ rotationOffset.height;
		const objectVerticalCenter = ((rect.width / 2) + positionOffset.width) + rotationOffset.width;

		return {
			horizontalCenter,
			verticalCenter,
			objectHorizontalCenter,
			objectVerticalCenter,
		};
	}

	// setTransform(el, degree) {
	//	 const t = parseTransform(el.style.transform);
	//	 t.rotate = `${degree}deg`;
	//	 let str = '';
	//	 for (const k in t) {
	//		 str += `${k}(${t[k]}) `;
	//	 }
	//	 el.style.transform = str;
	// }

	render(el) {
		const rect = el.getBoundingClientRect();
		const {
			horizontalCenter,
			verticalCenter,
			objectHorizontalCenter,
			objectVerticalCenter,
		} = this.getCenters(el, rect);

		const diffX = Math.abs(horizontalCenter - objectHorizontalCenter);
		if (diffX <= POSITION_GRID) {
			this.showFixed('horizontal', diffX < HIGHLIGHT_THRESHOLD);
		} else {
			this.hideFixed('horizontal');
		}

		const diffY = Math.abs(verticalCenter - objectVerticalCenter);
		if (diffY <= POSITION_GRID) {
			this.showFixed('vertical', diffY < HIGHLIGHT_THRESHOLD);
		} else {
			this.hideFixed('vertical');
		}

		// object rotation lines
		// const degree = getRotation(el);
		// let hit = false;
		// let showHorizontal = false;
		// let showVertical = false;
		// let showDiagonal = false;

		// [45, 135, 225, 315].forEach((b) => {
		// 	const diff = Math.abs(b - degree);
		// 	if (diff <= ROTATION_GRID) {
		// 		showDiagonal = b;
		// 	}
		// 	if (diff === 0) {
		// 		hit = true;
		// 	}
		// });
		// [0, 180].forEach((b) => {
		// 	const diff = Math.abs(b - degree);
		// 	if (diff <= ROTATION_GRID) {
		// 		showHorizontal = true;
		// 	}
		// 	if (diff === 0) {
		// 		hit = true;
		// 	}
		// });
		// [90, 270].forEach((b) => {
		// 	const diff = Math.abs(b - degree);
		// 	if (diff <= ROTATION_GRID) {
		// 		showVertical = true;
		// 	}
		// 	if (diff === 0) {
		// 		hit = true;
		// 	}
		// });

		// if (showHorizontal) {
		// 	this.showFlexible('horizontal', {
		// 		top: objectHorizontalCenter,
		// 	});
		// } else {
		// 	this.hideFlexible('horizontal');
		// }

		// if (showVertical) {
		// 	this.showFlexible('vertical', {
		// 		left: objectVerticalCenter,
		// 	});
		// } else {
		// 	this.hideFlexible('vertical');
		// }

		// if (showDiagonal) {
		// 	this.showFlexible('diagonal', {
		// 		top: objectHorizontalCenter,
		// 		left: objectVerticalCenter,
		// 	}, showDiagonal);
		// } else {
		// 	this.hideFlexible('diagonal');
		// }

		// if (hit && vibrate) {
		// 	vibrate();
		// }
	}

	snap() {
		const adj = {
			rotation: null,
			position: { left: null, top: null },
		};

		return adj;

		// TODO: this snap functionality doesn't feel right, yet
		//
		// [90, 270].forEach(b => {
		//	 if (Math.abs(b - degree) <= ROTATION_GRAVITY) {
		//		 adj.rotate = b;
		//	 }
		// });
		// [0, 180, 360].forEach(b => {
		//	 if (Math.abs(b - degree) <= ROTATION_GRAVITY) {
		//		 adj.rotate = b;
		//	 }
		// });

		// const rect = el.getBoundingClientRect();
		// const {
		//	 horizontalCenter,
		//	 verticalCenter,
		//	 objectHorizontalCenter,
		//	 objectVerticalCenter,
		// } = this.getCenters(el, rect);

		// if (Math.abs(horizontalCenter - objectHorizontalCenter) <= POSITION_GRAVITY) {
		//	 adj.position.top = rect.top - (objectHorizontalCenter - horizontalCenter);
		// }

		// if (Math.abs(verticalCenter - objectVerticalCenter) <= POSITION_GRAVITY) {
		//	 adj.position.left = rect.left - (objectVerticalCenter - verticalCenter);
		// }

		// return adj;
	}

	resolve(el) {
		const degree = getRotation(el);

		const adj = this.snap(el, degree);
		this.render(el, degree);
		return adj;
	}
}
