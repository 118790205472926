import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';

export const requestSendReport = createAsyncThunk(
	'requestSendReport',
	async ({
		resourceType,
		resourceId,
		reason,
		details,
	}, { rejectWithValue }) => {
		try {
			const response = await api.post(`support/report/${resourceType}/${resourceId}`, {
				reason,
				other: 'other',
				details,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const reportSlice = createSlice({
	name: 'report',
	initialState: {
		errors: null,
		loading: false,
	},
	reducers: {},
	extraReducers: {
		[requestSendReport.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestSendReport.fulfilled]: (state, action) => {
			state.profile = action.payload;
			state.loading = false;
			state.errors = null;
		},
		[requestSendReport.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = reportSlice;
export { name, reducer, actions };

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);

injectReducer(name, reducer);
