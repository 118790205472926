import React, { useEffect, useRef, useState } from 'react';
import TextField from 'rds/components/TextField';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles({
	eyeContainer: {
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: -8,
	},
});

export default ({
	flashSuccess,
	placeholder,
	variant,
	theme,
	darkContrast,
	signin,
	...rest
}) => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const inputRef = useRef();
	theme = theme || useTheme();

	useEffect(() => {
		if (inputRef.current) {
			const start = inputRef.current.selectionStart;
			const end = inputRef.current.selectionEnd;
			setTimeout(() => {
				inputRef.current.setSelectionRange(start, end);
			}, 0);
		}
	}, [showPassword]);

	return (
		<TextField
			theme={theme}
			name="password"
			variant={variant}
			placeholder={placeholder}
			type={showPassword ? 'text' : 'password'}
			autoComplete="current-password"
			formItem
			onPaste={(e) => {
				e.preventDefault();
				return false;
			}}
			inputRef={inputRef}
			icon={{
				icon: 'Key',
				size: 20,
			}}
			right={(
				<div
					className={classes.eyeContainer}
					onMouseDown={(e) => {
						e.preventDefault();
						setShowPassword(!showPassword);
					}}
				>
					<IconV3
						icon={showPassword ? 'EyeOn' : 'EyeOff'}
						size={16}
						containerSize={16}
					/>
				</div>
			)}
			{...rest}
		/>
	);
};
