import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	getMyStats,
	getTheirStats,
	getMyLoadingProfile,
	getTheirLoadingProfile,
} from 'app/slices/user-account-v2/accounts';
import LoadingSkeleton from 'rds/components/LoadingSkeleton';
import { useLocation } from 'react-router-dom';
import Stat from './Stat';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
}));

export default ({ isMe, rootStyle }) => {
	const classes = useStyles();
	const stats = useSelector(isMe ? getMyStats : getTheirStats);
	const loading = useSelector(isMe ? getMyLoadingProfile : getTheirLoadingProfile);

	const location = useLocation();

	const baseCurrentPath = location.pathname;
	const queryParams = location.search;

	const followingPath = `${baseCurrentPath}/follow/following${queryParams}`;
	const followersPath = `${baseCurrentPath}/follow/followers${queryParams}`;

	const label = stats?.followers?.robot === 1 ? 'Follower' : 'Followers';

	if (loading) {
		return <LoadingSkeleton lines={1} />;
	}

	return (
		<div className={classes.root} style={rootStyle}>
			<Stat stat={stats?.experienceCount} label="Experiences" />
			<Stat
				stat={stats?.followingTotal}
				label="Following"
				to={followingPath}
			/>
			<Stat
				stat={stats?.followers}
				label={label}
				to={followersPath}
			/>
		</div>
	);
};
