import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import SwitchInput from 'rds/components/SwitchInput/SwitchInput';
import {
	getPushNotificationsOn,
	togglePushNotifications,
} from 'app/slices/account-settings/notifications/push-notifications';
import { getPushTarget, requestNotifications } from 'app/slices/account-settings/notifications';
import SettingItemWithField from '../../SettingItemWithField';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(6),
		paddingLeft: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingRight: theme.spacing(4),
		borderTop: `1px solid ${theme.colors.neutral.C800}`,
	},
	switch: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(4),
	},

}));

// return true if on, false otherwise
// subgroup is on if the selected option has types
const getOnOff = (subGroups) => {
	for (let i = 0; i < subGroups.length; i++) {
		const sg = subGroups[i];
		const { selectedOption } = sg;
		const { types } = sg.options.find((o) => o.slug === selectedOption);

		if (types.length) {
			return true;
		}
	}

	return false;
};

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const on = useSelector(getPushNotificationsOn);
	const pushTarget = useSelector(getPushTarget);

	return (
		<div className={classes.root}>
			<div className={classes.switch}>
				<Typography variant="subtitle1">
					Push Notifications
				</Typography>
				<SwitchInput
					label="push notifications"
					checked={on}
					onChange={async () => {
						await dispatch(togglePushNotifications());
						await dispatch(requestNotifications());
					}}
				/>
			</div>

			<Collapse collapsedSize={0} in={on}>
				{
					pushTarget && pushTarget.notificationTypeGroups.map(({ slug, title, subGroups }) => (
						<SettingItemWithField
							key={title}
							title={title}
							value={getOnOff(subGroups) ? 'On' : 'Off'}
							to={`/my-account/settings/notifications/${slug}`}
						/>
					))
				}
			</Collapse>
		</div>
	);
};
