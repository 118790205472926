import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'fixed',
		flexDirection: 'column',
		justifyContent: 'center',
		top: '30%',
		width: '100%',
		textAlign: 'center',
	},
	headline: {
		marginRight: theme.spacing(6),
		marginLeft: theme.spacing(6),
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',

	},
	subtitle: {
		marginRight: theme.spacing(6),
		marginLeft: theme.spacing(6),
		color: theme.colors.neutral.C300,
	},
	iconContainer: {
		marginLeft: 'calc(50% - 40px)',
		marginTop: theme.spacing(11.5),
		marginBottom: theme.spacing(5),
	},

}));

export default () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Typography className={classes.headline} variant="h5">No saved experiences</Typography>
			<div className={classes.details}>
				<div className={classes.iconContainer}>
					<IconV3
						icon="BookmarkUnfilled"
						containerSize={80}
						size={80}
					/>
				</div>

				<Typography
					className={classes.subtitle}
					variant="subtitle1"
				>
					Tap this icon on any experience to save and view here at any time
				</Typography>
			</div>

		</div>
	);
};
