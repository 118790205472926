import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';
// ensures dependencies (accountSettingsPushNotifications) are available
import { clearTargetId } from './push-notifications';

export const requestNotifications = createAsyncThunk(
	'requestNotifications',
	async (_, { dispatch, getState, rejectWithValue }) => {
		const { uuid } = getState().session.session;
		const { initComplete, targetId } = getState().accountSettingsPushNotifications;

		try {
			const response = await api.get(`notifications/${uuid}/preferences`);
			const emailTarget = response.data.targets.find((t) => t.targetType === 'email');
			const pushTarget = response.data.targets.find((t) => t.id === targetId);

			if (!pushTarget && initComplete) {
				dispatch(clearTargetId());
			}

			return {
				emailTarget,
				pushTarget,
			};
		} catch (err) {
			return rejectWithValue(handleSliceError(err));
		}
	},
);

export const patchPushGroup = createAsyncThunk(
	'patchPushGroup',
	async ({ targetId, option, group }, { getState, rejectWithValue }) => {
		const { uuid } = getState().session.session;

		try {
			const response = await api.patch(`notifications/${uuid}/preferences/target/${targetId}/group/${group}`, {
				option,
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(handleSliceError(err));
		}
	},
);

const initialState = {
	pushTarget: null,
	emailTarget: null,
	loading: null,
	errors: null,
};

const userAccountNotificationsSlice = createSlice({
	name: 'userAccountNotifications',
	initialState: { ...initialState },
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
	},
	extraReducers: {
		[patchPushGroup.pending]: (state, action) => {
			const { option, group, slug } = action.meta.arg;

			const ntgId = state.pushTarget.notificationTypeGroups.findIndex((ntg) => ntg.slug === slug);
			const sgId = state.pushTarget.notificationTypeGroups[ntgId].subGroups.findIndex((sg) => sg.slug === group);
			state.pushTarget.notificationTypeGroups[ntgId].subGroups[sgId].selectedOption = option;
		},

		[patchPushGroup.rejected]: (state, action) => {
			const { previous, group, slug } = action.meta.arg;

			const ntgId = state.pushTarget.notificationTypeGroups.findIndex((ntg) => ntg.slug === slug);
			const sgId = state.pushTarget.notificationTypeGroups[ntgId].subGroups.findIndex((sg) => sg.slug === group);
			state.pushTarget.notificationTypeGroups[ntgId].subGroups[sgId].selectedOption = previous;
		},

		[requestNotifications.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestNotifications.fulfilled]: (state, action) => {
			state.emailTarget = action.payload.emailTarget;
			state.pushTarget = action.payload.pushTarget;
			state.loading = false;
		},

		[requestNotifications.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = userAccountNotificationsSlice;
const { reset } = actions;

export {
	name, reset,
};

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getPreferences = createSelector(getSlice, (slice) => (
	slice.emailTarget || slice.pushTarget
		? { emailTarget: slice.emailTarget, pushTarget: slice.pushTarget }
		: null
));
export const getEmailTarget = createSelector(getSlice, (slice) => slice?.emailTarget);
export const getPushTarget = createSelector(getSlice, (slice) => slice?.pushTarget);
export const getPushTargetGroup = (key) => createSelector(getSlice, (slice) => {
	const targetId = slice.pushTarget.id;
	const group = slice.pushTarget.notificationTypeGroups.find((ntg) => ntg.slug === key);

	return {
		targetId,
		...group,
	};
});
export default userAccountNotificationsSlice;

injectReducer(name, reducer);
