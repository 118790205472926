const px = (s) => `${s}px`;

const getShadow = (el) => {
	let { shadow } = el;

	if (!shadow) {
		shadow = el.cloneNode(true);
		el.shadow = shadow;
		// shadow.textContent = el.textContent;
		shadow.style.removeProperty('position');
		shadow.style.visibility = 'hidden';
		shadow.style.height = 'auto';
		shadow.style.width = 'auto';
		shadow.innerHTML = shadow.innerHTML || 'YO!';
		// shadow.style.whiteSpace = 'break-spaces';
		document.body.appendChild(shadow);
	}

	return shadow;
};

const findHeight = (el, { minHeight, paddingBottom }) => {
	const shadow = getShadow(el);

	let { height } = shadow.getBoundingClientRect();
	height += paddingBottom;
	return Math.max(height, minHeight);
};

export default (el, {
	doNotApply = false,
	targetTop = 0.40,
	maxHeight = () => window.innerHeight,
	minFontSize = 1,
	fontSize = 40,
	minHeight = 50,
	paddingBottom = 4,
}) => {
	const targetFontSize = fontSize;
	const fit = () => {
		const height = findHeight(el, { minHeight, paddingBottom });

		if (height > maxHeight()) {
			if (fontSize > minFontSize) {
				fontSize--;
				el.shadow.style.fontSize = px(fontSize);
				fit();
				return;
			}
		}
		const targetTopPx = (
			(window.innerHeight * targetTop) + targetFontSize / 2)
			- (height / 2);
		const top = Math.max(0, Math.min(window.innerHeight, targetTopPx));
		el.style.top = px(top);
		el.style.fontSize = px(fontSize);

		for (let i = 0; i < el.children.length; i++) {
			const c = el.children[i];
			c.style.pointerEvents = 'none';
			c.style.removeProperty('font-size');
		}

		el.style.height = px(height);
		el.shadow.remove();
		el.shadow = null;
	};
	el.triggerTextFit = fit;
	el.addEventListener('change', fit);
	el.addEventListener('keyup', fit);

	if (!doNotApply) {
		fit();
	}

	return () => {
		el.removeEventListener('change', fit);
		el.removeEventListener('keyup', fit);
	};
};
