import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMatch, set } from 'app/slices/content-creation/create/text-component';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { getRecommendations, requestRecommendations } from 'app/slices/content-creation/recommendations';
import PickerLayout from './StylePicker/PickerLayout';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		height: 72,
		zIndex: 2140000002,
		background: 'linear-gradient(180deg, rgba(27, 36, 45, 0) -9.13%, rgba(27, 36, 45, 0.2) 26.08%, rgba(27, 36, 45, 0.4) 62.03%, rgba(27, 36, 45, 0.6) 96.51%, rgba(27, 36, 45, 0.8) 131.73%)',
		width: '100%',
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		display: 'flex',
	},
	user: {
		display: 'flex',
		width: 68,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	userImage: {
		marginRight: theme.spacing(3),
		marginLeft: theme.spacing(3),
		borderRadius: '50%',
		width: 44,
		height: 44,
	},
	name: {
		width: 68,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		marginTop: theme.spacing(),
		textOverflow: 'ellipsis',
		textAlign: 'center',
	},
	notFound: {
		position: 'fixed',
		left: theme.spacing(4),
		bottom: theme.spacing(4),
	},
}));

const DEBOUNCE = 250;

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const match = useSelector(selectMatch);
	const recommendations = useSelector(getRecommendations);

	useEffect(() => {
		const to = setTimeout(() => {
			if (match) {
				dispatch(requestRecommendations(match));
			}
		}, DEBOUNCE);

		return () => {
			clearTimeout(to);
		};
	}, [match]);

	const onMouseDown = (u) => (e) => {
		e.preventDefault();

		dispatch(set({
			recommendedValue: {
				match,
				value: u.entity.username.toLowerCase(),
				uuid: u.entity.uuid,
			},
		}));
	};

	if (!match) {
		return null;
	}

	if (recommendations && !recommendations.entities.length) {
		return (
			<Typography variant="subtitle2" className={classes.notFound}>No users found</Typography>
		);
	}

	return (
		<PickerLayout>
			<div className={classes.root}>
				{recommendations && recommendations.entities.map((u, i) => (
					<div
						key={i}
						className={classes.user}
						onMouseDown={onMouseDown(u)}
					>
						<div style={{ backgroundImage: `url(${u.entity.avatar.large.jpg})`, backgroundSize: 'cover' }} className={classes.userImage} />
						<Typography variant="body2" className={classes.name}>
							{u.entity.username.toLowerCase()}
						</Typography>
					</div>
				))}
			</div>
		</PickerLayout>
	);
};
