import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		background: theme.palette.background.page,
		display: 'flex',
		flexDirection: 'column',
		// left: 0,
		// right: 0,
		width: '100%',
		zIndex: 13,
		overflowY: 'scroll',
		overflowX: 'hidden',
		overscrollBehavior: 'contain',
		borderRadius: 10,
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
}));

export default ({ height, children }) => {
	const classes = useStyles();
	const divRef = useRef(null);

	const style = {
		top: height,
		bottom: 0,
	};

	useEffect(() => {
		const handleTouchMove = (e) => {
			const { deltaY, target } = e;

			// Scrolling up, but already at the top.
			if (deltaY < 0 && target.scrollTop <= 0) {
				e.preventDefault();
			}

			// Scrolling down, but already at the bottom.
			if (deltaY > 0 && target.scrollHeight - target.scrollTop <= target.clientHeight) {
				e.preventDefault();
			}
		};

		if (divRef.current) {
			divRef.current.addEventListener('touchmove', handleTouchMove, { passive: false });
		}

		return () => {
			if (divRef.current) {
				divRef.current.removeEventListener('touchmove', handleTouchMove);
			}
		};
	}, []);

	return (
		<div className={classes.root} style={style} ref={divRef}>
			{children}
		</div>
	);
};
