import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import {
	getTheirInteractions,
	getTheirFollowing,
	getTheirFollowRequest,
	getTheirProfileUuid,
	getTheirLoadingProfile,
} from 'app/slices/user-account-v2/accounts';
import { requestToggleFollow } from 'app/slices/user-account-v2/following-actions';
import LoadingSkeleton from 'rds/components/LoadingSkeleton';
import { change } from 'app/slices/destination-account-v2/trusted-experiences/slice';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		marginTop: theme.spacing(4),
		paddingLeft: theme.spacing(3.5),
		paddingRight: theme.spacing(3.5),
	},
	button: {
		width: 130,
		height: 36,
		backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
			color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		},
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const interactions = useSelector(getTheirInteractions);
	const blocked = interactions?.callerIsBlocking;
	const following = useSelector(getTheirFollowing);
	const requested = useSelector(getTheirFollowRequest);
	const uuid = useSelector(getTheirProfileUuid);
	const loading = useSelector(getTheirLoadingProfile);

	if (blocked) {
		return null;
	}

	const style = {};

	if (following || requested) {
		style.background = 'none';
		style.color = theme.colors.neutral.C000;
		style.border = `1px solid ${theme.colors.neutral.C000}`;
	}

	const label = following ? 'Following' : requested ? 'Requested' : 'Follow';

	if (loading) {
		return <LoadingSkeleton lines={1} />;
	}

	return (
		<div className={classes.root}>
			<Button
				variant="contained"
				disableRipple
				disableFocusRipple
				className={classes.button}
				style={style}
				onClick={async () => {
					await dispatch(requestToggleFollow({ uuid, type: !following ? 'follow' : 'unfollow' }));
					dispatch(change({ shouldReloadTrustedCreators: true }));
				}}
			>
				{label}
			</Button>
		</div>
	);
};
