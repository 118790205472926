import React from 'react';
import { useParams } from 'react-router-dom';
import CreateAvatar from './CreateAvatar';
import EditAvatar from './EditAvatar';

const AvatarCapture = () => {
	const { action } = useParams();

	if (action === 'create') {
		return <CreateAvatar />;
	}

	if (action === 'edit') {
		return <EditAvatar />;
	}

	return null;
};

export default AvatarCapture;
