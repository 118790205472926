import assetManager from 'lib/asset-manager';
import {
	FILTER,
	HTML,
	GIF,
	IMAGE,
	VIDEO,
} from 'lib/editing-canvas/src/types';
import getDuration from './get-duration';
import getRegions from './get-regions';
import zipAssets from './zip-assets';

const imageTypes = [HTML, IMAGE, FILTER];

const getType = (node) => {
	if (node.background) {
		return 'background';
	}

	if (node.type === VIDEO) {
		return 'video';
	}

	if (imageTypes.includes(node.type)) {
		return 'image';
	}

	if (node.type === GIF) {
		return 'gif';
	}

	throw new Error('Type unsupported');
};

const waitFor = (t) => new Promise((resolve) => setTimeout(resolve, t));

export const waitForAll = async (ecs) => {
	for (let i = 0; i < ecs.length; i++) {
		const { editingCanvas } = ecs[i];
		const rootNode = editingCanvas.store.get(editingCanvas.root);

		if (!rootNode || !editingCanvas.compiledPreview) {
			// not ready yet
			await waitFor(100);
			await waitForAll(ecs);
			break;
		}
	}

	return true;
};

const compileAll = async (ecs) => {
	const assetMap = {};
	const allNodes = [];
	const allTouchRegions = [];

	let startMod = 0;
	let cover = null;
	let coverBlob = null;

	await waitForAll(ecs);

	for (let i = 0; i < ecs.length; i++) {
		const { editingCanvas, muted } = ecs[i];
		const { nodes } = await editingCanvas.canvas.getNodeRenderingData();

		const duration = await getDuration(editingCanvas);
		const clipStart = startMod;
		const clipEnd = startMod + duration;

		if (!cover) {
			coverBlob = editingCanvas.compiledPreview.image;
			cover = editingCanvas.canvas?.preview.src;
		}

		for (let j = 0; j < nodes.length; j++) {
			const node = nodes[j];

			if (!node.asset) {
				node.asset = assetManager.set(node.sourceData);
			}

			assetMap[node.asset.key] = node.asset;

			const type = getType(node);

			const nodeData = {
				type,
				size: node.size,
				file: node.asset.filename,
				start: clipStart,
				end: clipEnd,
				position: node.position,
				rotation: node.rotation,
			};

			if (type === 'video') {
				nodeData.muteAudio = !!muted;
				nodeData.videoStart = node.start || 0;
				nodeData.videoEnd = node.stop || duration;
			}

			allNodes.push(nodeData);
		}

		const touchRegions = getRegions(editingCanvas);
		allTouchRegions.push(...touchRegions.map((tr) => ({
			...tr,
			start: clipStart,
			end: clipEnd,
			rotation: tr.position.rotation,
			size: { height: tr.position.height, width: tr.position.width },
			position: { x: tr.position.x, y: tr.position.y },
		})));

		startMod += duration;
	}

	const assets = [];
	Object.keys(assetMap).forEach((key) => {
		assets.push(assetMap[key]);
	});

	const zipFile = await zipAssets(assets);

	return {
		cover,
		coverBlob,
		touchRegions: allTouchRegions,
		nodes: allNodes,
		assets,
		zip: {
			contentLength: zipFile.size,
			origin: window.location.origin,
		},
		zipFile,
	};
};

export default compileAll;
