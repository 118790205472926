import { useEffect } from 'react';
import scrollEmitter from 'layout/scrollEmitter';

const DURATION = 300;

const usePageScrollEvents = () => {
	useEffect(() => {
		const smoothScrollTo = (target, duration) => {
			const pageDiv = document.querySelector('.page-root');
			const start = pageDiv.scrollTop;
			const distance = target - start;
			let startTime;

			function animation(currentTime) {
				if (!startTime) startTime = currentTime;

				const timeElapsed = currentTime - startTime;
				const run = ease(timeElapsed, start, distance, duration);

				pageDiv.scrollTo(0, run);

				if (timeElapsed < duration) requestAnimationFrame(animation);
			}

			const ease = (t, b, c, d) => -c * (t /= d) * (t - 2) + b;

			requestAnimationFrame(animation);
		};
		const scrollToTop = (options) => {
			const speed = options && options.speed ? options.speed : 'auto';
			if (speed === 'slow') {
				smoothScrollTo(0, DURATION);
			} else {
				const pageDiv = document.querySelector('.page-root');
				if (pageDiv) {
					pageDiv.scrollTo(0, 0);
				}
			}
		};

		const scrollToBottom = () => {
			const pageDiv = document.querySelector('.page-root');
			if (pageDiv) {
				pageDiv.scrollTo(0, document.body.scrollHeight);
			}
		};

		scrollEmitter.addListener('scrollToTop', (options) => (scrollToTop(options)));
		scrollEmitter.addListener('scrollToBottom', scrollToBottom);

		return () => {
			scrollEmitter.removeListener('scrollToTop', scrollToTop);
			scrollEmitter.removeListener('scrollToBottom', scrollToBottom);
		};
	}, [scrollEmitter]);
};

export default usePageScrollEvents;
