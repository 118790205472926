import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	getVisibleExperience,
	getOpenDirective,
	setOpenDirective,
	getTransitioned,
	setTransitioned,
} from 'app/slices/experience-modal';
import { countRouterCall, getRouterCalls, getDirection } from 'app/slices/layout';
import waitFor from 'utils/wait-for';
import ECRLoading from './ECRLoading';
import {
	// tickCompanion,
	tickInstant,
	tickPartialSlide,
	tickSlide,
	tickSetup,
} from './ecr-transition-utils';
import emitter from './emitter';

const useStyles = makeStyles(() => ({
	experienceModal: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: '100%',
		width: '100%',
		zIndex: 15,
		overflow: 'hidden',
		MsOverflowStyle: 'none',
		scrollbarWidth: 'none',
		whiteSpace: 'nowrap',
		touchAction: 'none',
		transform: 'scale(0)',
		transformOrigin: 'top left',
		opacity: 0,
	},
	companion: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: 0,
		scrollbarWidth: 'none',
		whiteSpace: 'nowrap',
		touchAction: 'none',
		zIndex: 9,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		display: 'inline-block',
		borderRadius: 5,
	},
}));

const ECRTransition = ({
	children,
	fRef,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const lockRef = useRef(false);
	const companionRef = useRef();
	const transitioned = useSelector(getTransitioned);
	const routerCalls = useSelector(getRouterCalls);
	const visibleExperience = useSelector(getVisibleExperience);
	const direction = useSelector(getDirection);
	const openDirective = useSelector(getOpenDirective) || {};

	useEffect(() => {
		if (!visibleExperience) {
			lockRef.current = null;
		}
	}, [visibleExperience]);

	const {
		type = 'instant',
		querySelector = '',
	} = openDirective;

	useEffect(async () => {
		if (lockRef.current || transitioned) {
			return;
		}
		let t = type;
		let qs = querySelector;

		//
		const done = async (ms = 100) => {
			emitter.emit('opening');
			await waitFor(ms);
			emitter.emit('opened');
			dispatch(setTransitioned(true));
		};

		dispatch(countRouterCall());
		lockRef.current = true;

		if (t === 'slideIn') {
			await tickSlide(fRef, 'forward');
			await done();
			// lockRef.current = false;
			return;
		}

		if (t === 'instant' && routerCalls < 2) {
			tickInstant(fRef, companionRef);
			await done();
			// lockRef.current = false;
			return;
		}

		if (t === 'instant') {
			if (direction === 'back') {
				await tickPartialSlide(fRef, direction);
				await done();
				lockRef.current = false;
				return;
			}
			t = 'expansion';
			qs = '[data-experience-modal-id="missing"]';
		}

		if (t === 'expansion') {
			const el = document.querySelector(qs);

			const ignore = el
				? el.getAttribute('data-ignore-experience-modal') === 'true'
				: true;

			if (el && !ignore) {
				// const clone = el.cloneNode(true);
				// clone.style.margin = 0;
				// clone.style.paddingBottom = 0;

				// if (companionRef.current) {
				// 	companionRef.current.appendChild(clone);
				// }
			}

			if (!el) {
				tickInstant(fRef, companionRef);
				await done();
				return;
			}

			const rect = el.getBoundingClientRect();

			if (ignore) {
				await tickSetup(fRef, companionRef, rect, {
					expansionTransition: 'all 300ms ease-in-out',
				});
			} else {
				await tickSetup(fRef, companionRef, rect);
			}

			await tickInstant(fRef);
			// await tickCompanion(companionRef);
			dispatch(setOpenDirective(null));
			await done();
			// lockRef.current = false;
		}
	}, [
		querySelector,
		routerCalls,
		fRef,
		companionRef,
		lockRef,
		type,
		transitioned,
	]);

	if (!visibleExperience) {
		if (type === 'instant') {
			return <ECRLoading />;
		}

		return null;
	}

	return (
		<div>
			<div className={classes.experienceModal} ref={fRef}>
				{children}
			</div>
			<div className={classes.companion} ref={companionRef} />
		</div>
	);
};

export default ECRTransition;
