import { createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

const initialState = {
	activeMenu: null,
	route: null,
	editProfileModal: false,
};

const name = 'userAccountV2Layout';

const layoutSlice = createSlice({
	name,
	initialState,
	reducers: {
		openDotsMenu: (state) => {
			state.activeMenu = 'DOTS_MENU';
		},
		openBottomMenu: (state, action) => {
			state.activeMenu = action.payload;
		},
		closeMenu: (state) => {
			state.activeMenu = null;
		},
		updateRoute: (state, action) => {
			state.route = action.payload;
		},
		toggleEditProfileModal: (state, action) => {
			state.editProfileModal = action.payload;
		},
	},
});

export const {
	openDotsMenu,
	openBottomMenu,
	closeMenu,
	updateRoute,
	toggleEditProfileModal,
} = layoutSlice.actions;

export default layoutSlice.reducer;

injectReducer(name, layoutSlice.reducer);
