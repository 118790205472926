import store from 'store';

const useCDLValues = () => {
	const p = store.get('android-port');
	const ShouldShowPermissons = store.get('ShouldShowPermissons');
	const ZeroState = store.get('ZeroState');
	const AndroidPort = store.get('AndroidPort');

	return {
		port: p,
		ShouldShowPermissons,
		ZeroState,
		AndroidPort,
	};
};

export default useCDLValues;
