import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import requestFormRequest from 'services/request-form-request';
import { getFormPayload, setErrors } from './forgot-password-form';

export const requestForgotPassword = createAsyncThunk(
	'requestForgotPassword',
	async (navigate, options) => {
		const [response, success] = await requestFormRequest(options, {
			method: 'POST',
			route: '/accounts/grant-password/reset',
			getFormPayload,
			setErrors,
		});

		if (success) {
			navigate('/forgot-password/sent');
		}

		return response.data;
	},
);

export const forgotPasswordSlice = createSlice({
	name: 'forgotPassword',
	initialState: {
		succeed: false,
		errors: null,
		loading: false,
	},
	extraReducers: {
		[requestForgotPassword.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestForgotPassword.fulfilled]: (state) => {
			state.succeed = true;
			state.loading = false;
			state.errors = null;
		},

		[requestForgotPassword.rejected]: (state, action) => {
			state.succeed = false;
			state.loading = false;
			state.errors = action.payload;
		},
	},

});

const { name, reducer } = forgotPasswordSlice;
export { name };

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);

injectReducer(name, reducer);
