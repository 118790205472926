import JSZip from 'jszip';

// function downloadBlob(blob, filename) {
// 	const url = URL.createObjectURL(blob);
// 	const a = document.createElement('a');
// 	a.href = url;
// 	a.download = filename;
// 	document.body.appendChild(a);
// 	a.click();
// 	document.body.removeChild(a);
// 	URL.revokeObjectURL(url);
// }

/**
 * Create a zip file containing the provided assets.
 *
 * @param {Array} assets - An array of assets, each with "asset.blob" and "asset.filename".
 * @returns {Promise<Blob>} A Promise that resolves with the zip file as a Blob.
 */
async function zipAssets(assets) {
	const zip = new JSZip();
	const experience = zip.folder('experience');

	// Add each asset to the zip file
	assets.forEach((asset) => {
		experience.file(asset.filename, asset.blob);
	});

	// Generate the zip file as a Blob
	const zipFile = await zip.generateAsync({ type: 'blob' });

	// downloadBlob(zipFile, 'exp.zip');

	return zipFile;
}

export default zipAssets;
