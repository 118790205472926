import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Button } from '@material-ui/core';
import SupportModal from 'rds/components/ReportModal/SupportModal';
import buttons from 'rds/components/ReportModal/ReportOptions';
import Header from 'rds/components/ReportModal/Header';
import { set } from 'app/slices/reports/layout';

const useStyles = makeStyles((theme) => ({
	button: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
		textAlign: 'left',
		height: 48,
		paddingLeft: theme.spacing(4),
	},
}));

export default ({ label, title, type }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<SupportModal
			title={title}
			contentStyle={{ marginRight: 0, marginLeft: 0 }}
			type={type}
		>
			<Header
				label={label}
				type={type}
			/>
			{buttons.map((b, i) => (
				<Button
					key={i}
					className={classes.button}
					onClick={() => {
						dispatch(set({
							formTitle: b,
							route: 'details',
						}));
					}}
				>
					{b.title}
				</Button>
			))}
		</SupportModal>
	);
};
