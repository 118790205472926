import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';
import { getTheirProfileUuid } from '../accounts';

export const requestUserExperiences = createAsyncThunk(
	'requestUserExperiences',
	async ({ accountUuid }, { rejectWithValue, getState }) => {
		try {
			accountUuid = accountUuid || getTheirProfileUuid(getState());
			const response = await api.get(`experiences/users/${accountUuid}?limit=${12}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestMergeUserExperiences = createAsyncThunk(
	'requestMergeUserExperiences',
	async ({ accountUuid }, { rejectWithValue }) => {
		try {
			const response = await api.get(`experiences/users/${accountUuid}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestLoadMoreExperiences = createAsyncThunk(
	'requestLoadMoreExperiences',
	async (_, { rejectWithValue, getState }) => {
		const { next } = getState().userAcccountV2Experiences;

		if (!next) {
			return null;
		}

		try {
			const response = await api.get(`${next}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);
