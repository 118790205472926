export default ({
	height: containerHeight,
	width: containerWidth,
}, {
	height: imageHeight,
	width: imageWidth,
}) => {
	const cAr = containerHeight / containerWidth;
	const iAr = imageHeight / imageWidth;

	let height;
	let width;
	let scale;

	// image is taller than wide
	if (iAr > cAr) {
		width = containerWidth;
		height = containerWidth * iAr;
		scale = (height - ((height - containerHeight) / 2)) / containerHeight;
	} else {
		height = containerHeight;
		width = containerHeight * (1 / iAr);
		scale = (width - ((width - containerWidth) / 2)) / containerWidth;
	}

	return {
		scale,
		height,
		width,
		containerHeight,
		containerWidth,
	};
};
