import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getAlbumsOpen } from 'app/slices/content-creation/create/device-library/state';
import { getAlbums } from 'app/slices/content-creation/create/device-library/albums';
import EachAlbum from './EachAlbum';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(40),
	},
	secondary: {
		paddingTop: theme.spacing(18),
	},

}));

export default () => {
	const classes = useStyles();
	const albumsOpen = useSelector(getAlbumsOpen);
	const albums = useSelector(getAlbums);

	return (
		<div className={!albumsOpen ? classes.root : classes.secondary}>
			{albums && albums.map((album) => (
				<EachAlbum key={album.name} album={album} />
			))}
		</div>
	);
};
