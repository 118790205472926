import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectClipCount } from 'app/slices/content-creation/experience';
import IconV3 from 'rds/components/IconV3/IconV3';
import { fetchPermissions, getShouldShowPermissionsRationale, clearShowPermissionsRationale } from 'app/android-service';
import { enableGateway } from 'app/slices/content-creation/create/device-library/state';
import { formSettingsDeepLink } from 'app/android-service/util';
import { notify } from 'app/slices/notifications';
import useNavigateCC from 'app/hooks/useNavigateCC';
import useStyles from './use-styles';

const AUTO_OPEN_DELAY = 700;

export default ({
	autoOpen,
	onQuickDisable,
	params,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigateCC = useNavigateCC();
	const clipCount = useSelector(selectClipCount);

	const onClickCallback = useCallback(async () => {
		const go = await fetchPermissions();

		if (!go && clipCount > 0) {
			// if not go & clip count > 0, disable it
			onQuickDisable();
		} else if (getShouldShowPermissionsRationale()) {
			clearShowPermissionsRationale();
			// if we should show permissions, show them
			dispatch(notify({
				autoHideDuration: null,
				severity: 'error',
				title: 'Unable to access photos',
				description: 'Go to Settings to grant Rhino access to your device library.',
				buttons: [{
					label: 'Go to Settings',
					link: formSettingsDeepLink(),
				}, {
					label: 'Cancel',
				}],
			}));
		} else if (params.subTab !== 'library') {
			await dispatch(enableGateway());
			navigateCC.enterLibrary();
		}
	}, [clipCount, params]);

	useEffect(() => {
		if (autoOpen) {
			setTimeout(onClickCallback, AUTO_OPEN_DELAY);
		}
	}, [autoOpen]);

	return (
		<IconV3
			className={classes.iconButton}
			icon="PhotoLibrary"
			dropShadow
			size={32}
			onClick={onClickCallback}
		/>
	);
};
