import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		alignContent: 'center',
		margin: '0 auto',
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
}));

export default ({
	children,
	...rest
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root} {...rest}>
			{children}
		</div>
	);
};
