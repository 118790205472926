/*
 * Note: Environment variable names must be prefixed with REACT_APP_
 */
require('dotenv').config();

const devMode = process.env.REACT_APP_DEV_MODE === '1';
const apiHost = process.env.REACT_APP_API || 'https://api.dev.roamrhino.com/api';
const deployedOnDev = process.env.REACT_APP_DEPLOYED_ON_DEV === '1';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '76845936038-qfu8md51597afoe1vidr02ssp1g5kcl3.apps.googleusercontent.com';
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || '659065524660112';
const clientType = process.env.REACT_APP_CLIENT_TYPE || 'pwa';
const buildVersion = process.env.REACT_APP_BUILD_VERSION || '';
const vapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY || 'BLdeOK_BINveSpYivunW8g_hrWmCMUE89OPE8s_itzt3Si1ZQ_U6nwsAEjuYF4C3fYSwOluRpTIxI25DnLZiT-E';
const appleStoreLink = 'https://apps.apple.com/us/app/roam-rhino/id1512823922';
const playStoreLink = 'https://play.google.com/store/apps/details?id=com.roamrhino.app.twa';
const deepLinkScheme = (devMode || deployedOnDev) ? 'devrhino' : 'rhino';
const analyticsTrackingId = (devMode || deployedOnDev) ? 'G-PG4M2ZY29M' : 'G-8P39N1TBY7';

const google = {
	clientId: googleClientId,
	scope: 'user.birthday.read',
};
const facebook = {
	clientId: facebookAppId,
};

export {
	clientType,
	analyticsTrackingId,
	apiHost,
	deepLinkScheme,
	deployedOnDev,
	devMode,
	facebook,
	google,
	buildVersion,
	appleStoreLink,
	playStoreLink,
	vapidKey,
};
