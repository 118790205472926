import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const OFFSET = 48;

const useStyles = makeStyles({
	spacer: {
		height: 0,
	},
});

export default () => {
	const classes = useStyles();
	const [spacerHeight, setSpacerHeight] = useState(0);

	useEffect(() => {
		const updateSpacerHeight = () => {
			const destinationElement = document.querySelector('[data-filters-container="destination"]');
			if (destinationElement) {
				const destinationTop = destinationElement.getBoundingClientRect().top;
				const windowHeight = window.innerHeight;
				const extraHeightNeeded = windowHeight - destinationTop;

				if (extraHeightNeeded > 0) {
					setSpacerHeight(extraHeightNeeded - OFFSET);
				}
			}
		};

		window.addEventListener('resize', updateSpacerHeight);
		updateSpacerHeight();

		return () => window.removeEventListener('resize', updateSpacerHeight);
	}, []);

	return (
		<div
			className={classes.spacer}
			style={{ height: spacerHeight }}
		/>
	);
};
