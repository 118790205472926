import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'rds/components/Layouts/Form';
import {
	control, resetForm,
} from 'app/slices/authentication/reset-password/form';
import api from 'services/api';
import { getLoading, requestResetPassword } from 'app/slices/authentication/reset-password';
import PageSpinners from 'rds/components/Spinners/Page';
import useRdsTheme from 'rds/theme/useRdsTheme';
import { useNavigate } from 'react-router-dom';
import PasswordField from '../components/fields/PasswordField';
import SubmitButton from '../components/SubmitButton/SubmitButton';

export default ({ token }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const fields = control.get(dispatch);
	const loading = useSelector(getLoading);
	const [password, passwordErr, passwordTmpErr] = fields.password.values;
	const theme = useRdsTheme('dark');
	const [tokenValidated, setTokenValidated] = useState(false);

	const onSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			if (loading) {
				return;
			}

			const response = await dispatch(requestResetPassword({ token }));
			if (response.meta.requestStatus === 'fulfilled') {
				navigate('/forgot-password/reset/success');
			}
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(resetForm());
	}, [dispatch]);

	useEffect(async () => {
		let url = '/forgot-password/expired';

		try {
			const response = await api.put(`accounts/grant-password/reset/${token}/check`);
			const { email, ok } = response.data;

			if (ok) {
				setTokenValidated(true);
			} else {
				if (email) {
					url += `?email=${btoa(email)}`;
				}

				navigate(url);
			}
		} catch (err) {
			navigate(url);
		}
	}, [dispatch, token]);

	const disabled = !!passwordErr || !password || password.length < 12;

	if (!tokenValidated) {
		return (<PageSpinners />);
	}

	return (
		<Form onSubmit={onSubmit}>
			<PasswordField
				theme={theme}
				variant="glass"
				placeholder="Enter new password (min. 12)"
				value={password}
				err={passwordErr}
				tmpErr={passwordTmpErr}
				onChange={fields.password.change}
				flashSuccess
			/>
			<SubmitButton loading={loading} disabled={disabled}>Reset Password</SubmitButton>
		</Form>
	);
};
