import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import EachActivity from './EachActivity';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(11),
		marginBottom: theme.spacing(6),
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
	},
	titles: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(),
	},
	left: {
		display: 'flex',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},

}));

export default ({ activities }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.titles}>
				<div className={classes.left}>
					<IconV3
						icon="ListCheck"
						style={{
							marginRight: 12,
						}}
						size={20}
						containerSize={20}
					/>
					<Typography variant="subtitle1">Activities</Typography>
				</div>
			</div>
			<div className={classes.container}>
				{activities && activities.map((a, i) => (
					<EachActivity activity={a} index={i} key={i} />
				))}
			</div>
		</div>
	);
};
