import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';

const SECTIONS = [
	['North America', 'Latin America & the Caribbean'],
	['Europe', 'Africa'],
	['Asia', 'Oceania'],
];

// helper functions
const addIt = (regions, uid) => {
	const next = regions.slice();
	next.push(uid);
	return next;
};

const removeIt = (regions, uid) => {
	const next = regions.slice();
	next.splice(next.findIndex((a) => uid === a), 1);
	return next;
};

export const requestCategories = createAsyncThunk(
	'requestGetCategories',
	async (_, { rejectWithValue }) => {
		try {
			const response = await api.get('destinations/regions');
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestRegions = createAsyncThunk(
	'requestGetRegions',
	async (_, { rejectWithValue }) => {
		try {
			const response = await api.get('accounts/preferences/least-known-regions');
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestPatchRegion = createAsyncThunk(
	'requestPatchRegion',
	async ({ uid, remove }, {
		rejectWithValue,
	}) => {
		try {
			let response;
			if (remove) {
				response = await api.patch('accounts/preferences/remove-least-known-region', {
					leastKnownRegion: uid,
				});
			} else {
				response = await api.patch('accounts/preferences/least-known-region', {
					leastKnownRegion: uid,
				});
			}
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

// slice
export const regionsSlice = createSlice({
	name: 'regions',
	initialState: {
		categories: null,
		regions: null,
		errors: null,
		categoriesLoading: false,
		regionsLoading: false,
	},
	extraReducers: {
		[requestCategories.pending]: (state) => {
			state.categoriesLoading = true;
			state.errors = null;
		},

		[requestCategories.fulfilled]: (state, action) => {
			state.categories = action.payload.categories;
			state.loading = false;
			state.errors = null;
		},

		[requestCategories.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},

		[requestRegions.pending]: (state) => {
			state.regionsLoading = true;
			state.errors = null;
		},

		[requestRegions.fulfilled]: (state, action) => {
			state.regions = action.payload.regions;
			state.loading = false;
			state.errors = null;
		},

		[requestRegions.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},

		[requestPatchRegion.pending]: (state, payload) => {
			const { remove, uid } = payload.meta.arg;

			if (remove) {
				state.regions = removeIt(state.regions, uid);
			} else {
				state.regions = addIt(state.regions, uid);
			}
		},

		[requestPatchRegion.rejected]: (state, action) => {
			if (action.payload) {
				if (action.payload.remove) {
					state.regions = addIt(state.regions, action.payload.uid);
				} else {
					state.regions = removeIt(state.regions, action.payload.uid);
				}
			}
		},
	},
});

const { name, reducer } = regionsSlice;
export { name };

export const getSlice = (state) => state[name];
export const getCategoriesLoading = createSelector(getSlice, (slice) => slice?.categoriesLoading);
export const getRegionsLoading = createSelector(getSlice, (slice) => slice?.regionsLoading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);
export const getCategories = createSelector(getSlice, (slice) => slice?.categories);
export const getRegions = createSelector(getSlice, (slice) => slice?.regions);
export const getSections = (index) => createSelector(getSlice, (slice) => {
	const titles = SECTIONS[index];
	const categories = slice?.categories ?? [];
	const ret = [];

	titles.forEach((t) => {
		const v = categories.find((r) => r.name === t);
		if (v) {
			ret.push({
				...v,
				regions: v.regions.map((r) => ({
					...r,
					checked: slice.regions.findIndex((i) => i === r.uid) !== -1,
				})),
			});
		}
	});

	return ret;
});

injectReducer(name, reducer);
