import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	replyBannerContainer: {
		position: 'absolute',
		width: '100%',
		background: theme.colors.neutral.C800,
		top: theme.spacing(-10.5),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: theme.spacing(2),
	},
	replyBanner: {
		padding: theme.spacing(3.5, 5),
		color: theme.colors.neutral.C200,
	},

}));

export default ({
	commentToReply,
	onClose,
	...props
}) => {
	const classes = useStyles();

	if (!commentToReply) {
		return null;
	}

	return (
		<div className={classes.replyBannerContainer} {...props}>
			<Typography variant="subtitle2" className={classes.replyBanner}>
				Reply to
				{' '}
				{commentToReply?.author?.username}
			</Typography>
			<IconV3
				icon="Cancel"
				color="neutralC200"
				size={20}
				containerSize={32}
				onClick={onClose}
			/>
		</div>
	);
};
