import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getConstants,
	requestApp,
	getErrors as getConstantsErrors,
	getLoading as getAppLoading,
} from 'app/slices/app/app';
import { requestPatchOnboarding } from 'app/slices/onboarding/onboarding';
import RequestProvider from 'rds/providers/Request';
import Skeleton from 'rds/components/Skeletons/PrimaryPage';
import { getIsOnboardingCompleted } from 'app/slices/authentication/session/session';
import { set } from 'app/slices/app-navigations/navigations';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from 'app/hooks/useQueryParams';
import PageLayout from '../Layout/Page';
import PageContents from './PageContents';

export default () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isOnboardingCompleted = useSelector(getIsOnboardingCompleted);
	const isItRedirectedFromNotification = useQueryParam('notificationRedirect');

	useEffect(() => {
		if (isOnboardingCompleted && !isItRedirectedFromNotification) {
			navigate('/');
		}
	}, [isOnboardingCompleted]);

	return (
		<RequestProvider
			Skeleton={Skeleton}
			requests={{
				constants: {
					selector: getConstants,
					action: requestApp,
					loading: getAppLoading,
					errors: getConstantsErrors,
				},
			}}
		>
			<PageLayout
				header="Which activities are you interested in?"
				top={{
					next: {
						label: 'Next',
						to: isItRedirectedFromNotification ? '/onboarding/destinations?notificationRedirect=true' : '/onboarding/destinations',
						onClick: () => {
							dispatch(requestPatchOnboarding({ activities: true }));
						},
					},
					back: {
						label: 'Cancel',
						onClick: async () => {
							await dispatch(requestPatchOnboarding({
								activities: true, destinations: true, regions: true,
							}));
							dispatch(set({ currentTab: 4 }));
							navigate('/my-account');
						},
					},
				}}
			>
				<PageContents />
			</PageLayout>
		</RequestProvider>
	);
};
