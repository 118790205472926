import React from 'react';
import Item from './Item';

const getWidthStyle = (size) => ({ width: `${(size / 12) * 100}%` });

export default ({
	c,
	classes,
	cols,
	i,
}) =>	 (
	<div key={i} className={classes.column} style={getWidthStyle(cols[i].size)}>
		{
			c.map(({
				aspectRatio,
				template,
				entity,
			}) => (
				<Item
					aspectRatio={aspectRatio}
					key={entity.uid}
					classes={classes}
					entity={entity}
					template={template}
				/>
			))
		}
	</div>
);
