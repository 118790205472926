import React, { useRef, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import {
	getMedia,
	requestLoadMoreMedia,
	getHasMoreMedia,
	getLoading,
} from 'app/slices/content-creation/create/device-library/media';
import WaveSkeleton from 'components/WaveSkeleton';
import EachMedia from './EachMedia';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		overflowY: 'auto',
		maxHeight: '100vh',
		paddingBottom: theme.spacing(50),
	},
	itemContent: {
		display: 'block',
		paddingBottom: '100%',
		position: 'relative',
		marginRight: 1,
		marginLeft: 1,
		marginTop: theme.spacing(0.5),
	},
	skeletonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
}));

export default ({ selected }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const media = useSelector(getMedia);
	const hasMoreMedia = useSelector(getHasMoreMedia);
	const loading = useSelector(getLoading);
	const endRef = useRef();

	const observer = useMemo(
		() => new IntersectionObserver(([intersection]) => {
			if (!intersection.isIntersecting || !hasMoreMedia || loading) {
				return;
			}
			dispatch(requestLoadMoreMedia());
		}),
		[hasMoreMedia, loading],
	);

	useEffect(() => {
		observer.observe(endRef.current);
		return () => observer.disconnect();
	}, [endRef, observer]);

	return (
		<Grid container className={classes.content} rowspacing={4}>
			{
				media.map((m, i) => (
					<Grid item xs={4} key={i}>
						<div className={classes.itemContent}>
							<EachMedia
								key={i}
								selected={selected}
								media={m}
							/>
						</div>
					</Grid>
				))
			}
			{loading && (
				<div className={classes.skeletonContainer}>
					<WaveSkeleton
						style={{
							marginTop: 16,
							height: 24,
							width: 188,
						}}
					/>
				</div>
			)}
			<div ref={endRef} />
		</Grid>
	);
};
