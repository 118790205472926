import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import Avatar from 'rds/components/Avatar';
import Typography from 'rds/components/Typography';
import WaveSkeleton from 'components/WaveSkeleton';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: `calc(100% - ${theme.spacing(2)}px)`,
		height: '100%',
		overflow: 'hidden',
		marginLeft: theme.spacing(),
		marginRight: theme.spacing(),
	},
	avatarContainer: {
		marginBottom: theme.spacing(),
	},
	title: {
		whiteSpace: 'pre-wrap',
		textAlign: 'left',
		display: 'box',
		boxOrient: 'vertical',
		overflow: 'hidden',
		lineClamp: 2,
		wordBreak: 'break-word',
		width: '100%',
	},
	destinationTitle: {
		textAlign: 'center',
		lineClamp: 1,
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	destination: {
		color: theme.colors.neutral.C200,
		whiteSpace: 'pre-wrap',
		textAlign: 'left',
		display: 'box',
		boxOrient: 'vertical',
		overflow: 'hidden',
		lineClamp: 1,
		width: '100%',
		wordBreak: 'break-all',
	},
}));

export default ({
	avatar,
	title,
	avatarSize,
	titleVariant,
	destination,
	subtitle,
	destinationVariant,
	rootStyle,
	loaded,
	pattern,
	skeletonWidth,
	skeletonHeight,
	titleStyle,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const skeletonStyle = {};

	if (pattern === 'standard') {
		skeletonStyle.marginBottom = theme.spacing();
	}

	if (!loaded && pattern === 'wide') {
		return (
			<>
				<WaveSkeleton
					style={{
						...skeletonStyle,
						borderRadius: '100%',
						height: skeletonHeight,
						width: skeletonWidth,
						marginBottom: theme.spacing(6),
					}}
				/>
			</>
		);
	}

	return (
		<div className={classes.root} style={rootStyle}>
			<div className={classes.avatarContainer}>
				<Avatar
					alt="Explore avatar"
					size={avatarSize}
					src={avatar}
				/>
			</div>
			{title
				&& (
					<Typography
						variant={titleVariant}
						className={(pattern === 'wide') ? clsx(classes.title, classes.destinationTitle) : classes.title}
						style={{
							...titleStyle,
							textAlign: (pattern === 'wide' || pattern === 'big') ? 'center' : 'left',
						}}
					>
						{title}
					</Typography>
				)}
			{(destination || subtitle) && (
				<Typography
					variant={destinationVariant}
					className={classes.destination}
					style={{
						...titleStyle,
						textAlign: pattern !== 'standard' ? 'center' : 'left',
					}}
				>
					{destination?.title || subtitle}
				</Typography>
			)}
		</div>

	);
};
