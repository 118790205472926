let image;

export const setImage = (img) => {
	image = img;
};

export const getImage = () => image;

export const clear = () => {
	image?.remove();
	image = null;
};
