import React from 'react';
import ECRLink from 'features/ExperienceModal/ECRLink';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useUserAccountLink from 'app/hooks/useUserAccountLink';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	user: {
		display: 'inline-block',
		color: theme.colors.neutral.C100,
		marginRight: theme.spacing(),
	},
	mention: {
		color: theme.colors.primary.C200,
		marginRight: 2,
		display: 'inline-block',
	},
}));

const TokenTypes = Object.freeze({
	TEXT: 'text',
	MENTION: 'mention',
	EMOJI: 'emoji',
});

export default function CommentTokenView({ comment, users, author }) {
	const classes = useStyles();
	const redirectTo = useUserAccountLink(author);

	return (
		<div>
			<Typography component={ECRLink} to={redirectTo} className={classes.user} variant="title2">
				{author}
			</Typography>
			{comment?.contents?.map((token, index) => {
				const { type, value } = token;

				if (type === TokenTypes.MENTION) {
					const user = users[value];
					const { username } = user;
					const to = `/${username}`;

					return (
						<ECRLink key={index} className={clsx(classes.mention)} to={to} mention>
							@
							{username}
						</ECRLink>
					);
				}
				return (
					<span key={index}>
						{value}
					</span>
				);
			})}
		</div>
	);
}
