import React from 'react';
import clsx from 'clsx';
import { makeStyles, Button } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		whiteSpace: 'nowrap',
		overflowX: 'hidden',
		marginRight: theme.spacing(2),
	},
	button: {
		height: 28,
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
			color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		},
	},
}));

export default ({
	group,
}) => {
	const classes = useStyles();

	return (
		<div
			className={classes.root}
		>
			<Button
				variant="contained"
				color="primary"
				disableRipple
				disableFocusRipple
				className={clsx(classes.button)}
				fullWidth
			>
				<Typography variant="subtitle2">{group.title}</Typography>
			</Button>

		</div>
	);
};
