import React, {
	memo, useCallback, useEffect, useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import emitter from '../../emitter';
import getCoverDimensions from './get-cover-dimensions';

const useStyles = makeStyles({
	container: {
		position: 'absolute',
		top: 0,
		width: '100%',
		height: '100%',
		zIndex: 2,
		touchAction: 'none',
	},
});

const pxIt = (n) => `${n}px`;

const TouchRegion = memo(({
	entity,
	type,
	coverDimensions,
}) => {
	const navigate = useNavigate();

	const {
		height,
		width,
		containerHeight,
		containerWidth,
	} = coverDimensions;

	const offsetY = (height - containerHeight) / 2; // px;
	const offsetX = (width - containerWidth) / 2; // px;

	const trTop = (height * entity.position.y) - offsetY;
	const trLeft = (width * entity.position.x) - offsetX;
	const trHeight = (entity.position.height) * height;
	const trWidth = (entity.position.width) * width;

	return (
		<div
			data-ignoreslider={type === 'mention' ? 'true' : 'false'}
			style={{
				position: 'absolute',
				top: pxIt(trTop),
				left: pxIt(trLeft),
				height: pxIt(trHeight),
				width: pxIt(trWidth),
				transform: `rotate(${entity.position.rotation}rad) scale(${entity.position.scale})`,
				// backgroundColor: 'black',
			}}
			onClick={() => {
				switch (type) {
				case 'mention':
					navigate(`/${entity.username}`);
					break;
				default:
					// nothing
				}
			}}
		/>
	);
});

export default ({ touchRegions }) => {
	const classes = useStyles();
	const [coverDimensions, setCoverDimensions] = useState(null);

	const isActive = useCallback((dimensions) => {
		if (!dimensions.height || !dimensions.width) {
			setCoverDimensions(null);
			return;
		}

		const cd = getCoverDimensions({
			height: window.innerHeight,
			width: window.innerWidth,
		}, dimensions);

		if (JSON.stringify(cd) !== coverDimensions) {
			setCoverDimensions(cd);
		}
	}, [coverDimensions]);

	useEffect(() => {
		emitter.addListener('active', isActive);

		return () => {
			emitter.removeListener('active', isActive);
		};
	}, [isActive]);

	if (!touchRegions?.length || !coverDimensions) {
		return null;
	}

	return (
		<div className={classes.container}>
			{touchRegions.map((ta, i) => <TouchRegion key={i} {...ta} coverDimensions={coverDimensions} />)}
		</div>
	);
};
