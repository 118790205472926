import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'fixed',
		alignItems: 'center',
		justifyContent: 'center',
		bottom: 0,
		top: 0,
		left: 0,
		right: 0,
		zIndex: 100,
		opacity: 1,
		transition: 'transform 0.65s ease, background-image 0.05s ease',
	},
	background: {
		position: 'fixed',
		bottom: 0,
		top: 0,
		left: 0,
		right: 0,
		zIndex: 17,
		opacity: 0.6,
		background: theme.palette.background.page,
	},
	circle: {
		stroke: theme.colors.primary.C500,
	},
	header: {
		marginBottom: theme.spacing(4),
	},
}));

export default ({ message, style }) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.root} style={style}>
				{message && <Typography className={classes.header} variant="h4">{message}</Typography>}
				<CircularProgress
					classes={{
						circle: classes.circle,
					}}
					size={40}
					thickness={3.2}
				/>
			</div>
			<div className={classes.background} />
		</>

	);
};
