import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';
import { updateExperience } from 'app/slices/experience-modal';
import { notify } from 'app/slices/notifications/notifications';
import deduplicateList from 'utils/deduplicate-List';

export const requestToggleSaveExperience = createAsyncThunk(
	'requestToggleSaveExperience',
	async ({ experience, save }, { rejectWithValue, dispatch }) => {
		const updatedExperience = { ...experience };
		updatedExperience.interactions = updatedExperience.interactions || {};
		updatedExperience.interactions = { callerHasSaved: save };
		dispatch(updateExperience(updatedExperience));
		try {
			const apiAction = save ? 'post' : 'delete';
			const response = await api[apiAction](`experiences/${experience.uid}/save`);
			dispatch(notify({
				severity: 'info',
				description: save ? 'Experience saved.' : 'Experience unsaved.',
				autoHideDuration: 2000,
			}));
			return response.data;
		} catch (error) {
			dispatch(updateExperience(experience));
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestUserSavedExperiences = createAsyncThunk(
	'requestUserSavedExperiences',
	async (_, { getState, rejectWithValue }) => {
		const { uuid } = getState().session.session;

		try {
			const response = await api.get(`experiences/users/${uuid}/saved?limit=${12}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestLoadMoreSavedExperiences = createAsyncThunk(
	'requestLoadMoreSavedExperiences',
	async (_, { rejectWithValue, getState }) => {
		const { next } = getState().savedExperiences;

		if (!next) {
			return null;
		}
		try {
			const response = await api.get(`${next}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const initialState = {
	savedExperiences: [],
	next: null,
	errors: null,
	loading: null,
	loadingMore: false,
	alertMessage: null,
	isItEmpty: false,
	seenBefore: {},
};

const savedExperiencesSlice = createSlice({
	name: 'savedExperiences',
	initialState: { ...initialState },
	reducers: {
		updateAlertMessage: (state, action) => {
			state.alertMessage = action.payload;
		},
		update: (state, action) => {
			const previousState = [...state.savedExperiences];
			const newState = [];
			for (let i = 0; i < previousState.length; i++) {
				if (previousState[i].uid === action.payload.uid) {
					previousState[i] = action.payload;
				}
				newState.push(previousState[i]);
			}

			state.savedExperiences = newState;
		},
		remove: (state) => {
			state.savedExperiences = state.savedExperiences ? state.savedExperiences.filter((ex) => ex.interactions?.callerHasSaved) : null;
			state.alertMessage = null;
			state.isItEmpty = !state.savedExperiences.length;
		},
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},

	},
	extraReducers: {
		[requestUserSavedExperiences.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestUserSavedExperiences.fulfilled]: (state, action) => {
			const { experiences, _links } = action.payload;
			state.seenBefore = {};
			const experiencesList = deduplicateList(experiences, state.seenBefore, 'uid');
			state.savedExperiences = experiencesList;
			state.isItEmpty = !experiences.length;
			state.next = _links?.next?.href || null;
			state.loading = false;
			state.errors = null;
		},
		[requestUserSavedExperiences.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestLoadMoreSavedExperiences.pending]: (state) => {
			state.loadingMore = true;
			state.errors = null;
		},
		[requestLoadMoreSavedExperiences.fulfilled]: (state, action) => {
			state.loadingMore = false;

			if (!action.payload) {
				return;
			}

			const { experiences, _links } = action.payload;
			const experiencesList = deduplicateList(experiences, state.seenBefore, 'uid');
			state.savedExperiences = [...state.savedExperiences, ...experiencesList];
			state.next = _links?.next?.href || null;
		},
		[requestLoadMoreSavedExperiences.rejected]: (state, action) => {
			state.loadingMore = false;
			state.errors = action.payload;
		},

	},
});

const { name, reducer, actions } = savedExperiencesSlice;
const {
	updateAlertMessage, update, remove, reset,
} = actions;

export {
	name, updateAlertMessage, update, remove, reset,
};

export const getSlice = (state) => state[name];
export const getUserSavedExperiences = createSelector(getSlice, (slice) => slice?.savedExperiences);
export const getNextLinkOnSavedExperiences = createSelector(getSlice, (slice) => slice?.next);
export const getHasMoreToLoad = createSelector(getSlice, (slice) => !!slice?.next);
export const getAlertMessage = createSelector(getSlice, (slice) => slice?.alertMessage);
export const getShowEmptyContent = createSelector(getSlice, (slice) => slice?.isItEmpty);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getLoadingMore = createSelector(getSlice, (slice) => slice?.loadingMore);

export default savedExperiencesSlice;

injectReducer(name, reducer);
