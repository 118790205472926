import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		height: 72,
		width: '100%',
	},
});

export default ({
	children,
}) => {
	const classes = useStyles();

	return (
		<div
			className={classes.root}
		>
			{children}
		</div>
	);
};
