/* eslint-disable no-restricted-globals */
import { EventEmitter } from 'events';
import neutral from 'rds/colors/neutral';
import animate from './animate';

const TIME_OUT = 100;
const TAP_FIDGET_THRESHOLD = 5;

const MODES = {
	animating: 'animating',
	deciding: 'deciding',
	scrolling: 'scrolling',
	selecting: 'selecting',
};

const CIRCLE_STYLE = {
	border: `2px solid ${neutral.C000}`,
	borderRadius: '50%',
	width: '28px',
	height: '28px',
	display: 'inline-block',
	zIndex: 3,
	backgroundSize: 'cover',
	pointerEvents: 'none',
};
const FONT_STYLE = {
	display: 'inline-block',
	zIndex: 3,
	fontSize: '24px',
	pointerEvents: 'none',
};

const CONTAINER_STYLE = {
	width: '100%',
	overflowX: 'auto',
	whiteSpace: 'nowrap',
	zIndex: 3,
};

const CIRCLE_CONTAINER_STYLE = {
	display: 'inline-block',
	paddingRight: '8px',
	paddingLeft: '8px',
};

const FONT_CONTAINER_STYLE = {
	display: 'inline-block',
	paddingLeft: '12px',
	paddingRight: '12px',
};

const PLACE_HOLDER = {
	border: `1px solid ${neutral.C000}`,
	paddingBottom: 2,
	position: 'absolute',
	height: '1px',
	bottom: '-8px',
	visibility: 'hidden',
};

class DrawerPicker extends EventEmitter {
	constructor(container, items, type, offset, options = {}) {
		super();
		this.container = container;
		this.items = items;
		this.offset = offset || 0;
		this.type = type;
		this.direction = null;
		this.startX = null;
		this.distX = null;
		this.lastTouchs = null;
		this.selected = null;
		this.mode = null;
		this.placeHolder = null;

		this._toucharea = null;
		this._touchtarget = null;

		const { selectedId } = options;
		this._placeAll(selectedId);
	}

	// makes the whole drawer

	get rect() {
		return this.container.getBoundingClientRect();
	}

	get center() {
		return (this.rect.width / 2) + this.offset;
	}

	_onMouseDown(e) {
		e.preventDefault();
	}

	_onTouchStart(e) {
		clearTimeout(this._selectTimer);
		this._touching = true;

		if (this.mode === MODES.selecting) {
			e.preventDefault();
			return;
		}

		if (e.target.getAttribute('data-element')) {
			this._touchtarget = e.target;
			this._toucharea = {
				x: e.touches[0].clientX,
				y: e.touches[0].clientY,
			};
		} else {
			this._touchtarget = null;
		}
	}

	_onTouchMove(e) {
		if (this._touchtarget && !this._eventIsNearToucharea(e)) {
			this._touchtarget = null;
		}
	}

	async _onTouchEnd(e) {
		this._touching = false;
		if (this._touchtarget) {
			await this._selectItem(e.target);
		} else if (!this._scrollTimer && this.mode !== MODES.animating) {
			this._selectElementNearCenter();
		}
	}

	_onScroll(e) {
		e.preventDefault();
		if (this._touchtarget || this.mode === MODES.animating) {
			return;
		}

		clearTimeout(this._scrollTimer);
		this._scrollTimer = setTimeout(() => {
			this._scrollTimer = null;
			if (!this._touching) {
				this._selectElementNearCenter();
			}
		}, TIME_OUT);
	}

	_eventIsNearToucharea(e) {
		const x = e.touches[0].clientX;
		const y = e.touches[0].clientY;

		if (Math.abs(this._toucharea.x - x) < TAP_FIDGET_THRESHOLD
			&& Math.abs(this._toucharea.y - y) < TAP_FIDGET_THRESHOLD) {
			return true;
		}

		return false;
	}

	_placeAll(selectedId) {
		const container = document.createElement('div');

		container.addEventListener('mousedown', this._onMouseDown.bind(this));
		container.addEventListener('touchstart', this._onTouchStart.bind(this), { passive: true });
		container.addEventListener('touchmove', this._onTouchMove.bind(this), { passive: true });
		container.addEventListener('touchend', this._onTouchEnd.bind(this), { passive: true });
		container.addEventListener('scroll', this._onScroll.bind(this));

		Object.keys(CONTAINER_STYLE).forEach((key) => {
			container.style[key] = CONTAINER_STYLE[key];
		});
		container.classList.add('no-scroll');

		switch (this.type) {
		case 'font':
			this._placeFonts(container);
			break;
		case 'color':
			this._placeColors(container);
			break;
		case 'textColor':
			this._placeColors(container);
			break;
		default:
			throw new Error('not a correct type');
		}

		const shadowNode = container.firstChild.cloneNode(true);
		shadowNode.style.visibility = 'hidden';
		document.body.appendChild(shadowNode);
		const { width } = shadowNode.getBoundingClientRect();
		this.placeHolder = document.createElement('div');
		Object.keys(PLACE_HOLDER).forEach((key) => {
			this.placeHolder.style[key] = PLACE_HOLDER[key];
		});
		shadowNode.remove();
		this.leftPadding = (window.innerWidth / 2) - (width / 2) - this.offset;
		container.style.paddingLeft = `${this.leftPadding}px`;
		this.container.append(this.placeHolder);

		if (!selectedId) {
			this._selectItem(this.container.children[0].children[0]);
		} else {
			this.selectItem(selectedId);
		}
	}

	_placeFonts(container) {
		this.items.forEach((item, i) => {
			const font = this._createFont(item, i);
			container.append(font);
		});
		this._createSpacer(container);
		this.container.append(container);
	}

	_createSpacer(container) {
		const spacer = document.createElement('div');
		spacer.style.width = `${window.innerWidth / 2}px`;
		spacer.style.display = 'inline-block';
		container.append(spacer);
	}

	_placeColors(container) {
		this.items.forEach((item, i) => {
			const circle = this._createCircle(item, i);
			container.append(circle);
		});
		this._createSpacer(container);
		this.container.append(container);
	}

	_getChildren() {
		const children = [];
		const container = this.container.children[0];
		for (let i = 0; i < container.children.length; i++) {
			const c = container.children[i];
			if (c.getAttribute('data-element')) {
				children.push(c);
			}
		}
		return children;
	}

	_createFont(item, i) {
		const element = document.createElement('div');
		const innerElement = document.createElement('div');

		element.appendChild(innerElement);
		element.setAttribute('data-element', i);

		Object.keys(FONT_STYLE).forEach((key) => {
			innerElement.style[key] = FONT_STYLE[key];
		});
		Object.keys(FONT_CONTAINER_STYLE).forEach((key) => {
			element.style[key] = FONT_CONTAINER_STYLE[key];
		});

		element.style.fontFamily = item.label;
		element.setAttribute('id', item.uid);
		innerElement.innerHTML = item.label;

		return element;
	}

	_createCircle(item, i) {
		const element = document.createElement('div');
		const innerElement = document.createElement('div');

		element.appendChild(innerElement);
		element.setAttribute('data-element', i);

		Object.keys(CIRCLE_STYLE).forEach((key) => {
			innerElement.style[key] = CIRCLE_STYLE[key];
		});

		Object.keys(CIRCLE_CONTAINER_STYLE).forEach((key) => {
			element.style[key] = CIRCLE_CONTAINER_STYLE[key];
		});

		if (this.type === 'textColor') {
			element.setAttribute('id', item.uid);
			innerElement.style.backgroundColor = `#${item.hex}`;
		} else {
			element.setAttribute('id', item.uid);
			innerElement.style.backgroundImage = `url(${item.image.small.jpg})`;
		}
		return element;
	}

	async _selectElementNearCenter() {
		const children = this._getChildren();
		const myArray = [];

		for (let i = 0; i < children.length; i++) {
			const rect = children[i].getBoundingClientRect();
			const d = rect.x + this.offset;
			const diff = Math.abs(d - this.center);
			myArray.push(diff);
		}
		const minimum = Math.min(...myArray);
		const index = myArray.indexOf(minimum);
		await this._selectItem(children[index]);
	}

	_findElement(e) {
		const index = [...e.parentElement.children].indexOf(e);
		return index;
	}

	_getItem(uid) {
		for (let i = 0; i < this.items.length; i++) {
			if (this.items[i].uid === uid) {
				return this.items[i];
			}
		}
		return null;
	}

	async _selectItem(item, force = false) {
		if (
			!force
			&& ((this.mode && this.mode !== MODES.selecting)
				|| this._selecting === item)
		) {
			return;
		}

		this.mode = MODES.selecting;
		this._selecting = item;
		if (this.destroyAnimation) {
			this.destroyAnimation();
		}
		this.selected = item;

		if (force) {
			this.mode = MODES.animating;
			await this._transformToCenter(this.selected);
			this.emit('selected', this.selected);
			this.mode = null;
			return;
		}

		await this._transformToCenter(this.selected);
		const { id } = this.selected;
		const selectedItem = this._getItem(id);

		this._selected = item;
		this._selecting = null;

		if (!selectedItem) {
			throw new Error('failed to select item');
		}
		// setTimeout(() => {
		this.mode = null;
		// }, TIME_OUT);
		this.emit('selected', selectedItem);
	}

	selectItem(id, force) {
		const children = this._getChildren();
		for (let i = 0; i < children.length; i++) {
			if (children[i].id === id) {
				this._selectItem(children[i], force);
			}
		}
	}

	_transformToCenter(target) {
		return new Promise((resolve) => {
			const container = this.container.children[0];
			window.container = container;
			window.target = target;

			this.mode = MODES.animating;
			this._destroyAnimation = animate(container, target, this.offset, () => {
				const { width } = target.children[0].getBoundingClientRect();
				this.placeHolder.style.width = `${width}px`;
				const marginLeft = `${(window.innerWidth / 2) - ((width / 2) + this.offset)}px`;
				this.placeHolder.style.marginLeft = marginLeft;
				this.placeHolder.style.visibility = 'visible';
				this._destroyAnimation = null;
				resolve();
			});
		});
	}

	destroy() {
		while (this.container.firstChild) {
			this.container.firstChild.remove();
		}
	}
}

export default DrawerPicker;
