import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	errorRoot: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
		position: 'relative',
		top: '30vh',
		paddingRight: theme.spacing(3),
		paddingLeft: theme.spacing(3),
	},
	title: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	subtitle: {
		marginBottom: theme.spacing(9),
	},
	retry: {
		marginLeft: theme.spacing(2),
		color: theme.colors.primary.C600,
	},
	refresh: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
}));

export default ({
	show,
	title,
	subtitle,
}) => {
	const classes = useStyles();

	if (!show) {
		return null;
	}

	return (
		<div className={classes.errorRoot}>
			<div className={classes.container}>
				<Typography className={classes.title} variant="h2">
					{title}
				</Typography>
				<Typography className={classes.subtitle} variant="h6">
					{subtitle}
				</Typography>
				<div
					className={classes.refresh}
					onClick={() => { window.location.reload(); }}
				>
					<IconV3
						icon="Refresh"
						color="primaryC600"
						size={20}
						containerSize={20}
					/>
					<Typography
						variant="subtitle1"
						className={classes.retry}
					>
						Retry
					</Typography>
				</div>
			</div>
		</div>
	);
};
