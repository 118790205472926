import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Modal from 'rds/components/Modal';
import Hexagon from 'rds/components/Hexagon';
import useTheme from 'rds/theme/useRdsTheme';
import { useDispatch, useSelector } from 'react-redux';
import {
	getMyIdentifiers,
	getSuggestedIdentifiers,
	requestPatchIdentifiers,
} from 'app/slices/user-account-v2/accounts';
import { InlineEditableArea } from 'rds/components/InlineEditable';
import { notifyErrors } from 'app/slices/notifications/notifications';
import { useNavbarState } from 'app/hooks/useNavbarState';
import SliderModal from 'rds/components/SliderModal';
import counter from 'utils/str-counter';
import sliceString from 'utils/slice-string';

const DARK_ACTIVE_GRADIENT = 'linear-gradient(180deg, #20D2B2 0%, #FB7848 50%, #CDBB0A 100%)';
const LIGHT_ACTIVE_GRADIENT = 'linear-gradient(90deg, #1BB6B6 0%, #E54210 100%)';
const TIME_OUT = 500;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		right: 'calc(50% - 45px)',
		zIndex: 30,
		top: '20vh',
		'&::-webkit-scrollbar': {
			display: 'none',
			width: 0,
		},
	},
	editableField: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'absolute',
		height: '94%',
		width: '60%',
		marginLeft: '20%',
		whiteSpace: 'normal',
		zIndex: 5,
		marginRight: theme.spacing(),
	},
	suggestions: {
		position: 'fixed',
		width: '100%',
		bottom: theme.spacing(10),
		left: theme.spacing(2),
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		paddingTop: theme.spacing(2),
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	identifier: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'absolute',
		height: '92%',
		width: '90%',
		whiteSpace: 'normal',
		overflow: 'hidden',
		marginLeft: '5%',
		zIndex: 3,
	},
	container: {
		marginRight: theme.spacing(2),
		display: 'inline-block',
	},
	item: {
		border: `1px solid ${theme.colors.neutral.C300}`,
		marginRight: theme.spacing(2),
		padding: theme.spacing(2),
	},

}));

const MAX_LENGTH = 30;

export default ({
	open,
	onClose,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const { username } = useParams();
	const [name, setName] = useState('');
	const suggestedIdentifiers = useSelector(getSuggestedIdentifiers);
	const filteredIdentifiers = suggestedIdentifiers.filter((identifier) => identifier.title.toLowerCase().startsWith(name.toLowerCase()));
	const identifiers = useSelector((state) => getMyIdentifiers(state, username));
	const navbarState = useNavbarState();

	useEffect(() => {
		if (open) {
			navbarState.close('high-priority');
		} else {
			navbarState.open('high-priority', TIME_OUT);
		}
	}, [open]);

	return (
		<SliderModal open={open}>
			<Modal
				disableClose
				top={{
					back: {
						label: 'Cancel',
						onClick: () => {
							setName('');
							onClose();
						},
					},
					next: {
						label: 'Save',
						onClick: () => {
							if (!name) {
								return;
							}

							dispatch(requestPatchIdentifiers({
								newIdentifier: { title: name.trim() },
								custom: true,
							}));
							setName('');
							onClose();
						},
					},
				}}
			>
				<div
					className={classes.root}
				>
					<Hexagon
						drawLabel={() => (
							<div className={classes.editableField}>
								<InlineEditableArea
									open
									maxLength={MAX_LENGTH}
									value={name}
									style={{
										maxWidth: 90,
										paddingLeft: theme.spacing(),
										paddingRight: theme.spacing(),
									}}
									onChange={(e) => {
										let newName = e.target.value;
										const actualLength = counter(newName);
										if (actualLength > MAX_LENGTH) {
											newName = sliceString(newName, 0, MAX_LENGTH);
										}
										setName(newName);
									}}
									fixedMaxLengthCaption
									disableUnderline
								/>
							</div>
						)}
						width={90}
						height={104}
						hexagonStyles={{
							background: theme.palette.background.page,
						}}
						selected
						selectedBorder={
							theme.palette.type === 'light'
								? LIGHT_ACTIVE_GRADIENT
								: DARK_ACTIVE_GRADIENT
						}
					/>
				</div>
				<div
					className={classes.suggestions}
				>
					{
						filteredIdentifiers && filteredIdentifiers.map((identifier, i) => (
							<div className={classes.container} key={i}>
								<div
									className={classes.item}
									onClick={() => {
										const existingIdentifiers = identifiers.filter((idf) => idf.slug === identifier.slug);
										if (!existingIdentifiers.length) {
											dispatch(requestPatchIdentifiers({ newIdentifier: identifier }));
										} else {
											notifyErrors([{ text: 'Already added.' }]);
										}
										setName('');
										onClose();
									}}
								>
									{identifier.title}
								</div>
							</div>
						))
					}

				</div>
			</Modal>
		</SliderModal>
	);
};
