import EventEmitter from 'events';

const TIME_OUT = 200;

export default class CommentSlider extends EventEmitter {
	constructor(commentContainer, contentDiv, sliderMenu, type) {
		super();
		this.commentContainer = commentContainer;
		this.sliderMenu = sliderMenu;
		this.contentDiv = contentDiv;
		this.startX = 0;
		this.lastX = 0;
		this.startY = 0;
		this.lastY = 0;
		this.direction = null;
		this.type = type;

		this.attachEventListeners();
		this.resizeTheSliderMenu();
	}

	attachEventListeners() {
		this.commentContainer.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
		this.commentContainer.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
		this.commentContainer.addEventListener('touchend', this.handleTouchEnd.bind(this), false);
	}

	resizeTheSliderMenu() {
		const el = this.sliderMenu;
		const rect = this.commentContainer.getBoundingClientRect();
		el.style.height = `${rect.height}px`;
	}

	handleTouchStart(e) {
		this.startX = e.targetTouches[0].clientX;
		this.startY = e.targetTouches[0].clientY;
		this.lastX = this.startX;
		this.lastY = this.startY;
	}

	handleTouchMove(e) {
		const { clientX, clientY } = e.targetTouches[0];
		const dx = clientX - this.lastX;
		const dy = clientY - this.lastY;

		if (Math.abs(dy) > Math.abs(dx)) {
			return;
		}
		const minLeft = 0;
		const maxLeft = 80;
		this.direction = dx > 0 ? 'right' : 'left';
		const el = this.sliderMenu;
		const { contentDiv } = this;
		const currentRight = parseFloat(el.style.right.slice(0, -2)) || 0;
		const currentLeft = parseFloat(contentDiv.style.left.slice(0, -2)) || 0;
		if (this.startX >= clientX && this.direction === 'left') {
			if (currentRight < 0) {
				el.style.right = `${currentRight + Math.abs(dx)}px`;
			}
			const distance = currentLeft - Math.abs(dx);

			if (distance >= -maxLeft) { contentDiv.style.left = `${distance}px`; }
		} else {
			const distance = currentLeft + Math.abs(dx);
			el.style.right = `${currentRight - dx}px`;
			if (distance < minLeft) {
				contentDiv.style.left = `${distance}px`;
			}
		}
		this.lastX = clientX;
		this.lastY = clientY;
	}

	addTransition() {
		this.sliderMenu.style.transition = '0.2s';
		this.contentDiv.style.transition = '0.2s';
	}

	removeTransition() {
		this.sliderMenu.style.transition = 'none';
		this.contentDiv.transition = 'none';
	}

	hide(transition = true) {
		if (transition) {
			this.addTransition();
		}
		const el = this.sliderMenu;
		const { width } = el.getBoundingClientRect();
		const { contentDiv } = this;
		el.style.right = `-${width}px`;
		contentDiv.style.left = 0;
	}

	show() {
		this.emit('active', this.id);
		this.addTransition();
		const el = this.sliderMenu;
		const { contentDiv } = this;
		el.style.right = this.type === 'comment' ? '-80px' : 0;
		contentDiv.style.left = '-80px';
		el.style.visibility = 'visible';
	}

	handleTouchEnd() {
		if (this.direction === 'right') {
			this.hide();
		} else if (this.direction === 'left') {
			this.show();
		}
		setTimeout(() => {
			this.removeTransition();
			this.direction = null;
		}, TIME_OUT);
	}

	detachEventListeners() {
		this.commentContainer.removeEventListener('touchstart', this.handleTouchStart.bind(this), false);
		this.commentContainer.removeEventListener('touchmove', this.handleTouchMove.bind(this), false);
		this.commentContainer.removeEventListener('touchend', this.handleTouchEnd.bind(this), false);
	}

	cleanup() {
		this.detachEventListeners();
	}
}
