import { createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

export const navigations = createSlice({
	name: 'navigations',
	initialState: {
		show: true,
		currentTab: 0,
	},
	reducers: {
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
	},

});

export default navigations;

const { name, reducer, actions } = navigations;
const { set } = actions;
export { set };

const getSlice = (state) => state[name];
export const getCurrentTab = createSelector(getSlice, (slice) => slice?.currentTab);
export const getShowNavigationsBar = createSelector(getSlice, (slice) => slice?.show);
injectReducer(name, reducer);
