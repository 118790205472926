import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import WaveSkeleton from 'components/WaveSkeleton';

const TRANSITION_TIME = 200;

const useStyles = makeStyles({
	root: {
		height: '100vh',
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		paddingBottom: 80,
		bottom: 0,
		top: 0,
		left: 0,
		right: 0,
		opacity: 0,
		transition: 'opacity 300ms ease-in-out',
	},

	fadeIn: {
		opacity: 1,
	},
});

export default ({ hide, skeletonHeight, ...props }) => {
	const classes = useStyles();
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsVisible(true);
		}, TRANSITION_TIME);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div
			className={`${classes.root} ${isVisible ? classes.fadeIn : ''}`}
			{...props}
		>
			<WaveSkeleton
				style={{
					height: 24,
					width: 188,
				}}
			/>
		</div>
	);
};
