import { createSelector } from '@reduxjs/toolkit';

const getSlice = (state) => state.userAcccountV2FollowingActions;

export const getFollowModal = createSelector(getSlice, (slice) => slice?.followModal);
export const getFollowTab = createSelector(getSlice, (slice) => slice?.followTab);
export const getFollowingList = createSelector(getSlice, (slice) => slice?.followingsList);
export const getFollowersList = createSelector(getSlice, (slice) => slice?.followersList);
export const getNextFollowersLink = createSelector(getSlice, (slice) => slice?.nextFollowersLink);
export const getNextFollowingLink = createSelector(getSlice, (slice) => slice?.nextFollowingLink);
export const getLoadingFollowersFollowing = createSelector(getSlice, (slice) => slice?.loadingFollowersFollowing);
export const getUsername = createSelector(getSlice, (slice) => slice?.username);
