import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import counter from 'utils/str-counter';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: theme.spacing(-7.5),
		width: '100%',
		height: theme.spacing(7.5),
		background: theme.colors.neutral.C900,
		display: 'flex',
		justifyContent: 'end',
		alignItems: 'center',
		borderTop: `1px solid ${theme.colors.neutral.C600}`,
		paddingRight: theme.spacing(4),
	},
	exceeded: {
		color: theme.colors.error.C500,
	},
}));

export default ({
	value,
	maxLength,
	threshold,
}) => {
	const classes = useStyles();
	const valueLength = value && counter(value);

	if (!value || valueLength < threshold) {
		return null;
	}
	const isExceeded = valueLength > maxLength;

	return (
		<Typography className={classes.root} variant="body2">
			<span className={isExceeded ? classes.exceeded : ''}>{valueLength}</span>
			/
			{maxLength}
		</Typography>
	);
};
