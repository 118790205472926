import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
	Button,
	makeStyles, Typography,
} from '@material-ui/core';
import { getIsUpdatedVersionAvailable, change } from 'app/slices/app/app';
import { getSession } from 'app/slices/authentication/session/session';
import { requestAllNotifications } from 'app/slices/app-notifications/notifications';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		height: 48,
		zIndex: 15,
		background: theme.colors.information.C700,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const show = useSelector(getIsUpdatedVersionAvailable);
	const session = useSelector(getSession);
	const location = useLocation();

	useEffect(() => {
		if (session && location.pathname !== '/notifications') {
			dispatch(requestAllNotifications());
		}
	}, [session, location]);

	if (!show) {
		return null;
	}
	return (
		<div className={classes.root}>
			<Typography className={classes.text} variant="subtitle2">A new update is available!</Typography>
			<Button
				className={classes.button}
				onClick={() => {
					dispatch(change({ updatedVersionAvailable: false }));
					window.location.reload();
				}}
			>
				Update Now
			</Button>
		</div>
	);
};
