import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
// import { getTheirIdentifiers, getTheirCustomIdentifiers, getProfileUuid } from 'app/slices/user-account-v2/accounts';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(3),
		marginLeft: theme.spacing(3),
		marginTop: theme.spacing(-2),
	},
	identities: {
		textAlign: 'center',
		whiteSpace: 'break-spaces',
		color: theme.colors.neutral.C300,
		width: 296,
	},
}));

export default ({ user }) => {
	const classes = useStyles();
	const customIdentifiers = user.customIdentifiers || [];
	const identifiers = user.identifiers || [];
	const combinedArray = customIdentifiers.concat(identifiers);
	const identities = combinedArray.map((obj) => obj.title);
	identities.sort((a, b) => a.localeCompare(b));

	if (!identifiers.length && !customIdentifiers.length) {
		return null;
	}

	let identifiersOutput = '';
	identities.forEach((identity, index) => {
		identifiersOutput += identity;
		if (index !== identities.length - 1) {
			identifiersOutput += ' • ';
		}
	});

	return (
		<div className={classes.root}>
			<Typography className={classes.identities} variant="body1">
				{identifiersOutput}
			</Typography>
		</div>

	);
};
