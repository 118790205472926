import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateAvatar } from 'app/slices/authentication/session/session';
import { notifyErrors } from 'app/slices/notifications/notifications';
import { requestPatchOnboarding } from 'app/slices/onboarding/onboarding';
import handleSliceError from 'utils/handle-slice-error';
import { getMyCustomIdentifiers, getMyIdentifiers } from './selectors';
import { makeApiRequest } from '../../utils';
import { trimAtSymbol } from '../utils';

const removeIt = (identifier, identifiers, customIdentifiers) => {
	const updatedIdentifiers = identifiers.filter((n) => n.slug !== identifier.slug);
	const updatedCustomIdentifiers = customIdentifiers.filter((n) => n.title !== identifier.title);
	return { updatedIdentifiers, updatedCustomIdentifiers };
};

const addIt = (identifier, identifiers, customIdentifiers) => {
	const updatedCustomIdentifiers = [...customIdentifiers];
	const updatedIdentifiers = [...identifiers];
	if (identifier.slug) {
		const hasDuplicate = updatedIdentifiers.find((id) => id.slug.toLowerCase() === identifier.slug.toLowerCase());
		if (!hasDuplicate) {
			updatedIdentifiers.push(identifier);
		} else {
			notifyErrors([{ text: 'Already added.' }]);
		}
	} else {
		const hasDuplicateCustom = updatedCustomIdentifiers.find((id) => id.title.toLowerCase() === identifier.title.toLowerCase());
		const hasDuplicateIdentifiers = updatedIdentifiers.find((id) => id.slug.toLowerCase() === identifier.title.toLowerCase());
		const hasDuplicate = hasDuplicateCustom || hasDuplicateIdentifiers;
		if (!hasDuplicate) {
			updatedCustomIdentifiers.push(identifier);
		} else {
			notifyErrors([{ text: 'Already added.' }]);
		}
	}
	return { updatedIdentifiers, updatedCustomIdentifiers };
};

export const requestProfile = createAsyncThunk(
	'accounts/requestProfile',
	async ({ username }, { getState, rejectWithValue }) => {
		const suid = getState()?.session?.session?.uuid;

		if (!suid && username === 'my-account') {
			throw new Error('User not logged in');
		}

		const myAccount = !username || username === 'my-account';
		const url = myAccount
			? `accounts/${suid}/profile`
			: `accounts/by-username/${trimAtSymbol(username)}/profile`;

		const response = await makeApiRequest({
			method: 'GET',
			url,
			rejectWithValue,
			notifyUser: false,
		});

		response.myAccount = myAccount;
		return response;
	},
	{
		condition: (args, { getState }) => {
			const { bypassCache } = args;
			const { session } = getState();
			const username = args.username || session?.session?.username;
			const account = getState().userAccountV2Accounts.accounts[username];
			return !(account && !bypassCache);
		},
	},
);

export const requestChangeAvatar = createAsyncThunk(
	'accounts/requestChangeAvatar',
	async (formData, { dispatch, rejectWithValue }) => {
		const data = await makeApiRequest({
			method: 'POST',
			url: 'accounts/profile/avatar',
			data: formData,
			rejectWithValue,
		});
		dispatch(updateAvatar(data.avatar));
		return data;
	},
);

export const requestChangeName = createAsyncThunk(
	'accounts/requestChangeName',
	async (newName, { getState, rejectWithValue }) => makeApiRequest({
		method: 'PATCH',
		url: `accounts/${getState().session.session.uuid}/profile/name`,
		data: { name: newName },
		rejectWithValue,
	}),
);

export const requestChangeBio = createAsyncThunk(
	'accounts/requestChangeBio',
	async (newBio, { getState, rejectWithValue }) => makeApiRequest({
		method: 'PATCH',
		url: `accounts/${getState().session.session.uuid}/profile/bio`,
		data: { bio: newBio },
		rejectWithValue,
	}),
);

export const requestBlockUser = createAsyncThunk(
	'accounts/requestBlockUser',
	async (uuid, { rejectWithValue }) => makeApiRequest({
		method: 'PUT',
		url: `accounts/block/${uuid}`,
		rejectWithValue,
	}),
);

export const requestUnBlockUser = createAsyncThunk(
	'accounts/requestUnBlockUser',
	async (uuid, { rejectWithValue }) => makeApiRequest({
		method: 'DELETE',
		url: `accounts/block/${uuid}`,
		rejectWithValue,
	}),
);

export const requestCheckIsUserBlocked = createAsyncThunk(
	'accounts/requestCheckIsUserBlocked',
	async (uuid, { getState, rejectWithValue }) => {
		if (uuid === getState()?.session?.session?.uuid) {
			return null;
		}
		return makeApiRequest({
			method: 'GET',
			url: `accounts/block/${uuid}`,
			rejectWithValue,
		});
	},
	{
		condition: (args, { getState }) => {
			const slice = getState().userAccountV2Accounts;
			const username = slice.currentUsername;
			const account = slice.accounts[username];
			return (typeof account.blocked !== 'undefined');
		},
	},
);

export const requestPatchIdentifiers = createAsyncThunk(
	'accounts/requestPatchIdentifiers',
	async ({
		custom, newIdentifier, remove,
	}, { dispatch, getState, rejectWithValue }) => {
		const identifiers = getMyIdentifiers(getState());
		const customIdentifiers = getMyCustomIdentifiers(getState());

		try {
			const newIdentifiers = remove
				? removeIt(newIdentifier, identifiers, customIdentifiers).updatedIdentifiers
				: addIt(newIdentifier, identifiers, customIdentifiers).updatedIdentifiers;

			const newCustomIdentifiers = remove
				? removeIt(newIdentifier, identifiers, customIdentifiers).updatedCustomIdentifiers
				: addIt(newIdentifier, identifiers, customIdentifiers).updatedCustomIdentifiers;

			if (custom) {
				dispatch(requestPatchOnboarding({ firstVisit: false }));
			}

			const response = await makeApiRequest({
				method: 'PATCH',
				url: 'accounts/profile/identifiers',
				data: {
					identifiers: newIdentifiers.map((identifier) => identifier.slug),
					customIdentifiers: newCustomIdentifiers.map((identifier) => identifier.title),
				},
			});
			return { response, newIdentifiers, newCustomIdentifiers };
		} catch (error) {
			return rejectWithValue({ error: handleSliceError(error), identifiers, customIdentifiers });
		}
	},
);

export const requestUpdateIdentifiers = createAsyncThunk(
	'accounts/requestUpdateIdentifiers',
	async ({ previousIdentifier, newIdentifier }, { getState, rejectWithValue }) => {
		const identifiers = getMyIdentifiers(getState());
		const customIdentifiers = getMyCustomIdentifiers(getState());
		const newIdentifiers = [];
		const newCustomIdentifiers = [];

		identifiers.forEach((i) => {
			newIdentifiers.push(i.slug);
		});

		customIdentifiers.forEach((i) => {
			newCustomIdentifiers.push(i.title);
		});

		if (previousIdentifier.slug) {
			newIdentifiers.splice(newIdentifiers.indexOf(previousIdentifier.slug), 1);
			newCustomIdentifiers.push(newIdentifier);
		} else {
			newCustomIdentifiers.splice(newCustomIdentifiers.indexOf(previousIdentifier.title), 1, newIdentifier);
		}
		return makeApiRequest({
			method: 'PATCH',
			url: 'accounts/profile/identifiers',
			data: {
				identifiers: newIdentifiers,
				customIdentifiers: newCustomIdentifiers,
			},
			rejectWithValue,
		});
	},
);

export const requestSuggestedIdentifiers = createAsyncThunk(
	'accounts/requestSuggestedIdentifiers',
	async (_, { rejectWithValue }) => makeApiRequest({
		method: 'GET',
		url: 'accounts/identifiers',
		rejectWithValue,
	}),
	{
		condition: (_, { getState }) => {
			const { userAccountV2Accounts } = getState();
			return !userAccountV2Accounts.suggestedIdentifiers.length;
		},
	},
);

export const requestApproveFollowRequest = createAsyncThunk(
	'accounts/requestApprovedFollowRequest',
	async (uuid, { rejectWithValue }) => makeApiRequest({
		method: 'PUT',
		url: `accounts/follow/approve/${uuid}`,
		rejectWithValue,
	}),
);
