import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles({
	root: {
		width: '100%',
		marginTop: 48,
	},
	emptyState: {
		textAlign: 'center',
	},

});

function EmptyContent() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="subtitle2" className={classes.emptyState}>Be the first to comment</Typography>
		</div>
	);
}

export default EmptyContent;
