import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getLoading } from 'app/slices/destination-account-v2/accounts/selectors';
import { getAllExperiencesByDestination, getErrors } from 'app/slices/destination-account-v2/experiences/selectors';
import WaveSkeleton from 'components/WaveSkeleton';
import EmptyContent from './EmptyContent';
import AllExperiences from './AllExperiences';
import TrustedCreators from './TrustedCreators';
import Filters from './Filters';
import Spacer from './Spacer';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(40),
	},
	skeletonContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginRight: theme.spacing(3),
		marginLeft: theme.spacing(3),
	},
}));

export default () => {
	const classes = useStyles();
	const experiences = useSelector(getAllExperiencesByDestination);
	const loadingAccount = useSelector(getLoading);
	const allExperiencesError = useSelector(getErrors);

	return (
		<div className={!experiences.length ? classes.root : null}>
			{loadingAccount ? 	(
				<div className={classes.skeletonContainer}>
					<WaveSkeleton
						style={{
							height: 20,
							borderRadius: 4,
							marginTop: 60,
						}}
					/>
				</div>
			) : !experiences.length && !allExperiencesError
				? (
					<>
						<Filters />
						<EmptyContent />
					</>
				)
				: (
					<>
						<TrustedCreators />
						<Filters />
						<AllExperiences />
					</>
				)}
			<Spacer />
		</div>
	);
};
