/**
 * shallow override default options
 * @param	{options} [options]
 * @return {defaultOptions} [defaultOptions]
 */

export default (options, defaultOptions) => {
	options = options || {};
	const cloneOptions = { ...options };

	if (!defaultOptions) {
		return cloneOptions;
	}

	Object.keys(defaultOptions).forEach((p) => {
		if (typeof cloneOptions[p] !== 'undefined') {
			return;
		}
		cloneOptions[p] = defaultOptions[p];
	});

	return cloneOptions;
};
