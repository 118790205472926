import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { primary, secondary } from 'rds/colors';

const ROUTE_ORDER = [
	'/onboarding/activities',
	'/onboarding/destinations',
	'/onboarding/regions',
];

const useStyles = makeStyles((theme) => ({
	root: {
		marginRight: theme.spacing(),
		height: 4,
		width: 'calc(33.33vw - 18.65px)',

		borderRadius: 10,
		background: theme.palette.type === 'light' ? primary.C300 : primary.C100,
		opacity: 0.60,
		transition: 'all 1s',
	},
	active: {
		background: secondary.C500,
		opacity: 0.85,
	},
}));

export default ({ route }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const componentIndex = ROUTE_ORDER.indexOf(route);
	const locationIndex = ROUTE_ORDER.indexOf(location.pathname);

	const rootKlass = locationIndex >= componentIndex
		? clsx(classes.root, classes.active)
		: classes.root;

	return (
		<div
			onClick={() => {
				navigate(route);
			}}
			className={rootKlass}
		/>
	);
};
