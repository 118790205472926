import { registerRoute } from 'Router/registry';
import { requestAllNotifications } from 'app/slices/app-notifications/notifications';
import Skeleton from 'rds/components/Skeletons/SecondaryPage';
import { register } from 'features/ExperienceModal/ECRProvider';
import soloEcr from 'app/slices/index/solo-ecr';
import Header from './Header';
import Page from './Page';

register('solo', soloEcr);

const appNotificationsRequiredData = [
	({ dispatch }) => {
		dispatch(requestAllNotifications());
	}];

registerRoute({
	authorization: true,
	Component: Page,
	Header,
	Skeleton,
	ecrSelectorName: 'solo',
	soloECR: true,
	routeOptions: {
		path: '/notifications',
	},
	loadData: appNotificationsRequiredData,
	authorizePage: '/landing',
	transition: 'slide',
	navigationMenu: '/notifications',
	root: true,
});
