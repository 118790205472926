import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getDestinationStats, getLoading } from 'app/slices/destination-account-v2/accounts/selectors';
import WaveSkeleton from 'components/WaveSkeleton';
import { useParams } from 'react-router-dom';
import Stat from './Stat';
import FollowersModal from './FollowersModal';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: theme.spacing(6),
	},
}));

export default () => {
	const classes = useStyles();
	const { uid } = useParams();
	const stats = useSelector(getDestinationStats(uid));
	const [open, setOpen] = useState(false);
	const loading = useSelector(getLoading);
	const followersLabel = stats?.followers?.robot === 1 ? 'Follower' : 'Followers';
	const experienceLabel = stats?.experienceCount?.robot === 1 ? 'Experience' : 'Experiences';

	return (
		<div className={classes.root}>
			{loading
				? (
					<WaveSkeleton
						style={{
							width: 288,
							height: 20,
							borderRadius: 4,
							marginBottom: 8,
						}}
					/>
				)
				: (
					<>
						<Stat
							stat={stats?.experienceCount || { robot: 0 }}
							label={experienceLabel}
						/>
						<Stat
							stat={stats?.followers}
							label={followersLabel}
						/>
					</>
				)}
			{ open ? <FollowersModal onClose={() => setOpen(false)} /> : null }
		</div>
	);
};
