import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import emitter from './emitter';

const useStyles = makeStyles((theme) => ({
	errorRoot: {
		position: 'absolute',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	container: {
		textAlign: 'center',
	},
	header: {
		marginBottom: theme.spacing(4),
	},
	error: {
		color: theme.colors.neutral.C500,
		marginTop: theme.spacing(18),
	},
	retry: {
		marginLeft: theme.spacing(2),
		color: theme.colors.primary.C400,
	},
	refresh: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(12.5),
	},
}));

export default ({ errors }) => {
	const classes = useStyles();

	return (
		<div className={classes.errorRoot}>
			<div className={classes.container}>
				<Typography className={classes.header} variant="h2">
					Unable to load
					{' '}
					<br />
					{' '}
					clip
				</Typography>
				<div
					className={classes.refresh}
					onClick={() => {
						emitter.emit('reloadClip');
					}}
				>
					<IconV3
						icon="Refresh"
						color="primaryC600"
						size={20}
						containerSize={20}
					/>
					<Typography className={classes.retry} variant="subtitle1">Retry</Typography>
				</div>
				{errors && errors.map((error, i) => (
					<Typography className={classes.error} key={`error_${i}`} variant="subtitle2">
						{error.text}
					</Typography>
				))}
			</div>
		</div>
	);
};
