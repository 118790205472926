import React from 'react';
import { makeStyles } from '@material-ui/core';
import WaveSkeleton from 'components/WaveSkeleton';

const useStyles = makeStyles((theme) => ({
	root: {
		marginRight: theme.spacing(4),
		marginLeft: theme.spacing(4),
	},
}));

export default ({ lines }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			{[...Array(lines)].map((s, index) => (
				<WaveSkeleton style={{ height: 12, marginBottom: 8 }} key={index} />
			))}
		</div>
	);
};
