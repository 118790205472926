import { useSelector } from 'react-redux';
import { getColors } from 'rds/colors/slice';
import { getTheme } from './providerSlice';
import themes from './themes';

export default (key) => {
	key = key || useSelector(getTheme);
	const colors = useSelector(getColors);

	const t = themes[key](colors);

	t.icon = (variant) => {
		if (variant === 'contrast') {
			return t.palette.type === 'light'
				? 'darkContrast'
				: 'lightContrast';
		}

		return t.palette.type === 'light'
			? 'lightContrast'
			: 'darkContrast';
	};

	return t;
};
