import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import { notify } from '../notifications/notifications';

export const requestDestinationByCode = createAsyncThunk(
	'requestDestinationByCode',
	async ({ code }, { dispatch }) => {
		try {
			const response = await api.get(`/destinations/by-share/${code}`);
			return response.data;
		} catch (error) {
			dispatch(notify({
				severity: 'info',
				description: 'Destination not found.',
			}));
			return { error, code: 404 };
		}
	},
);

const initialState = {
	destination: null,
	loading: false,
	errors: null,
	notFound: false,
};

const destinationByCodeSlice = createSlice({
	name: 'destinationByCode',
	initialState: { ...initialState },
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
	},
	extraReducers: {
		[requestDestinationByCode.pending]: (state) => {
			state.errors = null;
			state.loading = true;
			state.notFound = false;
		},

		[requestDestinationByCode.rejected]: (state) => {
			state.loading = false;
			state.notFound = true;
		},

		[requestDestinationByCode.fulfilled]: (state, action) => {
			state.loading = false;
			state.errors = null;
			state.destination = action.payload;
			state.notFound = action.payload.code === 404;
		},
	},
});

const { name, reducer, actions } = destinationByCodeSlice;
const {
	reset,
} = actions;

export {
	reset,
};

export const getSlice = (state) => state[name];
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getNotFound = createSelector(getSlice, (slice) => slice?.notFound);
export const getDestination = createSelector(getSlice, (slice) => slice?.destination);

export default destinationByCodeSlice;

injectReducer(name, reducer);
