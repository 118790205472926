import EventEmitter from 'events';
import queueFetch from 'services/queue-fetch';

const channel = new BroadcastChannel('upload_channel');

const MAX_LISTENER = 30000; // 30 seconds to fetch uploads
const CACHE_DURATION = 5000; // 5 seconds

class UploadQueue extends EventEmitter {
	constructor() {
		super();

		// Adding cache properties
		this.lastFetchTime = null;
		this.cachedUploads = null;

		channel.onmessage = (ev) => {
			const { type, ...context } = ev.data;
			this.emit(type, context);
		};
	}

	async uploadExperience({
		accountUuid,
		experienceUid,
		cover,
		blob,
		uploadInfo,
	}) {
		const { url, chunkSize } = uploadInfo;

		await queueFetch(url, {
			method: 'PUT',
			requestType: 'upload',
			headers: {
				'content-type': 'application/octet-stream',
				'content-range': `bytes ${0}-${blob.size - 1}/${blob.size}`,
				'x-context': JSON.stringify({
					accountUuid,
					experienceUid,
					cover,
					chunkSize,
				}),
			},
			body: blob,
		});
	}

	deleteExperience(uid) {
		return new Promise((resolve, reject) => {
			this.once('delete-experience-response', (context) => {
				if (context.status === 'error') {
					reject(new Error(context.error));
				} else {
					resolve();
				}
			});

			channel.postMessage({
				type: 'delete-experience',
				uid,
			});
		});
	}

	fetchUploads(accountUuid) {
		const currentTime = Date.now();

		// Check cache validity and return if valid
		if (this.lastFetchTime && (currentTime - this.lastFetchTime < CACHE_DURATION)) {
			return Promise.resolve(this.cachedUploads);
		}

		return new Promise((resolve, reject) => {
			const onFetchUploadsResponse = (context) => {
				// Update cache with new data
				this.lastFetchTime = currentTime;
				this.cachedUploads = context.experiences;

				if (context.status === 'error') {
					reject(new Error(context.error));
				} else {
					resolve(context.experiences);
				}
			};

			this.once('fetch-uploads-response', onFetchUploadsResponse);

			setTimeout(() => {
				this.removeListener('fetch-uploads-response', onFetchUploadsResponse);
			}, MAX_LISTENER);

			channel.postMessage({
				type: 'fetch-uploads',
				accountUuid,
			});
		});
	}

	updateChaosMode() {
		const chaosMessage = {
			type: localStorage.getItem('developer:upload-chaos-mode') === 'true'
				? 'start-chaos-mode'
				: 'stop-chaos-mode',
		};
		channel.postMessage(chaosMessage);
	}
}

const uploadQueue = new UploadQueue();

uploadQueue.updateChaosMode();

export default uploadQueue;
