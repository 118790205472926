import { registerRoute } from 'Router/registry';
import { setCurrentUsername } from 'app/slices/user-account-v2/accounts';
import { requestProfile, requestSuggestedIdentifiers } from 'app/slices/user-account-v2/accounts/thunks';
import { reset as resetExperiences } from 'app/slices/user-account-v2/experiences';
import { requestInProgressExperiences } from 'app/slices/user-account-v2/in-progress';
import Skeleton from 'rds/components/Skeletons/SecondaryPage';
import store from 'services/store';
import { theirUaEcr, myUaEcr } from 'app/slices/user-account-v2/experiences-ecr';
import { requestExperienceByCode } from 'app/slices/experience-by-share';
import { trimAtSymbol } from 'app/slices/user-account-v2/utils';
import { register } from 'features/ExperienceModal/ECRProvider';
import AvatarCapture from './AvatarCapture';
import { MySubPage, TheirSubPage, TheirSubPageByCode } from './SubPage';
import TheirAccountPage from './Page/TheirAccountPage';
import MyAccountPage from './Page/MyAccountPage';
import MyAccountHeader from './Header/MyAccountHeader';
import TheirAccountHeader from './Header/TheirAccountHeader';
import { MySubHeader, TheirSubHeader } from './Header/SubHeader';
import UserNotFound from './UserNotFound';

import './UnsubscribeEmail';
import './VerifyEmail';

register('their-ua', theirUaEcr);
register('ua', myUaEcr);

const uaFollowPageRequiredData = [];
const uaByCodeRequiredData = [
	async ({ dispatch, params, navigateCallback }) => {
		const state = store.getState();
		const { session } = state.session;

		const username = params.username ? trimAtSymbol(params.username) : null;
		const parts = params.subpage.split(/-/g);
		const code = parts[parts.length - 1];
		let response;

		if (code) {
			response = await dispatch(requestExperienceByCode({ code }));
		}

		const sharedExperience = response?.payload;

		const getNavigationPath = () => {
			if (username === session.username) {
				if (sharedExperience?.user?.interactions?.callerIsBlocking) {
					return '/my-account';
				}
				return `/my-account?shared=true&experience=${sharedExperience.uid}`;
			}

			if (sharedExperience?.user?.interactions?.callerIsBlocking) {
				return '/explore';
			}

			if (!sharedExperience) {
				return `/${username}`;
			}

			return `/${sharedExperience.user.username}?shared=true&experience=${sharedExperience.uid}`;
		};
		const to = getNavigationPath();
		navigateCallback(to);
		return new Promise((resolve) => resolve());
	}];

const uaRequiredData = [
	async ({ dispatch, params, navigateCallback }) => {
		const state = store.getState();
		const { currentUsername } = state.userAccountV2Accounts;
		const { session } = state.session;

		const username = params.username ? trimAtSymbol(params.username) : null;

		if (username === session.username) {
			if (navigateCallback) {
				navigateCallback('/my-account');
			}
			return null;
		}

		if (currentUsername === username) {
			return new Promise((resolve) => resolve());
		}

		await dispatch(setCurrentUsername(username));
		await dispatch(resetExperiences());

		return dispatch(requestProfile({ username: params.username || 'my-account' }));
	},
];

const myRequiredData = [
	({ dispatch }) => {
		const state = store.getState();
		const { myAccount } = state.userAccountV2Accounts;

		if (myAccount) {
			return new Promise((resolve) => resolve());
		}

		return dispatch(requestProfile({ username: 'my-account' }));
	},
	({ dispatch }) => {
		const state = store.getState();
		const { suggestedIdentifiers } = state.userAccountV2Accounts;

		if (suggestedIdentifiers.length) {
			return new Promise((resolve) => resolve());
		}
		dispatch(requestInProgressExperiences());
		return dispatch(requestSuggestedIdentifiers());
	},
];

registerRoute({
	authorization: true,
	Component: AvatarCapture,
	Skeleton,
	routeOptions: {
		path: '/my-account/avatar/:action',
	},
	transition: 'dissolve',
	rootRedirect: '/my-account',
	preserveScrollState: false,
});

registerRoute({
	authorization: true,
	Header: MySubHeader,
	Component: MySubPage,
	Skeleton,
	routeOptions: {
		path: '/my-account/follow/:subpage',
	},
	loadData: uaFollowPageRequiredData,
	transition: 'slide',
	navigationMenu: '/my-account',
	rootRedirect: '/my-account',
	preserveScrollState: false,
});

registerRoute({
	authorization: true,
	Component: TheirAccountPage,
	Header: TheirAccountHeader,
	Skeleton,
	ecrSelectorName: 'their-ua',
	routeOptions: {
		path: '/:username',
	},
	errors: {
		Rejected: UserNotFound,
	},
	loadData: uaRequiredData,
	transition: 'slide',
	navigationMenu: true,
});

registerRoute({
	authorization: true,
	Header: TheirSubHeader,
	Component: TheirSubPage,
	Skeleton,
	routeOptions: {
		path: '/:username/follow/:subpage',
	},
	loadData: uaFollowPageRequiredData,
	transition: 'slide',
	navigationMenu: true,
	preserveScrollState: false,
});

registerRoute({
	authorization: true,
	Component: TheirSubPageByCode,
	Skeleton,
	routeOptions: {
		path: '/:username/:subpage',
	},
	loadData: uaByCodeRequiredData,
	transition: 'slide',
	navigationMenu: true,
	preserveScrollState: false,
});

registerRoute({
	authorization: true,
	Component: MyAccountPage,
	Header: MyAccountHeader,
	Skeleton,
	ecrSelectorName: 'ua',
	routeOptions: {
		path: '/my-account',
	},
	loadData: myRequiredData,
	transition: 'slide',
	navigationMenu: '/my-account',
	root: true,
	skipReloads: true,
});
