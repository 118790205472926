import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import { useNavigate } from 'react-router-dom';
import SettingItemWithArrow from './SettingItemWithArrow';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
		paddingLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		paddingRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		borderTop: `1px solid ${theme.colors.neutral.C800}`,
	},
	title: {
		color: theme.colors.neutral.C300,
	},
}));

export default () => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<div className={classes.root}>
			<Typography
				className={classes.title}
				variant="body2"
			>
				SUPPORT & ABOUT
			</Typography>
			<SettingItemWithArrow marginTop title="Terms of Use" onClick={() => { navigate('/terms'); }} />
			<SettingItemWithArrow title="Privacy Policy" onClick={() => { navigate('/terms#privacy-policy'); }} />
		</div>
	);
};
