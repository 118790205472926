import React from 'react';
import IconV3 from 'rds/components/IconV3';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		width: 32,
		height: 32,
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	background: {
		width: '100%',
		height: '100%',
		borderRadius: '50%',
		zIndex: 1,
		background: 'linear-gradient(180deg, #20D2B2 0%, #FB7848 50%, #CDBB0A 100%)',
	},
	icon: {
		position: 'absolute',
		width: 28,
		height: 28,
		borderRadius: '50%',
		zIndex: 2,
		background: theme.colors.neutral.C900,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default () => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.root}>
				<div className={classes.background} />
				<div className={classes.icon}>
					<IconV3
						icon="Plus"
						size={24}
						containerSize={20}
						dropShadow
					/>
				</div>
			</div>
		</>
	);
};
