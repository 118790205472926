import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
	transition: `all ${duration}ms ease-in-out`,
	transform: 'translateY(100%) scale(0)',
	opacity: 0,
};

const transitionStyles = {
	entering: { transform: 'translateY(100%) scale(0)', opacity: 0 },
	entered: { transform: 'translateY(0) scale(1)', opacity: 1 },
};

const GenieTransition = ({ in: inProp, children, className }) => (
	<Transition in={inProp} timeout={duration}>
		{(state) => (
			<div className={className} style={{ ...defaultStyle, ...transitionStyles[state] }}>
				{children}
			</div>
		)}
	</Transition>
);

export default GenieTransition;
