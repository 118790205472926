/* eslint-disable */

import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSuspended } from 'components/Suspense/Suspense';
import Layout from './Layout';
import TopSpacer from './Components/TopSpacer';
import Section from './Components/Section';
import FullSection from './Components/FullSection';
import Paragraph from './Components/Paragraph';
import HeaderText from './Components/HeaderText';
import HighlightText from './Components/HighlightText';
import SubHighlightText from './Components/SubHighlightText';
import termsData from './data';

const { sToAnchor } = termsData;
const SCROLL_TIME_OUT = 500;

const Terms = ({ pwa = true }) => {
    const location = useLocation();
    const suspended = useSuspended();
    const timeout = useRef();

    useEffect(() => {
        clearTimeout(timeout.current);

        const el = location.hash && document.querySelector(location.hash);

        if (pwa && location.hash && el) {
            timeout.current = setTimeout(() => {
                const el = document.querySelector(location.hash);
                el?.scrollIntoView({ behavior: "smooth" });
            }, SCROLL_TIME_OUT);
        }
        if (!suspended && location.hash && !pwa) {
            if (el) {
                timeout.current = setTimeout(() => {
                    el?.scrollIntoView({ behavior: "smooth" });
                }, SCROLL_TIME_OUT);
            }
        }
    }, [location.hash]);

    return (
        <Layout pwa={pwa} header={location.hash ? 'Privacy Policy' : 'Terms of Use'}>
            <Section>
                <TopSpacer />
                <FullSection gutters>
                    <HeaderText>Terms of Use</HeaderText>
                </FullSection>
            </Section>

            {
                termsData.sections.map(({ body, subsections, title }, i) => (
                    <Section key={i}>
                        <div id={sToAnchor(title)} />
                        <FullSection gutters style={{ alignItems: 'flex-start', textAlign: 'left' }}>
                            <HighlightText>{title}</HighlightText>
                            {body && (
                                <Paragraph component="div">
                                    <div dangerouslySetInnerHTML={{ __html: body }} />
                                </Paragraph>
                            )}

                            {
                                subsections && subsections.map(({ title: subTitle, body: subBody }, j) => (
                                    <div key={`${i}${j}`} style={{ paddingLeft: 24 }}>
                                        <SubHighlightText id={sToAnchor(title)}>{subTitle}</SubHighlightText>
                                        {subBody && (
                                            <Paragraph component="div">
                                                <div dangerouslySetInnerHTML={{ __html: subBody }} />
                                            </Paragraph>
                                        )}
                                    </div>
                                ))
                            }
                        </FullSection>
                    </Section>
                ))
            }
        </Layout>
    );
};

export default Terms;
