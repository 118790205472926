import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import useTheme from 'rds/theme/useRdsTheme';
import { applyContextMenu } from 'app/slices/app/context-menu';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'inline-block',
		userSelect: 'none',
		paddingTop: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
		'&:last-child': {
			marginRight: 0,
		},
	},
	hexagon: {
		position: 'relative',
		clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
		borderRadius: 4,
		left: 3,
		top: -4,
		zIndex: 2,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	background: {
		position: 'relative',
		clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
		zIndex: 1,
	},
	image: {
		zIndex: 1,
		position: 'relative',
	},
	darkLayer: {
		position: 'relative',
		clipPath: 'polygon(50% 3%, 98% 26%, 98% 73%, 50% 97%, 2% 73%, 2% 26%)',
		zIndex: 3,
		backgroundColor: '#000000',
		opacity: 0.3,
	},
	textContainer: {
		position: 'absolute',
		zIndex: 3,
		width: '100%',
		height: '100%',
		display: 'flex',
		bottom: 0,
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		...theme.typography.subtitle2,
		color: theme.colors.neutral.C000,
	},

}));

const PRESS_THRESHOLD = 300;

export default ({
	drawLabel,
	hexagonStyles = { background: '#ccc' },
	onClick,
	selected,
	title,
	id,
	height = 148,
	width = 130,
	selectedBorder,
	onlongPressed,
	rootStyles,
	longpress,
	darkLayer,
	textShadow,
	...rest
}) => {
	const classes = useStyles();
	const ref = useRef();
	const theme = useTheme();
	const [startTime, setStartTime] = useState(null);
	const [startTouch, setStartTouch] = useState(null);
	const [lastTouch, setLastTouch] = useState(null);
	const style = {
		height,
		selected,
		width,
		...hexagonStyles,
	};

	const backgroundStyle = {
		height: height + 8,
		width: width + 6,
		marginTop: -(height + 8),
	};

	if (selected) {
		backgroundStyle.background = theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000;
	}
	if (selected && selectedBorder) {
		backgroundStyle.background = selectedBorder;
	}
	if (!selected && selectedBorder) {
		backgroundStyle.background = theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000;
	}

	useEffect(() => {
		if (ref?.current && onlongPressed && longpress) {
			applyContextMenu(ref.current, onlongPressed, {
				ignoreWidth: true,
			});
		}
	}, [ref]);

	return (
		<div
			className={classes.root}
			style={rootStyles}
			ref={ref}
			onTouchStart={(e) => {
				setStartTouch(e.touches[0].clientX);
				setStartTime(Date.now());
			}}
			onTouchMove={(e) => {
				setLastTouch(e.touches[0].clientX);
			}}
			onTouchEnd={() => {
				const pressTime = Date.now() - startTime;
				if (lastTouch === null || Math.abs(lastTouch - startTouch) < 50) {
					if (pressTime < PRESS_THRESHOLD) {
						if (onClick) {
							onClick(
								id,
							);
						}
					}
				}
				setStartTime(null);
				setLastTouch(null);
				setStartTouch(null);
			}}
			{...rest}
		>
			{drawLabel && drawLabel(selected)}
			<div className={classes.hexagon} style={style} />
			<div className={classes.background} style={backgroundStyle} />
			{darkLayer && (
				<div
					className={classes.darkLayer}
					style={{
						height: backgroundStyle.height,
						width: backgroundStyle.width,
						marginTop: backgroundStyle.marginTop,
					}}
				/>
			)}
			<div className={classes.textContainer} style={{ textShadow: textShadow ? '0px 0px 2px rgba(0, 0, 0, 0.4)' : null }}>
				<Typography variant="body1" className={classes.title}>{title}</Typography>
			</div>
		</div>
	);
};
