const getRotation = (el, checkParent = true) => {
	const parts = el.style.transform.split(' ');
	let angle;

	for (let i = 0; i < parts.length; i++) {
		if (parts[i].slice(0, 6) === 'rotate') {
			angle = parseFloat(parts[i].slice(7, -4));
		}
	}

	if (!angle && checkParent && el.parentElement) {
		return getRotation(el.parentElement, false);
	}

	return angle || 0;
};

export default getRotation;
