import React from 'react';
import { makeStyles } from '@material-ui/core';
import Hexagon from '../Hexagon';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		paddingLeft: theme.spacing(6),
		paddingTop: theme.spacing(2),
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	spacer: {
		display: 'inline-block',
		height: 1,
		width: theme.spacing(6),
	},
}));

export default ({
	oddStyle = {
		marginLeft: 69,
		marginTop: -36,
	},
	rows,
	images,
	onClick,
}) => {
	const classes = useStyles();
	const hexagonRows = [];

	const toImageName = (title) => title.toLowerCase().replace(/&/, '')
		.replace(/\//, '_')
		.replace(/\s\s/, ' ')
		.replace(/\s/, '_');

	for (let i = 0; i < rows.length; i++) {
		hexagonRows.push(rows[i]);
	}

	return (
		<div className={classes.root}>
			{rows.map((r, i) => (
				<div key={`row-${i}`} style={i % 2 === 1 ? oddStyle : {}}>
					{r.map((hex, j) => {
						const image = images[toImageName(hex.title)];
						return (
							<Hexagon
								onClick={onClick}
								hexagonStyles={{ backgroundImage: `url(${image})`, zIndex: 2 }}
								key={`row-${i}-hexagon-${j}`}
								darkLayer
								textShadow
								title={hex.title}
								id={hex.uid}
								{...hex}
							/>
						);
					})}
					<div className={classes.spacer} />
				</div>
			))}

		</div>
	);
};
