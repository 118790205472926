import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
	getSuggestedStickers,
	requestSuggestedStickers,
} from 'app/slices/content-creation/editor/stickers';
import cslx from 'clsx';
import StickerGrid from './StickerGrid';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(8),
		zIndex: 2140000003,
	},
	selected: {
		...theme.typography.title2,
		color: `${theme.colors.neutral.C000} !important`,
		borderBottom: `2px solid ${theme.colors.neutral.C000}`,
		opacity: 1,
	},
	container: {
		width: '100%',
		marginTop: theme.spacing(14),
	},
	titles: {
		position: 'fixed',
		right: theme.spacing(4),
		left: theme.spacing(4),
		top: 100,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		background: theme.colors.neutral.C900,
		paddingBottom: theme.spacing(4),
	},
	tab: {
		color: theme.colors.neutral.C000,
		marginTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
		width: '100%',
		textAlign: 'center',
	},
	inActive: {
		opacity: 0.6,
	},

}));

export default ({ params }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const stickers = useSelector(getSuggestedStickers);
	const [tab, setTab] = useState(0);

	useEffect(() => {
		dispatch(requestSuggestedStickers());
	}, []);

	return (
		<div className={classes.root}>
			<div className={classes.titles}>
				{stickers && stickers.context && stickers.context.filter((entities) => entities.title.toLowerCase() !== 'tags').map((entities, i) => {
					const klass = tab === i ? cslx(classes.tab, classes.selected) : cslx(classes.tab, classes.inActive);
					return (
						<Typography key={i} className={klass} onClick={() => setTab(i)}>
							{entities.title}
						</Typography>
					);
				})}

			</div>
			<div className={classes.container}>
				{stickers && stickers.context && stickers.context.map((entities, index) => (
					tab === index ? <StickerGrid key={index} params={params} group={entities} /> : null
				))}
			</div>
		</div>
	);
};
