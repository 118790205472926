import { EventEmitter } from 'events';

const easeInOutCubic = (x) => (x < 0.5 ? 4 * x * x * x : 1 - ((-2 * x + 2) ** 3) / 2);

const DELAY_TIME = 150;
const ANIMATION_TIME = 300;
const SNAP_BACK_TIME = 150;

const SCALE_TYPES = {
	dimensions: (el, v, rect) => {
		el.style.height = `${rect.height * v}px`;
		el.style.width = `${rect.width * v}px`;
	},
	transform: (el, v) => {
		el.style.transform = `scale(${v})`;
	},
};

export default class ScaleAnimation extends EventEmitter {
	constructor(el, done, options = {}) {
		super();
		this._scale = 1;
		this.el = el;
		this._startScroll = this.el.scrollLeft;
		this._originalRect = this.el.getBoundingClientRect();
		this._scaleType = options.type || 'transform';
		if (!SCALE_TYPES[this._scaleType]) {
			throw new Error(`invalid scale type ${this._scaleType}`);
		}

		this.to = setTimeout(() => {
			this.tick({
				start: window.performance.now(),
				duration: ANIMATION_TIME,
				direction: 1,
			}, done);
		}, DELAY_TIME);
	}

	//

	set scale(v) {
		this._scale = v;

		this.el.setAttribute('data-longpress-scale', v);
		SCALE_TYPES[this._scaleType](this.el, v, this._originalRect);
	}

	tick(options, done) {
		const {
			startingScale = 1,
			start = 0,
			duration = 0,
			direction = 1,
		} = options;

		this.ticking = true;

		if (this.shouldStop) {
			this.ticking = false;
			this.shouldStop = false;
			this.stopDone && this.stopDone();
			return;
		}

		if (this.destroyed) {
			this.ticking = false;
			return;
		}

		const now = window.performance.now() - start;
		if (now >= duration) {
			this.ticking = false;
			if (direction < 0) {
				this.scale = 1;
			}
			done && done();
			return;
		}

		const p = easeInOutCubic(now / duration);

		// if (p > 0.6) {
		//	 done && done();
		//	 done = null;
		// }

		let scale;

		if (direction > 0) {
			const value = p / 7;
			scale = value < 0.08
				? 1 - (value)
				: 1 + (value - 0.08);
		} else {
			scale = startingScale - ((startingScale - 1) * p);
		}

		this.scale = scale;
		this.emit('tick', scale);

		requestAnimationFrame(() => {
			this.tick(options, done);
		});
	}

	snapback(options = {}, done) {
		this.stop(() => {
			const startingScale = parseFloat(this.el.getAttribute('data-longpress-scale')) || 1;
			if (!startingScale || Number(startingScale) === 1) {
				this.emit('close', options);
				done && done();
				return;
			}

			this.tick({
				startingScale,
				start: window.performance.now(),
				duration: options.duration || SNAP_BACK_TIME,
				direction: -1,
			}, () => {
				this.emit('close', options);
				done && done();
			});
		});
	}

	reset() {
		this.el.style.removeProperty('transform');
	}

	stop(done) {
		clearTimeout(this.to);
		if (!this.ticking) {
			done && done();
			return;
		}
		this.shouldStop = true;
		this.stopDone = done;
	}

	destroy() {
		clearTimeout(this.to);
		this.reset();
		this.destroyed = true;
	}
}
