import {
	getSoloExperiences,
	update,
	getSoloLoading,
	requestSoloExperiences,
	requestMoreSoloExperiences,
	getHasMoreToLoad,
} from './index';

export default {
	selector: getSoloExperiences,
	requestExperiences: requestSoloExperiences,
	requestMore: requestMoreSoloExperiences,
	loadingSelector: getSoloLoading,
	hasMoreToLoad: getHasMoreToLoad,
	update,
};
