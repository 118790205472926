import { discover } from 'lib/touchable-regions';
import getRotation from 'utils/get-rotation';
import getScale from 'utils/get-scale';

const getRadians = (el) => {
	const angle = getRotation(el);
	const rads = angle * (Math.PI / 180);
	return rads;
};

const getRegions = (editingCanvas, ignore) => {
	const regions = [];

	if (!ignore) {
		document.body.appendChild(editingCanvas.container);
	}
	const { width: totalWidth, height: totalHeight } = editingCanvas.container.getBoundingClientRect();

	editingCanvas.items.forEach((item) => {
		if (!item?.data?.specialNodes) {
			return;
		}

		item.data.specialNodes.forEach((specialNode) => {
			if (specialNode.div) {
				const overlay = specialNode.div.parentElement;
				const rotate = getRadians(overlay);
				const scale = getScale(overlay);
				const { transform } = overlay.style;

				overlay.style.removeProperty('transform');

				const outer = overlay.getBoundingClientRect();
				const innerRect = specialNode.div.getBoundingClientRect();
				const inner = {
					x: specialNode.div.offsetLeft,
					y: specialNode.div.offsetTop,
					height: innerRect.height,
					width: innerRect.width,
				};

				const {
					x,
					y,
					width,
					height,
				} = discover({
					outer,
					inner,
					rotate,
					scale,
				});

				overlay.style.transform = transform;

				const position = {
					x: x / totalWidth,
					y: y / totalHeight,
					height: height / totalHeight,
					width: width / totalWidth, // give it a little padding
					rotation: rotate,
					scale,
				};

				//	debugging function
				//	 window.drawIt = () => {
				//		 const pxit = (px) => `${100 * px}%`;
				//		 const div = document.createElement('div');
				//		 div.style.position = 'absolute';
				//		 div.style.zIndex = 21000000000;
				//		 div.style.top = pxit(position.y);
				//		 div.style.left = pxit(position.x);
				//		 div.style.width = pxit(position.width);
				//		 div.style.height = pxit(position.height);
				//		 div.style.backgroundColor = 'red';
				//		 div.style.opacity = 0.3;
				//		 div.style.transform = transform;
				//		 document.body.appendChild(div);
				//	 };

				regions.push({
					position,
					type: specialNode.type === 'ccMention' ? 'mention' : specialNode.type,
					...specialNode.data,
				});
			}
		});
	});

	if (!ignore) {
		try {
			document.body.removeChild(editingCanvas.container);
		} catch (_) {
			// Safe to ignore error
		}
	}
	return regions;
};

export default getRegions;
