import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
	captured,
	selectExperienceActivities,
	selectClips,
	requestUserIdentifiers,
	selectUserIdentifiers,
	selectLoading,
	selectErrors,
	hideClipTray,
	selectCaptured,
} from 'app/slices/content-creation/experience';
import { publish, getActive } from 'app/slices/content-creation/experience/queue';
import RequestProvider from 'rds/providers/Request';
import {
	getLoading as getAppLoading,
	getConstants,
	getErrors,
	requestApp,
} from 'app/slices/app/app';
import { setCreateNavigation } from 'app/slices/content-creation/create/device-library/state';
import Activities from './Activities';
import TopControls from './TopControls';
import ClipTray from './ClipTray';
import DestinationTag from '../Editor/Destination/DestinationTag';
import TitleAndCaptionContainer from './TitleAndCaptionContainer';
import RecommendedFor from './RecommendedFor';
import Identifiers from './Identifiers';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		height: '100%',
	},
	overlay: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		zIndex: 1,
	},
});

export default ({ params }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const hasCaptured = useSelector(selectCaptured);
	const activities = useSelector(selectExperienceActivities);
	const [showError, setShowError] = useState(false);
	const [needMedia,	setNeedMedia] = useState(false);
	const clips = useSelector(selectClips);
	const isPublishing = useSelector(getActive);

	useEffect(() => {
		for (let i = 0; i < clips.length; i++) {
			clips[i].editingCanvas.pauseAudio();
		}

		dispatch(captured());
	}, [hasCaptured, clips]);

	useEffect(() => {
		if (activities.length) {
			setShowError(false);
		}
	}, [activities]);

	useEffect(() => {
		dispatch(setCreateNavigation({
			open: true,
			slide: true,
		}));
		return () => {
			dispatch(hideClipTray());
		};
	}, []);

	return (
		<div className={classes.root}>
			{isPublishing && <div className={classes.overlay} />}
			<TopControls onClick={async () => {
				if (!clips.length) {
					setNeedMedia(true);
				}
				if (!activities.length) {
					setShowError(true);
				} else if (clips.length) {
					const response = await dispatch(publish(
						'/my-account',
						// `/experience/create/photo/gateway?route=${encodeURIComponent('/my-account')}`,
					));
					if (response.type === 'publish/fulfilled') {
						//
					}
				}
			}}
			/>
			<RequestProvider
				requests={{
					constants: {
						action: requestApp,
						selector: getConstants,
						errors: getErrors,
						loading: getAppLoading,
					},
					identifiers: {
						action: requestUserIdentifiers,
						selector: selectUserIdentifiers,
						loading: selectLoading,
						errors: selectErrors,
					},
				}}
			>
				<ClipTray params={params} needMedia={needMedia} />
				<DestinationTag />
				<TitleAndCaptionContainer />
				<Activities showError={showError} />
				<RecommendedFor />
				<Identifiers />
			</RequestProvider>
		</div>
	);
};
