import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import Filters from '../Filters';
import SearchBar from '../SeachBar';

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		position: 'sticky',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 14,
		height: 112,
		width: '100%',
		background: theme.palette.background.page,
		overflowX: 'clip',
	},
	filterHeaderClass: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		marginTop: 112,
		marginBottom: 16,
		zIndex: 18,
	},
	rootSecondary: {
		height: 160,
		paddingTop: theme.spacing(12),
	},
	iconContainer: {
		position: 'absolute',
		justifyContent: 'center',
		alignItems: 'center',
		width: 48,
		height: 48,
		top: 0,
		left: 0,
		zIndex: 15,
	},
	inactive: {
		opacity: 0.3,
	},
	header: {
		zIndex: 1,
		overflowX: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#ffffff',
		width: '100%',
		paddingLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		paddingRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		backgroundColor: theme.palette.background.page,
		height: 48,
		position: 'absolute',
	},
	exploreHeader: {
		justifyContent: 'start',
		alignItems: 'end',
	},
	exploreContents: {},
	searchContents: {},
}));

export default ({ isSearching, setIsSearching }) => {
	const classes = useStyles();
	const [modalOptions, setModalOptions] = useState(null);
	const show = useSelector(getIsUpdatedVersionAvailable);

	const headerContainerClass = show ? clsx(classes.headerContainer, classes.rootSecondary) : clsx(classes.headerContainer);

	const exploreHeaderClass = clsx(classes.header, classes.exploreHeader);

	return (
		<div className={headerContainerClass}>
			<div className={exploreHeaderClass}>
				<Typography className={modalOptions ? clsx(classes.exploreContents, classes.inactive) : classes.exploreContents} variant="h5">Explore with Rhino</Typography>
			</div>
			<div className={classes.filterHeaderClass}>
				<Filters
					modalOptions={modalOptions}
					setModalOptions={setModalOptions}
				/>
			</div>
			<SearchBar
				isSearching={isSearching}
				setIsSearching={setIsSearching}
				isFilerModalOpen={modalOptions}
			/>
		</div>
	);
};
