import { injectReducer } from 'services/store';
import { createSlice } from '@reduxjs/toolkit';
import deduplicateList from 'utils/deduplicate-List';
import {
	requestAllExperiencesByDestination,
	requestLoadMoreExperiencesByDestination,
} from './thunks';

const updateLocalStorage = (filters, uid) => {
	const stringifiedFilters = JSON.stringify(filters);
	window.sessionStorage.setItem(`destinationFilters-${uid}`, stringifiedFilters);
};

const getDestinationFilters = (uid) => JSON.parse(window.sessionStorage.getItem(`destinationFilters-${uid}`));

const initialState = {
	experiences: [],
	loading: false,
	loadingMore: false,
	filters: { Activity: [], Community: [], 'Recommended for': [] },
	filterHasBeenChanged: false,
	openingFiltersState: null,
	next: null,
	errors: null,
	seenBefore: {},
};

const name = 'destinationAccountExperiences';

const destinationExperiencesSlice = createSlice({
	name,
	initialState,
	reducers: {
		update: (state, action) => {
			const previousState = [...state.experiences];
			const newState = [];
			for (let i = 0; i < previousState.length; i++) {
				if (previousState[i].uid === action.payload.uid) {
					previousState[i] = action.payload;
				}
				newState.push(previousState[i]);
			}
			state.experiences = newState;
		},
		reset: () => ({ ...initialState }),
		deleteMyExperience: (state, action) => {
			const { uid } = action.payload;
			state.experiences = state.experiences.filter((exp) => exp.uid !== uid);
		},
		addFilters: (state, action) => {
			const { group, filter, uid } = action.payload;
			const updatedFilters = getDestinationFilters(uid) || state.filters;
			updatedFilters[group].push(filter);
			state.filters = updatedFilters;
			updateLocalStorage(updatedFilters, uid);
			state.filterHasBeenChanged = true;
			state.filters = initialState.filters;
		},
		removeFilters: (state, action) => {
			const { group, filter, uid } = action.payload;
			const updatedFilters = getDestinationFilters(uid) || state.filters;
			updatedFilters[group] = updatedFilters[group].filter((o) => o.slug !== filter.slug);
			state.filterHasBeenChanged = true;
			state.filters = updatedFilters;
			updateLocalStorage(updatedFilters, uid);
			state.filters = initialState.filters;
		},
		resetFilters: (state, action) => {
			updateLocalStorage(initialState.filters, action.payload);
		},
		resetToPreviousState: (state, action) => {
			state.filterHasBeenChanged = false;
			state.filters = state.openingFiltersState;
			updateLocalStorage(state.openingFiltersState, action.payload);
			state.filters = initialState.filters;
		},
		setOpenModalState: (state, action) => {
			const p = getDestinationFilters(action.payload?.uid) || state.filters;
			state.openingFiltersState = { ...p };
		},
	},
	extraReducers: {
		[requestAllExperiencesByDestination.pending]: (state) => {
			state.loading = true;
		},
		[requestAllExperiencesByDestination.fulfilled]: (state, action) => {
			const { experiences, _links } = action.payload;
			state.loading = false;
			state.seenBefore = {};
			const experiencesList = deduplicateList(experiences, state.seenBefore, 'uid');
			state.experiences = experiencesList;
			state.next = _links?.next?.href || null;
			state.errors = null;
			state.filterHasBeenChanged = false;
		},
		[requestAllExperiencesByDestination.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestLoadMoreExperiencesByDestination.pending]: (state) => {
			state.loadingMore = true;
			state.errors = null;
		},
		[requestLoadMoreExperiencesByDestination.fulfilled]: (state, action) => {
			const { experiences, _links } = action.payload;
			const experiencesList = deduplicateList(experiences, state.seenBefore, 'uid');
			state.experiences = [...state.experiences, ...experiencesList];
			state.next = _links?.next?.href;
			state.loadingMore = false;
			state.errors = null;
		},
		[requestLoadMoreExperiencesByDestination.rejected]: (state, action) => {
			state.loadingMore = false;
			state.errors = action.payload;
		},
	},
});

export const {
	update,
	resetECR,
	reset,
	deleteMyExperience,
	addFilters,
	removeFilters,
	resetFilters,
	resetToPreviousState,
	setOpenModalState,
} = destinationExperiencesSlice.actions;
export default destinationExperiencesSlice.reducer;

injectReducer(name, destinationExperiencesSlice.reducer);
