import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import { clearPort } from 'app/android-service';
import { reset, requestMedia } from 'app/slices/content-creation/create/device-library/media';
import useNavigateCC from 'app/hooks/useNavigateCC';

const useStyles = makeStyles((theme) => ({
	errorRoot: {
		position: 'absolute',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	container: {
		textAlign: 'center',
	},
	header: {
		marginBottom: theme.spacing(4),
	},
	error: {
		color: theme.colors.primary.C400,
		marginTop: theme.spacing(4),
	},
	retry: {
		marginLeft: theme.spacing(2),
		color: theme.colors.primary.C400,
	},
	refresh: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(12.5),
	},
}));

export default ({ errors }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const navigateCC = useNavigateCC();

	return (
		<div className={classes.errorRoot}>
			<div className={classes.container}>
				<Typography className={classes.header} variant="h3">
					Could not load media
				</Typography>
				<div
					className={classes.refresh}
					onClick={async () => {
						await dispatch(reset());
						dispatch(requestMedia());
					}}
				>
					<IconV3
						icon="Refresh"
						color="primaryC600"
						size={20}
						containerSize={20}
					/>
					<Typography className={classes.retry} variant="subtitle1">Retry</Typography>
				</div>
				{errors && errors.map((error) => (
					<Typography className={classes.error} variant="subtitle2">
						{error.text}
					</Typography>
				))}
				<div
					className={classes.error}
					onClick={() => {
						clearPort();
						navigateCC(null, {
							subTab: null,
						});
					}}
				>
					<Typography variant="subtitle1">Use our legacy device library</Typography>
				</div>
			</div>
		</div>
	);
};
