import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Transition } from 'react-transition-group';

const TIME_OUT = 200;

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.background.default,
		borderRadius: '8px 8px 0 0',
		position: 'fixed',
		bottom: 0,
		left: theme.spacing(4),
		right: theme.spacing(4),
		zIndex: 28,
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(),
		display: 'flex',
		flexDirection: 'column',
		transition: `transform ${TIME_OUT}ms ease-in-out`,
	},
	background: {
		position: 'fixed',
		bottom: 0,
		top: 0,
		left: 0,
		right: 0,
		width: '100%',
		height: '100%',
		zIndex: 16,
		background: theme.colors.neutral.C900,
		opacity: 0.6,
	},
	backgroundTransition: {
		transition: `opacity ${TIME_OUT}ms ease-in-out`,
	},
	backgroundEntering: {
		opacity: 0,
	},
	backgroundEntered: {
		opacity: 0.6,
	},
	backgroundExiting: {
		opacity: 0,
	},
	backgroundExited: {
		opacity: 0,
	},
	item: {
		paddingBottom: theme.spacing(4),
		paddingTop: theme.spacing(4),
		'&:hover': {
			background: theme.colors.neutral.C900,
		},
	},
	entering: {
		transform: 'translateY(100%)',
	},
	entered: {
		transform: 'translateY(0)',
	},
	exiting: {
		transform: 'translateY(100%)',
	},
	exited: {
		transform: 'translateY(100%)',
	},
}));

export default ({
	items,
	onClose,
	keepOnSelect,
	show,
}) => {
	const classes = useStyles();

	return (
		<>
			<Transition in={show} timeout={TIME_OUT} mountOnEnter unmountOnExit>
				{(state) => (
					<div className={`${classes.root} ${classes[state]}`}>
						{items && items.map((item, i) => (
							<div key={i} onClick={item.disabled ? item.disabledOnClick : undefined}>
								<Button
									fullWidth
									data-ignoreslider="true"
									disabled={item.disabled}
									datatype="button"
									className={classes.item}
									onClick={(e) => {
										e.stopPropagation();
										if (!keepOnSelect) {
											onClose();
										}
										item.onClick(e);
									}}
									style={{ ...item }}
								>
									{item.label}
								</Button>
							</div>
						))}
					</div>
				)}
			</Transition>
			<Transition in={show} timeout={TIME_OUT} mountOnEnter unmountOnExit>
				{(state) => (
					<div
						className={`${classes.background} ${classes.backgroundTransition} ${classes[`background${ state.charAt(0).toUpperCase() }${state.slice(1)}`]}`}
						onClick={(e) => {
							const type = e.target.getAttribute('data-type');
							if (type !== 'button') {
								onClose();
							}
						}}
					/>
				)}
			</Transition>
		</>
	);
};
