import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, makeStyles } from '@material-ui/core';
import {
	getMyProfileUuid,
} from 'app/slices/user-account-v2/accounts';
import {
	requestMergeMyExperiences,
} from 'app/slices/user-account-v2/my-experiences';
import {
	requestInProgressExperiences,
	getInProgressExperiences,
} from 'app/slices/user-account-v2/in-progress';
import isDeveloper from 'lib/developer';
import Experience from './Experience';

// if there are experiences
const SHORT_DELAY = 5 * 1000; // 5 seconds
// if there aren't experiences
const LONG_DELAY = 60 * 1000; // 60 seconds

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(6),
		marginLeft: theme.spacing(6),
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showDetails, setShowDetails] = useState(false);
	const accountUuid = useSelector(getMyProfileUuid);
	const inProgress = useSelector(getInProgressExperiences);

	useEffect(() => {
		let stop = false;

		let to;

		const delay = inProgress.length ? SHORT_DELAY : LONG_DELAY;

		const nextRequest = (id) => {
			to = setTimeout(async () => {
				if (stop) {
					return;
				}
				await dispatch(requestInProgressExperiences());
				await dispatch(requestMergeMyExperiences({ accountUuid: id }));
			}, delay);
		};

		if (accountUuid && !!inProgress.length) {
			stop = false;
			nextRequest(accountUuid);
		} else {
			stop = true;
		}

		return () => {
			stop = true;
			clearTimeout(to);
		};
	}, [inProgress, accountUuid]);

	if (!inProgress.length) {
		return null;
	}

	return (
		<List classes={{ root: classes.root }}>
			{inProgress.map(({
				title,
				thumbnail,
				failed,
				status,
				chunksRemaining,
				totalChunks,
				total,
				completed,
				uid,
				percentage,
			}) => (
				<Experience
					key={uid}
					uid={uid}
					title={title}
					thumbnail={thumbnail}
					chunksRemaining={chunksRemaining}
					totalChunks={totalChunks}
					total={total}
					completed={completed}
					failed={failed}
					status={status}
					percentage={percentage}
					showDetails={showDetails}
					onItemClick={() => {
						if (isDeveloper()) {
							setShowDetails(!showDetails);
						}
					}}
				/>
			))}
		</List>
	);
};
