import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Followers from './Followers';

const TabPanel = (props) => {
	const {
		children, value, index,
	} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</div>
	);
};

const users = [
	{
		username: 'alirezans',
		displayName: 'Alireza',
		avatar: { small: { jpg: '' } },
	},
];

export default () => (
	<Box>
		<Box>
			<Tabs variant="fullWidth" centered indicatorColor="primary" value={0}>
				<Tab disableRipple disableFocusRipple label="Followers" />
			</Tabs>
		</Box>
		<TabPanel value={0} index={0}>
			<Followers users={users} />
		</TabPanel>
	</Box>
);
