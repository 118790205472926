import React from 'react';
import { makeStyles } from '@material-ui/core';
import useRdsTheme from 'rds/theme/useRdsTheme';
import Indicator from './Indicator';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		bottom: 0,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'bottom center',
		zIndex: 4,
	},
	indicators: {
		display: 'flex',
		flexDirection: 'row',
	},
	inner: {
		position: 'relative',
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(5),
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
}));

export default () => {
	const classes = useStyles();
	const theme = useRdsTheme();
	const bg = theme.palette.background.page;

	return (
		<div className={classes.root} style={{ background: bg }}>
			<div className={classes.inner}>
				<div className={classes.indicators}>
					<Indicator route="/onboarding/activities" />
					<Indicator route="/onboarding/destinations" />
					<Indicator route="/onboarding/regions" />
				</div>
			</div>

		</div>
	);
};
