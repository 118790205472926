import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Hero from 'rds/components/Layouts/Hero';
import Logo from 'rds/components/Logo';
import api from 'services/api';
import { useQueryParam } from 'app/hooks/useQueryParams';
import TopButton from 'rds/components/Layouts/FixedTopBar/TopButton';
import useRdsTheme from 'rds/theme/useRdsTheme';
import { getIsOnboardingCompleted, getSession } from 'app/slices/authentication/session/session';
import { useNavigate } from 'react-router-dom';

const SPLASH_TIMER = 2000;

const useStyles = makeStyles((theme) => ({
	faded: {
		color: theme.colors.neutral.C000,
	},
	content: {
		zIndex: 3,
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
	},
	show: {
		opacity: 1,
	},
	gradient: {
		background: 'linear-gradient(180deg, #1B242D 0%, #1B242D 0%, rgba(27, 36, 45, 0.8) 9.6%, rgba(27, 36, 45, 0.6) 18.7%, rgba(27, 36, 45, 0.4) 28.06%, rgba(27, 36, 45, 0.2) 37.16%, rgba(27, 36, 45, 0) 47.33%, rgba(27, 36, 45, 0) 100%);',
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
	},
	overideColor: {
		color: theme.colors.neutral.C000,
		zIndex: 3,
	},
	header: {
		zIndex: 4,
	},

}));

export default () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const stay = useQueryParam('stay');
	const theme = useRdsTheme();
	const session = useSelector(getSession);
	const username = session?.username;
	const isOnboardingCompleted = useSelector(getIsOnboardingCompleted);
	const isItRedirectedFromNotification = useQueryParam('notificationRedirect');

	useEffect(() => {
		if (isOnboardingCompleted && !isItRedirectedFromNotification) {
			navigate(`/@${username}`);
		}
	}, [isOnboardingCompleted]);

	useEffect(() => {
		try {
			api.post('/accounts/follow/all-suggested-users');
		} catch (_) {
			// its ok to ignore this error
		}
	}, []);

	useEffect(() => {
		let to;
		if (!stay) {
			to = setTimeout(() => {
				const redirectPath = isItRedirectedFromNotification ? '/onboarding/activities?notificationRedirect=true' : '/onboarding/activities';
				navigate(redirectPath);
			}, SPLASH_TIMER);
		}
		return () => { clearTimeout(to); };
	}, []);

	return (
		<>
			<div className={classes.content}>
				<div className={classes.gradient} />
				<div className={classes.header}>
					<TopButton
						label="Next"
						exceptionColor={theme.colors.neutral.C000}
						right={0}
						to="/onboarding/activities"
					/>
				</div>

				<Hero
					image={(
						<Logo
							bodyColor="#ffffff"
							square
							size="small"
							style={{ filter: 'drop-shadow(0px 2px 8px rgba(35, 47, 46, 0.5))' }}
						/>
					)}
					title={(
						<div className={classes.overideColor}>
							Welcome to the community
						</div>
					)}
					caption={(
						<span className={classes.faded}>
							Help us get to know you
						</span>
					)}
				/>
			</div>

		</>
	);
};
