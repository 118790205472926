import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from 'rds/components/TextField';
import Modal from 'rds/components/Modal';
import { control, resetForm } from 'app/slices/destinations-search/form';
import {
	getLoading,
	reset,
	requestSearch,
	getDestinations,
} from 'app/slices/destinations-search';
import IconV3 from 'rds/components/IconV3';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import { changeDestinationLayout, getModal } from 'app/slices/destinations-search/layout';
import { change } from 'app/slices/content-creation/editor/layout';
import Chip from 'rds/components/Chip';
import SuggestedDestinations from './SuggestedDestinations';
import Results from './Results';

const TIME_OUT = 650;

const useStyles = makeStyles((theme) => ({
	fixedContainer: {
		position: 'fixed',
		left: 0,
		right: 0,
		top: theme.spacing(16),
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		zIndex: 11,
	},
	background: {
		position: 'fixed',
		zIndex: 1,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top center',
		top: 0,
		left: 0,
		right: 0,
		paddingBottom: theme.spacing(2),
	},
	resultText: {
		paddingBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
	},
	selectedContainer: {
		marginTop: theme.spacing(4),
		textAlign: 'left',
	},
	contents: {
		marginTop: theme.spacing(2),
	},
	selectedText: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	help: {
		marginBottom: theme.spacing(8),
	},
}));

const DEBOUNCE_SEARCH = 200;
let DEBOUNCE_SINGLETON;

export default ({
	onSelect,
	selectedDestination,
	showSelected,
	title = 'Add Destination',
	onRemoveSelected,
	onClose,
	onOpen,
	disableCustomDestination,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const ref = useRef();
	const fields = control.get(dispatch);
	const search = useSelector(fields.search.selector);
	const searchErr = useSelector(fields.search.err);
	const destinations = useSelector(getDestinations);
	const loading = useSelector(getLoading);
	const theme = useTheme();
	const open = useSelector(getModal);
	const [isUserTyping, setIfUserTyping] = useState(false);
	const [focused, setFocused] = useState(false);

	useEffect(() => () => {
		dispatch(resetForm());
		dispatch(reset());
	}, []);

	const bg = theme.palette.background.page;
	let paddingTop;

	if (showSelected && !selectedDestination) {
		paddingTop = theme.spacing(47);
	} else if (showSelected && selectedDestination && !!selectedDestination) {
		paddingTop = theme.spacing(57);
	} else {
		paddingTop = theme.spacing(35);
	}

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				ref?.current?.focus();
			}, TIME_OUT);
			onOpen && onOpen();
		} else {
			onClose && onClose();
		}
	}, [open, ref]);

	return (
		<Modal
			open={open}
			top={{
				close: () => {
					dispatch(changeDestinationLayout({ modal: false }));
					dispatch(change({ showControls: true }));
				},
				title: { text: title },
				closeIconPosition: 'left',
			}}
		>
			<div
				className={classes.background}
				style={{
					background: bg,
					paddingTop,
				}}
			>
				<div className={classes.fixedContainer}>
					<TextField
						inputRef={ref}
						value={search}
						variant="glass"
						searchField
						poweredByGoogle="dark"
						searchFieldFocused={focused}
						onFocus={() => {
							setFocused(true);
						}}
						onBlur={() => {
							setFocused(false);
						}}
						err={searchErr}
						onChange={(e) => {
							fields.search.change(e);
							if (!isUserTyping) {
								setIfUserTyping(true);
							}
							clearTimeout(DEBOUNCE_SINGLETON);
							DEBOUNCE_SINGLETON = setTimeout(() => {
								dispatch(requestSearch());
								setIfUserTyping(false);
							}, DEBOUNCE_SEARCH);
						}}
						formItem
						icon={{
							icon: 'Search',
							size: 20,
						}}
						right={(
							focused ? (
								<div
									onClick={() => {
										dispatch(resetForm());
									}}
								>
									<IconV3
										icon="XCircleFilledLayers"
										size={20}
										containerSize={22}
										gredient
									/>
								</div>
							) : null
						)}
						placeholder="Search destinations"
						requesting={loading}
					/>
					{showSelected && (
						<div className={classes.selectedContainer}>
							<Typography className={classes.help} variant="body1">{selectedDestination ? 'Selected' : 'Search or select a destination.'}</Typography>
							<div className={classes.contents}>
								{selectedDestination && (
									<Chip
										actionicon={(
											<IconV3
												icon="Cancel"
											/>
										)}
										labelIcon={(
											<IconV3
												icon="DestinationTag"
											/>
										)}
										contents={selectedDestination.title}
										onClick={() => {
											onRemoveSelected();
										}}
									/>
								)}
							</div>
						</div>

					)}

					<Typography variant="subtitle2" className={classes.resultText}>
						{
							destinations && !destinations.length && search.length && !isUserTyping && !loading
								? 'No destinations found'
								: !search.length && showSelected ? 'Suggested by Rhino' : search.length ? 'Destination Results' : null
						}
					</Typography>
				</div>

			</div>
			{!search.length && showSelected ? (
				<SuggestedDestinations open={open} onClick={onSelect} showSelected={selectedDestination} />
			) : (
				<Results disableCustomDestination={disableCustomDestination} onClick={onSelect} isUserTyping={isUserTyping} showSelected={showSelected} />
			)}

		</Modal>
	);
};
