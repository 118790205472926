const getRotatedPoint = (center, point, rads) => {
	const cos = Math.cos(rads);
	const sin = Math.sin(rads);
	const x = (cos * (point.x - center.x)) + (sin * (point.y - center.y)) + center.x;
	const y = (cos * (point.y - center.y)) - (sin * (point.x - center.x)) + center.y;
	return { x, y };
};

export default ({
	inner,
	outer,
	rotate,
	scale = 1,
}) => {
	// find the center of the box to rotate points around
	const center = {
		x: outer.x + (outer.width / 2),
		y: outer.y + (outer.height / 2),
	};

	// now find the center point of the mention
	// relative to the box
	const mentionCenter = {
		x: outer.x + (inner.x + (inner.width / 2)),
		y: outer.y + inner.y + (inner.height / 2),
	};

	// now take the difference in x & y
	// between the mention center and center
	// and scale that
	const diffx = mentionCenter.x - center.x;
	const diffy = mentionCenter.y - center.y;
	mentionCenter.x += (diffx * scale) - diffx;
	mentionCenter.y += (diffy * scale) - diffy;

	// now move the mention center point around the origin center point
	// to find the new center point of a scaled & rotated mention
	const rotatedMentionCenter = getRotatedPoint(
		center,
		mentionCenter,
		-rotate,
	);

	// now find the top left corner using the unmodified height & width
	const topLeft = {
		x: rotatedMentionCenter.x - (inner.width / 2),
		y: rotatedMentionCenter.y - (inner.height / 2),
	};

	// now return the position and box
	return {
		x: topLeft.x,
		y: topLeft.y,
		height: inner.height,
		width: inner.width,
	};
};
