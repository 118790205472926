import { createFormSlice } from 'services/slice';
import emailValidator from 'validators/email';
import passwordValidator from 'validators/password';
import usernameValidator from 'validators/username';

export const signUpFormSlice = createFormSlice({
	name: 'signUpFormSlice',
	initialState: {
		dateOfBirth: null,
		email: '',
		password: '',
		username: '',
		errors: [],
	},
	validators: {
		email: emailValidator,
		password: passwordValidator,
		username: usernameValidator,
	},
});

const {
	// slice
	control, reducer,
	// actions
	resetErrors, resetForm, setErrors, setFormField,
	// selectors
	getFormField, getFormErrors, getFormFieldError, getFormPayload,
} = signUpFormSlice;

export {
	control, reducer,
	resetErrors, resetForm, setErrors, setFormField,
	getFormField, getFormErrors, getFormFieldError, getFormPayload,
};

export default reducer;
