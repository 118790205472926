export default (flash) => {
	let flashIcon;
	let next;

	switch (flash) {
	case 'off':
		flashIcon = 'FlashOffFilled';
		next = 'flash';
		break;
	case 'flash':
		flashIcon = 'FlashOnFilled';
		next = 'off';
		break;
	default:
		flashIcon = 'FlashOffFilled';
	}

	return { flashIcon, next };
};
