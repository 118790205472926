import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import {
	updateUsername,
} from 'app/slices/user-account-v2/accounts';
import { notify } from 'app/slices/notifications/notifications';
import { requestSession } from 'app/slices/authentication/session/session';
import handleSliceError from 'utils/handle-slice-error';

export const requestUserPrivateProfile = createAsyncThunk(
	'requestUserPrivateProfile',
	async (_, { rejectWithValue }) => {
		try {
			const response = await api.get('accounts/profile?include=emailVerification');
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestDeleteMyAccount = createAsyncThunk(
	'requestDeleteMyAccount',
	async (_, { rejectWithValue, getState }) => {
		const { uuid } = getState().session.session;
		if (!uuid) {
			return null;
		}
		try {
			const response = await api.post(`accounts/${uuid}/delete-account`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestChangeUsername = createAsyncThunk(
	'requestChangeUsername',
	async ({ username }, { getState, rejectWithValue, dispatch }) => {
		const { uuid, username: currentUsername } = getState().session.session;
		try {
			const response = await api.patch(`accounts/${uuid}/profile/username`, {
				username,
			});
			await dispatch(updateUsername({ currentUsername, username }));
			await dispatch(requestSession({ force: true }));

			dispatch(notify({
				severity: 'info',
				description: 'Username updated.',
				autoHideDuration: 2000,
			}));
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestVerifyEmail = createAsyncThunk(
	'requestVerifyEmail',
	async (_, { getState, rejectWithValue, dispatch }) => {
		const { uuid, requiresEmailVerification } = getState().session.session;
		const emailUuid = requiresEmailVerification?.emailUuid;

		try {
			if (!uuid || !emailUuid) {
				return null;
			}
			const response = await api.post(`accounts/emails/${uuid}/email/${emailUuid}/verify`);
			dispatch(notify({
				severity: 'info',
				description: 'Verification email sent.',
				autoHideDuration: 2000,
			}));
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error, { notifyUser: false }));
		}
	},
);

const initialState = {
	username: '',
	email: '',
	dateOfBirth: '',
	requesting: false,
	loading: false,
	errors: null,
	requiresEmailVerification: null,
};

const accountSettingsSlice = createSlice({
	name: 'accountSettings',
	initialState: { ...initialState },
	reducers: {
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
	},
	extraReducers: {
		[requestUserPrivateProfile.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestUserPrivateProfile.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestUserPrivateProfile.fulfilled]: (state, action) => {
			state.loading = false;
			state.errors = null;
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},

		[requestDeleteMyAccount.rejected]: (state, action) => {
			state.errors = action.payload;
		},
		[requestDeleteMyAccount.fulfilled]: (state) => {
			state.errors = null;
		},
		[requestChangeUsername.pending]: (state) => {
			state.requesting = true;
			state.errors = null;
		},
		[requestChangeUsername.rejected]: (state, action) => {
			state.requesting = false;
			state.errors = action.payload;
		},
		[requestChangeUsername.fulfilled]: (state) => {
			state.requesting = false;
			state.errors = null;
		},
	},
});

const { name, reducer, actions } = accountSettingsSlice;
const { change, reset } = actions;

export {
	name, change, reset,
};

export const getSlice = (state) => state[name];

export const getUserUsername = createSelector(getSlice, (slice) => slice?.username);
export const getUserEmail = createSelector(getSlice, (slice) => slice?.email);
export const getRequesting = createSelector(getSlice, (slice) => slice?.loading);
export const getUserDateOfBirth = createSelector(getSlice, (slice) => slice?.dateOfBirth);
export default accountSettingsSlice;

injectReducer(name, reducer);
