import React, {
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Avatar from 'rds/components/Avatar';
import { useFormUrl } from 'app/hooks/useQueryParams';
import { addToQueue, requestSendReadState } from 'app/slices/app-notifications/read-state';
import { markRead } from 'app/slices/app-notifications/notifications';
import store from 'services/store';
import { setOpenDirective } from 'app/slices/experience-modal';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},
	left: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
	timeStamp: {
		color: theme.colors.neutral.C400,
	},
	right: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		width: '100%',
		color: theme.colors.neutral.C000,
	},
	readStatus: {
		marginLeft: theme.spacing(-2),
		marginRight: theme.spacing(),
		width: 4,
		height: 44,
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
		transition: 'all 0.4s ease',
	},
	unread: {
		background: theme.colors.neutral.C400,
	},
	title: {
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		display: '-webkit-box',
		wordBreak: 'break-word',
	},

}));

const DELAY = 1000;
const DEBOUNCE = 250;

let locked = false;
const debounceSend = () => {
	if (locked) {
		return;
	}
	locked = true;
	setTimeout(async () => {
		const response = await store.dispatch(requestSendReadState());
		locked = false;

		if (response.payload.length) {
			debounceSend();
		}
	}, DEBOUNCE);
};

export default ({ notification }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const formUrl = useFormUrl();
	const ref = useRef();
	const { unread } = notification;

	let timeStamp;
	if (notification?.created) {
		timeStamp = dayjs(notification.created).fromNow();
	}

	const observer = useMemo(
		() => new IntersectionObserver(([intersection]) => {
			if (!intersection.isIntersecting || !unread) {
				return;
			}

			setTimeout(() => {
				dispatch(markRead(notification.id));
				dispatch(addToQueue(notification.id));
				debounceSend();
			}, DELAY);
		}),
		[unread],
	);

	useEffect(() => {
		let isConnected = false;

		if (ref.current) {
			observer.observe(ref.current);
			isConnected = true;
		}

		return () => {
			if (isConnected) {
				observer.disconnect();
				isConnected = false;
			}
		};
	}, [ref, observer]);

	const generateFollowUrl = (n) => {
		switch (n.action) {
		case 'onboarding':
			return '/onboarding/splash?notificationRedirect=true';
		case 'user-account':
			return '/my-account';
		case 'user':
			if (n.expandedCount === 1) {
				const { username } = n?.context?.user;
				return `/${username}`;
			}
			return `/notifications/followers/${n.id}`;
		default:
			return '/';
		}
	};

	const mentionUrl = (n) => {
		const { uid } = n?.context?.experience;
		if (n?.context?.comment?.id) {
			return formUrl({
				params: {
					experience: uid,
					comment: n.context.comment.id,
					panel: 'comments',
				},
			});
		}
		return formUrl({
			params: {
				experience: uid,
			},
		});
	};

	const notificationUrlMap = {
		user: generateFollowUrl,
		experience: mentionUrl,
		comment: mentionUrl,
		onboarding: generateFollowUrl,
		'user-account': generateFollowUrl,
	};

	const handleNotificationClick = async (e, n) => {
		e.preventDefault();
		if (!n.action) {
			return;
		}
		const url = notificationUrlMap[n.action](n);
		await dispatch(setOpenDirective({
			type: 'slideIn',
		}));
		navigate(url);
	};

	const readClass = unread
		? clsx(classes.readStatus, classes.unread)
		: classes.readStatus;

	const username = notification?.context?.user?.username;

	const navigateToUser = () => {
		const url = `/${username || 'notifications'}`;
		navigate(url);
	};

	const avatarSrc = notification?.icon?.small?.jpg || notification?.icon;

	return (
		<div className={classes.root}>
			<div className={readClass} />
			<div onClick={navigateToUser} className={classes.left}>
				<Avatar
					size="large"
					alt="notification"
					src={avatarSrc}
				/>
			</div>
			<div onClick={(e) => handleNotificationClick(e, notification)} className={classes.right}>
				<Typography variant="body2" className={classes.title} ref={ref}>
					{/* eslint-disable-next-line */}
					<span dangerouslySetInnerHTML={{ __html: notification.title }} />
				</Typography>
				<Typography className={classes.timeStamp} variant="caption">
					{timeStamp}
				</Typography>
			</div>

		</div>
	);
};
