import React from 'react';
import { useSelector } from 'react-redux';
import { getSession } from 'app/slices/authentication/session/session';
import { getVisibleExperience } from 'app/slices/experience-modal';
import WriterMenu from './WriterMenu';
import ViewerMenu from './ViewerMenu';
import ExperienceCreatorMenu from './ExperienceCreatorMenu';

export default ({
	handleReport,
	handleDelete,
	onClose,
	author,
}) => {
	const session = useSelector(getSession);
	const experienceCreator = useSelector(getVisibleExperience);
	const commentCreator = author?.uuid;

	if (session?.uuid === commentCreator) {
		return (
			<WriterMenu
				onClose={onClose}
				handleDelete={handleDelete}
			/>
		);
	}

	if (session?.uuid === experienceCreator?.user?.uuid) {
		return (
			<ExperienceCreatorMenu
				onClose={onClose}
				handleReport={handleReport}
				handleDelete={handleDelete}
			/>
		);
	}
	return (
		<ViewerMenu
			onClose={onClose}
			handleReport={handleReport}
		/>
	);
};
