import { createSelector } from '@reduxjs/toolkit';

export const getSlice = (state) => state.destinationAccountExperiences;

export const getLoading = createSelector(getSlice, (slice) => slice?.loading);

export const getAllExperiencesByDestination = createSelector(getSlice, (slice) => slice?.experiences);

export const getNextLink = createSelector(getSlice, (slice) => slice?.next);

export const getLoadingMore = createSelector(getSlice, (slice) => slice?.loadingMore);

export const getErrors = createSelector(getSlice, (slice) => slice.errors);

export const getFilters = (uid) => createSelector(getSlice, (slice) => JSON.parse(window.sessionStorage.getItem(`destinationFilters-${uid}`)) ||	slice?.filters);

export const getFilterModal = createSelector(getSlice, (slice) => slice?.isFilterModalOpen);

export const getFilterHasBeenChanged = createSelector(getSlice, (slice) => slice?.filterHasBeenChanged);

export const getOpeningFilterState = createSelector(getSlice, (slice) => slice?.openingFiltersState);
