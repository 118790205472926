import EventEmitter from 'events';
import counter from 'utils/str-counter';
import {
	findCursor,
	placeCursor,
	placeCursorAtPosition,
} from './utils';
import Store from './store';

const nodes = {};
nodes.text = document.createElement('span');
nodes.text.classList.add('text');

nodes.mention = document.createElement('span');
nodes.mention.classList.add('mention');

nodes.ccMention = document.createElement('span');
nodes.ccMention.classList.add('cc-mention');

const ccMentionText = document.createElement('span');
ccMentionText.classList.add('text');

const ccMentionUnderline = document.createElement('span');
ccMentionUnderline.classList.add('cc-mentions__underline');
ccMentionUnderline.setAttribute('data-style-span', true);

nodes.ccMention.appendChild(ccMentionText);
nodes.ccMention.appendChild(ccMentionUnderline);

export default class Composer extends EventEmitter {
	constructor(textInput, options = {}) {
		super();
		this.store = new Store();

		this.store.on('update', this.update.bind(this));

		this.options = options;
		this.options.maxHeight = parseFloat(this.options.maxHeight) || null;
		this.options.maxLength = parseInt(this.options.maxLength, 10) || null;

		this.textInput = textInput;

		this.textInput.addEventListener('keydown', this.onKeydown.bind(this));
		this.textInput.addEventListener('keyup', this.onKeyup.bind(this));
		this.textInput.addEventListener('input', this.onInput.bind(this));
		this.textInput.addEventListener('focus', () => {
			this.update();
			this.placeCursorAtEnd();
		});
		this.textInput.addEventListener('blur', this.update.bind(this));
		this.update();
	}

	set string(s) {
		this.store.string = s;
	}

	get editableDiv() {
		return this.textInput;
	}

	addNode(...p) {
		this.store.addNode(...p);
		this.store.validateNodes();
	}

	nodeToHtml(node, active) {
		const n = nodes[node.type].cloneNode(true);

		if (node.id) {
			n.setAttribute('data-id', node.id);
		}

		if (n.classList.contains('text') || n.classList.contains('mention')) {
			n.textContent = node.contents;
		} else {
			n.querySelector('.text').textContent = node.contents;
		}

		if (!node.contents.trim()) {
			n.style.whiteSpace = 'pre';
		}

		if (active) {
			n.querySelectorAll('[data-style-span]').forEach((x) => x.remove());
		}

		return n;
	}

	toHtml(n, active) {
		const ret = [];

		for (let i = 0; i < n.length; i++) {
			ret.push(this.nodeToHtml(n[i], active));
		}

		return ret;
	}

	update() {
		const htmlNodes = this.toHtml(this.store.nodes, document.activeElement === this.editableDiv);
		this.textInput.innerHTML = '';
		htmlNodes.forEach((n) => {
			this.textInput.appendChild(n);
		});
		this.emit('update');
	}

	get cursorRange() {
		const sel = window.getSelection();
		const range = sel.getRangeAt(0);
		return range;
	}

	trackCursor() {
		this.cursor = findCursor(this.editableDiv);
	}

	onKeydown(e) {
		if (e.key === 'Tab') {
			e.preventDefault();
		}
		this.prev = {
			html: this.textInput.innerHTML,
			range: findCursor(this.textInput),
		};
	}

	onKeyup() {
		this.trackCursor();
	}

	// event listeners
	onInput() {
		const height = this.textInput.scrollHeight;

		if (this.options.maxLength && counter(this.textInput.textContent) > this.options.maxLength) {
			this.textInput.innerHTML = this.prev.html;
			placeCursor(this.textInput, this.prev.range);
			return;
		}

		if (this.options.maxHeight && height > this.options.maxHeight) {
			this.textInput.innerHTML = this.prev.html;
			placeCursor(this.textInput, this.prev.range);
			return;
		}

		this.trackCursor();
		this.store.validateNodes(this.textInput.innerText);
		this.store.string = this.textInput.innerText;
		placeCursor(this.editableDiv, this.cursor);
	}

	placeCursorPosition(position) {
		this.cursor = placeCursorAtPosition(this.editableDiv, position);
	}

	placeCursorAtEnd() {
		this.cursor = placeCursorAtPosition(this.editableDiv, this.textInput.innerText.length);
	}

	padEndWithSpace() {
		const hasSpace = this.store._originalString.endsWith(' ');
		if (!hasSpace) {
			const span = document.createElement('span');
			span.classList.add('text');
			span.innerHTML = '&nbsp;';
			this.textInput.appendChild(span);
		}
	}

	get nodes() {
		const n = [];

		for (let i = 0; i < this.textInput.children.length; i++) {
			const c = this.textInput.children[i];
			const id = parseInt(c.getAttribute('data-id'), 10);
			const node = this.store.getNodeById(id);

			if (id && node) {
				n.push({
					div: this.textInput.children[i],
					data: node.data,
					type: node.type,
					id,
				});
			} else {
				n.push({
					data: c.innerText,
					type: 'text',
				});
			}
		}

		return n;
	}

	get specialNodes() {
		const snodes = [];

		for (let i = 0; i < this.textInput.children.length; i++) {
			const c = this.textInput.children[i];
			const id = parseInt(c.getAttribute('data-id'), 10);
			const node = this.store.getNodeById(id);

			if (id && node) {
				snodes.push({
					div: this.textInput.children[i],
					data: node.data,
					type: node.type,
					id,
				});
			}
		}

		return snodes;
	}

	destroy() {
		this.store.destroy();
	}
}
