import React, { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { buildVersion } from 'config';
import { setDeveloper } from 'lib/developer';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(12),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	title: {
		marginBottom: theme.spacing(8.5),
		color: theme.colors.neutral.C300,
		userSelect: 'none',
	},
}));

export default () => {
	const classes = useStyles();
	const [tap, setTap] = useState(0);

	return (
		<div className={classes.root}>
			<Typography
				className={classes.title}
				onClick={() => {
					setTap(tap + 1);
					if (tap === 10) {
						setDeveloper(true);
						window.location.reload();
					}
				}}
				variant="caption"
			>
				Version
				{' '}
				{buildVersion}
			</Typography>
		</div>
	);
};
