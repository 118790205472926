import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { getMyAvatar } from 'app/slices/user-account-v2/accounts';
import Avatar from 'rds/components/Avatar';
import { change } from 'app/slices/user-account-v2/edit-profile/slice';
import { getUpdatedAvatar } from 'app/slices/user-account-v2/edit-profile/selectors';
import UserForms from './UserForms';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginLeft: theme.spacing(3),
		overflowY: 'scroll',
		overflowX: 'hidden',
		maxHeight: '100vh',
		paddingBottom: '50%',
		widt: '100%h',
	},
	text: {
		color: theme.colors.primary.C600,
		marginLeft: theme.spacing(2),
	},

}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const currentAvatar = useSelector(getMyAvatar);
	const avatar = useSelector(getUpdatedAvatar);

	useEffect(() => {
		dispatch(change({ avatar: currentAvatar?.large?.jpg }));
	}, []);

	const onClick = () => {
		navigate('/my-account/avatar/create');
	};

	return (
		<div className={classes.root}>
			<Avatar
				src={avatar}
				size="xLarge"
				alt="Username"
				addIcon
				onClick={onClick}
			/>
			<UserForms />
		</div>
	);
};
