import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Icon from 'rds/components/Icon';
import { getShowNavigationsBar } from 'app/slices/app-navigations/navigations';
import Navigation from 'components/Navigation/Menu';

const useStyles = makeStyles({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default () => {
	const classes = useStyles();
	const showNavBar = useSelector(getShowNavigationsBar);

	return (
		<>
			<div className={classes.root}>
				<div>
					<Icon
						icon="Rhino"
						containerHeight={96}
						containerWidth={96}
						height={43}
						width={64}
					/>
				</div>
				<Typography variant="subtitle1">No user found.</Typography>
			</div>
			{showNavBar
      && <Navigation />}
		</>
	);
};
