import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: '0px 2px 8px rgba(27, 36, 45, 0.24)',
		backgroundColor: theme.colors.neutral.C000,
		borderRadius: 2,
		border: `1px solid ${theme.colors.neutral.C000}`,
		color: theme.colors.neutral.C900,
		height: 40,
		textAlign: 'center',
		position: 'relative',
		marginBottom: theme.spacing(3),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(1.5),
		marginLeft: theme.spacing(1.5),
	},
	icon: {
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		width: 20,
		height: 20,
		marginRight: theme.spacing(2),
	},
	disabled: {
		backgroundColor: theme.colors.neutral.C200,
		border: `1px solid ${theme.colors.neutral.C200}`,
		color: theme.colors.neutral.C500,
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default ({
	icon,
	children,
	disabled,
	variant,
	...rest
}) => {
	const classes = useStyles();

	const rootClass = disabled ? clsx(classes.root, classes.disabled) : classes.root;
	const v = variant || 'subtitle2';
	return (
		<div className={rootClass} {...rest}>
			<div className={classes.content}>
				{icon && <div className={classes.icon} style={{ backgroundImage: `url(${icon})` }} />}
				<Typography variant={v}>
					{children}
				</Typography>
			</div>
		</div>
	);
};
