import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';
import { addCollections, removeCollections, selectCollections } from 'app/slices/content-creation/experience';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		whiteSpace: 'nowrap',
	},
	button: {
		marginRight: theme.spacing(3),
	},
	checked: {
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
			color: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		},
	},
	unChecked: {
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C800 : theme.colors.neutral.C000,
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C200 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C800 : theme.colors.neutral.C000,
			color: theme.palette.type === 'dark' ? theme.colors.neutral.C200 : theme.colors.neutral.C900,
		},
	},

}));

export default ({
	group,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const collections = useSelector(selectCollections);

	const selected = collections.map((r) => r.title);

	const handleSelect = () => {
		if (!selected.includes(group.title)) {
			dispatch(addCollections(group));
		} else {
			dispatch(removeCollections(group));
		}
	};
	const klass = selected.includes(group.title) ? classes.checked : classes.unChecked;

	return (
		<div
			className={classes.root}
		>
			<Button
				variant="contained"
				color="secondary"
				disableRipple
				disableFocusRipple
				className={clsx(classes.button, klass)}
				fullWidth
				onClick={handleSelect}
			>
				<Typography variant="subtitle2">{group.title}</Typography>
			</Button>

		</div>
	);
};
