const buttons = [
	{ title: 'Spam', enum: 'spam' },
	{ title: 'Nudity', enum: 'nudity' },
	{ title: 'Harassment', enum: 'harassment' },
	{ title: 'Copyright / Intellectual Property', enum: 'copyright_ip' },
	{ title: 'Hate Speech', enum: 'hate_speech' },
	{ title: 'Self-Harm / Violence', enum: 'self_harm' },
	{ title: 'Fake / Impersonation', enum: 'fake_impersonation' },
	{ title: 'Other', enum: 'other' }];

export default buttons;
