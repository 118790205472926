import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { selectAlbum } from 'app/slices/content-creation/create/device-library/albums';
import { closeAlbums } from 'app/slices/content-creation/create/device-library/state';
import { getThumbnailPath } from 'app/android-service';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		'&:hover': {
			background: theme.colors.neutral.C800,
		},
	},
	left: {
		position: 'relative',
		margin: theme.spacing(4),
		width: 59,
		height: 59,
	},
	right: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	count: {
		marginTop: theme.spacing(),
		color: theme.colors.neutral.C200,
	},
	thumbnail: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		borderRadius: 2,
		objectFit: 'cover',
	},
}));

export default ({ album }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<div
			className={classes.root}
			onClick={() => {
				dispatch(selectAlbum(album));
				dispatch(closeAlbums());
			}}
		>
			<div className={classes.left}>
				<div
					className={classes.thumbnail}
					style={{
						backgroundImage: `url(${ getThumbnailPath(album.preview) })`,
					}}
				/>
			</div>
			<div className={classes.right}>
				<Typography className={classes.name} variant="subtitle2">{album.name}</Typography>
				<Typography className={classes.count} variant="body2">{album.count}</Typography>
			</div>
		</div>
	);
};
