import { createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import {
	requestProfile,
	requestChangeAvatar,
	requestChangeName,
	requestChangeBio,
	requestCheckIsUserBlocked,
	requestBlockUser,
	requestUnBlockUser,
	requestSuggestedIdentifiers,
	requestPatchIdentifiers,
	requestUpdateIdentifiers,
} from './thunks';
import { updateAccount } from '../utils';

const initialState = {
	accounts: {},
	myAccount: null,
	currentUsername: null,
	suggestedIdentifiers: [],
	loading: false,
	loadingSuggestedIdentifiers: false,
	errors: null,
	notFound: false,
};

const name = 'userAccountV2Accounts';

const userAccountSlice = createSlice({
	name,
	initialState,
	reducers: {
		change: (state, action) => {
			Object.assign(state, action.payload);
		},
		reset: () => initialState,
		updateFollowStatus: (state, action) => {
			const { key, amount } = action.payload;
			if (state.myAccount) {
				state.myAccount.stats.followingTotal.robot += amount;
			}

			const account = state.accounts[state.currentUsername];
			if (account) {
				account.stats[key].robot += amount;
				account.following = amount > 0;
			}
		},
		updateUsername: (state, action) => {
			const { username } = action.payload;

			if (state.myAccount) {
				state.myAccount.username = username;
			}
		},
		setCurrentUsername: (state, action) => {
			state.currentUsername = action.payload;
		},
		setIdentifiers: (state, action) => {
			const { identifiers, customIdentifiers } = action.payload;

			if (state.myAccount) {
				state.myAccount.identifiers = identifiers;
				state.myAccount.customIdentifiers = customIdentifiers;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestProfile.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(requestProfile.fulfilled, (state, action) => {
				const {
					avatar,
					displayName,
					interactions,
					stats,
					username,
					bio,
					pendingInteractions,
					identifiers,
					customIdentifiers,
					uuid,
					myAccount,
				} = action.payload;

				if (myAccount) {
					state.myAccount = {
						username,
						name: displayName,
						bio,
						stats,
						pendingInteractions,
						customIdentifiers: customIdentifiers || [],
						identifiers: identifiers || [],
						following: interactions?.callerIsFollowing || false,
						interactions,
						avatar,
						uuid,
					};
				} else {
					updateAccount(state, username, {
						username,
						name: displayName,
						bio,
						stats,
						pendingInteractions,
						customIdentifiers: customIdentifiers || [],
						identifiers: identifiers || [],
						following: interactions?.callerIsFollowing || false,
						interactions,
						avatar,
						uuid,
					});
					state.currentUsername = username;
				}

				state.loading = false;
			})
			.addCase(requestProfile.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload || null;
				state.notFound = !action.payload;
			})
			.addCase(requestChangeAvatar.fulfilled, (state, action) => {
				if (state.myAccount) {
					state.myAccount.avatar = action.payload.avatar;
				}
			})
			.addCase(requestChangeBio.pending, (state, action) => {
				if (state.myAccount) {
					state.myAccount.bio = action.meta.arg;
				}
			})
			.addCase(requestChangeName.pending, (state, action) => {
				if (state.myAccount) {
					state.myAccount.name = action.meta.arg;
				}
			})
			.addCase(requestCheckIsUserBlocked.pending, (state) => {
				state.loading = true;
			})
			.addCase(requestCheckIsUserBlocked.fulfilled, (state, action) => {
				if (action.payload) {
					state.accounts[state.currentUsername].interactions.callerIsBlocking = action.payload.blocking;
				}
				state.loading = false;
			})
			.addCase(requestBlockUser.pending, (state) => {
				state.accounts[state.currentUsername].interactions.callerIsBlocking = true;
				state.accounts[state.currentUsername].following = false;
			})
			.addCase(requestUnBlockUser.pending, (state) => {
				state.accounts[state.currentUsername].interactions.callerIsBlocking = false;
			})
			.addCase(requestSuggestedIdentifiers.pending, (state) => {
				state.loadingSuggestedIdentifiers = true;
			})
			.addCase(requestSuggestedIdentifiers.fulfilled, (state, action) => {
				const identifiers = [...action.payload.identifiers];
				const sorted = identifiers.sort((a, b) => a.slug.localeCompare(b.slug));
				state.suggestedIdentifiers = sorted;
				state.loadingSuggestedIdentifiers = false;
			})
			.addCase(requestSuggestedIdentifiers.rejected, (state) => {
				state.loadingSuggestedIdentifiers = false;
			})
			.addCase(requestPatchIdentifiers.fulfilled, (state, action) => {
				const { newIdentifiers, newCustomIdentifiers } = action.payload;

				if (state.myAccount) {
					state.myAccount.identifiers = newIdentifiers;
					state.myAccount.customIdentifiers = newCustomIdentifiers;
				}
			})
			.addCase(requestPatchIdentifiers.rejected, (state, action) => {
				const { identifiers, customIdentifiers } = action.payload;

				if (state.myAccount) {
					state.myAccount.identifiers = identifiers;
					state.myAccount.customIdentifiers = customIdentifiers;
				}
			})
			.addCase(requestUpdateIdentifiers.fulfilled, (state, action) => {
				const { customIdentifiers, identifiers } = action.payload;

				if (state.myAccount) {
					state.myAccount.customIdentifiers = customIdentifiers;
					state.myAccount.identifiers = identifiers;
				}
			});
	},
});

export const {
	change,
	reset,
	updateFollowStatus,
	updateUsername,
	setCurrentUsername,
	setIdentifiers,
} = userAccountSlice.actions;

injectReducer(name, userAccountSlice.reducer);

export default userAccountSlice.reducer;
