import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import { fetchPermissions, getPort } from 'app/android-service';

const initialState = {
	errors: null,
	disableCancel: false,
	loading: false,
	percentage: 0,
	albumsOpen: false,
};

export const requestPermissions = createAsyncThunk(
	'requestPermissions',
	async () => {
		try {
			const proceed = await fetchPermissions();
			return proceed;
		} catch (error) {
			return false;
		}
	},
);

export const isAvailable = () => !Number.isNaN(getPort());

const dlSlice = createSlice({
	name: 'deviceLibraryState',
	initialState: {
		enabled: undefined,
		createNavigation: {
			open: true,
			slide: true,
		},
		gateway: false,
		...initialState,
	},
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		openAlbums: (state) => {
			state.albumsOpen = true;
		},
		closeAlbums: (state) => {
			state.albumsOpen = false;
		},
		disableGateway: (state) => {
			state.gateway = false;
		},
		enableGateway: (state) => {
			state.gateway = true;
		},
		enableCancel: (state) => {
			state.disableCancel = false;
		},
		clearPercentage: (state) => {
			state.percentage = 0;
		},
		updatePercentage: (state, action) => {
			if (action.payload > state.percentage) {
				state.percentage = action.payload;
			}
		},
		disableCancel: (state) => {
			state.disableCancel = true;
		},
		setCreateNavigation: (state, action) => {
			state.createNavigation = action.payload;
		},
	},
	extraReducers: {
		[requestPermissions.pending]: (state) => {
			state.errors = null;
			state.loading = true;
		},
		[requestPermissions.rejected]: (state, action) => {
			state.enabled = undefined;
			state.errors = action.payload;
			state.loading = true;
		},
		[requestPermissions.fulfilled]: (state, action) => {
			state.loading = false;
			state.errors = null;
			state.enabled = action.payload;
		},
	},
});

const { name, reducer, actions } = dlSlice;
const {
	reset,
	openAlbums,
	closeAlbums,
	disableCancel,
	enableCancel,
	clearPercentage,
	updatePercentage,
	disableGateway,
	enableGateway,
	setCreateNavigation,
} = actions;

export {
	reset,
	openAlbums,
	closeAlbums,
	disableCancel,
	enableCancel,
	clearPercentage,
	updatePercentage,
	disableGateway,
	enableGateway,
	setCreateNavigation,
};

export const getSlice = (state) => state[name];
export const getAlbumsOpen = createSelector(getSlice, (slice) => slice?.albumsOpen);
export const getDisableCancel = createSelector(getSlice, (slice) => slice?.disableCancel);
export const getEnabled = createSelector(getSlice, (slice) => slice?.enabled);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getPercentage = createSelector(getSlice, (slice) => slice?.percentage);
export const getGateway = createSelector(getSlice, (slice) => slice?.gateway);
export const getCreateNavigation = createSelector(getSlice, (slice) => slice?.createNavigation);

injectReducer(name, reducer);
