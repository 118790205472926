import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import ecCompose from 'utils/editing-canvas-compose';
import { addClipText, CLIPS_THRESHOLD, selectClipCount } from 'app/slices/content-creation/experience';
import { COMMON_STYLES } from 'routes/ContentCreation/Create/TextComponent/styles';
import neutral from 'rds/colors/neutral';
import { reset as resetTextClip } from 'app/slices/content-creation/create/text-component';
import createElement from 'utils/create-element';

export const editingCanvasMap = {};
let locked = false;

export const createEditingCanvas = createAsyncThunk(
	'createEditingCanvas',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { editingcanvas, textComponent } = getState();
			const { background } = textComponent;
			if (editingcanvas.editingCanvasId || locked) {
				throw new Error('EC already exists');
			}

			locked = true;

			const ec = await ecCompose({});

			const { height, width } = ec.dimensions;

			if (Object.keys(background).length !== 0) {
				const root = await ec.add('image', {
					src: background.image,
					datasrc: background.image,
				}, {
					top: 0,
					left: 0,
					height: `${height}px`,
					width: `${width}px`,
				});
				root.makeBackground();
				root.makeRoot();

				root.lock();
			}

			const div = createElement({
				tagName: 'div',
				attributes: {
					contenteditable: 'true',
					autocomplete: 'off',
					placeholder: 'Tap to type text',
					class: 'tap',
				},
			});

			const styles = {
				...COMMON_STYLES,
				'font-family': 'Standard',
				'font-size': '32px',
				top: `${(window.innerHeight * 0.4)}px`,
			};
			Object.keys(styles).forEach((key) => {
				div.style[key] = styles[key];
			});

			const tapToType = await ec.add('html', {
				el: div,
			});

			ec.tapToType = tapToType;
			ec.tapToType.lock();

			const overlay = await ec.add('html', {
				tagName: 'div',
			}, {
				background: `${neutral.C900}70`,
				opacity: 0.489999,
				width: '100%',
				height: '100%',
				display: 'none',
			});
			ec.overlay = overlay;
			editingCanvasMap[ec.store.ID_SERIAL] = ec;
			locked = false;

			return ec.store.ID_SERIAL;
		} catch (err) {
			return rejectWithValue(err);
		}
	},
);

export const updateRootNode = createAsyncThunk(
	'updateRootNode',
	async ({ src }, { getState }) => {
		const state = getState();

		const editingCanvas = editingCanvasMap[state.editingcanvas.editingCanvasId];

		if (editingCanvas.rootLock) {
			return;
		}

		editingCanvas.rootLock = true;
		if (editingCanvas.root) {
			await editingCanvas.update(editingCanvas.root, {
				src,
				datasrc: src,
			});
			editingCanvas.rootNode.src = src;
		} else {
			const { height, width } = editingCanvas.dimensions;
			const root = await editingCanvas.add('image', {
				src,
				datasrc: src,
			}, {
				top: 0,
				left: 0,
				height: `${height}px`,
				width: `${width}px`,
			});

			root.makeRoot();
			root.makeBackground();
			root.lock();
		}
		editingCanvas.rootLock = false;
	},
);

export const addTextClip = createAsyncThunk(
	'addTextClip',
	async (_, { dispatch, getState }) => {
		const state = getState();

		const clipCount = selectClipCount(state);
		if (clipCount >= CLIPS_THRESHOLD) {
			return false;
		}

		const editingCanvas = editingCanvasMap[state.editingcanvas.editingCanvasId];

		const ttp = editingCanvas.find(editingCanvas.tapToType.item.id);

		if (ttp.textContent.trim().length <= 0) {
			return false;
		}
		editingCanvas.tapToType.unlock();
		await dispatch(addClipText({
			container: editingCanvas.container,
			editingCanvas,
		}));
		dispatch(resetTextClip(['background']));

		return true;
	},
);

export const editingCanvasSlice = createSlice({
	name: 'editingcanvas',
	initialState: {
		editingCanvasId: null,
		errors: null,
		hasReset: false,
	},
	reducers: {
		// clearAll: () => {},

		reset: (state) => {
			state.editingCanvasId = null;
			state.errors = null;

			// const props = Object.getOwnPropertyNames(editingCanvasMap);
			// for (let i = 0; i < props.length; i++) {
			//	 delete editingCanvasMap[props[i]];
			// }

			state.hasReset = true;
		},

	},
	extraReducers: {
		[createEditingCanvas.fulfilled]: (state, action) => {
			if (action.payload) {
				state.editingCanvasId = action.payload;
			}
			state.hasReset = false;
		},
		[createEditingCanvas.rejected]: (state, action) => {
			state.errors = action.payload;
		},
		[addTextClip.fulfilled]: (state, action) => {
			if (action.payload) {
				state.editingCanvasId = null;
				state.errors = null;
				state.hasReset = true;
			}
		},
	},
});

const { name, reducer, actions } = editingCanvasSlice;
const { reset } = actions;
export { reset };
export const getSlice = (state) => state[name];
export const selectEditingCanvas = createSelector(getSlice, (slice) => editingCanvasMap[slice?.editingCanvasId]);

injectReducer(name, reducer);
