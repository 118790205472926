import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	selectEditingNode,
} from 'app/slices/content-creation/create/text-component';
import { getConstants } from 'app/slices/app/app';
import DrawerPicker from 'rds/components/DrawerPicker/DrawerPicker';
import { selectEditingCanvas } from 'app/slices/content-creation/editing-canvas';
import PickerLayout from './PickerLayout';
import mentionsCss from './mentions-css';

const OFFSET = 58;

const useStyles = makeStyles({
	container: {
		position: 'relative',
		width: '100%',
	},

});

export default ({ editingCanvas }) => {
	const classes = useStyles();
	const ref = useRef();
	const constants = useSelector(getConstants);
	const editingNode = useSelector(selectEditingNode);
	editingCanvas = editingCanvas || useSelector(selectEditingCanvas);
	const { textColors } = constants;
	const getItems = () => {
		const myArray = [];
		textColors.forEach((g) => {
			g.items.forEach((k) => {
				myArray.push(k);
			});
		});
		return myArray;
	};

	useEffect(() => {
		const textColorsArray = getItems();
		ref.current.picker = new DrawerPicker(ref.current, textColorsArray, 'textColor', OFFSET);
		ref.current.picker.on('selected', (item) => {
			const { el, id } = editingNode;
			if (item?.hex) {
				const color = `#${item.hex}`;
				mentionsCss(el, color);
				el.style.color = color;
				el.setAttribute('color-uid', item.uid);
				editingCanvas.updateStyle(id, 'color', `#${item.hex}`);
			}
		});

		const colorUid = editingNode.el.getAttribute('color-uid');
		ref.current.picker.selectItem(colorUid, true);

		return () => {
			if (ref.current) {
				ref.current.picker.destroy();
				ref.current.picker = null;
			}
		};
	}, [ref, constants, editingNode]);

	if (!constants) {
		return null;
	}

	return (
		<>
			<PickerLayout>
				<div ref={ref} className={classes.container} />
			</PickerLayout>
		</>
	);
};
