import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	getConversationEuid,
	getListOfComments,
	requestExperienceComments,
	reset,
} from 'app/slices/comments';
import WaveSkeleton from 'components/WaveSkeleton';
import Comments from '../Comments';

const useStyles = makeStyles({
	loadingContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '30%',
	},
});

export default ({
	context,
	uid,
	...props
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const listOfComments = useSelector(getListOfComments);
	const experienceUid = useSelector(getConversationEuid);
	const { comment } = context;

	useEffect(() => {
		if (uid) {
			dispatch(requestExperienceComments({
				uid,
				comment,
			}));
		} else if (!uid) {
			dispatch(reset());
		}
	}, [uid]);

	if (!listOfComments || uid !== experienceUid) {
		return (
			<div className={classes.loadingContainer}>
				<WaveSkeleton
					style={{
						height: 24,
						width: 188,
					}}
				/>
			</div>
		);
	}

	return (
		<Comments find={comment} context={context} {...props} />
	);
};
