import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getEditProfileModal } from 'app/slices/user-account-v2/layout/selectors';
import SliderModal from 'rds/components/SliderModal';
import { useNavbarState } from 'app/hooks/useNavbarState';
import Contents from './Contents';
import Header from './Header';

export default () => {
	const open = useSelector(getEditProfileModal);
	const navbarState = useNavbarState();

	useEffect(() => {
		if (open) {
			navbarState.close('high-priority');
		} else {
			navbarState.open('high-priority');
		}
	}, [open]);

	return (
		<SliderModal open={open}>
			<Header />
			<Contents />
		</SliderModal>
	);
};
