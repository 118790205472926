import React, { useState, useEffect } from 'react';
import ProgressiveThumbnail from 'rds/components/ProgressiveThumbnail/ProgressiveThumbnail';
import previewEmitter from 'app/slices/user-account-v2/preview-emitter';

export default ({
	thumbnail,
	experienceUid,
}) => {
	const [previewPic, setPreviewPic] = useState(thumbnail);

	useEffect(() => {
		const eventName = `update-${experienceUid}`;
		const handleThumbnailEvent = (url) => {
			setPreviewPic(url);
		};
		previewEmitter.on(eventName, handleThumbnailEvent);
		return () => {
			previewEmitter.removeListener(eventName, handleThumbnailEvent);
		};
	}, []);

	return (
		<ProgressiveThumbnail src={previewPic} animation="wave" style={{ borderRadius: '2px' }} />
	);
};
