import React, { useRef, useCallback, useEffect } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import api, { CancelToken } from 'services/api';
import { username as usernameRE } from 'utils/regexes';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import TextField from 'rds/components/TextField';
import Form from 'rds/components/Layouts/Form';
import Actions from 'rds/components/Actions';
import { clearMissingFields } from 'app/slices/authentication/session/session';
import { useNavigate } from 'react-router-dom';
import PasswordField from '../components/fields/PasswordField';
import DatePickerField from '../components/fields/DatePickerField';
import SubmitButton from '../components/SubmitButton/SubmitButton';

const setupWait = (ms) => {
	let to;

	const promise = new Promise((resolve) => {
		to = setTimeout(() => {
			resolve();
		}, ms);
	});

	return {
		promise,
		clearTimeout: () => { clearTimeout(to); },
	};
};

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'block',
		textAlign: 'center',
		marginTop: theme.spacing(),
		color: theme.colors.neutral.C000,
		textShadow: '0px 2px 8px rgba(35, 47, 46, 0.5)',
	},
	link: {
		...theme.typography.link,
		fontSize: 12,
		fontWeight: 700,
	},
}));

export default ({
	broken,
	disabledCheck,
	disablePassword,
	profile,
	formSlice,
	apiSlice,
	setUpLaterButton,
	theme,
}) => {
	const {
		control,
		resetForm,
		setErrors,
	} = formSlice;

	const { getLoading, requestUpdate } = apiSlice;

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const asyncValidatorRef = useRef();

	const fields = control.get(dispatch);
	const [email, emailErr, emailTmpErr] = fields.email.values;
	const [username, usernameErr, usernameTmpErr] = fields.username.values;
	const [password, passwordErr, passwordTmpErr] = fields.password.values;
	const dateOfBirth = useSelector(fields.dateOfBirth.selector);
	const loading = useSelector(getLoading);

	const onSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			if (loading || broken) {
				return;
			}
			const response = await dispatch(requestUpdate());
			if (response?.payload?.token) {
				navigate('/');
			}
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(resetForm());
	}, [dispatch]);

	const disabled = disabledCheck({
		email,
		emailTmpErr,
		username,
		usernameTmpErr,
		password,
		passwordTmpErr,
		dateOfBirth,
	});

	const asyncValidator = profile?.username
		? null
		: async () => {
			if (usernameErr) {
				return false;
			}

			if (asyncValidatorRef.current) {
				asyncValidatorRef.current.cancel();
			}

			const source = CancelToken.source();

			const wait = setupWait(250);
			asyncValidatorRef.current = {
				cancel: () => {
					source.cancel();
					wait.clearTimeout();
				},
			};

			await wait.promise;
			try {
				await api.post('accounts/profile/validate', {
					username,
				}, {
					cancelToken: source.token,
				});
				return true;
			} catch (error) {
				if (error.__CANCEL__) {
					return false;
				}
				const errors = getErrorsFromRejectedRequest(error);
				if (!usernameErr) {
					dispatch(setErrors(errors));
				}
				return false;
			}
		};

	return (
		<Form onSubmit={onSubmit}>
			<TextField
				theme={theme}
				inputProps={{
					autoComplete: 'none',
				}}
				value={profile?.username
					? profile.username.toLowerCase()
					: username.toLowerCase()}
				err={usernameErr}
				tmpErr={usernameTmpErr}
				onChange={fields.username.change}
				pattern={usernameRE}
				flashSuccess
				disabled={!!profile?.username}
				name="username"
				variant="glass"
				maxLength="30"
				minLength="2"
				autoComplete="username"
				placeholder="Create a username"
				fullWidth
				formItem
				icon={{
					icon: 'Avatar',
					size: 20,
				}}
				asyncValidator={asyncValidator}
			/>

			<TextField
				theme={theme}
				value={profile?.email ? profile.email.toLowerCase() : email.toLowerCase()}
				err={emailErr}
				tmpErr={emailTmpErr}
				variant="glass"
				inputProps={{
					autoComplete: 'none',
				}}
				onChange={fields.email.change}
				disabled={!!profile?.email}
				type="email"
				autoComplete="email"
				formItem
				icon={{
					icon: 'Email',
					size: 20,
				}}
				placeholder="Enter email address"
				fullWidth
			/>

			{
				disablePassword
					? null
					: (
						<PasswordField
							theme={theme}
							placeholder="Create password (min. 12)"
							err={passwordErr}
							variant="glass"
							tmpErr={passwordTmpErr}
							onChange={fields.password.change}
							value={password}
						/>
					)
			}
			<DatePickerField
				theme={theme}
				value={profile?.dateOfBirth ? dayjs(profile?.dateOfBirth) : dateOfBirth ? dayjs(dateOfBirth) : null}
				disabled={!!profile?.dateOfBirth}
				onChange={(v) => {
					fields.dateOfBirth.change({
						target: { value: v.format('YYYY-MM-DD') },
					});
				}}
			/>

			<Actions>
				<Typography variant="body2" className={classes.content}>
					This platform and community has been created to build inclusion and celebrate our differences as strengths. By continuing, you agree to support this initiative, as well as our
					{' '}
					<span className={classes.link} onClick={() => { navigate('/terms'); }}>Terms of Use</span>
					{' '}
					and
					{' '}
					<span className={classes.link} onClick={() => { navigate('/terms#privacy-policy'); }}>Privacy Policy</span>
					.
				</Typography>
			</Actions>

			<SubmitButton
				loading={loading}
				onMouseDown={(e) => { e.preventDefault(); }}
				disabled={disabled}
			>
				Sign up
			</SubmitButton>
			{setUpLaterButton && (
				<Button
					disableRipple
					disableFocusRipple
					size="small"
					onClick={() => {
						dispatch(clearMissingFields());
					}}
				>
					Set up later
				</Button>
			)}
		</Form>

	);
};
