import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMatch } from 'utils/look';
import textFit from 'utils/text-fit';
import {
	close,
	removeNode,
	set,
	selectEditingNode,
	selectRecommendedValue,
	selectRecommendationTriggers,
} from 'app/slices/content-creation/create/text-component';
import { selectEditingCanvas } from 'app/slices/content-creation/editing-canvas';
import { toggleControls } from 'app/slices/content-creation/experience';
import Composer from 'lib/composer';
// import { getRegions } from 'utils/editing-canvas-compile';
import { FOCUS_STYLES } from './styles';

const TRANSITION_TIMER = 500;
const TIME_OUT = 100;
// const TRANSITION = 'left 0.5s, top 0.5s';
const IGNORE_STYLES = ['transition', 'height', 'width', 'color'];

const getWidthOfInput = (el) => {
	const temporaryElement = el.cloneNode(true);
	temporaryElement.style.position = 'relative';
	temporaryElement.style.display = 'inline-block';
	temporaryElement.style.removeProperty('width');
	temporaryElement.style.visibility = 'hidden';
	el.parentElement.appendChild(temporaryElement);
	const { width, height } = temporaryElement.getBoundingClientRect();

	try {
		el.parentElement.removeChild(temporaryElement);
	} catch (_) {
		// Safe to ignore error
	}

	return { width: Math.ceil(width), height: Math.ceil(height) };
};

export default ({
	editingCanvas,
}) => {
	const dispatch = useDispatch();
	editingCanvas = editingCanvas || useSelector(selectEditingCanvas);
	const node = useSelector(selectEditingNode);
	const recommendationTriggers = useSelector(selectRecommendationTriggers);
	const recommendedValue = useSelector(selectRecommendedValue);
	const containerRef = useRef();

	useEffect(async () => {
		if (node) {
			const { el, id } = node;
			let closed = false;

			if (!el.composer) {
				el.composer = new Composer(el);
			}

			clearTimeout(editingCanvas.backgroundTo);
			clearTimeout(editingCanvas.closeTo);

			dispatch(toggleControls({ showControls: false }));
			dispatch(set({ showBackgroundPicker: false }));

			const localStyles = {};

			Object.keys(FOCUS_STYLES).forEach((key) => {
				if (IGNORE_STYLES.indexOf(key) === -1) {
					localStyles[key] = el.style[key];
				}
				el.style[key] = FOCUS_STYLES[key];
			});

			const removeTextFit = textFit(el, {
				fontSize: 32,
				maxHeight: () => window.innerHeight - 72,
			});

			el.removeAttribute('disabled');

			editingCanvas.disableGestures();
			const tapToType = await editingCanvas.find(editingCanvas.tapToType.item.id);
			if (tapToType && tapToType !== el && !tapToType.textContent.trim()) {
				tapToType.style.visibility = 'hidden';
			}

			if (tapToType) {
				tapToType.onfocus = () => {
					tapToType.style.visibility = 'visible';
					tapToType.setAttribute('placeHolder', '');
				};
			}

			el.focus();
			const onResize = () => {
				el.style.transition = '0.1s top linear';
				el.triggerTextFit();
				setTimeout(() => {
					el.style.removeProperty('transition');
				}, 100);
				window.visualViewport.removeEventListener('resize', onResize);
			};
			window.visualViewport.addEventListener('resize', onResize);

			const overlay = await editingCanvas.find(editingCanvas.overlay.item.id);
			overlay.style.display = 'block';
			overlay.style.zIndex = editingCanvas.store.Z_INDEX_SERIAL - 1;
			overlay.ontouchend = () => {
				closeIt();
			};

			editingCanvas.container.ontouchend = (e) => {
				if (e.target !== el) {
					closeIt();
				}
			};

			el.onkeydown = (e) => {
				if (e.which === 13) {
					e.preventDefault();
					closeIt();
				}
			};

			el.onkeyup = () => {
				const match = getMatch(
					el.textContent,
					el.composer.cursor.start.totalOffset - 1,
					recommendationTriggers,
					/\s/,
				);

				dispatch(set({
					match: match || null,
				}));
			};

			const closeIt = (e) => {
				if (closed) {
					return;
				}

				closed = true;
				el.onblur = null;
				if (!e) {
					el.blur();
				}
				dispatch(close());

				removeTextFit();
				overlay.style.display = 'none';

				if (tapToType) {
					tapToType.style.visibility = 'visible';
				}
				el.innerHTML = el.innerHTML.trim();
				const trimmedContents = el.textContent.trim();

				if (trimmedContents) {
					const newWidth = getWidthOfInput(el).width;
					el.style.width = `${newWidth}px`;
				}

				editingCanvas.enableGestures();
				el.onfocus = null;
				el.onkeydown = null;
				editingCanvas.container.ontouchend = null;

				if (!trimmedContents && el !== tapToType) {
					editingCanvas.remove(id);
					dispatch(removeNode({ id }));
					return;
				}

				if (tapToType && tapToType !== el && !tapToType.textContent.trim()) {
					tapToType.remove();
				} else {
					editingCanvas.tapToType.unlock();
				}

				const specialNodes = el.composer ? el.composer.specialNodes : undefined;
				editingCanvas.update(id, { el, specialNodes });

				// el.style.transition = TRANSITION;
				const { width, height } = getWidthOfInput(el);
				if (tapToType === el && !trimmedContents) {
					tapToType.setAttribute('placeHolder', 'Tap to type text');
					editingCanvas.tapToType.lock();
				} else if (tapToType === el && !!trimmedContents && !localStyles.left) {
					localStyles.left = `calc(50% - ${width / 2}px`;
				} else if (tapToType === el && !!trimmedContents && !localStyles.top) {
					tapToType.style.top = `calc(50% - ${height / 2}px`;
				}
				Object.keys(localStyles).forEach((key) => {
					if (key === 'z-index' || key === 'height' || key === 'width') {
						return;
					}
					el.style[key] = localStyles[key];
				});

				editingCanvas.backgroundTo = setTimeout(() => {
					dispatch(toggleControls({ showControls: true }));
					dispatch(set({ showBackgroundPicker: true }));
				}, TIME_OUT);

				editingCanvas.closeTo = setTimeout(() => {
					el.style.removeProperty('transition');
					el.style['z-index'] = localStyles['z-index'];
					el.setAttribute('disabled', true);
					window.ec = editingCanvas;
				}, TRANSITION_TIMER);
			};

			const onblur = closeIt;

			el.onblur = onblur;
		}
	}, [node, containerRef.current]);

	useEffect(() => {
		if (!recommendedValue || !node) {
			return;
		}

		const { el } = node;
		if (!el.composer) {
			el.composer = new Composer(el);
		}

		const { start, end } = recommendedValue.match.position;
		let value = el.textContent[end] !== ' ' ? recommendedValue.value : recommendedValue.value;

		const trailing = el.textContent.slice(end);
		if (!trailing.length) {
			value += ' ';
		} else if (trailing[0] !== ' ') {
			value += ' ';
		}

		const str = `${el.textContent.slice(0, start)}${value}${trailing}`;

		if (el.composer) {
			el.composer.string = str;
			el.composer.addNode({
				type: 'ccMention',
				contents: `${recommendedValue.match.prefix}${recommendedValue.value}`,
				data: { uuid: recommendedValue.uuid },
			});

			el.composer.placeCursorPosition(start + value.length);

			// debugging tools
			// window.getRegions = () => getRegions(editingCanvas, true);
		}
		el.triggerTextFit();
		dispatch(set({
			recommendedValue: null,
			match: null,
		}));
	}, [node, recommendedValue]);

	return (
		<div />
	);
};
