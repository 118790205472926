import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { requestPatchRegion } from 'app/slices/onboarding/regions';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		whiteSpace: 'nowrap',
		overflowX: 'hidden',
		paddingTop: theme.spacing(),
		paddingBottom: theme.spacing(),
	},
	button: {
		paddingTop: theme.spacing(),
		paddingBottom: theme.spacing(),
	},
	checked: {
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C800,
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C800 : theme.colors.neutral.C000,
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C800,
			color: theme.palette.type === 'dark' ? theme.colors.neutral.C800 : theme.colors.neutral.C000,
		},
	},
	unChecked: {
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C800 : theme.colors.neutral.C000,
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C800,
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C800 : theme.colors.neutral.C000,
			color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C800,
		},
	},

}));

export default ({
	checked,
	name,
	uid,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const klass = checked ? classes.checked : classes.unChecked;
	return (
		<div
			className={classes.root}
		>
			<Button
				variant="contained"
				color="primary"
				disableRipple
				className={clsx(classes.button, klass)}
				fullWidth
				onClick={() => {
					dispatch(requestPatchRegion({ uid, remove: !!checked }));
				}}
			>
				<Typography variant="body2">{name}</Typography>
			</Button>

		</div>
	);
};
