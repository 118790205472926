import React from 'react';
import { registerRoute } from 'Router/registry';
import { Navigate } from 'react-router-dom';

const registerRedirects = (redirects) => {
	redirects.forEach(([path, to]) => {
		const Component = () => <Navigate to={to} replace />;

		registerRoute({
			Component,
			routeOptions: { path },
		});
	});
};

export default registerRedirects;
