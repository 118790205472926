import {
	getUserSavedExperiences,
	update,
	getLoading,
	requestUserSavedExperiences,
	requestLoadMoreSavedExperiences,
	getHasMoreToLoad,
} from './index';

export default {
	selector: getUserSavedExperiences,
	requestExperiences: requestUserSavedExperiences,
	requestMore: requestLoadMoreSavedExperiences,
	loadingSelector: getLoading,
	hasMoreToLoad: getHasMoreToLoad,
	update,
};
