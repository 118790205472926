import store from 'store';

const getParam = (key) => {
	const params = new URLSearchParams(window.location.search);
	return params.get(key);
};

function removeParams(url, keys) {
	const urlArray = url.split('?');
	const baseUrl = urlArray[0];
	const queryString = urlArray[1];

	if (!queryString) {
		return baseUrl;
	}
	const searchParams = new URLSearchParams(queryString);

	keys.forEach((k) => {
		searchParams.delete(k);
	});

	const newQueryString = searchParams.toString();
	return `${baseUrl}?${newQueryString}`;
}

export const init = () => {
	const port = parseInt(getParam('port'), 10);
	const autoRejectPermissions = getParam('auto-reject-permissions');
	const showPermissionsRationale = getParam('show-permissions-rationale');
	const zeroState = getParam('zero-state');

	if (!Number.isNaN(port)) {
		store.set('android-port', port);
	}

	if (autoRejectPermissions) {
		store.set('auto-permissions', autoRejectPermissions !== 'on');
	}

	if (showPermissionsRationale) {
		store.set('should-show-permissions-rationale', showPermissionsRationale);
	}

	if (zeroState) {
		store.set('zero-state', zeroState === 'on');
	}

	const cleanUrl = removeParams(window.location.href, ['port', 'auto-reject-permissions', 'show-permissions-rationale', 'zero-state']);
	window.history.pushState({}, '', cleanUrl);
};

const getAndroidPort = () => {
	const p = store.get('android-port');
	return parseInt(p, 10) || null;
};

export const clearPort = () => {
	store.remove('android-port');
};

export const getAutoPermissions = () => store.get('auto-permissions');

export const getZeroState = () => store.get('zero-state');

export const getShouldShowPermissionsRationale = () => store.get('should-show-permissions-rationale') === 'on';

export const clearShowPermissionsRationale = () => { store.set('should-show-permissions-rationale', 'off'); };

export default getAndroidPort;
