import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import ProgressIndicator from 'rds/components/ProgressIndicator/ProgressIndicator';
import { getSelected as getSelectedAlbum } from 'app/slices/content-creation/create/device-library/albums';
import {
	getTotalClipCount,
	processMedia,
	getProcessing,
	getSelected,
} from 'app/slices/content-creation/create/device-library/media';
import {
	openAlbums,
	closeAlbums,
	getAlbumsOpen,
	getEnabled,
} from 'app/slices/content-creation/create/device-library/state';

import FixedTopControls from 'routes/ContentCreation/Components/Layout/FixedTopControls';
import { exit } from 'app/slices/content-creation/create/device-library/utils';
import { selectClips } from 'app/slices/content-creation/experience';
import useNavigateCC from 'app/hooks/useNavigateCC';
import { getRoute } from 'app/slices/content-creation/navigation';
import LoadingState from './LoadingState';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	selector: {
		height: 32,
		marginTop: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: theme.colors.neutral.C000,
		color: theme.colors.neutral.C900,
		'&:hover': {
			background: theme.colors.neutral.C000,
		},
	},
	folderName: {
		marginRight: theme.spacing(4),
	},
	clipIndicatorsContainer: {
		position: 'absolute',
		top: theme.spacing(17),
		zIndex: 10,
		right: theme.spacing(3),
		left: theme.spacing(3),
		display: 'flex',
		flexDirection: 'row',
	},
	selectionText: {
		textAlign: 'center',
		marginTop: theme.spacing(11),
		color: theme.colors.neutral.C300,
		paddingBottom: theme.spacing(11),
		userSelect: 'none',
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigateCC = useNavigateCC();
	const { params } = useSelector(getRoute);
	const [processing, setProcessing] = useState(false);
	const clips = useSelector(selectClips);
	const selectedAlbum = useSelector(getSelectedAlbum);
	const selectedMedia = useSelector(getSelected);
	const enabled = useSelector(getEnabled);
	const totalClipCount = useSelector(getTotalClipCount);
	const albumsOpened = useSelector(getAlbumsOpen);
	const processingMedia = useSelector(getProcessing);

	const showProgress = true;
	const showText = enabled;
	const showNext = totalClipCount > 0 && !selectedMedia.length && !processing;
	const showStart = clips.length === 0;

	const buttons = [{
		text: 'Close',
		ds: true,
		disabled: false,
		onClick: () => {
			exit(dispatch, {
				open: false,
				slide: true,
			});

			navigateCC('create', {
				tab: 'photo',
				subTab: params?.qs?.details ? 'tray' : '',
			});
		},
	}, {
		text: showNext ? 'Next' : 'Add',
		ds: true,
		disabled: (!totalClipCount && !selectedMedia.length) || processing,
		onClick: async () => {
			if (totalClipCount > 0 && !selectedMedia.length) {
				navigateCC('details', {
					qs: { start: true },
				});
				return;
			}

			if (!totalClipCount || !selectedMedia.length) {
				return;
			}

			setProcessing(true);

			await dispatch(processMedia());
			navigateCC('details', {
				qs: showStart,
			});

			setTimeout(() => {
				exit(dispatch, {
					open: true,
					slide: false,
				});
			}, 500);
		},
	}];

	const onClickCallback = useCallback(() => {
		if (albumsOpened) {
			dispatch(closeAlbums());
		} else {
			dispatch(openAlbums());
		}
	}, [albumsOpened]);

	return (
		<div className={classes.root}>
			<FixedTopControls
				buttons={buttons}
				position="absolute"
			/>
			<Button
				className={classes.selector}
				variant="contained"
				color="primary"
				onClick={onClickCallback}
				disableRipple
			>
				<Typography className={classes.folderName} variant="subtitle2">{selectedAlbum?.name}</Typography>
				<IconV3
					icon="ChevronDown"
					size={16}
					containerSize={16}
					color="primaryC900"
				/>
			</Button>
			{(showProgress && !albumsOpened) && (
				<div className={classes.clipIndicatorsContainer}>
					{[...Array(10)].map((m, i) => (
						<ProgressIndicator
							current={totalClipCount}
							index={i}
							key={i}
						/>
					))}
				</div>
			)}
			{(showText && !albumsOpened) && <Typography className={classes.selectionText} variant="body2">Select media to fill up to 10 clips</Typography>}
			{processingMedia && <LoadingState />}
		</div>
	);
};
