import { EventEmitter } from 'events';
import JSZip from 'jszip';
import {
	ADD_OBJECT,
	CANVAS_OBJECT_MOVE,
	CANVAS_OBJECT_UP,
	COMPILE,
	COMPILING,
	COMPILE_HALTED,
	DRAW,
	ERROR,
	LOG,
	PREVIEW,
	REMOVE_OBJECT,
	UPDATE_CANVAS,
	UPDATE_OBJECT,
	UPDATE_FILTER,
	SELECT_CANVAS,
	SELECT_OBJECT,
} from './event-types';
import {
	FAILED_TO_LOAD_GIF,
	INVALID_TYPE,
	PLUGIN_INITIALIZE_FAILED,
} from './errors';
import {
	AUDIO,
	HTML,
	GIF,
	GOP,
	IMAGE,
	VIDEO,
} from './types';
import autocompile from './autocompile';
import Canvas from './canvas';
import loadGifData, { loadGifDataFromArrayBuffer } from './loaders/load-gif-data';
import loadImage from './loaders/load-image';
import loadVideo from './loaders/load-video';
import getDefaultOptions from './utils/get-default-options';
import proxyEvents from './utils/proxy-events';
import Store from './store';
import createElement from './utils/create-element';

const acceptedTypes = [AUDIO, HTML, GIF, GOP, IMAGE, VIDEO];
const defaultOptions = {
	plugins: [],
	fonts: {},
	audioBitsPerSecond: null,
	videoBitsPerSecond: 45000000,
	preview: null,
};

let ecId = 0;
/**
 * Main editing canvas class
 *
 * @param {object} container container element
 * @param {object} [options]
 */
class EditingCanvas extends EventEmitter {
	constructor(container, options) {
		super();

		this.id = ecId++;
		this.compiled = null;
		this.dirty = { core: false, preview: false };

		this.store = new Store();
		this.container = container;
		this.options = getDefaultOptions(options, defaultOptions);

		this.store.addListener(UPDATE_OBJECT, () => {
			this.dirty = { core: true, preview: true };
		});

		this.destroyStoreEvents = proxyEvents([
			ADD_OBJECT,
			ERROR,
			LOG,
			REMOVE_OBJECT,
			UPDATE_OBJECT,
		], this.store, this);

		this.canvas = new Canvas(this.container, this.store, {
			filterCollection: this.options.filterCollection,
			fps: this.options.fps,
			fonts: this.options.fonts,
			preview: this.options.preview,
			audioBitsPerSecond: this.options.audioBitsPerSecond,
			videoBitsPerSecond: this.options.videoBitsPerSecond,
		});

		if (this.options.filterCollection) {
			this.destroyFilterEvents = proxyEvents([
				UPDATE_FILTER,
			], this.options.filterCollection, this);
		}

		this.destroyCanvasEvents = proxyEvents([
			CANVAS_OBJECT_MOVE,
			CANVAS_OBJECT_UP,
			COMPILING,
			COMPILE_HALTED,
			DRAW,
			ERROR,
			LOG,
			PREVIEW,
			UPDATE_CANVAS,
			SELECT_CANVAS,
			SELECT_OBJECT,
		], this.canvas, this);

		this.canvas.on(COMPILE, (data) => {
			if (data.preview) {
				this.compiledPreview = data;
				this.dirty.preview = false;
			} else {
				this.compiled = data;
				this.dirty.core = false;
			}

			this.emit(COMPILE, data);
		});

		this.plugins = [];
		for (let i = 0; i < this.options.plugins.length; i++) {
			try {
				this.plugins.push(
					// eslint-disable-next-line
					new this.options.plugins(this),
				);
			} catch (err) {
				this.emit(ERROR, PLUGIN_INITIALIZE_FAILED, err);
				throw err;
			}
		}
	}

	get fps() {
		return this.canvas.options.fps;
	}

	/**
	 * Cleanup function - destroys plugins, containers, and removes anything persistant
	 *
	 */
	destroy() {
		this.destroyCanvasEvents();
		if (this.destroyFilterEvents) {
			this.destroyFilterEvents();
		}
		this.destroyStoreEvents();
		this.canvas.destroy();
		this.store.destroy();

		for (let i = 0; i < this.plugins.length; i++) {
			this.plugins[i].destroy();
		}
		this.container.innerHTML = '';
	}

	/**
	 * Getter to retrieve debug information
	 */
	get debug() {
		return {
			canvas: this.canvas.debug.data,
		};
	}

	/**
	 * Adds an object
	 *
	 * @param {string} type type of object
	 * @param {any} data object data
	 * @param {object} [styles] data styles
	 * @returns {object} object item
	 */
	async add(type, data, styles) {
		if (acceptedTypes.indexOf(type) === -1) {
			const e = new Error(INVALID_TYPE);
			this.emit(ERROR, INVALID_TYPE, e);
			throw e;
		}

		styles = styles || {};

		let gifData;
		let o;

		switch (type) {
		case GIF:
			try {
				if (data.arrayBuffer) {
					gifData = await loadGifDataFromArrayBuffer(data.arrayBuffer);
				} else {
					gifData = await loadGifData(data.src);
				}
			} catch (err) {
				this.emit(ERROR, FAILED_TO_LOAD_GIF, data);
				throw err;
			}
			o = this.store.add(type, { frames: gifData, ...data }, styles);
			break;

		case IMAGE:
			if (!data.blob) {
				data.blob = await loadImage(data.src);
			}
			o = this.store.add(type, data, styles);
			break;
		case VIDEO:
			if (!data.blob && !data.asset.blob && !data.el) {
				data.blob = await loadVideo(data.url);
			}
			o = this.store.add(type, data, styles);
			break;
		default:
			o = this.store.add(type, data, styles);
			break;
		}

		return o;
	}

	/**
	 * Returns the object id
	 *
	 * @param {number} id id of the object
	 * @returns {object} object
	 */
	get(id) {
		return this.store.get(id);
	}

	/**
	 * Update an object
	 *
	 * @param {number} id id of the object
	 * @param {any} data object data
	 * @param {object} [styles] data styles
	 */
	updateStyle(id, key, value) {
		return this.store.updateStyle(id, key, value);
	}

	/**
	 * Update an object
	 *
	 * @param {number} id id of the object
	 * @param {any} data object data
	 * @param {object} [styles] data styles
	 * @param {boolean} ignore z-index
	 * @returns {object} object
	 */
	async update(id, data, styles, ignoreZ) {
		const item = this.get(id);

		let gifData;
		if (data.src) {
			switch (item.type) {
			case GIF:
				try {
					if (data.arrayBuffer) {
						gifData = await loadGifDataFromArrayBuffer(data.arrayBuffer);
					} else {
						gifData = await loadGifData(data.src);
					}
				} catch (err) {
					this.emit(ERROR, FAILED_TO_LOAD_GIF, data);
					throw err;
				}
				data.frames = gifData;
				break;

			case IMAGE:
				if (!data.blob) {
					data.blob = await loadImage(data.src);
				}
				break;
			case VIDEO:
				if (!data.blob && !data.el) {
					data.blob = await loadVideo(data.url);
				}
				break;
			default:
				break;
			}
		}

		return this.store.update(id, data, styles, ignoreZ);
	}

	/*
	 *
	 * @param {number} id id of the object
	 */
	remove(id) {
		return this.store.remove(id);
	}

	/**
	 * draws the objects to a viewable/moveable canvas
	 */
	async draw() {
		await this.canvas.draw();
	}

	/*
	 * stops the draw
	 */
	async stop() {
		this.canvas.stop();
	}

	/**
	 * Halts the active compiling job
	 */
	async haltCompile() {
		await this.canvas.haltCompile();
	}

	/**
	 * Compiles a preview
	 */
	async compilePreview() {
		await this.canvas.compilePreview();
	}

	/**
	 * returns current compiled preview
	 */
	get preview() {
		return this.canvas.preview;
	}

	async compileZip({ duration, muted }) {
		const zip = new JSZip();
		const clip = zip.folder('clip');

		const { modifier, nodes } = await this.canvas.getNodeRenderingData();

		const manifest = {
			duration,
			modifier,
			muted: !!muted,
			nodes: [],
		};

		for (let i = 0; i < nodes.length; i++) {
			const {
				size,
				rotation,
				position,
				filename,
				sourceData,
				type,
			} = nodes[i];

			clip.file(filename, sourceData);

			manifest.nodes.push({
				size,
				rotation,
				position,
				type,
				file: filename,
			});
		}

		clip.file('manifest.json', JSON.stringify(manifest, null, '	'));
		const blob = await zip.generateAsync({ type: 'blob' });
		this.canvas.emit(COMPILE, { zip: blob });
		return blob;
	}

	/**
	 * Compiles a video
	 *
	 * @param {number} start time in seconds
	 * @param {number} end time in seconds
	 * @returns {blob} video blob
	 */
	async compile(start, end, options) {
		if (typeof start === 'undefined') {
			throw new Error('Compile must be called with an end time in seconds');
		}

		if (typeof end === 'undefined') {
			end = start;
			start = 0;
		}

		if (Number.isNaN(start) || Number.isNaN(end)) {
			throw new Error('Compile must be called with start and end times in seconds');
		}

		await this.canvas.compile((start || 0) * 1000, end * 1000, options);
	}

	/**
	 * Finds a node element the container
	 */
	find(id) {
		return this.container.querySelector(`[data-object-id="${id}"]`);
	}

	createCanvasNode(node) {
		return this.canvas.createNode(node);
	}

	get background() {
		return this.store.background;
	}

	get backgroundNode() {
		if (typeof this.store.background === 'undefined') {
			return null;
		}
		return this.find(this.store.background);
	}

	get root() {
		return this.store.root;
	}

	get rootNode() {
		if (typeof this.store.root === 'undefined') {
			return null;
		}
		return this.find(this.store.root);
	}

	get items() {
		return this.store.items;
	}

	disableGestures() {
		this.canvas.ignoreGestures = true;
	}

	enableGestures() {
		this.canvas.ignoreGestures = false;
	}

	pauseAudio(currentTime) {
		this.canvas.pauseAudio(currentTime);
	}

	playAudio(currentTime) {
		this.canvas.playAudio(currentTime);
	}

	pauseVideo() {
		this.canvas.pauseVideo();
	}

	waitDraw() {
		return new Promise((resolve) => {
			this.once(DRAW, resolve);
		});
	}

	async waitForEl(id) {
		const el = this.find(id);

		if (el) {
			return el;
		}

		await this.waitDraw();
		const next = await this.waitForEl(id);
		return next;
	}
}

EditingCanvas.autocompile = autocompile;

const compose = ({
	aspectRatio,
	dimensions,
	styles,
	...rest
}) => {
	const container = createElement({
		tagName: 'div',
		className: 'editing-canvas',
	});

	const { height, width } = dimensions;

	container.style.position = 'fixed';
	container.style.left = 0;
	container.style.top = 0;
	container.style.overflow = 'hidden';
	container.style.height = `${height}px`;
	container.style.width = `${width}px`;

	Object.keys(styles).forEach((k) => {
		container.style[k] = styles[k];
	});

	const editingCanvas = new EditingCanvas(container, rest);
	editingCanvas.dimensions = dimensions;
	return editingCanvas;
};

export {
	EditingCanvas as default,
	compose,
};
