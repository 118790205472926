import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ButtonSpinner from 'rds/components/Spinners/Button';

const BUFFER_THRESHOLD = 450;

const useStyles = makeStyles((theme) => ({
	container: {
		opacity: 0,
		position: 'absolute',
		top: 'calc(50% - 25px)',
		left: 'calc(50% - 25px)',
	},
	show: {
		opacity: 1,
	},
	darkOverlay: {
		position: 'absolute',
		left: 0,
		top: 0,
		zIndex: 1,
		bottom: 0,
		right: 0,
		width: '100%',
		height: '100%',
		background: theme.colors.neutral.C900,
		transition: 'opacity 0.25s linear',
		opacity: 0,
	},
	darkOverlayShow: {
		opacity: 0.4,
	},
}));

export default ({
	buffered,
	play,
	time,
	forceShow,
}) => {
	const classes = useStyles();
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(false);

		const t = setTimeout(() => {
			setShow(true);
		}, BUFFER_THRESHOLD);

		return () => {
			clearTimeout(t);
		};
	}, [time]);

	// should show if
	//   1. the use effect says show
	//   2. and its not paused
	//   3. and its not fully buffered

	const shouldShow = forceShow || (show && play && !buffered);

	// the spinner is only if its the active clip, the dark overlay can show for all
	const klass = shouldShow
		? clsx(classes.container, classes.show)
		: classes.container;

	const darkOverlayKlass = shouldShow
		? clsx(classes.darkOverlay, classes.darkOverlayShow)
		: classes.darkOverlay;

	return (
		<>
			<div className={darkOverlayKlass} />
			<div className={klass}>
				<ButtonSpinner size={50} />
			</div>
		</>
	);
};
