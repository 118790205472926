import React from 'react';
import Modal from 'rds/components/Modal';
import { useTheme } from '@material-ui/styles';

export default ({
	children,
	open,
	onClose,
}) => {
	const theme = useTheme();
	if (!open) {
		return null;
	}

	return (
		<Modal
			open={open}
			style={{ background: theme.colors.neutral.C900 }}
			top={{
				close: () => {
					onClose();
				},
				closeIconPosition: 'left',
			}}
		>
			<div>
				{children}
			</div>
		</Modal>
	);
};
