import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	getCanBlockUser,
	getMyUsername,
	getTheirUsername,
	getTheirProfileUuid,
	getMyLoadingProfile,
	getTheirLoadingProfile,
	getTheirInteractions,
} from 'app/slices/user-account-v2/accounts/selectors';
import { openDotsMenu, openBottomMenu } from 'app/slices/user-account-v2/layout/slice';
import PageTopBar from 'rds/components/Layouts/PageTopBar';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import { makeStyles, useTheme } from '@material-ui/core';
import useNavigateReportModal from 'app/hooks/useNavigateReportModal';

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		width: '100%',
	},
	pageTopBar: {
		marginTop: (props) => (props.show ? 48 : 0),
	},
}));

const UserAccountHeader = ({ back = false, isMe, fixPosition }) => {
	const classes = useStyles({ show: useSelector(getIsUpdatedVersionAvailable) });
	const dispatch = useDispatch();

	const theme = useTheme();
	const canBlockUser = isMe ? false : useSelector(getCanBlockUser);
	const interactions = useSelector(getTheirInteractions);
	const blocked = isMe ? false : interactions?.callerIsBlocking;
	const loadingProfile = useSelector(isMe ? getMyLoadingProfile : getTheirLoadingProfile);
	const username = useSelector(isMe ? getMyUsername : getTheirUsername);
	const navigate = useNavigate();
	const uuid = useSelector(getTheirProfileUuid);
	const reportNavigate = useNavigateReportModal();

	const overflow = [];

	if (isMe) {
		overflow.push({
			label: 'Settings',
			onClick: () => {
				navigate('/my-account/settings');
			},
		},
		{
			label: 'Saved',
			onClick: () => {
				navigate('/my-account/saved');
			},
		});
	} else if (!canBlockUser) {
		overflow.push({
			label: 'Report',
			onClick: () => {
				dispatch(openDotsMenu('reportAccount'));
			},
			color: theme.colors.error.C300,
		});
	} else {
		overflow.push({
			label: blocked ? 'Unblock' : 'Block',
			onClick: () => {
				if (blocked) {
					dispatch(openBottomMenu('unBlock'));
				} else {
					dispatch(openBottomMenu('block'));
				}
			},
			color: blocked ? null : theme.colors.error.C300,
		},
		{
			label: 'Report',
			onClick: () => {
				reportNavigate.enter({
					type: 'user',
					label: 'Report Account',
					title: username,
					id: uuid,
				});
			},
			color: theme.colors.error.C300,
		});
	}

	return (
		<>
			<div className={classes.root}>
				<PageTopBar
					className={classes.pageTopBar}
					overflow={!back ? overflow : null}
					disableOverflow={loadingProfile}
					title={username}
					back={back || isMe === false}
					fixPosition={fixPosition}
				/>
			</div>
		</>
	);
};

export default UserAccountHeader;
