import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import NF from 'routes/NotFound/NotFound';
import ErrorPage from 'rds/components/Errors/ErrorPage';

const isNull = (v) => v === null || typeof v === 'undefined';

export default ({
	children,
	ErrorComponent = ErrorPage,
	Skeleton,
	NotFound = NF,
	requests = {},
}) => {
	const dispatch = useDispatch();
	const [closed, setClosed] = useState(false);

	const data = {};
	const keys = Object.keys(requests);

	const nf = (k) => `${k}:notfound`;
	const lk = (k) => `${k}:loading`;
	const e = (k) => `${k}:error`;

	for (let i = 0; i < keys.length; i++) {
		const k = keys[i];

		data[k] = useSelector(requests[k].selector);
		data[e(k)] = useSelector(requests[k].errors);
		data[lk(k)] = useSelector(requests[k].loading);

		if (requests[k].notFound) {
			data[nf(k)] = useSelector(requests[k].notFound);
		}
	}

	const isDone = () => {
		let done = true;
		for (let i = 0; i < keys.length; i++) {
			if (data[keys[i]] === null || typeof data[keys[i]] === 'undefined') {
				done = false;
				break;
			}
		}

		return done;
	};

	const getErrors = () => {
		let errors = false;

		for (let i = 0; i < keys.length; i++) {
			const k = e(keys[i]);

			if (data[k] !== null && typeof data[k] !== 'undefined') {
				errors = data[k];
				break;
			}
		}

		return errors;
	};

	const getNotFound = () => {
		let result = false;

		for (let i = 0; i < keys.length; i++) {
			const k = nf(keys[i]);

			if (data[k]) {
				result = data[k];
				break;
			}
		}

		return result;
	};

	const done = isDone();
	const errors = getErrors();
	const notFound = getNotFound();

	useEffect(() => {
		for (let i = 0; i < keys.length; i++) {
			const k = keys[i];
			if (isNull(data[k]) && !data[lk(k)] && !data[e(k)] && !notFound && !errors) {
				dispatch(requests[k].action(requests[k].params));
			}
		}

		return () => {
			for (let i = 0; i < keys.length; i++) {
				const k = keys[i];
				if (data[k] && requests[k].reset) {
					if (!requests[k].resetConditional || requests[k].resetConditional(data[k])) {
						setClosed(false);
						dispatch(requests[k].reset());
					}
				}
			}
		};
	}, [data]);

	useEffect(() => {
		if (done && !closed) {
			setClosed(true);
		}
	}, [closed, done]);

	const render = () => {
		if (errors) {
			return (
				<ErrorComponent
					errors={errors}
					homeLink={false}
					title="Internal server error"
					message={(
						<>
							<Typography variant="body1">
								An error has occurred loading this page :(
							</Typography>
							<Button component={Link} to="/">Home</Button>
						</>
					)}
				/>
			);
		}

		for (let i = 0; i < keys.length; i++) {
			const k = keys[i];
			if (data[k] && requests[k].reset) {
				if (!requests[k].resetConditional || requests[k].resetConditional(data[k])) {
					return null;
				}
			}
		}

		if (notFound) {
			return <NotFound />;
		}

		if (closed) {
			return children;
		}

		if (!Skeleton) {
			return done ? children : null;
		}

		return (
			<>
				{ done ? children : null }
				<Skeleton hide={done} />
			</>
		);
	};

	return render();
};
