import React from 'react';
import { useSelector } from 'react-redux';
import PageTopBar from 'rds/components/Layouts/PageTopBar';
import scrollEmitter from 'layout/scrollEmitter';
import { getRouterCalls } from 'app/slices/layout';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import { useNavigate } from 'react-router-dom';

export default () => {
	const navigate = useNavigate();
	const showUpdateBanner = useSelector(getIsUpdatedVersionAvailable);
	const historyLength = useSelector(getRouterCalls);

	const handleClick = () => {
		if (historyLength > 1) {
			navigate(-1);
		} else {
			navigate('/my-account');
		}
		scrollEmitter.emit('scrollToTop');
	};

	return (
		<PageTopBar
			title="Saved"
			back
			onBack={handleClick}
			style={{ top: showUpdateBanner ? 48 : 0 }}
		/>
	);
};
