// Some things are commented out because they are obslete with the
// window.location.href change at the end
// this change is to wipe/clear memory, if we finish the memory leak branch
// we can bring it all back in

import {
	createAsyncThunk,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import api from 'services/api';
// import { notify } from 'app/slices/notifications/notifications';
// import cancelAllContentCreation from 'routes/ContentCreation/util/reset-content-creation-slices.js';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import uploadQueue from 'lib/upload-queue';
import { add } from 'app/slices/app/dev-logs';
import { compileAll } from './index'; // resetAll
import { requestCreateCustomDestination } from '../../destinations-search/custom-destination';

const createExperienceV3 = async ({
	activities,
	recommendedFor,
	title,
	caption,
	collections,
	destination,
	zip,
	nodes,
	touchRegions,
}) => {
	const experienceData = {
		activities: activities.map((a) => a.slug),
		recommendedFor: recommendedFor.map((a) => a.slug),
		title: title || undefined,
		caption: caption || undefined,
		identifiers: collections.map((a) => a.slug),
	};

	const response = await api.post('/experiences/create-v3', {
		experience: experienceData,
		zip,
		nodes,
		touchRegions,
		destination: destination?.uid,
	});
	return response.data;
};

export const publish = createAsyncThunk(
	'publish',
	async (to, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState();
			const {
				activities,
				recommendedFor,
				title,
				caption,
				collections,
				destination,
				clipIds,
			} = state.contentCreationExperience;

			dispatch(add({
				level: 'info',
				message: 'starting-publish',
				payload: state.contentCreationExperience,
			}));

			// Compile zipped up nodes together
			const {
				cover,
				nodes,
				touchRegions,
				zip,
				zipFile,
			} = await compileAll(clipIds);
			dispatch(add({
				level: 'info',
				message: 'compiled-publish',
				payload: {
					zipSize: zipFile.size,
				},
			}));

			// Prep the destination
			let des;
			if (destination && !destination.uid) {
				const customDes = await dispatch(requestCreateCustomDestination(destination));
				des = customDes.payload;
			} else {
				des = destination;
			}
			// console.log(cover,
			// 	nodes,
			// 	touchRegions,
			// 	zip,
			// 	zipFile);

			// 	const img = document.createElement('img');
			// 	img.src = cover;
			// 	img.style.width = '100%';
			// 	img.style.height = '100%';
			// 	console.log(img);

			// 	document.body.appendChild(img);

			// Make an API call to create the the experience
			// https://docs.dev.roamrhino.com/#tag/experiences/operation/experiences/create-v3
			const experience = await createExperienceV3({
				activities,
				recommendedFor,
				title,
				caption,
				collections,
				destination: des,
				zip,
				nodes,
				touchRegions,
			});

			dispatch(add({
				level: 'info',
				message: 'created-experience-v3',
				payload: {
					experience,
				},
			}));

			await uploadQueue.uploadExperience({
				accountUuid: experience.user.uuid,
				experienceUid: experience.uid,
				cover,
				blob: zipFile,
				uploadInfo: experience.uploadInfo,
			});

			dispatch(add({
				level: 'info',
				message: 'uploading experience in background',
				payload: {
					experienceUid: experience.uid,
				},
			}));

			if (to) {
				// dispatch(unlock());
				window.location.href = to;
				// dispatch(push(to));
			}

			// dispatch(notify({
			// 	severity: 'info',
			// 	description: 'Experience uploading in background',
			// 	autoHideDuration: 2000,
			// }));

			// cancelAllContentCreation();

			// dispatch(resetAll());
			return experience;
		} catch (error) {
			return rejectWithValue(handleSliceError(error, { defaultMessage: 'Failed to publish experience' }));
		}
	},
);

const INITIAL_STATE = {
	active: false,
	compileIndex: null,
	errors: null,
	// upload
};
export const experienceQueueSlice = createSlice({
	name: 'contentCreationExperienceQueue',
	initialState: { ...INITIAL_STATE },

	reducers: {
		reset: (state) => {
			Object.keys(INITIAL_STATE).forEach((key) => {
				state[key] = INITIAL_STATE[key];
			});
		},
		unlock: (state) => {
			state.active = false;
		},

	},

	extraReducers: {
		[publish.pending]: (state) => {
			state.active = true;
		},
		[publish.rejected]: (state, action) => {
			state.active = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = experienceQueueSlice;
const {
	reset, unlock,
} = actions;
// unlock = actions.unlock;

export {
	reset, unlock,
};

export const getSlice = (state) => state[name];
export const getActive = createSelector(getSlice, (slice) => slice?.active);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);

injectReducer(name, reducer);
