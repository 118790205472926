import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	makeStyles, Slide, List, ListItem,
} from '@material-ui/core';
import Typography from 'rds/components/Typography';
import {
	requestLoadMoreReplies,
	getLoadingMoreReplies,
	getNewCommentId,
	resetNewCommentId,
} from 'app/slices/comments';
import clsx from 'clsx';
import ButtonSpinner from 'rds/components/Spinners/Button';
import Reply from './Reply';

const useStyles = makeStyles((theme) => ({
	replyThreadContainer: {
		width: '100%',
	},
	showRepliesButton: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		marginLeft: theme.spacing(11),
		marginRight: theme.spacing(3),
	},
	showRepliesText: {
		display: 'flex',
		alignItems: 'center',
		color: theme.colors.neutral.C200,
		transition: 'all 0.1s',
	},
	listItem: {
		width: '100%',
		padding: '0px !important',
	},
	listContainer: {
		padding: '0px !important',
	},
	loading: {
		color: theme.colors.neutral.C400,
	},
	spinner: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: theme.spacing(2),
	},
	animation: {
		transition: 'opacity 500ms, transform 500ms',
	},

}));

export default ({ find, comment }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const ref = useRef();
	const {
		id,
		replies,
		interactions,
	} = comment;
	const newCommentId = useSelector(getNewCommentId);
	const [found, setFound] = useState(-1);
	const visibleReplies = replies.comments.length;
	const loadingReplies = useSelector(getLoadingMoreReplies);
	const repliesLength = Number(interactions?.replyCount);
	const showLoadMoreButton = repliesLength > visibleReplies;
	const remainingReplies = repliesLength - visibleReplies;

	const loadMoreReplies = () => {
		if (replies?.links?.next) {
			dispatch(requestLoadMoreReplies({ next: replies.links.next, parentId: id }));
		}
	};

	useEffect(() => {
		if (newCommentId) {
			setFound(newCommentId);
		}
	}, [newCommentId]);

	useEffect(() => () => {
		setFound(-1);
		dispatch(resetNewCommentId());
	}, []);

	return (
		<div className={classes.replyThreadContainer}>
			{showLoadMoreButton && (
				<div className={classes.showRepliesButton} onClick={loadMoreReplies}>
					<Typography
						variant="buttonXsmall"
						className={loadingReplies ? clsx(classes.showRepliesText, classes.loading) : classes.showRepliesText}
					>
						Show more replies (
						{remainingReplies}
						)
						<div style={{ visibility: loadingReplies ? 'visible' : 'hidden' }} className={classes.spinner}>
							<ButtonSpinner size={12} />
						</div>
					</Typography>
				</div>
			)}
			<List ref={ref} className={classes.listContainer} data-ignoreslider="true">
				{replies.comments.map((reply) => (
					<div key={reply.id}>
						{found === reply.id ? (
							<Slide
								direction="up"
								in
								timeout={500}
								mountOnEnter
								unmountOnExit
							>
								<ListItem
									id={`reply-${reply.id}`}
									className={`${classes.listItem} ${found === reply.id ? 'fade-enter-active' : ''} ${found === reply.id ? classes.animation : ''}`}
								>
									<Reply
										key={reply.id}
										reply={reply}
										parentId={id}
										expand={reply.new}
										highlight={newCommentId === reply.id || found === reply.id || find === reply.id}
										scrollTo={newCommentId === reply.id || found === reply.id || find === reply.id}
									/>
								</ListItem>
							</Slide>
						) : (
							<ListItem
								id={`reply-${reply.id}`}
								className={classes.listItem}
								key={reply.id}
							>
								<Reply
									key={reply.id}
									reply={reply}
									parentId={id}
									expand={reply.new}
									highlight={newCommentId === reply.id || found === reply.id || find === reply.id}
									scrollTo={newCommentId === reply.id || found === reply.id || find === reply.id}
								/>
							</ListItem>
						)}
					</div>
				))}
			</List>
		</div>
	);
};
