/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	CLIPS_THRESHOLD,
	DURATION_THRESHOLD,
	lockCC,
	unlockCC,
	requestAddClipPhoto,
	selectClipCount,
} from 'app/slices/content-creation/experience';
import dispatchError from 'routes/ContentCreation/errors';
import assetManager from 'lib/asset-manager';
import trim from 'lib/ffmpeg/alt-trim';
import PageSpinner from 'rds/components/Spinners/Page';
import IconV3 from 'rds/components/IconV3/IconV3';
import useStyles from './use-styles';
import { getImage, getVideoDetails, readFile } from './utils';

export const ACCEPTED_IMAGES = ['.bmp', '.gif', '.png', '.jpg', '.jpeg'];
export const ACCEPTED_VIDEOS = ['.mp4', '.webm']; // , '.mov'];

const Legacy = ({
	disabled,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [processing, setProcessing] = useState(false);
	const clipCount = useSelector(selectClipCount);
	const reachedMax = clipCount === CLIPS_THRESHOLD;

	const ref = useRef();

	const processImage = async (file, fileType) => {
		try {
			const { height, width, blob } = await getImage(file);

			await dispatch(requestAddClipPhoto({
				type: 'image',
				data: {
					blob,
				},
				dimensions: {
					height: document.body.clientHeight,
					width: document.body.clientWidth,
				},
				assetDimensions: {
					height,
					width,
				},
			}));
		} catch (err) {
			dispatchError(dispatch, `${fileType} is not a supported image format.`);
		}
	};

	const processVideo = async (file) => {
		try {
			const { duration, height, width } = await getVideoDetails(file);
			const incomingClipCount = Math.ceil((duration * 1000) / DURATION_THRESHOLD);
			const maxClips = Math.min(CLIPS_THRESHOLD - clipCount, incomingClipCount);

			await setProcessing(true);
			const data = await readFile(file);

			const output = await trim({
				name: file.name,
				data,
				// TODO: this needs to be fixed if we ever accept more codecs
				type: file.type === 'video/mp4' ? 'mp4' : 'webm',
				start: 0,
				stop: maxClips * (DURATION_THRESHOLD / 1000),
				log: true,
			});

			const blob = new Blob([output.data], { type: 'video/mp4' });
			const asset = assetManager.set(blob);

			for (let j = 0; j < maxClips; j++) {
				await dispatch(requestAddClipPhoto({
					type: 'video',
					data: {
						asset,
						start: j * (DURATION_THRESHOLD / 1000),
						end: Math.min(duration, (j + 1) * (DURATION_THRESHOLD / 1000)),
					},
					assetDimensions: {
						height,
						width,
					},
				}));
			}
		} catch (err) {
			dispatchError(dispatch, err.message);
		} finally {
			await setProcessing(false);
		}
	};

	const handleChange = async (e) => {
		const { files } = e.target;

		await dispatch(lockCC());
		for (let i = 0; i < files.length; i++) {
			if ((clipCount) >= CLIPS_THRESHOLD) {
				return;
			}

			const parts = files[i].name.split('.');
			const extension = `.${parts[parts.length - 1]}`;
			if (ACCEPTED_IMAGES.indexOf(extension.toLowerCase()) > -1) {
				await processImage(files[i], extension);
			} else if (ACCEPTED_VIDEOS.indexOf(extension.toLowerCase()) > -1) {
				await processVideo(files[i]);
			} else {
				const Err = `${extension} is not a supported ${files[i].type.slice(0, -extension.length)} format.`;
				dispatchError(dispatch, Err);
				break;
			}
		}
		await dispatch(unlockCC());

		if (ref?.current) {
			ref.current.value = null;
		}
	};

	useEffect(() => {
		if (disabled && Date.now() - disabled < 100) {
			ref?.current?.click();
		}
	}, [disabled]);

	return (
		<>
			{
				processing
					? <PageSpinner message="Processing video" />
					: null
			}
			<form>
				<label
					htmlFor="file-upload"
				>
					<IconV3
						className={classes.iconButton}
						icon="PhotoLibrary"
						dropShadow
						size={32}
					/>
				</label>
				<input
					type="file"
					name="media"
					disabled={reachedMax}
					id="file-upload"
					multiple
					onChange={(e) => handleChange(e)}
					placeholder="?"
					style={{ display: 'none' }}
					ref={ref}
				/>
			</form>
		</>
	);
};

export default Legacy;
