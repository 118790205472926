import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { createSimpleRequestSlice } from 'services/slice';
import api, { CancelToken } from 'services/api';
import handleSliceError from 'utils/handle-slice-error';
import { getFormPayload } from './form';

const requestSearch = createAsyncThunk(
	'requestSearch',
	async (_, {
		signal,
		getState,
		rejectWithValue,
	}) => {
		const source = CancelToken.source();

		signal.addEventListener('abort', () => {
			source.cancel();
		});

		try {
			const pl = getFormPayload(getState());
			const search = encodeURIComponent(pl.search);
			if (!pl.search) {
				return { destinations: [] };
			}

			const response = await api.get(`destinations/?includeThirdParty=true&includeCustom=true&q=${search}`, {
				cancelToken: source.token,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const name = 'searchDestinations';
const slice = createSimpleRequestSlice({
	name,
	request: requestSearch,
});

const {
	reset,
	getSlice,
	getLoading,
	getErrors,
	getResponse,
} = slice;

const getDestinations = createSelector((state) => state, (state) => {
	if (!state[name].response?.destinations) {
		return null;
	}

	const exceptions = state?.follow?.destinations?.map((d) => d.uid) || [];
	return state[name].response?.destinations?.filter((d) => exceptions.indexOf(d.uid) === -1) ?? [];
});

export {
	reset,
	getSlice,
	getLoading,
	getErrors,
	getResponse,
	getDestinations,
	requestSearch,
};
