import { createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import { requestEditProfile } from './thunks';

const initialState = {
	displayName: '',
	bio: '',
	avatar: null,
	avatarFormData: null,
	loading: false,
	updated: false,
};

const name = 'editProfile';

const editProfileSlice = createSlice({
	name,
	initialState,
	reducers: {
		change: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
			state.updated = true;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestEditProfile.fulfilled, (state) => {
				state.updated = false;
			})
			.addCase(requestEditProfile.rejected, (state) => {
				state.updated = false;
			});
	},
});

export const {
	change,
	reset,
} = editProfileSlice.actions;

injectReducer(name, editProfileSlice.reducer);

export default editProfileSlice.reducer;
