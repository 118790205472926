import { registerRoute } from 'Router/registry';
import SignIn from './SignIn';

registerRoute({
	authorization: undefined,
	Component: SignIn,
	routeOptions: {
		path: '/signin',
	},
	transition: 'slide',
});
