import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
import { getConstants } from 'app/slices/app/app';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import { getSelectedActivities, set, getShowActivitiesRequiredError } from 'app/slices/experience-view/edit';
import EachActivity from './EachActivity';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(3),
		paddingBottom: theme.spacing(2),
	},
	required: {
		textAlign: 'left',
	},
	titles: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),
	},
	left: {
		display: 'flex',
	},
	requiredText: {
		color: theme.colors.neutral.C300,
		marginBottom: theme.spacing(5),
	},
	selectedNumbers: {
		textAlign: 'left',
		marginRight: theme.spacing(4),
		color: theme.colors.neutral.C500,
	},
	activitiesIcon: {
		marginRight: theme.spacing(3),
	},
	number: {
		marginRight: theme.spacing(2),
	},
	errorContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingRight: theme.spacing(),
	},
	error: {
		color: theme.colors.error.C600,
	},
}));

export default ({ activities }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const constants = useSelector(getConstants);
	const newActivities = useSelector(getSelectedActivities);
	const showError = useSelector(getShowActivitiesRequiredError);

	useEffect(() => {
		if (!activities) {
			return;
		}

		dispatch(set({ activities }));
	}, [activities]);

	return (
		<div className={classes.root}>
			<div className={classes.titles}>
				<div className={classes.left}>
					<IconV3
						icon="ListCheck"
						style={{
							marginRight: 12,
						}}
						size={20}
						containerSize={20}
					/>
					<Typography variant="subtitle1">Activities</Typography>
				</div>
				<Typography className={classes.selectedNumbers} variant="body1">
					{newActivities.length}
					{' '}
					of 5
				</Typography>
			</div>

			<div className={classes.errorContainer}>
				<Typography className={showError ? clsx(classes.requiredText, classes.error) : clsx(classes.requiredText)} variant="caption">Select at least one (required).</Typography>

			</div>
			<Grid container rowspacing={2}>
				{constants && constants.activities.map((a, i) => (
					<Grid key={a.uid} item xs={6}>
						<EachActivity activity={a} index={i} />
					</Grid>
				))}
			</Grid>
		</div>
	);
};
