const MAX_KEYS = 2000;
const LOCAL_STORAGE_KEY = 'location_keys';

function getStoredKeys() {
	const storedKeys = localStorage.getItem(LOCAL_STORAGE_KEY);
	return storedKeys ? storedKeys.split(',') : [];
}

function storeKeys(keys) {
	localStorage.setItem(LOCAL_STORAGE_KEY, keys.join(','));
}

function addKeyToKeys(key) {
	const keys = getStoredKeys();
	if (!keys.includes(key)) {
		keys.push(key);
		if (keys.length > MAX_KEYS) {
			keys.splice(0, MAX_KEYS / 2);
		}
	}
	storeKeys(keys);
}

function determineDirection(currentKey, nextKey) {
	if (!currentKey) {
		return 'forward';
	}

	const keys = getStoredKeys();
	const currentKeyIndex = keys.indexOf(currentKey);
	const nextKeyIndex = keys.indexOf(nextKey);

	if (nextKeyIndex === -1 || nextKeyIndex > currentKeyIndex) {
		return 'forward';
	}

	return 'back';
}

export { addKeyToKeys, determineDirection };
