import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { gotoSettings } from 'app/android-service/util';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	subtitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(6),
	},
}));
export default () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography
				variant="subtitle1"
			>
				Update permissions
			</Typography>
			<Typography
				className={classes.subtitle}
				variant="body1"
			>
				Grant Rhino access to your device library.
			</Typography>
			<Button
				size="small"
				variant="outlined"
				color="primary"
				onClick={gotoSettings}
			>
				Go to settings
			</Button>
		</div>
	);
};
