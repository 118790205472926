import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	getConstants,
	requestApp,
	getErrors as getConstantsErrors,
	getLoading as getAppLoading,
	getIsUpdatedVersionAvailable,
} from 'app/slices/app/app';
import RequestProvider from 'rds/providers/Request';
import clsx from 'clsx';
import IconV3 from 'rds/components/IconV3';
import { useParams } from 'react-router-dom';
import EachFilterGroup from './EachFilterGroup';

const useStyles = makeStyles((theme) => ({
	filtersRoot: {
		background: theme.palette.background.page,
		position: 'sticky',
		left: 0,
		right: 0,
		zIndex: 5,
		overflow: 'hidden',
		marginBottom: theme.spacing(4),
		marginLeft: theme.spacing(3),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
	},
	filtersContainer: {
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	rootSecondary: {
		top: 156,
	},
	iconContainer: {
		position: 'absolute',
		top: 8,
		left: 0,
		height: 40,
		width: 24,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	scrollableOptions: {
		position: 'absolute',
		display: 'flex',
		width: '100%',
		left: theme.spacing(8),
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		paddingRight: theme.spacing(14),
		zIndex: 15,
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},

}));

export default ({
	modalOptions,
	setModalOptions,
	filters,
	onOpen,
	onReset,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { uid } = useParams();
	const constants = useSelector(getConstants);
	const show = useSelector(getIsUpdatedVersionAvailable);

	const containerClass = show ? clsx(classes.scrollableOptions, classes.secondaryScrollableOptions) : classes.scrollableOptions;
	const iconContainerClass = show ? clsx(classes.iconContainer, classes.secondaryIconContainer) : classes.iconContainer;
	const groups = [
		{ title: 'Community', options: constants?.identifiers },
		{ title: 'Activity', options: constants?.activities },
		{ title: 'Recommended for', options: constants?.recommendedFor },
	];

	return (
		<RequestProvider
			requests={{
				constants: {
					selector: getConstants,
					action: requestApp,
					loading: getAppLoading,
					errors: getConstantsErrors,
				},
			}}
		>
			<>
				<div className={classes.filtersRoot} style={{ zIndex: modalOptions ? 18 : 15 }}>
					<div className={classes.filtersContainer}>
						<div className={iconContainerClass}>
							<IconV3
								icon="Filter"
								size={24}
								containerSize={24}
								dropShadow
								color="primaryC600"
							/>
						</div>
					</div>
					<div className={containerClass}>
						{groups.map((g, i) => (
							<EachFilterGroup
								key={i}
								{...g}
								filters={filters}
								inactive={typeof modalOptions?.index === 'number' && modalOptions?.index !== i}
								active={modalOptions?.index === i}
								onClick={() => {
									if (i === modalOptions?.index) {
										setModalOptions(null);
										return;
									}
									if (modalOptions !== null) {
										onReset && onReset();
									}

									const y = { ...g, index: i, uid };
									dispatch(onOpen(y));
									setModalOptions(y);
								}}
							/>
						))}
					</div>
				</div>
			</>
		</RequestProvider>
	);
};
