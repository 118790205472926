import React from 'react';
import { makeStyles } from '@material-ui/core';
import LoadingSkeletonPage from 'rds/components/Skeletons/SecondaryPage';
import Contents from './AppNotificationsContents';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(17),
		paddingBottom: theme.spacing(10),
	},
	list: {
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		marginBottom: theme.spacing(10.5),
	},
	subtitle: {
		marginBottom: theme.spacing(2.5),
	},
}));

const Skeleton = ({ hide }) => {
	if (hide) {
		return null;
	}

	return (
		<LoadingSkeletonPage />
	);
};

export default ({ show }) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.root}>
				<Contents shouldRender={show} Skeleton={Skeleton} />
			</div>
		</>
	);
};
