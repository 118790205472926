import { useDispatch } from 'react-redux';
import {
	enterLibrary,
	exitCC,
	navigateCC,
	stayCC,
} from 'app/slices/content-creation/navigation';
import { change } from 'app/slices/content-creation/editor/layout';

const useNavigateCC = () => {
	const dispatch = useDispatch();

	const navigate = (path, params = {}) => {
		params.id = typeof params.id === 'undefined' ? null : params.id;
		dispatch(navigateCC({ path, params }));
	};

	navigate.exit = () => {
		dispatch(exitCC());
		dispatch(change({ position: 0 }));
	};

	navigate.stay = () => {
		dispatch(stayCC());
	};

	navigate.enterLibrary = () => {
		dispatch(enterLibrary());
	};

	return navigate;
};

export default useNavigateCC;
