import RouteMetadata from './route-metadata';
import withLayout from '../withLayout';

const RootPage = () => null;

// Local store for the routes.
const routesRegistry = [];

/**
 * Register a route into the registry.
 *
 * @param {Object} params - Route parameters.
 */
export function registerRoute(params) {
	const route = new RouteMetadata(params);
	routesRegistry.push(route);
}

export function getRegisteredRootRoutes() {
	return routesRegistry.filter((r) => r.root).map((r) => ({
		...r,
		Component: withLayout(r.Component, r),
	}));
}

/**
 * Retrieve all registered routes.
 *
 * @returns {Array<RouteMetadata>} Array of RouteMetadata objects.
 */
export function getRegisteredRoutes() {
	// .filter((r) => !r.root)
	return routesRegistry.map((r) => ({
		...r,
		Component: r.root ? RootPage : withLayout(r.Component, r),
	}));
}
