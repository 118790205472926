import React, {
	memo, useEffect, useRef, useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import useTheme from 'rds/theme/useRdsTheme';
import waitFor from 'utils/wait-for';
import clsx from 'clsx';
import Tab from './Tab';

const SCROLL_INCREMENT = 6;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: theme.spacing(4.5),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'stretch',
		zIndex: 1,
	},
	tabsContainer: {
		width: '100%',
		overflowX: 'hidden',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		opacity: 0,
		transition: 'all 0.1s',
		pointerEvents: 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	tabsUnderline: {
		position: 'absolute',
		height: 0.5,
		bottom: -theme.spacing(),
		opacity: 0.7,
		width: '100%',
	},
	underline: {
		position: 'absolute',
		height: 2,
		bottom: -theme.spacing(),
		transition: 'all 0.125s ease-in-out',
		boxShadow: '0px 2px 4px rgba(50, 56, 62, 0.25)',
		marginLeft: 2,
	},
	hidden: {
		display: 'none',
	},
}));

const addPadding = (container) => {
	container.style.paddingLeft = `${window.innerWidth / 2}px`;
	container.style.paddingRight = `${window.innerWidth / 2}px`;
};

class Animation {
	async run({
		rootRef,
		underlineRef,
		selected,
		firstLoad,
	}, cb) {
		if (firstLoad) {
			await waitFor(400);
			this.navigate(rootRef.current, underlineRef.current, selected, firstLoad);
			await waitFor(100);
			if (!rootRef.current) { return; }
			this.navigate(rootRef.current, underlineRef.current, selected, firstLoad);
			await waitFor(100);
			if (!rootRef.current) { return; }
			this.navigate(rootRef.current, underlineRef.current, selected, firstLoad);
			await waitFor(100);
			if (!rootRef.current) { return; }
			rootRef.current.style.opacity = 1;
			// await waitFor(200);
		}

		this.navigate(rootRef.current, underlineRef.current, selected, firstLoad);

		if (cb) {
			cb();
		}
	}

	transformToCenter(container, myTarget, disableAnimation) {
		if (this.canceled) {
			return;
		}

		setTimeout(() => {
			const { x, width } = myTarget.getBoundingClientRect();
			const elementCenter = x + ((width / 2));
			const center = container.getBoundingClientRect().width / 2;
			const diff = center - elementCenter;

			if (disableAnimation) {
				container.scrollLeft -= diff;
				return;
			}

			const increment = Math.abs(diff) >= SCROLL_INCREMENT
				? SCROLL_INCREMENT
				: Math.abs(diff);

			if (diff < 0) {
				container.scrollLeft += increment;
			} else {
				container.scrollLeft -= increment;
			}

			if (Math.abs(diff) > 1) {
				this.transformToCenter(container, myTarget);
			}
		}, 3);
	}

	navigate(container, underline, selected, disableAnimation) {
		if (!underline?.parentElement?.children[0]?.children || this.canceled) {
			return;
		}

		const div = underline.parentElement.children[0].children[selected];
		const rect = div.getBoundingClientRect();
		const { width } = rect;
		underline.style.width = `${width - 32}px`;
		this.transformToCenter(container, div, disableAnimation);
	}

	cancel() {
		this.canceled = true;
	}
}

const Tabs = ({
	disabledUnderline,
	labels,
	onSelect,
	justifyContent,
	selected,
	showTabs,
	variant = 'primary',
	style,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const underlineRef = useRef();
	const rootRef = useRef();
	const [firstLoad, setFirstLoad] = useState(true);
	const rootStyles = {};

	if (justifyContent) {
		rootStyles.justifyContent = justifyContent;
	}

	const color = theme.palette.text[variant];

	useEffect(() => {
		if (rootRef?.current) {
			addPadding(rootRef.current);
		}
	}, [rootRef]);

	useEffect(() => {
		const animation = new Animation();
		animation.run({
			selected,
			underlineRef,
			rootRef,
			firstLoad,
		}, () => {
			if (firstLoad) {
				setFirstLoad(false);
			}
		});

		return () => {
			animation.cancel();
		};
	}, [firstLoad, labels, selected, underlineRef, rootRef]);

	const rootKlass = showTabs ? classes.root : clsx(classes.root, classes.hidden);

	return (
		<div
			className={rootKlass}
			style={style || rootStyles}
		>
			<div ref={rootRef} className={classes.tabsContainer}>
				{labels.map((label, i) => (
					<Tab
						key={i}
						color={color}
						onClick={() => { onSelect && onSelect(i, { label, index: i }); }}
						selected={selected === i}
						label={label}
						spacing={theme.spacing()}
					/>
				))}
			</div>
			<div
				ref={underlineRef}
				className={classes.underline}
				style={{
					background: color,
				}}
			/>

			{
				disabledUnderline
					? null
					: (
						<div
							className={classes.tabsUnderline}
							style={{ background: color }}
						/>
					)
			}
		</div>
	);
};

export default memo(Tabs);
