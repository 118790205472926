const ANIMATE_TIME = 125;
const MAX_SCALE = 3;

export default class Cropper {
	constructor({
		image,
		container,
		croppingRectangle,
		sourceDimensions,
	}) {
		this._image = image;
		this._container = container;
		this._croppingRectangle = croppingRectangle;
		this._sourceDimensions = sourceDimensions;

		this._animating = false;
		this._scale = 1;
		this._lastDragE = null;
		this._lastRepositionE = null;

		const { left, top } = this._image.getBoundingClientRect();
		this._dimensions = { left, top };

		this._container.addEventListener('touchmove', this._touchmove.bind(this));
		this._container.addEventListener('touchend', this._touchend.bind(this));
		this._setScale();
	}

	get _drawScale() {
		return this._sourceDimensions.width / this._image.width;
	}

	_animate(fn, timer) {
		return new Promise((resolve) => {
			this._animating = true;
			this._image.style.transition = `all ${timer / 1000}s ease-out`;

			fn();
			setTimeout(() => {
				this._image.style.removeProperty('transition');
				this._animating = false;
				resolve();
			}, timer);
		});
	}

	_setPositions() {
		this._image.style.top = `${this._dimensions.top}px`;
		this._image.style.left = `${this._dimensions.left}px`;
	}

	_setScale() {
		const t = this._image.style.transform.split(' ');
		const newT = [];

		let updated = false;
		const newScale = `scale(${this._scale})`;

		t.forEach((i) => {
			if (i.slice(0, 5) === 'scale') {
				newT.push(newScale);
				updated = true;
			} else {
				newT.push(i);
			}
		});

		if (!updated) {
			newT.push(newScale);
		}

		this._image.style.transform = newT.join(' ');
	}

	_onReposition(e) {
		if (!this._lastRepositionE) {
			this._lastRepositionE = e;
			return;
		}

		const touch = e.touches[0];
		const lastTouch = this._lastRepositionE.touches[0];

		const { clientX, clientY } = touch;
		const { clientX: lastClientX, clientY: lastClientY } = lastTouch;

		const dx = clientX - lastClientX;
		const dy = clientY - lastClientY;

		if (this._scale !== 1) {
			this._dimensions.left += dx;
		}
		this._dimensions.top += dy;

		this._setPositions();

		this._lastRepositionE = e;
	}

	_onScale(e) {
		if (!this._lastDragE) {
			this._lastDragE = e;
			return;
		}

		const [touchOne, touchTwo] = e.touches;
		const [lastTouchOne, lastTouchTwo] = this._lastDragE.touches;

		const dx = touchOne.clientX - touchTwo.clientX;
		const dy = touchOne.clientY - touchTwo.clientY;
		const distance = Math.sqrt((dx * dx) + (dy * dy));

		const ldx = lastTouchOne.clientX - lastTouchTwo.clientX;
		const ldy = lastTouchOne.clientY - lastTouchTwo.clientY;
		const lastDistance = Math.sqrt((ldx * ldx) + (ldy * ldy));

		const dScale = distance / lastDistance;
		this._scale *= dScale;

		if (this._scale < 1) {
			this._scale = 1;
		}
		if (this._scale >= MAX_SCALE) {
			this._scale = MAX_SCALE;
		}

		this._setScale();

		this._lastDragE = e;
	}

	_touchmove(e) {
		if (e.touches.length === 1) {
			this._onReposition(e);
		} else if (e.touches.length === 2) {
			this._onScale(e);
		}
	}

	async _bouncebackScale() {
		if (this._scale < 1) {
			this._scale = 1;
			await this._animate(this._setScale.bind(this), ANIMATE_TIME);
		}
	}

	async _bouncebackPosition() {
		const rect = this._image.getBoundingClientRect();

		// if the left edge is to the right of the left edge boundary
		if (rect.left > (this._croppingRectangle.left)) {
			const scaleDiff = rect.width - (rect.width * (1 / this._scale));
			this._dimensions.left = this._croppingRectangle.left + (scaleDiff / 2);
		}

		// if the right edge is to the left of the right edge boundary
		if (rect.right < this._croppingRectangle.right) {
			this._dimensions.left += (this._croppingRectangle.right - rect.right);
		}

		// if the top edge is above below the top edge boundary
		if (rect.top > this._croppingRectangle.top) {
			const scaleDiff = rect.height - (rect.height * (1 / this._scale));
			this._dimensions.top = this._croppingRectangle.top + (scaleDiff / 2);
		}

		// if the bottom edge is above abvoe the bottom edge boundary
		if (rect.bottom < this._croppingRectangle.bottom) {
			this._dimensions.top += (this._croppingRectangle.bottom - rect.bottom);
		}

		await this._animate(this._setPositions.bind(this), ANIMATE_TIME);
	}

	async _bounceback() {
		await this._bouncebackPosition();
	}

	_touchend() {
		this._lastDragE = null;
		this._lastRepositionE = null;

		this._bounceback();
	}

	crop() {
		return new Promise(async (resolve) => {
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');

			const {
				top,
				left,
				height,
				width,
			} = this._croppingRectangle;

			const {
				top: imageTop,
				left: imageLeft,
				height: imageHeight,
				width: imageWidth,
			} = this._image.getBoundingClientRect();

			const offsetLeft = (imageLeft / imageWidth) * (imageWidth * (1 / this._scale));
			const offsetTop = (imageTop / imageHeight) * (imageHeight * (1 / this._scale));
			const sx = (left * (1 / this._scale)) - offsetLeft;
			const sy = (top * (1 / this._scale)) - offsetTop;
			const sWidth = width * (1 / this._scale);
			const sHeight = height * (1 / this._scale);
			const dWidth = width;
			const dHeight = height;

			canvas.height = height * this._drawScale;
			canvas.width = width * this._drawScale;

			context.drawImage(
				this._image,
				sx * this._drawScale,
				sy * this._drawScale,
				sWidth * this._drawScale,
				sHeight * this._drawScale,
				0,
				0,
				dWidth * this._drawScale,
				dHeight * this._drawScale,
			);

			canvas.toBlob((blob) => {
				resolve(blob);
				canvas?.remove();
			});
		});
	}

	destroy() {
		this._container.removeEventListener('touchmove', this._touchmove);
		this._container.removeEventListener('touchend', this._touchend);
	}
}
