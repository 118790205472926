import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api, { CancelToken } from 'services/api';
import handleSliceError from 'utils/handle-slice-error';

export const requestV0Profile = createAsyncThunk(
	'requestV0Profile',
	async (_, {
		signal, rejectWithValue,
	}) => {
		const source = CancelToken.source();
		signal.addEventListener('abort', () => {
			source.cancel();
		});

		try {
			const response = await api.get('/accounts/profile', {
				cancelToken: source.token,
			});

			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		profile: null,
		errors: null,
		loading: false,
	},
	reducers: {},
	extraReducers: {
		[requestV0Profile.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestV0Profile.fulfilled]: (state, action) => {
			state.profile = action.payload;
			state.loading = false;
			state.errors = null;
		},
		[requestV0Profile.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = profileSlice;
export { name, reducer, actions };

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);
export const getProfile = createSelector(getSlice, (slice) => slice?.profile);

injectReducer(name, reducer);
