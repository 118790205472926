import { notifyErrors } from 'app/slices/notifications/notifications';
import queueFetch from 'services/queue-fetch';
import { devMode, buildVersion } from 'config';
import getErrorsFromRejectedRequest from './get-errors-from-rejected-request';

export default (error, options = {}) => {
	const notifyUser = typeof options.notifyUser === 'undefined' ? true : options.notifyUser;
	const { defaultMessage } = options;
	const { isAxiosError } = error;

	if (devMode) {
		// eslint-disable-next-line
		console.error(error);
	}

	if (isAxiosError) {
		const errors = getErrorsFromRejectedRequest(error, defaultMessage);
		if (notifyUser) {
			notifyErrors(errors);
		}
		return errors;
	}

	if (!error?.message) {
		return null;
	}

	queueFetch('/log/pwa', {
		method: 'POST',
		json: [{
			level: 'error',
			payload: {
				message: 'slice error',
				stack: error?.stack,
				errorMessage: error.message,
				buildVersion,
				environment: devMode ? 'dev' : 'prod',
			},
		}],
	});

	const errors = getErrorsFromRejectedRequest(error, defaultMessage);
	notifyErrors(errors);

	return errors;
};
