import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core';
import { reset, getLoading } from 'app/slices/destinations-search';
import { changeDestinationLayout } from 'app/slices/destinations-search/layout';
import { resetForm } from 'app/slices/destinations-search/form';
import { getSuggestedDestinations, requestSuggestedDestinations } from 'app/slices/destinations-search/suggested-destinations';
import { change } from 'app/slices/content-creation/editor/layout';
import Result from 'routes/DestinationSearchModal/Search/Result';
import WaveSkeleton from 'components/WaveSkeleton';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'left',
	},
	header: {
		paddingBottom: theme.spacing(4),
		paddingTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

export default ({ open, onClick, showSelected }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const destinations = useSelector(getSuggestedDestinations);
	const loading = useSelector(getLoading);
	const theme = useTheme();
	useEffect(() => {
		dispatch(requestSuggestedDestinations());
	}, []);

	if (!open) {
		return null;
	}

	const style = {};

	if (showSelected) {
		style.marginTop = theme.spacing(52);
	} else {
		style.marginTop = theme.spacing(42);
	}

	return (
		<div className={classes.root} style={style}>
			<div className={classes.header} />
			{!loading
				&& destinations?.map((d) => (
					<Result
						key={d.uid}
						{...d}
						onMouseDown={() => {
							onClick(d);
							dispatch(reset());
							dispatch(resetForm());
							dispatch(changeDestinationLayout({ modal: false }));
							dispatch(change({ showControls: true }));
						}}
					/>
				))}
			{loading
			&& (
				[...Array(5)].map((s, index) => (
					<WaveSkeleton style={{ height: 12, marginBottom: 8 }} key={index} />
				))
			)}
		</div>
	);
};
