import { registerRoute } from 'Router/registry';
import { requestIndexExperiences } from 'app/slices/index';
import store from 'services/store';
import Skeleton from 'rds/components/Skeletons/SecondaryPage';
import { register } from 'features/ExperienceModal/ECRProvider';
import homeEcr from 'app/slices/index/ecr';
import HomeHeader from './Header';
import Home from './Home';
import './Terms';

register('home', homeEcr);

const homeRequiredData = [
	({ dispatch }) => {
		const { experiences } = store.getState().index;

		if (experiences.length) {
			return new Promise((resolve) => resolve());
		}

		return dispatch(requestIndexExperiences());
	},
];

registerRoute({
	authorization: true,
	Component: Home,
	Skeleton,
	routeOptions: {
		path: '/',
	},
	ecrSelectorName: 'home',
	loadData: homeRequiredData,
	authorizePage: '/landing',
	Header: HomeHeader,
	transition: 'slide',
	navigationMenu: '/home',
	root: true,
});
