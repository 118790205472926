import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	CLIPS_THRESHOLD,
	selectClipCount,
	selectShowControls,
	showClipTray,
	lockClipTray,
	unlockClipTray,
} from 'app/slices/content-creation/experience';
import { getRecordingStartTime } from 'app/slices/content-creation/create/capture';
import { useQueryParam } from 'app/hooks/useQueryParams';
import CaptureButton from '../CaptureButton/CaptureButton';
import Decagon from '../Decagon/Decagon';
import TrashCan from './TrashCan';

const useStyles = makeStyles((theme) => ({
	root: {},
	bottomGradient: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		height: 60,
		background: 'rgba(35, 47, 46, 0.8)',
	},
	content: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
	},
	bottom: {
		height: 200,
		bottom: 0,
		left: 0,
		position: 'absolute',
		right: 0,
		zIndex: 3,
		pointerEvents: 'none',
	},
	tray: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 'calc(100vh -100px)',
		pointerEvents: 'all',
	},
	notification: {
		position: 'absolute',
	},
	info: {
		position: 'fixed',
		zIndex: 2,
		bottom: theme.spacing(),
		right: theme.spacing(),
		left: theme.spacing(),
		display: 'flex',
		alignItems: 'center',
		background: theme.colors.neutral.C900,
		padding: theme.spacing(2, 2.5),
	},
	infoText: {
		marginLeft: theme.spacing(2),
	},
}));

function PageLayout({
	children,
	captureMode,
	bottomControl,
	videoRef,
	params,
	hasPermissions,
}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const recording = useSelector(getRecordingStartTime);
	const showControls = useSelector(selectShowControls);
	const clipCount = useSelector(selectClipCount);
	const redirectTo = useQueryParam('redirectTo');

	useEffect(() => {
		if (clipCount === CLIPS_THRESHOLD && redirectTo !== 'CC02') {
			dispatch(showClipTray());
			dispatch(lockClipTray());
		} else if (clipCount < CLIPS_THRESHOLD) {
			dispatch(unlockClipTray());
		}
	}, [clipCount, redirectTo]);

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				{children}
			</div>
			<div className={classes.bottom}>
				<>
					{!recording && bottomControl}
					{!recording && showControls && <Decagon hasPermissions={hasPermissions} mode={captureMode} />}
					{showControls && <CaptureButton params={params} videoRef={videoRef} mode={captureMode} hasPermissions={hasPermissions} />}
				</>
				<TrashCan />
			</div>
		</div>
	);
}

export default PageLayout;
