import { registerRoute } from 'Router/registry';
import registerRedirects from 'Router/register-redirects';
import Skeleton from 'rds/components/Skeletons/SecondaryPage';
import EditUsername from './EditUsername';
import NotificationsSettings from './Notifications';
import GroupNotificationsSettings from './Notifications/PushNotifications/Group';
import AccountSettings from './AccountSettings';

registerRedirects([
	['/:@username/settings/notifications/:group', '/my-account/settings/notifications'],
	['/:username/settings/notifications/:group', '/my-account/settings/notifications'],
	['/:@username/settings/notifications', '/my-account/settings/username/notifications'],
	['/:username/settings/notifications', '/my-account/settings/username/notifications'],
	['/:@username/settings/username', '/my-account/settings/username'],
	['/:username/settings/username', '/my-account/settings/username'],
	['/:@username/settings', '/my-account/settings'],
	['/:username/settings', '/my-account/settings'],
]);

registerRoute({
	authorization: true,
	Component: EditUsername,
	Skeleton,
	routeOptions: {
		path: '/my-account/settings/username',
	},
	transition: 'slide',
	navigationMenu: '/my-account',
});

registerRoute({
	authorization: true,
	Component: NotificationsSettings,
	Skeleton,
	routeOptions: {
		path: '/my-account/settings/notifications',
	},
	transition: 'slide',
	navigationMenu: '/my-account',
});

registerRoute({
	authorization: true,
	Component: GroupNotificationsSettings,
	Skeleton,
	routeOptions: {
		path: '/my-account/settings/notifications/:group',
	},
	transition: 'slide',
	navigationMenu: '/my-account',
});

registerRoute({
	authorization: true,
	Component: AccountSettings,
	Skeleton,
	routeOptions: {
		path: '/my-account/settings',
	},
	transition: 'slide',
	navigationMenu: '/my-account',
});
