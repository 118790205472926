import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core';
import Modal from 'rds/components/Modal';
import { InlineEditableArea } from 'rds/components/InlineEditable';
import Hexagon from 'rds/components/Hexagon';
import {
	getSuggestedIdentifiers,
	requestSuggestedIdentifiers,
	requestUpdateIdentifiers,
} from 'app/slices/user-account-v2/accounts';
import SliderModal from 'rds/components/SliderModal';
import { useNavbarState } from 'app/hooks/useNavbarState';

const DARK_ACTIVE_GRADIENT = 'linear-gradient(180deg, #20D2B2 0%, #FB7848 50%, #CDBB0A 100%)';
const MAX_LENGTH = 30;
const TIME_OUT = 500;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		right: 'calc(50% - 45px)',
		top: theme.spacing(25),
	},
	editableField: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'absolute',
		height: '94%',
		width: '60%',
		marginLeft: '20%',
		whiteSpace: 'normal',
		zIndex: 5,
		marginRight: theme.spacing(),

	},
	suggestions: {
		position: 'fixed',
		width: '100%',
		bottom: theme.spacing(10),
		left: theme.spacing(2),
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		paddingTop: theme.spacing(2),
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	identifier: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'absolute',
		height: '92%',
		width: '90%',
		whiteSpace: 'normal',
		overflow: 'hidden',
		marginLeft: '5%',
		zIndex: 3,
	},
	container: {
		marginRight: theme.spacing(2),
		display: 'inline-block',
	},
	item: {
		border: `1px solid ${theme.colors.neutral.C300}`,
		marginRight: theme.spacing(2),
		padding: theme.spacing(2),
	},

}));

export default ({
	open,
	onClose,
	editingIdentifier,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [name, setName] = useState('');
	const suggestedIdentifiers = useSelector(getSuggestedIdentifiers);
	const filteredIdentifiers = suggestedIdentifiers ? suggestedIdentifiers.filter((identifier) => identifier?.title?.toLowerCase().startsWith(name?.toLowerCase())) : [];
	const navbarState = useNavbarState();

	useEffect(() => {
		if (open) {
			navbarState.close('high-priority', TIME_OUT);
		} else {
			navbarState.open('high-priority', TIME_OUT);
		}
	}, [open]);

	useEffect(() => {
		if (editingIdentifier) {
			setName(editingIdentifier.title);
		}
	}, [editingIdentifier]);

	useEffect(() => {
		if (!suggestedIdentifiers.length) {
			dispatch(requestSuggestedIdentifiers());
		}
	}, [suggestedIdentifiers, open]);

	const handleClick = () => {
		dispatch(requestUpdateIdentifiers({
			previousIdentifier: editingIdentifier,
			newIdentifier: name.trim(),
		}));
		onClose();
	};

	return (
		<SliderModal open={open}>
			{open
			&& (
				<Modal
					top={{
						back: {
							label: 'Cancel',
							onClick: onClose,
						},
						next: {
							label: 'Save',
							onClick: () => {
								if (!name || name === editingIdentifier?.title) {
									onClose();
									return;
								}
								handleClick();
							},
						},
					}}
				>
					<div className={classes.root}>
						<Hexagon
							drawLabel={() => (
								<div className={classes.editableField}>
									<InlineEditableArea
										open
										maxLength={MAX_LENGTH}
										value={name}
										style={{ maxWidth: 90 }}
										onChange={(e) => {
											setName(e.target.value.slice(0, MAX_LENGTH));
										}}
										fixedMaxLengthCaption
										disableUnderline
									/>
								</div>
							)}
							width={90}
							height={104}
							hexagonStyles={{
								background: theme.palette.background.page,
							}}
							selected
							selectedBorder={DARK_ACTIVE_GRADIENT}
						/>
					</div>

					<div
						className={classes.suggestions}
					>
						{
							filteredIdentifiers && filteredIdentifiers.map((identifier, i) => (
								<div className={classes.container} key={i}>
									<div className={classes.item} onClick={() => { setName(identifier.title); }}>{identifier.title}</div>
								</div>
							))
						}
					</div>
				</Modal>
			)}
		</SliderModal>
	);
};
