import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getCreateNavigation } from 'app/slices/content-creation/create/device-library/state';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		right: 0,
		left: 0,
		bottom: 0,
		background: theme.palette.background.page,
		zIndex: 10,
	},
	offScreen: {
		top: '100%',
		transition: 'none',
	},
	instant: {
		top: 0,
		transition: 'none',
	},
	slideIn: {
		top: 0,
		transition: 'top 400ms',
	},
	contents: {
		position: 'relative',
	},
	hide: {
		display: 'none',
	},
	display: {
		display: 'block',
	},
}));

const DeviceLibraryModal = ({ show, children }) => {
	const classes = useStyles();
	const [transition, setTransition] = useState('offScreen');

	const { slide } = useSelector(getCreateNavigation);

	useEffect(() => {
		if (!show) {
			setTransition('offScreen');
		} else if (slide) {
			setTransition('slideIn');
		} else {
			setTransition('instant');
		}
	}, [show, slide]);

	const display = transition === 'offScreen' ? 'hide' : 'display';

	return (
		<div className={`${classes.root} ${classes[transition]}`}>
			<div className={`${classes.contents} ${classes[display]}`}>
				{children}
			</div>
		</div>
	);
};

export default DeviceLibraryModal;
