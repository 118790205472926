import React from 'react';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';
import MenuItem from './MenuItem';

const useStyles = makeStyles({
	root: {
		display: 'flex',
	},

});
export default ({
	onClose,
	handleDelete,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	return (
		<div className={classes.root}>
			<MenuItem
				label="Delete"
				background={theme.colors.error.C300}
				onClick={() => {
					handleDelete();
					onClose();
				}}
			/>
		</div>
	);
};
