export default {
	root: (theme) => ({
		display: 'inline',
		position: 'relative',
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor:
			theme.palette.type === 'light'
				? theme.colors.neutral.C700
				: theme.colors.neutral.C200,
		marginBottom: theme.spacing(2),
		paddingBottom: 4,
	}),

	input: (theme) => ({
		resize: 'none',
		overflow: 'hidden',
		border: 'none',
		background: 'none',
		boxShadow: 'none',
		outline: 'none',
		paddingBottom: 2,
		marginTop: theme.spacing(2),
		paddingLeft: 0,
		paddingRight: 0,
		textIndent: 0,
		...theme.typography.body1,
		fontSize: theme.typography.body1.fontSize,
		textAlign: 'center',
		whiteSpace: 'pre-wrap',
		color: theme.palette.text.primary,
		lineHeight: 1,
		'&::placeholder': {
			color: theme.palette.text.primary,
		},
	}),

	inputContrast: (theme) => ({
		color: theme.palette.text.contrast,
		'&::placeholder': {
			color: theme.palette.text.contrast,
		},
	}),

	inputPrimary: (theme) => ({
		color: theme.palette.text.primary,
		'&::placeholder': {
			color: theme.palette.text.primary,
		},
	}),

	inputSecondary: (theme) => ({
		color: theme.palette.text.secondary,
		'&::placeholder': {
			color: theme.palette.text.secondary,
		},
	}),

	shadow: {
		position: 'absolute',
		top: 0,
		left: 0,
		visibility: 'hidden',
		height: 0,
		overflow: 'scroll',
		whiteSpace: 'pre-wrap',
	},
};
