import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { endSession } from 'app/slices/authentication/session/session';
import SubmitButton from '../components/SubmitButton/SubmitButton';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 11,
		background: `${theme.colors.neutral.C900}64`,
		backdropFilter: 'blur(16px)',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		width: 288,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 'calc(28vh)',
	},
	message: {
		marginBottom: theme.spacing(8),
		color: theme.colors.neutral.C000,
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography className={classes.message} variant="h4">An account cannot be created for you</Typography>
				<SubmitButton onClick={() => { dispatch(endSession()); }}>OK</SubmitButton>
			</div>
		</div>
	);
};
