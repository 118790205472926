import React from 'react';

function ShowHide({
	transitionRef,
	children,
}) {
	return (
		<div ref={transitionRef} className="fast-fade">
			{children}
		</div>
	);
}

export default ShowHide;
