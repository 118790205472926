import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getConstants } from 'app/slices/app/app';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import EachRecommendedFor from './EachRecommendedFor';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(8),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginBottom: theme.spacing(4),
	},
	title: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		marginBottom: theme.spacing(3),
	},
	icon: {
		marginRight: theme.spacing(3),
	},
	container: {
		display: 'flex',
	},
}));

export default ({ ...props }) => {
	const classes = useStyles();
	const constants = useSelector(getConstants);

	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<IconV3
					icon="CircleCheck"
					style={{
						marginRight: 12,
					}}
					size={20}
					containerSize={20}
				/>
				<Typography variant="subtitle1">Recommended for</Typography>
			</div>
			<div className={classes.container}>
				{constants && constants.recommendedFor.map((r) => (
					<div key={r.uid}>
						<EachRecommendedFor {...props} key={r.uid} group={r} />
					</div>
				))}
			</div>
		</div>
	);
};
