import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import scrollEmitter from 'layout/scrollEmitter';
import Dot from './Dot';

const useStyles = makeStyles(({
	item: {
		position: 'relative',
		paddingRight: '5%',
		paddingLeft: '5%',
		paddingTop: 10,
		paddingBottom: 10,
		zIndex: 1,
	},
}));

const Item = ({
	active,
	dot,
	to,
	iconSize,
	icon,
	Icon,
	onClick,
	...rest
}) => {
	const classes = useStyles();
	const location = useLocation();

	const handleTap = () => {
		if (active && to === location.pathname) {
			scrollEmitter.emit('scrollToTop', { speed: 'slow' });
		}
		if (!active && onClick) {
			onClick();
		}
	};

	return (
		<div className={classes.item} onClick={handleTap}>
			<Link to={to} {...rest}>
				{Icon ? (
					<Icon />
				) : (
					<>
						<IconV3
							icon={icon}
							size={iconSize}
							containerSize={24}
							color={!active && 'neutralC500'}
						/>
						{dot && <Dot />}
					</>
				)}
			</Link>
		</div>
	);
};

export default memo(Item);
