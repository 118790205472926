import { registerRoute } from 'Router/registry';
import ForgotPassword from 'routes/Auth/ForgotPassword/ForgotPassword';
import ForgotPasswordExpired from 'routes/Auth/ForgotPassword/Expired';
import ForgotPasswordReset from 'routes/Auth/ForgotPassword/Reset';
import ForgotPasswordSent from 'routes/Auth/ForgotPassword/Sent';
import ForgotPasswordSuccess from 'routes/Auth/ForgotPassword/Success';
import resetBackground from 'routes/Auth/assets/reset-password/reset-background.webp';
import signUpBackground from 'routes/Auth/assets/signup/background.webp';

registerRoute({
	authorization: undefined,
	Component: ForgotPassword,
	routeOptions: {
		path: '/forgot-password',
	},
	Background: signUpBackground,
	transition: 'slide',
});

registerRoute({
	authorization: undefined,
	Component: ForgotPasswordExpired,
	routeOptions: {
		path: '/forgot-password/expired',
	},
	Background: signUpBackground,
	transition: 'slide',
});

registerRoute({
	authorization: undefined,
	Component: ForgotPasswordSent,
	routeOptions: {
		path: '/forgot-password/sent',
	},
	Background: signUpBackground,
	transition: 'slide',
});

registerRoute({
	authorization: undefined,
	Component: ForgotPasswordSuccess,
	routeOptions: {
		path: '/forgot-password/reset/success',
	},
	Background: resetBackground,
	transition: 'slide',
});

registerRoute({
	authorization: undefined,
	Component: ForgotPasswordReset,
	routeOptions: {
		path: '/forgot-password/reset/:token',
	},
	Background: resetBackground,
	transition: 'slide',
});
