import { registerRoute } from 'Router/registry';
import UnsubscribeEmail from './UnsubscribeEmail';

registerRoute({
	authorization: undefined,
	Component: UnsubscribeEmail,
	routeOptions: {
		path: '/unsubscribe/email/:token',
	},
	transition: 'slide',
});
