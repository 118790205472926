import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNavigateCC from 'app/hooks/useNavigateCC';
import NotFound from 'routes/NotFound/NotFound';
import {
	getCreateNavigation, requestPermissions, getGateway, disableGateway,
} from 'app/slices/content-creation/create/device-library/state';

import DeviceLibrary from 'routes/ContentCreation/Create/Camera/DeviceLibrary';
import { getPort } from 'app/android-service';
import { gotoPermissions } from 'app/android-service/util';
import AudioCameraAccess from '../../Permisions/AudioCamera/AudioCameraAccess';
import TextComponent from '../TextComponent/TextComponent';
import Camera from '../Camera/Camera';

const requestPermission = (name) => new Promise((resolve) => {
	navigator.permissions.query({ name })
		.then((permissionStatus) => {
			resolve(permissionStatus);
		}).catch(() => {
			resolve(false);
		});
});

const getPermissions = async () => {
	const microphone = await requestPermission('microphone');
	const camera = await requestPermission('camera');

	return {
		microphone,
		camera,
	};
};

export default ({
	params,
	permissions,
	setPermissions,
	hasPermissions,
}) => {
	const dispatch = useDispatch();
	const navigation = useSelector(getCreateNavigation);
	const gateway = useSelector(getGateway);
	const navigateCC = useNavigateCC();
	const { tab } = params;

	const doNotRender = !params.subTab && navigation.open && !navigation.slide;

	let content;

	switch (tab) {
	case 'text':
		content = <TextComponent params={params} create tab={tab} />;
		break;
	case 'photo':
		content = <Camera doNotRender={doNotRender} hasPermissions={hasPermissions} tab={tab} params={params} />;
		break;
	case 'library':
		content = <Camera doNotRender={doNotRender} hasPermissions={hasPermissions} tab={tab} params={params} />;
		break;
	default:
		return <NotFound />;
	}

	useEffect(async () => {
		if (params.subTab === 'library-gateway') {
			if (!gateway) {
				navigateCC('create', {
					tab,
				});
				return;
			}

			// not supported
			if (getPort() === null) {
				navigateCC('create', {
					tab,
				});
				dispatch(disableGateway());
			}
			// refresh permissions
			const response = await dispatch(requestPermissions());

			// if proceed, go to library
			if (response.payload) {
				navigateCC('create', {
					tab,
					subTab: 'library',
				});
				dispatch(disableGateway());
			} else {
				gotoPermissions();
			}
		}
	}, [tab, params.subTab, gateway]);

	useEffect(() => {
		const get = async () => {
			const p = await getPermissions();

			const onChange = () => {
				p.camera.onchange = null;
				p.microphone.onchange = null;
				get();
			};

			p.camera.onchange = onChange;
			p.microphone.onchange = onChange;

			setPermissions({
				camera: p.camera.state === 'granted',
				microphone: p.microphone.state === 'granted',
			});
		};
		get();
	}, []);

	if (!permissions) {
		return null;
	}

	return (
		<>
			{content}
			{!hasPermissions && !doNotRender
				&& (
					<AudioCameraAccess
						{...permissions}
						onChange={(newPermissions) => {
							setPermissions({ ...permissions, ...newPermissions });
						}}
					/>
				)}
			<DeviceLibrary show={params.subTab === 'library' || params.tab === 'library'} />
		</>
	);
};
