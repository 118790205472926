import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Avatar from 'rds/components/Avatar';
import ProgressiveThumbnail from 'rds/components/ProgressiveThumbnail/ProgressiveThumbnail';
import useExpandECR from 'features/ExperienceModal/useExpandECR';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
		marginRight: theme.spacing(1.5),
		marginLeft: theme.spacing(1.5),
		padding: 0,
		borderRadius: 5,
		marginBottom: theme.spacing(4),
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'end',
		justifyContent: 'center',
		textDecoration: 'none',
		position: 'relative',
	},
	avatarContainer: {
		position: 'absolute',
		marginBottom: theme.spacing(-4),
	},
	infoContainer: {
		position: 'absolute',
		textAlign: 'left',
		left: theme.spacing(),
		right: theme.spacing(),
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(5),
		width: '100%',
		zIndex: 2,
	},
	title: {
		overflow: 'hidden',
		wordBreak: 'break-word',
		textAlign: 'left',
		textOverflow: 'ellipsis',
		lineClamp: 2,
		display: 'box',
		boxOrient: 'vertical',
		marginRight: theme.spacing(2),
	},
	destination: {
		overflow: 'hidden',
		wordBreak: 'break-word',
		textAlign: 'left',
		textOverflow: 'ellipsis',
		lineClamp: 2,
		display: 'box',
		boxOrient: 'vertical',
		color: theme.colors.neutral.C200,
	},
}));

export default ({
	user,
	src,
	id,
	title,
	destination,
}) => {
	const classes = useStyles();
	const expandECR = useExpandECR(id, src);

	return (
		<div
			onClick={expandECR}
			className={classes.root}
			data-experience-modal-id={id}
		>
			<ProgressiveThumbnail src={src} gradient home />
			<div className={classes.avatarContainer}>
				<Avatar size="small" src={user?.avatar?.small?.jpg} alt={user?.uuid} />
			</div>
			<div className={classes.infoContainer}>
				{title
				&& (
					<Typography
						variant="subtitle2"
						className={classes.title}
					>
						{title}
					</Typography>
				)}
				{destination
				&& (
					<Typography
						variant="body2"
						className={classes.destination}
					>
						{destination.title}
					</Typography>
				)}
			</div>
		</div>
	);
};
