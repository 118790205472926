import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import Link from 'rds/components/Link';

const useStyles = makeStyles((theme) => ({
	texts: {
		marginTop: theme.spacing(4),
		textAlign: 'center',
		zIndex: 3,
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		bottom: 4,
	},
	background: {
		paddingTop: theme.spacing(3.5),
		paddingBottom: theme.spacing(3.5),
		opacity: 0.7,
		background: theme.colors.neutral.C700,
		width: '100%',
		position: 'fixed',
		bottom: theme.spacing(4),
	},
	span: {
		color: theme.colors.neutral.C000,
		paddingRight: theme.spacing(),
	},
}));

export default ({
	text,
	...rest
}) => {
	const classes = useStyles();

	return (
		<>
			<div className={text ? null : classes.background} />
			<div className={classes.texts}>
				<Typography variant="subtitle2" {...rest}>
					<span className={classes.span}>Don't have an account?</span>
				</Typography>
				<Link to="/signup">
					Sign up
				</Link>

			</div>

		</>
	);
};
