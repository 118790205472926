import { createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import { requestTrustedExperiencesByCreator, requestTrustedCreators } from './thunks';

const initialState = {
	errors: null,
	trustedExperiences: [],
	trustedCreators: [],
	nextLink: null,
	prevLink: null,
	currentCreator: null,
	shouldReloadTrustedCreators: false,
};

const name = 'trustedCreators';

const trustedCreatorsSlice = createSlice({
	name,
	initialState,
	reducers: {
		change: (state, action) => {
			Object.assign(state, action.payload);
		},
		reset: () => ({ ...initialState }),
	},
	extraReducers: {
		[requestTrustedCreators.fulfilled]: (state, action) => {
			const { trustedCreators } = action.payload;
			state.trustedCreators = trustedCreators;
			state.errors = null;
		},
		[requestTrustedCreators.rejected]: (state, action) => {
			state.loadingMore = false;
			state.errors = action.payload;
		},
		[requestTrustedExperiencesByCreator.fulfilled]: (state, action) => {
			const {
				data,
			} = action.payload;
			const {
				_links,
				experiences,
			} = data;

			state.trustedExperiences = experiences;
			state.prevLink = _links?.prev?.href ? _links?.prev.href : null;
			state.nextLink = _links?.next?.href ? _links?.next.href : null;
		},

	},
});

export const {
	change,
	reset,
} = trustedCreatorsSlice.actions;

injectReducer(name, trustedCreatorsSlice.reducer);

export default trustedCreatorsSlice.reducer;
