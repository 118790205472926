import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import camelCase from 'camelcase';
import * as Icons from 'rds/icons';
import useTheme from 'rds/theme/useRdsTheme';

const useStyles = makeStyles({
	container: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
});

export default ({
	icon,
	containerHeight = 40,
	containerWidth = 40,
	width = undefined,
	height = undefined,
	variant = 'contrast',
	style = {},
	darkContrast,
	theme,
	...rest
}) => {
	const classes = useStyles();
	theme = theme || useTheme();

	let variantKey;
	if (variant === 'darkContrast') {
		variantKey = 'light';
	} else if (variant === 'lightContrast') {
		variantKey = 'dark';
	} else if (variant === 'contrast') {
		variantKey = theme.palette.type === 'dark'
			? 'light'
			: 'dark';
	} else {
		variantKey = variant;
	}

	const key = `${camelCase(icon, { pascalCase: true })}${camelCase(darkContrast ? 'dark' : variantKey, { pascalCase: true })}`;

	const SVGIcon = Icons[key];
	if (!SVGIcon) {
		throw new Error(`Could not find rhino icon ${key}`);
	}

	return (
		<Suspense fallback={null}>
			<div
				className={classes.container}
				style={{
					height: containerHeight,
					width: containerWidth,
					...style,
				}}
				{...rest}
			>
				<SVGIcon height={height} width={width} />
			</div>
		</Suspense>
	);
};
