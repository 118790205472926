import React from 'react';
import DestinationInfo from './DestinationInfo';
import DestinationThumbnail from './DestinationThumbnail';
import StatsAndAction from './StatsAndAction';

export default () => (
	<>
		<DestinationThumbnail />
		<DestinationInfo />
		<StatsAndAction />
	</>
);
