import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cslx from 'clsx';

const useStyles = makeStyles((theme) => ({
	active: {
		position: 'absolute',
		background: theme.colors.neutral.C500,
		boxShadow: '0px 2px 4px rgba(35, 47, 46, 0.25)',
		borderTopRight: 2,
		borderTopLeft: 2,
		borderBottomLeftRadius: 7,
		borderBottomRightRadius: 7,
		height: 7.56,
		width: 30.22,
		cursor: 'pointer',
		marginBottom: 5,
		zIndex: 3,
		textAlign: 'center',
	},
	selected: {
		background: theme.colors.neutral.C000,
	},
}));
function DecagonIndicator({ selected, ...style }) {
	const classes = useStyles();
	const klass = selected ? cslx(classes.active, classes.selected) : cslx(classes.active);
	return (
		<div>
			<div className={klass} style={{ ...style }} />
		</div>
	);
}

export default DecagonIndicator;
