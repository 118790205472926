import { createTheme } from '@material-ui/core/styles';
import main from './main';

export default (colors) => {
	const mainTheme = main(colors);

	return createTheme({
		...mainTheme,

		palette: {
			...mainTheme.palette,
			type: 'light',
			background: {
				...mainTheme.palette.background,
				page: '#fff',
				main: colors.neutral.C100,
				default: colors.neutral.C100,
				contrast: colors.neutral.C900,
				border: colors.neutral.C300,
			},
			text: {
				default: colors.neutral.C900,
				primary: colors.neutral.C900,
				secondary: colors.neutral.C400,
				contrast: colors.neutral.C000,
			},
			secondary: {
				main: colors.secondary.C700,
			},
			error: {
				main: colors.error.C200,
			},
			neutral: {
				light: colors.neutral.C000,
				main: colors.neutral.C200,
				dark: colors.neutral.C700,
			},
			contrast: {
				main: colors.neutral.C900,
				contrastText: colors.neutral.C000,
				boundary: colors.neutral.C200,
			},
			glass: {
				...mainTheme.palette.glass,
				main: colors.neutral.C000,
				contrastText: colors.neutral.C900,
			},
		},

		overrides: {
			...mainTheme.overrides,
			MuiButton: {
				...mainTheme.overrides.MuiButton,
				containedPrimary: {
					backgroundColor: colors.neutral.C900,
					color: colors.neutral.C000,
					'&$disabled': {
						...mainTheme.overrides.MuiButton.containedPrimary['&$disabled'],
						backgroundColor: colors.neutral.C200,
						color: colors.neutral.C500,
					},
				},
				outlinedPrimary: {
					...mainTheme.overrides.MuiButton.outlinedPrimary,
					borderColor: colors.primary.C700,
					color: colors.primary.C700,
					'&$disabled': {
						...mainTheme.overrides.MuiButton.outlinedPrimary['&$disabled'],
						borderColor: colors.primary.C700,
						color: colors.primary.C700,
					},
				},
				textPrimary: {
					...mainTheme.overrides.MuiButton.textPrimary,
					color: colors.primary.C700,
					'&$disabled': {
						...mainTheme.overrides.MuiButton.textPrimary['&$disabled'],
						color: colors.primary.C700,
					},
				},
			},
			MuiSvgIcon: {
				...mainTheme.overrides.MuiSvgIcon,
				colorPrimary: {
					color: colors.neutral.C000,
				},
				colorSecondary: {
					color: colors.neutral.C000,
				},
			},

		},

		rhino: {
			...mainTheme.rhino,
			textField: {
				...mainTheme.rhino.textField,
				main: {
					...mainTheme.rhino.textField.main,
					root: {
						...mainTheme.rhino.textField.main.root,
						boxShadow: `0px 4px 4px ${colors.neutral.C900}20`,
					},
				},
				variants: {
					...mainTheme.rhino.textField.variants,

					default: {
						...mainTheme.rhino.textField.variants.default,
						inputRoot: {
							background: colors.neutral.C900,
							color: colors.neutral.C000,
						},
						errorInputRoot: {
							background: colors.neutral.C700,
						},
						subtleInputRoot: {
							color: colors.neutral.C900,
						},
					},

					glass: {
						...mainTheme.rhino.textField.variants.glass,
						inputRoot: {
							...mainTheme.rhino.textField.variants.glass.inputRoot,
							background: colors.neutral.C900,
							color: colors.neutral.C000,
						},
						errorInputRoot: {
							background: colors.error.C500,
							color: colors.neutral.C000,
						},
					},
				},
			},
		},
	});
};
