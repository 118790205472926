import React from 'react';
import RequestProvider from 'rds/providers/Request';
import {
	requestNotifications,
	getErrors,
	getLoading,
	getPreferences,
} from 'app/slices/account-settings/notifications';

export default ({ children }) => (
	<RequestProvider
		requests={{
			notifications: {
				selector: getPreferences,
				action: requestNotifications,
				errors: getErrors,
				loading: getLoading,
			},
		}}
	>
		{children}
	</RequestProvider>
);
