import React from 'react';
import { useSelector } from 'react-redux';
import {
	getIsModalOpen,
	getRoute,
	getReportLabel,
	getFormTitle,
	getType,
	getTitle,
	getResourceId,
} from 'app/slices/reports/layout';
import SliderModal from 'rds/components/SliderModal';
import SlideTransitions from 'rds/components/SlideTransitions';
import ReportForm from 'rds/components/ReportModal/ReportForm';
import ReportModal from 'rds/components/ReportModal/ReportModal';

export default () => {
	const route = useSelector(getRoute);
	const open = useSelector(getIsModalOpen);
	const label = useSelector(getReportLabel);
	const formTitle = useSelector(getFormTitle);
	const title = useSelector(getTitle);
	const type = useSelector(getType);
	const resourceId = useSelector(getResourceId);

	return (
		<SliderModal open={open}>
			<SlideTransitions
				components={[{
					path: 'categories',
					component: <ReportModal
						label={label}
						title={title}
						type={type}
					/>,
				}, {
					path: 'details',
					component: <ReportForm
						label={label}
						type={type}
						title={title}
						formTitle={formTitle}
						id={resourceId}
					/>,
				}]}
				path={route}
			/>
		</SliderModal>
	);
};
