import { createTheme } from '@material-ui/core/styles';
import main from './main';

export default (colors) => {
	const mainTheme = main(colors);

	return createTheme({
		...mainTheme,
		palette: {
			...mainTheme.palette,
			type: 'dark',
			background: {
				...mainTheme.palette.background,
				page: '#000d1b',
				main: colors.neutral.C900,
				default: colors.neutral.C900,
				contrast: colors.neutral.C000,
				border: colors.neutral.C800,
			},
			primary: {
				main: colors.primary.C500,
				default: colors.neutral.C900,
				secondary: colors.neutral.C100,
				contrastTest: colors.neutral.C000,
			},
			text: {
				default: colors.neutral.C000,
				primary: colors.neutral.C000,
				secondary: colors.neutral.C100,
				contrast: colors.neutral.C900,
			},
			secondary: {
				main: colors.secondary.C700,
			},
			error: {
				main: colors.error.C700,
			},
			contrast: {
				main: colors.neutral.C000,
				contrastText: colors.neutral.C900,
				boundary: colors.neutral.C200,
			},
			neutral: {
				light: colors.neutral.C400,
				main: colors.neutral.C700,
				dark: colors.neutral.C900,
			},
			glass: {
				...mainTheme.palette.glass,
				main: colors.neutral.C900,
				contrastText: colors.neutral.C000,
			},
		},

		overrides: {
			...mainTheme.overrides,
			MuiButton: {
				...mainTheme.overrides.MuiButton,
				containedPrimary: {
					backgroundColor: colors.neutral.C000,
					color: colors.neutral.C900,
					'&$disabled': {
						...mainTheme.overrides.MuiButton.containedPrimary['&$disabled'],
						backgroundColor: colors.neutral.C200,
						color: colors.neutral.C500,
					},
				},
			},

		},

		rhino: {
			...mainTheme.rhino,
			textField: {
				...mainTheme.rhino.textField,
				variants: {
					...mainTheme.rhino.textField.variants,
					glass: {
						...mainTheme.rhino.textField.variants.glass,
						subtleInputRoot: {
							...mainTheme.rhino.textField.variants.glass.subtleInputRoot,
							color: `${colors.neutral.C000}80`,
						},
					},
				},
			},
		},
	});
};
