import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageTopBar from 'rds/components/Layouts/PageTopBar';
import { getDestinationTitle } from 'app/slices/destination-account-v2/accounts/selectors';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import { useTheme, Typography } from '@material-ui/core';
import useNavigateReportModal from 'app/hooks/useNavigateReportModal';
import { useParams } from 'react-router-dom';

const THRESHOLD = 48;

export default () => {
	const { uid } = useParams();
	const [isVisible, setIsVisible] = useState(false);
	const theme = useTheme();
	const title = useSelector(getDestinationTitle(uid));
	const show = useSelector(getIsUpdatedVersionAvailable);
	const navigateReportModal = useNavigateReportModal();

	const overflow = [
		{
			label: 'Report',
			onClick: () => {
				navigateReportModal.enter({
					type: 'destination',
					label: 'Report Account',
					title,
					id: uid,
				});
			},
			color: theme.colors.error.C300,
		},
	];

	useEffect(() => {
		const onScroll = () => {
			const scrollCheck = window.scrollY;
			setIsVisible(scrollCheck > THRESHOLD);
		};

		document.addEventListener('scroll', onScroll);

		return () => {
			document.removeEventListener('scroll', onScroll);
		};
	}, []);

	const headerStyle = {
		height: '48px',
		width: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 1000,
		transition: 'opacity 0.3s',
		background: isVisible ? theme.palette.background.page : 'none',
	};

	const contentStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		opacity: isVisible ? 1 : 0,
		transition: 'opacity 0.3s',
	};

	return (
		<header style={headerStyle}>
			<PageTopBar
				style={{ top: show ? 48 : 0, background: 'none' }}
				overflow={overflow}
				back
			/>
			<Typography variant="body1" style={contentStyle}>
				{title}
			</Typography>
		</header>
	);
};
