import React from 'react';
import { makeStyles } from '@material-ui/core';
import useTheme from 'rds/theme/useRdsTheme';
import Sections from './Sections';
import images from '../assets/regions';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		width: 'calc(100% - 32px)',
		margin: `${theme.spacing(2)}px auto`,
	},

	image: {
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		display: 'block',
		width: '100%',
		paddingBottom: '64%',
		marginTop: theme.spacing(3),
	},
}));

export default ({ region }) => {
	const classes = useStyles();
	const theme = useTheme();

	const image = images[`${theme.palette.type}Region${region + 1}`];

	return (
		<>
			<div className={classes.root}>
				<div className={classes.image} style={{ backgroundImage: `url(${image})` }} />
			</div>

			<Sections region={region} />
		</>
	);
};
