/* eslint-disable no-undef */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';

export const editingNodeMap = {};

export const setEditingNode = createAsyncThunk(
	'setEditingNode',
	async (payload, { rejectWithValue }) => {
		try {
			editingNodeMap[payload.id] = payload;
			return payload.id;
		} catch (err) {
			return rejectWithValue(handleSliceError(err));
		}
	},
);

const initialState = {
	background: { },
	showBackgroundPicker: true,
	match: null,
	recommendationTriggers: ['@'],
	recommendedValue: null,
	showTapToType: true,
	styleRoute: 'color',
	errors: null,
};

const textComponentSlice = createSlice({
	name: 'textComponent',
	initialState: { ...initialState },
	reducers: {
		close: (state) => {
			state.editingNodeId = null;
			delete editingNodeMap[state.editingNodeId];
			state.styleRoute = 'color';
			state.match = null;
			state.recommendedValue = null;
		},
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		setRecommendedValue: (state, action) => {
			state.recommendedValue = action.payload;
		},
		setTextBackground: (state, action) => {
			state.background = action.payload;
		},

		removeNode: (state, action) => {
			state.editingNodeId = null;
			const { id } = action.payload;
			delete editingNodeMap[id];
			state.styleRoute = 'color';
			state.match = null;
			state.recommendedValue = null;
		},
		reset: (state, action) => {
			Object.keys(initialState).forEach((key) => {
				if (action.payload && action.payload.indexOf(key) !== -1) {
					return;
				}
				state[key] = initialState[key];
			});
		},
	},
	extraReducers: {
		[setEditingNode.fulfilled]: (state, action) => {
			if (action.payload) {
				state.editingNodeId = action.payload;
			}
		},
		[setEditingNode.rejected]: (state, action) => {
			state.editingNodeId = null;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = textComponentSlice;
const {
	close,
	set,
	reset,
	setTextBackground,
	removeNode,
	setRecommendedValue,
} = actions;
export {
	name,
	close,
	set,
	reset,
	removeNode,
	setTextBackground,
	setRecommendedValue,
};

export const getSlice = (state) => state[name];
export const selectEditingNode = createSelector(getSlice, (slice) => editingNodeMap[slice?.editingNodeId]);
export const selectTextBackground = createSelector(getSlice, (slice) => slice?.background);
export const selectMatch = createSelector(getSlice, (slice) => slice?.match);
export const selectShowBackgroundPicker = createSelector(getSlice, (slice) => slice?.showBackgroundPicker);
export const selectShowTapToType = createSelector(getSlice, (slice) => slice?.showTapToType);
export const selectRecommendationTriggers = createSelector(getSlice, (slice) => slice?.recommendationTriggers);
export const selectRecommendedValue = createSelector(getSlice, (slice) => slice?.recommendedValue);
export const selectStyleRoute = createSelector(getSlice, (slice) => slice?.styleRoute);
export const selectStyles = createSelector(getSlice, (slice) => slice?.styles);

injectReducer(name, reducer);
