import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(16),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		textAlign: 'left',
	},
}));

export default ({ children }) => {
	const classes = useStyles();

	if (typeof children !== 'string') {
		return children;
	}

	return (
		<Typography variant="subtitle1" className={classes.root}>
			{children}
		</Typography>
	);
};
