/* eslint-disable */

/*
 * Helper for creating elements
 * https://gist.github.com/MoOx/8614711
 *
 * createElement({
 *	 tagName: 'div',
 *	 className: 'my-class',
 *	 text: 'Blah blah',
 *	 attributes: {
 *		 'id': 'element id',
 *		 'data-truc': 'value'
 *	 },
 *	 childs: [ children ]
 * })
 */

/*
 * Util function for creating elements
 * @param {Config} [options] - Configurable options for the UploadCore class
 * @param {string} options.tagName - Element tag name
 * @param {string} options.text - Textnode string
 * @param {string} options.html - Html
 * @param {string} options.events - Object of events with key and function
 * @param {object} options.attributes - Object of key value attributes
 * @param {array} options.childs - Array of child elements
 */
function createElement(options) {
  let el;
  let a;
  let i;

  if (!options.tagName) {
    el = document.createDocumentFragment();
  } else {
    el = document.createElement(options.tagName);
    if (options.className) {
      el.className = options.className;
    }

    if (options.attributes) {
      for (a in options.attributes) {
        el.setAttribute(a, options.attributes[a]);
      }
    }

    if (options.html !== undefined) {
      el.innerHTML = options.html;
    }
  }

  if (options.text) {
    el.appendChild(document.createTextNode(options.text));
  }

  // IE 8 doesn"t have HTMLElement
  if (window.HTMLElement === undefined) {
    window.HTMLElement = Element;
  }

  if (options.childs && options.childs.length) {
    for (i = 0; i < options.childs.length; i++) {
      el.appendChild(
        options.childs[i] instanceof window.HTMLElement
          ? options.childs[i]
          : createElement(options.childs[i])
      );
    }
  }

  if (options.events) {
    for (const eventName in options.events) {
      el.addEventListener(eventName, options.events[eventName]);
    }
  }

  return el;
}

export default createElement;
