import { createSelector } from '@reduxjs/toolkit';

const getSlice = (state) => state.editProfile;

export const getUpdatedAvatar = createSelector(getSlice, (slice) => slice.avatar);
export const getUpdatedBio = createSelector(getSlice, (slice) => slice.bio);
export const getUpdatedDisplayName = createSelector(getSlice, (slice) => slice.displayName);
export const getErros = createSelector(getSlice, (slice) => slice.errors);
export const getLoading = createSelector(getSlice, (slice) => slice.loading);
export const getIsItUpdated = createSelector(getSlice, (slice) => slice.updated);
