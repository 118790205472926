import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'inline-block',
		maxWidth: 'calc(100vw - 100px)',
	},
	chip: {
		border: `1px solid ${theme.colors.neutral.C400}`,
		borderRadius: 4,
		boxShadow: '0px 1px 2px rgba(35, 47, 46, 0.04), 0px 2px 8px 1px rgba(35, 47, 46, 0.14)',
		display: 'flex',
		flexDirection: 'row',
		marginRight: theme.spacing(4),
	},
	icon: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(-1),
	},
	contents: {
		display: 'flex',
		flexDirection: 'row',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textAlign: 'center',
	},
	label: {
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5),
		paddingRight: theme.spacing(3),
		paddingLeft: theme.spacing(3),
		whiteSpace: 'pre-wrap',
		textAlign: 'left',
		display: 'box',
		boxOrient: 'vertical',
		overflow: 'hidden',
		lineClamp: 1,
		wordBreak: 'break-word',
	},
	close: {
		borderLeft: `1px solid ${theme.colors.neutral.C400}`,
	},

}));

export default ({
	actionicon,
	labelIcon,
	contents,
	onClick,
	onSelect,
	labelStyle,
	noBorder,
	...rest
}) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.chip} {...rest}>
				<div className={classes.contents} onClick={onSelect}>
					{labelIcon
					&& (
						<div className={classes.icon}>
							{labelIcon}
						</div>
					)}

					<Typography variant="body1" className={classes.label} style={labelStyle}>
						{contents}
					</Typography>
				</div>
				{actionicon
					&& (
						<div className={noBorder ? null : classes.close} onClick={onClick}>
							{actionicon}
						</div>
					)}
			</div>
		</div>
	);
};
