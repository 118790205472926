import { createAsyncThunk } from '@reduxjs/toolkit';
import handleSliceError from 'utils/handle-slice-error';
import { requestIndexExperiences } from 'app/slices/index';
import { resetExplore } from 'app/slices/search-explore/explore';
import api from 'services/api';

export const requestDestinationAccount = createAsyncThunk(
	'requestDestinationAccount',
	async (uid, { rejectWithValue }) => {
		try {
			const response = await api.get(`destinations/${uid}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error, { notifyUser: false }));
		}
	},
);

export const requestFollowDestinationAccount = createAsyncThunk(
	'requestFollowDestinationAccount',
	async (uid, { dispatch, rejectWithValue }) => {
		try {
			const response = await api.post(`destinations/${uid}/follow`);
			dispatch(requestIndexExperiences());
			dispatch(resetExplore());
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestUnfollowDestinationAccount = createAsyncThunk(
	'requestUnfollowDestinationAccount',
	async (uid, { dispatch, rejectWithValue }) => {
		try {
			const response = await api.delete(`destinations/${uid}/follow`);
			dispatch(requestIndexExperiences());
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);
