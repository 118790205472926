import React from 'react';
import { makeStyles } from '@material-ui/core';
import PageTopBar from 'rds/components/Layouts/PageTopBar';
import Footer from './Components/Footer';
import Header from './Components/Header';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		// maxWidth: 1280,
		// margin: '0 auto',
		minWidth: 320,
		backgroundColor: theme.colors.neutral.C900,
	},
	contents: {},
}));

export default ({ pwa, header, children }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{pwa ? (
				<PageTopBar
					title={header}
					back
				/>
			) : <Header />}
			<div className={classes.contents}>
				{children}
			</div>
			{!pwa && <Footer />}
		</div>
	);
};
