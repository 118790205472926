const easeInOutCubic = (x) => (x < 0.5 ? 4 * x * x * x : 1 - ((-2 * x + 2) ** 3) / 2);
const animationFn = easeInOutCubic;
const FRAME_RATE = 33;

export default class ScrollAnimation {
	constructor({
		maxDuration,
		el,
		left,
		earlyDone,
		done,
	}) {
		this._el = el;

		const distance = Math.abs(left - this._el.scrollLeft);
		const duration = Math.min(Math.floor(distance / 3), maxDuration);

		this.tick({
			distance,
			duration,
			startTime: window.performance.now(),
			startPosition: this._el.scrollLeft,
			left,
			earlyDone,
			done,
		});
	}

	tick(options) {
		const {
			distance,
			duration,
			left,
			startPosition,
			startTime,
			earlyDone,
			done,
		} = options;

		if (this._destroyed) {
			done && done();
			return;
		}

		const now = window.performance.now() - startTime;

		if (now >= duration) {
			this._el.scrollLeft = left;
			setTimeout(() => {
				done && done();
			}, FRAME_RATE);
			return;
		}

		const p = animationFn(now / duration);

		if (now / duration > 0.5) {
			earlyDone && earlyDone();
		}

		const move = p * distance;
		const final = (left > this._el.scrollLeft)
			? startPosition + move
			: startPosition - move;

		this._el.scrollLeft = final;

		setTimeout(() => {
			this.tick(options);
		}, FRAME_RATE);
	}

	destroy() {
		this._destroyed = true;
	}
}
