import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import Chip from 'rds/components/Chip';
import IconV3 from 'rds/components/IconV3';
import { unfollow, getDestinations } from 'app/slices/onboarding/follow';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'left',
		whiteSpace: 'nowrap',
		overflowY: 'scroll',
		marginTop: theme.spacing(-2),
		paddingBottom: theme.spacing(8),
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	contents: {
		height: 32,
		paddingLeft: theme.spacing(6),
	},
	spacer: {
		display: 'inline-block',
		width: theme.spacing(2),
		content: '" "',
	},
	empty: {
		paddingLeft: theme.spacing(6),
		height: theme.spacing(14),
		width: '100vw',
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const destinations = useSelector(getDestinations);
	const destinationsRef = useRef();
	const contentsRef = useRef();

	useEffect(() => {
		const current = destinationsRef.current || 0;
		if (destinations.length > current) {
			if (contentsRef.current) {
				contentsRef.current.parentElement.scrollLeft = contentsRef.current.scrollWidth;
			}
		}
		destinationsRef.current = destinations.length;
	}, [destinations]);

	if (!destinations.length) {
		return (
			<Typography className={classes.empty} variant="body2">
				No destination selected.
			</Typography>
		);
	}

	return (
		<div className={classes.root}>
			<div className={classes.contents} ref={contentsRef}>
				{destinations.map(
					(d) => (
						<Chip
							key={d.uid}
							actionicon={(
								<IconV3
									icon="Cancel"
									size={16}
									containerSize={32}
								/>
							)}
							contents={d.title}
							onClick={() => {
								dispatch(unfollow(d));
							}}
						/>
					),
				)}
				<div className={classes.spacer} />
			</div>
		</div>
	);
};
