import {
	createSelector, createSlice,
} from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import { map, getMirror } from 'app/slices/user-media';
import assetManager from 'lib/asset-manager';
import waitFor from 'utils/wait-for';
import toggleTorch from 'utils/toggle-torch';
import { requestAddClipPhoto, selectClipCount, CLIPS_THRESHOLD } from '../experience';
import MediaRecorder from './media-recorder';

export const PHOTO_THRESHOLD = 400;
export const VIDEO_CLIP_CHUNK_DURATION = 15000;

let mediaRecorderMap = {};
let globalRecordingMutex = false;

export const torchOn = () => (dispatch, getState) => {
	const { streamId, flash } = getState().usermedia;
	const stream = map[streamId];

	if (!stream) {
		return;
	}
	if (flash === 'flash' || flash === 'auto') {
		toggleTorch(true, stream);
	}
};

export const torchOff = () => (dispatch, getState) => {
	const { streamId } = getState().usermedia;
	const stream = map[streamId];

	if (!stream) {
		return;
	}
	toggleTorch(false, stream);
};

export const requestStartRecording = () => (dispatch, getState) => {
	if (globalRecordingMutex) {
		return false;
	}

	const state = getState();
	const { streamId, flash, mediaRecorderId } = state.usermedia;
	const clipCount = selectClipCount(state);

	if (clipCount >= CLIPS_THRESHOLD) {
		return false;
	}
	const mirror = getMirror(getState());

	if (mediaRecorderId) {
		return false;
	}

	const stream = map[streamId];

	if (!stream) {
		return false;
	}

	const vt = stream.getVideoTracks()[0];
	if (!vt || !vt.enabled || vt.readyState !== 'live') {
		return false;
	}

	globalRecordingMutex = true;
	if (flash === 'flash' || flash === 'auto') {
		toggleTorch(true, stream);
	}

	const mediaRecorder = new MediaRecorder(stream);
	mediaRecorder.on('error', (error) => {
		dispatch(setMediaRecorderError(error));
	});

	mediaRecorderMap[mediaRecorder.stream.id] = mediaRecorder;
	dispatch(startRec({ mediaRecorderId: mediaRecorder.stream.id }));
	mediaRecorder.start(VIDEO_CLIP_CHUNK_DURATION);

	mediaRecorder.once('stop', () => {
		globalRecordingMutex = false;
	});

	mediaRecorder.once('start', () => {
		dispatch(recordingStarted());
	});

	mediaRecorder.ondataavailable = (e) => {
		if (e.data.size > 0) {
			const asset = assetManager.set(e.data);
			const payload = {
				type: 'video',
				data: {
					asset,
					ext: 'webm',
					start: 0,
					attributes: {
						'data-mirror': mirror,
					},
				},
				dimensions: {
					height: document.body.clientHeight,
					width: document.body.clientWidth,
				},
				styles: {
					width: '100%',
					height: '100%',
					'object-fit': 'cover',
					transform: mirror ? 'rotateY(180deg)' : null,
				},
			};

			dispatch(requestAddClipPhoto(payload));
		}
	};

	return true;
};

const getBlobFromVideo = (video, mirror = false) => new Promise((resolve, reject) => {
	const { height, width } = video.getBoundingClientRect();
	const { videoWidth, videoHeight } = video;

	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	canvas.height = height;
	canvas.width = width;

	const videoAspectRatio = videoHeight / videoWidth;
	const aspectRatio = height / width;

	let sx;
	let sy;
	let sWidth;
	let sHeight;

	if (videoAspectRatio < aspectRatio) {
		sHeight = videoHeight;
		sy = 0;
		sWidth = videoHeight * (width / height);
		sx = (videoWidth - sWidth) / 2;
	} else {
		sWidth = videoWidth;
		sx = 0;
		sHeight = videoWidth * (height / width);
		sy = (videoHeight - sHeight) / 2;
	}

	if (mirror) {
		context.scale(-1, 1);
	}

	context.drawImage(
		video,
		sx,
		sy,
		sWidth,
		sHeight,
		0,
		0,
		mirror ? -canvas.width : canvas.width,
		canvas.height,
	);

	canvas.toBlob((blob) => {
		if (!blob.size) {
			reject(new Error('Failed to capture photo'));
		}
		canvas.remove();
		resolve(blob);

		// const image = new Image();
		// image.src = blob;
		// document.body.innerHTML = '';
		// document.body.appendChild(image);
	}, 'image/png');
});

export const requestStopRecording = (video, options = {}) => async (dispatch, getState) => {
	const { flashAnimation, shutterAnimation } = options;
	const state = getState();
	const { streamId, facingMode, flash } = state.usermedia;
	const clipCount = selectClipCount(state);

	const { mediaRecorderId, mediaRecorderStartTime } = getState().capture;
	const mirror = getMirror(getState());

	const stream = map[streamId];
	const mediaRecorder = mediaRecorderMap[mediaRecorderId];

	const type = mediaRecorderStartTime && (window.performance.now() - mediaRecorderStartTime > PHOTO_THRESHOLD)
		? 'video'
		: 'photo';

	if (!stream || !mediaRecorder) {
		return false;
	}

	if (clipCount >= CLIPS_THRESHOLD) {
		return false;
	}

	if (type === 'photo') {
		mediaRecorder.ondataavailable = null;

		if (flash !== 'off') {
			if (typeof flashAnimation === 'function' && facingMode === 'user') {
				flashAnimation();
				await waitFor(100);
			}
		} else if (typeof shutterAnimation === 'function') {
			shutterAnimation();
		}

		const blob = await getBlobFromVideo(video, mirror);
		dispatch(requestAddClipPhoto({
			dimensions: video.getBoundingClientRect(),
			type: 'image',
			data: {
				blob,
			},
			styles: {
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				width: '100%',
				height: '100%',
			},
		}));
	} else {
		toggleTorch(false, stream);
	}

	mediaRecorder.onstop = () => {
		dispatch(stopRec());
	};

	if (type === 'video') {
		await waitFor(250);
	}
	if (mediaRecorder.state !== 'inactive') {
		mediaRecorder.stop();
	}

	return type;
};

const initialState = {
	mediaRecorderId: null,
	mediaRecorderStartTime: null,
	mediaRecorderError: null,
	errors: null,
	loading: false,
};

export const captureSlice = createSlice({
	name: 'capture',
	initialState,
	reducers: {
		reset: (state) => {
			globalRecordingMutex = false;
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		startRec: (state, action) => {
			state.video = null;

			if (action.payload.mediaRecorderId) {
				state.mediaRecorderId = action.payload.mediaRecorderId;
			}

			state.mediaRecorderStartTime = null;
		},
		recordingStarted: (state) => {
			if (!state.mediaRecorderStartTime) {
				state.mediaRecorderStartTime = window.performance.now();
			}
		},
		stopRec: (state) => {
			state.mediaRecorderId = null;
			state.mediaRecorderStartTime = null;
			mediaRecorderMap = {};
		},
		setMediaRecorderError: (state, action) => {
			state.mediaRecorderError = action.payload;
		},
	},
	extraReducers: {
		[requestStopRecording.pending]: (state) => {
			state.mediaRecorderStartTime = null;
		},
	},
});

export default captureSlice;

const { name, reducer, actions } = captureSlice;
const {
	startRec,
	stopRec,
	setMediaRecorderError,
	recordingStarted,
	reset,
} = actions;

export {
	name,
	startRec,
	stopRec,
	setMediaRecorderError,
	recordingStarted,
	reset,
};

const getSlice = (state) => state[name];
export const getMediaRecorderId = createSelector(getSlice, (slice) => slice?.mediaRecorderId);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getRecordingStartTime = createSelector(getSlice, (slice) => slice?.mediaRecorderStartTime);

injectReducer(name, reducer);
