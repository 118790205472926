import React from 'react';
import { Typography } from '@material-ui/core';
import useRdsTheme from 'rds/theme/useRdsTheme';

export default ({
	ds,
	strong,
	style = {},
	gutter,
	theme,
	variant,
	...props
}) => {
	const rdsTheme = useRdsTheme();
	if (theme) {
		style.color = theme.palette.type === 'dark' ? theme.colors.neutral.C000 : null;
	}

	if (ds) {
		style.textShadow = typeof ds === 'string'
			? ds
			: '0px 1px 3px rgba(0, 0, 0, 0.5)';
	}

	if (strong) {
		style.fontWeight = 700;
	}

	if (variant === 'buttonXsmall') {
		variant = 'caption';
		style = { ...style, ...rdsTheme.typography.buttonXsmall };
	}

	if (variant === 'title2') {
		variant = 'subtitle1';
		style = { ...style, ...rdsTheme.typography.title2 };
	}

	// if (gutter) {
	//	 style.marginTop = theme.spacing();
	//	 style.marginBottom = theme.spacing();
	// }

	return <Typography variant={variant} style={style} {...props} />;
};
