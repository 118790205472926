import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { get, close } from 'app/slices/app/context-menu';
import OverflowMenu from 'rds/components/OverflowMenu';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 20000,
		background: `${theme.colors.neutral.C000}40`,
		overflow: 'hidden',
	},
	element: {
		zIndex: 15,
	},
}));

export default () => {
	const classes = useStyles();
	const ref = useRef();
	const dispatch = useDispatch();
	const contextMenu = useSelector(get);
	const { clonedElement, options } = contextMenu || {};

	useEffect(() => {
		if (ref?.current && clonedElement) {
			clonedElement.classList.add(classes.element);
			ref.current.appendChild(clonedElement);
		}
		return () => {
			if (ref && ref.current) {
				ref.current.innerHTML = '';
			}
		};
	}, [ref, clonedElement]);

	return (
		<div>
			<div ref={ref} />
			<OverflowMenu
				onClose={() => { dispatch(close()); }}
				show={!!options && contextMenu}
				items={options}
			/>
		</div>
	);
};
