import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getRoute } from 'app/slices/experience-view';
import ecrMemory from 'features/ExperienceModal/ecr-memory';
import emEmitter from 'features/ExperienceModal/emitter';
import Clip from './Clip';
import Timer from './Timer';

const PHOTO_DURATION = 2;
const TIME_OUT = 150;
const useStyles = makeStyles({
	container: {
		position: 'absolute',
		top: 0,
		width: '100%',
		height: '100%',
		zIndex: 1,
		touchAction: 'none',
	},

});

export default ({ active, experience, panelOpen }) => {
	const classes = useStyles();
	const route = useSelector(getRoute);
	const last = ecrMemory.get(experience.uid);
	const [clipState, setClipState] = useState({
		activeClip: last.clip,
		buffering: Date.now(),
		play: true,
		playCount: 0,
	});

	const [time, setTime] = useState(0);

	const {
		activeClip,
		play,
		playCount,
	} = clipState;

	const clipDurations = experience.clips.map((c) => (
		c?.outputs?.video?.duration
			? c.outputs.video.duration
			: PHOTO_DURATION
	));

	const done = clipDurations.slice(0, activeClip);
	const duration = clipDurations.reduce((a, b) => a + b) * 1000;
	const doneTime = done.length ? done.reduce((a, b) => a + b) * 1000 : 0;
	const remaining = typeof time !== 'undefined' ? (duration - time) - doneTime : undefined;

	const onTimeUpdate = useCallback((currentTime) => {
		setTime(currentTime);
	});

	useEffect(() => {
		emEmitter.emit('clip', clipState.activeClip);
		emEmitter.emit('showing');
	}, [clipState.activeClip]);

	const onEnd = useCallback(() => {
		if (activeClip >= experience.clips.length - 1) {
			emEmitter.emit('clip', 0);
			ecrMemory.set(experience.uid, { clip: 0 });
			emEmitter.emit('experience-end', 'forward');
			setTimeout(() => {
				setClipState({
					play: true,
					activeClip: 0,
					playCount: playCount + 1,
				});
				setTime(0);
			}, TIME_OUT);
			return;
		}

		const next = activeClip + 1;
		emEmitter.emit('clip', next);
		ecrMemory.set(experience.uid, { clip: next });
		setClipState({
			...clipState,
			activeClip: next,
			playCount: playCount + 1,
		});
		setTime(0);
	}, [activeClip, playCount, play]);

	const onPlay = useCallback(() => {
		setClipState({ ...clipState, play: true });
	}, [clipState]);

	const onPause = useCallback(() => {
		setClipState({
			...clipState,
			play: false,
			time,
		});
	}, [clipState]);

	const onTap = useCallback(({ localActiveClip, side }) => {
		if (!side) {
			return;
		}

		const next = side === 'right'
			? localActiveClip + 1
			: localActiveClip - 1;

		if (next >= experience.clips.length) {
			emEmitter.emit('experience-end', 'forward');
			ecrMemory.set(experience.uid, { clip: 0 });

			setTimeout(() => {
				setClipState({
					...clipState,
					activeClip: 0,
					playCount: playCount + 1,
					play: true,
				});
				setTime(0);
			}, TIME_OUT);
			return;
		}

		if (time > 1000 && side === 'left') {
			setClipState({
				...clipState,
				playCount: playCount + 1,
				play: true,
			});
			setTime(0);
			return;
		}

		if (next < 0) {
			emEmitter.emit('experience-end', 'backward');
			return;
		}

		emEmitter.emit('clip', next);
		ecrMemory.set(experience.uid, { clip: next });
		setClipState({
			...clipState,
			activeClip: next,
			playCount: playCount + 1,
			play: true,
		});
		setTime(0);
	}, [clipState, active]);

	useEffect(() => {
		let tapIndicator = 0;

		const localOnTap = (args) => {
			args.localActiveClip = activeClip;
			args.tapIndicator = tapIndicator++;
			onTap(args);
		};

		if (active) {
			emEmitter.addListener('play', onPlay);
			emEmitter.addListener('pause', onPause);
			emEmitter.addListener('tap', localOnTap);
		}

		if (!active) {
			emEmitter.removeListener('play', onPlay);
			emEmitter.removeListener('pause', onPause);
			emEmitter.removeListener('tap', localOnTap);
		}

		return () => {
			emEmitter.removeListener('play', onPlay);
			emEmitter.removeListener('pause', onPause);
			emEmitter.removeListener('tap', localOnTap);
		};
	}, [active, activeClip, clipState]);

	if (!experience) {
		return null;
	}

	return (
		<>
			<Timer remaining={remaining} />
			<div className={classes.container}>
				{
					experience.clips.map((c, i) => (
						<Clip
							key={c.clipUid}
							experienceUid={experience.uid}
							activeClip={activeClip === i}
							active={activeClip === i && active}
							activeExperience={active}
							preload={i === activeClip + 1}
							play={!route && active && activeClip === i && play && !panelOpen}
							playCount={playCount}
							onTimeUpdate={active && activeClip === i ? onTimeUpdate : null}
							onEnd={active && activeClip === i ? onEnd : null}
							clip={c}
							index={i}
							time={time}
						/>
					))
				}
			</div>
		</>
	);
};
