import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	getMyAvatar,
	getMyLoadingProfile,
	getTheirAvatar,
	getTheirLoadingProfile,

	getMyAvatarIsActive,
	getFirstVisit,
} from 'app/slices/user-account-v2/accounts';
import Avatar from 'rds/components/Avatar';
import RelativeContent from 'rds/components/Layouts/RelativeContent';
import useTheme from 'rds/theme/useRdsTheme';
import LoadingSkeleton from 'rds/components/LoadingSkeleton';
import { requestPatchOnboarding } from 'app/slices/onboarding/onboarding';
import WaveSkeleton from 'components/WaveSkeleton';
import { toggleEditProfileModal } from 'app/slices/user-account-v2/layout/slice';
import UserInfo from './UserInfo';
import EditProfile from './EditProfile/EditProfile';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(17),
	},
	skeletonContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	link: {
		textAlign: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(6),
		color: theme.colors.primary.C400,
		fontWeight: 500,
	},
}));

export default ({
	isMe,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const avatar = useSelector(isMe ? getMyAvatar : getTheirAvatar);
	const loading = useSelector(isMe ? getMyLoadingProfile : getTheirLoadingProfile);
	const avatarIsActive = isMe ? useSelector(getMyAvatarIsActive) : false;
	const firstVisit = isMe ? useSelector(getFirstVisit) : false;

	useEffect(() => () => {
		if (isMe && firstVisit) {
			dispatch(requestPatchOnboarding({ firstVisit: false }));
		}
	}, [isMe, firstVisit]);

	const handleClick = () => {
		if (isMe) {
			dispatch(toggleEditProfileModal(true));
		}
	};

	return (
		<div className={classes.root} onClick={handleClick}>
			{loading
				? (
					<div className={classes.skeletonContainer}>
						<WaveSkeleton style={{ borderRadius: '100%', height: 70, width: 70 }} />
					</div>
				)
				: (
					<Avatar
						active={avatarIsActive}
						src={avatar && avatar.large.jpg}
						size="xLarge"
						alt="Username"
					/>
				)}
			<RelativeContent style={{
				marginTop: theme.spacing(3),
				paddingLeft: theme.spacing(6),
				paddingRight: theme.spacing(6),
			}}
			>
				{loading
					? <LoadingSkeleton lines={1} />
					: <UserInfo isMe={isMe} />}
				<EditProfile isMe={isMe} />
			</RelativeContent>
		</div>
	);
};
