import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	full: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'left',
		height: '100%',
		padding: theme.spacing(6, 0, 0),
	},
	gutters: {
		paddingBottom: 0,

		paddingLeft: theme.spacing(9),
		paddingRight: theme.spacing(9),
	},
}));

export default ({
	gutters,
	style = {},
	zeroTop,
	children,
}) => {
	const classes = useStyles();

	const klass = gutters
		? clsx(classes.full, classes.gutters)
		: classes.full;

	if (zeroTop) {
		style.paddingTop = 0;
	}

	return (
		<Grid item xs={12}>
			<div className={klass} style={style}>
				{children}
			</div>
		</Grid>
	);
};
