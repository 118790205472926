import createElement from '../../utils/create-element';
import { IMAGE } from '../../types';
import BaseNode from './base';

export default class ImageNode extends BaseNode {
	constructor(canvas, item) {
		super(canvas, item);
		this.type = IMAGE;

		this.el = createElement({
			tagName: 'img',
		});

		if (item.data.src) {
			this.el.setAttribute('data-original-src', item.data.src);
			const parts = item.data.src.split('.');

			const proposed = parts[parts.length - 1];

			// sometimes i have a mime i could check...
			if (proposed.slice(0, 4) === 'blob') {
				if (item?.data?.blob?.type) {
					// eslint-disable-next-line
					this.ext = item.data.blob.type.split('/')[1];
				} else {
					this.ext = 'png';
				}
			} else {
				this.ext = proposed;
			}
		} else {
			this.ext = item?.data?.ext || 'png';
		}

		this.setElAttributes();

		this.el.src = item.asset
			? item.asset.url
			: window.URL.createObjectURL(item.data.blob);

		this.el.style.position = 'absolute';
		this.el.style.zIndex = item.z;
		Object.keys(this.styles).forEach((k) => {
			this.el.style[k] = this.styles[k];
		});
		this.setAttributes(this.el);
	}

	get dto() {
		return this.data.src;
	}

	async renderFrame(_, multiplier) {
		const {
			context, el,
		} = this.compileContext;

		const scale = this.getScale(el);

		const rect = el.getBoundingClientRect();
		const dimensions = {
			width: el.clientWidth, // * scale,
			height: el.clientHeight, // * scale,
		};
		const position = {
			x: parseFloat(el.style.left.slice(0, -2)) || 0,
			y: parseFloat(el.style.top.slice(0, -2)) || 0,
		};

		const angle = this.getRotation(el);
		const rads = angle * (Math.PI / 180);
		const x = multiplier * (position.x || rect.x);
		const y = multiplier * (position.y || rect.y);
		const w = multiplier * dimensions.width;
		const h = multiplier * dimensions.height;

		context.save();
		context.translate(x, y);
		context.translate(w / 2, h / 2);
		context.rotate(rads);
		context.drawImage(el,
			(-w * scale) / 2,
			(-h * scale) / 2,
			w * scale,
			h * scale);

		context.restore();
	}

	async setupCompile(canvas, context, shadowContainer) {
		const el = this.el.cloneNode(true);

		this.compileContext = {
			canvas,
			context,
			el,
			shadowContainer,
		};

		shadowContainer.appendChild(el);

		return new Promise((resolve, reject) => {
			el.onerror = reject;
			el.onload = resolve;
		});
	}
}
