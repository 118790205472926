import React, {
	useState,
	useEffect,
	useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Avatar from 'rds/components/Avatar';
import Typography from 'rds/components/Typography';
import clsx from 'clsx';
import {
	set,
	getActiveComment,
	requestDeleteComment,
	requestLikeComment,
	requestUnLikeComment,
	getCommentsUsers,
} from 'app/slices/comments';
import { getVisibleExperience } from 'app/slices/experience-modal';
import useUserAccountLink from 'app/hooks/useUserAccountLink';
import IconV3 from 'rds/components/IconV3';
import { useNavigate } from 'react-router-dom';
import useNavigateReportModal from 'app/hooks/useNavigateReportModal';
import Menu from './Menu';
import CommentTokenView from './CommentTokenView';
import Replies from './Replies';
import CommentSlider from '../../../../lib/comment-menu-slider';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		maxHeight: 0,
		position: 'relative',
		overflow: 'hidden',
		paddingLeft: theme.spacing(3),
		width: '100%',
	},
	left: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
	},
	right: {
		textAlign: 'center',
		paddingRight: theme.spacing(3),
	},
	commentContainer: {
		display: 'flex',
		flexDirection: 'Column',
		marginRight: theme.spacing(2),
	},
	comment: {
		...theme.typography.body1,
		whiteSpace: 'pre-wrap',
		textAlign: 'left',
		display: 'box',
		boxOrient: 'vertical',
		overflow: 'hidden',
		wordBreak: 'break-word',
	},
	ellipsis: {
		lineClamp: 4,
	},
	metadataContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		paddingBottom: theme.spacing(),
	},
	avatarContainer: {
		marginRight: theme.spacing(2),
	},
	text: {
		color: theme.colors.neutral.C100,
	},
	time: {
		color: theme.colors.neutral.C400,
		marginRight: theme.spacing(4),
		minWidth: theme.spacing(5),
	},
	number: {
		color: theme.colors.neutral.C300,
	},
	menu: {
		position: 'absolute',
		top: 0,
		display: 'flex',
		zIndex: 3,
	},
	showReply: {
		textAlign: 'left',
		marginLeft: theme.spacing(9),
		marginBottom: theme.spacing(5),
	},
	span: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(4),
	},
	empty: {
		visibility: 'hidden',
	},
	highlight: {
		position: 'absolute',
		zIndex: 0,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		transition: 'background 0.25s ease',
		background: theme.palette.type === 'light'
			? theme.colors.neutral.C100
			: theme.colors.neutral.C800,
		opacity: 0.6,
	},
	diminish: {
		background: 'none',
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
		zIndex: 1,
		flexGrow: 1,
		position: 'relative',
	},
	reply: {
		color: theme.colors.neutral.C200,
	},
	hidden: {
		opacity: 0,
	},

}));

const FADE_DELAY = 1000;

export default ({
	comment,
	find,
	highlight,
	expand,
	hidden,
	scrollBehavior,
	scrollTo,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showOverride, setShowOverride] = useState(false);
	const [expandComment, setExpandComment] = useState(false);
	const [diminish, setDiminish] = useState(false);
	const divRef = useRef();
	const menuRef = useRef();
	const contentRef = useRef();
	const active = useSelector(getActiveComment);
	const { userId } = comment;
	const users = useSelector(getCommentsUsers);
	const author = users[userId];
	const visibleExperience = useSelector(getVisibleExperience);
	const navigateReport = useNavigateReportModal();

	const timeStamp = comment?.meta?.createdAt;

	useEffect(() => {
		if (highlight) {
			setTimeout(() => {
				setDiminish(true);
			}, FADE_DELAY);
		}
	}, [highlight]);

	useEffect(() => {
		if (scrollTo && divRef.current) {
			divRef.current.scrollIntoView({
				behavior: scrollBehavior,
				block: 'center',
			});
		}

		if (divRef.current && hidden) {
			setShowOverride(true);
		}
	}, [divRef, scrollTo, scrollBehavior, hidden]);

	useEffect(() => {
		if (menuRef?.current && divRef.current && contentRef.current) {
			divRef.current.sliderMenu = new CommentSlider(divRef.current, contentRef.current, menuRef.current, 'comment');
			divRef.current.sliderMenu.on('active', () => {
				dispatch(set({ activeComment: comment.id }));
			});

			if (active !== comment.id) {
				divRef.current.sliderMenu.hide();
			}
		}

		return () => {
			if (divRef?.current?.sliderMenu) {
				divRef.current.sliderMenu.cleanup();
			}
		};
	}, [active, expandComment, divRef, menuRef, contentRef]);

	useEffect(() => {
		if (expand && divRef.current) {
			divRef.current.style.transition = 'all 400ms';
			divRef.current.style.maxHeight = 0;

			window.requestAnimationFrame(() => {
				if (!divRef.current) {
					return;
				}
				divRef.current.style.maxHeight = '150px';

				setTimeout(() => {
					if (!divRef.current) {
						return;
					}
					divRef.current.style.maxHeight = 'fit-content';
				}, 500);
			});
		} else if (!expand && divRef.current) {
			divRef.current.style.maxHeight = 'fit-content';
		}
	}, [divRef, expand]);

	useEffect(() => {
		if (comment.deleted && divRef.current) {
			divRef.current.style.transition = 'all 400ms';
			divRef.current.style.height = `${divRef.current.getBoundingClientRect().height}px`;
			window.requestAnimationFrame(() => {
				divRef.current.style.height = 0;
			});
		}
	}, [divRef, comment.deleted]);

	const handleReport = () => {
		navigateReport.enter({
			type: 'comment',
			label: 'Report Comment',
			reportedComment: comment,
			title: author?.username,
			id: comment?.id,
		});
	};

	const handleDelete = () => {
		dispatch(requestDeleteComment({
			comment,
			type: 'comment',
			experience: visibleExperience,
		}));
	};

	const handleClose = () => {
		dispatch(set({ activeComment: null }));
	};

	const klass = comment.empty
		? clsx(classes.root, classes.empty)
		: hidden && !showOverride
			? clsx(classes.root, classes.hidden)
			: classes.root;
	const redirectTo = useUserAccountLink(author?.username);

	let highlightClass;

	if (highlight) {
		highlightClass = classes.highlight;

		if (diminish) {
			highlightClass = classes.diminish;
		}
	}

	return (
		<>
			<div
				className={klass}
				ref={divRef}
			>
				<div className={classes.content} ref={contentRef}>
					<div className={classes.menu} ref={menuRef} style={{ visibility: active === comment.id ? 'visible' : 'hidden' }}>
						<Menu
							author={author}
							handleReport={handleReport}
							handleDelete={handleDelete}
							onClose={handleClose}
						/>
					</div>
					<div className={classes.left}>
						<div
							className={classes.avatarContainer}
							onClick={() => {
								dispatch(set({ f: false }));
								navigate(redirectTo);
							}}
						>
							<Avatar
								size="xxxSmall"
								alt="header"
								src={author?.avatar?.large?.png}
							/>
						</div>

						<div className={classes.commentContainer}>
							<div className={expandComment ? clsx(classes.comment) : clsx(classes.comment, classes.ellipsis)} onClick={() => setExpandComment(!expandComment)}>
								<CommentTokenView comment={comment} users={users} author={author?.username} />
							</div>
							<div className={classes.metadataContainer}>
								<Typography className={classes.time} variant="buttonXsmall">{timeStamp}</Typography>
								<Typography
									className={classes.reply}
									variant="buttonXsmall"
									onClick={(e) => {
										e.preventDefault();
										const commentToReplyObj = { ...comment };
										commentToReplyObj.author = author;
										dispatch(set({ commentToReply: commentToReplyObj }));
									}}
								>
									Reply
								</Typography>
							</div>
						</div>
					</div>
					<div className={classes.right}>
						<IconV3
							icon={comment?.interactions?.isLiked ? 'HeartFilled' : 'HeartUnfilled'}
							color="neutralC200"
							size={16}
							containerSize={20}
							onClick={() => {
								if (!comment?.interactions?.isLiked) {
									dispatch(requestLikeComment({ uid: comment.id, type: 'comment' }));
								} else {
									dispatch(requestUnLikeComment({ uid: comment.id, type: 'comment' }));
								}
							}}
						/>
						<Typography className={classes.number} style={{ visibility: comment?.interactions?.numLikes === 0 ? 'hidden' : 'visible' }} variant="buttonXsmall">{comment?.interactions?.numLikes !== 0 ? comment?.interactions?.numLikes : 0}</Typography>
					</div>
				</div>
				<div className={highlightClass} />
			</div>
			{!!comment?.replies?.comments && !!comment?.replies?.comments.length && <Replies find={find} comment={comment} />}
		</>
	);
};
