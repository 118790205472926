import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getCategories,
	requestCategories,
	getRegions,
	requestRegions,
	getErrors,
	getCategoriesLoading,
	getRegionsLoading,
} from 'app/slices/onboarding/regions';
import { requestPatchOnboarding } from 'app/slices/onboarding/onboarding';
import RequestProvider from 'rds/providers/Request';
import PageSkeleton from 'rds/components/Skeletons/PrimaryPage';
import { getIsOnboardingCompleted } from 'app/slices/authentication/session/session';
import { set } from 'app/slices/app-navigations/navigations';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from 'app/hooks/useQueryParams';
import Maps from './Maps';
import PageLayout from '../Layout/Page';

export default () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isOnboardingCompleted = useSelector(getIsOnboardingCompleted);
	const isItRedirectedFromNotification = useQueryParam('notificationRedirect');

	useEffect(() => {
		if (isOnboardingCompleted && !isItRedirectedFromNotification) {
			navigate('/my-account');
		}
	}, [isOnboardingCompleted]);

	return (
		<RequestProvider
			requests={{
				categories: {
					selector: getCategories,
					action: requestCategories,
					loading: getCategoriesLoading,
					errors: getErrors,
				},
				regions: {
					selector: getRegions,
					action: requestRegions,
					loading: getRegionsLoading,
					errors: getErrors,
				},
			}}
			Skeleton={PageSkeleton}
		>
			<PageLayout
				header="Which regions of the world are you least familiar with?"
				top={{
					next: {
						label: 'Done',
						onClick: async () => {
							await dispatch(requestPatchOnboarding({
								activities: true,
								destinations: true,
								regions: true,
							}));
							dispatch(set({ currentTab: 4 }));
							if (isItRedirectedFromNotification) {
								navigate('/notifications');
							}
						},
					},
					back: {
						label: 'Back',
						onClick: () => {
							navigate(-1);
						},
					},
				}}
			>
				<Maps />
			</PageLayout>
		</RequestProvider>

	);
};
