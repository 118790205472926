import React from 'react';
import { makeStyles } from '@material-ui/core';
import { PanelContainer, PanelContents, PanelHeader } from './ExperienceView/Panels';
import Details from './ExperienceView/OverflowMenus/Details';
import Comments from './ExperienceView/OverflowMenus/Comments';

const useStyles = makeStyles({
	background: {
		width: '100%',
		height: '100%',
		position: 'fixed',
		background: '#000000',
		opacity: 0.4,
		zIndex: 12,
	},
});

export default ({
	active,
	panel,
	onClose,
	height,
	...props
}) => {
	const classes = useStyles();
	const open = !!panel.pane;
	const background = !!panel.overlay;
	let title = 'Details';
	let panelPane = <Details context={panel.context} {...props} open={open} />;

	if (panel.pane === 'comments') {
		title = 'Comments';
		panelPane = <Comments context={panel.context} {...props} open={open} />;
	}

	if (panel.pane === 'add-comments') {
		title = 'Comments';
		panelPane = <Comments context={{ add: true }} {...props} open={open} />;
	}

	return (
		<>
			<PanelContainer height={height}>
				<PanelHeader title={title} onClose={onClose} open={open} />
				<PanelContents>
					{active ? panelPane : null}
				</PanelContents>
			</PanelContainer>
			{background && <div className={classes.background} />}
		</>
	);
};
