import { EventEmitter } from 'events';

const MODES = {
	photo: 'photo',
	text: 'text',
};

export const EVENTS = {
	textCaptureEnd: 'text-capture-end',
	textCaptureStart: 'text-capture-start',
	mediaCaptureEnd: 'media-capture-end',
	mediaCaptureStart: 'media-capture-start',
	mediaIsVideo: 'media-is-video',
};

export default class Capture extends EventEmitter {
	constructor({
		captureEl,
		mode,
		photoThreshold,
	}) {
		super();
		this._mode = mode;
		this._captureEl = captureEl;
		this._photoThreshold = photoThreshold;
		this._to = null;

		this._started = false;
		this._capturing = false;
		this._locked = false;
		this._captureEl.addEventListener('touchstart', this._touchstart.bind(this), { passive: true });
		this._captureEl.addEventListener('touchend', this._touchend.bind(this), { passive: true });
	}

	_touchstart() {
		if (this._capturing || this._locked) {
			return;
		}

		this._capturing = true;
		this._started = true;

		if (this._mode === MODES.photo) {
			this.emit(EVENTS.mediaCaptureStart);

			this._to = setTimeout(() => {
				if (!this._started) {
					return;
				}

				this.emit(EVENTS.mediaIsVideo);
			}, this._photoThreshold);
		} else {
			this.emit(EVENTS.textCaptureStart);
		}
	}

	_touchend() {
		if (this._locked) {
			return;
		}

		clearTimeout(this._to);
		this._started = false;
		if (this._mode === MODES.photo) {
			this.emit(EVENTS.mediaCaptureEnd);
		} else {
			this.emit(EVENTS.textCaptureEnd);
		}
	}

	shutter(color, ms = 200, delayMs = 0) {
		const shutterEl = document.createElement('div');
		shutterEl.style.backgroundColor = color;
		shutterEl.style.position = 'fixed';
		shutterEl.style.top = 0;
		shutterEl.style.right = 0;
		shutterEl.style.bottom = 0;
		shutterEl.style.left = 0;
		shutterEl.style.zIndex = 50000;
		shutterEl.style.opacity = 1;

		const duration = `${(ms + delayMs) / 1000}s`;
		const delay = delayMs ? `${delayMs / 1000}s` : '';
		shutterEl.style.transition = `all ${duration} linear ${delay}`;
		document.body.appendChild(shutterEl);

		setTimeout(() => {
			shutterEl.style.opacity = 0;
			setTimeout(() => {
				shutterEl.remove();
			}, ms + delayMs);
		}, 1);
	}

	lock() {
		this._locked = true;
	}

	unlock() {
		this._capturing = false;
		this._locked = false;
	}

	destroy() {
		this.removeAllListeners();
	}
}
