import { EventEmitter } from 'events';
import calculateTrueBoxDimensions from 'utils/calculate-true-box-dimensions';
import isElementInViewport from 'utils/is-element-in-view-port';
import getLargerScreenDimension from 'utils/get-device-max-height';
import ScrollAnimation from './scroll-animation';

const TAP_THRESHOLD = 250;
const PAUSE_THRESHOLD = 20;
const SCROLL_THRESHOLD = 3;
const ACTION_THRESHOLD = 20;
const RELEASE_THRESHOLD = 250;
const FAST_SCROLL_DURATION = 200; // ms
const SLOW_SCROLL_DURATION = 500;
const LEFT_THRESHOLD = 0.3; // 30% of left side of screen is left tap
const MAX_DISTANCE_FROM_TOP = 48; // this means the pane can go up to 48px from the top
// const TRANSITION = `all ${FAST_SCROLL_DURATION / 1000}s`;
const durationInSeconds = FAST_SCROLL_DURATION / 1000;
const TRANSITION = `opacity 300ms, transform ${durationInSeconds}s ease-out, height ${durationInSeconds}s ease-out, width ${durationInSeconds}s ease-out`; // animate transform, height, and width properties
// const DISMISS_TRANSITION = `all ${FAST_SCROLL_DURATION / 1000}s ease-in-out`;
const DISMISS_TRANSITION = 'all 300ms ease-in-out, opacity 900ms ease-in-out';
const DISMISS_OPACITY_TRANSITION = 'all 300ms ease-in-out, opacity 900ms ease-in-out';

export const EVENTS = {
	bottomPanelClose: 'bottom-panel:close',
	bottomPanelCloseFinished: 'bottom-panel:close:finished',
	bottomPanelOpen: 'bottom-panel:open',
	bottomPanelOpenFinished: 'bottom-panel:open:finished',
	touchStart: 'touch-start',
	touchEnd: 'touch-end',
	dismiss: 'dismiss',
	pause: 'pause',
	play: 'play',
	setPane: 'set-pane',
	tap: 'tap',
	verticalScroll: 'vertical-scroll',
};

// const pxToNm = (str) => Number(str.slice(0, -2));
const nmToPx = (nm) => `${nm}px`;

const touchTargetContains = (touchTarget, el) => {
	if (!touchTarget) {
		return false;
	}

	if (el === touchTarget) {
		return true;
	}

	return touchTargetContains(touchTarget.parentElement, el);
};

const ignoreTouchTarget = (touchTarget) => {
	if (!touchTarget) {
		return false;
	}

	if (touchTarget.getAttribute('data-ignoreslider') === 'true') {
		return true;
	}

	return ignoreTouchTarget(touchTarget.parentElement);
};

export default class ECRSlider extends EventEmitter {
	constructor(container, {
		startId,
		height,
		openDirective,
	}) {
		super();
		this.dismissed = false;
		this._bottomPaneOpen = false;
		this._locked = false;
		this._touch = null;
		this._currentId = startId;
		this._container = container;
		this._innerHeight = height;
		this._sticky = false;
		this.openDirective = openDirective;
		this._animationTimeouts = [];
		this.setIndex = -1;
		this._isGestureEnabled = true;
		this._panelOpening = false;
		this.boundOnTouchStart = this._onTouchStart.bind(this);
		this.boundOnTouchMove = this._onTouchMove.bind(this);
		this.boundOnTouchEnd = this._onTouchEnd.bind(this);

		this._container.addEventListener('touchstart', this.boundOnTouchStart);
		this._container.addEventListener('touchmove', this.boundOnTouchMove, { passive: false });
		this._container.addEventListener('touchend', this.boundOnTouchEnd);
		this._setPane(this.currentIndex, true);
	}

	get isGestureEnabled() {
		return !this._sticky && this._isGestureEnabled;
	}

	set isGestureEnabled(v) {
		this._isGestureEnabled = v;
	}

	get _panes() {
		const panes = [];

		const els = this._container.querySelectorAll('[data-pane]');
		for (let i = 0; i < els.length; i++) {
			const child = els[i];
			panes.push({
				id: child.getAttribute('data-uid'),
				contents: child.children[0],
				bottomPanel: child.children[1],
				editModal: child.children[2],
				div: child,
			});
		}

		return panes;
	}

	_onTouchStart(e) {
		if (this._locked || ignoreTouchTarget(e.target) || this._touch?.mode) {
			return;
		}

		e.preventDefault();

		const timeout = setTimeout(() => {
			if (this._touch.paused) {
				return;
			}
			this._touch.paused = true;
			this.emit(EVENTS.pause);
		}, PAUSE_THRESHOLD);

		e.time = Date.now();
		this._touch = {
			start: Date.now(),
			pausing: false,
			scrolling: null,
			direction: null,
			mode: null,
			timeout,
			startE: e,
			lastEs: [e],
			multiFinger: e.touches.length > 1,
		};

		this.emit(EVENTS.touchStart);
	}

	_onTouchMove(e) {
		if (!this.isGestureEnabled) {
			return;
		}

		if (this._locked || !this._touch) {
			return;
		}

		e.preventDefault();

		e.time = Date.now();
		if (e.touches.length > 1) {
			this._touch.multiFinger = true;
		}

		const lastE = this._touch.lastEs[0];
		const xDiff = e.touches[0].clientX - lastE.touches[0].clientX;
		const yDiff = e.touches[0].clientY - lastE.touches[0].clientY;

		if (!this._touch.scrolling) {
			if (Math.abs(xDiff) > SCROLL_THRESHOLD) {
				this._touch.scrolling = 'horizontal';
			} else if (Math.abs(yDiff) > SCROLL_THRESHOLD / 3) {
				this._touch.scrolling = 'vertical';
				this.emit(EVENTS.verticalScroll);
			}
		}

		if (this._touch.scrolling === 'vertical') {
			if (!this._touch.direction) {
				if (yDiff > 0) {
					this._touch.direction = 'uptodown';
				} else {
					this._touch.direction = 'downtoup';
				}
			}
		}

		const bp = this.currentPane?.bottomPanel;

		if (this._touch.direction === 'downtoup' || (bp?.open && this._touch.direction === 'uptodown')) {
			this._touch.mode = 'panelmove';
			this._handleBottomPaneMove({
				startTouch: this._touch.startE.touches[0],
				currentTouch: e.touches[0],
				open: bp?.open,
			});
		} else if (this._touch.direction === 'uptodown' && !bp?.open) {
			this._touch.mode = 'dismissmove';
			this._handleDismissMove({
				startTouch: this._touch.startE.touches[0],
				currentTouch: e.touches[0],
			});
		} else if (this._touch.scrolling === 'horizontal' && !bp?.open) {
			this._touch.mode = 'panemove';
			this._handleHorizontalMove({
				lastTouch: this._touch.lastEs[0].touches[0],
				currentTouch: e.touches[0],
			});
		}
		this._touch.lastEs.unshift(e);
		this._touch.lastEs.splice(5);
	}

	_onTouchEnd(e) {
		if (this._locked || !this._touch || e.touches.length > 0) {
			return;
		}

		const end = () => {
			clearTimeout(this._touch.timeout);
			this._touch = null;
		};

		const isTap = Date.now() - this._touch.startE.time <= TAP_THRESHOLD;
		if (this._touch.paused && !isTap) {
			this.emit(EVENTS.touchEnd, this._touch.mode);
		}

		const isBottomPaneTarget = touchTargetContains(this._touch.startE.target, this.currentPane.bottomPanel);

		// handle some tap logic with panel move
		if (this._bottomPaneOpen && isTap && isBottomPaneTarget) {
			if (this._touch.mode === 'panelmove') {
				// handle flick pane down
				this._handleBottomPaneEnd(this._touch.lastEs);
			}
			end();
			return;
		}

		// handle tap logic
		if (isTap) {
			if (this._bottomPaneOpen) {
				this._setBottomPanel(false);
				end();
				return;
			} if (!this._touch.mode) {
				const side = this._touch.startE.touches[0].clientX / window.innerWidth >= LEFT_THRESHOLD
					? 'right'
					: 'left';
				this.emit(EVENTS.tap, { side });
				end();
				return;
			}
		}

		// handle modes
		if (this._touch.mode === 'panelmove') {
			this._handleBottomPaneEnd(this._touch.lastEs);
		} else if (this._touch.mode === 'panemove') {
			this._handleHorizontalEnd({ lastEs: this._touch.lastEs, startE: this._touch.startE });
		} else if (this._touch.mode === 'dismissmove') {
			this._handleDismissMoveEnd({ lastEs: this._touch.lastEs, startE: this._touch.startE });
		}

		end();
	}

	bottomPaneFill() {
		// const bp = this.currentPane.bottomPanel;
		// bp.style.position = 'sticky';
		// debugger;
		this._sticky = true;
	}

	bottomPaneUnfill() {
		this._sticky = false;
		// TODO? pin to the top
		// if (this._bottomPaneOpen) {
		// 	const bp = this.currentPane.bottomPanel;
		// 	bp.style.top = 0;

		// 	setTimeout(() => {
		// 		bp.style.removeProperty('top');
		// 		this._setBottomPanel(true);
		// 	}, 50);
		// }
	}

	_setBottomPanel(open, options = {}) {
		this._panelOpening = true;
		this._animationTimeouts.forEach((a) => {
			clearTimeout(a);
		});

		this._animationTimeouts = [];

		const speed = options.speed || 'fast';
		const bp = this.currentPane.bottomPanel;
		const height = getLargerScreenDimension();
		bp.open = open;
		bp.style.position = 'fixed';
		this._bottomPaneOpen = open;

		const scrollDuration = speed === 'fast'
			? FAST_SCROLL_DURATION
			: SLOW_SCROLL_DURATION;

		bp.style.top = nmToPx(open ? MAX_DISTANCE_FROM_TOP : height);

		if (options.instant) {
			bp.style.removeProperty('transition');
			bp.style.top = nmToPx(open ? MAX_DISTANCE_FROM_TOP : height);
			this.emit(open ? EVENTS.bottomPanelOpen : EVENTS.bottomPanelClose);
			this.emit(open ? EVENTS.bottomPanelOpenFinished : EVENTS.bottomPanelCloseFinished);
			return;
		}

		bp.style.transition = `top ${scrollDuration / 1000}s ease-out`;

		this.emit(open ? EVENTS.bottomPanelOpen : EVENTS.bottomPanelClose);

		this._locked = true;
		const t2 = setTimeout(() => {
			bp.style.removeProperty('transition');
			this._locked = false;
			this.emit(open ? EVENTS.bottomPanelOpenFinished : EVENTS.bottomPanelCloseFinished);
		}, scrollDuration + 25);
		this._animationTimeouts.push(t2);
	}

	_setPane(index, instant = false, silent = false, direction = null) {
		if (this.dismissed) { return; }
		if (index < 0) { return; }
		if (index >= this._panes.length) { return; }
		if (!this._panes[index]) { return; }

		const emit = () => {
			if (!silent) {
				this.emit(EVENTS.setPane, { index, id, direction });
			}
		};

		const { div, id } = this._panes[index];
		const doEmit = id !== this._currentId;
		this._currentId = id;

		const scrollLeft = div.offsetLeft;

		if (instant) {
			this._container.scrollLeft = scrollLeft;
			if (!this.block) {
				doEmit && emit();
			}
		} else {
			this._locked = true;
			const sa = new ScrollAnimation({
				maxDuration: FAST_SCROLL_DURATION,
				el: this._container,
				left: scrollLeft,
				earlyDone: () => {
					// consider emitting here for fast playback
				},
				done: () => {
					this._locked = false;
					if (!this.block) {
						doEmit && emit();
					}
					sa.destroy();
				},
			});
		}

		this.setIndex = index;
	}

	_handleBottomPaneEnd(lastEs) {
		const e = lastEs[0];
		const tm = Date.now() - e.time > RELEASE_THRESHOLD;

		if (tm) {
			this._setBottomPanel(false);
			return;
		}

		const ys = lastEs.map((le) => le.touches[0].clientY);
		if (ys.length < 2 || ys[0] > ys[1]) {
			this._setBottomPanel(false);
			return;
		}

		this._setBottomPanel(true);
	}

	_handleBottomPaneMove({ startTouch, currentTouch, open }) {
		const { clientY: startY } = startTouch;
		const { clientY: currentY } = currentTouch;

		const bp = this.currentPane.bottomPanel;
		const height = window.innerHeight;

		const diffY = currentY - startY;

		if (!open) {
			if (diffY >= height) {
				bp.style.top = nmToPx(0);
			} else {
				const next = (height + diffY);
				bp.style.top = nmToPx(Math.max(MAX_DISTANCE_FROM_TOP, next));
			}
		} else if (diffY > 0) {
			bp.style.top = nmToPx(MAX_DISTANCE_FROM_TOP + diffY);
		} else {
			bp.style.top = nmToPx(MAX_DISTANCE_FROM_TOP);
		}
	}

	disableGesture() {
		this._isGestureEnabled = false;
	}

	enableGesture() {
		this._isGestureEnabled = true;
	}

	_dismissContent(isDismissMove = false) {
		this._locked = true;
		const { contents, id } = this.currentPane;

		this.currentPane.contents.classList.add('ecr-dismiss');

		const localDestroy = () => {
			window.removeEventListener('scroll', localDestroy);
			contents.style.removeProperty('transition');
			this.emit(EVENTS.dismiss, this.currentPane);
			setTimeout(() => {
				this.currentPane?.contents?.classList.remove('ecr-dismiss');
			}, 1);
		};

		window.addEventListener('scroll', localDestroy);

		if (this.openDirective?.type === 'slideIn' && !isDismissMove) {
			this.dismissed = true;
			contents.style.transition = 'all 300ms ease-out';
			window.requestAnimationFrame(() => {
				contents.style.transform = 'translate(100%)';
			});
			setTimeout(localDestroy, 300);
			return;
		}

		const selector = this.openDirective.selectorFn
			? this.openDirective.selectorFn(id)
			: this.openDirective.querySelector || `[data-experience-modal-id="${id}"]`;
		const te = document.querySelector(selector);
		const thumbnailElement = te && isElementInViewport(te) ? te : null;

		const dismissOpacity = te && te.attributes['data-dismiss-opacity'];

		contents.style.transition = dismissOpacity ? DISMISS_OPACITY_TRANSITION : DISMISS_TRANSITION;
		// contents.style.transition = 'all 30000ms ease-in-out, opacity 45000ms ease-in-out'; // DISMISS_TRANSITION;

		window.requestAnimationFrame(() => {
			if (thumbnailElement && isElementInViewport(thumbnailElement)) {
				const dimensions = calculateTrueBoxDimensions(thumbnailElement);

				document.querySelectorAll('[data-resize-on-dismiss="true"]')
					.forEach((el) => el.style.height = '100%');

				contents.style.borderRadius = window.getComputedStyle(thumbnailElement).borderRadius || '2px';
				contents.style.overflow = 'hidden';
				// contents.style.transform = `scale(${scale})`;
				contents.style.removeProperty('transform');
				contents.style.removeProperty('margin-left');
				contents.style.removeProperty('margin-top');

				//
				contents.style.borderRadius = `${dimensions.borderRadius}px`;
				contents.style.borderWidth = `${dimensions.borderWidth}px`;
				contents.style.height = `${dimensions.height}px`;
				contents.style.width = `${dimensions.width}px`;
				contents.style.left = `${dimensions.left}px`;
				contents.style.top = `${dimensions.top}px`;
				// contents.style.marginLeft = `${translateX}px`;
				// contents.style.marginTop = `${translateY}px`;
				if (dismissOpacity) {
					contents.style.height = '1px';
					contents.style.width = '1px';
					contents.style.left = `${dimensions.left + dimensions.width / 2}px`;
					contents.style.top = `${dimensions.top + dimensions.height / 2}px`;
					contents.style.opacity = 0;
				}
			} else {
				const translateX = (window.innerWidth - contents.offsetWidth) / 2;
				const translateY = window.innerHeight - 68 - (contents.offsetHeight * (0.4 / 2)); // ; - (contents.offsetHeight * 0.4);

				contents.style.transform = `translate(${translateX}px, ${translateY}px) scale(0)`;
				contents.style.borderRadius = '12px';
				contents.style.overflow = 'hidden';
				contents.style.transform = 'scale(0, 0)';
				contents.style.marginLeft = `${translateX}px`;
				contents.style.marginTop = `${translateY}px`;
				contents.style.opacity = 0;
			}

			setTimeout(localDestroy, 300);
		});

		this.dismissed = true;
	}

	_expandContent() {
		const { contents } = this.currentPane;
		contents.style.transformOrigin = `${this._tapX}px ${this._tapY}px`;

		contents.style.transition = TRANSITION;
		setTimeout(() => {
			contents.style.transform = 'scale(1)';
			contents.style.top = '0';

			setTimeout(() => {
				contents.style.removeProperty('transition');
				contents.style.removeProperty('transform-origin');
				this.emit(EVENTS.expand, this.currentPane);
			}, FAST_SCROLL_DURATION);
		}, 1);
	}

	_returnContent(instant = false) {
		const { contents } = this.currentPane;

		if (!instant) {
			contents.style.transition = TRANSITION;
		}

		contents.style.removeProperty('margin-top');
		contents.style.removeProperty('margin-left');
		contents.style.removeProperty('border-radius');
		contents.style.removeProperty('transform');
		contents.style.removeProperty('transition');
		this._locked = true;

		setTimeout(() => {
			this._locked = false;
			this.emit(EVENTS.play);
		}, FAST_SCROLL_DURATION);
	}

	_handleDismissMoveEnd({ lastEs, startE }) {
		const l1 = lastEs[0];
		const l2 = lastEs[1];
		const tm = Date.now() - l1.time > RELEASE_THRESHOLD;

		if (tm) {
			this._returnContent();
			return;
		}

		if (l1.touches[0].clientY <= startE.touches[0].clientY + ACTION_THRESHOLD) {
			this._returnContent();
			return;
		}

		if (l1.touches[0].clientY > l2.touches[0].clientY) {
			this._dismissContent(true);
		} else {
			this._returnContent();
		}
	}

	_handleDismissMove({ startTouch, currentTouch }) {
		const { clientY: startY, clientX: startX } = startTouch;
		const { clientY: currentY, clientX: currentX } = currentTouch;

		const diffY = (startY - currentY) / 1.5;
		const diffX = (startX - currentX) / 1.5;

		const { contents } = this.currentPane;
		const mt = -diffY;
		const ml = -diffX;

		contents.style.marginTop = `${mt}px`;
		contents.style.marginLeft = `${ml}px`;

		if (mt === 0) {
			contents.style.removeProperty('transform');
			contents.style.removeProperty('border-radius');
		} else {
			contents.style.transition = TRANSITION;
			const scale = Math.max((window.innerHeight + diffY) / window.innerHeight, 0.2);
			contents.style.transform = `scale(${scale})`;
			contents.style.borderRadius = '20px';
		}
	}

	_handleHorizontalEnd({ lastEs, startE }) {
		const l1 = lastEs[0];
		const l2 = lastEs[1];
		const tm = Date.now() - l1.time > RELEASE_THRESHOLD;

		if (tm) {
			this._setPane(this.currentIndex);
			return;
		}

		if (l1.touches[0].clientX > l2.touches[0].clientX) {
			if (l1.touches[0].clientX > startE.touches[0].clientX + ACTION_THRESHOLD) {
				this.previous();
			} else {
				this._setPane(this.currentIndex);
			}
		} else if (l1.touches[0].clientX < startE.touches[0].clientX - ACTION_THRESHOLD) {
			this.next(false);
		} else {
			this._setPane(this.currentIndex);
		}
	}

	_handleHorizontalMove({ lastTouch, currentTouch }) {
		const { clientX: startX } = lastTouch;
		const { clientX: currentX } = currentTouch;

		// const maxWidth = window.innerWidth;
		// const furthestLeft = Math.max(maxWidth * (this.currentIndex - 1), 0);
		// const furthestRight = maxWidth * (this.currentIndex + 1);

		const diffX = startX - currentX;
		const total = this._container.scrollLeft + diffX;
		this._container.scrollLeft = total;
	}

	_handleEditModalMove({ lastTouch, currentTouch, editModal }) {
		const diffY = lastTouch.clientY - currentTouch.clientY;
		const container = editModal?.firstChild;
		if (container) {
			editModal.firstChild.scrollTop += diffY;
		}
	}

	get currentIndex() {
		return this._panes.findIndex((p) => p.id === this._currentId);
	}

	get currentPane() {
		return this._panes[this.currentIndex];
	}

	lockPane(id) {
		if (id) {
			this._currentId = id;
		}

		if (this.currentIndex > -1) {
			this._setPane(this.currentIndex, true, true);
		}
	}

	expand() {
		this._expandContent();
	}

	dismiss() {
		this._dismissContent();
	}

	//
	setPane() {
		this._setPane(this.currentIndex, true);
	}

	// transition to next pane
	next(dismiss = true) {
		const n = this.currentIndex + 1;

		if (n >= this._panes.length) {
			this._setPane(this.currentIndex);
			this._dismissContent();
			return;
		}

		if (dismiss) {
			this._dismissContent();
			return;
		}

		this._setPane(n);
	}

	// transition to previous pane
	previous() {
		if (this.currentIndex === 0) {
			this.emit(EVENTS.play);
			this._setPane(this.currentIndex);
		} else {
			this._setPane(this.currentIndex - 1, false, false, 'backwards');
		}
	}

	// open the bottom pane
	openBottomPane(options) {
		this._setBottomPanel(true, options);
	}

	// close the bottom page
	closeBottomPane() {
		this._setBottomPanel(false);
	}

	// toggle bottom pane
	toggleBottomPanel() {
		this._setBottomPanel(!this._bottomPaneOpen);
	}

	// get if the bottom pane is open
	get bottomPaneOpen() {
		return this._bottomPaneOpen;
	}

	// restore content
	restore() {
	}

	// hide ecr
	hide() {
		this._container.style.opacity = 0;
	}

	// show ecr
	show() {
		this._container.style.opacity = 1;
	}

	// add a pane to beginning of slider
	prepend(p) {
		this._panes.unshift(p);
	}

	// add a pane to end of slider
	append(p) {
		this._panes.push(p);
	}

	// pop a pane off end of slider
	pop() {
		this._panes.pop();
	}

	// shift a pane off beginning of slider
	shift() {
		this._panes.shift();
	}

	// destroy all dom nodes
	destroy() {
		this._container.removeEventListener('touchstart', this.boundOnTouchStart);
		this._container.removeEventListener('touchmove', this.boundOnTouchMove);
		this._container.removeEventListener('touchend', this.boundOnTouchEnd);
	}
}
