import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	iconContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		cursor: 'pointer',
	},
});

export default ({
	className,
	children,
	size,
	style = {},
	...rest
}) => {
	const classes = useStyles();

	style.height = style.height || size;
	style.width = style.width || size;

	const klass = className
		? clsx(classes.iconContainer, className)
		: classes.iconContainer;

	return (
		<div className={klass} style={style} {...rest}>
			{children}
		</div>
	);
};
