import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useTheme from 'rds/theme/useRdsTheme';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 10,
		position: 'fixed',
		top: 0,
		padding: theme.spacing(2),
	},
	link: {
		height: 48,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	disable: {
		color: theme.colors.neutral.C500,
	},
}));

export default ({
	icon,
	onClick,
	label,
	to,
	left,
	relative,
	right,
	zIndex,
	variant,
	disabled,
	exceptionColor,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	let color;
	if (variant) {
		color = theme.palette[variant].contrastTest;
	} else if (exceptionColor) {
		color = exceptionColor;
	}
	const style = {
		color,
		left,
		right,
		zIndex,
		position: relative ? 'relative' : undefined,
		opacity: disabled ? 0.7 : 1,
		background: 'none',
	};

	if (label) {
		if (to) {
			return (
				<Button
					className={clsx(classes.root, classes.link)}
					onClick={onClick}
					to={to}
					style={style}
					component={Link}
					disableRipple
					disableFocusRipple
				>
					{label}
				</Button>
			);
		}
		return (
			<Button
				className={clsx(classes.root, classes.link)}
				style={style}
				onClick={onClick}
				disableRipple
				disableFocusRipple
			>
				{label}
			</Button>
		);
	}

	return (
		<div
			className={classes.root}
			style={style}
			onClick={onClick}
		>
			{icon}
		</div>
	);
};
