import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import useTheme from 'rds/theme/useRdsTheme';

const useStyles = makeStyles((theme) => ({
	absolute: {
		position: 'absolute',
		bottom: -theme.spacing(2.5),
	},
	fixedContainer: {
		bottom: 0,
		left: 0,
		right: 0,
		height: 30,
		position: 'fixed',
		textAlign: 'right',
		color: theme.colors.neutral.C000,
		background: theme.colors.neutral.C900,
		zIndex: 30,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row-reverse',
		paddingRight: theme.spacing(6),
		borderTop: `1px solid ${theme.colors.neutral.C000}`,
	},
}));

export default ({
	bottom,
	color,
	maxLength,
	value,
	fixed,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const style = {
		color: theme.palette.text[color],
	};

	if (bottom) {
		style.bottom = bottom;
	}

	if (fixed) {
		return (
			<div className={classes.fixedContainer}>
				<Typography className={classes.fixed} variant="body2">
					{`${value}/${maxLength}`}
				</Typography>
			</div>
		);
	}

	return (
		<Typography className={classes.absolute} style={style} variant="caption">
			{`${value}/${maxLength}`}
		</Typography>
	);
};
