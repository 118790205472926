import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import Avatar from 'rds/components/Avatar';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	header: {
		zIndex: 3,
		display: 'flex',
		justifyContent: 'left',
		touchAction: 'none',
	},
	creator: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		lineHeight: 16.8,
		height: 48,
		width: '100%',
	},
	chevronDownContainer: {
		marginRight: theme.spacing(2.5),
		filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.4))',
		paddingTop: 6,
	},
	username: {
		color: '#FFFFFF',
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(),
		lineHeight: 1,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		textAlign: 'left',
		width: '80%',
	},
	usernameV2: {
		...theme.typography.subtitle3,
		marginTop: 0,
	},
	right: {
		display: 'flex',
		position: 'absolute',
		right: -20,
		top: theme.spacing(6),
	},
	left: {
		display: 'flex',
		position: 'absolute',
		left: -20,
		top: theme.spacing(6),
	},
	eachAvatar: {
		background: 'yellow',
		marginRight: theme.spacing(1.5),
		marginLeft: theme.spacing(1.5),
	},
	identities: {
		lineHeight: 1,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		textAlign: 'left',
		width: '90%',
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(),
		color: '#FFFFFF',
	},
	identitiesV2: {
		...theme.typography.caption,
	},
	infoContainer: {
		width: '80%',
	},
	headerInside: {
		position: 'absolute',
		top: theme.spacing(),
		left: theme.spacing(),
		width: '100%',
	},
	headerOnTop: {
		width: '100%',
		marginTop: theme.spacing(),
		right: 0,
		marginLeft: theme.spacing(),
	},
}));

export default ({ user, isLargeScreen }) => {
	const classes = useStyles();
	const customIdentifiers = user?.customIdentifiers || [];
	const identifiers = user?.identifiers || [];
	const combinedArray = customIdentifiers && customIdentifiers.concat(identifiers);

	const identities = combinedArray.map((obj) => obj.title);
	identities.sort((a, b) => a.localeCompare(b));

	let identifiersOutput = '';
	identities.forEach((identity, index) => {
		identifiersOutput += identity;
		if (index !== identities.length - 1) {
			identifiersOutput += ' • ';
		}
	});

	return (
		<>
			<div className={isLargeScreen ? clsx(classes.header, classes.headerOnTop) : clsx(classes.header, classes.headerInside)}>
				<div className={!combinedArray.length ? classes.creatorTwo : classes.creator}>
					<div className={classes.avatarContainer}>
						<Avatar
							size={isLargeScreen ? 'medium' : 'xxxxSmall'}
							alt="header"
							src={user?.avatar?.small?.jpg}
						/>
					</div>
					<div className={classes.infoContainer}>
						<Typography variant="subtitle2" className={isLargeScreen ? classes.username : clsx(classes.username, classes.usernameV2)}>{user?.username}</Typography>
						{identities && !!identities.length && (
							<Typography
								variant="body2"
								className={isLargeScreen ? classes.identities : clsx(classes.identities, classes.identitiesV2)}
							>
								{identifiersOutput}
							</Typography>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
