import { useState, useEffect } from 'react';
import debounce from 'utils/debounce';
import getLargerScreenDimension from 'utils/get-device-max-height';

const DEBOUNCE_TIME = 250;

const getInitialDimensions = () => Math.max(window.innerWidth, window.innerHeight);

const useScreenDimensions = () => {
	const [largerDimension, setLargerDimension] = useState(getInitialDimensions());

	useEffect(() => {
		const debouncedHandleResize = debounce(() => {
			setLargerDimension(getLargerScreenDimension());
		}, DEBOUNCE_TIME);

		window.addEventListener('resize', debouncedHandleResize);

		return () => {
			window.removeEventListener('resize', debouncedHandleResize);
		};
	}, []);

	return largerDimension;
};

export default useScreenDimensions;
