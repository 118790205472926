import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearPercentage,
	requestPermissions,
	getEnabled,
	getErrors,
	getLoading,
	reset,
	setCreateNavigation,
} from 'app/slices/content-creation/create/device-library/state';
import RequestProvider from 'rds/providers/Request';

import { gotoPermissions } from 'app/android-service/util';
import { getPort } from 'app/android-service';
import DeviceLibraryModal from './DeviceLibraryModal';

const Next = ({
	show,
}) => {
	const enabled = useSelector(getEnabled);

	useEffect(() => {
		if (enabled === null || typeof enabled === 'undefined') {
			gotoPermissions();
		}
	}, [enabled]);

	return <DeviceLibraryModal show={show} />;
};

export default ({
	show,
}) => {
	const dispatch = useDispatch();
	const errors = useSelector(getErrors);
	const port = getPort();

	useEffect(() => {
		dispatch(clearPercentage(0));

		return () => {
			dispatch(clearPercentage(0));
		};
	});

	useEffect(() => {
		if (show) {
			dispatch(setCreateNavigation({
				open: false,
				slide: true,
			}));
		}
	}, [show]);

	if (errors || port === null) {
		// return <Redirect to="/experience/create/photo" />;
		return null;
	}

	return (
		<RequestProvider
			requests={{
				permission: {
					selector: getEnabled,
					action: requestPermissions,
					errors: getErrors,
					loading: getLoading,
					reset,
					resetConditional: () => false,
				},
			}}
		>
			<Next show={show} />
		</RequestProvider>
	);
};
