import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import HRNumbers from 'human-readable-numbers';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import { requestToggleSaveExperience } from 'app/slices/saves/index';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		bottom: theme.spacing(6),
		right: window.innerWidth < 390 ? theme.spacing() : theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 11,
		filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.4))',
		width: 48,
	},
	messageIconContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	counts: {
		...theme.typography.title3,
		marginTop: theme.spacing(-2),
		height: 16.8,
	},
}));

export default ({ experience, openPanel }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const commentsCount = experience?.stats?.comments;
	const counts = commentsCount ? HRNumbers.toHumanString(commentsCount) : 0;
	const bookMarked = experience?.interactions?.callerHasSaved ? 'BookmarkFilled' : 'BookmarkUnfilled';

	return (
		<div className={clsx(classes.root, 'ecr-dismiss-quickfade')} data-ignoreslider="true">
			<IconV3
				icon="DetailsFilled"
				data-ignoreslider="true"
				onClick={() => {
					openPanel('details');
				}}
				size={32}
			/>
			<IconV3
				icon={bookMarked}
				data-ignoreslider="true"
				size={32}
				onClick={() => {
					if (!experience?.interactions?.callerHasSaved) {
						dispatch(requestToggleSaveExperience({ experience, save: true }));
					} else {
						dispatch(requestToggleSaveExperience({ experience, save: false }));
					}
				}}
				style={{ marginTop: 6 }}
			/>
			<div className={classes.messageIconContainer}>
				<IconV3
					icon="MessageCircleFilled"
					data-ignoreslider="true"
					onClick={() => {
						openPanel('comments');
					}}
					size={32}
					style={{ marginTop: 6 }}
				/>
				<Typography className={classes.counts}>
					{counts}
				</Typography>
			</div>
		</div>
	);
};
