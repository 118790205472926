import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import ECRLink from 'features/ExperienceModal/ECRLink';
import Typography from 'rds/components/Typography';
import Avatar from 'rds/components/Avatar';
import { useFormUrl } from 'app/hooks/useQueryParams';
import IconV3 from 'rds/components/IconV3';
import emitter from 'features/ExperienceModal/emitter';
import useUserAccountLink from 'app/hooks/useUserAccountLink';
import WaveSkeleton from 'components/WaveSkeleton';
import {
	getLoadingSingleExperience,
} from 'app/slices/experience-modal';
import { getExperienceCreatorCustomIdentifiers } from 'app/slices/user-account-v2/accounts';
import { change } from 'app/slices/experience-view';

const useStyles = makeStyles((theme) => ({
	header: {
		position: 'absolute',
		top: theme.spacing(2.5),
		marginBottom: theme.spacing(2.5),
		right: 0,
		left: 0,
		height: 80,
		zIndex: 3,
		display: 'flex',
		justifyContent: 'left',
		touchAction: 'none',
	},
	background: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		height: '20.75%',
		background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.146743) 36.98%, rgba(0, 0, 0, 0.260417) 65.62%, rgba(0, 0, 0, 0.4) 100%);',
		zIndex: 2,
		touchAction: 'none',
		transform: 'rotate(-180deg)',
	},
	creator: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		lineHeight: 16.8,
		height: 48,
		marginTop: theme.spacing(3),
	},
	creatorTwo: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'left',
		lineHeight: 16.8,
		height: 48,
	},
	chevronDownContainer: {
		marginRight: theme.spacing(2.5),
		filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.4))',
		paddingTop: 6,
	},
	username: {
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		marginLeft: theme.spacing(2),
		lineHeight: 1,
	},
	right: {
		display: 'flex',
		position: 'absolute',
		right: -20,
		top: theme.spacing(6),
	},
	left: {
		display: 'flex',
		position: 'absolute',
		left: -20,
		top: theme.spacing(6),
	},
	eachAvatar: {
		background: 'yellow',
		marginRight: theme.spacing(1.5),
		marginLeft: theme.spacing(1.5),
	},
	identities: {
		color: theme.colors.neutral.C000,
		marginTop: theme.spacing(),
		marginLeft: theme.spacing(2),
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: '50vw',
	},
	expandedIdentities: {
		color: theme.colors.neutral.C000,
		marginTop: theme.spacing(),
		marginLeft: theme.spacing(2),
		maxWidth: '50vw',
		whiteSpace: 'break-spaces',
	},
	overflowDots: {
		position: 'absolute',
		right: 0,
		top: theme.spacing(2.5),
		paddingTop: 6,
		zIndex: 12,
	},
}));

export default ({ user }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const formUrl = useFormUrl();
	const loading = useSelector(getLoadingSingleExperience);
	const [expandIdentities, setExpandIdentities] = useState(false);
	const customIdentifiers = useSelector(getExperienceCreatorCustomIdentifiers(user)) || [];
	const identifiers = user?.identifiers || [];
	const combinedArray = customIdentifiers && customIdentifiers.concat(identifiers);

	const identities = combinedArray.map((obj) => obj.title);
	identities.sort((a, b) => a.localeCompare(b));

	let identifiersOutput = '';
	identities.forEach((identity, index) => {
		identifiersOutput += identity;
		if (index !== identities.length - 1) {
			identifiersOutput += ' • ';
		}
	});

	const to = formUrl({
		params: {
			experience: undefined,
		},
		path: useUserAccountLink(user.username),
	});

	return (
		<div className="ecr-dismiss-quickfade">
			<div className={classes.overflowDots} data-ignoreslider="true">
				<IconV3
					icon="Dots"
					data-ignoreslider="true"
					size={32}
					style={{ filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.4))' }}
					onClick={() => {
						dispatch(change({ route: 'overflow' }));
					}}
				/>
			</div>
			<div className={classes.header}>
				<div className={classes.chevronDownContainer}>
					<IconV3
						icon="ChevronDown"
						size={32}
						dropShadow
						data-ignoreslider="true"
						onClick={() => {
							emitter.emit('dismiss');
						}}
					/>
				</div>
				<div className={!combinedArray.length ? classes.creatorTwo : classes.creator}>
					<div className={classes.avatarContainer}>
						<ECRLink
							data-ignoreslider="true"
							to={to}
						>
							<Avatar
								size="medium"
								alt="header"
								src={user?.avatar?.small?.jpg}
							/>
						</ECRLink>
					</div>
					<div className={classes.infoContainer}>
						{loading ? <WaveSkeleton style={{ width: 99, height: 12, marginLeft: 8 }} />
							: (
								<ECRLink
									data-ignoreslider="true"
									to={to}
								>
									<Typography variant="subtitle2" className={classes.username}>{user?.username}</Typography>
								</ECRLink>
							)}
						{identities && !!identities.length && (
							<Typography
								variant="body2"
								data-ignoreslider="true"
								className={expandIdentities ? classes.expandedIdentities : classes.identities}
								onClick={(e) => {
									e.preventDefault();
									setExpandIdentities(!expandIdentities);
								}}

							>
								{identifiersOutput}
							</Typography>
						)}
					</div>
				</div>
			</div>
			<div className={classes.background} />
		</div>
	);
};
