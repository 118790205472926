import { registerRoute } from 'Router/registry';
import signUpBackground from 'routes/Auth/assets/signup/background.webp';
import SignUp from './SignUp';
import SignUpFailure from './Failure';
import SignUpCompletion from './Completion';

registerRoute({
	authorization: undefined,
	Component: SignUp,
	routeOptions: {
		path: '/signup',
	},
	Background: signUpBackground,
	transition: 'slide',
});

registerRoute({
	authorization: false,
	Component: SignUpFailure,
	routeOptions: {
		path: '/signup/failure',
	},
	Background: signUpBackground,
	transition: 'slide',
});

registerRoute({
	authorization: false,
	Component: SignUpCompletion,
	routeOptions: {
		path: '/signup/completion',
	},
	Background: signUpBackground,
	transition: 'slide',
});
