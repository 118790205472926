/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
import { analyticsTrackingId } from 'config';

const GoogleAnalytics = ({ trackingId = analyticsTrackingId }) => {
	const script = document.createElement('script');
	script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
	script.async = true;
	document.head.appendChild(script);

	script.onload = () => {
		window.dataLayer = window.dataLayer || [];
		function gtag() { dataLayer.push(arguments); }
		window.gtag = gtag;
		gtag('js', new Date());
		gtag('config', trackingId);
	};

	return null;
};

export default GoogleAnalytics;
