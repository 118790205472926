import { registerRoute } from 'Router/registry';
import VerifyEmail from './VerifyEmail';

registerRoute({
	authorization: undefined,
	Component: VerifyEmail,
	routeOptions: {
		path: '/verify-email/:token',
	},
	transition: 'slide',
});
