import createImageObjectFromBlob from './create-image-object-from-blob';

const supportedMimeTypes = {
	webm: 'webm',
	'x-matroska': 'mkv',
	mp4: 'mp4',
	png: 'png',
	gif: 'gif',
	jpeg: 'jpeg',
	jpg: 'jpg',
};

class AssetFile {
	constructor({
		key,
		blob,
	}) {
		this.video = document.createElement('video');
		this.key = key;
		this.blob = blob;
		this.url = window.URL.createObjectURL(blob);
		this.video.src = this.url;
		this.video.style.position = 'absolute';
		this.video.style.visibility = 'hidden';
		this.video.style.width = '1px';
		this.video.style.height = '1px';
		document.body.appendChild(this.video);

		this.video.setAttribute('preload', 'auto');
		this.video.setAttribute('muted', '');
		this.video.setAttribute('loop', '');
		this.video.setAttribute('controls', '');
		this.video.setAttribute('autoplay', '');
		this.video.setAttribute('playsinline', '');
		this.video.muted = true;
	}

	get name() {
		return `${this.key}-${this.type}`;
	}

	get type() {
		return (this.blob.type ? this.blob.type.split('/')[0] : 'unknown');
	}

	get ext() {
		const mimeType = this.blob.type;

		// Iterate through the supported MIME types and check if the Blob's MIME type includes the supported type
		const supportedTypes = Object.keys(supportedMimeTypes);
		for (let i = 0; i < supportedTypes.length; i++) {
			const supportedType = supportedTypes[i];
			if (mimeType.includes(supportedType)) {
				return supportedMimeTypes[supportedType];
			}
		}

		return null;
	}

	get filename() {
		if (this.ext) {
			return `${this.name}.${this.ext}`;
		}
		return this.name;
	}

	destroy() {
		if (this.video) {
			try {
				this.video.remove();
			} catch (_) {
				// Safe to ignore error
			}
			this.video = null;
		}
	}

	async getImage(options) {
		options.blob = this.blob;
		options.video = this.video;
		const image = await createImageObjectFromBlob(options);
		return image;
	}
}

export default AssetFile;
