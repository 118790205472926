import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import api, { CancelToken } from 'services/api';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import { getFormPayload } from './form';

const stripSpecialChars = (str) => {
	if (str.startsWith('@') || str.startsWith('+')) {
		return str.substring(1);
	}
	return str;
};

export const requestSearchDestinations = createAsyncThunk(
	'requestSearchDestinations',
	async (_, { signal, getState, rejectWithValue }) => {
		const pl = getFormPayload(getState());
		const source = CancelToken.source();
		signal.addEventListener('abort', () => {
			source.cancel();
		});

		try {
			const search = encodeURIComponent(pl.search);
			const response = await api.get(`destinations/?includeThirdParty=true&q=${search}`, { cancelToken: source.token });
			response.data.lastSearch = pl.search;
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},	{
		condition: (_, { getState }) => {
			const { destinations } = getSlice(getState());
			const currentSearch = getFormPayload(getState()).search;
			const strippedCurrentSearch = stripSpecialChars(currentSearch);
			const strippedLastSearch = stripSpecialChars(destinations.lastSearch);
			return strippedCurrentSearch !== strippedLastSearch;
		},
	},
);

export const requestSearchUsers = createAsyncThunk(
	'requestSearchUsers',
	async (_, { signal, getState, rejectWithValue }) => {
		const pl = getFormPayload(getState());
		const source = CancelToken.source();
		signal.addEventListener('abort', () => {
			source.cancel();
		});
		try {
			const search = encodeURIComponent(pl.search);
			const response = await api.get(`content/v0/users/search/?q=${search}`, { cancelToken: source.token });
			response.data.lastSearch = pl.search;
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
	{
		condition: (_, { getState }) => {
			const { users } = getSlice(getState());
			const currentSearch = getFormPayload(getState()).search;
			const strippedCurrentSearch = stripSpecialChars(currentSearch);
			const strippedLastSearch = stripSpecialChars(users.lastSearch);
			return strippedCurrentSearch !== strippedLastSearch;
		},
	},
);

export const searchSlice = createSlice({
	name: 'searchExploreSearch',
	initialState: {
		destinations: {
			results: [],
			lastSearch: '',
		},
		users: {
			results: [],
			lastSearch: '',
		},
		errors: null,
		loading: false,
	},
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		reset: (state) => {
			state.users = [];
			state.destinations = [];
		},
	},
	extraReducers: {
		[requestSearchDestinations.pending]: (state) => {
			state.destinations.results = [];
			state.loading = true;
			state.errors = null;
		},
		[requestSearchDestinations.fulfilled]: (state, action) => {
			const newDestinations = [];
			const entities = action?.payload?.destinations;
			const type = 'destination';
			for (let i = 0; i < entities.length; i++) {
				newDestinations.push({ entity: entities[i], type });
			}
			state.destinations.lastSearch = action?.payload?.lastSearch;
			state.destinations.results = newDestinations;
			state.loading = false;
			state.errors = null;
		},

		[requestSearchDestinations.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestSearchUsers.pending]: (state) => {
			state.users.results = [];
			state.loading = true;
			state.errors = null;
		},
		[requestSearchUsers.fulfilled]: (state, action) => {
			state.users.results = action?.payload?.entities;
			state.users.lastSearch = action?.payload?.lastSearch;
			state.loading = false;
			state.errors = null;
		},
		[requestSearchUsers.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = searchSlice;
const {
	reset,
	setLoading,
} = actions;

export {
	reset,
	setLoading,
};

export const getSlice = (state) => state[name];
export const getIsFirstLoad = createSelector(getSlice, (slice) => slice?.firstLoad);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getLoadingMore = createSelector(getSlice, (slice) => slice?.loadingMore);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);
export const getSearchedUsersAndDestinations = createSelector(getSlice, (slice) => {
	const users = slice?.users?.results;
	const destinations = slice?.destinations?.results;
	const combo = destinations.concat(users);
	return combo;
});
export const getTopThreeResults = createSelector(getSlice, (slice) => {
	const users = slice?.users?.results;
	const destinations = slice?.destinations?.results.slice(0, 3);
	const combo = destinations.concat(users);
	return combo;
});

injectReducer(name, reducer);
