import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { registerRoute } from 'Router/registry';
import useNavigateCC from 'app/hooks/useNavigateCC';
import './Router';

const CCRedirect = () => {
	const navigateCC = useNavigateCC();
	const { tab } = useParams();

	navigateCC('create', {
		tab,
		subTab: '',
	});

	return <Navigate to="/" />;
};

registerRoute({
	authorization: undefined,
	Component: CCRedirect,
	routeOptions: {
		path: '/experience/create/:tab',
	},
	transition: 'none',
});
