import { createFormSlice } from 'services/slice';

export const searchAndExploreFormSlice = createFormSlice({
	name: 'searchAndExploreFormSlice',
	initialState: {
		search: '',
		errors: [],
	},
});

const {
	// slice
	control, reducer,
	// actions
	resetErrors, resetForm, setErrors, setFormField,
	// selectors
	getFormField, getFormErrors, getFormFieldError, getFormPayload,
} = searchAndExploreFormSlice;

export {
	control, reducer,
	resetErrors, resetForm, setErrors, setFormField,
	getFormField, getFormErrors, getFormFieldError, getFormPayload,
};

export default reducer;
