import { useEffect, useState, useRef } from 'react';

const useResizeHandler = (actions) => {
	const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
	const initialHeight = useRef(window.innerHeight);

	useEffect(() => {
		const handleResize = () => {
			const isNowOpen = window.innerHeight < initialHeight.current;
			if (isKeyboardOpen && !isNowOpen) {
				setIsKeyboardOpen(false);
				actions.onKeyboardClose();
			}
			setIsKeyboardOpen(isNowOpen);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isKeyboardOpen, actions]);

	return isKeyboardOpen;
};

export default useResizeHandler;
