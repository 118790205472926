import axios, { isCancel, CancelToken } from 'axios';
import storage from 'store';
import store from 'services/store';
import { isSessionAuthorized, endSession } from 'app/slices/authentication/session/session';
import { apiHost } from 'config';

const instance = axios.create({
	baseURL: apiHost,
	timeout: 60000,
});

instance.interceptors.request.use((options) => {
	const token = storage.get('token');

	if (options?.signal && !options?.cancelToken) {
		const { signal } = options;
		const source = CancelToken.source();

		options.cancelToken = source.token;

		signal.addEventListener('abort', () => source.cancel());
	}

	if (!token) {
		return options;
	}

	return {
		...options,
		headers: {
			...options.headers,
			Authorization: `Bearer ${token}`,
		},
	};
});

instance.interceptors.response.use(null, (error) => {
	const { isAxiosError } = error;

	if (!isAxiosError) {
		return Promise.reject(error);
	}

	const { response } = error;

	if (!response) {
		return Promise.reject(error);
	}
	const { status } = response;

	if (status === 401 && isSessionAuthorized(store.getState())) {
		store.dispatch(endSession());
	}

	return Promise.reject(error);
});

export { isCancel, CancelToken };
export default instance;
