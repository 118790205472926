import colors from 'rds/colors';

export default ({
	legColor = colors.neutral.C600,
	bodyColor = colors.primary.C300,
}) => `<svg width="512" height="343" viewBox="0 0 512 343" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M209.892 231.935C270.725 255.154 290.784 256.631 290.784 256.631C294.907 276.644 283.248 311.144 267.196 322.748C276.463 325.503 281.606 331.235 282.627 339.944L228.411 338.621C222.451 336.394 222.304 330.956 227.97 322.307C224.612 303.968 231.976 301.294 225.323 283.072C213.295 268.577 208.151 251.532 209.892 231.935Z" fill="${legColor}" />
			<path fillRule="evenodd" clipRule="evenodd" d="M81.8335 231.935L173.088 266.768C176.644 284.072 156.207 318.22 139.101 322.748C148.362 325.503 153.506 331.235 154.533 339.944L100.389 338.621C94.4346 336.394 94.2876 330.956 99.9475 322.307C97.7988 316.083 98.2399 309.031 101.271 301.15C102.111 297.495 100.791 291.469 97.3096 283.072C85.2516 268.577 80.0929 251.532 81.8335 231.935Z" fill="${legColor}" />
			<path fillRule="evenodd" clipRule="evenodd" d="M205.949 12.3528C182.541 6.60891 165.03 7.75228 141.757 13.1901C121.888 17.8266 104.152 25.9113 91.0706 36.1297C77.287 47.3114 66.4655 59.6094 58.3088 75.4546C55.968 80.0011 53.7262 83.5573 51.6106 88.3649C48.5315 95.3962 45.8486 100.879 43.4538 108.306C39.5688 120.188 37.5166 132.593 37.3678 145.093C37.0347 185.489 57.2554 212.741 58.2277 218.782C59.3261 225.57 58.984 228.1 61.3158 234.69C112.858 281.11 217.148 292.138 281.321 266.633C286.21 264.688 291.558 261.663 295.996 260.07C299.913 258.656 308.114 263.329 312.382 264.571C326.876 268.929 368.2 276.923 383.901 278.976C407.426 283.189 437.019 281.353 448.417 261.573C449.065 260.457 449.002 260.736 450.433 260.34C477.892 252.723 490.767 216.558 500.292 191.917C499.965 190.44 499.52 188.992 498.959 187.587C480.953 169.581 474.723 160.524 448.858 151.944C446.211 151.044 446.481 149.792 446.157 147.181C445.752 143.499 445.671 139.808 445.257 136.117C441.934 111.124 434.111 88.599 415.826 70.557C394.732 50.6424 390.284 50.2553 364.464 39.7669C348.718 33.3748 364.815 -0.152315 344.297 0.153786C325.634 0.396866 341.263 33.6268 314.587 32.9786C294.943 32.5015 303.397 -2.35804 283.896 0.126778C263.018 2.78266 276.901 41.4504 254.763 34.5091C247.839 32.3394 237.027 24.2908 229.554 20.5455L226.736 19.2581C219.953 16.4463 212.964 14.1588 205.831 12.4158L205.949 12.3528ZM498.545 183.499C497.699 183.445 497.699 183.4 497.645 183.355L498.545 183.499ZM497.717 183.004V182.689V183.004ZM498.752 179.403C498.824 179.232 498.905 179.052 498.977 178.863C498.86 179.025 498.779 179.205 498.707 179.376L498.752 179.403Z" fill="${bodyColor}" />
			<path fillRule="evenodd" clipRule="evenodd" d="M61.3425 234.726L152.606 269.514C156.207 286.817 135.698 320.966 118.664 325.503C127.932 328.258 133.075 333.987 134.096 342.69L79.8706 341.366C73.9167 339.14 73.7696 333.705 79.4295 325.062C80.6539 306.048 85.5695 302.753 76.7826 285.827C69.4362 273.331 63.1431 255.793 61.3425 234.726Z" fill="${bodyColor}" />
			<path fillRule="evenodd" clipRule="evenodd" d="M191.985 234.726C252.818 257.945 272.877 259.412 272.877 259.412C276.991 279.435 265.341 313.925 249.289 325.539C258.556 328.294 263.7 334.023 264.72 342.726L210.495 341.402C204.541 339.176 204.394 333.741 210.054 325.098C206.705 306.759 214.069 304.085 207.407 285.863C195.391 271.338 190.25 254.293 191.985 234.726Z" fill="${bodyColor}" />
			<path fillRule="evenodd" clipRule="evenodd" d="M450.073 160.47C444.983 199.615 461.738 209.881 500.337 191.269C514.453 159.984 519.819 124.539 492.756 98.2592C489.992 95.5583 487.012 94.2619 486.22 99.2945C485.554 103.544 485.32 107.559 484.509 111.944C480.548 133.389 471.788 152.421 450.073 160.47Z" fill="${bodyColor}" />
			<path d="M56.5171 102.653C52.8169 105.11 48.5135 103.904 46.5238 99.8437C44.012 94.703 45.4795 88.653 48.0723 83.8814C53.3661 74.1132 59.227 64.8401 60.6135 53.5414C61.8379 43.5841 57.4984 34.131 47.055 31.9343C36.0714 29.6025 25.3488 35.0853 15.8687 39.9289C9.67464 43.089 15.1304 52.416 21.2705 49.256C26.9693 46.348 44.9933 36.1837 49.4048 45.8709C51.4034 50.2463 49.2517 56.3413 47.8022 60.5457C45.7973 65.9149 43.2419 71.062 40.1767 75.9048C34.6939 85.0968 31.7409 96.7737 37.8359 106.515C42.9226 114.618 53.8882 117.318 61.9279 111.998C67.6988 108.171 62.288 98.8083 56.4721 102.662L56.5171 102.653Z" fill="${bodyColor}" />
			<path fillRule="evenodd" clipRule="evenodd" d="M20.6672 39.8029C11.7362 35.0403 9.16138 39.0916 4.28177 43.9082C0.0593737 48.0766 -0.651862 52.7762 0.482513 53.8115C3.32745 53.1903 2.85029 51.6778 4.984 52.6501L12.0063 54.2076C11.8893 53.9916 20.5862 55.8192 23.9803 46.6181" fill="${bodyColor}" />
		</svg>`;
