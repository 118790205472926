import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import {
	getFirstVisit,
	getMyIdentifiers,
	getSuggestedIdentifiers,
	requestPatchIdentifiers,
} from 'app/slices/user-account-v2/accounts';
import Hexagon from 'rds/components/Hexagon';
import IconV3 from 'rds/components/IconV3';

const DARK_ACTIVE_GRADIENT = 'linear-gradient(180deg, #20D2B2 0%, #FB7848 50%, #CDBB0A 100%)';

const useStyles = makeStyles((theme) => ({
	container: {
		marginRight: theme.spacing(2),
	},
	identifier: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'absolute',
		height: '92%',
		width: '90%',
		whiteSpace: 'normal',
		overflow: 'hidden',
		marginLeft: '5%',
		zIndex: 3,
	},
	honeyComb: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(4),
		paddingBottom: theme.spacing(12),
		marginRight: theme.spacing(6),
		width: '100%',
	},
	odd: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(-4),
	},
	even: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(-4),
	},
}));

export default ({ openModal }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const firstVisit = useSelector(getFirstVisit);
	const suggestedIdentifiers = useSelector(getSuggestedIdentifiers);
	const identifiers = useSelector(getMyIdentifiers);
	const selected = identifiers.map((r) => r.slug);

	return (
		<div className={classes.honeyComb} style={{ visibility: !firstVisit ? 'hidden' : 'visbile' }}>
			<div className={clsx(classes.container, classes.odd)}>
				{suggestedIdentifiers.slice(0, 3).map((si, index) => (
					<Hexagon
						key={index}
						drawLabel={() => (
							<div className={classes.identifier}>
								{si.title}
							</div>
						)}
						width={78}
						height={92}
						hexagonStyles={{ background: theme.palette.background.page }}
						onClick={() => {
							dispatch(requestPatchIdentifiers({ newIdentifier: si, remove: !!selected.includes(si.slug) }));
						}}
						selected={selected.includes(si.slug)}
						rootStyles={{ marginLeft: '8px', marginTop: '4px' }}
						selectedBorder={DARK_ACTIVE_GRADIENT}
					/>
				))}
			</div>
			<div className={clsx(classes.container, classes.even)}>
				{suggestedIdentifiers.slice(3, 5).map((si, index) => (
					<Hexagon
						key={index}
						drawLabel={() => (
							<div className={classes.identifier}>
								{si.title}
							</div>
						)}
						width={78}
						height={92}
						hexagonStyles={{
							background: theme.palette.background.page,
						}}
						onClick={() => {
							dispatch(requestPatchIdentifiers({ newIdentifier: si, remove: !!selected.includes(si.slug) }));
						}}
						rootStyles={{ marginLeft: '8px', marginTop: '4px' }}
						selected={selected.includes(si.slug)}
						selectedBorder={DARK_ACTIVE_GRADIENT}
					/>

				))}
			</div>
			<div className={clsx(classes.container, classes.odd)}>
				{suggestedIdentifiers.slice(5, 9).map((si, index) => (
					<Hexagon
						key={index}
						drawLabel={() => (
							<div className={classes.identifier}>
								{si.title}
							</div>
						)}
						width={78}
						height={92}
						hexagonStyles={{ background: theme.palette.background.page }}
						onClick={() => {
							dispatch(requestPatchIdentifiers({ newIdentifier: si, remove: !!selected.includes(si.slug) }));
						}}
						rootStyles={{ marginLeft: '8px', marginTop: '4px' }}
						selected={selected.includes(si.slug)}
						selectedBorder={DARK_ACTIVE_GRADIENT}
					/>

				))}
			</div>
			<div className={clsx(classes.container, classes.even)}>

				<Hexagon
					drawLabel={() => (
						<div className={classes.identifier}>
							<IconV3
								icon="Plus"
							/>
						</div>
					)}
					onClick={openModal}
					width={78}
					height={92}
					selected
					hexagonStyles={{
						background: theme.palette.background.page,
					}}
					rootStyles={{ marginLeft: '8px', marginTop: '4px' }}
					selectedBorder={theme.colors.neutral.C000}
				/>
				<Hexagon
					width={78}
					height={92}
					rootStyles={{ visibility: 'hidden', marginLeft: '8px', marginTop: '4px' }}
				/>
			</div>
		</div>
	);
};
