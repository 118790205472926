import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import queueFetch from 'services/queue-fetch';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';

const MAX_LOGS = 200;

export const requestSendLogs = createAsyncThunk(
	'requestSendLogs',
	async (_, { rejectWithValue, getState }) => {
		try {
			const { logs } = getState().devLogs;

			queueFetch('/log/pwa', {
				method: 'POST',
				json: logs,
			});

			return true;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const devLogsSlice = createSlice({
	name: 'devLogs',
	initialState: {
		logs: [],
	},
	reducers: {
		add: (state, action) => {
			const logs = [...state.logs];
			logs.unshift(action.payload);
			state.logs = logs.slice(0, MAX_LOGS);
		},
	},
	extraReducers: {
		[requestSendLogs.pending]: (state) => {
			state.logs = [];
		},
	},
});

const { name, reducer, actions } = devLogsSlice;
const { add } = actions;

export { add };

export const getSlice = (state) => state[name];
export const getLogs = createSelector(getSlice, (slice) => slice.logs);

injectReducer(name, reducer);
