import React from 'react';
import FixedTopBar from 'rds/components/Layouts/FixedTopBar';
import Hero from 'rds/components/Layouts/Hero';
import Logo from 'rds/components/Logo';
import useRdsTheme from 'rds/theme/useRdsTheme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	content: {
		position: 'fixed',
		zIndex: 3,
		overflowY: 'auto',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
	},
});

export default ({
	children,
	theme,
	main,
	close,
	...rest
}) => {
	const classes = useStyles();
	theme = theme || useRdsTheme();

	return (
		<>
			<div className={classes.content}>
				<Hero
					theme={theme}
					image={(
						<Logo
							bodyColor={theme.colors.primary.C400}
							legColor={theme.colors.primary.C700}
							square={false}
							gutters={false}
							size="small"
						/>
					)}
					{...rest}
				/>
				{!main
					&& (
						<FixedTopBar
							theme={theme}
							variant="glass"
							close={close}
							closeIconPosition="left"
						/>
					)}

				{children}

			</div>

		</>

	);
};
