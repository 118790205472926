const CLASS_NAME = 'cc-mentions__underline';
const DATA_ATTR = 'data-mentions-css';
const SS_SELECTOR = 'mentions-css';

const getStyle = (cid, color = '#fff') => `.${cname(cid)} .${CLASS_NAME} {
	background-color: ${color};
}

.${cname(cid)}:focus .cc-mention::after {
	content: " ";
	display: block;
	position: absolute;
	height: 2px;
	left: 32px;
	right: 0;
	bottom: 0px;
	background-color: ${color};
}

.${cname(cid)} span {
	pointer-events: none;
}
`;

const createStyleSheet = (styleId) => {
	const cid = cname(styleId);
	const style = document.createElement('style');
	style.setAttribute('type', 'text/css');
	style.setAttribute('class', SS_SELECTOR);
	style.setAttribute('data-include-html-to-image', true);
	style.setAttribute('id', cid);
	document.body.appendChild(style);
	return style;
};

let id = 1;

const cname = (cid) => `${CLASS_NAME}-${cid}`;

const getMentionsCssId = (el) => {
	let mid = parseInt(el.getAttribute(DATA_ATTR), 10);

	if (!mid) {
		mid = id++;
		el.setAttribute(DATA_ATTR, mid);
		el.classList.add(cname(mid));
	}

	return mid;
};

const getMentionsSs = (styleId) => {
	let sheet = document.getElementById(cname(styleId));

	if (!sheet) {
		sheet = createStyleSheet(styleId);
	}

	return sheet;
};

export default (el, color) => {
	const styleId = getMentionsCssId(el);
	const sheet = getMentionsSs(styleId, color);
	sheet.innerHTML = getStyle(styleId, color);
};

export const clear = () => {
	document.body.querySelectorAll('.mentions-css').forEach((ss) => ss.remove());
};

window.clear = clear;
