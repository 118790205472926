import React, { useState } from 'react';
import TextField from 'rds/components/TextField';
import { DatePicker } from '@material-ui/pickers';

export default ({
	disabled, value, theme, ...rest
}) => {
	const [open, setOpen] = useState(false);

	const openit = () => {
		if (!disabled) {
			setOpen(true);
		}
	};

	const currentDate = new Date();

	return (
		<DatePicker
			{...rest}
			disableFuture
			open={open}
			value={value}
			format="MMMM DD, YYYY"
			emptyLabel="Enter date of birth"
			openTo="year"
			views={['year', 'month', 'date']}
			onClose={() => setOpen(false)}
			maxDate={currentDate}
			TextFieldComponent={() => (
				<TextField
					theme={theme}
					placeholder="Enter date of birth"
					fullWidth
					disabled={disabled}
					variant="glass"
					value={value ? value.format('MMMM DD, YYYY') : undefined}
					onFocus={openit}
					onClick={openit}
					formItem
					icon={{
						icon: 'Calendar',
						size: 20,
					}}
				/>
			)}
		/>
	);
};
