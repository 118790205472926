import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import useNavigateCC from 'app/hooks/useNavigateCC';
import {
	getMediaRecorderId,
	getRecordingStartTime,
	requestStartRecording,
	requestStopRecording,
	PHOTO_THRESHOLD,
	VIDEO_CLIP_CHUNK_DURATION,
	torchOn,
	torchOff,
} from 'app/slices/content-creation/create/capture';
import {
	CLIPS_THRESHOLD,
	selectClipCount,
	selectLockCC,
} from 'app/slices/content-creation/experience';
import clsx from 'clsx';
import {
	addTextClip,
} from 'app/slices/content-creation/editing-canvas';
import { neutral } from 'rds/colors';
import IconV3 from 'rds/components/IconV3';
import Capture, { EVENTS as captureEvents } from './Capture';

const PROGRESS_TICK = 85; // lower will be smoother progress move

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		position: 'absolute',
		bottom: 67,
		left: 0,
		right: 0,
	},
	circle: {
		height: 60,
		width: 60,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#ffffff',
		borderRadius: '100%',
		transition: 'background 300ms ease, width 300ms ease, height 300ms ease',
		border: '30px solid rgba(255, 255, 255, 0.4)',
	},

	pressed: {
		background: theme.colors.neutral.C200,
	},

	middleCircle: {
		border: '4px solid rgba(255, 255, 255, 0.4)',
		borderRadius: '100%',
		height: 72,
		width: 72,
		position: 'absolute',
		bottom: 12,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		pointerEvents: 'all',
	},
	outerCircle: {
		backgroundColor: '#ffffff',
		opacity: 0.4,
		borderRadius: '100%',
		height: 96,
		width: 96,
		position: 'absolute',
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	hide: {
		border: 'none',
	},
	imageIcon: {
		width: 38,
		height: 38,
		left: 12,
		top: 12,
	},
}));

const CaptureButton = ({
	mode,
	videoRef,
	hasPermissions,
	params,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigateCC = useNavigateCC();
	const captureButtonRef = useRef();
	const [percentageComplete, setPercentageComplete] = useState(100);
	const lockCC = useSelector(selectLockCC);
	const startTime = useSelector(getRecordingStartTime);
	const mediaRecorderId = useSelector(getMediaRecorderId);
	const clipCount = useSelector(selectClipCount);

	const reachedMax = clipCount >= CLIPS_THRESHOLD;
	const pressed = lockCC || !!mediaRecorderId;

	useEffect(() => {
		let i;

		if (reachedMax && startTime) {
			dispatch(requestStopRecording());
		}

		if (startTime) {
			i = setInterval(() => {
				const diff = window.performance.now() - startTime;

				if (diff >= PHOTO_THRESHOLD) {
					setPercentageComplete((diff / VIDEO_CLIP_CHUNK_DURATION) * 100);
				}
			}, PROGRESS_TICK);
		} else {
			setPercentageComplete(0);
		}
		return () => { clearInterval(i); };
	}, [startTime, percentageComplete, reachedMax]);

	useEffect(() => {
		if (captureButtonRef?.current && (mode === 'text' || videoRef?.current)) {
			if (captureButtonRef.current.capture) {
				captureButtonRef.current.capture.destroy();
				captureButtonRef.current.capture = null;
			}

			const c = new Capture({
				captureEl: captureButtonRef.current,
				videoEl: videoRef?.current,
				mode,
				photoThreshold: PHOTO_THRESHOLD,
			});

			c.on(captureEvents.textCaptureEnd, () => {
				const result = dispatch(addTextClip());

				if (result && params?.subTab === 'tray') {
					navigateCC('details');
				}
			});

			c.on(captureEvents.mediaCaptureStart, async () => {
				const result = await dispatch(requestStartRecording());
				if (!result) {
					c?.unlock();
				}
			});

			c.on(captureEvents.mediaIsVideo, async () => {
				dispatch(torchOn());
			});

			c.on(captureEvents.mediaCaptureEnd, async () => {
				const flashAnimation = () => c.shutter('#fff', 200, 200);
				const shutterAnimation = () => c.shutter(neutral.C900, 250);
				const type = await dispatch(requestStopRecording(videoRef?.current, {
					flashAnimation,
					shutterAnimation,
				}));

				if (type === 'video' || type === 'photo') {
					dispatch(torchOff());
				}

				if (type && params?.subTab === 'tray') {
					navigateCC('details');
				}
			});

			captureButtonRef.current.capture = c;
		}

		return () => {
			if (captureButtonRef.current?.capture) {
				captureButtonRef.current.capture.destroy();
				captureButtonRef.current.capture = null;
			}
		};
	}, [params, captureButtonRef, videoRef]);

	useEffect(() => {
		if (captureButtonRef?.current?.capture) {
			if (lockCC || reachedMax) {
				captureButtonRef.current.capture.lock();
			} else {
				captureButtonRef.current.capture.unlock();
			}
		}
	}, [reachedMax, lockCC]);

	const klass = (startTime && Date.now() - (startTime > PHOTO_THRESHOLD * 2))
		? clsx(classes.middleCircle, classes.hide)
		: classes.middleCircle;

	const circleClass = pressed
		? clsx(classes.circle, classes.pressed)
		: classes.circle;

	return (
		<div className={classes.root} style={{ opacity: (!hasPermissions && mode !== 'text') ? 0.2 : 1 }}>
			{percentageComplete > 3
				? (
					<div className={classes.outerCircle}>
						<CircularProgress
							size={96}
							variant="determinate"
							color="primary"
							value={percentageComplete % 100}
							thickness={4}
						/>
					</div>
				) : null}

			<div className={klass} ref={captureButtonRef}>
				<div className={circleClass}>
					{mode === 'text' && (
						<IconV3
							icon="AddTextOverlay"
							size={48}
							gredient="true"
						/>
					) }
				</div>
			</div>
		</div>
	);
};

export default CaptureButton;
