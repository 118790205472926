import React from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEditProfileModal } from 'app/slices/user-account-v2/layout/slice';
import { getIsItUpdated } from 'app/slices/user-account-v2/edit-profile/selectors';
import { reset } from 'app/slices/user-account-v2/edit-profile/slice';
import { requestEditProfile } from 'app/slices/user-account-v2/edit-profile/thunks';

const useStyles = makeStyles({
	topControls: {
		height: 48,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
});

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isItUpdated = useSelector(getIsItUpdated);

	return (
		<div className={classes.topControls}>
			<Button
				disableRipple
				disableFocusRipple
				onClick={() => {
					dispatch(toggleEditProfileModal(false));
					dispatch(reset());
				}}
			>
				Cancel
			</Button>
			<Typography variant="body1">
				Edit profile
			</Typography>
			<Button
				disableRipple
				disableFocusRipple
				onClick={() => {
					if (isItUpdated) {
						dispatch(requestEditProfile());
					}
					dispatch(toggleEditProfileModal(false));
				}}
			>
				Save
			</Button>
		</div>
	);
};
