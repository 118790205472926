import React from 'react';
import {
	FormControlLabel,
	makeStyles,
	Radio,
	RadioGroup,
} from '@material-ui/core';

const useStyles = makeStyles({
	radio: {
		marginLeft: 0,
		justifyContent: 'space-between',
	},
});

export default ({ options, value, handleChange }) => {
	const classes = useStyles();

	return (
		<RadioGroup
			value={value}
			onChange={handleChange}
		>
			{options.map((o, i) => (
				<FormControlLabel
					className={classes.radio}
					key={i}
					value={o.value}
					label={o.label}
					labelPlacement="start"
					control={<Radio disableRipple />}
				/>
			))}
		</RadioGroup>
	);
};
