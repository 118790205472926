import img1 from 'routes/NotFound/background.webp';

export default (experiences) => {
	const ex = [];

	if (!experiences) { return null; }

	for (let i = 0; i < experiences.length; i++) {
		const thumbnails = experiences[i]?.clips[0]?.outputs;
		if (Object.keys(thumbnails).length === 0) {
			ex.push({
				image: img1,
				uid: experiences[i].uid,
				user: experiences[i].user,
				title: experiences[i].title,
				saved: experiences[i].interactions?.callerHasSaved,
				destination: experiences[i]?.destinations[0],
			});
		} else if (thumbnails.hasOwnProperty('photo')) {
			ex.push({
				image: thumbnails.photo.small.webp,
				uid: experiences[i].uid,
				user: experiences[i].user,
				title: experiences[i].title,
				saved: experiences[i].interactions?.callerHasSaved,
				destination: experiences[i]?.destinations[0],
			});
		} else if (thumbnails.hasOwnProperty('thumbnail')) {
			ex.push({
				image: thumbnails.thumbnail.small.webp,
				uid: experiences[i].uid,
				user: experiences[i].user,
				title: experiences[i].title,
				saved: experiences[i].interactions?.callerHasSaved,
				destination: experiences[i]?.destinations[0],
			});
		}
	}

	return ex;
};
