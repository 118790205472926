import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import ageGate from 'services/age-gate';
import { injectReducer } from 'services/store';
import requestFormRequest from 'services/request-form-request';
import GAEvent from 'components/GoogleAnalytics/event';
import { getFormPayload, setErrors } from './form';
import { startSession } from '../session/session';

const requestRegister = createAsyncThunk(
	'requestRegister',
	async (_, options) => {
		const pl = getFormPayload(options.getState());

		if (ageGate(pl.dateOfBirth)) {
			return null;
		}

		const [response, success, payload] = await requestFormRequest(options, {
			method: 'POST',
			route: '/accounts/grant-password/register',
			getFormPayload,
			setErrors,
		});

		if (success) {
			GAEvent({ name: 'pwa_registration_page_button_signup', uuid: response.data?.accountUuid });
			options.dispatch(startSession({
				...response.data,
				expires: !payload?.staySignedIn,
			}));
		}

		return response.data;
	},
);
export const requestUpdate = requestRegister;

export const registerSlice = createSlice({
	name: 'register',
	initialState: {
		isRegistered: false,
		errors: null,
		loading: false,
	},
	extraReducers: {
		[requestRegister.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestRegister.fulfilled]: (state) => {
			state.isRegistered = true;
			state.loading = false;
			state.errors = null;
		},

		[requestRegister.rejected]: (state, action) => {
			state.isRegistered = false;
			state.loading = false;
			state.errors = action.payload;
		},
	},

});

const { name, reducer } = registerSlice;
export { name, reducer };

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);

injectReducer(name, reducer);
