import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import MaxLengthCaption from './MaxLengthCaption';
import styles from './styles';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	container: {
		...styles.root(theme),
		display: 'inline-block',
	},
	shadow: { ...styles.shadow },
	input: styles.input(theme),
	input_contrast: styles.inputContrast(theme),
	input_secondary: styles.inputSecondary(theme),
	input_primary: styles.inputPrimary(theme),
}));

const resizeEl = (target, shadow) => {
	const targetStyles = window.getComputedStyle(target);
	shadow.style.fontSize = targetStyles.fontSize;
	shadow.style.fontFamily = targetStyles.fontFamily;
	shadow.style.fontWeight = targetStyles.fontWeight;
	shadow.style.fontStyle = targetStyles.fontStyle;
	shadow.style.letterSpacing = targetStyles.letterSpacing;
	shadow.style.textTransform = targetStyles.textTransform;
	shadow.innerText = target.value || target.getAttribute('placeholder');
	const width = shadow.scrollWidth + 16;
	target.style.width = `${width}px`;
};

export default ({
	onChange,
	value,
	color = 'primary',
	placeholder,
	maxLength,
	maxLengthCaption = false,
	blur,
	...rest
}) => {
	const classes = useStyles();
	const divRef = useRef();
	const shadowRef = useRef();
	const [focused, setFocused] = useState(false);
	const containerKlass = clsx(classes.container, classes[`container_${color}`]);
	const inputKlass = clsx(classes.input, classes[`input_${color}`]);
	const theme = useTheme();

	useEffect(() => {
		if (divRef?.current && shadowRef?.current) {
			resizeEl(divRef.current, shadowRef.current);
		}
	}, [value, divRef?.current, shadowRef?.current]);

	return (
		<div className={classes.root}>
			{maxLengthCaption && focused
			&& (
				<MaxLengthCaption
					color={color}
					fixed={maxLengthCaption}
					maxLength={maxLength}
					value={value}
					bottom={-theme.spacing(4.5)}
				/>
			)}

			<div className={containerKlass}>
				<div
					className={classes.shadow}
					ref={shadowRef}
				/>
				<input
					ref={divRef}
					placeholder={placeholder}
					value={value}
					onClick={(e) => {
						if (!e.target.focused) {
							e.target.selectionStart = e.target.value.length;
							e.target.selectionEnd = e.target.value.length;
						}
					}}
					onBlur={(e) => {
						e.target.focused = false;
						blur();
						setFocused(false);
					}}
					onFocus={(e) => {
						setTimeout(() => {
							e.target.focused = true;
							setFocused(true);
						}, 1);
					}}
					maxLength={maxLength}
					onChange={onChange}
					type="text"
					className={inputKlass}
					{...rest}
				/>
			</div>
		</div>
	);
};
