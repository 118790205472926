/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import assets from 'routes/Auth/assets/registrations';
import SocialButton from 'rds/components/Button/SocialButton';
import useRdsTheme from 'rds/theme/useRdsTheme';
import { useNavigate } from 'react-router-dom';
import IconV3 from 'rds/components/IconV3';
import GAEvent from 'components/GoogleAnalytics/event';
import FooterText from './FooterText';
import GoogleButton from '../SocialAuth/GoogleButton';
import PageLayout from '../components/layout/PageLayout';
import Contents from './Contents';

const useStyles = makeStyles((theme) => ({
	background: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		objectFit: 'cover',
		backgroundImage: `url(${assets.travel})`,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		opacity: 0.1,
	},
	buttons: {
		display: 'flex',
		marginRight: theme.spacing(10),
		marginLeft: theme.spacing(10),
	},
	explore: {
		...theme.typography.title1,
		marginBottom: theme.spacing(4),
		textAlign: 'center',
	},
	emailButton: {
		display: 'flex',
	},
	emailText: {
		marginLeft: theme.spacing(2),
	},
	content: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		position: 'absolute',
		bottom: theme.spacing(4),
		top: theme.spacing(50),
	},
	footer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		marginTop: theme.spacing(7),
	},
}));

const RegistrationMainScreen = () => {
	const classes = useStyles();
	const theme = useRdsTheme('dark');
	const navigate = useNavigate();

	useEffect(() => {
		if (navigator.serviceWorker) {
			navigator.serviceWorker.ready.then((registration) => {
				registration.update();
			});
		}

		if (localStorage.getItem('firstVisit') === null) {
			localStorage.setItem('firstVisit', 'true');
			GAEvent({
				action: 'pwa_registration_page_first_open',
				event_category: 'Registration',
				eventKey: 'First Time Visit',
			});
		}
	}, []);

	return (
		<PageLayout
			theme={theme}
			main
			title="Find travel content that resonates"
		>
			<div className={classes.background} />
			<div className={classes.content}>
				<Contents />
				<div className={classes.footer}>
					<Typography className={classes.explore}>Start exploring on Rhino</Typography>
					<div className={classes.buttons}>
						<GoogleButton />
						<SocialButton
							onClick={() => { navigate('/signin'); }}
						>
							<div className={classes.emailButton}>
								<IconV3
									icon="Email"
									size={24}
									containerSize={20}
									color="neutralC900"
								/>
								<Typography className={classes.emailText}>Email</Typography>
							</div>
						</SocialButton>
					</div>
					<FooterText text />
				</div>
			</div>
		</PageLayout>
	);
};

export default RegistrationMainScreen;
