import { createSelector } from '@reduxjs/toolkit';
import { getOnboarding } from 'app/slices/authentication/session/session';
import { trimAtSymbolSelector } from '../utils';

const getSlice = (state) => state.userAccountV2Accounts;
const getSession = (state) => state.session.session;

const createAccountSelector = (key) => createSelector(
	[getSlice, trimAtSymbolSelector],
	(slice, username) => {
		username = username || slice.currentUsername;

		if (key === 'account') {
			return slice.accounts[username];
		}

		return slice.accounts[username]?.[key];
	},
);

const createMyAccountSelector = (key) => createSelector(
	getSlice,
	(slice) => (slice.myAccount ? slice.myAccount[key] : null),
);

export const getTheirAccount = createAccountSelector('account');
export const getTheirAvatar = createAccountSelector('avatar');
export const getTheirBio = createAccountSelector('bio');
export const getTheirUsername = createAccountSelector('username');
export const getTheirName = createAccountSelector('name');
export const getTheirInteractions = createAccountSelector('interactions');
export const getTheirFollowing = createAccountSelector('following');
export const getTheirStats = createAccountSelector('stats');
export const getTheirProfileUuid = createAccountSelector('uuid');
export const getTheirIdentifiers = createAccountSelector('identifiers');
export const getTheirCustomIdentifiers = createAccountSelector('customIdentifiers');
export const getTheirListing = createAccountSelector('listing');
export const getTheirRoute = createAccountSelector('route');
export const getTheirCollections = createAccountSelector('collections');
export const getTheirFollowRequest = createAccountSelector('followRequest');
export const getTheirFormTitle = createAccountSelector('formTitle');
export const getTheirHappening = createAccountSelector('happening');
export const getTheirLoadingProfile = createAccountSelector('loadingProfile');
export const getTheirErrors = createAccountSelector('errors');
export const getTheirCurrentHappening = createAccountSelector('currentHappeningClip');
export const getTheirCurrentPercentage = createAccountSelector('currentPercentage');
export const getTheirCheckingIfUserIsBlocked = createAccountSelector('checkingIfUserIsBlocked');
export const getTheirContextMenuActiveExperience = createAccountSelector('contextMenuActiveExperience');

export const getMyAccount = createSelector(getSlice, (slice) => slice.myAccount);
export const getMyUsername = createSelector(getSession, (slice) => slice?.username);
export const getMyAvatar = createMyAccountSelector('avatar');
export const getMyBio = createMyAccountSelector('bio');
export const getMyName = createMyAccountSelector('name');
export const getMyFollowing = createMyAccountSelector('following');
export const getMyStats = createMyAccountSelector('stats');
export const getMyProfileUuid = createMyAccountSelector('uuid');
export const getMyIdentifiers = createMyAccountSelector('identifiers');
export const getMyCustomIdentifiers = createMyAccountSelector('customIdentifiers');
export const getMyAvatarIsActive = createMyAccountSelector('avatarIsActive');
export const getMyListing = createMyAccountSelector('listing');
export const getMyRoute = createMyAccountSelector('route');
export const getMyCollections = createMyAccountSelector('collections');
export const getMyFollowRequest = createMyAccountSelector('followRequest');
export const getMyFormTitle = createMyAccountSelector('formTitle');
export const getMyHappening = createMyAccountSelector('happening');
export const getMyLoadingProfile = createMyAccountSelector('loadingProfile');
export const getMyErrors = createMyAccountSelector('errors');
export const getMyCurrentHappening = createMyAccountSelector('currentHappeningClip');
export const getMyCurrentPercentage = createMyAccountSelector('currentPercentage');
export const getMyContextMenuActiveExperience = createMyAccountSelector('contextMenuActiveExperience');

export const getCurrentUsername = createSelector(getSlice, (slice) => slice.currentUsername);
export const getLoading = createSelector(getSlice, (slice) => slice.loading);
export const getNotFound = createSelector(getSlice, (slice) => slice.notFound);
export const getSuggestedIdentifiers = createSelector(getSlice, (slice) => slice.suggestedIdentifiers);
export const getRequestingSuggestedIdentifiers = createSelector(getSlice, (slice) => slice.loadingSuggestedIdentifiers);

export const getIsMe = createSelector(
	[getSlice, getSession],
	(slice, session) => slice.myAccount || session?.username === slice.currentUsername,
);

export const getCanBlockUser = createSelector(
	[getSlice, trimAtSymbolSelector],
	(slice, username) => {
		username = username || slice.currentUsername;
		return slice.accounts[username]?.interactions?.callerCanBlock !== false;
	},
);

export const getFirstVisit = (state) => {
	const onboarding = getOnboarding(state);
	return onboarding.firstVisit;
};
export const getExperienceCreatorCustomIdentifiers = (user) => createSelector(
	[getSlice, getSession],
	(userSlice, session) => {
		if (session?.username === user?.username) {
			return userSlice.myAccount?.customIdentifiers;
		}
		return user?.customIdentifiers;
	},
);
