import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { selectClipCount } from 'app/slices/content-creation/experience';
import DecagonIndicator from './DecagonIndicator';
import DecagonFragments from './DecagonStyle';

const useStyles = makeStyles({
	decagons: {
		position: 'absolute',
		left: 'calc(50% - 53px)',
		width: 106,
		height: 102,
		top: 34,
	},

});
export default ({ hasPermissions, mode }) => {
	const classes = useStyles();
	const clipCount = useSelector(selectClipCount);

	return (
		<div className={classes.decagons} style={{ opacity: (!hasPermissions && mode !== 'text') ? 0.2 : 1 }}>
			{DecagonFragments.map((d, i) => (
				<div key={i} className={classes.decagon}>
					<DecagonIndicator
						transform={d.transform}
						top={d.top}
						left={d.left}
						selected={i < clipCount}
					/>
				</div>
			))}
		</div>
	);
};
