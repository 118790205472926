import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useQueryParam } from 'app/hooks/useQueryParams';
import Header from '../Components/Header';

const useStyles = makeStyles((theme) => ({
	header: {
		position: 'sticky',
		top: 0,
		left: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: theme.palette.background.page,
		height: 48,
		zIndex: 4,
		padding: theme.spacing(3.5, 0),
	},
}));

export default ({
	onClose,
	title,
}) => {
	const classes = useStyles();
	const panelParam = useQueryParam('panel');
	const style = {};

	return (
		<div className={classes.header} style={style}>
			<Header title={title} back={panelParam} onClose={onClose} />
		</div>
	);
};
