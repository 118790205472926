import { createSelector } from '@reduxjs/toolkit';

const getSlice = (state) => state.destinationAccounts;

const createAccountSelector = (key, uid) => createSelector(
	[getSlice],
	(slice) => {
		if (key === 'account') {
			return slice.accounts[uid];
		}

		return slice.accounts[uid]?.[key];
	},
);

export const getDestinationTitle = (uid) => createAccountSelector('title', uid);
export const getDestinationSubtitle = (uid) => createAccountSelector('subtitle', uid);
export const getDestinationThumbnail = (uid) => createAccountSelector('thumbnail', uid);
export const getDestinationInteractions = (uid) => createAccountSelector('interactions', uid);
export const getDestinationStats = (uid) => createAccountSelector('stats', uid);
export const getDestinationUid = (uid) => createAccountSelector('uid', uid);
export const getDestinationParent = (uid) => createAccountSelector('parent', uid);
export const getErrors = createAccountSelector('errors');
export const getLoading = createAccountSelector('loading');
