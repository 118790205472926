/* eslint-disable no-restricted-syntax */
export default (obj) => {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = obj[key];
			if (value !== null && value !== undefined && (Array.isArray(value) && value.length > 0)) {
				return false;
			}
		}
	}
	return true;
};
