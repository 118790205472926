import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';
import { updateOnboarding } from '../authentication/session/session';

export const requestPatchOnboarding = createAsyncThunk(
	'requestPatchOnboarding',
	async (onboarding, {
		rejectWithValue, getState, dispatch,
	}) => {
		const state = getState();
		const payload = {
			...state.session.session.onboarding,
			...state.onboardingLayout.onboarding,
			...onboarding,
		};
		try {
			await api.patch('accounts/settings', {
				onboarding: payload,
			});
			await dispatch(updateOnboarding(payload));
			return payload;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const layoutSlice = createSlice({
	name: 'onboardingLayout',
	initialState: {
		onboarding: {},
		errors: null,
		loading: false,
	},
	extraReducers: {
		[requestPatchOnboarding.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestPatchOnboarding.fulfilled]: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state.onboarding[key] = action.payload[key];
			});
		},
		[requestPatchOnboarding.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default layoutSlice;

const { name, actions } = layoutSlice;

export const getSlice = (state) => state[name];

injectReducer(name, layoutSlice.reducer, actions);
