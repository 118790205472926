import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Icon from 'rds/components/Icon';
import DestinationHeader from 'routes/DestinationAccount/Header/TopHeader';

const useStyles = makeStyles({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default () => {
	const classes = useStyles();

	return (
		<>
			<DestinationHeader />
			<div className={classes.root}>
				<div>
					<Icon
						icon="Rhino"
						containerHeight={96}
						containerWidth={96}
						height={43}
						width={64}
					/>
				</div>
				<Typography variant="subtitle1">No destination found.</Typography>
			</div>
		</>
	);
};
