/* eslint-disable consistent-return */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
	selectEditorControls,
	change,
	selectActiveTrashCan,
	selectTrashRegion,
	selectShowTrashCan,
	selectPlugins,
} from 'app/slices/content-creation/editor/layout';
import {
	selectClips,
	hideClipTray,
	unlockClipTray,
} from 'app/slices/content-creation/experience';
import {
	CANVAS_OBJECT_MOVE,
	CANVAS_OBJECT_UP,
	SELECT_CANVAS,
	SELECT_OBJECT,
} from 'lib/editing-canvas/src/event-types';
import { setEditingNode } from 'app/slices/content-creation/create/text-component';
import RequestProvider from 'rds/providers/Request';
import {
	getLoading as getAppLoading,
	getConstants,
	getErrors,
	requestApp,
} from 'app/slices/app/app';
import createElement from 'utils/create-element';
import Tray from './Tray/Tray';
import TopControls from './TopControls';
import Modal from './Modal';
import Recommendations from '../Create/TextComponent/Recommendations';
import StylePicker from '../Create/TextComponent/StylePicker';
import PluginsModal from './PluginsModal';
import Plugins from './Plugins';
import TrashCan from '../Components/Layout/TrashCan';
import { COMMON_STYLES } from '../Create/TextComponent/styles';
import MentionModal from './MentionModal';
import LoadingPage from './LoadingPage';

const TIME_OUT_ONE = 300;
const TIME_OUT_TWO = 500;

const OBJECT_Y_MAX = window.innerHeight - 80;
const TRASH_THRESHOLD_Y = 120;
const TRASH_THRESHOLD_X = 100;

const useStyles = makeStyles({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 17,
		overflow: 'hidden',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	preview: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
});

const clampToBounds = (el, rect) => {
	// const scale = getScale(el);
	const bottomBorder = rect.y;

	// const maxTop = OBJECT_Y_MAX - (rect.height / scale);
	if (bottomBorder > OBJECT_Y_MAX) {
		el.style.top = `${bottomBorder - 1}px`;
	}
};

const ClipEditor = ({ params, path }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const containerRef = useRef();
	const clips = useSelector(selectClips);
	const showControls = useSelector(selectEditorControls);
	const showTrashCan = useSelector(selectShowTrashCan);
	const activeTrashCan = useSelector(selectActiveTrashCan);
	const trashRegion = useSelector(selectTrashRegion);
	const plugins = useSelector(selectPlugins);

	const clipIndex = clips.findIndex((c) => parseInt(c.id, 10) === parseInt(params.id, 10));
	const clip = clips[clipIndex];

	useEffect(() => {
		dispatch(hideClipTray(true));
		dispatch(unlockClipTray());
	}, []);

	useEffect(() => () => {
		dispatch(hideClipTray());
	}, []);

	useEffect(() => {
		for (let i = 0; i < clips.length; i++) {
			const c = clips[i];

			if (clip?.id === c?.id) {
				if (c.muted) {
					c.editingCanvas.pauseAudio();
				} else {
					c.editingCanvas.playAudio();
				}
			} else {
				c.editingCanvas.pauseAudio();
			}
		}

		return () => {
			for (let i = 0; i < clips.length; i++) {
				clips[i].editingCanvas.pauseAudio();
			}
		};
	}, [clip, clips]);

	const createTextOverlay = async (styles) => {
		const div = createElement({
			tagName: 'div',
			attributes: {
				contenteditable: 'true',
				autocomplete: 'off',
			},
		});
		const divStyles = {
			...COMMON_STYLES,
			top: styles.top,
			left: styles.left,
		};

		Object.keys(divStyles).forEach((key) => {
			div.style[key] = divStyles[key];
		});

		const node = await clip.editingCanvas.add('html', {
			el: div,
		}, {
			// ignoreStyles: ['width'],
		});
		clip.editingCanvas.compilePreview();
		const el = await clip.editingCanvas.waitForEl(node.item.id);

		dispatch(
			setEditingNode({
				el,
				id: node.item.id,
			}),
		);
	};

	const addText = () => {
		const styles = { top: 'calc(50% - 100px)' };
		createTextOverlay(styles);
	};

	const selectCanvas = async (e) => {
		e.preventDefault();

		const { clientX, clientY } = e.lastEvent.touches[0];
		const styles = {
			left: `${clientX}px`,
			top: `${clientY}px`,
		};
		createTextOverlay(styles);
	};

	// TODO: @alireza for mention back color changing
	// const changeMentionBackgroundColor = (el, id) => {
	//	 const nextStyles = {};
	//	 const backgroundId = el.getAttribute('backgroundcolorid');
	//	 for (let i = 0; i < s.styles.length; i++) {
	//		 if (i === Number(backgroundId)) {
	//			 nextStyles.color = s.styles[i].color;
	//			 nextStyles.background = s.styles[i].background;
	//		 }
	//	 }
	//	 el.style.background = nextStyles.background;
	//	 el.style.color = nextStyles.color;
	//	 clip.editingCanvas.updateStyle(id, 'background', nextStyles.background);
	//	 if (backgroundId !== '2') {
	//		 el.setAttribute('backgroundcolorid', Number(backgroundId) + 1);
	//	 } else {
	//		 el.setAttribute('backgroundcolorid', 0);
	//	 }
	// };

	const selectObject = (e, object) => {
		const { el, id } = object;

		const isContentEditable = el.getAttribute('contenteditable') === 'true';

		// if (el.getAttribute('nodetype') === 'action') {
		//	 changeMentionBackgroundColor(el, id);
		//	 return;
		// }

		if (isContentEditable) {
			dispatch(
				setEditingNode({
					el,
					id,
				}),
			);
		}
	};
	const initialHeight = window.innerHeight;
	const isInRemovingRegion = (touch) => {
		const { clientX, clientY } = touch;
		return clientX >= trashRegion.left - TRASH_THRESHOLD_X
				&& clientX <= trashRegion.right + TRASH_THRESHOLD_X
				&& clientY >= initialHeight - TRASH_THRESHOLD_Y
				&& clientY <= initialHeight + TRASH_THRESHOLD_Y;
	};

	const onCanvasObjectMove = (e, args) => {
		const { toggled } = e.target;

		const { el, rect } = args;

		if (el.getAttribute('root')) {
			return;
		}

		if (!toggled) {
			e.target.toggled = true;

			if (showControls || !showTrashCan) {
				dispatch(change({
					showControls: false,
					showTrashCan: true,
				}));
			}
		}

		clampToBounds(el, rect);

		const readyToDelete = isInRemovingRegion(e.touches[0]);

		if (readyToDelete) {
			if (activeTrashCan) {
				return;
			}
			dispatch(change({
				activeTrashCan: true,
			}));
		} else {
			dispatch(change({
				activeTrashCan: false,
			}));
		}
	};

	let loadingPreview = false;

	const updatePreview = async () => {
		if (clip.editingCanvas.dirty.preview) {
			loadingPreview = true;
			await clip.editingCanvas.compilePreview();
			loadingPreview = false;
		}
	};

	const onCanvasObjectUp = (e, args) => {
		if (args.el.getAttribute('root')) {
			return;
		}
		const readyToDelete = args.lastEvent && isInRemovingRegion(args.lastEvent.touches[0]);
		if (readyToDelete) {
			const id = args.el.getAttribute('data-object-id');
			clip.editingCanvas.remove(Number(id));

			dispatch(change({ fullTrashCan: true }));
			setTimeout(() => {
				dispatch(change({
					showTrashCan: false,
					activeTrashCan: false,
				}));
				setTimeout(() => {
					dispatch(change({
						fullTrashCan: false,
						showControls: true,
					}));
				}, TIME_OUT_TWO);
			}, TIME_OUT_ONE);
		} else if (e.target.toggled) {
			dispatch(change({
				showTrashCan: false,
				showControls: true,
			}));
		}
		updatePreview();
		e.target.toggled = false;
	};

	useEffect(() => {
		if (!clip || containerRef?.current?.clipId === clip.id) {
			return;
		}
		if (containerRef?.current) {
			clip.editingCanvas.draw();
			containerRef.current.innerHTML = '';
			containerRef.current.appendChild(clip.editingCanvas.container);
			containerRef.current.clipId = clip.id;
		}

		clip.editingCanvas.addListener(SELECT_CANVAS, selectCanvas);
		clip.editingCanvas.addListener(SELECT_OBJECT, selectObject);
		clip.editingCanvas.addListener(CANVAS_OBJECT_MOVE, onCanvasObjectMove);
		clip.editingCanvas.addListener(CANVAS_OBJECT_UP, onCanvasObjectUp);

		return () => {
			clip.editingCanvas.stop();
			clip.editingCanvas.removeListener(SELECT_CANVAS, selectCanvas);
			clip.editingCanvas.removeListener(SELECT_OBJECT, selectObject);
			clip.editingCanvas.removeListener(CANVAS_OBJECT_MOVE, onCanvasObjectMove);
			clip.editingCanvas.removeListener(CANVAS_OBJECT_UP, onCanvasObjectUp);
		};
	}, [clip?.id]);

	if (!clips.length || !clip || path !== 'editor') {
		return null;
	}

	return (
		<>
			<div className={classes.root}>
				{ showControls && <TopControls loading={loadingPreview} onClick={updatePreview} /> }
				<div
					className={classes.preview}
					ref={containerRef}
				/>
				<RequestProvider
					requests={{
						constants: {
							action: requestApp,
							selector: getConstants,
							errors: getErrors,
							loading: getAppLoading,
						},
					}}
				>
					<Modal editingCanvas={clip.editingCanvas} />
					<MentionModal editingCanvas={clip.editingCanvas} />
					<StylePicker editingCanvas={clip.editingCanvas} />
					<Recommendations />
					<TrashCan />
				</RequestProvider>
				{showControls
				&& (
					<Tray clip={clip} clipIndex={clipIndex} addText={addText} />
				)}
				<PluginsModal open={plugins} onClose={() => { dispatch(change({ plugins: false })); }}>
					{ plugins && <Plugins params={params} /> }
				</PluginsModal>
			</div>
			{loadingPreview && <LoadingPage />}
		</>
	);
};

export default ClipEditor;
