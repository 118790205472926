import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { control } from 'app/slices/search-explore/form.js';
import { getSearchedUsersAndDestinations, getTopThreeResults, getLoading as searchLoading } from 'app/slices/search-explore/search';
import _debounce from 'lodash.debounce';
import Tabs from '../Tabs';

const useStyles = makeStyles(() => ({
	container: {},
}));

const SearchResults = ({ isSearching }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const fields = control.get(dispatch);
	const search = useSelector(fields.search.selector);
	const destinationsAndUsers = useSelector(getSearchedUsersAndDestinations);
	const topResults = useSelector(getTopThreeResults);
	const loading = useSelector(searchLoading);

	const [showLoading, setShowLoading] = useState(loading);
	const debounceSetShowLoading = _debounce(setShowLoading, 250);

	useEffect(() => {
		if (loading) {
			setShowLoading(true);
		} else {
			debounceSetShowLoading(false);
		}
	}, [loading, debounceSetShowLoading]);

	const showNoResults = !destinationsAndUsers.length && !!search.length && !showLoading;

	return (
		<div className={classes.container}>
			{!!search.length && (
				<Tabs
					isSearching={isSearching}
					destinationsAndUsers={destinationsAndUsers}
					topResults={topResults}
					search={search}
					onChange={fields.search.change}
					loading={loading}
					showLoading={showLoading}
					showNoResults={showNoResults}
				/>
			)}
		</div>
	);
};

export default SearchResults;
