import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { change } from 'app/slices/user-account-v2/edit-profile/slice';
import { getUpdatedBio, getUpdatedDisplayName } from 'app/slices/user-account-v2/edit-profile/selectors';
import { getMyUsername, getMyBio, getMyName } from 'app/slices/user-account-v2/accounts/selectors';
import counter from 'utils/str-counter';
import Counter from 'rds/components/Counter';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginRight: theme.spacing(3),
		marginLeft: theme.spacing(3),
		marginTop: theme.spacing(3),
	},
	eachField: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	label: {
		color: theme.colors.neutral.C300,
		marginBottom: theme.spacing(),
	},
	textEditor: {
		background: theme.colors.neutral.C900,
		'& .MuiOutlinedInput-input': {
			padding: `0 ${theme.spacing(3.5)}px !important`,
		},
		'& .MuiOutlinedInput-root': {
			height: 40,
			width: '100% ',
		},
		width: '100%',
	},
	default: {
		background: theme.colors.neutral.C900,
		width: '100%',
		'& .MuiOutlinedInput-root': {
			height: 155,
			width: '100% ',
		},
		'& .MuiOutlinedInput-inputMultiline': {
			height: '135px !important',
		},
	},
	counter: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		left: 0,
		zIndex: 5,
	},

}));

const EachField = ({
	label,
	multiline,
	value,
	onChange,
	selector,
	maxLength,
	...props
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [focused, setFocused] = useState(false);

	return (
		<>
			<div className={classes.eachField}>
				<Typography variant="body2" className={classes.label}>{label}</Typography>
				<TextField
					onChange={(e) => {
						if (counter(e.target.value) <= maxLength) {
							dispatch(change({ [selector]: e.target.value }));
						}
					}}
					multiline={multiline}
					minRows={10}
					value={value}
					className={multiline ? classes.default : classes.textEditor}
					variant="outlined"
					onFocus={() => { setFocused(true); }}
					onBlur={() => { setFocused(false); }}
					{...props}
				/>
			</div>
			{focused
			&& (
				<div className={classes.counter}>
					<Counter
						value={value}
						maxLength={maxLength}
					/>
				</div>
			)}
		</>
	);
};

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const username = useSelector(getMyUsername);
	const displayName = useSelector(getUpdatedDisplayName);
	const bio = useSelector(getUpdatedBio);
	const myCurrentName = useSelector(getMyName);
	const myCurrentBio = useSelector(getMyBio);

	useEffect(() => {
		dispatch(change({
			bio: myCurrentBio || '',
			displayName: myCurrentName || '',
		}));
	}, []);

	return (
		<div className={classes.root}>
			<EachField
				label="Username"
				InputProps={{
					readOnly: true,
				}}
				value={username}
				onClick={() => { navigate('/my-account/settings/username'); }}
			/>
			<EachField
				label="Display Name"
				selector="displayName"
				value={displayName}
				maxLength={30}
				placeholder="Add Display Name"
			/>
			<EachField
				label="Bio"
				multiline
				selector="bio"
				value={bio}
				maxLength={150}
				placeholder="Add bio"
			/>

		</div>
	);
};
