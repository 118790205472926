import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
	toggleClipSound,
} from 'app/slices/content-creation/experience';
import {
	change,
} from 'app/slices/content-creation/editor/layout';
import IconV3 from 'rds/components/IconV3/IconV3';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		pointerEvents: 'none',
		bottom: 0,
		width: '100%',
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		height: 60,
		zIndex: 2140000003,
		display: 'flex',
		alignItems: 'center',
		background: theme.colors.neutral.C900,
		opacity: 0.9,
	},
	controls: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		width: '100%',
		zIndex: 2140000003,
	},
	clipTray: {
		position: 'absolute',
		width: '100%',
		top: -200,
	},
	hide: {
		display: 'none',
	},
	spinnerContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 60,
		minWidth: 57,
	},
}));

function Tray({ addText, clip, clipIndex }) {
	const classes = useStyles();
	const ref = useRef();
	const dispatch = useDispatch();
	const [muted, setMuted] = useState(clip?.muted || false);
	const isVideo = clip.editingCanvas.originalType === 'video';

	useEffect(() => {
		if (clip.muted) {
			setMuted(true);
		} else {
			setMuted(false);
		}
	}, [clip, clip.muted]);

	const iconStyle = { pointerEvents: 'all', width: '90px' };

	return (
		<div className={classes.root} ref={ref}>
			<div className={classes.controls}>
				<IconV3
					icon="AddTextOverlay"
					dropShadow
					size={40}
					onClick={addText}
					style={{ ...iconStyle }}
				/>
				<IconV3
					icon="Stickers"
					dropShadow
					size={32}
					onClick={() => {
						dispatch(change({ plugins: true }));
					}}
					style={{ ...iconStyle }}
				/>
				{!!isVideo
				&& (
					<IconV3
						icon={!muted ? 'SoundOn' : 'SoundOff'}
						dropShadow
						size={32}
						style={{ ...iconStyle }}
						onClick={() => {
							if (muted) {
								clip.editingCanvas.playAudio();
								setMuted(false);
								dispatch(toggleClipSound({ index: clipIndex, muted: false }));
							} else {
								clip.editingCanvas.pauseAudio();
								setMuted(true);
								dispatch(toggleClipSound({ index: clipIndex, muted: true }));
							}
						}}
					/>
				)}
			</div>
		</div>
	);
}

export default Tray;

// {downloading
//	 ? (
//		 <div className={classes.spinnerContainer}>
//			 <ButtonSpinner
//				 size={24}
//				 variant="primary"
//			 />
//		 </div>
//	 )
//	 :
// <Icon
// icon="download"
// variant="darkContrast"
// style={{}}
// containerHeight={60}
// containerWidth={57}
// height={24}
// width={24}
// style={{ pointerEvents: 'all' }}
// onClick={async () => {
//	 await dispatch(download(clip));
// }}
// />
