import React from 'react';
import Container from './Container';
import Icon from './Icon';

export default ({
	icon,
	color = 'neutralC000',
	containerSize = 48,
	size = 24,
	dropShadow,
	gredient,
	...rest
}) => (
	<Container size={containerSize} {...rest}>
		<Icon color={color} dropShadow={dropShadow} icon={icon} size={size} gredient={gredient} />
	</Container>
);
