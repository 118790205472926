import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getScroll,
	getIsSearching,
	setIsSearching,
	saveScroll,
} from 'app/slices/search-explore/layout';
import Header from './Header';
import ExploreGrid from './ExploreGrid';

const Explore = ({ show }) => {
	const dispatch = useDispatch();
	const isSearching = useSelector(getIsSearching);
	const scroll = useSelector(getScroll);

	const prevIsSearchingRef = useRef();
	useEffect(() => {
		prevIsSearchingRef.current = isSearching;
	}, [isSearching]);
	const prevIsSearching = prevIsSearchingRef.current;

	useEffect(() => {
		if (prevIsSearching === false && isSearching) {
			dispatch(saveScroll(window.scrollY));
			setTimeout(() => {
				window.scrollTo({ top: 0, left: 0 });
			}, 250);
		} else if (prevIsSearching === true && !isSearching) {
			window.scrollTo(0, scroll);
		}
	}, [isSearching, prevIsSearching, scroll, dispatch]);

	return (
		<>
			<Header
				isSearching={isSearching}
				setIsSearching={setIsSearching}
			/>
			<ExploreGrid
				show={show}
			/>
		</>
	);
};

export default Explore;
