import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		marginTop: theme.spacing(6),
		zIndex: 3,
	},
	caption: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
	controls: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginTop: theme.spacing(2),
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		zIndex: 3,
	},
	title: {
		textAlign: 'center',
		textShadow: 'none',
	},
	text: {
		textAlign: 'center',
		color: theme.colors.neutral.C200,
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		marginLeft: theme.spacing(8),
		marginRight: theme.spacing(8),
		whiteSpace: 'pre-wrap',
	},
}));

export default ({
	title, subtitle, children,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.caption}>
				<Typography className={classes.title} variant="h5">{title}</Typography>
				<Typography className={classes.text} variant="body1">{subtitle}</Typography>
			</div>
			<div className={classes.controls}>
				{children}
			</div>
		</div>
	);
};
