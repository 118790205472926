import React from 'react';
import ActivePlayer from './ActivePlayer';
import Header from './Header';
import Controls from './Controls';
import Footer from './Footer';
import Poster from './View/Poster';

export default ({
	active,
	errors,
	...props
}) => {
	const imageBackground = props.clips[0]?.outputs?.thumbnail?.small?.webp || props.clips[0]?.outputs?.photo?.small?.webp;

	return (
		<>
			<Header user={props.user} active={active} />
			<Controls experience={props} openPanel={props.openPanel} />
			{!errors && <Poster image={imageBackground} /> }
			<Footer {...props} />
			<ActivePlayer active={active} {...props} />
		</>
	);
};
