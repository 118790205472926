import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SELECT_CANVAS, SELECT_OBJECT } from 'lib/editing-canvas/src/event-types';
import { HTML } from 'lib/editing-canvas/src/types';
import { setEditingNode, selectTextBackground } from 'app/slices/content-creation/create/text-component';
import { selectEditingCanvas } from 'app/slices/content-creation/editing-canvas';
import { makeStyles } from '@material-ui/styles';
import Modal from './Modal';
import Recommendations from './Recommendations';
import StylePicker from './StylePicker';

const useStyles = makeStyles({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 0,
	},
});

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const editingCanvas = useSelector(selectEditingCanvas);
	const backgroundImage = useSelector(selectTextBackground);

	const openTapToType = () => {
		const { item } = editingCanvas.tapToType;
		const { id } = item;
		const el = editingCanvas.find(id);

		dispatch(
			setEditingNode({
				el,
				id,
			}),
		);
	};

	const selectCanvas = () => {
		openTapToType();
	};

	const selectObject = (e, object) => {
		const { el, id, node } = object;

		if (node.type === HTML) {
			dispatch(
				setEditingNode({
					el,
					id,
				}),
			);
		} else {
			openTapToType();
		}
	};

	useEffect(() => {
		if (!editingCanvas) {
			return () => { };
		}

		editingCanvas.addListener(SELECT_CANVAS, selectCanvas);
		editingCanvas.addListener(SELECT_OBJECT, selectObject);

		return () => {
			editingCanvas.removeListener(SELECT_CANVAS, selectCanvas);
			editingCanvas.removeListener(SELECT_OBJECT, selectObject);
		};
	}, [editingCanvas]);

	return (
		<>
			<div
				className={classes.container}
				style={{
					backgroundImage: `url('${backgroundImage.image}')`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
				}}
			>
				<Modal />
			</div>
			<Recommendations />
			<StylePicker />
		</>
	);
};
