import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import { getHeaderText } from 'app/slices/index';
import scrollEmitter from 'layout/scrollEmitter';
import headerImg from './Assets/header.svg';

const DARK_ACTIVE_GRADIENT = 'linear-gradient(90deg, rgba(25,204,204,1) 0%, rgba(243,81,32,1) 50%, rgba(214,201,17,1) 100%)';
const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		display: 'flex',
		flexDirection: 'column',
		zIndex: 10,
		background: theme.palette.background.page,
		paddingBottom: theme.spacing(2),
	},
	rootSecondary: {
		top: 48,
	},
	logo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
		userSelect: 'none',
	},
	rhino: {
		background: `url(${headerImg})`,
		width: 112,
		height: 32,
	},
	textContainer: {
		marginTop: theme.spacing(6),
		textAlign: 'center',
	},
	border: {
		width: '100%',
		position: 'absolute',
		left: 0,
		right: 0,
		height: 1,
		background: DARK_ACTIVE_GRADIENT,
		zIndex: 3,
	},
	text: {
		paddingRight: theme.spacing(15),
		paddingLeft: theme.spacing(15),
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},

}));

export default () => {
	const classes = useStyles();
	const show = useSelector(getIsUpdatedVersionAvailable);
	const klass = show ? clsx(classes.root, classes.rootSecondary) : clsx(classes.root);
	const headerText = useSelector(getHeaderText);

	return (
		<div className={klass}>
			<div
				className={classes.logo}
				onClick={() => {
					scrollEmitter.emit('scrollToTop');
				}}
			>
				<div className={classes.rhino} />
			</div>
			{headerText
				&& (
					<div className={classes.textContainer}>
						<div className={classes.border} />
						<Typography className={classes.text} variant="body1">{headerText}</Typography>
						<div className={classes.border} />
					</div>
				)}
		</div>
	);
};
