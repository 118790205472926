import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';
// helper functions

const addIt = (activities, uid) => {
	const next = activities.slice();
	next.push(uid);
	return next;
};

const removeIt = (activities, uid) => {
	const next = activities.slice();
	next.splice(next.findIndex((a) => uid === a), 1);
	return next;
};

export const requestActivities = createAsyncThunk(
	'requestGetActivities',
	async (_, {
		rejectWithValue,
	}) => {
		try {
			const response = await api.get('accounts/preferences/activities');
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestPatchActivity = createAsyncThunk(
	'requestPatchActivity',
	async ({ uid, remove }, {
		rejectWithValue,
	}) => {
		try {
			let response;
			if (remove) {
				response = await api.patch('accounts/preferences/remove-activity', {
					activity: uid,
				});
			} else {
				response = await api.patch('accounts/preferences/activity', {
					activity: uid,
				});
			}
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

// slice
export const activitiesSlice = createSlice({
	name: 'activities',
	initialState: {
		activities: [],
		errors: null,
		loading: false,
	},
	extraReducers: {
		[requestActivities.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestActivities.fulfilled]: (state, action) => {
			state.activities = action.payload.activities;
			state.loading = false;
			state.errors = null;
		},

		[requestActivities.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},

		[requestPatchActivity.pending]: (state, payload) => {
			const { remove, uid } = payload.meta.arg;

			if (remove) {
				state.activities = removeIt(state.activities, uid);
			} else {
				state.activities = addIt(state.activities, uid);
			}
		},

		[requestPatchActivity.rejected]: (state, action) => {
			if (action.payload) {
				if (action.payload.remove) {
					state.activities = addIt(state.activities, action.payload.uid);
				} else {
					state.activities = removeIt(state.activities, action.payload.uid);
				}
			}
		},
	},
});

const { name, reducer } = activitiesSlice;
export { name };

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);
export const getActivities = createSelector((state) => state, (state) => {
	const constants = state.app?.constants;
	const activities = state[name]?.activities;

	if (!activities || !constants?.activities) {
		return null;
	}

	return constants?.activities.filter((c) => c.title !== 'Other').map((a) => ({
		selected: activities && activities.findIndex((uid) => uid === a.uid) !== -1,
		...a,
	}));
});

injectReducer(name, reducer);
