import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { getVisibleExperience, getExitingExperience } from 'app/slices/experience-modal';
import { getDirection } from 'app/slices/layout';
import { useQueryParam } from 'app/hooks/useQueryParams';
import navigationEmitter from 'components/Navigation/emitter';
import emitter from './emitter';

const TIMEOUT = 300;
const EXPANSION_TRANSITION = `transform ${TIMEOUT / 1000}s ease-in-out`;
const HIDE_TRANSITION = 'all 300ms ease-in-out';
const IN_TRANSITION = `transform ${TIMEOUT / 2000}s ease-in-out`;

const useStyles = makeStyles({
	exitingEcr: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: '100%',
		width: '100%',
		zIndex: 28,
		overflow: 'hidden',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		MsOverflowStyle: 'none',
		scrollbarWidth: 'none',
		whiteSpace: 'nowrap',
		touchAction: 'none',
		transitionDelay: 130,
		transition: EXPANSION_TRANSITION,
		display: 'none',
	},
	show: {
		display: 'block',
	},
	backgroundImage: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		transition: EXPANSION_TRANSITION,
		opacity: 0,
		pointerEvents: 'none',
	},
	showBackground: {
		opacity: 1,
	},
});

const slideOut = (div) => {
	const transform = 'translateX(-40%)';

	div.style.transform = transform;
	div.style.zIndex = 3;
	setTimeout(() => {
		if (!div) {
			return;
		}
		div.style.removeProperty('transform');
		div.style.removeProperty('z-index');
	}, 400);
};

const slideIn = (div) => {
	const transform = 'translateX(0%)';
	div.style.transition = IN_TRANSITION;

	window.requestAnimationFrame(() => {
		div.style.transform = transform;
		setTimeout(() => {
			if (!div) {
				return;
			}
			div.style.removeProperty('transform');
			div.style.removeProperty('transition');
			div.style.removeProperty('z-index');
		}, 400);
	});
};

const shrink = (div) => {
	if (!div) {
		return;
	}

	div.style.transition = HIDE_TRANSITION;
	div.style.width = `${window.innerWidth}px`;
	div.style.height = `${window.innerHeight}px`;

	window.requestAnimationFrame(() => {
		if (!div) {
			return;
		}

		div.style.height = 0;
		div.style.width = 0;
		div.style.opacity = 0;
		div.style.top = `${window.innerHeight}px`;
		div.style.left = `${window.innerWidth / 2}px`;

		setTimeout(() => {
			if (!div) {
				return;
			}
			div.style.removeProperty('height');
			div.style.removeProperty('width');
			div.style.removeProperty('opacity');
			div.style.removeProperty('top');
			div.style.removeProperty('left');
			div.style.removeProperty('transition');
		}, 350);
	});
};

// const expand = (div) => {
// 	if (!div) {
// 		return;
// 	}

// 	div.style.removeProperty('transition');
// 	window.requestAnimationFrame(() => {
// 		div.style.height = 0;
// 		div.style.width = 0;
// 		div.style.opacity = 0;
// 		div.style.top = `${window.innerHeight}px`;
// 		div.style.left = `${window.innerWidth / 2}px`;
// 		div.style.transition = HIDE_TRANSITION;

// 		window.requestAnimationFrame(() => {
// 			if (!div) {
// 				return;
// 			}

// 			// div.style.top = 0;
// 			// div.style.left = 0;
// 			div.style.opacity = 1;
// 			// div.style.height = '50%';
// 			// div.style.width = '50%';

// 			setTimeout(() => {
// 				if (!div) {
// 					return;
// 				}
// 				div.style.removeProperty('height');
// 				div.style.removeProperty('width');
// 				div.style.removeProperty('opacity');
// 				div.style.removeProperty('top');
// 				div.style.removeProperty('left');
// 				div.style.removeProperty('transition');
// 			}, 400);
// 		});
// 	});
// };

const ECRExiting = ({
	children,
	showPanes,
}) => {
	const classes = useStyles();
	const ref = useRef();
	const uid = useQueryParam('experience');
	const direction = useSelector(getDirection);
	const experience = useSelector(getVisibleExperience);
	const exitingExperience = useSelector(getExitingExperience);
	const [backgroundImageSrc, setBackgroundImageSrc] = useState(null);

	const activeExperience = !experience?.exiting ? experience : null;

	useEffect(() => {
		const opening = () => {
			if (ref?.current) {
				// ref.current.style.zIndex = 4;
			}
		};
		const opened = () => {
			if (ref?.current) {
				// ref.current.style.zIndex = 28;
			}
		};

		emitter.on('opening', opening);
		emitter.on('opened', opened);

		return () => {
			emitter.off('opening', opening);
			emitter.off('opened', opened);
		};
	}, []);

	useEffect(() => {
		if (activeExperience) {
			const clip = activeExperience.clips.find((c) => c.clipUid === activeExperience.coverClip);
			const imageSrc = clip?.outputs?.thumbnail?.medium?.jpg || clip?.outputs?.photo?.medium?.jpg;
			setBackgroundImageSrc(imageSrc);
		}
	}, [activeExperience]);

	useEffect(() => {
		if (ref?.current) {
			if (exitingExperience && direction === 'forward') {
				navigationEmitter.emit('fade-menu-in');
				slideOut(ref.current);
			} else if (exitingExperience && direction === 'back') {
				shrink(ref.current);
			} else if (!exitingExperience && direction === 'back') {
				slideIn(ref.current);
			}
		}
	}, [ref, direction, exitingExperience]);

	const klass = experience || uid
		? clsx(classes.exitingEcr, classes.show)
		: classes.exitingEcr;

	const backgroundClass = clsx(classes.backgroundImage, {
		[classes.showBackground]: exitingExperience,
	});

	const backgroundStyle = {
		backgroundImage: `url(${backgroundImageSrc})`,
	};

	return (
		<div className={klass} ref={ref}>
			{
				showPanes && children
			}
			<div className={backgroundClass} style={backgroundStyle} />
		</div>
	);
};

export default ECRExiting;
