import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import deduplicateList from 'utils/deduplicate-List';
import {
	LargePattern,
	SmallPattern,
	howMany,
} from './explore-patterns';

const updateLocalStorage = (filters) => {
	const stringifiedFilters = JSON.stringify(filters);
	window.sessionStorage.setItem('exploreFilters', stringifiedFilters);
};

export const requestExploreContents = createAsyncThunk(
	'requestExploreContents',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { destinations, experiences, filters } = getState().searchExploreExplore;
			const {
				destinations: destinationsLimit,
				experiences: experiencesLimit,
			} = howMany(experiences, destinations);

			const qparams = {
				experiencesLimit,
				activities: filters.Activity.map((f) => f.slug).join(','),
				recommendedFor: filters['Recommended for'].map((f) => f.slug).join(','),
				identifiers: filters.Community.map((f) => f.slug).join(','),
			};

			if (!qparams.activities && !qparams.recommendedFor && !qparams.identifiers) {
				qparams.destinationsLimit = destinationsLimit;
			}

			const parts = [];
			Object.keys(qparams).forEach((key) => {
				if (qparams[key]) {
					parts.push(`${key}=${qparams[key]}`);
				}
			});
			const qs = parts.length ? `?${parts.join('&')}` : '';

			const response = await api.get(`content/v0/explore${qs}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error, { notifyUser: false }));
		}
	},
);

export const requestLoadMoreExploreContents = createAsyncThunk(
	'requestLoadMoreExploreContents',
	async (_, { getState, rejectWithValue }) => {
		try {
			const {
				destinations,
				experiences,
				filters,
				links,
			} = getState().searchExploreExplore;

			const url = links?.next?.href;
			const {
				destinations: destinationsLimit,
				experiences: experiencesLimit,
			} = howMany(experiences, destinations);

			const qparams = {
				experiencesLimit,
			};
			const filtersParams = {
				activities: filters.Activity.map((f) => f.slug).join(','),
				recommendedFor: filters['Recommended for'].map((f) => f.slug).join(','),
				identifiers: filters.Community.map((f) => f.slug).join(','),
			};

			if (!filtersParams.activities && !filtersParams.recommendedFor && !filtersParams.identifiers) {
				qparams.destinationsLimit = destinationsLimit;
			}

			const parts = [];
			Object.keys(qparams).forEach((key) => {
				if (qparams[key]) {
					parts.push(`${key}=${qparams[key]}`);
				}
			});
			const qs = parts.length ? `${parts.join('&')}` : '';
			const response = await api.get(`${url}&${qs}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const Pattern = window.innerWidth < 402 ? SmallPattern : LargePattern;
const pattern = new Pattern();

const placeResults = ({ ...params }) => {
	const {
		destinations,
		experiences,
		stateDestinations,
		stateExperiences,
		stateItems,
	} = params;
	const nextD = destinations && destinations.concat(stateDestinations);
	const nextE = experiences && experiences.concat(stateExperiences);
	const items = stateItems.slice();
	while (experiences.length) {
		const result = pattern.shift(nextE, nextD);
		if (!result) {
			break;
		}
		items[result.index].push(result);
	}
	return { nextE, nextD, items };
};

export const exploreSlice = createSlice({
	name: 'searchExploreExplore',
	initialState: {
		experiences: [],
		destinations: [],
		ecrExperiences: [],
		items: pattern.cols.map(() => []),
		cols: pattern.cols.map((c) => ({ size: c.size })),
		links: null,
		tab: 0,
		errors: null,
		loading: false,
		loadingMore: false,
		openSearch: false,
		filters: JSON.parse(window.sessionStorage.getItem('exploreFilters')) || { Activity: [], Community: [], 'Recommended for': [] },
		filterHasBeenChanged: false,
		openingFiltersState: null,
		firstLoad: true,
		seenBeforeEcrList: {},
		seenBeforeExploreGrid: {},
		seenBeforeDestinations: {},
		sharedExperience: null,
	},
	reducers: {
		resetExplore: (state) => {
			state.experiences = [];
			state.destinations = [];
			state.ecrExperiences = [];
			state.items = pattern.cols.map(() => []);
			state.links = null;
			state.sharedExperience = null;
		},
		addFilters: (state, action) => {
			const { group, filter } = action.payload;
			state.filters[group].push(filter);
			updateLocalStorage(state.filters);
			state.filterHasBeenChanged = true;
		},
		removeFilters: (state, action) => {
			const { group, filter } = action.payload;
			state.filters[group] = state.filters[group].filter((o) => o.slug !== filter.slug);
			state.filterHasBeenChanged = true;
			updateLocalStorage(state.filters);
		},
		resetFilters: (state) => {
			state.filters = { Activity: [], Community: [], 'Recommended for': [] };
			state.filterHasBeenChanged = true;
			updateLocalStorage(state.filters);
		},
		selectAll: (state, action) => {
			const { group, filters } = action.payload;
			state.filters[group] = filters;
			state.filterHasBeenChanged = true;
			updateLocalStorage(state.filters);
		},
		updateExperiences: (state, action) => {
			const previousState = [...state.ecrExperiences];
			const newState = [];
			for (let i = 0; i < previousState.length; i++) {
				if (previousState[i].uid === action.payload.uid) {
					previousState[i] = action.payload;
				}
				newState.push(previousState[i]);
			}
			state.ecrExperiences = newState;
		},
		addSharedExperienceToEcr: (state, action) => {
			state.sharedExperience = action.payload;
		},
		removeSharedExperienceInEcr: (state) => {
			if (state?.sharedExperience?.uid === state?.ecrExperiences[0]?.uid) {
				const [, ...newArray] = state.ecrExperiences;
				state.ecrExperiences = newArray;
				state.sharedExperience = null;
			}
		},
		resetToPreviousState: (state) => {
			state.filterHasBeenChanged = false;
			state.filters = state.openingFiltersState;
			updateLocalStorage(state.filters);
		},
		setOpenModalState: (state) => {
			const p = { ...state.filters };
			state.openingFiltersState = p;
		},
		// removeDestination: (state, action) => {
		// 	const items = state.items.slice();

		// 	for (let i = 0; i < items.length; i++) {
		// 		items[i] = items[i].slice().filter(
		// 			(item) => item.entity.destinations.indexOf(action.payload) === -1,
		// 		);
		// 	}
		// 	state.items = items;
		// 	state.destinations = state.destinations.slice().filter(
		// 		(d) => d.uid === action.payload,
		// 	);
		// 	state.experiences = state.experiences.slice().filter(
		// 		(e) => e.destinationsV2.find((d) => d.uid !== action.payload),
		// 	);
		// 	const x = state.ecrExperiences.slice();
		// 	state.ecrExperiences = state.ecrExperiences.slice().filter(
		// 		(e) => e.destinationsV2.find((d) => d.uid !== action.payload),
		// 	);
		// 	const y = state.ecrExperiences.slice();
		// },
	},
	extraReducers: {
		[requestExploreContents.pending]: (state) => {
			state.loading = true;
			state.errors = null;
			state.filterHasBeenChanged = false;
			state.items = pattern.cols.map(() => []);
			state.destinations = [];
			state.experiences = [];
			state.links = null;
			state.ecrExperiences = [];
			state.seenBeforeEcrList = {};
			state.seenBeforeExploreGrid = {};
			state.seenBeforeDestinations = {};
			pattern.reset();
		},
		[requestExploreContents.fulfilled]: (state, action) => {
			const { destinations, experiences, links } = action.payload;
			let ecrExperiences = [...experiences];

			state.seenBeforeEcrList = {};
			state.seenBeforeExploreGrid = {};
			state.seenBeforeDestinations = {};

			ecrExperiences = deduplicateList(ecrExperiences, state.seenBeforeEcrList, 'uid');
			const deduplicatedExperiencesList = deduplicateList(experiences, state.seenBeforeExploreGrid, 'uid');
			const deduplicatedDestinationsList = deduplicateList(destinations, state.seenBeforeDestinations, 'uid');

			if (state.sharedExperience !== null) {
				ecrExperiences.unshift(state.sharedExperience);
				deduplicatedExperiencesList.unshift(state.sharedExperience);
			}
			const { nextE, nextD, items } = placeResults({
				destinations: deduplicatedDestinationsList,
				experiences: deduplicatedExperiencesList,
				stateDestinations: state.destinations,
				stateExperiences: state.experiences,
				stateItems: state.items,
			});
			state.items = items;
			state.destinations = nextD || [];
			state.experiences = nextE || [];
			state.ecrExperiences = ecrExperiences;
			state.links = deduplicatedExperiencesList.length ? links || null : null;
			state.loading = false;
			state.errors = null;
			state.firstLoad = false;
		},
		[requestExploreContents.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestLoadMoreExploreContents.pending]: (state) => {
			state.loadingMore = true;
			state.errors = null;
		},
		[requestLoadMoreExploreContents.fulfilled]: (state, action) => {
			const { destinations, experiences, links } = action.payload;
			const deduplicatedExperiencesList = deduplicateList(experiences, state.seenBeforeExploreGrid, 'uid');
			const deduplicatedDestinationsList = deduplicateList(destinations, state.seenBeforeDestinations, 'uid');
			const { nextE, nextD, items } = placeResults({
				destinations: deduplicatedDestinationsList,
				experiences: deduplicatedExperiencesList,
				stateDestinations: state.destinations,
				stateExperiences: state.experiences,
				stateItems: state.items,
			});
			state.items = items;
			state.destinations = nextD || [];
			state.experiences = nextE || [];
			state.ecrExperiences = [...state.ecrExperiences, ...deduplicatedExperiencesList];
			state.links = deduplicatedExperiencesList.length ? links || null : null;
			state.loadingMore = false;
			state.errors = null;
		},
		[requestLoadMoreExploreContents.rejected]: (state, action) => {
			state.loadingMore = false;
			state.errors = action.payload;
		},

	},
});

const { name, reducer, actions } = exploreSlice;
const {
	addFilters,
	removeFilters,
	resetFilters,
	selectAll,
	updateExperiences,
	setOpenModalState,
	resetToPreviousState,
	resetExplore,
	addSharedExperienceToEcr,
	removeSharedExperienceInEcr,
	// removeDestination,
} = actions;

export {
	addFilters,
	removeFilters,
	resetFilters,
	selectAll,
	updateExperiences,
	setOpenModalState,
	resetToPreviousState,
	resetExplore,
	addSharedExperienceToEcr,
	removeSharedExperienceInEcr,
	// removeDestination,
};

export const getSlice = (state) => state?.[name];
export const getIsFirstLoad = createSelector(getSlice, (slice) => slice?.firstLoad);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getLoadingMore = createSelector(getSlice, (slice) => slice?.loadingMore);
export const getHasMoreToLoad = createSelector(getSlice, (slice) => !!slice?.links?.next?.href);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getFilters = createSelector(getSlice, (slice) => slice?.filters);
export const getFilterModal = createSelector(getSlice, (slice) => slice?.isFilterModalOpen);
export const getExploreExperiences = createSelector(getSlice, (slice) => slice?.ecrExperiences);
export const getFilterHasBeenChanged = createSelector(getSlice, (slice) => slice?.filterHasBeenChanged);
export const getOpeningFilterState = createSelector(getSlice, (slice) => slice?.openingFiltersState);
export const getExploreData = createSelector(getSlice, (slice) => ({
	cols: slice.cols,
	items: slice?.items,
	links: slice?.links,
}));

injectReducer(name, reducer);
