import { compose } from 'lib/editing-canvas/src';
import fontsMap from 'utils/fonts-map';

// import { CSSFilter, FilterCollection } from 'lib/editing-canvas/src/filters';
// const normal = new CSSFilter('normal', 'Normal', {});
// const nineteen77 = new CSSFilter('nineteen77', '1977', {
//	 contrast: 1.1,
//	 brightness: 1.1,
//	 saturate: 1.3,
//	 background: 'rgba(243, 106, 188, .3)',
// });
// const brooklyn = new CSSFilter('brooklyn', 'Brooklyn', {
//	 contrast: 0.9,
//	 brightness: 1.1,
//	 background: 'radial-gradient(circle, rgba(168, 223, 193, .4) 70%, rgb(196, 183, 200))',
// });
// const earlyBird = new CSSFilter('early-bird', 'Early Bird', {
//	 contrast: 0.9,
//	 sepia: 0.2,
//	 background: 'radial-gradient(circle, rgb(208, 186, 142) 20%, rgb(54, 3, 9) 85%, rgb(29, 2, 16) 100%)',
//	 opacity: 0.6,
//	 'mix-blend-mode': 'overlay',
// });

// const collection = new FilterCollection([normal, nineteen77, brooklyn, earlyBird]);
// { filterCollection: collection, }

export default async ({ preview }) => {
	const ec = compose({
		dimensions: {
			height: window.innerHeight,
			width: window.innerWidth,
		},
		fps: 60,
		fonts: fontsMap,
		styles: {
			backgroundSize: 'cover',
		},
		preview,
	});

	ec.draw();
	return ec;
};
