import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import Avatar from 'rds/components/Avatar';
import { requestNotifyServerOnDestinationSelection } from 'app/slices/notify-server';
import { useNavigate } from 'react-router-dom';
import { resetFilters } from 'app/slices/destination-account-v2/experiences/slice';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		paddingTop: theme.spacing(4.5),
		paddingBottom: theme.spacing(4.5),
		alignItems: 'center',
	},
	avatarContainer: {
		marginRight: theme.spacing(2),
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'left',
		textAlign: 'left',
	},
	name: {
		color: theme.colors.neutral.C200,
	},

}));

export default ({
	destination,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div
			className={classes.root}
			onClick={async () => {
				const res = await dispatch(requestNotifyServerOnDestinationSelection(destination.uid));
				const { type } = res;
				dispatch(resetFilters(res.payload.uid));
				if (type === 'requestNotifyServerOnDestinationSelection/fulfilled') {
					navigate(`/destination/${res.payload.uid}`);
				}
			}}
		>
			<div className={classes.avatarContainer}>
				<Avatar alt="user" size="large" src={destination.thumbnail.medium.jpg} />
			</div>
			<div className={classes.info}>
				<Typography variant="subtitle2">
					+
					{destination.title}
				</Typography>
				<Typography className={classes.name} variant="caption">{destination.subtitle}</Typography>
			</div>
		</div>
	);
};
