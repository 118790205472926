import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import LogoWordmark from './Logo-Wordmark';
import igIcon from './instagram.png';

const useStyles = makeStyles((theme) => ({
	desktop: {
		display: 'block',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	mobile: {
		display: 'none',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: theme.spacing(24),
		padding: theme.spacing(0, 6, 6),
		alignItems: 'center',
		textAlign: 'center',
		'@media (max-width: 599px)': {
			display: 'flex',
		},
	},
	mobileColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: theme.spacing(4),
		gap: theme.spacing(4),
	},
	footer: {
		// maxWidth: 1280,
		// margin: '0 auto',
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1,
		justifyContent: 'space-between',
		padding: theme.spacing(0, 9, 6),
		marginTop: theme.spacing(20),
	},

	link: {
		color: theme.colors.neutral.C000,
	},

	body2: {
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '25.6px',
		letterSpacing: -0.08,
		marginTop: theme.spacing(4),
	},

	igIcon: {
		cursor: 'pointer',
		display: 'inline-block',
		margin: 0,
		height: 40,
		width: 40,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50%',
		fontSize: 0,
	},

	column: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		gap: theme.spacing(4),
	},

	contacts: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'right',
		marginTop: theme.spacing(3),
	},

	right: {
		alignItems: 'flex-end',
	},
}));

export default () => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.desktop}>
				<div className={classes.footer}>
					<div className={classes.column}>
						<LogoWordmark width={100} />
						<Typography variant="body1">Explore. Connect. Discover.</Typography>
						<Typography variant="body1">Rhino Apps, LLC 2023. All rights reserved.</Typography>
					</div>

					<div className={clsx(classes.column, classes.right)}>
						<div>
							<div className={classes.contacts}>
								<a
									target="_blank"
									href="https://www.instagram.com/roamrhino/"
									className={classes.igIcon}
									style={{ backgroundImage: `url(${igIcon})` }}
									rel="noreferrer"
								>
									rhino ig

								</a>
							</div>
						</div>
						<div className={classes.links}>
							<Typography variant="body1">
								<Link className={classes.link} to="/terms">Terms</Link>
								{' '}
								|
								{' '}
								<Link className={classes.link} to="/terms#privacy-policy">Privacy Policy</Link>
							</Typography>

						</div>
					</div>
				</div>

			</div>
			<div className={classes.mobile}>
				<div className={classes.mobileColumn}>
					<div>
						<LogoWordmark width={100} />
						<Typography variant="body1">Explore. Connect. Discover.</Typography>
					</div>

					<br />
					<a
						target="_blank"
						href="https://www.instagram.com/roamrhino/"
						className={classes.igIcon}
						style={{ backgroundImage: `url(${igIcon})` }}
						rel="noreferrer"
					>
						rhino ig
					</a>
					<br />
					<Typography variant="body1">Rhino Apps, LLC 2023. All rights reserved.</Typography>
					<Typography variant="body1">
						<Link className={classes.link} to="/terms">Terms</Link>
					</Typography>
					<Typography variant="body1">
						<Link className={classes.link} to="/terms#privacy-policy">Privacy Policy</Link>
					</Typography>
				</div>
			</div>
		</>
	);
};
