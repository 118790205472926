import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setOpenDirective } from 'app/slices/experience-modal';
import { useFormUrl } from 'app/hooks/useQueryParams';

/**
 * Custom hook for generating ECR Expansion
 *
 * @param {string} id - The id associated with the experience modal
 * @param {string} src - The source URL
 * @param {object} [params] - Extra params
 * @return {function} expandECR - The generated handle click function
 */
const useExpandECR = (uid, src, params = {}, options = {}) => {
	const dispatch = useDispatch();
	const formUrl = useFormUrl();
	const navigate = useNavigate();

	const to = formUrl({
		params: {
			experience: uid,
			...params,
		},
	});

	const selectorFn = options.selectorFn
		? options.selectorFn
		: (sel) => `[data-experience-modal-id="${sel}"]`;

	const expandECR = async () => {
		await dispatch(setOpenDirective({
			querySelector: selectorFn(options.selector || uid),
			selectorFn,
			src,
		}));
		navigate(to, { replace: true });
	};

	return expandECR;
};

export default useExpandECR;
