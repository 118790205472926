import {
	isLocked,
	isObject,
	isRoot,
	numFromPx,
	isNodeLocked,
} from './utils';

const getRotationAngle = (e, store) => {
	e.moveTarget.touchAngleChange = null;

	const forceRotation = e.moveTarget.getAttribute('data-force-rotation');
	if (forceRotation !== null) {
		return parseFloat(forceRotation);
	}

	if (e.touches.length !== 2 || store.lastEvent.touches.length !== 2) {
		return e.moveTarget.rotation;
	}

	const [thisTouch1, thisTouch2] = e.touches;
	const dx = thisTouch2.clientX - thisTouch1.clientX;
	const dy = thisTouch2.clientY - thisTouch1.clientY;

	const touchAngle = Math.atan2(dy, dx);

	if (!e.moveTarget.startAngle) {
		e.moveTarget.rotations = 0;
		e.moveTarget.startAngle = touchAngle;
		return e.moveTarget.rotation;
	}

	const startOffset = e.moveTarget.rotation || 0;
	const { startAngle } = e.moveTarget;
	const change = touchAngle - startAngle;

	let nextRotation = ((change * 180) / Math.PI) % 360;

	if (Math.abs(nextRotation) > 160) {
		nextRotation /= 180;
	}

	e.moveTarget.touchAngleChange = change;
	e.moveTarget.startAngle = change + startAngle;
	e.moveTarget.rotation = nextRotation + startOffset;

	return e.moveTarget.rotation;
};

const findCentroid = (points) => {
	let area = 0;
	let x = 0;
	let y = 0;

	for (let i = 0; i < points.length - 1; i++) {
		const p = points[i];
		const np = points[i + 1];

		const m = (p.x * np.y - np.x * p.y);
		x += m * (p.x + np.x);
		y += m * (p.y + np.y);

		area += (p.x * np.y) - (np.x * p.y);
	}

	area /= 2;
	x *= (1 / (6 * area));
	y *= (1 / (6 * area));

	return { x, y };
};

const getPosition = (e, store) => {
	if (!store.lastEvent) {
		return null;
	}

	if (e.touches.length !== store.lastEvent.touches.length) {
		return null;
	}

	const { rect } = e;

	if (e.touches.length > 2) {
		const points = [];
		for (let i = 0; i < e.touches.length; i++) {
			points.push({ x: e.touches[i].clientX, y: e.touches[i].clientY });
		}

		const centroid = findCentroid(points);
		return {
			left: centroid.x,
			top: centroid.y,
		};
	}

	let dx;
	let dy;

	if (e.touches.length === 2 && store.lastEvent.touches.length === 2) {
		const dx1 = e.touches[0].clientX - store.lastEvent.touches[0].clientX;
		const dy1 = e.touches[0].clientY - store.lastEvent.touches[0].clientY;

		const dx2 = e.touches[1].clientX - store.lastEvent.touches[1].clientX;
		const dy2 = e.touches[1].clientY - store.lastEvent.touches[1].clientY;

		dx = (dx1 + dx2) / 2;
		dy = (dy1 + dy2) / 2;
	} else {
		dx = e.touches[0].clientX - store.lastEvent.touches[0].clientX;
		dy = e.touches[0].clientY - store.lastEvent.touches[0].clientY;
	}

	const startTop = numFromPx(e.moveTarget.style.top) || rect.top;
	const startLeft = numFromPx(e.moveTarget.style.left) || rect.left;
	const left = startLeft + dx;
	const top = startTop + dy;

	return {
		left,
		top,
	};
};

const getScale = (e) => {
	const forceScale = e.moveTarget.getAttribute('data-force-scale');
	if (forceScale !== null) {
		return parseFloat(forceScale);
	}

	if (e.touches.length !== 2) {
		e.moveTarget.lastDistance = null;
		return e.moveTarget.scale;
	}

	e.moveTarget.scale = Math.max(e.moveTarget.scale || 1, 0.02);

	const [thisTouch1, thisTouch2] = e.touches;
	const dx = thisTouch2.clientX - thisTouch1.clientX;
	const dy = thisTouch2.clientY - thisTouch1.clientY;
	const distance = Math.sqrt((dx * dx) + (dy * dy));

	if (!e.moveTarget.lastDistance) {
		e.moveTarget.lastDistance = distance;
		return e.moveTarget.scale;
	}

	const change = distance - e.moveTarget.lastDistance;
	e.moveTarget.scale += (change / 200);
	e.moveTarget.lastDistance = distance;
	return e.moveTarget.scale;
};

const handleFilterMove = (e, canvas, store) => {
	if (canvas.filterCollection && store.lastEvent) {
		e.now = Date.now();
		e.filterMove = true;
		const dx = e.touches[0].clientX - store.lastEvent.touches[0].clientX;
		canvas.filterCollection.move(dx);
		e.dx = dx;
		store.lastEvent = e;
	}
};

export default (canvas, store) => (e) => {
	if (canvas.ignoreGestures) { return; }
	if (store.withinGracePeriod()) {
		return;
	}

	e.moveTarget = e.target;
	if (!isObject(e) || isNodeLocked(e.moveTarget)) {
		e.moveTarget = canvas.container.querySelector(`[data-object-id="${canvas.store.root}"]`);
	}

	if (
		(store.lastEvent && store.lastEvent.filterMove)
		|| ((isRoot(e) || isLocked(e)) && e.touches.length < 2)
	) {
		handleFilterMove(e, canvas, store);
		return;
	}

	if (isNodeLocked(e.moveTarget)) {
		return;
	}

	if (!isObject(e)) {
		if (e.touches.length === 1) {
			handleFilterMove(e, canvas, store);
			return;
		}

		if (!e.moveTarget) {
			return;
		}
	}

	if (!store.interacting) {
		return;
	}

	if (!store.lastEvent) {
		store.lastEvent = e;
		return;
	}

	if (store.lastEvent.filterMove) {
		return;
	}

	if (e.moveTarget !== store.target) {
		return;
	}

	e.rect = e.moveTarget.getBoundingClientRect();

	const id = parseInt(e.moveTarget.getAttribute('data-object-id'), 10);
	const transform = [];
	const edits = {};

	const scale = getScale(e, store);
	let rotation = getRotationAngle(e, store, canvas);
	const position = getPosition(e, store);

	store.checkInteraction(e);

	const adj = canvas.grid.resolve(e.moveTarget);

	if (adj.rotation) {
		rotation = adj.rotation;
	}

	if (rotation) {
		transform.push(`rotate(${rotation}deg)`);
	}

	if (e.moveTarget.getAttribute('data-mirror') === 'true') {
		transform.push('rotateY(180deg)');
	}

	if (scale) {
		transform.push(`scale(${scale})`);
	}

	if (position) {
		if (adj.position.left) {
			position.left = adj.position.left;
		}
		if (adj.position.top) {
			position.top = adj.position.top;
		}
		edits.left = `${position.left}px`;
		edits.top = `${position.top}px`;
	}

	if (transform.length) {
		edits.transform = transform.join(' ');
	}
	canvas.store.update(id, undefined, edits);

	store.lastEvent = e;

	if (isObject(e)) {
		canvas.onObjectMove(e, {
			el: e.moveTarget,
			event: e,
			position,
			rect: e.rect,
			rotation,
			scale,
		});
	}
};
