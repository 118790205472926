import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'rds/components/Layouts/Form';
import PasswordField from 'routes/Auth/components/fields/PasswordField';
import TextField from 'rds/components/TextField';
import SubmitButton from 'routes/Auth/components/SubmitButton/SubmitButton';
import Actions from 'rds/components/Actions';
import Typography from 'rds/components/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import {
	control, getFormErrors, resetForm, resetErrors,
} from 'app/slices/authentication/signIn/sign-in-form.js';
import { getLoading, requestGrantPassword } from 'app/slices/authentication/signIn/grant-password';
import SignInError from 'rds/components/Errors/SignInError';
import FooterText from '../MainScreen/FooterText';
import Footer from '../components/footer/Footer';

export default () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const formErrors = useSelector(getFormErrors);
	const fields = control.get(dispatch);
	const username = useSelector(fields.username.selector);
	const password = useSelector(fields.password.selector);
	const loading = useSelector(getLoading);
	const [focused, setFocused] = useState(false);

	const onSubmit = useCallback(
		async (e) => {
			if (loading) {
				return;
			}
			e.preventDefault();
			const response = await dispatch(requestGrantPassword());

			if (response?.payload?.token) {
				navigate('/');
			}
		},
		[dispatch, navigate],
	);

	useEffect(() => {
		dispatch(resetForm());
	}, [dispatch]);

	const disabled = !username || !password;

	return (
		<>
			<Form onSubmit={onSubmit}>
				<TextField
					autoComplete="off"
					onBlurCb={() => { setFocused(false); }}
					onFocusCb={() => { setFocused(true); }}
					name="username"
					variant="glass"
					id="username"
					searchFieldFocused={focused}
					value={username.toLowerCase()}
					err={useSelector(fields.username.err)}
					onChange={(e) => {
						fields.username.change(e);
						if (formErrors) {
							dispatch(resetErrors());
						}
					}}
					formItem
					placeholder="Username / Email"
					icon={{
						icon: 'Avatar',
						size: 20,
					}}
				/>
				<PasswordField
					value={password}
					id="password"
					placeholder="Password"
					signin
					variant="glass"
					err={useSelector(fields.password.err)}
					onChange={(e) => {
						fields.password.change(e);
						if (formErrors) {
							dispatch(resetErrors());
						}
					}}
					onBlurCb={() => { setFocused(false); }}
					onFocusCb={() => { setFocused(true); }}
				/>
				<Actions>
					<Link to="/forgot-password" style={{ color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900 }}>
						<Typography variant="body2">
							Forgot password?
						</Typography>
					</Link>

				</Actions>
				<SubmitButton
					loading={loading}
					disabled={disabled}
					onMouseDown={(e) => {
						e.preventDefault();
						const usernameField = document.getElementById('username');
						const passwordField = document.getElementById('password');
						usernameField.blur();
						passwordField.blur();
					}}
				>
					Log in
				</SubmitButton>
				<SignInError errors={formErrors} />
			</Form>
			{!focused
				&& (
					<Footer>
						<FooterText />
					</Footer>
				)}

		</>

	);
};
