import React, {
	useState,
	createContext,
	useContext,
	useCallback,
} from 'react';

const NavbarStateContext = createContext();

export const NavbarStateProvider = ({ children }) => {
	const [isNavbarOpen, setIsNavbarOpen] = useState(true);
	const [currentPriority, setCurrentPriority] = useState(null);

	const open = useCallback((priority = 'low', delay = 0) => {
		if (!currentPriority || priority === 'high-priority') {
			setTimeout(() => {
				setIsNavbarOpen(true);
				setCurrentPriority(priority);
			}, delay);
		}
	}, [currentPriority]);

	const close = useCallback((priority = 'low', delay = 0) => {
		setTimeout(() => {
			setIsNavbarOpen(false);
			setCurrentPriority(priority);
		}, delay);
	}, []);

	return (
		<NavbarStateContext.Provider value={{ open, close, isNavbarOpen }}>
			{children}
		</NavbarStateContext.Provider>
	);
};

export const useNavbarState = () => {
	const context = useContext(NavbarStateContext);
	if (context === undefined) {
		throw new Error('whatever');
	}
	return context;
};
