import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
	selectEditingCanvas,
} from 'app/slices/content-creation/editing-canvas';

export default () => {
	const ref = useRef();
	const editingCanvas = useSelector(selectEditingCanvas);

	useEffect(() => {
		if (ref?.current && editingCanvas) {
			ref.current.appendChild(editingCanvas.container);
		}

		return () => {
			if (ref?.current) {
				ref.current.innerHTML = '';
			}
		};
	}, [ref, editingCanvas]);

	return <div ref={ref} />;
};
