import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	poster: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		width: '100%',
		height: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		touchAction: 'none',
		opacity: 1,
		background: theme.palette.background.page,
	},
}));

export default ({
	image,
}) => {
	const classes = useStyles();

	const style = {
		backgroundImage: `url("${image}")`,
	};

	return (
		<div className={classes.poster} style={style} />
	);
};
