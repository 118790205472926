import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getTransitioned } from 'app/slices/experience-modal';
import CustomAlert from 'rds/components/CustomAlert/CustomAlert';
import useScreenDimensions from 'app/hooks/useScreenDimensions';
import View from './View';

const useStyles = makeStyles({
	activePlayer: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		width: '100%',
		height: (props) => props.height,
		touchAction: 'none',
	},
});

const ActivePlayer = ({
	active,
	alertMessage,
	panelOpen,
	...props
}) => {
	const largerDimension = useScreenDimensions();
	const transitioned = useSelector(getTransitioned);
	const classes = useStyles({ height: largerDimension });

	return (
		<div className={classes.activePlayer} data-resize-on-dismiss>
			{active && (
				<>
					<View experience={props} active={transitioned && active} panelOpen={panelOpen} />
					<CustomAlert alertMessage={alertMessage} active={active} {...props} />
				</>
			)}
		</div>
	);
};

export default ActivePlayer;
