import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chip from 'rds/components/Chip';
import { makeStyles } from '@material-ui/core';
import { changeDestinationLayout } from 'app/slices/destinations-search/layout';
import { changeDestination, getDestination } from 'app/slices/experience-view/edit';
import {
	getVisibleExperience,
	getLoadingSingleExperience,
} from 'app/slices/experience-modal';
import { useTheme } from '@material-ui/styles';
import IconV3 from 'rds/components/IconV3/IconV3';
import Avatar from 'rds/components/Avatar';
import WaveSkeleton from 'components/WaveSkeleton';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		display: 'flex',
		justifyContent: 'center',
	},
}));

const DEFAULT_DESTINATION_IMAGE = 'https://cdn.dev.roamrhino.com/images/d/default-destination_128w.jpg';

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const changedDestination = useSelector(getDestination);
	const visibleExperience = useSelector(getVisibleExperience);
	const loading = useSelector(getLoadingSingleExperience);
	const destination = changedDestination ? changedDestination.destination : visibleExperience.destinations[0];
	const theme = useTheme();

	const actionicon = destination?.title.length ? (
		<IconV3
			icon="Cancel"
			size={20}
			containerSize={32}
			onClick={() => {
				dispatch(changeDestination({
					destination: null,
					experience: visibleExperience,
				}));
			}}
		/>
	) : (
		<IconV3
			icon="Plus"
			containerSize={32}
			onClick={() => {
				dispatch(changeDestinationLayout({ modal: true }));
			}}
			size={20}
		/>
	);

	return (
		<div
			className={classes.root}
			onClick={() => {
				if (!destination) {
					dispatch(changeDestinationLayout({ modal: true }));
				}
			}}
		>
			{
				loading
					? (
						<div
							className={classes.root}
							data-ignoreslider="true"
						>
							<WaveSkeleton style={{ width: 145, height: 32, borderRadius: 4 }} />
						</div>
					) : (
						<>
							<Avatar
								src={destination?.thumbnail?.small?.jpg || DEFAULT_DESTINATION_IMAGE}
								size="xxxSmall"
								alt="destinationAccount"
							/>
							<Chip
								data-ignoreslider="true"
								contents={destination ? destination?.title : 'Add a destination'}
								style={{
									background: theme.colors.neutral.C900,
									marginLeft: theme.spacing(2),
									border: 'none',
								}}
								labelStyle={{ paddingRight: theme.spacing() }}
								actionicon={actionicon}
								noBorder
							/>
						</>
					)
			}
		</div>
	);
};
