import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'left',
	},
	subtitle: {
		color: theme.colors.neutral.C300,
		marginTop: theme.spacing(),
	},

}));

export default ({ title, subtitle }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography
				variant="subtitle1"
			>
				{title}
			</Typography>
			<Typography
				className={classes.subtitle}
				variant="body2"
			>
				{subtitle}
			</Typography>
		</div>
	);
};
