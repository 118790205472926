import { createSlice, createSelector } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

export const pluginsSlice = createSlice({
	name: 'ccPlugins',
	initialState: {
		focused: false,
		showControls: true,
		trashRegion: null,
		showTrashCan: false,
		activeTrashCan: false,
		fullTrashCan: false,
		plugins: false,
		position: 0,
	},
	reducers: {
		change: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
	},
});

const { name, reducer, actions } = pluginsSlice;
const { change } = actions;

export { change };

export const getSlice = (state) => state[name];
export const selectInputFocused = createSelector(getSlice, (slice) => slice?.focused);
export const selectEditorControls = createSelector(getSlice, (slice) => slice?.showControls);
export const selectTrashRegion = createSelector(getSlice, (slice) => slice?.trashRegion);
export const selectShowTrashCan = createSelector(getSlice, (slice) => slice?.showTrashCan);
export const selectActiveTrashCan = createSelector(getSlice, (slice) => slice?.activeTrashCan);
export const selectFullTrashCan = createSelector(getSlice, (slice) => slice?.fullTrashCan);
export const selectPlugins = createSelector(getSlice, (slice) => slice?.plugins);
export const selectScrollPosition = createSelector(getSlice, (slice) => slice?.position);
injectReducer(name, reducer);
