import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import Background from './Background';
import Title from './Title';
import TopButton from './TopButton';

const useStyles = makeStyles({
	middle: {
		position: 'fixed',
		display: 'flex',
		left: '50%',
		transform: 'translate(-50%, 0)',
		zIndex: 11,
	},
});

export default ({
	back,
	close,
	closeIconPosition,
	fixedBackground = null,
	middle,
	next,
	title,
	bgcolor,
	variant = 'primary',
	zIndex = 11,
}) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const closeParam = params.get('close');

	if (close) {
		return (
			<Background fixed={!!fixedBackground} bgcolor={bgcolor}>
				{
					title
						? (
							<Title
								zIndex={zIndex}
								{...title}
							/>
						)
						: null
				}
				<TopButton
					left={closeIconPosition === 'left' ? 0 : null}
					zIndex={zIndex}
					onClick={() => {
						if (typeof close === 'function') {
							close();
							return;
						}

						const to = typeof close === 'string'
							? close
							: typeof close === 'object' && typeof close.to === 'string'
								? close.to
								: closeParam;

						navigate(to);

						if (close.onClick) {
							close.onClick();
						}
					}}
					icon={(
						<IconV3
							icon="ChevronLeft"
							size={32}
							style={{ position: 'fixed', left: 0, top: 0 }}
						/>
					)}
				/>
			</Background>
		);
	}

	return (
		<Background fixed={!!fixedBackground} bgcolor={bgcolor} {...fixedBackground}>
			{back ? <TopButton left={0} zIndex={zIndex} variant={variant} {...back} /> : null}
			{
				middle
					? (
						<div className={classes.middle}>
							{ middle.map((m, i) => <TopButton key={i} relative {...m} />) }
						</div>
					)
					: null
			}
			{title ? <Title zIndex={zIndex} {...title} /> : null}
			{next ? <TopButton right={0} zIndex={zIndex} variant={variant} {...next} /> : null}
		</Background>
	);
};
