import { reset as resetAlbums } from 'app/slices/content-creation/create/device-library/albums';
import { reset as resetMedia } from 'app/slices/content-creation/create/device-library/media';
import { reset as resetState, setCreateNavigation } from 'app/slices/content-creation/create/device-library/state';

export const resetAll = async (dispatch) => {
	await dispatch(resetAlbums());
	await dispatch(resetMedia());
	await dispatch(resetState());
};

export const exit = async (dispatch, navigationParams) => {
	if (navigationParams) {
		await dispatch(setCreateNavigation(navigationParams));
	}

	setTimeout(async () => {
		await resetAll(dispatch);
	}, 1);
};
