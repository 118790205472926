import React, { useState } from 'react';
import {
	Button, makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getLogs, requestSendLogs } from 'app/slices/app/dev-logs';
import isDeveloper from 'lib/developer';
import Typography from 'rds/components/Typography/Typography';
import SwitchInput from 'rds/components/SwitchInput/SwitchInput';
import uploadQueue from 'lib/upload-queue';
import useCDLValues from 'app/hooks/useCDLValues';
import { notifyErrors } from 'app/slices/notifications/notifications';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
	},
	item: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
		width: '100%',
	},
	logContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		width: '100%',
		gap: theme.spacing(2),
	},
	logItem: {
		whiteSpace: 'pre',
	},
	logItemTitle: {
		fontWeight: 500,
	},
	logItemPreview: {
		fontSize: 10,
	},
	label: {
		marginLeft: theme.spacing(2),
	},
	logPreview: {
		color: theme.colors.neutral.C900,
		background: theme.colors.neutral.C100,
		padding: theme.spacing(2),
		height: 200,
		width: '100%',
		overflowY: 'scroll',
	},
	dot: {
		position: 'fixed',
		display: 'block',
		background: theme.colors.primary.C400,
		borderRadius: '100%',
		top: 64,
		left: -12,
		height: 24,
		width: 24,
		zIndex: 200,
		cursor: 'pointer',
	},
	developerSettings: {
		display: 'block',
		position: 'fixed',
		top: 96,
		left: 0,
		width: 280,
		zIndex: 200,
		background: theme.colors.neutral.C900,
		padding: 20,
		transform: 'translateX(-100%)',
		transition: 'transform 0.3s ease-in-out',
		'&.active': {
			transform: 'translateX(0)',
		},
	},
}));

const DeveloperBanner = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const logs = useSelector(getLogs);
	const cdlValues = useCDLValues();
	const [uploadChaosMode, setUploadChaosMode] = useState(
		localStorage.getItem('developer:upload-chaos-mode') === 'true',
	);
	const [showSettings, setShowSettings] = useState(false);

	if (!isDeveloper()) {
		return null;
	}

	return (
		<>
			<div
				className={classes.dot}
				onClick={() => setShowSettings(!showSettings)}
			/>

			<div className={`${classes.developerSettings} ${showSettings ? 'active' : ''}`}>
				<Typography variant="subtitle1">Developer Settings</Typography>
				<div className={classes.content}>
					<div className={classes.item}>
						<SwitchInput
							label="Upload Chaos Mode"
							checked={uploadChaosMode}
							onChange={() => {
								const next = !uploadChaosMode;
								setUploadChaosMode(next);
								localStorage.setItem('developer:upload-chaos-mode', next ? 'true' : 'false');
								uploadQueue.updateChaosMode();
							}}
						/>
						<Typography className={classes.label} variant="body1">
							Upload Chaos Mode
						</Typography>
					</div>
					<div>

						<Typography>
							CDL:
							{!cdlValues.port ? 'off' : 'on'}
						</Typography>
						{cdlValues.port
									&& (
										<ul>
											<li>
												Port:
												{cdlValues.port}
											</li>
											<li>
												ShouldShowPermissons:
												{cdlValues.ShouldShowPermissons}
											</li>
											<li>
												ZeroState:
												{cdlValues.ZeroState}
											</li>
											<li>
												Android Port:
												{cdlValues.AndroidPort}
											</li>
										</ul>
									)}
					</div>
					<div className={classes.item}>
						<div className={classes.logContainer}>
							<Typography variant="subtitle1">
								Logs
							</Typography>
							<div className={classes.logPreview}>
								{
									logs.map(({ message, payload }, i) => (
										<div key={i} className={classes.logItem}>
											<div className={classes.logItemTitle}>{message}</div>
											<div className={classes.logItemPreview}>{JSON.stringify(payload)}</div>
										</div>
									))
								}
							</div>
							<Button
								variant="outlined"
								fullWidth
								onClick={() => {
									dispatch(requestSendLogs());
								}}
							>
								Send logs
							</Button>
						</div>
					</div>
					<div className={classes.item}>
						<Button
							variant="outlined"
							fullWidth
							onClick={() => {
								localStorage.removeItem('developer:upload-chaos-mode');
								localStorage.removeItem('developer');
								window.location.reload();
							}}
						>
							Turn developer mode off
						</Button>
					</div>
					<div className={classes.item}>
						<Button
							variant="outlined"
							fullWidth
							onClick={() => {
								notifyErrors([{ text: 'this is only for testing' }]);
							}}
						>
							show an high pri snackbar
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default DeveloperBanner;
