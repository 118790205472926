import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getVisibleExperience,
	getUid,
	getSelector,
	getShow,
	getErrors,
	setOpenDirective,
} from 'app/slices/experience-modal';
import ECRExiting from './ECRExiting';
// import emEmitter from './emitter';
import Panes from './Panes';

export default () => {
	const dispatch = useDispatch();
	const uid = useSelector(getUid);
	const show = useSelector(getShow);
	const selector = getSelector ? useSelector(getSelector) : null;
	const visibleExperience = useSelector(getVisibleExperience);
	const errors = useSelector(getErrors);

	useEffect(() => {
		if (!uid) {
			dispatch(setOpenDirective(null));
		}
	}, [uid, dispatch]);

	useEffect(() => {
		if (!show && errors) {
			// emEmitter.emit('dismiss');
		}
	}, [show, errors]);

	const showTransition = !(!show || !selector || !uid);
	const showPanes = !!visibleExperience;

	return (
		<ECRExiting showTransition={showTransition} showPanes={showPanes}>
			<Panes
				selector={selector}
				uid={uid}
			/>
		</ECRExiting>
	);
};
