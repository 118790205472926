import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	getExperiences,
	requestLoadMoreExperiences,
	getLoadingExperiences,
	getNextLink,
	requestUserExperiences,
} from 'app/slices/user-account-v2/experiences';
import {
	getMyExperiences, getLoadingMyExperiences, requestMyExperiences, getNextLink as getMyNextLink, requestLoadMoreMyExperiences,
} from 'app/slices/user-account-v2/my-experiences';
import ThumbnailGrid from 'rds/components/ThumbnailGrid';
import useExperiencesThumbnail from 'app/hooks/useExperiencesThumbnail';
import { getMyProfileUuid, getTheirProfileUuid } from 'app/slices/user-account-v2/accounts';

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(12),
	},
	spacer: {
		paddingTop: theme.spacing(4),
	},
}));

export default ({ isMe }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const profileUuid = useSelector(isMe ? getMyProfileUuid : getTheirProfileUuid);
	const data = useSelector(isMe ? getMyExperiences : getExperiences);
	const experiences = useExperiencesThumbnail(data);
	const loading = useSelector(isMe ? getLoadingMyExperiences : getLoadingExperiences);
	const nextLink = isMe ? useSelector(getMyNextLink) : useSelector(getNextLink);
	const requestLoadMore = isMe ? requestLoadMoreMyExperiences : requestLoadMoreExperiences;

	useEffect(() => {
		if (experiences === null) {
			if (isMe) {
				dispatch(requestMyExperiences());
			} else if (profileUuid) {
				dispatch(requestUserExperiences({ accountUuid: profileUuid }));
			}
		}
	}, [experiences, isMe, profileUuid]);

	if (!experiences?.length) {
		return null;
	}

	return (
		<div className={classes.root}>
			<ThumbnailGrid
				items={experiences}
				onLongPress={() => { }}
				loading={loading}
				requestLoadMore={nextLink ? requestLoadMore : null}
			/>
		</div>
	);
};
