import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	endStream,
	getFacingMode,
	getStream,
	requestUserMedia,
	setFacingMode,
	setFlash,
	getFlash,
} from 'app/slices/user-media';
import neutral from 'rds/colors/neutral';
import getFlashIcon from 'utils/get-flash';
import waitFor from 'utils/wait-for';
import toggleTorch from 'utils/toggle-torch';
import { notifyErrors } from 'app/slices/notifications/notifications';
import { useNavigate } from 'react-router-dom';
import SelectFromDevice from './SelectFromDevice';
import TopControls from './TopControls';
import ImageSelector from './ImageSelector';
import { clear, setImage } from './store';

let tmpFlashObject = null;
const PHOTO_THRESHOLD = 300;

const useStyles = makeStyles((theme) => ({
	videoContainer: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		right: 0,
		left: 0,
		bottom: 0,
		top: 0,
	},
	video: {
		position: 'absolute',
		zIndex: 3,
		height: '100%',
		width: '100%',
		objectFit: 'cover',
	},
	viewport: {
		position: 'absolute',
		zIndex: 4,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		margin: '0 auto',
		overflow: 'hidden',
	},
	shadow: {
		content: '""',
		position: 'absolute',
		top: '20vw',
		width: '100vw',
		left: 0,
		height: '100vw',
		borderRadius: '50%',
		boxShadow: `0px 0px 0px 2000px ${theme.colors.neutral.C900}20`,
		zIndex: 5,
		touchAction: 'none',
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const FlashMode = useSelector(getFlash);
	const videoRef = useRef();
	const stream = useSelector(getStream);
	const flash = getFlashIcon(FlashMode);
	const facingMode = useSelector(getFacingMode);
	const containerRef = useRef();
	const viewportRef = useRef();
	const [mirror, setMirror] = useState(false);

	useEffect(() => {
		dispatch(requestUserMedia({
			audio: false,
			facingMode,
		}));
		if (facingMode === 'user') {
			setMirror(true);
		} else {
			setMirror(false);
		}

		return () => {
			dispatch(endStream());
		};
	}, [facingMode]);

	useEffect(() => {
		if (videoRef?.current) {
			if (!stream) {
				videoRef.current.srcObject = null;
			} else if (videoRef?.current) {
				videoRef.current.srcObject = stream;

				const p = videoRef.current.play();
				if (p.catch) {
					/* eslint-disable-next-line */
					p.catch((_) => {
						// this means that the play call was interrupted
						// ignore it
					});
				}
			}
		}
		return () => {
			if (videoRef?.current && videoRef?.current.srcObject) {
				videoRef.current.srcObject = null;
			}
		};
	}, [videoRef?.current, stream]);

	const captureAnimation = (color, opacity, recording) => {
		tmpFlashObject = document.createElement('div');
		tmpFlashObject.setAttribute('id', 'bright-screen');
		tmpFlashObject.style.background = color;
		tmpFlashObject.style.width = '100%';
		tmpFlashObject.style.height = '100%';
		tmpFlashObject.style.opacity = opacity;
		tmpFlashObject.style.position = 'absolute';

		if (flash === 'flash') {
			tmpFlashObject.style.top = 0;
			tmpFlashObject.style.zIndex = 3;
		}
		if (containerRef && containerRef.current) {
			containerRef.current.append(tmpFlashObject);
		}

		if (!recording) {
			setTimeout(() => {
				tmpFlashObject?.remove();
			}, PHOTO_THRESHOLD);
		}
	};

	const applyMirror = (c, end) => {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		canvas.height = c.height;
		canvas.width = c.width;
		context.scale(-1, 1);
		context.drawImage(c, -canvas.width, 0, canvas.width, canvas.height);
		c.remove();
		end(canvas);
	};

	const takePhoto = async () => {
		if (!videoRef?.current || !viewportRef?.current) {
			return;
		}

		if (FlashMode === 'flash') {
			if (facingMode === 'user') {
				captureAnimation('#fff', 1);
				await waitFor(200);
			} else {
				await toggleTorch(true, stream);
				captureAnimation(neutral.C900, 1);
			}
		} else {
			captureAnimation(neutral.C900, 1);
		}

		// get the original video
		const video = videoRef.current;
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		const { videoHeight, videoWidth } = video;
		const videoAr = videoHeight / videoWidth;

		// get the viewport dimensions
		const {
			width: viewPortWidth,
			height: viewPortHeight,
		} = viewportRef.current.getBoundingClientRect();
		const viewPortAr = viewPortHeight / viewPortWidth;

		// create a rectangle with the viewport dimensions that is upscaled to the resolution of the video
		let drawWidth;
		let drawHeight;
		let offsetHeight;
		let offsetWidth;
		let offsetX = 0;
		let offsetY = 0;

		if (viewPortAr > videoAr) {
			drawWidth = videoWidth;
			drawHeight = videoWidth * (viewPortHeight / viewPortWidth);

			offsetWidth = videoHeight * (viewPortWidth / viewPortHeight);
			offsetX = (videoWidth - offsetWidth) / 2;
		} else {
			drawHeight = videoHeight;
			drawWidth = videoHeight * (viewPortWidth / viewPortHeight);

			offsetHeight = videoWidth * (viewPortHeight / viewPortWidth);
			offsetY = (videoHeight - offsetHeight) / 2;
		}

		canvas.height = drawHeight;
		canvas.width = drawWidth;

		context.drawImage(
			video,
			offsetX,
			offsetY,
			videoWidth - (offsetX * 2),
			videoHeight - (offsetY * 2),
			0,
			0,
			canvas.width,
			canvas.height,
		);

		const end = (c) => {
			const src = c.toDataURL('image/png');
			const img = new Image();

			img.onload = () => {
				setImage(img);
				navigate('/my-account/avatar/edit');
				c.remove();
			};

			img.onerror = () => {
				clear();
				notifyErrors([{
					text: 'Failed to capture a photo',
				}]);
			};

			img.src = src;
		};

		if (mirror) {
			applyMirror(canvas, end);
		} else {
			end(canvas);
		}
	};

	return (
		<div ref={containerRef}>
			<TopControls
				buttons={[{
					text: 'Cancel',
					ds: true,
					disabled: 'false',
					onClick: () => {
						navigate(-1);
						dispatch(endStream());
					},
					style: { zIndex: 10 },
				}, {
					text: 'Save',
					ds: true,
					style: { zIndex: 10 },
				}, {
					icon: 'CameraRotate',
					size: 32,
					style: { zIndex: 10 },
					onClick: () => {
						dispatch(setFacingMode(facingMode === 'environment' ? 'user' : 'environment'));
					},
				}, {
					icon: flash.flashIcon,
					size: 32,
					style: { zIndex: 10 },
					filter: 'drop-shadow(0px 2px 4px rgba(50, 56, 62, 0.25))',
					onClick: () => { dispatch(setFlash(flash.next)); },
				}]}
			/>

			<div className={classes.videoContainer}>
				<video
					autoPlay
					className={classes.video}
					ref={videoRef}
					style={{ transform: mirror ? 'rotateY(180deg)' : null }}
					muted
				/>
			</div>

			<div ref={viewportRef} className={classes.viewport} />
			<div className={classes.shadow} />

			<ImageSelector onSelect={() => takePhoto()} />
			<SelectFromDevice />
		</div>
	);
};
