import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import {
	getMyBio,
	getMyName,
	getTheirBio,
	getTheirName,
} from 'app/slices/user-account-v2/accounts';
import { toggleEditProfileModal } from 'app/slices/user-account-v2/layout/slice';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
		marginTop: theme.spacing(3),
	},
	bio: {
		whiteSpace: 'pre-wrap',
		wordBreak: 'break-word',
		color: theme.colors.neutral.C100,
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	placeholder: {
		color: theme.colors.neutral.C300,
	},
}));

export default ({ isMe }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const rawBio = useSelector(isMe ? getMyBio : getTheirBio);
	const rawName = useSelector(isMe ? getMyName : getTheirName);
	const bio = rawBio || (isMe ? 'Add bio' : '');
	const name = rawName || (isMe ? 'Add Display Name' : '');

	const isBioPlaceholder = isMe && !rawBio;
	const isNamePlaceholder = isMe && !rawName;

	const handleClick = (placeholder) => {
		if (placeholder) {
			dispatch(toggleEditProfileModal(true));
		}
	};

	return (
		<div className={classes.root}>
			<Typography variant="subtitle1" className={isNamePlaceholder ? classes.placeholder : ''} onClick={() => { handleClick(isNamePlaceholder); }}>
				{name}
			</Typography>
			<Typography className={`${classes.bio} ${isBioPlaceholder ? classes.placeholder : ''}`} gutterBottom variant="body1" onClick={() => { handleClick(isBioPlaceholder); }}>
				{bio}
			</Typography>
		</div>
	);
};
