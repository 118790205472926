const SPACING = 4;

export default (colors) => ({
	colors,
	spacing: 4,

	typography: {
		h1: {
			fontSize: 36,
			fontWeight: 600,
			lineHeight: 1.3,
		},
		h2: {
			fontSize: 32,
			fontWeight: 600,
			lineHeight: 1.3,
		},
		h3: {
			fontSize: 28,
			fontWeight: 600,
			lineHeight: 1.3,
		},
		h4: {
			fontSize: 26,
			fontWeight: 500,
			lineHeight: 1.3,
		},
		h5: {
			fontSize: 22,
			fontWeight: 500,
			lineHeight: 1.3,
		},
		h6: {
			fontSize: 18,
			fontWeight: 400,
			lineHeight: 1.3,
		},
		title1: {
			fontSize: 16,
			fontWeight: 600,
			lineHeight: 1.4,
		},
		title2: {
			fontSize: 14,
			fontWeight: 600,
			lineHeight: 1.4,
		},
		title3: {
			fontSize: 12,
			fontWeight: 600,
			lineHeight: 1.4,
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: 500,
			lineHeight: 1.4,
		},
		subtitle2: {
			fontSize: 14,
			fontWeight: 500,
			lineHeight: 1.4,
		},
		subtitle3: {
			fontSize: 10,
			fontWeight: 500,
			lineHeight: 1.4,
		},
		body1: {
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.4,
		},
		body2: {
			fontSize: 12,
			fontWeight: 400,
			letterSpacing: -0.08,
		},
		link: {
			color: colors.information.C500,
			fontSize: 14,
			textDecoration: 'underline',
			fontWeight: 500,
		},
		caption: {
			fontWeight: 400,
			fontSize: 10,
			lineHeight: 1.4,
		},
		button: {
			fontSize: 16,
			fontWeight: 500,
			textTransform: 'none',
		},
		buttonXsmall: {
			fontSize: 10,
			fontWeight: 500,
			lineHeight: '100%',
		},
		fontFamily: 'inter',
		fontStyle: 'normal',
		letterSpacing: '-0.08px',
		fontWeight: 400,
	},

	palette: {
		background: {
			lightContrast: colors.neutral.C900,
			darkContrast: colors.neutral.C000,
		},

		primary: {
			main: colors.neutral.C900,
		},

		neutral: {
			light: colors.neutral.C200,
			main: colors.neutral.C500,
			dark: colors.neutral.C700,
		},

		glass: {
			opacity: 0.95,
		},
	},

	overrides: {
		MuiButton: {
			root: {
				fontSize: 14,
				fontWeight: 500,
				textTransform: 'none',
				borderRadius: 2,
				lineHeight: 1,
				paddingBottom: SPACING * 3,
				paddingTop: SPACING * 3,
				paddingLeft: SPACING * 4,
				paddingRight: SPACING * 4,
			},
			containedPrimary: {
				backgroundColor: colors.neutral.C000,
				'&$disabled': {
					backgroundColor: colors.neutral.C200,
					color: colors.neutral.C600,
				},
				'&:hover': {
					backgroundColor: colors.neutral.C000,
					color: colors.neutral.C900,
				},
			},
			containedSecondary: {
				backgroundColor: colors.neutral.C800,
				color: colors.neutral.C200,
				'&$disabled': {
					opacity: 0.7,
					backgroundColor: colors.neutral.C900,
					color: colors.neutral.C400,
				},
				'&:hover': {
					backgroundColor: colors.neutral.C900,
					color: colors.neutral.C400,
				},
			},
			// TODO @Alireza, what is this?
			// containedError: {
			// 	backgroundColor: colors.error.C700,
			// 	color: colors.neutral.C000,
			// 	'&$disabled': {
			// 		color: colors.neutral.C500,
			// 		backgroundColor: colors.neutral.C900,
			// 	},
			// },
			outlinedPrimary: {
				borderColor: colors.neutral.C000,
				color: colors.neutral.C000,
				'&$disabled': {
					opacity: 0.7,
					borderColor: colors.neutral.C600,
					color: colors.neutral.C600,
				},
			},
			outlinedSecondary: {
				borderColor: colors.neutral.C800,
				color: colors.neutral.C800,
				'&$disabled': {
					opacity: 0.7,
					borderColor: colors.neutral.C900,
					color: colors.neutral.C900,
				},
			},
			// outlinedError: {
			// 	borderColor: colors.error.C700,
			// 	color: colors.error.C700,
			// 	'&$disabled': {
			// 		opacity: 0.7,
			// 		borderColor: colors.error.C900,
			// 		color: colors.error.C900,
			// 	},
			// },
			text: {
				color: colors.neutral.C50,
				'&:hover': {
					color: colors.neutral.C200,
					background: 'none',
				},
			},
			textPrimary: {
				color: colors.primary.C800,
				'&:hover': {
					color: colors.primary.C600,
					background: 'none',
				},
				'&$disabled': {
					opacity: 0.7,
					color: colors.primary.C600,
				},
			},
			textSecondary: {
				color: colors.neutral.C800,
				'&:hover': {
					background: 'none',
				},
				'&$disabled': {
					opacity: 0.7,
					color: colors.neutral.C900,
				},
			},
			// textError: {
			// 	color: colors.error.C700,
			// 	'&$disabled': {
			// 		opacity: 0.7,
			// 		color: colors.error.C900,
			// 	},
			// },
			sizeLarge: {
				fontSize: 16,
				fontWeight: 500,
			},
			sizeSmall: {
				fontSize: 12,
				fontWeight: 500,
				height: 36,
			},
		},
		MuiIconButton: {
			root: {
				color: colors.primary.C800,
			},
		},
		MuiInputBase: {
			input: {
				height: 'inherit',
				fontSize: 14,
				fontWeight: 500,
				paddingLeft: SPACING,
				paddingTop: 8,
				paddingBottom: 8,
			},
		},
		MuiDialog: {
			paperWidthSm: {
				maxWidth: '100% !important',
			},
		},
		MuiDialogContent: {
			root: {
				padding: '0px !important',
				'&:first-child': {
					padding: '0px !important',
				},
			},
		},
		MuiLinearProgress: {
			colorPrimary: {
				backgroundColor: colors.neutral.C900,
			},
		},
		MuiPaper: {
			root: {
				backgroundColor: colors.neutral.C000,
				display: 'flex !important',
				flexDirection: 'column !important',
				justifyContent: 'center !important',
				alignItems: 'center !important',
			},
		},
		MuiTabs: {
			centered: {
				height: 36,
				alignItems: 'center',
			},
			indicator: {
				background: colors.neutral.C000,
				bottom: SPACING * 3,
			},
		},
		MuiSnackbar: {
			root: {
				left: SPACING * 3,
				right: SPACING * 3,
				bottom: `${SPACING * 4}px !important`,
				background: colors.information.C700,
			},
			anchorOriginBottomCenter: {
				bottom: SPACING * 14,
				borderRadius: 2,
			},
		},
		MuiAlert: {
			root: {
				width: '100%',
				paddingTop: 0,
				paddingBottom: 0,
			},
			standardError: {
				width: '100%',
				color: colors.neutral.C700,
				backgroundColor: colors.error.C000,
			},
			standardInfo: {
				width: '100%',
				backgroundColor: colors.neutral.C900,
				color: colors.neutral.C200,
			},
			icon: {
				color: `${colors.neutral.C000} !important`,
				fontSize: 20,
				marginRight: SPACING * 4,
				position: 'absolute',
				top: SPACING,
			},
			message: {
				paddingTop: SPACING * 3,
				paddingBottom: SPACING * 3,
			},
		},
		MuiRadio: {
			root: {
				color: colors.neutral.C000,
			},
			colorSecondary: {
				'&$checked': {
					color: colors.neutral.C000,
				},
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: colors.primary.C800,
				height: 'none',
				width: '100%',
				justifyContent: 'space-evenly',
			},

		},
		MuiPickersDatePickerRoot: {
			toolbar: {
				paddingTop: SPACING * 5,
				paddingBottom: SPACING * 5,
			},
		},
		MuiPickersYear: {
			root: {
				color: colors.neutral.C900,
			},
			yearSelected: {
				color: colors.primary.C800,
			},
			yearDisabled: {
				color: colors.neutral.C900,
			},
		},
		MuiPickersMonth: {
			root: {
				color: colors.neutral.C900,
			},
		},
		MuiPickersDay: {
			day: {
				color: colors.neutral.C900,
			},
		},
		MuiPickersToolbarText: {
			toolbarTxt: {
				color: colors.neutral.C000,
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: 40,
				letterSpacing: '-0.003em',
			},
			toolbarBtnSelected: {
				color: colors.primary.C000,
				fontSize: 20,
			},

		},
		MuiPickersCalendarHeader: {
			iconButton: {
				backgroundColor: 'none',
			},
			dayLabel: {
				color: colors.neutral.C300,
			},
		},
		MuiPickersSlideTransition: {
			transitionContainer: {
				color: colors.neutral.C900,
			},
		},
		MuiCircularProgress: {
			colorPrimary: {
				color: colors.primary.C600,
				opacity: 0.5,
			},
		},

	},
	MuiInputBase: {
		focused: {
			outline: 'none',
		},
	},

	rhino: {
		textField: {
			main: {
				root: {
					position: 'relative',
				},
				subtleRoot: {
					boxShadow: 'none',
				},
				subtleInputRoot: {
					paddingLeft: 0,
					paddingRight: 0,
					background: 'none',
					border: 'none',
					borderWidth: 0,
				},
				error: {
					position: 'absolute',
					top: SPACING * 9,
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: colors.error.C700,
					color: colors.neutral.C000,
					paddingRight: SPACING * 2,
					paddingLeft: SPACING * 2,
					paddingTop: SPACING,
					paddingBottom: SPACING,
					left: 0,
					borderRadius: 2,
					fontSize: 10,
				},
				icon: {
					position: 'absolute',
					left: SPACING * 2,
					top: 7,
					zIndex: 1,
				},
				inputRoot: {
					borderRadius: 2,
					paddingTop: 2,
					paddingBottom: 2,
					paddingLeft: SPACING * 8,
					border: `1px solid ${colors.neutral.C400}`,
				},
				errorInputRoot: {
					padding: '2px 8px 2px 8px',
					marginTop: 6,
					borderRadius: 2,
					backgroundColor: colors.error.C700,
					backdropFilter: 'blur(4px)',
				},
				input: {
					'&::placeholder': {
						opacity: 1,
						borderBottom: 'none',
					},
					'&:focus': {
						'&::placeholder': {
							color: colors.neutral.C600,
							textOverflow: 'ellipsis',
						},
					},
				},

				underline: {
					'&&&:before': {
						borderBottom: 'none',
					},
					'&&:after': {
						borderBottom: 'none',
					},
				},

				right: {
					position: 'absolute',
					right: SPACING,
					top: SPACING * 2,
					zIndex: 1,
					display: 'flex',
				},
			},

			variants: {
				default: {
					inputRoot: {
						background: colors.neutral.C000,
						color: colors.neutral.C900,
					},
					errorInputRoot: {
						background: colors.neutral.C000,
					},
					subtleInputRoot: {
						color: colors.neutral.C000,
					},
				},
				secondary: {

				},
				glass: {
					inputRoot: {
						background: colors.neutral.C900,
					},
					errorInputRoot: {
						background: colors.error.C700,
					},
				},
			},
		},
	},
});
