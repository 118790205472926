import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	circle: {
		stroke: theme.colors.secondary.C500,
	},
	primary: {
		stroke: theme.colors.primary.C500,
	},
}));

export default ({
	size,
	thickness,
	variant,
	...props
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root} {...props}>
			<CircularProgress
				classes={{
					circle: variant !== 'primary' ? classes.secondary : classes.primary,
				}}
				size={size}
				thickness={thickness}
				{...props}
			/>
		</div>
	);
};
