import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import Avatar from 'rds/components/Avatar';
import IconV3 from 'rds/components/IconV3';
import {
	set,
	getActiveComment,
	requestDeleteComment,
	requestLikeComment,
	requestUnLikeComment,
	getCommentsUsers,
} from 'app/slices/comments';
import { getVisibleExperience } from 'app/slices/experience-modal';
import useUserAccountLink from 'app/hooks/useUserAccountLink';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import useNavigateReportModal from 'app/hooks/useNavigateReportModal';
import CommentSlider from '../../../../lib/comment-menu-slider';
import CommentTokenView from './CommentTokenView';
import Menu from './Menu';

const useStyles = makeStyles((theme) => ({
	replyContainer: {
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
		paddingLeft: theme.spacing(11),
		width: '100%',
		maxHeight: 0,
		overflow: 'hidden',
	},
	avatarContainer: {
		marginRight: theme.spacing(2),
	},
	replyContent: {
		...theme.typography.body1,
		whiteSpace: 'pre-wrap',
		textAlign: 'left',
		display: 'box',
		boxOrient: 'vertical',
		overflow: 'hidden',
		wordBreak: 'break-word',
	},
	left: {
		display: 'flex',
	},
	right: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingRight: theme.spacing(3),
	},
	user: {
		color: theme.colors.neutral.C100,
		marginRight: theme.spacing(),
	},
	time: {
		color: theme.colors.neutral.C400,
		marginRight: theme.spacing(4),
		minWidth: theme.spacing(5),
	},
	highlight: {
		position: 'absolute',
		zIndex: 1,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		transition: 'background 0.25s ease',
		background: theme.palette.type === 'light'
			? theme.colors.neutral.C100
			: theme.colors.neutral.C800,
		opacity: 0.6,
	},
	diminish: {
		background: 'none',
	},
	menu: {
		position: 'absolute',
		top: 0,
		display: 'flex',
		zIndex: 3,
		visibility: 'hidden',
	},
	ellipsis: {
		lineClamp: 4,
	},
	metadataContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		paddingBottom: theme.spacing(),
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between',
		position: 'relative',
		zIndex: 2,
	},
	reply: {
		color: theme.colors.neutral.C200,
	},
}));

const FADE_DELAY = 1000;

export default ({
	expand,
	reply,
	parentId,
	highlight,
	scrollTo,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [expandReply, setExpandReply] = useState(false);
	const [diminish, setDiminish] = useState(false);
	const active = useSelector(getActiveComment);
	const divRef = useRef();
	const menuRef = useRef();
	const contentRef = useRef();
	const users = useSelector(getCommentsUsers);
	const author = users[reply.userId];
	const redirectTo = useUserAccountLink(author?.username);
	const visibleExperience = useSelector(getVisibleExperience);
	const timeStamp = reply?.meta?.createdAt;
	const navigateReport = useNavigateReportModal();

	let highlightClass;

	if (highlight) {
		highlightClass = classes.highlight;

		if (diminish) {
			highlightClass = classes.diminish;
		}
	}

	useEffect(() => {
		if (highlight) {
			setTimeout(() => {
				setDiminish(true);
			}, FADE_DELAY);
		}
	}, [highlight]);

	useEffect(() => {
		if (scrollTo && divRef.current) {
			divRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [divRef, scrollTo]);

	useEffect(() => {
		if (menuRef?.current && divRef.current && contentRef.current) {
			contentRef.current.sliderMenu = new CommentSlider(divRef.current, contentRef.current, menuRef.current, 'reply');
			contentRef.current.sliderMenu.on('active', () => {
				dispatch(set({ activeComment: reply.id }));
			});
		}

		if (active !== reply.id) {
			contentRef?.current?.sliderMenu.hide(false);
		}

		return () => {
			if (contentRef?.current?.sliderMenu) {
				contentRef.current.sliderMenu.cleanup();
			}
		};
	}, [active, divRef, menuRef, contentRef]);

	useEffect(() => {
		if (expand && divRef.current) {
			divRef.current.style.transition = 'all 400ms';
			divRef.current.style.maxHeight = 0;

			window.requestAnimationFrame(() => {
				if (!divRef.current) {
					return;
				}
				divRef.current.style.maxHeight = '150px';

				setTimeout(() => {
					if (!divRef.current) {
						return;
					}
					divRef.current.style.maxHeight = 'fit-content';
				}, 500);
			});
		} else if (!expand && divRef.current) {
			divRef.current.style.maxHeight = 'fit-content';
		}
	}, [expand, divRef]);

	useEffect(() => {
		if (reply.deleted && divRef.current) {
			divRef.current.style.transition = 'all 400ms';
			divRef.current.style.height = `${divRef.current.getBoundingClientRect().height}px`;
			window.requestAnimationFrame(() => {
				divRef.current.style.height = 0;
			});
		}
	}, [divRef, reply.deleted]);

	const handleReport = () => {
		navigateReport.enter({
			type: 'comment',
			label: 'Report Comment',
			reportedComment: reply,
			title: author?.username,
			id: reply?.id,
		});
	};

	const handleDelete = () => {
		dispatch(requestDeleteComment({
			comment: reply,
			parentId,
			type: 'reply',
			experience: visibleExperience,
		}));
	};

	const handleClose = () => {
		dispatch(set({ activeComment: null }));
	};

	return (
		<div
			className={classes.replyContainer}
			ref={divRef}
		>
			<div className={classes.content} ref={contentRef}>
				<div className={classes.left}>
					<div
						className={classes.avatarContainer}
						onClick={() => {
							dispatch(set({ open: false }));
							navigate(redirectTo);
						}}
					>
						<Avatar
							size="xxxxSmall"
							alt="header"
							src={author?.avatar?.small?.png}
						/>
					</div>
					<div>
						<div className={expandReply ? clsx(classes.replyContent) : clsx(classes.replyContent, classes.ellipsis)} onClick={() => setExpandReply(!expandReply)}>
							<CommentTokenView comment={reply} users={users} author={author?.username} />
						</div>
						<div className={classes.metadataContainer}>
							<Typography className={classes.time} variant="buttonXsmall">
								{timeStamp}
							</Typography>
							<Typography
								className={classes.reply}
								variant="buttonXsmall"
								onClick={() => {
									const commentToReplyObj = { ...reply };
									commentToReplyObj.author = author;
									dispatch(set({ commentToReply: commentToReplyObj }));
								}}
							>
								Reply
							</Typography>
						</div>
					</div>
				</div>
				<div className={classes.right}>
					<IconV3
						icon={reply?.interactions?.isLiked ? 'HeartFilled' : 'HeartUnfilled'}
						color="neutralC200"
						size={16}
						containerSize={20}
						onClick={() => {
							if (!reply?.interactions?.isLiked) {
								dispatch(requestLikeComment({ uid: reply.id, type: 'reply', commentId: reply.parentId }));
							} else {
								dispatch(requestUnLikeComment({ uid: reply.id, type: 'reply', commentId: reply.parentId }));
							}
						}}
					/>
					<Typography className={classes.number} variant="buttonXsmall">{reply?.interactions?.numLikes !== 0 ? reply?.interactions?.numLikes : null}</Typography>
				</div>
			</div>
			<div className={classes.menu} ref={menuRef}>
				<Menu
					author={author}
					handleReport={handleReport}
					handleDelete={handleDelete}
					onClose={handleClose}
				/>
			</div>
			<div className={highlightClass} />
		</div>
	);
};
