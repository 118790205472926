import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import emitter from './emitter';

export default ({
	to,
	children,
	active,
	mention,
	...props
}) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleNavigation = () => {
		if (mention) {
			navigate(to);
			return;
		}

		if (pathname.toLowerCase() === to.toLowerCase() || pathname === '/my-account') {
			emitter.emit('dismiss');
		} else {
			navigate(to);
		}
	};

	return (
		<div onClick={handleNavigation} {...props}>
			{children}
		</div>
	);
};
