import { createSelector, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { injectReducer } from 'services/store';

export const setupControl = ({
	initialState,
	getFormField,
	setFormField,
	getFormFieldError,
	getFormFieldTmpError,
}) => (dispatch) => {
	const ret = {};

	Object.keys(initialState).forEach((k) => {
		if (k === 'error') {
			return;
		}

		ret[k] = {
			err: getFormFieldError(k),
			tmpErr: getFormFieldTmpError(k),
			selector: getFormField(k),
			values: [
				useSelector(getFormField(k)),
				useSelector(getFormFieldError(k)),
				useSelector(getFormFieldTmpError(k)),
			],
			change: (e) => {
				dispatch(setFormField({
					field: k,
					value: e.target.value,
				}));
			},
		};
	});

	return ret;
};

const updateErrorsFromState = (validators, errors, state, field) => {
	const value = state[field];
	const err = validators[field](value);
	const errIndex = state.errors.findIndex((e) => e.field === field);

	if (err) {
		if (errIndex === -1) {
			errors.push([err]);
		} else {
			errors[errIndex] = err;
		}
	}
};

export const setupFormReducers = (initialState, validators) => ({
	resetErrors: (state) => {
		state.errors = [];
	},
	resetForm: () => ({ ...initialState }),
	setErrors: (state, action) => {
		state.errors = action.payload;
	},
	validateForm: (state) => {
		const errors = [];

		Object.keys(validators).forEach((field) => {
			updateErrorsFromState(validators, errors, state, field);
		});

		state.errors = errors;
	},
	setFormField: (state, action) => {
		const { field, value } = action.payload;
		state[field] = value;

		const ei = state.errors.findIndex((e) => e.field === field);

		if (ei !== -1) {
			const errors = state.errors.slice();
			errors.splice(ei, 1);
			state.errors = errors;
		}

		const ti = state.tmpErrors.findIndex((e) => e.field === field);
		if (typeof validators[field] !== 'undefined') {
			const err = validators[field](value);

			if (err) {
				if (ti === -1) {
					state.tmpErrors = state.tmpErrors.concat([err]);
				} else {
					state.tmpErrors[ti] = err;
				}
			} else if (ti !== -1) {
				const next = state.tmpErrors.slice();
				next.splice(ti, 1);
				state.tmpErrors = next;
			}
		}
	},
});

export const createFormSlice = ({ name, initialState, validators = {} }) => {
	const _initialState = { ...initialState, errors: [], tmpErrors: [] };

	const formSlice = createSlice({
		name,
		initialState: _initialState,
		reducers: setupFormReducers(_initialState, validators),
	});

	const { setFormField } = formSlice.actions;

	// set up selectors
	const getSlice = (state) => state[name];
	const getFormField = (field) => createSelector(getSlice, (slice) => slice[field]);
	const getFormErrors = createSelector(getSlice, (slice) => slice.errors.filter((e) => !e.field));
	const getFormFieldError = (field) => createSelector(getSlice, (slice) => slice.errors.find((error) => error.field === field));
	const getFormFieldTmpError = (field) => createSelector(getSlice, (slice) => slice.tmpErrors.find((error) => error.field === field));
	const getFormPayload = createSelector(getSlice, (slice) => {
		const {
			errors,
			tmpErrors,
			passwordConfirm,
			...payload
		} = slice;

		return payload;
	});

	formSlice.selectors = {
		getFormField,
		getFormErrors,
		getFormFieldError,
		getFormFieldTmpError,
		getFormPayload,
	};

	// set up form control
	formSlice.control = {
		get: setupControl({
			initialState,
			getFormField,
			setFormField,
			getFormFieldError,
			getFormFieldTmpError,
		}),
	};

	// inject reducer
	injectReducer(name, formSlice.reducer);

	return {
		control: formSlice.control,
		reducer: formSlice.reducer,
		...formSlice.actions,
		...formSlice.selectors,
	};
};

export const createSimpleRequestSlice = ({
	name,
	initialState = {
		response: null,
		loading: null,
		errors: null,
	},
	request,
}) => {
	const requestSlice = createSlice({
		name,
		initialState,
		reducers: {
			reset: () => ({ ...initialState }),
		},
		extraReducers: {
			[request.pending]: (state) => {
				state.loading = true;
				state.errors = null;
			},
			[request.fulfilled]: (state, action) => {
				state.loading = false;
				state.errors = null;
				state.response = action.payload;
			},
			[request.rejected]: (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			},
		},
	});

	const { reducer, actions } = requestSlice;
	const { reset } = actions;

	const getSlice = (state) => state[name];
	const getLoading = createSelector(getSlice, (slice) => slice?.loading);
	const getErrors = createSelector(getSlice, (slice) => slice?.errors);
	const getResponse = createSelector(getSlice, (slice) => slice?.response);

	injectReducer(name, reducer);

	return {
		reset,
		getSlice,
		getLoading,
		getErrors,
		getResponse,
	};
};

export const requestFormRequest = async () => true;

export default setupControl;
