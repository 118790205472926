import { registerRoute } from 'Router/registry';
import notFoundBackground from 'routes/NotFound/background.webp';
import NotFound from './NotFound';

registerRoute({
	Component: NotFound,
	Background: notFoundBackground,
	routeOptions: {
		path: '*',
	},
	transition: 'dissolve',
});
