import ScaleAnimation from './scale-animation';

const THRESHOLD = 1;

const inBounds = ({ clientX, clientY }, rect) => {
	const isWithinXBounds = Math.abs(clientX - rect.x) <= THRESHOLD || Math.abs(clientX - (rect.x + rect.width)) <= THRESHOLD;
	const isWithinYBounds = Math.abs(clientY - rect.y) <= THRESHOLD || Math.abs(clientY - (rect.y + rect.height)) <= THRESHOLD;

	return isWithinXBounds && isWithinYBounds;
};

const longPressScale = (el, onOpen, onClose, options = { }) => {
	const { scrollCancelEl, onTouchStart } = options;

	const touchstart = (e) => {
		if (el.sa || e.touches.length > 1) {
			return;
		}

		onTouchStart && onTouchStart(el);
		let ended = false;
		let sa;

		const end = (opts) => {
			if (ended) {
				return;
			}
			ended = true;
			el.removeEventListener('touchmove', ontouchmove);
			el.removeEventListener('touchend', ontouchend);

			sa?.snapback(opts);
			if (scrollCancelEl) {
				scrollCancelEl.removeEventListener('scroll', scrollCancel);
			}
		};

		const scrollCancel = () => {
			end({ scrollCancel: true });
		};

		const ontouchmove = (me) => {
			const touch = me.touches[0];
			const rect = me.target.getBoundingClientRect();

			if (me.touches.length > 1 || !inBounds(touch, rect)) {
				end({ scrollCancel: true });
			}
		};

		const ontouchend = () => {
			end();
		};

		el.addEventListener('touchmove', ontouchmove, { passive: true });
		el.addEventListener('touchend', ontouchend);
		if (scrollCancelEl) {
			scrollCancelEl.addEventListener('scroll', scrollCancel);
		}

		sa = new ScaleAnimation(el, () => {
			el.removeEventListener('touchmove', ontouchmove);
			el.removeEventListener('touchend', ontouchend);
			if (!ended) {
				if (scrollCancelEl) {
					scrollCancelEl.removeEventListener('scroll', scrollCancel);
				}
				onOpen(sa);
			}
		});
		const now = window.performance.now();

		options.sa = sa;
		sa.on('close', (opts) => {
			el.sa = null;
			if (scrollCancelEl) {
				scrollCancelEl.removeEventListener('scroll', scrollCancel);
			}
			el.removeEventListener('touchmove', ontouchmove);
			el.removeEventListener('touchend', ontouchend);
			opts.ms = window.performance.now() - now;
			onClose(opts);
		});
		el.sa = sa;
	};

	el.addEventListener('touchstart', touchstart, { passive: true });
};

export default longPressScale;
