import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useQueryParam } from 'app/hooks/useQueryParams';
import Region from './Region';
import LeftRightNav from './LeftRightNav';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 206,
		width: '50%',
		textAlign: 'center',
		padding: theme.spacing(1),
	},
	category: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	regions: {
		marginTop: theme.spacing(4),
	},
}));
const makePath = (n) => `/onboarding/regions?region=${n}`;

export default ({
	regions,
}) => {
	const classes = useStyles();

	const regionKey = useQueryParam('region');
	const k = parseInt(regionKey, 10) || 0;

	let left;
	let right;
	if (k > 0) {
		left = makePath(k - 1);
	}
	if (k < 2) {
		right = makePath(k + 1);
	}

	return (
		<>
			<LeftRightNav
				right={right}
				left={left}
			/>
			<div className={classes.root}>
				<div className={classes.regions}>
					{regions.map((r) => <Region label={r.key} {...r} />)}
				</div>
			</div>
		</>
	);
};
