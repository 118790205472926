const COMMON_STYLES = {
	margin: '0',
	padding: '0',
	border: 'none',
	outline: 'none',
	resize: 'none',
	color: '#fff',
	background: 'none',
	fontFamily: 'Standard',
	width: '100%',
	maxWidth: '100%',
	whiteSpace: 'pre-wrap',
	'font-size': '32x',
	'text-align': 'center',

};

const FOCUS_STYLES = {
	left: 0,
	top: null,
	width: '100%',
	textAlign: 'center',
	minWidth: '46px',
	transform: '',
};

export {
	COMMON_STYLES,
	FOCUS_STYLES,
};
