import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import api, { CancelToken } from 'services/api';
import { injectReducer } from 'services/store';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';

export const requestApp = createAsyncThunk(
	'requestApp',
	async (_, {
		signal, rejectWithValue,
	}) => {
		const source = CancelToken.source();

		signal.addEventListener('abort', () => {
			source.cancel();
		});

		try {
			const response = await api.get('/experiences/constants', {
				cancelToken: source.token,
			});

			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorsFromRejectedRequest(error));
		}
	},
);

export const appSlice = createSlice({
	name: 'app',
	initialState: {
		constants: null,
		errors: null,
		loading: false,
		updatedVersionAvailable: false,
	},
	reducers: {
		change: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
	},
	extraReducers: {
		[requestApp.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestApp.fulfilled]: (state, action) => {
			state.constants = action.payload;
			state.loading = false;
			state.errors = null;
		},

		[requestApp.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = appSlice;

const { change } = actions;

export {
	change,
};

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);
export const getConstants = createSelector(getSlice, (slice) => slice?.constants);
export const getIsUpdatedVersionAvailable = createSelector(getSlice, (slice) => slice?.updatedVersionAvailable);
injectReducer(name, reducer);
