import { EventEmitter } from 'events';

export default class MediaRecorder extends EventEmitter {
	constructor(stream) {
		super();
		this.stream = stream;
		this._halt = false;
	}

	start(chunkSize) {
		this._halt = false;
		this.mediaRecorder = new window.MediaRecorder(this.stream, {
			mimeType: 'video/webm',
		});

		this.mediaRecorder.onerror = (error) => {
			this.emit('error', error);
		};

		this.mediaRecorder.onstart = () => {
			this.emit('start');
		};

		this.mediaRecorder.onstop = () => {
			this.emit('stop');
			this.onstop && this.onstop();
			this.onstop = null;
		};

		this.mediaRecorder.ondataavailable = (e) => {
			this.ondataavailable && this.ondataavailable(e);
			this.mediaRecorder.ondataavailable = null;

			if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
				this.mediaRecorder.stop();
			}

			if (!this._halt) {
				this.start(chunkSize);
			}
		};

		try {
			this.mediaRecorder.start(chunkSize);
		} catch (err) {
			this.emit('error', err);
		}
	}

	stop() {
		this._halt = true;
		if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
			this.mediaRecorder.stop();
		}
	}
}
