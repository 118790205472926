import React, {
	useEffect,
	useRef,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { neutral } from 'rds/colors';
import Menu from './Menu';
import LogoWordmark from './Logo-Wordmark';

const DEBOUNCE_TIME = 50;

const useStyles = makeStyles((theme) => ({
	header: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 5,
		transition: 'backgroundColor 0.2s linear',
	},
	contents: {
		// maxWidth: 1280,
		// margin: '0 auto',
		padding: theme.spacing(4, 9),
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		'@media (max-width: 599px)': {
			padding: theme.spacing(6),
		},
	},
	link: {
		zIndex: 2,
	},
}));

export default () => {
	const classes = useStyles();
	const ref = useRef();
	const [menu, setMenu] = useState(false);

	const handleScroll = (menuIsOpen) => {
		if (!ref?.current) {
			return;
		}

		const isMobile = window.innerWidth <= 600;
		if (menuIsOpen && isMobile) {
			ref.current.style.backgroundColor = neutral.C900;
		} else if (window.pageYOffset > 0) {
			ref.current.style.backgroundColor = neutral.C900;
		} else {
			ref.current.style.removeProperty('background-color');
		}
	};

	useEffect(() => {
		let lastWatch = -DEBOUNCE_TIME;

		const watchScroll = () => {
			if (window.performance.now() - lastWatch >= DEBOUNCE_TIME) {
				setTimeout(() => {
					handleScroll(menu);
				}, [DEBOUNCE_TIME]);
				lastWatch = window.performance.now();
			}
		};

		handleScroll(menu);

		window.addEventListener('resize', watchScroll);
		window.addEventListener('scroll', watchScroll);
		return () => {
			window.addEventListener('resize', watchScroll);
			window.addEventListener('scroll', watchScroll);
		};
	}, [ref, menu]);

	return (
		<div ref={ref} className={classes.header}>
			<div className={classes.contents}>
				<Link to="/" className={classes.link} onClick={() => { window.scrollTo(0, 0); }}>
					<LogoWordmark />
				</Link>
				<Menu onChange={(open) => setMenu(open)} />
			</div>
		</div>
	);
};
