import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	fixed: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		height: 52,
		zIndex: 3,
	},
});

export default ({
	bgcolor,
	fixed,
	children,
}) => {
	const classes = useStyles();

	if (!fixed) {
		return <div className={classes.fixed} style={{ backgroundColor: bgcolor }}>{children}</div>;
	}

	return (
		<Box className={classes.fixed} bgcolor={bgcolor}>
			{children}
		</Box>
	);
};
