import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getSession } from 'app/slices/authentication/session/session';
import { useSuspended } from 'components/Suspense/Suspense';
import clsx from 'clsx';
import Composer from 'lib/composer';
import Avatar from 'rds/components/Avatar';
import {
	set,
	requestSendComment,
	getPostingComment,
	getCommentToReply,
} from 'app/slices/comments';
import { getVisibleExperience } from 'app/slices/experience-modal';
import { getMatch } from 'utils/look';
import ButtonSpinner from 'rds/components/Spinners/Button';
import { useTheme } from '@material-ui/styles';
import { notifyErrors } from 'app/slices/notifications/notifications';
import emitter from 'features/ExperienceModal/emitter';
import Counter from 'rds/components/Counter';
import ReplyBanner from './ReplyBanner';
import Recommendations from './Recommendations/Recommendations';
import SendIcon from './Assets/Send.png';

const MAX_LENGTH = 2000;
const THRESHOLD = 1950;
const TIME_OUT = 300;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'end',
		paddingBottom: theme.spacing(2),
		zIndex: 15,
		position: 'absolute',
		width: '100%',
		bottom: 0,
		background: theme.palette.background.page,
		borderTop: `1px solid ${theme.colors.neutral.C800}`,
	},
	avatarContainer: {
		position: 'absolute',
		left: 20,
		bottom: theme.spacing(10),
	},
	spinnerContainer: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		bottom: theme.spacing(8),
		right: theme.spacing(4),
		width: 40,
		height: 44,
	},
	sendIcon: {
		position: 'absolute',
		bottom: theme.spacing(8),
		right: theme.spacing(4),
		width: 40,
		height: 44,
		backgroundSize: 'cover',
	},
	comment: {
		...theme.typography.body1,
		'&::placeholder': {
			color: `${theme.colors.neutral.C400} !important`,
		},
		outline: 'none',
		whiteSpace: 'pre-wrap',
		minHeight: 44,
		maxHeight: 124,
		overflowY: 'scroll',
		borderRadius: 24,
		width: '100%',
		background: theme.colors.neutral.C900,
		position: 'relative',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(6),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		padding: '11px 36px 11px 44px',
		wordBreak: 'break-word',
		'&::-webkit-scrollbar': {
			display: 'none',
			width: 0,
		},
	},
}));

export default ({
	context,
	open,
}) => {
	const theme = useTheme();
	const classes = useStyles();
	const [match, setMatch] = useState(null);
	const dispatch = useDispatch();
	const session = useSelector(getSession);
	const avatarSrc = session?.avatar?.small?.jpg;
	const [contentLength, setContentLength] = useState(0);
	const suspended = useSuspended();
	const ref = useRef();
	const inputRef = useRef();
	const isPosting = useSelector(getPostingComment);
	const commentToReply = useSelector(getCommentToReply);
	const showCounter = ref?.current?.textContent?.length <= THRESHOLD;
	const visibleExperience = useSelector(getVisibleExperience);

	useEffect(() => {
		if (!ref && !ref.current) { return; }

		if (!ref.current.composer) {
			ref.current.composer = new Composer(ref.current, {
				maxHeight: 2040,
			});
			ref.current.onkeyup = () => {
				const m = getMatch(
					ref.current.textContent,
					ref.current.composer.cursor.start.totalOffset - 1,
					['@'],
					/\s/,
				);

				setMatch(m);
				setContentLength(ref.current.innerText.trim().length);
				ref.current.scrollTop = ref.current.scrollHeight;
			};
		}

		if (context.add) {
			ref.current.focus();
		}
	}, [ref, suspended]);

	useEffect(() => {
		if (!ref && !ref.current && !ref.current.composer) {
			return;
		}

		if (commentToReply) {
			const el = ref.current;
			const rValue = `@${commentToReply?.author?.username}`;
			let v = el.textContent[rValue.length] !== ' ' ? rValue : rValue;
			const trailing = el.textContent.slice(rValue.length);
			if (!trailing.length) {
				v += ' ';
			} else if (trailing[0] !== ' ') {
				v += ' ';
			}

			const str = `${el.textContent.slice(0, 0)}${v}${trailing}`;
			el.composer.string = str;

			el.composer.addNode({
				type: 'mention',
				contents: `${rValue}`,
				data: { uuid: commentToReply?.author?.uuid },
			});
			el.composer.placeCursorPosition(`${rValue.length}`);
			inputRef.current.focus();
			ref.current.focus();
		}
	}, [ref, commentToReply]);

	const style = {};
	if (open) {
		style.position = 'fixed';
	}

	return (
		<div
			className={clsx(classes.root, 'messages')}
			data-ignoreslider="true"
			style={style}
		>
			<Counter
				value={ref?.current?.textContent}
				maxLength={MAX_LENGTH}
				threshold={THRESHOLD}
			/>
			<ReplyBanner
				commentToReply={commentToReply}
				style={{ top: !showCounter ? theme.spacing(-18) : theme.spacing(-10.5) }}
				onClose={() => {
					dispatch(set({ commentToReply: null }));
					ref.current.focus();
				}}
			/>

			<Recommendations
				match={match}
				onClick={({ entity, match: inputMatch }) => {
					if (!ref?.current?.composer) {
						return;
					}

					const el = ref.current;
					const rValue = entity.username.toLowerCase();
					const { start, end } = inputMatch.position;
					let v = el.textContent[end] !== ' ' ? rValue : rValue;

					const trailing = el.textContent.slice(end);
					if (!trailing.length) {
						v += ' ';
					} else if (trailing[0] !== ' ') {
						v += ' ';
					}

					const str = `${el.textContent.slice(0, start)}${v}${trailing}`;
					el.composer.string = str;

					el.composer.addNode({
						type: 'mention',
						contents: `${inputMatch.prefix}${rValue}`,
						data: { uuid: entity.uuid },
					});

					el.composer.placeCursorPosition(start + v.length);
					setMatch(null);
				}}
			/>
			<div className={classes.avatarContainer}>
				<Avatar
					src={avatarSrc}
					size="xxSmall"
					alt="Username"
				/>
			</div>

			<div
				contentEditable
				placeholder="Comment"
				onBlur={() => {
					setTimeout(() => {
						if (ref?.current && document.activeElement !== ref.current) {
							emitter.emit('bp-unfill');
						}
					}, 1);
				}}
				onFocus={() => emitter.emit('bp-fill')}
				className={clsx('comment', classes.comment)}
				ref={ref}
			/>
			<input
				style={{ position: 'absolute', top: '-9999px' }}
				ref={inputRef}
			/>

			{isPosting
				? (
					<div className={classes.spinnerContainer}>
						<ButtonSpinner size={20} />
					</div>
				)
				: (
					<div
						className={classes.sendIcon}
						style={{ opacity: contentLength > 0 ? 1 : 0.5, backgroundImage: `url(${SendIcon})` }}
						onClick={() => {
							const lengthOfContent = ref.current.innerText.trim().length;
							if (!lengthOfContent) {
								return;
							}
							if (lengthOfContent > MAX_LENGTH) {
								setTimeout(() => {
									notifyErrors([{ title: 'Unable to send comment', text: 'Max character count exceeded.' }]);
								}, TIME_OUT);
								return;
							}

							const messageContent = ref.current.composer.nodes.map((i) => ({
								type: i.type,
								value: i.type === 'text' ? i.data : i.data.uuid,
							}));

							setTimeout(() => {
								dispatch(requestSendComment({
									message: messageContent,
									type: commentToReply ? 'reply' : null,
									experience: visibleExperience,
								}));
								ref.current.composer.string = '';
							}, 1);
						}}
					/>
				)}
		</div>
	);
};
