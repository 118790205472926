/* eslint-disable array-callback-return */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectInputFocused } from 'app/slices/content-creation/editor/layout';
import SearchSticker from './SearchSticker';
import SearchedStickers from './SearchedSticker';
import SuggestedStickers from './SuggestedStickers';

function Plugins({ params }) {
	const focused = useSelector(selectInputFocused);

	return (
		<>
			<SearchSticker />
			<div>
				{focused
					? <SearchedStickers params={params} />
					: <SuggestedStickers params={params} />}
			</div>
		</>
	);
}

export default Plugins;
