import React from 'react';
import { makeStyles } from '@material-ui/core';
import TitlesAndSubTitle from './TitlesAndSubTitle';
import RadioGroups from './RadioGroups';

const useStyles = makeStyles((theme) => ({
	comment: {
		paddingTop: theme.spacing(6),
		paddingLeft: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingRight: theme.spacing(4),
		borderTop: `1px solid ${theme.colors.neutral.C800}`,
	},
	commentLikes: {
		paddingTop: theme.spacing(6),
		paddingLeft: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingRight: theme.spacing(4),
		borderTop: `1px solid ${theme.colors.neutral.C800}`,
	},

}));

export default ({
	slug,
	title,
	selectedOption,
	subtitle,
	options,
	onChange,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.comment}>
			<TitlesAndSubTitle
				title={title}
				subtitle={subtitle}
			/>
			<RadioGroups
				options={options.map(({ slug: value, title: label }) => ({
					label,
					value,
				}))}
				value={selectedOption}
				handleChange={(e) => onChange(e.target.value, slug, selectedOption)}
			/>
		</div>
	);
};
