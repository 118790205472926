import { createSelector } from '@reduxjs/toolkit';

export const getSlice = (state) => state.destinationEcrExperiences;

export const getLoading = createSelector(getSlice, (slice) => slice?.loading);

export const getECRExperiences = createSelector(getSlice, (slice) => slice?.ecrExperiences);

export const getNextLink = createSelector(getSlice, (slice) => slice?.ecr?.next);

export const getPrevLink = createSelector(getSlice, (slice) => slice?.ecr?.prev);

export const getErrors = createSelector(getSlice, (slice) => slice.errors);

export const getCurrentCreator = createSelector(getSlice, (slice) => slice?.currentCreator);
