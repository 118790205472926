import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { buildVersion, devMode } from 'config';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import { fetchFile, fetchMedia } from 'app/android-service';
import { getImage, getVideoDetails, readFile } from 'routes/ContentCreation/Create/Camera/SelectFromLibrary/utils';
import trim from 'lib/ffmpeg/alt-trim';
import assetManager from 'lib/asset-manager';
import { notifyErrors } from 'app/slices/notifications/notifications';
import queueFetch from 'services/queue-fetch';
import { clearPercentage, disableCancel, updatePercentage } from './state';
import {
	requestAddClipPhoto,
	selectClipCount,
	CLIPS_THRESHOLD,
	DURATION_THRESHOLD,
} from '../../experience';

const LIMIT = 36;

const initialState = {
	loading: false,
	errors: null,
	selected: [],
	album: null,
	media: [],
	processing: false,
	hasMoreMedia: false,
};

export const requestMedia = createAsyncThunk(
	'requestMedia',
	async (album, { rejectWithValue }) => {
		try {
			const media = await fetchMedia(album);
			return { album, media };
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const requestLoadMoreMedia = createAsyncThunk(
	'requestLoadMoreMedia',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { album, media } = getState().deviceLibraryMedia;

			if (!album) {
				throw new Error('Failed to retrieve more media');
			}

			const newList = await fetchMedia(album, { limit: LIMIT, offset: media.length });
			return newList;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const processImage = async (dispatch, file) => {
	const { blob, height, width } = await getImage(file);

	return async () => {
		const response = await dispatch(requestAddClipPhoto({
			type: 'image',
			data: {
				blob,
			},
			dimensions: {
				height: document.body.clientHeight,
				width: document.body.clientWidth,
			},
			assetDimensions: {
				height,
				width,
			},
		}));

		return response;
	};
};

const processVideo = async (dispatch, media, clipCount, file) => {
	const { duration, height, width } = await getVideoDetails(file);
	const incomingClipCount = Math.ceil((duration * 1000) / DURATION_THRESHOLD);
	const maxClips = Math.min(CLIPS_THRESHOLD - clipCount, incomingClipCount);

	const data = await readFile(file);

	const output = await trim({
		name: media.name,
		data,
		start: 0,
		stop: maxClips * (DURATION_THRESHOLD / 1000),
		// TODO: this needs to be fixed if we ever accept more codecs
		type: file.type === 'video/mp4' ? 'mp4' : 'webm',
		log: true,
	});
	// start: j * (DURATION_THRESHOLD / 1000),
	// end: Math.min(duration, (j + 1) * (DURATION_THRESHOLD / 1000)),

	const genDispatch = (asset, { start, stop }) => async () => {
		const response = await dispatch(requestAddClipPhoto({
			type: 'video',
			data: {
				asset,
				start,
				end: stop,
			},
			assetDimensions: {
				height,
				width,
			},
		}));

		return response;
	};

	const dispatches = [];
	const blob = new Blob([output.data], { type: 'video/mp4' });
	const asset = assetManager.set(blob);

	for (let j = 0; j < maxClips; j++) {
		dispatches.push(genDispatch(asset, {
			start: j * (DURATION_THRESHOLD / 1000),
			stop: Math.min(duration, (j + 1) * (DURATION_THRESHOLD / 1000)),
		}));
	}

	return dispatches;
};

const delayedUpdates = (dispatch, currentPercentage, updateTo, steps, now = 0) => {
	if (now >= steps) {
		return;
	}
	now++;

	const diff = updateTo - currentPercentage;
	const next = ((diff / steps) * now) + currentPercentage;

	dispatch(updatePercentage(next));

	setTimeout(() => {
		delayedUpdates(dispatch, currentPercentage, updateTo, steps, now);
	}, 400);
};

let cancelProcessing;
export const processMedia = createAsyncThunk(
	'processMedia',
	async (_, { dispatch, getState, rejectWithValue }) => {
		try {
			const state = getState();
			const { selected } = state.deviceLibraryMedia;
			const clipCount = selectClipCount(state);

			let cancelled = false;
			cancelProcessing = () => {
				cancelled = true;
				cancelProcessing = null;
			};

			const dispatches = [];
			let percentage = 0;
			for (let i = 0; i < selected.length; i++) {
				const m = selected[i];

				// Wrap this in a try catch to limit the failure
				// offer an option to fall back to legacy
				const blob = await fetchFile(m.fileUri);
				if (cancelled) {
					return false;
				}

				const updateTo = ((i + 1) / selected.length) * 100;
				if (m.mediaType === 3) {
					const duration = parseInt(m.duration, 10);

					if (Number.isNaN(duration)) {
						if (!cancelled) {
							dispatch(updatePercentage(updateTo));
						}
					} else {
						const count = Math.ceil(m.duration / DURATION_THRESHOLD) * 10;
						if (!cancelled) {
							delayedUpdates(dispatch, percentage, updateTo, count);
						}
					}
					try {
						const deferred = await processVideo(dispatch, m, clipCount, blob);
						dispatches.push(...deferred);
					} catch (err) {
						const text = `Failed to process video ${m?.name ? m.name : ''}`;
						notifyErrors([{
							text,
						}]);
						queueFetch('/log/pwa', {
							method: 'POST',
							json: [{
								level: 'error',
								payload: {
									errorMessage: text,
									stack: err.stack,
									buildVersion,
									environment: devMode ? 'dev' : 'prod',
								},
							}],
						});
					}
				} else {
					if (!cancelled) {
						dispatch(updatePercentage(updateTo));
					}
					const deferred = await processImage(dispatch, blob);
					dispatches.push(deferred);
				}
				percentage = updateTo;
			}

			if (cancelled) {
				if (!cancelProcessing) {
					dispatch(clearPercentage());
				}
				return false;
			}

			dispatch(updatePercentage(100));
			dispatch(disableCancel());
			for (let i = 0; i < dispatches.length; i++) {
				await dispatches[i]();
			}

			return true;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const dlSlice = createSlice({
	name: 'deviceLibraryMedia',
	initialState: { ...initialState },
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},

		select: (state, action) => {
			const selected = [...state.selected];

			const index = selected.findIndex(({ fileUri }) => fileUri === action.payload.fileUri);
			if (index === -1) {
				selected.push(action.payload);
			} else {
				selected.splice(index, 1);
			}

			state.selected = selected;
		},

		cancel: (state) => {
			cancelProcessing && cancelProcessing();
			cancelProcessing = null;
			state.processing = false;
		},
	},

	extraReducers: {
		[processMedia.pending]: (state) => {
			state.processing = true;
		},
		[processMedia.fulfilled]: (state, action) => {
			if (action.payload) {
				state.selected = [];
			}
			state.processing = false;
		},
		[processMedia.rejected]: (state) => {
			state.processing = false;
		},
		[requestMedia.pending]: (state) => {
			state.loading = true;
			state.media = [];
			state.errors = null;
		},
		[requestMedia.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestMedia.fulfilled]: (state, action) => {
			state.loading = false;
			state.errors = null;
			const { album, media } = action.payload;
			state.album = album;
			state.media = media.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
			if (media.length >= LIMIT) {
				state.hasMoreMedia = true;
			} else {
				state.hasMoreMedia = false;
			}
		},
		[requestLoadMoreMedia.pending]: (state) => {
			state.loading = true;
		},
		[requestLoadMoreMedia.fulfilled]: (state, action) => {
			state.loading = false;

			state.media.push(...action.payload);

			if (action.payload.length >= LIMIT) {
				state.hasMoreMedia = true;
			} else {
				state.hasMoreMedia = false;
			}
		},
	},
});

const { name, reducer, actions } = dlSlice;
const {
	reset, cancel, select,
} = actions;
export {
	reset, cancel, select,
};

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSelected = createSelector(getSlice, (slice) => slice?.selected);
export const getMediaOffset = createSelector(getSlice, (slice) => slice?.offset);
export const getMedia = createSelector(getSlice, (slice) => slice?.media);
export const getProcessing = createSelector(getSlice, (slice) => slice?.processing);
export const getHasMoreMedia = createSelector(getSlice, (slice) => slice?.hasMoreMedia);
export const getTotalClipCount = createSelector((state) => state, (state) => {
	const clipCount = selectClipCount(state);
	const selectedMedia = getSelected(state);

	let selectedCount = 0;
	selectedMedia.forEach((m) => {
		const duration = parseInt(m.duration, 10);

		if (duration) {
			selectedCount += Math.ceil(m.duration / DURATION_THRESHOLD);
		} else {
			selectedCount += 1;
		}
	});

	return clipCount + selectedCount;
});

injectReducer(name, reducer);
