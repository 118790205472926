import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '../Grid';

const useStyles = makeStyles(() => ({
	root: {
		paddingTop: 52,
		overflowY: 'hidden',
	},
}));

const ExploreGrid = ({ show, isSearching, setIsSearching }) => {
	const classes = useStyles();

	return (
		<div
			className={classes.root}
			onMouseDown={(e) => { e.preventDefault(); }}
		>
			<Grid show={show} isSearching={isSearching} setIsSearching={setIsSearching} />
		</div>
	);
};

export default ExploreGrid;
