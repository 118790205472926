import React, {
	memo,
	useMemo,
	useEffect,
	useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { getSession } from 'app/slices/authentication/session/session';
import Avatar from 'rds/components/Avatar';
import { getAllNotifications } from 'app/slices/app-notifications/notifications';
import { setCreateNavigation } from 'app/slices/content-creation/create/device-library/state';
import { useNavbarState } from 'app/hooks/useNavbarState';
import useNavigateCC from 'app/hooks/useNavigateCC';
import { set } from 'app/slices/content-creation/permission';
import { getUserEmail } from 'app/slices/account-settings';
import { useNavigate } from 'react-router-dom';
import navigationEmitter from './emitter';
import AddIcon from './AddIcon';
import Item from './Item';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		left: 0,
		width: '100%',
		height: 48,
		background: theme.palette.background.page,
		zIndex: 27,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
		opacity: 1,
		borderTop: '1px solid rgba(50, 56, 62, 0.4)',
	},
	hidden: {
		display: 'none',
	},
}));

const AccountIcon = () => {
	const session = useSelector(getSession);

	return (
		<Avatar fallback={false} src={session?.avatar?.small?.jpg} size="xSmall" alt="username" />
	);
};

const PureMenu = ({ active, unread }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navigateCC = useNavigateCC();
	const session = useSelector(getSession);
	const hasPermissionToCreate = session?.permissions['create-experience'] === true;
	const email = useSelector(getUserEmail);
	const userIsVerified = email?.isVerified;

	return (
		<>
			<Item to="/" icon="Home" active={active === '/home'} />
			<Item to="/explore" icon="Search" active={active === '/explore'} />
			<Item
				Icon={AddIcon}
				onClick={async () => {
					if (!userIsVerified) {
						dispatch(set({ createPermissionModal: true }));
						return;
					}
					if (userIsVerified && !hasPermissionToCreate) {
						navigate('/signout');
						return;
					}
					await dispatch(setCreateNavigation({
						open: true,
						slide: true,
					}));
					navigateCC('create', {
						tab: 'photo',
						subTab: '',
					});
				}}
			/>
			<Item to="/notifications" dot={!!unread} icon={unread ? 'NotificationUnread' : 'NotificationRead'} active={active === '/notifications'} />
			<Item to="/my-account" Icon={AccountIcon} active={active === '/my-account'} />
		</>
	);
};

const tickFrame = (fn, delay = 0) => new Promise((resolve) => {
	fn();

	window.requestAnimationFrame(() => {
		setTimeout(() => {
			resolve();
		}, delay);
	});
});

const Menu = ({ show, active, lastActiveString }) => {
	const classes = useStyles();
	const ref = useRef();
	const notifications = useSelector(getAllNotifications);
	const unread = notifications && !!notifications.filter((n) => n.unread).length;
	const { isNavbarOpen } = useNavbarState();

	const klass = useMemo(() => {
		const baseClass = (!isNavbarOpen || !show) ? clsx(classes.root, classes.hidden) : classes.root;
		return baseClass;
	}, [isNavbarOpen, show, classes.root, classes.hidden]);

	useEffect(() => {
		const fadeMenuIn = async () => {
			await tickFrame(() => {
				if (ref?.current?.style) {
					ref.current.style.opacity = 0;
				}
			});
			await tickFrame(() => {
				if (ref?.current?.style) {
					ref.current.style.transition = 'opacity 100ms ease-in-out 200ms';
				}
			});

			await tickFrame(() => {
				if (ref?.current?.style) {
					ref.current.style.opacity = 1;
				}
			}, 300);

			ref?.current?.style?.removeProperty('transition');
		};

		navigationEmitter.on('fade-menu-in', fadeMenuIn);

		return () => {
			navigationEmitter.off('fade-menu-in', fadeMenuIn);
		};
	}, [ref, navigationEmitter]);

	return (
		<div ref={ref} className={klass}>
			<PureMenu active={active ? lastActiveString : active} unread={unread} />
		</div>
	);
};

export default memo(Menu);
