import { registerRoute } from 'Router/registry';
import Skeleton from 'rds/components/Skeletons/SecondaryPage';
import {
	requestExperienceByCode,
} from 'app/slices/experience-by-share';
import ExportByCodePage from './ByCode';

registerRoute({
	authorization: true,
	Component: ExportByCodePage,
	Skeleton,
	loadData: [
		async ({ dispatch, params }) => {
			const { code } = params;
			await dispatch(requestExperienceByCode({ code }));
		},
	],
	routeOptions: {
		path: '/e/:code',
	},
	transition: 'dissolve',
});
