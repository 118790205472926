/**
 * Proxy events from child event emitter through parent event emitter
 *
 * @param {array} events
 * @param {object} child Any event emitter that you would like to proxy
 * @param {object} parent The event emitter that will proxy events from the first emitter
 * @returns {function} A function to clean all of the created events
 * @usage

import EventEmitter from 'events';

class ParentComponent extends EventEmitter {
	constructor() {
		this.childComponent = new ChildComponent();
		this.destroyProxyEvents = proxyEvents(['log', 'error'], this.childComponent, this);
	}

	destroy() {
		this.destroyProxyEvents();
	}
}

 */
export default (events, child, parent) => {
	for (let i = 0; i < events.length; i++) {
		child.on(events[i], (...args) => {
			// eslint-disable-next-line
			parent.emit.apply(parent, [events[i]].concat(args));
		});
	}

	return () => {
		for (let i = 0; i < events.length; i++) {
			child.removeEventListener(events[i]);
		}
	};
};
