import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { requestTrustedExperiencesByCreator } from 'app/slices/destination-account-v2/trusted-experiences/thunks';
import { setCurrentCreator } from 'app/slices/destination-account-v2/experiences-ecr/slice';
import { setOpenDirective } from 'app/slices/experience-modal';
import { useFormUrl } from 'app/hooks/useQueryParams';
import clsx from 'clsx';
import ButtonSpinner from 'rds/components/Spinners/Button';

const useStyles = makeStyles((theme) => ({
	user: {
		display: 'flex',
		width: 68,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	userImage: {
		position: 'relative',
		overflow: 'hidden',
		marginRight: theme.spacing(4),
		marginLeft: theme.spacing(4),
		borderRadius: '50%',
		width: 70,
		height: 70,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: `0.5px solid ${theme.colors.neutral.C800}`,
	},
	backgroundOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundSize: 'cover',
		borderRadius: '50%',
		zIndex: 0,
	},
	name: {
		width: 68,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		marginTop: theme.spacing(2),
		textOverflow: 'ellipsis',
		textAlign: 'center',
	},
	loading: {
		opacity: 0.6,
	},
}));

export default ({ creator }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { uid } = useParams();
	const navigate = useNavigate();
	const formUrl = useFormUrl();

	const cUuid = `trusted-creator-uuid-${creator.uuid}`;
	const querySelector = `[data-experience-modal-id="${cUuid}"]`;

	const handleClick = async () => {
		setLoading(true);
		await dispatch(setCurrentCreator(creator?.uid));
		const response = await dispatch(requestTrustedExperiencesByCreator({ uid, creatorId: creator?.uid }));
		const trustedExperiences = response.payload?.data?.experiences;

		const experience = trustedExperiences && trustedExperiences[0];
		const url = formUrl({
			params: {
				experience: experience?.uid,
				trustedCreator: creator.uid,
			},
		});

		await dispatch(setOpenDirective({
			querySelector,
		}));
		setLoading(false);
		navigate(url);
	};

	return (
		<div
			key={creator.uid}
			className={classes.user}
			onClick={handleClick}
			data-ignore-experience-modal
			data-dismiss-opacity
			data-experience-modal-id={cUuid}
		>
			<div className={classes.userImage}>
				<div
					style={{ backgroundImage: `url(${creator?.avatar?.large?.jpg})` }}
					className={loading ? clsx(classes.backgroundOverlay, classes.loading) : classes.backgroundOverlay}
				/>
				{loading && (
					<ButtonSpinner
						size={32}
						style={{ opacity: 1 }}
					/>
				)}
			</div>
			<Typography variant="body2" className={loading ? clsx(classes.name, classes.loading) : classes.name}>
				{creator?.username}
			</Typography>
		</div>
	);
};
