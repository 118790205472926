/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

export default ({ label, checked, onChange }) => (
	<div
		className="switchContainer"
		onClick={(e) => {
			e.preventDefault();
			onChange();
		}}
	>
		<div className="toggle-switch">
			<input
				type="checkbox"
				className="checkbox"
				name={label}
				id={label}
				checked={checked}
				onChange={onChange}
			/>
			<label className="label" htmlFor={label}>
				<span className="inner" />
				<span className="switch" />
			</label>
		</div>
	</div>
);
