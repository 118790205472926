import { createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

const initialState = {
	open: false,
	route: null,
	type: null,
	label: '',
	formTitle: '',
	id: null,
	title: '',
};

export const reportModalNavigatorSlice = createSlice({
	name: 'reportModalNavigator',
	initialState: { ...initialState },
	reducers: {
		changeRoute: (state, action) => {
			state.route = action.payload;
		},
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
	},
});

const { name, reducer, actions } = reportModalNavigatorSlice;
export const {
	changeRoute,
	set,
	reset,
} = actions;

export const getSlice = (state) => state[name];
export const getRoute = createSelector(getSlice, (slice) => slice?.route);
export const getType = createSelector(getSlice, (slice) => slice?.type);
export const getFormTitle = createSelector(getSlice, (slice) => slice?.formTitle);
export const getReportLabel = createSelector(getSlice, (slice) => slice?.label);
export const getResourceId = createSelector(getSlice, (slice) => slice?.id);
export const getTitle = createSelector(getSlice, (slice) => slice?.title);
export const getIsModalOpen = createSelector(getSlice, (slice) => slice?.open);
injectReducer(name, reducer);
