import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const DURATION = 200;

const useStyles = makeStyles({
	default: {
		position: 'relative',
		zIndex: 1000,
	},
	stickyFilters: {
		position: 'sticky',
		top: 48,
		zIndex: 1000,
	},
});

const StickyContainer = ({ children, isOpen }) => {
	const classes = useStyles();
	const filtersRef = useRef(null);
	const [isSticky, setSticky] = useState(false);

	useEffect(() => {
		const checkSticky = () => {
			if (!filtersRef.current) {
				return;
			}

			const filtersPosition = filtersRef.current.getBoundingClientRect().top;
			setSticky(filtersPosition <= 48);
		};

		window.addEventListener('scroll', checkSticky);
		return () => {
			window.removeEventListener('scroll', checkSticky);
		};
	}, []);

	const scrollToBottom = () => {
		if (filtersRef.current) {
			const filtersTopPosition = filtersRef.current.offsetTop - 48;
			const startPosition = window.scrollY;
			const distance = filtersTopPosition - startPosition;

			let start = null;

			const step = (timestamp) => {
				if (!start) start = timestamp;
				const progress = timestamp - start;
				window.scrollTo(0, startPosition + distance * (progress / DURATION));
				if (progress < DURATION) {
					window.requestAnimationFrame(step);
				}
			};

			window.requestAnimationFrame(step);
		}
	};

	useEffect(() => {
		if (isOpen) {
			scrollToBottom();
		}
	}, [isOpen]);

	return (
		<div
			ref={filtersRef}
			className={isSticky ? classes.stickyFilters : classes.default}
			data-filters-container="destination"
		>
			{children}
		</div>
	);
};

export default StickyContainer;
