import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getSearchedStickers, requestSearchStickers } from 'app/slices/content-creation/editor/stickers';
import { control, resetForm } from 'app/slices/content-creation/editor/search-stickers-form';
import StickerGrid from './StickerGrid';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowY: 'auto',
		whiteSpace: 'nowrap',
		marginTop: theme.spacing(30),
	},
	eachGroup: {
		marginBottom: theme.spacing(2),
		color: theme.colors.neutral.C000,
	},
	title: {
		textAlign: 'left',
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	notFound: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(7),
	},
}));

const DEBOUNCE_SEARCH = 200;

export default ({ params }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const stickers = useSelector(getSearchedStickers);
	const fields = control.get(dispatch);
	const search = useSelector(fields.search.selector);
	const searchRef = useRef();
	useEffect(() => {
		clearTimeout(searchRef.current);
		if (!search) {
			dispatch(resetForm());
		} else {
			searchRef.current = setTimeout(() => {
				dispatch(requestSearchStickers());
			}, DEBOUNCE_SEARCH);
		}

		return () => {
			clearTimeout(searchRef.current);
		};
	}, [search]);

	if (stickers === null) {
		return (
			<div className={classes.notFound}>
				<Typography variant="body1" className={classes.title}>
					No results found.
				</Typography>
			</div>

		);
	}

	return (
		<div className={classes.root}>
			{stickers && stickers.context.map((group, i) => (
				<div className={classes.eachGroup} key={i}>
					<Typography variant="body1" className={classes.title}>
						{ group.title}
					</Typography>
					<StickerGrid params={params} group={group} />
				</div>
			))}
		</div>
	);
};
