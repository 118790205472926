import createElement from '../../utils/create-element';
import { HTML } from '../../types';
import { convertHtmlToPng } from './utils/html-to-image';
import BaseNode from './base';

export default class HtmlNode extends BaseNode {
	constructor(canvas, item) {
		super(canvas, item);
		this.ext = 'png';
		this.type = HTML;
		this.fonts = canvas.fonts || {};

		if (!item) {
			return;
		}

		if (this.data.el) {
			this.el = this.data.el;
		} else {
			this.el = createElement(this.data);
			if (this.data.text) {
				this.el.innerText = this.data.text;
			}
		}

		this.el.style.position = 'absolute';
		this.el.style.zIndex = item.z;

		if (!this.data.el) {
			Object.keys(this.styles).forEach((k) => {
				this.el.style[k] = this.styles[k];
			});
		}

		if (this.data.attributes) {
			Object.keys(this.data.attributes).forEach((k) => {
				this.el.setAttribute(k, this.data.attributes[k]);
			});
		}
		if (this.data.eventListeners) {
			Object.keys(this.data.eventListeners).forEach((k) => {
				this.el.addEventListener(k, this.data.eventListeners[k]);
			});
		}

		this.setAttributes(this.el);
	}

	async renderFrame(_, multiplier) {
		const {
			context,
			el,
			shadowContainer,
		} = this.compileContext;
		shadowContainer.appendChild(el);

		const f = this.fonts[el.style['font-family']];
		const fonts = f ? [f] : [];

		const scale = this.getScale(el);
		const angle = this.getRotation(el);
		const rads = angle * (Math.PI / 180);
		const size = {};
		const position = {};

		el.style.removeProperty('transform');
		const image = await convertHtmlToPng(el, { multiplier, fonts });
		this.rendered = image;

		const rect = el.getBoundingClientRect();
		el.style.transform = `rotate(${angle}deg)`;
		const containerRect = shadowContainer.getBoundingClientRect();

		// true center
		const centerPercent = {
			x: (rect.x + (rect.width / 2)) / containerRect.width,
			y: (rect.y + (rect.height / 2)) / containerRect.height,
		};

		// center point
		const center = {
			x: (containerRect.width * multiplier) * centerPercent.x,
			y: (containerRect.height * multiplier) * centerPercent.y,
		};

		// find the top left
		const topLeftCorner = {
			x: center.x - ((rect.width * scale * multiplier) / 2),
			y: center.y - ((rect.height * scale * multiplier) / 2),
		};

		size.height = Math.round(rect.height * multiplier * scale);
		size.width = Math.round(rect.width * multiplier * scale);
		position.x = Math.round(topLeftCorner.x);
		position.y = Math.round(topLeftCorner.y);

		context.save();
		context.translate(position.x, position.y);
		context.translate(size.width / 2, size.height / 2);
		context.rotate(rads);
		context.drawImage(image,
			0,
			0,
			size.width,
			size.height,
			(-size.width / 2),
			(-size.height / 2),
			size.width * multiplier * scale,
			size.height * multiplier * scale);
		context.restore();
	}

	setupCompile(canvas, context, shadowContainer) {
		const el = this.el.cloneNode(true);

		this.compileContext = {
			canvas,
			context,
			el,
			shadowContainer,
			background: this.background,
			root: this.root,
		};

		shadowContainer.appendChild(el);
	}
}
