import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import CancelAlert from 'rds/components/CancelAlert';
import { useTheme } from '@material-ui/styles';
import { requestDeleteMyAccount } from 'app/slices/account-settings';
import { notify } from 'app/slices/notifications';
import { useNavigate } from 'react-router-dom';
import { endSession } from 'app/slices/authentication/session/session';
import SettingItemWithArrow from './SettingItemWithArrow';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(6),
		paddingLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		paddingRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		borderTop: `1px solid ${theme.colors.neutral.C800}`,
	},
	title: {
		color: theme.colors.neutral.C300,
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	return (
		<div className={classes.root}>
			<Typography
				className={classes.title}
				variant="body2"
			>
				PRIVACY & SECURITY
			</Typography>
			<SettingItemWithArrow marginTop title="Delete Account" onClick={() => setOpen(true)} />
			<CancelAlert
				title="Are you sure you want to delete your account and related data & history?"
				subtitle="You will be immediately logged out. This change cannot be undone."
				open={open}
				background
				onClick={async () => {
					setOpen(false);
					await dispatch(requestDeleteMyAccount());
					navigate('/landing');
					dispatch(notify({
						severity: 'info',
						description: 'Account deleted.',
					}));
					dispatch(endSession());
				}}
				leftButton="Cancel"
				rightButton="Delete"
				rightButtonStyle={{ color: theme.colors.error.C700 }}
				onClose={() => { setOpen(false); }}
			/>
		</div>
	);
};
