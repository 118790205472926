import { createSelector } from '@reduxjs/toolkit';

export const getSlice = (state) => state.trustedCreators;

export const getTrustedCreators = createSelector(getSlice, (slice) => slice?.trustedCreators);

export const getTrustedExperiences = createSelector(getSlice, (slice) => slice?.trustedExperiences);

export const getShouldReload = createSelector(getSlice, (slice) => slice?.shouldReloadTrustedCreators);

export const getErrors = createSelector(getSlice, (slice) => slice?.errors);

export const getLoading = createSelector(getSlice, (slice) => slice?.loading);

export const getHasMoreToLoad = createSelector(getSlice, (slice) => !!slice?.next);

export const getHasPrevToLoad = createSelector(getSlice, (slice) => !!slice?.prev);
