import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNavigateCC from 'app/hooks/useNavigateCC';
import {
	selectClips,
	selectLockCC,
	compileUncompiledPreviews,
} from 'app/slices/content-creation/experience';
import {
	getFlash,
	setFacingMode,
	setFlash,
	getFacingMode,
} from 'app/slices/user-media';
import { reset as resetNotifications } from 'app/slices/notifications/notifications';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import getFlashIcon from 'utils/get-flash';
import FixedTopControls from './FixedTopControls';

export default ({
	camera,
	hasPermissions,
	tray,
}) => {
	const dispatch = useDispatch();
	const navigateCC = useNavigateCC();
	const flashMode = useSelector(getFlash);
	const facingMode = useSelector(getFacingMode);
	const clips = useSelector(selectClips);
	const lockCC = useSelector(selectLockCC);
	const show = useSelector(getIsUpdatedVersionAvailable);
	const flash = getFlashIcon(flashMode);
	const disabled = lockCC;

	const buttons = [];

	if (tray) {
		buttons.push({
			text: 'Cancel',
			ds: true,
			disabled: false,
			style: { top: show ? 48 : 0 },
			onClick: () => {
				navigateCC('details');
			},
		});
		buttons.push({
			text: '',
			ds: true,
			disabled,
			style: { top: show ? 48 : 0 },
			onClick: () => {},
		});
	} else {
		buttons.push({
			text: 'Exit',
			ds: true,
			disabled: false,
			style: { top: show ? 48 : 0 },
			onClick: () => {
				navigateCC.exit();
			},
		});
		buttons.push({
			text: 'Next',
			ds: true,
			disabled,
			style: { top: show ? 48 : 0 },
			onClick: () => {
				if (disabled) {
					return;
				}
				// needs to be tied directly to click event
				if (clips.length) {
					clips[0].editingCanvas.playAudio();
				}
				dispatch(resetNotifications());
				compileUncompiledPreviews();
				navigateCC('details', {
					qs: {
						start: true,
					},
				});
			},
		});
	}

	if (camera || tray) {
		buttons.push({
			icon: 'CameraRotate',
			disabled: !hasPermissions,
			style: { top: show ? 48 : 0 },
			onClick: () => {
				if (!hasPermissions) {
					return;
				}
				dispatch(setFacingMode(facingMode === 'user' ? 'environment' : 'user'));
			},
		}, {
			icon: flash.flashIcon,
			disabled: !hasPermissions,
			style: { top: show ? 48 : 0 },
			filter: 'drop-shadow(0px 2px 4px rgba(50, 56, 62, 0.25))',
			onClick: () => {
				if (!hasPermissions) {
					return;
				}
				dispatch(setFlash(flash.next));
			},
		});
	}

	return (
		<>
			<FixedTopControls
				buttons={buttons}
			/>
		</>
	);
};
