import { createFormSlice } from 'services/slice';
import emailValidator from 'validators/email';

export const forgotPasswordFormSlice = createFormSlice({
	name: 'forgotPasswordFormSlice',
	initialState: {
		email: '',
	},
	validators: {
		email: emailValidator,
	},
});

const {
	// slice
	control, reducer,
	// actions
	resetErrors, resetForm, setErrors, setFormField,
	// selectors
	getFormField, getFormErrors, getFormFieldError, getFormPayload,
} = forgotPasswordFormSlice;

export {
	control, reducer,
	resetErrors, resetForm, setErrors, setFormField,
	getFormField, getFormErrors, getFormFieldError, getFormPayload,
};

export default reducer;
