import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	},
	alert: {
		color: theme.colors.neutral.C000,
		background: theme.colors.error.C700,
		paddingTop: theme.spacing(),
		paddingBottom: theme.spacing(),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		borderRadius: 2,
	},

}));

export default ({ errors }) => {
	const classes = useStyles();

	if (!errors.length) {
		return null;
	}

	return (
		<div className={classes.root}>
			{errors.map((e, i) => <Typography key={i} variant="caption" className={classes.alert}>{e.text}</Typography>)}
		</div>
	);
};
