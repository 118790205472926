export default (el) => {
	const parts = el.style.transform.split(' ');
	let scale;

	for (let i = 0; i < parts.length; i++) {
		if (parts[i].slice(0, 5) === 'scale') {
			scale = parseFloat(parts[i].slice(6, -1));
		}
	}

	return scale || 1;
};
