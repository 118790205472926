import createElement from '../../utils/create-element';
import { VIDEO } from '../../types';
import BaseNode from './base';

export default class VideoNode extends BaseNode {
	constructor(canvas, item) {
		super(canvas, item);

		this.type = VIDEO;

		const {
			asset,
			container,
			start,
			end,
			blob,
			el,
			ext,
		} = item.data;

		this.ext = ext || 'mp4';

		if (container) {
			this.containerDiv = createElement({ tagName: 'div' });
			Object.keys(container).forEach((key) => {
				this.containerDiv.style[key] = container[key];
			});
		}

		if (el) {
			this.el = el;
			this.setElAttributes();
			this.el.style.position = 'absolute';
			this.el.style.zIndex = item.z;

			if (this.containerDiv) {
				this.containerDiv.appendChild(this.el);
			}
			return;
		}

		this.el = createElement({
			attributes: {
				autoplay: true,
				loop: true,
				muted: true,
				playsinline: true,
			},
			tagName: 'video',
		});
		this.setElAttributes();
		this.el.muted = true;
		this.el.style.position = 'absolute';
		this.el.style.zIndex = item.z;

		this.end = end;
		this.start = start || 0;

		const source = document.createElement('source');

		source.src = asset
			? asset.url
			: window.URL.createObjectURL(blob);

		const sourceTypeBlob = asset ? asset.blob : blob;
		source.type = sourceTypeBlob.type.slice(0, 10) === 'video/webm'
			? 'video/webm'
			: 'video/mp4';
		this.el.append(source);
		Object.keys(this.styles).forEach((k) => {
			this.el.style[k] = this.styles[k];
		});

		if (end) {
			this.el.currentTime = start;
			this.el.onloadedmetadata = () => {
				this.el.currentTime = start;
			};
			this.el.ontimeupdate = () => {
				if (end && this.el.currentTime > end) {
					this.el.currentTime = start;
				}
			};
		}

		this.setAttributes(this.el);
		if (this.containerDiv) {
			this.containerDiv.appendChild(this.el);
		}
	}

	get dto() {
		return this.data.blob;
	}

	restart() {
		this.el.currentTime = this.start;
		this.el.play().catch(() => { });
	}

	pauseAudio() {
		this.el.muted = true;
	}

	playAudio(currentTime) {
		this.el.muted = false;
		if (typeof currentTime !== 'undefined') {
			this.el.currentTime = currentTime;
		}
		this.el.play().catch(() => { });
	}

	destroy() {
		this.compileContext?.el.remove();
		this.el.pause();
		this.el.remove();
	}

	drawFrame() {
		if (this.drawn) {
			return;
		}
		this.el.setAttribute('data-object-id', this.id);
		this.canvas.container.appendChild(this.containerDiv || this.el);
		this.drawn = true;
	}

	draw() {
		this.canvas.container.appendChild(
			this.containerDiv || this.el,
		);
	}

	renderFrame(t, multiplier = 1) {
		const { context, el, shadowContainer } = this.compileContext;

		const scale = this.getScale(el);
		const angle = this.getRotation(el);
		const rads = angle * (Math.PI / 180);
		const size = {};
		const position = {};

		el.style.removeProperty('transform');

		const rect = el.getBoundingClientRect();
		el.style.transform = `rotate(${angle}deg)`;
		const containerRect = shadowContainer.getBoundingClientRect();

		// true center
		const centerPercent = {
			x: (rect.x + (rect.width / 2)) / containerRect.width,
			y: (rect.y + (rect.height / 2)) / containerRect.height,
		};

		// center point
		const center = {
			x: (containerRect.width * multiplier) * centerPercent.x,
			y: (containerRect.height * multiplier) * centerPercent.y,
		};

		// find the top left
		const topLeftCorner = {
			x: center.x - ((rect.width * scale * multiplier) / 2),
			y: center.y - ((rect.height * scale * multiplier) / 2),
		};

		size.height = Math.round(rect.height * multiplier * scale);
		size.width = Math.round(rect.width * multiplier * scale);
		position.x = Math.round(topLeftCorner.x);
		position.y = Math.round(topLeftCorner.y);

		context.save();
		context.translate(position.x, position.y);
		context.translate(size.width / 2, size.height / 2);
		context.rotate(rads);

		if (this.el.getAttribute('data-mirror') === 'true') {
			context.scale(-1, 1);
		}

		context.drawImage(el,
			(-size.width / 2),
			(-size.height / 2),
			size.width,
			size.height);
		context.restore();
	}

	async setupCompile(canvas, context, shadowContainer, start, end, preview) {
		const el = this.el.cloneNode(true);
		el.style.visibility = 'hidden';

		el.muted = true;
		el.volume = 0;

		el.play().catch(() => { });
		el.setAttribute('playsinline', true);
		el.currentTime = start || this.start;

		this.compileContext = {
			canvas,
			context,
			el,
			shadowContainer,
		};

		shadowContainer.appendChild(el);
		return new Promise((resolve, reject) => {
			el.onerror = reject;
			el.onloadedmetadata = () => {
				if (!preview) {
					resolve(el);
				} else {
					el.ontimeupdate = () => {
						resolve();
						el.pause();
					};
					el.currentTime = this.start;
					el.play().catch(() => { });
				}
			};
		});
	}
}
