import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import {
	selectClips,
	enablePlusElement,
	hideClipTray,
	placeClipTray,
	disablePlusElement,
	scrollToEnd,
	CLIPS_THRESHOLD,
} from 'app/slices/content-creation/experience';
import {
	selectScrollPosition,
	change,
} from 'app/slices/content-creation/editor/layout';
import useNavigateCC from 'app/hooks/useNavigateCC';

const useStyles = makeStyles((theme) => ({
	root: {
		height: 164,
		width: '100%',
		position: 'relative',
		overflowX: 'scroll',
		overflowY: 'hidden',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		transition: 'all 100ms',
	},
	headerText: {
		marginTop: theme.spacing(12),
		color: theme.colors.neutral.C300,
		textAlign: 'center',
	},
	error: {
		color: theme.colors.error.C300,
	},
}));

export default ({ params, needMedia }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const scrollPosition = useSelector(selectScrollPosition);
	const navigateCC = useNavigateCC();
	const ref = useRef();
	const clips = useSelector(selectClips);
	const qs = params.qs || {};
	const { start } = qs;
	const [ignoreNext, setIgnoreNext] = useState(false);

	useEffect(() => {
		if (ref.current) {
			placeClipTray(ref.current);

			if (start) {
				navigateCC('details');
				setIgnoreNext(true);
			} else if (!ignoreNext) {
				scrollToEnd();
			}
		}

		return () => {
			hideClipTray();
		};
	}, [ref, start]);

	useEffect(() => {
		const handleScroll = () => {
			dispatch(change({ position: ref.current.scrollLeft }));
		};

		if (ref.current) {
			ref.current.scrollLeft = scrollPosition;
			ref.current.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener('scroll', handleScroll);
			}
		};
	}, [scrollPosition, dispatch]);

	useEffect(() => {
		if (clips.length >= CLIPS_THRESHOLD) {
			disablePlusElement();
		} else {
			enablePlusElement();
		}
	}, [ref, clips]);

	const adjustment = 48 + (clips.length * 48);
	const paddingLeft = `calc(50vw - ${adjustment}px)`;

	return (
		<div>
			<Typography
				className={needMedia ? clsx(classes.headerText, classes.error) : classes.headerText}
				variant="body1"
			>
				{clips.length ? 'Tap to edit' : 'Add media'}
			</Typography>
			<div className={classes.root} ref={ref} style={{ paddingLeft }} />
		</div>
	);
};
