import React from 'react';
import { makeStyles } from '@material-ui/core';
import WaveSkeleton from 'components/WaveSkeleton';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

});

export default ({ ...props }) => {
	const classes = useStyles();
	return (
		<div
			className={classes.root}
			{...props}
		>
			<WaveSkeleton
				style={{
					height: 16,
					width: 188,
					marginTop: 48,
				}}
			/>
		</div>
	);
};
