import React from 'react';
import wordmark from './wordmark.png';

const LogoWordmark = ({ width = 112 }) => (
	<img
		width={width}
		src={wordmark}
		alt="Rhino logo"
	/>
);

export default LogoWordmark;
