import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import HRNumbers from 'human-readable-numbers';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginRight: theme.spacing(0.5),
		marginLeft: theme.spacing(0.5),
		userSelect: 'none',
	},
	count: {
		display: 'inline',
		fontWeight: 600,
		marginRight: theme.spacing(),
	},
	label: {
		display: 'inline',
		color: theme.colors.neutral.C300,
		width: theme.spacing(20),
		textAlign: 'center',
	},
}));

export default ({
	stat,
	label,
	onClick,
}) => {
	const classes = useStyles();
	const string = stat ? HRNumbers.toHumanString(stat.robot || 0) : '-';
	return (
		<div className={classes.root} onClick={onClick}>
			<Typography variant="body1" className={classes.count}>
				{string}
				{' '}
			</Typography>
			<Typography variant="body1" className={classes.label}>{label}</Typography>
		</div>
	);
};
