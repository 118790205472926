import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestPermissions, getGateway, disableGateway } from 'app/slices/content-creation/create/device-library/state';
import { getPort } from 'app/android-service';
import { gotoPermissions } from 'app/android-service/util';
import useNavigateCC from 'app/hooks/useNavigateCC';

const PermissionsGate = () => {
	const dispatch = useDispatch();
	const navigateCC = useNavigateCC();
	const gateway = useSelector(getGateway);

	useEffect(async () => {
		// this is an unintentional redirect
		if (!gateway) {
			navigateCC('create', {
				tab: 'photo',
			});
			return;
		}

		// not supported
		if (getPort() === null) {
			navigateCC('create', {
				tab: 'photo',
			});
			dispatch(disableGateway());
			return;
		}

		// refresh permissions
		const response = await dispatch(requestPermissions());

		// if proceed, go to library
		if (response.payload) {
			navigateCC('create', {
				subTab: 'library',
			});
			dispatch(disableGateway());
		} else {
			gotoPermissions();
		}
	}, [gateway]);

	return null;
};

export default PermissionsGate;
