import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import LRButton from './LRButton';

const useStyles = makeStyles({
	button: {
		position: 'fixed',
		paddingTop: 45,
	},
	left: {
		left: 0,
	},
	right: {
		right: 0,
	},
});

export default ({
	left,
	right,
}) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const goto = (to) => () => {
		navigate(to);
	};

	return (
		<>
			<div className={clsx(classes.left, classes.button)}>
				{left ? <LRButton direction="Left" onClick={goto(left)} /> : null}
			</div>
			<div className={clsx(classes.right, classes.button)}>
				{right ? <LRButton direction="Right" onClick={goto(right)} /> : null}
			</div>
		</>
	);
};
