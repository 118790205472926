import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import {
	getTrustedCreators, getShouldReload, getErrors,
} from 'app/slices/destination-account-v2/trusted-experiences/selectors';
import { requestTrustedCreators } from 'app/slices/destination-account-v2/trusted-experiences/thunks';
import { useQueryParam } from 'app/hooks/useQueryParams';
import { getVisibleExperience, setOpenDirective } from 'app/slices/experience-modal';
import { useParams } from 'react-router-dom';
import EachTrustedCreator from './EachTrustedCreator';
import ErrorComponent from './ErrorComponent';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(6),
		paddingLeft: theme.spacing(3),
	},
	errorRoot: {
		paddingRight: theme.spacing(3),
	},
	container: {
		width: '100%',
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		display: 'flex',
		marginTop: theme.spacing(3),
	},
	title: {
		color: theme.colors.neutral.C400,
	},
	user: {
		display: 'flex',
		width: 68,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	userImage: {
		marginRight: theme.spacing(3),
		marginLeft: theme.spacing(3),
		borderRadius: '50%',
		width: 70,
		height: 70,

	},
	name: {
		width: 68,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		marginTop: theme.spacing(),
		textOverflow: 'ellipsis',
		textAlign: 'center',
	},
	notFound: {
		position: 'fixed',
		left: theme.spacing(4),
		bottom: theme.spacing(4),
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const trustedCreators = useSelector(getTrustedCreators);
	const shouldReload = useSelector(getShouldReload);
	const error = useSelector(getErrors);
	const visibleExperience = useSelector(getVisibleExperience);
	const trustedCreator = useQueryParam('trustedCreator');
	const { uid } = useParams();

	useEffect(() => {
		if (shouldReload) {
			dispatch(requestTrustedCreators(uid));
		}
	}, [shouldReload]);

	useEffect(() => {
		if (visibleExperience && visibleExperience.uid && trustedCreator) {
			const { uuid } = visibleExperience.user;
			const cUuid = `trusted-creator-uuid-${uuid}`;
			const querySelector = `[data-experience-modal-id="${cUuid}"]`;

			dispatch(setOpenDirective({
				querySelector,
			}));
		}
	}, [visibleExperience, trustedCreator]);

	if (!trustedCreators?.length && !error) {
		return null;
	}

	return (
		<div className={error ? clsx(classes.root, classes.errorRoot) : classes.root}>
			<Typography
				className={classes.title}
				variant="body2"
			>
				Experiences by people you follow
			</Typography>
			{error ? (
				<ErrorComponent
					show={error}
					title="Unable to load list."
					onRetry={() => { dispatch(requestTrustedCreators(uid)); }}
					size="small"
				/>
			)
				: (
					<div className={classes.container}>
						{trustedCreators && trustedCreators.map((u, i) => (
							<EachTrustedCreator key={i} creator={u} />
						))}
					</div>
				)}
		</div>
	);
};
