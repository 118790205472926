import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getRoute } from 'app/slices/experience-view';
import { getTransitioned } from 'app/slices/experience-modal';
import ExperienceView from './ExperienceView/ExperienceView';
import Panel from './Panel';
import EditExperience from './ExperienceView/OverflowMenus/EditExperience';

const useStyles = makeStyles(({
	pane: {
		position: 'relative',
		display: 'inline-block',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
	},
	contents: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		background: 'black',
	},
	editExperience: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		top: 0,
		height: '100%',
		width: '100%',
		zIndex: 13,
	},
}));

export default ({
	active,
	errors,
	onClose,
	panel,
	isShown,
	...props
}) => {
	const classes = useStyles();
	const route = useSelector(getRoute);
	const transitioned = useSelector(getTransitioned);
	window.oncontextmenu = () => false;

	if (!isShown && !transitioned) {
		return (<div className={classes.pane} data-pane data-uid={props.uid} />);
	}

	return (
		<div className={classes.pane} data-pane data-uid={props.uid}>
			<div className={classes.contents}>
				<ExperienceView
					active={active}
					errors={errors}
					panelOpen={panel.overlay}
					{...props}
				/>
			</div>
			<Panel active={active} onClose={onClose} panel={panel} {...props} />
			{route === 'editExperience' && active && (
				<div className={classes.editExperience} data-ignoreslider="true">
					<EditExperience {...props} />
				</div>
			)}
		</div>
	);
};
