import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CancelAlert from 'rds/components/CancelAlert';
import { unsetCaptured } from 'app/slices/content-creation/experience';
import useNavigateCC from 'app/hooks/useNavigateCC';
import { change } from 'app/slices/content-creation/editor/layout';
import { reset as resetNotifications } from 'app/slices/notifications/notifications';
import { reset as resetTextComponentSlice } from 'app/slices/content-creation/create/text-component';
import resetContentCreationSlices from './util/reset-content-creation-slices';

const ExitGateway = ({ clipCount }) => {
	const dispatch = useDispatch();
	const navigateCC = useNavigateCC();

	const exitCallback = () => {
		dispatch(change({ trayRoute: null }));
		dispatch(resetNotifications());
		dispatch(resetTextComponentSlice(['background']));
		dispatch(unsetCaptured());
		resetContentCreationSlices();
		navigateCC();
	};

	useEffect(() => {
		if (!clipCount) {
			navigateCC();
		}
	}, [clipCount]);

	return (
		clipCount && (
			<CancelAlert
				open
				title="Are you sure?"
				subtitle="By exiting, your changes will not be saved."
				leftButton="Stay"
				rightButton="Exit"
				onClick={exitCallback}
				onClose={async () => {
					navigateCC.stay();
				}}
			/>
		)
	);
};

export default ExitGateway;
