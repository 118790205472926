/**
 * Debug class
 *
 * debug class to track draw & compile data
 * - frames per second
 * - framedrops
 * - canvas dimensions
 * - time running
 * - # of active nodes, accessor to each node
 * - list of bad nodes
 *	 * error state
 *	 * data
 * - submit data as a report
 *
 * @param {object} canvas
 */
export default class Debug {
	constructor(canvas) {
		this.canvas = canvas;
	}

	get data() {
		const { height, width } = this.canvas.container.getBoundingClientRect();
		const nodes = {};
		const badNodes = [];

		for (let i = 0; i < this.canvas.nodes.length; i++) {
			const node = this.canvas.nodes[i];
			const { type } = node;
			if (!nodes[type]) {
				nodes[type] = {
					count: 0,
					nodes: [],
				};
			}
			nodes[type].count++;
			nodes[type].nodes.push(node);

			if (node.bad) {
				badNodes.push(node);
			}
		}

		return {
			badNodes,
			compileContext: this.canvas.compileContext,
			dimensions: { height, width },
			drawContext: this.canvas.drawContext,
			fps: this.canvas.options.fps,
			nodes,
		};
	}
}
