import EventEmitter from 'events';

class Timer extends EventEmitter {
	constructor({
		duration,
		time = 0,
		onload,
		isLoaded,
	}) {
		super();
		this.currentTime = time;
		this.paused = true;
		this.duration = duration || 0;
		this._lastTimeupdate = 0;
		this._loaded = isLoaded();

		onload(() => {
			this._loaded = true;
			this._onload && this._onload();
		});
	}

	play() {
		if (!this._loaded) {
			this._onload = () => {
				this._onload = null;
				this.play();
			};
			return;
		}

		this.paused = false;
		this.emit('play');

		this.tick(window.performance.now() - this.currentTime);
	}

	sendThrottledTimeupdate() {
		if (window.performance.now() - this._lastTimeupdate > 85) {
			this.emit('timeupdate', this.currentTime);
			this._lastTimeupdate = window.performance.now();
		}
	}

	pause() {
		if (this.paused) {
			return;
		}
		this.paused = true;
		this.emit('pause');
	}

	destroy() {
		this._destroyed = true;
		this.paused = true;
		this.removeAllListeners('timeupdate');
		this.removeAllListeners('end');
		this.currentTime = 0;
		this.duration = 0;
	}

	tick(start) {
		requestAnimationFrame(() => {
			if (this._destroyed) {
				return;
			}

			if (this.paused) {
				return;
			}

			const next = window.performance.now() - start;
			this.currentTime = Math.min(this.duration, next);

			this.sendThrottledTimeupdate();
			if (this.currentTime < this.duration) {
				this.tick(start);
			} else {
				this.emit('end');
			}
		});
	}
}

export default Timer;
