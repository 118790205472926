import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useQueryParam } from 'app/hooks/useQueryParams';
import neutral from 'rds/colors/neutral';
import Map from './Map';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		textAlign: 'left',
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
	questionnaire: {
		marginBottom: theme.spacing(5),
	},
}));

export default () => {
	const classes = useStyles();

	const regionKey = useQueryParam('region');
	const k = parseInt(regionKey, 10) || 0;

	return (
		<div className={classes.root}>
			<Typography className={classes.questionnaire} variant="body2" style={{ color: `${neutral.C400}` }}>Select all that apply</Typography>
			<Map
				region={k}
			/>
		</div>
	);
};
