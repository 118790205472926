import React from 'react';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';

/**
 * This component needs to position each control in a fixed spot
 * user's need to interact with the interface beneath the controls
 * so each control should have miminal container width
 */

// the layouts should by dynamically based on the buttons
// 1 control should always start in top left
// 2 in top left top right
// 3 top left, top right, center
// 4 top left, top right, center, center
const layouts = [
	[[0, undefined, undefined, 0]],
	[[0, undefined, undefined, 0], [0, 0, undefined, undefined]],
	[[0, undefined, undefined, 0], [0, 0, undefined, undefined], [0, undefined, undefined, 'calc(50vw - 24px)']],
	[[0, undefined, undefined, 0], [0, 0, undefined, undefined], [0, undefined, undefined, 'calc(50vw - 48px)'], [0, undefined, undefined, 'calc(50vw)']],
];

export default ({
	buttons,
}) => {
	const layout = layouts[buttons.length - 1];
	const getPosition = (index, position) => {
		if (!layout[index]) {
			return null;
		}

		const v = layout[index][position];
		if (typeof v === 'undefined') {
			return undefined;
		}

		return typeof v !== 'number' ? v : `${v}px`;
	};

	return (
		<div>
			{buttons.map(({
				style = {},
				variant = 'darkContrast',
				containerSize = 52,
				width = 16,
				height = 16,
				disabled,
				icon,
				text,
				...b
			}, i) => {
				if (!icon) {
					return (
						<Typography
							key={i}
							variant="subtitle2"
							style={{
								opacity: disabled ? 1 : 0.7,
								zIndex: 2140000008,
								padding: 16,
								position: 'absolute',
								top: getPosition(i, 0),
								right: getPosition(i, 1),
								bottom: getPosition(i, 2),
								left: getPosition(i, 3),
								filter: 'drop-shadow(0px 2px 4px rgba(50, 56, 62, 0.25))',
								...style,
							}}
							{...b}
						>
							{text}
						</Typography>
					);
				}

				return (
					<IconV3
						key={i}
						icon={icon}
						style={{
							position: 'fixed',
							zIndex: 3,
							top: getPosition(i, 0),
							right: getPosition(i, 1),
							bottom: getPosition(i, 2),
							left: getPosition(i, 3),
							filter: 'drop-shadow(0px 2px 4px rgba(50, 56, 62, 0.25))',
							...style,
						}}
						containerSize={containerSize}
						variant={variant}
						height={height}
						width={width}
						{...b}
					/>
				);
			})}
		</div>
	);
};
