/* eslint-disable no-useless-catch */
import { registerRoute } from 'Router/registry';
import DestinationAccount from 'routes/DestinationAccount/Page';
import DestinationHeader from 'routes/DestinationAccount/Header/TopHeader';
import Skeleton from 'rds/components/Skeletons/SecondaryPage';
import daEcr from 'app/slices/destination-account-v2/experiences-ecr';
import { register } from 'features/ExperienceModal/ECRProvider';
import store from 'services/store';
import { requestProfile } from 'app/slices/user-account-v2/accounts/thunks';
import { requestDestinationAccount } from 'app/slices/destination-account-v2/accounts/thunks';
import { requestTrustedCreators } from 'app/slices/destination-account-v2/trusted-experiences/thunks';
import { requestAllExperiencesByDestination } from 'app/slices/destination-account-v2/experiences/thunks';
import { requestDestinationByCode } from 'app/slices/destination-by-share';
import DestinationNotFound from './DestinationNotFound';
import ByCode from './Page/ByCode';

register('da', daEcr);

let isExecuting = false;

const daRequiredData = [
	async ({ dispatch, params }) => {
		const { uid } = params;

		if (isExecuting) {
			return null;
		}

		isExecuting = true;

		try {
			const state = store.getState();
			const { accounts } = state.destinationAccounts;
			// @JORDAN WHAT IS THIS HAN HAN HAN ?
			if (!state.userAccountV2Accounts.myAccount) {
				await dispatch(requestProfile({ username: 'my-account' }));
			}

			const accountExists = accounts.hasOwnProperty(uid);

			let result = null;

			if (!accountExists) {
				result = await dispatch(requestDestinationAccount(uid));
				await dispatch(requestAllExperiencesByDestination(uid));
				await dispatch(requestTrustedCreators(uid));
			}

			return Promise.resolve(result);
		} catch (error) {
			throw error;
		} finally {
			isExecuting = false;
		}
	},
];

registerRoute({
	authorization: true,
	Component: DestinationAccount,
	Skeleton,
	Header: DestinationHeader,
	loadData: daRequiredData,
	ecrSelectorName: 'da',
	routeOptions: {
		path: '/destination/:uid',
		exact: true,
	},
	errors: {
		Rejected: DestinationNotFound,
	},
	transition: 'slide',
	navigationMenu: true,
});

registerRoute({
	authorization: true,
	Component: ByCode,
	Skeleton,
	Header: DestinationHeader,
	loadData: [
		async ({ dispatch, params }) => {
			const parts = params.params.split(/-/g);
			const code = parts[parts.length - 1];
			await dispatch(requestDestinationByCode({ code }));
		},
	],
	ecrSelectorName: 'da',
	routeOptions: {
		path: '/d/:params',
		exact: true,
	},
	errors: {
		Rejected: DestinationNotFound,
	},
	transition: 'slide',
	navigationMenu: true,
});
