import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, makeStyles, useTheme } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import Avatar from 'rds/components/Avatar';
import { getSession } from 'app/slices/authentication/session/session';
import { getIsMe } from 'app/slices/user-account-v2/accounts';
import { requestFollowUnFollowTheirFollowings } from 'app/slices/user-account-v2/following-actions';
import useUserAccountLink from 'app/hooks/useUserAccountLink';

const useStyles = makeStyles((theme) => ({
	name: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		width: 120,
		textOverflow: 'ellipsis',
		color: theme.colors.neutral.C400,
	},
	info: {
		display: 'flex',
		flexDirection: 'row',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: theme.spacing(3),
		justifyContent: 'space-evenly',
		textAlign: 'left',
	},
	actions: {
		width: 86,
	},
	button: {
		height: 36,
		width: 86,
		backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
			color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		},
	},
}));

export default ({
	username,
	avatar,
	displayName,
	uuid,
	interactions,
	onClose,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const session = useSelector(getSession);
	const isMe = useSelector((state) => getIsMe(state, username)) || false;

	const FollowingStyle = {
		background: 'none',
		color: theme.colors.neutral.C000,
		border: `1px solid ${theme.colors.neutral.C000}`,
	};

	const to =	useUserAccountLink(username);

	const user = {
		username,
		avatar,
		displayName,
		uuid,
		interactions,
	};

	return (
		<>
			<div
				className={classes.info}
				onClick={() => {
					navigate(to);
					onClose();
				}}
			>
				<Avatar size="small" alt={username} src={avatar?.small?.jpg} />
				<div className={classes.details}>
					<Typography variant="body1">{username}</Typography>
					<Typography className={classes.name} variant="caption">{displayName}</Typography>
				</div>
			</div>
			{username !== session.username
			&& (
				<div className={classes.actions}>
					<Button
						variant="contained"
						disableRipple
						fullWidth
						style={interactions?.callerIsFollowing ? FollowingStyle : null}
						disableFocusRipple
						size="small"
						className={classes.button}
						onClick={() => {
							if (interactions?.callerIsFollowing) {
								dispatch(requestFollowUnFollowTheirFollowings(
									{
										type: 'unfollow',
										isMe,
										user,
									},
								));
							} else if ((!interactions?.callerIsFollowing)) {
								dispatch(requestFollowUnFollowTheirFollowings(
									{
										type: 'follow',
										isMe,
										user,
									},
								));
							}
						}}
					>
						{interactions?.callerIsFollowing ? 'Following' : 'Follow' }
					</Button>
				</div>
			)}
		</>
	);
};
