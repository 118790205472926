import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import api from 'services/api';
import Recommendation from './Recommendation';

const DEBOUNCE = 200;

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		top: -77,
		left: 0,
		right: 0,
		paddingLeft: theme.spacing(),
		paddingRight: theme.spacing(),
		overflowX: 'scroll',
		whiteSpace: 'nowrap',
		height: 77,
		background: 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
			width: 0,
		},
	},
	active: {
		background: theme.palette.background.page,
	},
}));

export default ({ match, onClick }) => {
	const classes = useStyles();
	const [recommendations, setRecommendations] = useState([]);

	useEffect(() => {
		let to;
		let ignore = false;

		if (match?.word) {
			to = setTimeout(async () => {
				const response = await api.get(`content/v0/users/search?q=${match.word}`);
				if (!ignore) {
					setRecommendations(response.data.entities);
				}
			}, DEBOUNCE);
		} else {
			setRecommendations([]);
		}

		return () => {
			clearTimeout(to);
			ignore = true;
		};
	}, [match]);

	const klass = recommendations.length
		?	clsx(classes.container, classes.active)
		: classes.container;

	const style = {};
	if (!recommendations.length) {
		style.visibility = 'hidden';
	}

	return (
		<div className={klass} style={style}>
			{recommendations.map((r) => (
				<Recommendation
					key={r.entity.uuid}
					{...r.entity}
					onClick={() => onClick({ match, entity: r.entity })}
				/>
			))}
		</div>
	);
};
