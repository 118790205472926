import {
	createAsyncThunk,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import store, { injectReducer } from 'services/store';
import api from 'services/api';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import ecCompose from 'utils/editing-canvas-compose';
import compileAllEditingCanvases from 'lib/editing-canvas-compile';
import neutral from 'rds/colors/neutral';
import handleSliceError from 'utils/handle-slice-error';
import createImageObjectFromBlob from 'lib/asset-manager/create-image-object-from-blob';
import { notifyErrors, notify } from 'app/slices/notifications/notifications';
import { navigateCC } from '../navigation';
import {
	add as addToTray,
	destroy as destroyTray,
	show as trayShowTray,
	hide as trayHideTray,
	place as placeClipTray,
	setFns as traySetFns,
	disable as trayDisable,
	enable as trayEnable,
	disablePlusElement,
	enablePlusElement,
	scrollToEnd,
} from './tray';

export const CLIPS_THRESHOLD = 10;
export const DURATION_THRESHOLD = 15000;

const clipsMap = {};
let mapId = 0;

const getStyles = ({
	assetDimensions,
}) => {
	const { height, width } = assetDimensions;
	const deviceHeight = window.innerHeight;
	const deviceWidth = window.innerWidth;
	const s = {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
		'object-fit': 'cover',
	};

	const aAr = height / width;
	const rAr = deviceHeight / deviceWidth;

	if (aAr > rAr) {
		s.height = '100%';
		s.width = deviceHeight * (width / height);
		s.left = `${(deviceWidth - s.width) / 2}px`;
		s.width = `${s.width}px`;
	} else {
		s.width = '100%';
		s.height = deviceWidth * (height / width);
		s.top = `${(deviceHeight - s.height) / 2}px`;
		s.height = `${s.height}px`;
	}

	return s;
};

const createBackgroundNode = async (type, data, editingCanvas) => {
	let bg;

	const blob = data.asset ? data.asset.blob : data.blob;

	// TODO: use the comment to support dynamic video backgrounds
	if (type === 'video') {
		const bgImageBlob = await data.asset.getImage({
			backgroundColor: neutral.C200,
			timestamp: data.start,
		});

		bg = await editingCanvas.add('image', {
			blob: bgImageBlob,
			background: true,
		}, {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		});
	} else if (type === 'image') {
		const bgImageBlob = await createImageObjectFromBlob({
			blob,
			backgroundColor: neutral.C200,
		});

		bg = await editingCanvas.add('image', {
			blob: bgImageBlob,
			background: true,
		}, {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		});
	} else {
		const bgData = { ...data };

		bgData.container = {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: neutral.C900,
		};
		bgData.background = true;
		bg = await editingCanvas.add('video', bgData, {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			filter: 'blur(200px)',
			opacity: 0.8,
		});
	}
	bg.makeBackground();
	bg.lock();
	return bg;
};

export const requestAddClipPhoto = createAsyncThunk(
	'requestAddClip',
	async (payload, {
		dispatch,
		getState,
		rejectWithValue,
	}) => {
		const state = getState();

		const clipCount = selectClipCount(state);
		if (clipCount >= CLIPS_THRESHOLD) {
			return null;
		}

		const {
			assetDimensions,
			data,
			type,
			preview,
		} = payload;

		try {
			const editingCanvas = await ecCompose({
				preview,
			});
			editingCanvas.originalType = type;
			const overlay = await editingCanvas.add('html', {
				tagName: 'div',
			}, {
				backgroundColor: `${neutral.C900}70`,
				opacity: 0.489999,
				width: '100%',
				height: '100%',
				display: 'none',
			});
			editingCanvas.overlay = overlay;

			const styles = payload.styles || getStyles({
				container: editingCanvas.container,
				assetDimensions,
			});

			const runInBackground = async () => {
				if (!data.asset) {
					if (!data.blob) {
						throw new Error('attempting to create a node without an asset or blob');
					}
				}

				try {
					await createBackgroundNode(type, data, editingCanvas);
					const item = await editingCanvas.add(type, data, styles);
					item.makeRoot();
					await editingCanvas.compilePreview();
				} catch (_) {
					// editing canvas was probably removed, try to recompile anyway
					if (editingCanvas?.compilePreview) {
						editingCanvas.compilePreview();
					}
				}
			};
			runInBackground();

			const thisId = mapId++;
			clipsMap[thisId] = {
				container: editingCanvas.container, editingCanvas, muted: false, id: thisId,
			};
			addToTray({
				editingCanvas,
				id: thisId,
				type,
				onRemove: (id) => {
					dispatch(removeClip(id));
				},
			});

			// await goToEditor(dispatch);
			return thisId;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const addClipText = createAsyncThunk(
	'addClipText',
	async (payload, {
		dispatch,
	}) => {
		const {
			container,
			editingCanvas,
		} = payload;
		try {
			const thisId = mapId++;
			clipsMap[thisId] = {
				container, editingCanvas, muted: false, id: thisId,
			};
			await editingCanvas.compilePreview();
			addToTray({
				editingCanvas,
				id: thisId,
				type: 'text',
				onRemove: (id) => {
					dispatch(removeClip(id));
				},
			});

			// await goToEditor(dispatch);

			return thisId;
		} catch (error) {
			notifyErrors([{ text: 'Could not add the clip, Try again!' }]);
			return null;
		}
	},
);

export const requestUserIdentifiers = createAsyncThunk(
	'requestUserIdentifiers',
	async () => {
		try {
			const response = await api.get('accounts/profile/identifiers');
			return response.data;
		} catch (error) {
			return getErrorsFromRejectedRequest(error);
		}
	},
);

export const hideClipTray = createAsyncThunk(
	'hideClipTray',
	async (force = false, { getState }) => {
		const state = getState().contentCreationExperience;

		if (!force && state.clipTrayState.locked) {
			return false;
		}

		trayHideTray();
		return true;
	},
);

export const updatePreview = async (clip) => {
	if (clip.editingCanvas.dirty.preview) {
		await clip.editingCanvas.compilePreview();
	}
};

export const updatePreviews = () => {
	Object.keys(clipsMap).forEach((key) => {
		if (clipsMap[key].editingCanvas.dirty.preview) {
			clipsMap[key].editingCanvas.compilePreview();
		}
	});
};

export const compileUncompiledPreviews = async () => {
	Object.keys(clipsMap).forEach((key) => {
		const ec = clipsMap[key].editingCanvas;

		if (!ec.compiledPreview) {
			ec.compilePreview();
		}
	});
};

export const compileAll = async (clipIds) => {
	const orderedClips = [];

	clipIds.forEach((id) => {
		const clip = Object.values(clipsMap).find((c) => c.id === id);
		if (clip) {
			orderedClips.push(clip);
		}
	});

	const compiled = await compileAllEditingCanvases(
		orderedClips.map((a) => ({
			editingCanvas: a.editingCanvas,
			muted: a.muted,
		})),
	);

	return compiled;
};

export const showClipTray = createAsyncThunk(
	'showClipTray',
	async (options) => {
		updatePreviews();
		trayShowTray(options);
		return true;
	},
);

const reducerUpdateClipState = (state) => {
	const clipCount = selectClipCount(state);
	if (clipCount >= CLIPS_THRESHOLD) {
		state.clipTrayState.locked = true;
	} else {
		state.clipTrayState.locked = false;
	}
};

const reducerAddAnyClip = (state, action) => {
	const clipCount = selectClipCount(state);
	if (clipCount >= CLIPS_THRESHOLD) {
		state.errors = [{ value: 'can not make more than 10 clips' }];
		return;
	}

	state.clipIds.push(action.payload);
	reducerUpdateClipState(state);
};

const initialState = {
	clipIds: [],
	errors: null,
	loading: false,
	clipId: null,
	captured: false,
	showControls: true,
	currentClipIndex: 0,
	destination: null,
	showTrashCan: false,
	activeTrashCan: false,
	fullTrashCan: false,
	title: '',
	caption: '',
	clipTrayState: {
		show: false,
		lock: false,
	},
	activities: [],
	recommendedFor: [],
	showActivities: true,
	percentage: 0,
	closeCaptioning: false,
	collections: [],
	identifiers: [],
	isCancelAlertOpen: false,
	downloading: false,
	muted: false,
	lockCC: false,
};

export const experienceSlice = createSlice({
	name: 'contentCreationExperience',
	initialState: { ...initialState },

	reducers: {
		reset: (state) => {
			Object.keys(clipsMap).forEach((key) => {
				delete (clipsMap[key]);
			});
			state.clipIds = [];
			const props = Object.getOwnPropertyNames(clipsMap);
			for (let i = 0; i < props.length; i++) {
				delete clipsMap[props[i]];
			}
			destroyTray();
		},
		lockCC: (state) => {
			state.lockCC = true;
		},
		unlockCC: (state) => {
			state.lockCC = false;
		},
		resetAll: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		toggleControls: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		closeCancelAlert: (state) => {
			trayEnable();
			state.isCancelAlertOpen = false;
		},
		openCancelAlert: (state) => {
			trayDisable();
			state.isCancelAlertOpen = true;
		},
		captured: (state) => {
			state.captured = true;
		},
		unsetCaptured: (state) => {
			state.captured = false;
		},
		setClipOrder: (state, action) => {
			const { ids, selected } = action.payload;
			state.clipIds = ids.map((id) => Number(id));

			Object.keys(clipsMap).forEach((key) => {
				if (state.clipIds.indexOf(Number(key)) === -1) {
					delete clipsMap[key];
				}
			});

			const index = state.clipIds.indexOf(Number(selected));
			state.currentClipIndex = index !== -1 ? index : 0;
		},
		setClipId: (state, action) => {
			const index = state.clipIds.indexOf(Number(action.payload));
			state.currentClipIndex = index !== -1 ? index : 0;
		},
		setClip: (state, action) => {
			state.currentClipIndex = action.payload;
		},
		setPercentage: (state, action) => {
			state.percentage = action.payload;
		},
		removeClip: (state, action) => {
			const id = state.clipIds[action.payload];
			const index = state.clipIds.findIndex((i) => i === action.payload);
			const arr = state.clipIds.slice();
			arr.splice(index, 1);
			state.clipIds = arr;
			delete clipsMap[id];
			if (index === state.currentClipIndex) {
				state.currentClipIndex = 0;
			}

			reducerUpdateClipState(state);
		},
		setExperienceDestination: (state, action) => {
			state.destination = action.payload;
		},
		addActivities: (state, action) => {
			if (state.activities.length < 5) {
				state.activities = state.activities.concat(action.payload);
			}
		},
		removeActivities: (state, action) => {
			state.activities = state.activities.filter((activity) => activity.title
				!== action.payload.title);
		},
		showActivitySection: (state, action) => {
			state.showActivities = action.payload;
		},
		addTitle: (state, action) => {
			state.title = action.payload;
		},
		addCaption: (state, action) => {
			state.caption = action.payload;
		},
		addRecommendedFor: (state, action) => {
			state.recommendedFor = state.recommendedFor.concat(action.payload);
		},
		removeRecommendedFor: (state, action) => {
			state.recommendedFor = state.recommendedFor.filter((rm) => rm.title
				!== action.payload.title);
		},
		lockClipTray: (state) => {
			state.clipTrayState.locked = true;
		},
		unlockClipTray: (state) => {
			state.clipTrayState.locked = false;
		},
		addCollections: (state, action) => {
			if (!state.collections.find((item) => item.slug === action.payload.slug)) {
				state.collections.push(action.payload);
			}
		},
		removeCollections: (state, action) => {
			state.collections = state.collections.filter((rm) => rm.title
				!== action.payload.title);
		},
		toggleCloseCaptioning: (state) => {
			state.closeCaptioning = !state.closeCaptioning;
		},
		toggleClipSound: (state, action) => {
			const { index, muted } = action.payload;
			const clipid = state?.clipIds[index];
			clipsMap[clipid].muted = muted;
		},
	},

	extraReducers: {
		[requestAddClipPhoto.pending]: (state) => {
			state.lockCC = true;
			state.loading = true;
			state.errors = null;
		},
		[requestAddClipPhoto.fulfilled]: (state, action) => {
			state.lockCC = false;
			state.loading = false;
			if (typeof action.payload === 'number') {
				reducerAddAnyClip(state, action);
			}
		},
		[requestAddClipPhoto.rejected]: (state, action) => {
			state.lockCC = false;
			state.loading = false;
			state.errors = action.payload;
		},
		[addClipText.pending]: (state) => {
			state.lockCC = true;
		},
		[addClipText.fulfilled]: (state, action) => {
			state.lockCC = false;
			state.loading = false;
			if (typeof action.payload === 'number') {
				reducerAddAnyClip(state, action);
			}
		},
		[addClipText.rejected]: (state, action) => {
			state.lockCC = false;
			state.loading = false;
			state.errors = action.payload;
		},
		[requestUserIdentifiers.fulfilled]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestUserIdentifiers.fulfilled]: (state, action) => {
			if (action.payload) {
				state.identifiers = action.payload.identifiers;
				state.loading = false;
				state.errors = null;
			}
		},
		[requestUserIdentifiers.rejected]: (state, action) => {
			state.errors = action.payload;
			state.loading = false;
		},
		[showClipTray.fulfilled]: (state) => {
			state.clipTrayState.show = true;
		},
		[hideClipTray.fulfilled]: (state, action) => {
			if (action.payload) {
				state.clipTrayState.show = false;
				state.clipTrayState.locked = false;
			}
		},
	},
});

const { name, reducer, actions } = experienceSlice;
const {
	removeClip,
	setClip,
	setClipId,
	toggleControls,
	reset,
	resetAll,
	setExperienceDestination,
	addActivities,
	removeActivities,
	addCaption,
	addTitle,
	addRecommendedFor,
	removeRecommendedFor,
	showActivitySection,
	captured,
	unsetCaptured,
	setClipOrder,
	setPercentage,
	addCollections,
	removeCollections,
	toggleCloseCaptioning,
	lockCC,
	unlockCC,
	lockClipTray,
	unlockClipTray,
	closeCancelAlert,
	openCancelAlert,
	toggleClipSound,
} = actions;

export {
	removeClip,
	setClip,
	setClipId,
	toggleControls,
	reset,
	resetAll,
	setExperienceDestination,
	addActivities,
	removeActivities,
	addCaption,
	addTitle,
	addRecommendedFor,
	removeRecommendedFor,
	showActivitySection,
	captured,
	unsetCaptured,
	setClipOrder,
	setPercentage,
	addCollections,
	removeCollections,
	toggleCloseCaptioning,
	lockCC,
	unlockCC,
	lockClipTray,
	unlockClipTray,
	placeClipTray,
	disablePlusElement,
	enablePlusElement,
	scrollToEnd,
	closeCancelAlert,
	openCancelAlert,
	toggleClipSound,
};

traySetFns({
	change: (ids, selected) => {
		store.dispatch(setClipOrder(ids, selected));
	},
	close: () => {
		store.dispatch(hideClipTray());
	},
	select: (id) => {
		store.dispatch(navigateCC({
			path: 'editor',
			params: {
				id,
			},
		}));
	},
	add: () => {
		const state = store.getState();
		const clipCount = selectClipCount(state);
		if (clipCount >= CLIPS_THRESHOLD) {
			store.dispatch(notify({
				severity: 'info',
				title: 'Max 10 clips reached',
				description: 'Delete a clip to add new media',
			}));
		} else {
			store.dispatch(navigateCC({
				path: 'create',
				params: {
					tab: 'library',
					subTab: 'tray',
					qs: {
						details: true,
					},
				},
			}));
		}
	},
});

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const selectClipCount = createSelector(getSlice, (slice) => slice?.clipIds?.length);
export const selectClips = createSelector(getSlice, (slice) => slice?.clipIds.map((id) => {
	if (!clipsMap[id]) {
		return null;
	}

	const { container, editingCanvas, muted } = clipsMap[id];

	return {
		container,
		editingCanvas,
		muted,
		id,
	};
}));

export const selectClip = (index) => createSelector(getSlice, (slice) => {
	const id = slice?.clipIds[index];

	if (!clipsMap[id]) {
		return null;
	}

	const { container, editingCanvas, muted } = clipsMap[id];
	return {
		container, editingCanvas, muted, id,
	};
});

export const selectLockCC = createSelector(getSlice, (slice) => slice?.lockCC);
export const selectShowControls = createSelector(getSlice, (slice) => slice?.showControls);
export const selectCurrentClipIndex = createSelector(getSlice, (slice) => slice?.currentClipIndex);
export const selectExperienceDestination = createSelector(getSlice, (slice) => slice?.destination);
export const selectExperienceActivities = createSelector(getSlice, (slice) => slice?.activities);
export const selectRecommendedFor = createSelector(getSlice, (slice) => slice?.recommendedFor);
export const selectTitle = createSelector(getSlice, (slice) => slice?.title);
export const selectCaption = createSelector(getSlice, (slice) => slice?.caption);
export const selectShowActivities = createSelector(getSlice, (slice) => slice?.showActivities);
export const selectPercentage = createSelector(getSlice, (slice) => slice?.percentage);
export const selectCloseCaptioning = createSelector(getSlice, (slice) => slice?.closeCaptioning);
export const selectUserIdentifiers = createSelector(getSlice, (slice) => slice?.identifiers);
export const selectCollections = createSelector(getSlice, (slice) => slice?.collections);
export const selectClipTrayState = createSelector(getSlice, (slice) => slice?.clipTrayState);
export const selectIsCancelAlertOpen = createSelector(getSlice, (slice) => slice?.isCancelAlertOpen);
export const selectLoading = createSelector(getSlice, (slice) => slice?.loading);
export const selectErrors = createSelector(getSlice, (slice) => slice?.errors);
export const selectDownloading = createSelector(getSlice, (slice) => slice?.downloading);
export const selectIsVideoMuted = createSelector(getSlice, (slice) => slice?.muted);
export const selectCaptured = createSelector(getSlice, (slice) => slice?.captured);
injectReducer(name, reducer);
