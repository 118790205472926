import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useExpandECR from 'features/ExperienceModal/useExpandECR';
import { resetFilters } from 'app/slices/destination-account-v2/experiences/slice';

/**
 * Custom hook for generating ECR Expansion
 *
 * @param {string} id - The id associated with the experience modal
 * @param {string} src - The source URL
 * @param {string} type - experience or destination
 * @return {function} expandECR - The generated handle click function
 */
const useClick = (uid, src, type = 'experience') => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return type === 'experience'
		? useExpandECR(uid, src)
		: () => {
			dispatch(resetFilters(uid));
			navigate(`/destination/${uid}`);
		};
};

export default useClick;
