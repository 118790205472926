import { EventEmitter } from 'events';
import React, {
	createContext,
	useContext,
	useState,
	useMemo,
	useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PageStateContext = createContext();

export const usePageState = () => {
	const context = useContext(PageStateContext);

	if (!context) {
		throw new Error('usePageState must be used within a PageStateProvider');
	}

	return context;
};

export const PageStateProvider = ({ children }) => {
	const navigate = useNavigate();
	const [pageStates, setPageStates] = useState({});
	const [snapNavigating, setSnapNavigating] = useState(false);
	const location = useLocation();
	const eventEmitter = useMemo(() => new EventEmitter(), []);
	const currentKey = location.pathname;
	const snapNavigate = useCallback(async (to) => {
		setSnapNavigating(true);

		if (snapNavigating) {
			return;
		}
		await navigate(to);
		setTimeout(() => setSnapNavigating(false), 1000);
	}, [navigate, snapNavigating]);

	return (
		<PageStateContext.Provider value={{
			currentKey,
			eventEmitter,
			pageStates,
			pathname: location.pathname,
			setPageStates,
			setSnapNavigating,
			snapNavigating,
			snapNavigate,
		}}
		>
			{children}
		</PageStateContext.Provider>
	);
};
