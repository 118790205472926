import createElement from 'utils/create-element';

export const DELETE_ICON_CLASS = 'experience_tray_delete_icon';
export const DELETE_ICON_CONTAINER_CLASS = 'experience_tray_delete_icon_container';
export const OVERLAY_CLASS = 'experience_tray_dark_overlay';
export const ELEMENT_CLASS = 'experience_tray_element';
export const ELEMENT_IMAGE_CLASS = 'experience_tray_element_image';
export const TRAY_DELETE_CLASS = 'experience_tray_delete';
export const PLAY_ICON_CLASS = 'experience_tray_play';
export const PLUS_ICON_CLASS = 'experience_tray_plus';
export const MEDIA_ICON_CLASS = 'experience_tray_media';
export const ELEMENT_PLUS_CONTAINER_CLASS = 'experience_tray_plus_container';
const BASE_EL = createElement({ tagName: 'div' });
BASE_EL.classList.add(ELEMENT_CLASS);

const BASE_IMAGE = createElement({
	tagName: 'div',
	attributes: { 'data-sortable': true },
});
BASE_IMAGE.classList.add(ELEMENT_IMAGE_CLASS);

const DARK_OVERLAY = createElement({ tagName: 'div' });
DARK_OVERLAY.classList.add(OVERLAY_CLASS);

const DELETE = createElement({ tagName: 'div', attributes: { 'data-delete': true } });
DELETE.classList.add(TRAY_DELETE_CLASS);

const DELETE_ICON_CONTAINER = createElement({ tagName: 'div' });
DELETE_ICON_CONTAINER.classList.add(DELETE_ICON_CONTAINER_CLASS);

const DELETE_ICON = createElement({ tagName: 'div' });
DELETE_ICON.classList.add(DELETE_ICON_CLASS);

const PLAY_ICON = createElement({ tagName: 'div' });
PLAY_ICON.classList.add(PLAY_ICON_CLASS);

const PLUS_ICON = createElement({ tagName: 'div' });
PLUS_ICON.classList.add(PLUS_ICON_CLASS);

const MEDIA_ICON = createElement({ tagName: 'div' });
MEDIA_ICON.classList.add(MEDIA_ICON_CLASS);

export const trayCreatePlusElement = () => {
	const el = BASE_EL.cloneNode(true);
	const dao = DARK_OVERLAY.cloneNode(true);
	const plus = PLUS_ICON.cloneNode(true);
	const plusContainer = createElement({
		tagName: 'div',
		attributes: { 'data-sortable': true },
	});
	plusContainer.classList.add(ELEMENT_PLUS_CONTAINER_CLASS);
	el.style.display = 'flex';
	el.style.justifyContent = 'center';
	el.style.alignItems = 'center';
	el.setAttribute('data-type', 'add');
	el.appendChild(plusContainer);
	el.appendChild(dao);
	el.appendChild(plus);

	return el;
};

export default ({
	editingCanvas,
	id,
	type,
}) => {
	const el = BASE_EL.cloneNode(true);
	const image = BASE_IMAGE.cloneNode(true);
	const d = DELETE.cloneNode(true);
	const di = DELETE_ICON.cloneNode(true);
	const dao = DARK_OVERLAY.cloneNode(true);
	const dic = DELETE_ICON_CONTAINER.cloneNode(true);
	const play = PLAY_ICON.cloneNode(true);
	const media = MEDIA_ICON.cloneNode(true);

	dic.appendChild(di);
	d.appendChild(dic);
	el.appendChild(media);
	if (editingCanvas?.preview?.src) {
		el.classList.remove('experience_tray_empty');
		image.style.backgroundImage = `url(${editingCanvas.preview.src})`;
	} else {
		el.classList.add('experience_tray_empty');
	}

	editingCanvas.on('preview', (img, blob) => {
		// Its too small to be a valid preview
		if (blob.size > 0) {
			image.style.backgroundImage = `url(${img.src})`;
			el.classList.remove('experience_tray_empty');
		} else {
			image.style.backgroundImage = 'none';
			el.classList.add('experience_tray_empty');
		}
	});

	el.appendChild(image);
	el.appendChild(d);
	el.appendChild(dao);

	if (type === 'video') {
		el.appendChild(play);
	}

	dic.setAttribute('data-id', id);

	return {
		el,
		deleteButton: d,
	};
};
