import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core';
import {
	getSuggestedDestinations,
	requestSuggestedDestinations,
	getErrors,
	getLoading as getSuggestedDestinationsLoading,
} from 'app/slices/destinations-search/suggested-destinations.js';
import { requestPatchOnboarding } from 'app/slices/onboarding/onboarding';
import RequestProvider from 'rds/providers/Request';
import Typography from 'rds/components/Typography';
import PageSkeleton from 'rds/components/Skeletons/PrimaryPage';
import {
	followMultiDestinations,
	getMultiDestinations,
	follow,
} from 'app/slices/onboarding/follow';
import { getIsOnboardingCompleted } from 'app/slices/authentication/session/session';
import LoadingSkeleton from 'rds/components/LoadingSkeleton';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from 'app/hooks/useQueryParams';
import PageLayout from '../Layout/Page';
import DestinationsTray from './DestinationsTray';
import Search from '../../DestinationSearchModal/Search';
import SearchModal from '../../DestinationSearchModal/Search/SearchModal';
import Suggested from './Suggested';

const useStyles = makeStyles((theme) => ({
	header: {
		position: 'fixed',
		zIndex: 1,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top center',
		top: 0,
		left: 0,
		right: 0,
		paddingTop: theme.spacing(16),
		paddingBottom: theme.spacing(4),
	},
	headerContents: {
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const headerRef = useRef();
	const multiDestinationsId = useSelector(getMultiDestinations);
	const destinations = useSelector(getSuggestedDestinations);
	const loading = useSelector(getSuggestedDestinationsLoading);
	const bg = theme.palette.background.page;
	const isOnboardingCompleted = useSelector(getIsOnboardingCompleted);
	const isItRedirectedFromNotification = useQueryParam('notificationRedirect');

	useEffect(() => {
		if (isOnboardingCompleted && !isItRedirectedFromNotification) {
			navigate('/');
		}
	}, [isOnboardingCompleted]);

	const handleSelect = (d) => {
		dispatch(follow(d));
	};

	useEffect(() => {
		dispatch(requestSuggestedDestinations());
	}, []);

	return (
		<RequestProvider
			requests={{
				destinations: {
					selector: getSuggestedDestinations,
					action: requestSuggestedDestinations,
					loading: getSuggestedDestinationsLoading,
					errors: getErrors,
				},
			}}
			Skeleton={PageSkeleton}
		>
			<PageLayout
				header={(
					<div ref={headerRef} className={classes.header} style={{ background: bg }}>
						<div className={classes.headerContents}>
							<Typography variant="subtitle1">
								Which destinations are you interested in?
							</Typography>
							<Search />
						</div>
						<DestinationsTray />
						<div className={classes.headerContents}>
							{destinations && destinations.length !== 0 && (<Typography variant="subtitle2">Suggested by Rhino</Typography>)}
						</div>
					</div>
				)}
				top={{
					back: {
						label: 'Back',
						onClick: () => {
							navigate(-1);
						},
					},
					next: {
						label: 'Next',
						to: isItRedirectedFromNotification ? '/onboarding/regions?notificationRedirect=true' : '/onboarding/regions',
						onClick: () => {
							dispatch(requestPatchOnboarding({ destinations: true }));
							dispatch(followMultiDestinations(multiDestinationsId));
						},
					},
				}}
			>
				<SearchModal
					disableCustomDestination
					onSelect={handleSelect}
				/>
				{loading
					? <LoadingSkeleton lines={30} />

					: <Suggested headerRef={headerRef} />}

			</PageLayout>
		</RequestProvider>

	);
};
