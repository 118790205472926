import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		zIndex: 2,
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
}));

export default ({ children, ...rest }) => {
	const classes = useStyles();

	return (
		<form
			className={classes.root}
			{...rest}
		>
			{children}
		</form>
	);
};
