import {
	getECRExperiences,
	getLoading,
	getNextLink,
	getPrevLink,
} from './selectors';

import {
	requestECRMore,
	requestECRExperiences,
	requestDeleteMyEcrExperience,
} from './thunks';

import {
	update,
	resetECR,
} from './slice';

export default {
	selector: getECRExperiences,
	requestExperiences: requestECRExperiences,
	requestMore: requestECRMore,
	loadingSelector: getLoading,
	hasMoreToLoad: getNextLink,
	hasPreviousToLoad: getPrevLink,
	update,
	reset: resetECR,
	deleteMyExperience: requestDeleteMyEcrExperience,
};
