import { createSelector } from '@reduxjs/toolkit';

export const getSlice = (state) => state.userAcccountV2Experiences;

export const getExperiences = createSelector(getSlice, (slice) => slice?.experiences);

export const getLoadingExperiences = createSelector(getSlice, (slice) => slice?.loadingExperiences);

export const getNextLink = createSelector(getSlice, (slice) => slice?.next);

export const getHasMoreToLoad = createSelector(getSlice, (slice) => !!slice?.next);
