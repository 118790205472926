import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useRdsTheme from 'rds/theme/useRdsTheme';
import PageLayout from '../components/layout/PageLayout';
import ResetPasswordForm from '../ResetPassword/ResetPasswordForm';

export default () => {
	const params = useParams();
	const theme = useRdsTheme('dark');
	const navigate = useNavigate();

	return (
		<PageLayout
			theme={theme}
			title="Reset Password"
			close={() => {
				navigate('/signin');
			}}
		>
			<ResetPasswordForm token={params.token} />
		</PageLayout>
	);
};
