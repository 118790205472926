import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import Avatar from 'rds/components/Avatar';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'inline-block',
		margin: theme.spacing(),
		width: 68,
		overflow: 'hidden',
	},
	avatar: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	username: {
		marginTop: theme.spacing(),
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textAlign: 'center',
	},
}));

export default memo(({ avatar, username, onClick }) => {
	const classes = useStyles();

	return (
		<div className={classes.container} onClick={onClick}>
			<div className={classes.avatar}>
				<Avatar
					size="large"
					alt="header"
					src={avatar?.medium?.png}
				/>
			</div>
			<div className={classes.username}>
				{username.toLowerCase()}
			</div>
		</div>
	);
});
