import { deepLinkScheme } from 'config';

export const formPermissionDeepLink = (redirect = '/experience/create/capture') => `${deepLinkScheme}://request.permissions?redirect=${redirect}`;
export const formSettingsDeepLink = (redirect = '/experience/create/capture') => `${deepLinkScheme}://request.settings?redirect=${redirect}`;

export const gotoPermissions = () => window.location.href = formPermissionDeepLink();
export const gotoSettings = () => window.location.href = formSettingsDeepLink();

// export const gotoPermissions = () => window.open(formPermissionDeepLink());
// export const gotoSettings = () => window.open(formSettingsDeepLink());

// export const gotoPermissions = () => window.history.replaceState({}, '', formPermissionDeepLink());
// export const gotoSettings = () => window.history.replaceState({}, '', formSettingsDeepLink());
