module.exports = {
	C000: '#FFE9DB',
	C100: '#FCD2BA',
	C200: '#FBB591',
	C300: '#F8986F',
	C400: '#FB7848',
	C500: '#EA5E34',
	C600: '#E04A1D',
	C700: '#C73D0A',
	C800: '#B53C08',
	C900: '#952F04',
};
