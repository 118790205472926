import React, { useRef, useMemo, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ButtonSpinner from 'rds/components/Spinners/Button';
import EachUser from './EachUser';
import EachDestination from './EachDestination';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	people: {
		position: 'relative',
		zIndex: 1,
		marginTop: theme.spacing(9),
	},
	person: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}));

export default ({
	data,
	onClose,
	tab,
	loading,
	hasNext,
	loadMore,
}) => {
	const classes = useStyles();
	const endRef = useRef();

	const observer = useMemo(
		() => new IntersectionObserver(([intersection]) => {
			if (!intersection.isIntersecting || !hasNext || loading) {
				return;
			}
			loadMore();
		}),
		[hasNext, loadMore],
	);

	useEffect(() => {
		observer.observe(endRef.current);
		return () => observer.disconnect();
	}, [endRef, observer]);

	return (
		<div className={classes.root}>
			<Grid container className={classes.people}>
				{data && data.map((d, i) => (
					<Grid key={i} item xs={12} className={classes.person}>
						{d.type === 'user' ? 	<EachUser {...d.entity} onClose={onClose} tab={tab} /> :	<EachDestination {...d.entity} onClose={onClose} /> }
					</Grid>
				))}
				<div ref={endRef} />
			</Grid>
			{loading && (
				<ButtonSpinner size={15} />
			)}
		</div>
	);
};
