const DecagonFragments = [
	{
		left: 'calc(50% - 15.11px)',
	},
	{
		left: '63%',
		top: '9%',
		transform: 'matrix(-0.81, -0.59, 0.59, -0.81, 0, 0) rotateX(180deg)',
	},

	{
		left: '79%',
		top: '33%',
		transform: 'matrix(-0.31, -0.95, 0.95, -0.31, 0, 0)	rotateX(180deg)',
	},

	{
		left: '79%',
		top: '62%',
		bottom: '0%',
		transform: ' matrix(0.31, -0.95, 0.95, 0.31, 0, 0)	rotateX(180deg)',
	},

	{
		left: '63%',
		top: '86%',
		bottom: '0%',
		transform: 'matrix(0.81, -0.59, 0.59, 0.81, 0, 0)	rotateX(180deg)',
	},

	{
		left: 'calc(50% - 15.11px)',
		top: '95%',
		bottom: 0,
		transform: 'rotateX(180deg)',
	},

	{
		left: '9px',
		top: '86%',
		transform: 'matrix(-0.81, -0.59, 0.59, -0.81, 0, 0)',
	},

	{
		left: '-8%',
		top: '62%',
		transform: 'matrix(-0.31, -0.95, 0.95, -0.31, 0, 0)',
	},

	{
		left: '-8%',
		top: '33%',
		transform: 'matrix(0.31, -0.95, 0.95, 0.31, 0, 0)',
	},

	{
		left: '9%',
		top: '9%',
		transform: 'matrix(0.81, -0.59, 0.59, 0.81, 0, 0)',
	},
];
export default DecagonFragments;
