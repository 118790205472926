import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import CancelAlert from 'rds/components/CancelAlert';
import {
	getUserDateOfBirth,
	getUserEmail,
	getUserUsername,
	requestUserPrivateProfile,
} from 'app/slices/account-settings';
import { useTheme } from '@material-ui/styles';
import dayjs from 'dayjs';
import SettingItemWithArrow from './SettingItemWithArrow';
import SettingItemWithField from './SettingItemWithField';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(6),
		paddingLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		paddingRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		borderTop: `1px solid ${theme.colors.neutral.C800}`,
	},
	title: {
		marginBottom: theme.spacing(8.5),
		color: theme.colors.neutral.C300,
	},
}));

export default () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const username = useSelector(getUserUsername);
	const email = useSelector(getUserEmail);
	const date = useSelector(getUserDateOfBirth);
	const [open, setOpen] = useState();
	const dob = (date && dayjs(date).format('MM/DD/YYYY')) || 'N/A';

	useEffect(() => {
		dispatch(requestUserPrivateProfile());
	}, []);

	return (
		<div className={classes.root}>
			<Typography
				className={classes.title}
				variant="body2"
			>
				ACCOUNT
			</Typography>
			<SettingItemWithField marginTop title="Username" value={username} to="/my-account/settings/username" />
			<SettingItemWithField title="Email Address" value={email.email} />
			<SettingItemWithField title="Birthday" value={dob} />
			<SettingItemWithArrow title="Notifications" to="/my-account/settings/notifications" />
			<SettingItemWithArrow title="Log Out" onClick={() => { setOpen(true); }} />
			<CancelAlert
				title="Are you sure you want to log out of this account?"
				open={open}
				background
				onClick={() => {
					navigate('/signout');
				}}
				leftButton="Cancel"
				rightButton="Log out"
				rightButtonStyle={{ color: theme.colors.error.C700 }}
				onClose={() => { setOpen(false); }}
			/>
		</div>
	);
};
