import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRouterCalls, countRouterCall } from 'app/slices/layout';

const useCountRouterCalls = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const routerCalls = useSelector(getRouterCalls);

	React.useEffect(() => {
		dispatch(countRouterCall());
	}, [location]);

	return routerCalls;
};

export default useCountRouterCalls;
