import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		marginBottom: theme.spacing(2),
		color: theme.colors.primary.C600,
		'&:hover': {
			color: theme.colors.primary.C600,
		},
	},
	text: {
		marginLeft: theme.spacing(2),
	},
}));

export default ({ isMe }) => {
	const classes = useStyles();

	if (!isMe) {
		return null;
	}

	return (
		<>
			<Button size="small" className={classes.root} disableRipple disableFocusRipple>
				<IconV3
					icon="Edit"
					size={12}
					containerSize={12}
					dropShadow
					color="primaryC600"
				/>
				<span className={classes.text}>Edit profile</span>
			</Button>
		</>
	);
};
