import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OverflowMenu from 'rds/components/OverflowMenu';
import Menu from 'routes/UserAccount/Menu';
import BlockMenu from 'routes/UserAccount/Menu/BlockMenu';
import { getOverflowMenu, closeOverflowMenu } from 'app/slices/layout';
import { getActiveMenu } from 'app/slices/user-account-v2/layout';
import { getTheirProfileUuid, requestUnBlockUser } from 'app/slices/user-account-v2/accounts';

export default function UserAccountMenu() {
	const dispatch = useDispatch();
	const { overflow } = useSelector((state) => ({
		overflow: getOverflowMenu(state),
	}));
	const menu = useSelector(getActiveMenu);
	const uuid = useSelector(getTheirProfileUuid);

	const handleUnblockClick = () => {
		dispatch(requestUnBlockUser(uuid));
	};

	if (menu === 'block') {
		return <Menu isMe={false} />;
	}

	if (menu === 'unBlock') {
		return (
			<BlockMenu
				title="Unblock"
				buttonVariant="contained"
				buttonColor="secondary"
				subtitle="Once unblocked, they will be able to engage with you and follow you. They will not be notified that you unblocked them."
				label="Unblock"
				onClick={handleUnblockClick}
			/>
		);
	}

	return (
		<OverflowMenu
			onClose={() => dispatch(closeOverflowMenu())}
			show={!!overflow}
			items={overflow}
		/>
	);
}
