import { createSlice } from '@reduxjs/toolkit';
import deduplicateList from 'utils/deduplicate-List';
import { injectReducer } from 'services/store';
import {
	requestUserExperiences,
	requestMergeUserExperiences,
	requestLoadMoreExperiences,
} from './thunks';

const initialState = {
	experiences: null,
	errors: null,
	loadingExperiences: false,
	next: null,
	seenBefore: {},
};

const name = 'userAcccountV2Experiences';

const userExperiencesSlice = createSlice({
	name,
	initialState: { ...initialState },
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		update: (state, action) => {
			const previousState = [...state.experiences];
			const newState = [];
			for (let i = 0; i < previousState.length; i++) {
				if (previousState[i].uid === action.payload.uid) {
					previousState[i] = action.payload;
				}
				newState.push(previousState[i]);
			}

			state.experiences = newState;
		},
	},
	extraReducers: {
		[requestUserExperiences.pending]: (state) => {
			state.loadingExperiences = true;
			state.errors = null;
		},
		[requestMergeUserExperiences.fulfilled]: (state, action) => {
			const { experiences } = action.payload;

			const add = [];
			for (let i = 0; i < experiences.length; i++) {
				if (state.experiences.findIndex((e) => e.uid === experiences[i].uid) === -1) {
					add.push(experiences[i]);
				}
			}
			if (add.length) {
				state.experiences = add.concat(state.experiences);
			}
		},
		[requestUserExperiences.fulfilled]: (state, action) => {
			const { experiences, _links } = action.payload;
			state.seenBefore = {};
			const experiencesList = deduplicateList(experiences, state.seenBefore, 'uid');
			state.experiences = experiencesList;
			state.next = _links?.next?.href || null;
			state.loadingExperiences = false;
			state.errors = null;
		},
		[requestUserExperiences.rejected]: (state, action) => {
			state.loadingExperiences = false;
			state.errors = action.payload;
		},
		[requestLoadMoreExperiences.pending]: (state) => {
			state.loadingExperiences = true;
			state.errors = null;
		},
		[requestLoadMoreExperiences.fulfilled]: (state, action) => {
			state.errors = null;
			state.loadingExperiences = false;

			if (!action.payload) {
				return;
			}

			const { experiences, _links } = action.payload;
			const experiencesList = deduplicateList(experiences, state.seenBefore, 'uid');
			state.experiences = [...state.experiences, ...experiencesList];
			state.next = _links?.next?.href || null;
		},
		[requestLoadMoreExperiences.rejected]: (state, action) => {
			state.loadingExperiences = false;
			state.errors = action.payload;
		},
	},
});

export const { update, reset } = userExperiencesSlice.actions;
export default userExperiencesSlice.reducer;

injectReducer(name, userExperiencesSlice.reducer);
