import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import HRNumbers from 'human-readable-numbers';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
	change,
	getMyProfileUuid,
	getTheirProfileUuid,
	getMyStats,
	getTheirStats,
} from 'app/slices/user-account-v2/accounts';
import {
	getFollowersList,
	getNextFollowersLink,
	getNextFollowingLink,
	getLoadingFollowersFollowing,
	getFollowingList,
	requestUsersFollowingFollowers,
	requestLoadMoreFollowingFollowers,
	getFollowTab,
} from 'app/slices/user-account-v2/following-actions';
import { makeStyles } from '@material-ui/core';
import FollowTabContent from './FollowTabContent';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(13),
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
	},
	tabs: {
		position: 'fixed',
		right: 0,
		left: 0,
		zIndex: 4,
	},
	header: {
		position: 'fixed',
		right: 0,
		left: 0,
		top: 0,
		height: 112,
		background: theme.palette.background.page,
		zIndex: 2,
	},
	activeTab: {
		...theme.typography.title2,
		width: '50%',
	},
	unactive: {
		...theme.typography.body1,
		color: theme.colors.neutral.C000,
		opacity: 0.6,
		width: '50%',
	},
}));

const TabPanel = (props) => {
	const {
		children, value, index,
	} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</div>
	);
};

export default ({
	isMe,
}) => {
	const navigate = useNavigate();
	const { subpage } = useParams();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [value, setValue] = useState(
		subpage === 'following' ? 0 : 1,
	);
	const tab = useSelector(getFollowTab);
	const uuid = useSelector(isMe ? getMyProfileUuid : getTheirProfileUuid);
	const followingsList = useSelector(getFollowingList);
	const followersList = useSelector(getFollowersList);
	const nextFollowers = useSelector(getNextFollowersLink);
	const nextFollowing = useSelector(getNextFollowingLink);
	const loading = useSelector(getLoadingFollowersFollowing);
	const stats = useSelector(isMe ? getMyStats : getTheirStats);

	useEffect(() => {
		if (!isMe && !uuid) {
			navigate(-2);
		}
	}, [isMe, uuid, navigate]);

	useEffect(() => {
		if (!uuid) {
			return;
		}

		dispatch(change({ followings: [], followers: [] }));
		dispatch(requestUsersFollowingFollowers({ uuid, list: 'followers' }));
		dispatch(requestUsersFollowingFollowers({ uuid, list: 'following' }));
	}, [uuid]);

	useEffect(() => {
		if (tab) {
			setValue(tab);
		}
	}, [tab]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const followersNumber = HRNumbers.toHumanString(stats?.followers?.robot);
	const followingNumber = HRNumbers.toHumanString(stats?.followingTotal?.robot);
	const followingLabel = `${followingNumber || 0} Following`;
	const followersLabel = stats?.followers?.robot === 1 ? '1 Follower' : `${followersNumber || 0} Followers`;

	if (!uuid) {
		return null;
	}

	return (
		<div className={classes.root}>
			<div className={classes.header} />
			<Box>
				<Box className={classes.tabs}>
					<Tabs style={{ marginRight: 16, marginLeft: 16 }} variant="fullWidth" centered indicatorColor="primary" value={value} onChange={handleChange}>
						<Tab className={value === 0 ? classes.activeTab : classes.unactive} disableRipple disableFocusRipple label={followingLabel} />
						<Tab className={value === 1 ? classes.activeTab : classes.unactive} disableRipple disableFocusRipple label={followersLabel} />
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<FollowTabContent
						data={followingsList}
						loading={loading}
						hasNext={nextFollowing}
						loadMore={() => { dispatch(requestLoadMoreFollowingFollowers('following')); }}
						tab="followings"
					/>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<FollowTabContent
						data={followersList}
						loading={loading}
						hasNext={nextFollowers}
						loadMore={() => { dispatch(requestLoadMoreFollowingFollowers('followers')); }}
						tab="followers"
					/>
				</TabPanel>
			</Box>
		</div>
	);
};
