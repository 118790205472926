import { lazy } from 'react'



const ActivitiesDsLight = lazy(() => import('./ActivitiesDs/Light'));
const ActivitiesDsDark = lazy(() => import('./ActivitiesDs/Dark'));
const ActivitiesDsSecondary = lazy(() => import('./ActivitiesDs/Secondary'));
const ActivitiesDsPrimary = lazy(() => import('./ActivitiesDs/Primary'));
const ActivitiesDsDisabled = lazy(() => import('./ActivitiesDs/Disabled'));
const ActivitiesDsError = lazy(() => import('./ActivitiesDs/Error'));
const ActivitiesDsSuccess = lazy(() => import('./ActivitiesDs/Success'));
const ActivitiesDsInfo = lazy(() => import('./ActivitiesDs/Info'));
const ActivitiesLight = lazy(() => import('./Activities/Light'));
const ActivitiesDark = lazy(() => import('./Activities/Dark'));
const ActivitiesSecondary = lazy(() => import('./Activities/Secondary'));
const ActivitiesPrimary = lazy(() => import('./Activities/Primary'));
const ActivitiesDisabled = lazy(() => import('./Activities/Disabled'));
const ActivitiesError = lazy(() => import('./Activities/Error'));
const ActivitiesSuccess = lazy(() => import('./Activities/Success'));
const ActivitiesInfo = lazy(() => import('./Activities/Info'));
const AddCustomDsLight = lazy(() => import('./AddCustomDs/Light'));
const AddCustomDsDark = lazy(() => import('./AddCustomDs/Dark'));
const AddCustomDsSecondary = lazy(() => import('./AddCustomDs/Secondary'));
const AddCustomDsPrimary = lazy(() => import('./AddCustomDs/Primary'));
const AddCustomDsDisabled = lazy(() => import('./AddCustomDs/Disabled'));
const AddCustomDsError = lazy(() => import('./AddCustomDs/Error'));
const AddCustomDsSuccess = lazy(() => import('./AddCustomDs/Success'));
const AddCustomDsInfo = lazy(() => import('./AddCustomDs/Info'));
const AddCustomLight = lazy(() => import('./AddCustom/Light'));
const AddCustomDark = lazy(() => import('./AddCustom/Dark'));
const AddCustomSecondary = lazy(() => import('./AddCustom/Secondary'));
const AddCustomPrimary = lazy(() => import('./AddCustom/Primary'));
const AddCustomDisabled = lazy(() => import('./AddCustom/Disabled'));
const AddCustomError = lazy(() => import('./AddCustom/Error'));
const AddCustomSuccess = lazy(() => import('./AddCustom/Success'));
const AddCustomInfo = lazy(() => import('./AddCustom/Info'));
const AddTextOverlayCustomDsLight = lazy(() => import('./AddTextOverlayCustomDs/Light'));
const AddTextOverlayCustomDsDark = lazy(() => import('./AddTextOverlayCustomDs/Dark'));
const AddTextOverlayCustomDsSecondary = lazy(() => import('./AddTextOverlayCustomDs/Secondary'));
const AddTextOverlayCustomDsPrimary = lazy(() => import('./AddTextOverlayCustomDs/Primary'));
const AddTextOverlayCustomDsDisabled = lazy(() => import('./AddTextOverlayCustomDs/Disabled'));
const AddTextOverlayCustomDsError = lazy(() => import('./AddTextOverlayCustomDs/Error'));
const AddTextOverlayCustomDsSuccess = lazy(() => import('./AddTextOverlayCustomDs/Success'));
const AddTextOverlayCustomDsInfo = lazy(() => import('./AddTextOverlayCustomDs/Info'));
const AddTextOverlayCustomLight = lazy(() => import('./AddTextOverlayCustom/Light'));
const AddTextOverlayCustomDark = lazy(() => import('./AddTextOverlayCustom/Dark'));
const AddTextOverlayCustomSecondary = lazy(() => import('./AddTextOverlayCustom/Secondary'));
const AddTextOverlayCustomPrimary = lazy(() => import('./AddTextOverlayCustom/Primary'));
const AddTextOverlayCustomDisabled = lazy(() => import('./AddTextOverlayCustom/Disabled'));
const AddTextOverlayCustomError = lazy(() => import('./AddTextOverlayCustom/Error'));
const AddTextOverlayCustomSuccess = lazy(() => import('./AddTextOverlayCustom/Success'));
const AddTextOverlayCustomInfo = lazy(() => import('./AddTextOverlayCustom/Info'));
const AvatarCustomDsLight = lazy(() => import('./AvatarCustomDs/Light'));
const AvatarCustomDsDark = lazy(() => import('./AvatarCustomDs/Dark'));
const AvatarCustomDsSecondary = lazy(() => import('./AvatarCustomDs/Secondary'));
const AvatarCustomDsPrimary = lazy(() => import('./AvatarCustomDs/Primary'));
const AvatarCustomDsDisabled = lazy(() => import('./AvatarCustomDs/Disabled'));
const AvatarCustomDsError = lazy(() => import('./AvatarCustomDs/Error'));
const AvatarCustomDsSuccess = lazy(() => import('./AvatarCustomDs/Success'));
const AvatarCustomDsInfo = lazy(() => import('./AvatarCustomDs/Info'));
const AvatarCustomLight = lazy(() => import('./AvatarCustom/Light'));
const AvatarCustomDark = lazy(() => import('./AvatarCustom/Dark'));
const AvatarCustomSecondary = lazy(() => import('./AvatarCustom/Secondary'));
const AvatarCustomPrimary = lazy(() => import('./AvatarCustom/Primary'));
const AvatarCustomDisabled = lazy(() => import('./AvatarCustom/Disabled'));
const AvatarCustomError = lazy(() => import('./AvatarCustom/Error'));
const AvatarCustomSuccess = lazy(() => import('./AvatarCustom/Success'));
const AvatarCustomInfo = lazy(() => import('./AvatarCustom/Info'));
const BookmarkCustomLight = lazy(() => import('./BookmarkCustom/Light'));
const BookmarkCustomDark = lazy(() => import('./BookmarkCustom/Dark'));
const BookmarkCustomSecondary = lazy(() => import('./BookmarkCustom/Secondary'));
const BookmarkCustomPrimary = lazy(() => import('./BookmarkCustom/Primary'));
const BookmarkCustomDisabled = lazy(() => import('./BookmarkCustom/Disabled'));
const BookmarkCustomError = lazy(() => import('./BookmarkCustom/Error'));
const BookmarkCustomSuccess = lazy(() => import('./BookmarkCustom/Success'));
const BookmarkCustomDsLight = lazy(() => import('./BookmarkCustomDs/Light'));
const BookmarkCustomDsDark = lazy(() => import('./BookmarkCustomDs/Dark'));
const BookmarkCustomDsSecondary = lazy(() => import('./BookmarkCustomDs/Secondary'));
const BookmarkCustomDsPrimary = lazy(() => import('./BookmarkCustomDs/Primary'));
const BookmarkCustomDsDisabled = lazy(() => import('./BookmarkCustomDs/Disabled'));
const BookmarkCustomDsError = lazy(() => import('./BookmarkCustomDs/Error'));
const BookmarkCustomDsSuccess = lazy(() => import('./BookmarkCustomDs/Success'));
const CalendarCustomDsLight = lazy(() => import('./CalendarCustomDs/Light'));
const CalendarCustomDsDark = lazy(() => import('./CalendarCustomDs/Dark'));
const CalendarCustomDsSecondary = lazy(() => import('./CalendarCustomDs/Secondary'));
const CalendarCustomDsPrimary = lazy(() => import('./CalendarCustomDs/Primary'));
const CalendarCustomDsDisabled = lazy(() => import('./CalendarCustomDs/Disabled'));
const CalendarCustomDsError = lazy(() => import('./CalendarCustomDs/Error'));
const CalendarCustomDsSuccess = lazy(() => import('./CalendarCustomDs/Success'));
const CalendarCustomDsInfo = lazy(() => import('./CalendarCustomDs/Info'));
const CalendarCustomLight = lazy(() => import('./CalendarCustom/Light'));
const CalendarCustomDark = lazy(() => import('./CalendarCustom/Dark'));
const CalendarCustomSecondary = lazy(() => import('./CalendarCustom/Secondary'));
const CalendarCustomPrimary = lazy(() => import('./CalendarCustom/Primary'));
const CalendarCustomDisabled = lazy(() => import('./CalendarCustom/Disabled'));
const CalendarCustomError = lazy(() => import('./CalendarCustom/Error'));
const CalendarCustomSuccess = lazy(() => import('./CalendarCustom/Success'));
const CalendarCustomInfo = lazy(() => import('./CalendarCustom/Info'));
const CameraFlipCustomDsLight = lazy(() => import('./CameraFlipCustomDs/Light'));
const CameraFlipCustomDsDark = lazy(() => import('./CameraFlipCustomDs/Dark'));
const CameraFlipCustomDsSecondary = lazy(() => import('./CameraFlipCustomDs/Secondary'));
const CameraFlipCustomDsPrimary = lazy(() => import('./CameraFlipCustomDs/Primary'));
const CameraFlipCustomDsDisabled = lazy(() => import('./CameraFlipCustomDs/Disabled'));
const CameraFlipCustomDsError = lazy(() => import('./CameraFlipCustomDs/Error'));
const CameraFlipCustomDsSuccess = lazy(() => import('./CameraFlipCustomDs/Success'));
const CameraFlipCustomDsInfo = lazy(() => import('./CameraFlipCustomDs/Info'));
const CameraFlipCustomLight = lazy(() => import('./CameraFlipCustom/Light'));
const CameraFlipCustomDark = lazy(() => import('./CameraFlipCustom/Dark'));
const CameraFlipCustomSecondary = lazy(() => import('./CameraFlipCustom/Secondary'));
const CameraFlipCustomPrimary = lazy(() => import('./CameraFlipCustom/Primary'));
const CameraFlipCustomDisabled = lazy(() => import('./CameraFlipCustom/Disabled'));
const CameraFlipCustomError = lazy(() => import('./CameraFlipCustom/Error'));
const CameraFlipCustomSuccess = lazy(() => import('./CameraFlipCustom/Success'));
const CameraFlipCustomInfo = lazy(() => import('./CameraFlipCustom/Info'));
const CameraFocusCustomDsLight = lazy(() => import('./CameraFocusCustomDs/Light'));
const CameraFocusCustomDsDark = lazy(() => import('./CameraFocusCustomDs/Dark'));
const CameraFocusCustomDsSecondary = lazy(() => import('./CameraFocusCustomDs/Secondary'));
const CameraFocusCustomDsPrimary = lazy(() => import('./CameraFocusCustomDs/Primary'));
const CameraFocusCustomDsDisabled = lazy(() => import('./CameraFocusCustomDs/Disabled'));
const CameraFocusCustomDsError = lazy(() => import('./CameraFocusCustomDs/Error'));
const CameraFocusCustomDsSuccess = lazy(() => import('./CameraFocusCustomDs/Success'));
const CameraFocusCustomDsInfo = lazy(() => import('./CameraFocusCustomDs/Info'));
const CameraFocusCustomLight = lazy(() => import('./CameraFocusCustom/Light'));
const CameraFocusCustomDark = lazy(() => import('./CameraFocusCustom/Dark'));
const CameraFocusCustomSecondary = lazy(() => import('./CameraFocusCustom/Secondary'));
const CameraFocusCustomPrimary = lazy(() => import('./CameraFocusCustom/Primary'));
const CameraFocusCustomDisabled = lazy(() => import('./CameraFocusCustom/Disabled'));
const CameraFocusCustomError = lazy(() => import('./CameraFocusCustom/Error'));
const CameraFocusCustomSuccess = lazy(() => import('./CameraFocusCustom/Success'));
const CameraFocusCustomInfo = lazy(() => import('./CameraFocusCustom/Info'));
const CancelCustomDsLight = lazy(() => import('./CancelCustomDs/Light'));
const CancelCustomDsDark = lazy(() => import('./CancelCustomDs/Dark'));
const CancelCustomDsSecondary = lazy(() => import('./CancelCustomDs/Secondary'));
const CancelCustomDsPrimary = lazy(() => import('./CancelCustomDs/Primary'));
const CancelCustomDsDisabled = lazy(() => import('./CancelCustomDs/Disabled'));
const CancelCustomDsError = lazy(() => import('./CancelCustomDs/Error'));
const CancelCustomDsSuccess = lazy(() => import('./CancelCustomDs/Success'));
const CancelCustomDsInfo = lazy(() => import('./CancelCustomDs/Info'));
const CancelCustomLight = lazy(() => import('./CancelCustom/Light'));
const CancelCustomDark = lazy(() => import('./CancelCustom/Dark'));
const CancelCustomSecondary = lazy(() => import('./CancelCustom/Secondary'));
const CancelCustomPrimary = lazy(() => import('./CancelCustom/Primary'));
const CancelCustomDisabled = lazy(() => import('./CancelCustom/Disabled'));
const CancelCustomError = lazy(() => import('./CancelCustom/Error'));
const CancelCustomSuccess = lazy(() => import('./CancelCustom/Success'));
const CancelCustomInfo = lazy(() => import('./CancelCustom/Info'));
const ChatCustomDsLight = lazy(() => import('./ChatCustomDs/Light'));
const ChatCustomDsDark = lazy(() => import('./ChatCustomDs/Dark'));
const ChatCustomDsSecondary = lazy(() => import('./ChatCustomDs/Secondary'));
const ChatCustomDsPrimary = lazy(() => import('./ChatCustomDs/Primary'));
const ChatCustomDsDisabled = lazy(() => import('./ChatCustomDs/Disabled'));
const ChatCustomDsError = lazy(() => import('./ChatCustomDs/Error'));
const ChatCustomDsSuccess = lazy(() => import('./ChatCustomDs/Success'));
const ChatCustomDsInfo = lazy(() => import('./ChatCustomDs/Info'));
const ChatCustomLight = lazy(() => import('./ChatCustom/Light'));
const ChatCustomDark = lazy(() => import('./ChatCustom/Dark'));
const ChatCustomSecondary = lazy(() => import('./ChatCustom/Secondary'));
const ChatCustomPrimary = lazy(() => import('./ChatCustom/Primary'));
const ChatCustomDisabled = lazy(() => import('./ChatCustom/Disabled'));
const ChatCustomError = lazy(() => import('./ChatCustom/Error'));
const ChatCustomSuccess = lazy(() => import('./ChatCustom/Success'));
const ChatCustomInfo = lazy(() => import('./ChatCustom/Info'));
const ChevronDownCustomDsLight = lazy(() => import('./ChevronDownCustomDs/Light'));
const ChevronDownCustomDsDark = lazy(() => import('./ChevronDownCustomDs/Dark'));
const ChevronDownCustomDsSecondary = lazy(() => import('./ChevronDownCustomDs/Secondary'));
const ChevronDownCustomDsPrimary = lazy(() => import('./ChevronDownCustomDs/Primary'));
const ChevronDownCustomDsDisabled = lazy(() => import('./ChevronDownCustomDs/Disabled'));
const ChevronDownCustomDsError = lazy(() => import('./ChevronDownCustomDs/Error'));
const ChevronDownCustomDsSuccess = lazy(() => import('./ChevronDownCustomDs/Success'));
const ChevronDownCustomDsInfo = lazy(() => import('./ChevronDownCustomDs/Info'));
const ChevronDownCustomLight = lazy(() => import('./ChevronDownCustom/Light'));
const ChevronDownCustomDark = lazy(() => import('./ChevronDownCustom/Dark'));
const ChevronDownCustomSecondary = lazy(() => import('./ChevronDownCustom/Secondary'));
const ChevronDownCustomPrimary = lazy(() => import('./ChevronDownCustom/Primary'));
const ChevronDownCustomDisabled = lazy(() => import('./ChevronDownCustom/Disabled'));
const ChevronDownCustomError = lazy(() => import('./ChevronDownCustom/Error'));
const ChevronDownCustomSuccess = lazy(() => import('./ChevronDownCustom/Success'));
const ChevronDownCustomInfo = lazy(() => import('./ChevronDownCustom/Info'));
const ChevronLeftCustomDsLight = lazy(() => import('./ChevronLeftCustomDs/Light'));
const ChevronLeftCustomDsDark = lazy(() => import('./ChevronLeftCustomDs/Dark'));
const ChevronLeftCustomDsSecondary = lazy(() => import('./ChevronLeftCustomDs/Secondary'));
const ChevronLeftCustomDsPrimary = lazy(() => import('./ChevronLeftCustomDs/Primary'));
const ChevronLeftCustomDsDisabled = lazy(() => import('./ChevronLeftCustomDs/Disabled'));
const ChevronLeftCustomDsError = lazy(() => import('./ChevronLeftCustomDs/Error'));
const ChevronLeftCustomDsSuccess = lazy(() => import('./ChevronLeftCustomDs/Success'));
const ChevronLeftCustomDsInfo = lazy(() => import('./ChevronLeftCustomDs/Info'));
const ChevronLeftCustomLight = lazy(() => import('./ChevronLeftCustom/Light'));
const ChevronLeftCustomDark = lazy(() => import('./ChevronLeftCustom/Dark'));
const ChevronLeftCustomSecondary = lazy(() => import('./ChevronLeftCustom/Secondary'));
const ChevronLeftCustomPrimary = lazy(() => import('./ChevronLeftCustom/Primary'));
const ChevronLeftCustomDisabled = lazy(() => import('./ChevronLeftCustom/Disabled'));
const ChevronLeftCustomError = lazy(() => import('./ChevronLeftCustom/Error'));
const ChevronLeftCustomSuccess = lazy(() => import('./ChevronLeftCustom/Success'));
const ChevronLeftCustomInfo = lazy(() => import('./ChevronLeftCustom/Info'));
const ChevronRightCustomDsLight = lazy(() => import('./ChevronRightCustomDs/Light'));
const ChevronRightCustomDsDark = lazy(() => import('./ChevronRightCustomDs/Dark'));
const ChevronRightCustomDsSecondary = lazy(() => import('./ChevronRightCustomDs/Secondary'));
const ChevronRightCustomDsPrimary = lazy(() => import('./ChevronRightCustomDs/Primary'));
const ChevronRightCustomDsDisabled = lazy(() => import('./ChevronRightCustomDs/Disabled'));
const ChevronRightCustomDsError = lazy(() => import('./ChevronRightCustomDs/Error'));
const ChevronRightCustomDsSuccess = lazy(() => import('./ChevronRightCustomDs/Success'));
const ChevronRightCustomDsInfo = lazy(() => import('./ChevronRightCustomDs/Info'));
const ChevronRightCustomLight = lazy(() => import('./ChevronRightCustom/Light'));
const ChevronRightCustomDark = lazy(() => import('./ChevronRightCustom/Dark'));
const ChevronRightCustomSecondary = lazy(() => import('./ChevronRightCustom/Secondary'));
const ChevronRightCustomPrimary = lazy(() => import('./ChevronRightCustom/Primary'));
const ChevronRightCustomDisabled = lazy(() => import('./ChevronRightCustom/Disabled'));
const ChevronRightCustomError = lazy(() => import('./ChevronRightCustom/Error'));
const ChevronRightCustomSuccess = lazy(() => import('./ChevronRightCustom/Success'));
const ChevronRightCustomInfo = lazy(() => import('./ChevronRightCustom/Info'));
const ChevronUpCustomDsLight = lazy(() => import('./ChevronUpCustomDs/Light'));
const ChevronUpCustomDsDark = lazy(() => import('./ChevronUpCustomDs/Dark'));
const ChevronUpCustomDsSecondary = lazy(() => import('./ChevronUpCustomDs/Secondary'));
const ChevronUpCustomDsPrimary = lazy(() => import('./ChevronUpCustomDs/Primary'));
const ChevronUpCustomDsDisabled = lazy(() => import('./ChevronUpCustomDs/Disabled'));
const ChevronUpCustomDsError = lazy(() => import('./ChevronUpCustomDs/Error'));
const ChevronUpCustomDsSuccess = lazy(() => import('./ChevronUpCustomDs/Success'));
const ChevronUpCustomDsInfo = lazy(() => import('./ChevronUpCustomDs/Info'));
const ChevronUpCustomLight = lazy(() => import('./ChevronUpCustom/Light'));
const ChevronUpCustomDark = lazy(() => import('./ChevronUpCustom/Dark'));
const ChevronUpCustomSecondary = lazy(() => import('./ChevronUpCustom/Secondary'));
const ChevronUpCustomPrimary = lazy(() => import('./ChevronUpCustom/Primary'));
const ChevronUpCustomDisabled = lazy(() => import('./ChevronUpCustom/Disabled'));
const ChevronUpCustomError = lazy(() => import('./ChevronUpCustom/Error'));
const ChevronUpCustomSuccess = lazy(() => import('./ChevronUpCustom/Success'));
const ChevronUpCustomInfo = lazy(() => import('./ChevronUpCustom/Info'));
const ChipCancelCustomDsLight = lazy(() => import('./ChipCancelCustomDs/Light'));
const ChipCancelCustomDsDark = lazy(() => import('./ChipCancelCustomDs/Dark'));
const ChipCancelCustomDsSecondary = lazy(() => import('./ChipCancelCustomDs/Secondary'));
const ChipCancelCustomDsPrimary = lazy(() => import('./ChipCancelCustomDs/Primary'));
const ChipCancelCustomDsDisabled = lazy(() => import('./ChipCancelCustomDs/Disabled'));
const ChipCancelCustomDsError = lazy(() => import('./ChipCancelCustomDs/Error'));
const ChipCancelCustomDsSuccess = lazy(() => import('./ChipCancelCustomDs/Success'));
const ChipCancelCustomDsInfo = lazy(() => import('./ChipCancelCustomDs/Info'));
const ChipCancelCustomLight = lazy(() => import('./ChipCancelCustom/Light'));
const ChipCancelCustomDark = lazy(() => import('./ChipCancelCustom/Dark'));
const ChipCancelCustomSecondary = lazy(() => import('./ChipCancelCustom/Secondary'));
const ChipCancelCustomPrimary = lazy(() => import('./ChipCancelCustom/Primary'));
const ChipCancelCustomDisabled = lazy(() => import('./ChipCancelCustom/Disabled'));
const ChipCancelCustomError = lazy(() => import('./ChipCancelCustom/Error'));
const ChipCancelCustomSuccess = lazy(() => import('./ChipCancelCustom/Success'));
const ChipCancelCustomInfo = lazy(() => import('./ChipCancelCustom/Info'));
const ClipTrayCustomDsLight = lazy(() => import('./ClipTrayCustomDs/Light'));
const ClipTrayCustomDsDark = lazy(() => import('./ClipTrayCustomDs/Dark'));
const ClipTrayCustomDsSecondary = lazy(() => import('./ClipTrayCustomDs/Secondary'));
const ClipTrayCustomDsPrimary = lazy(() => import('./ClipTrayCustomDs/Primary'));
const ClipTrayCustomDsDisabled = lazy(() => import('./ClipTrayCustomDs/Disabled'));
const ClipTrayCustomDsError = lazy(() => import('./ClipTrayCustomDs/Error'));
const ClipTrayCustomDsSuccess = lazy(() => import('./ClipTrayCustomDs/Success'));
const ClipTrayCustomDsInfo = lazy(() => import('./ClipTrayCustomDs/Info'));
const ClipTrayCustomLight = lazy(() => import('./ClipTrayCustom/Light'));
const ClipTrayCustomDark = lazy(() => import('./ClipTrayCustom/Dark'));
const ClipTrayCustomSecondary = lazy(() => import('./ClipTrayCustom/Secondary'));
const ClipTrayCustomPrimary = lazy(() => import('./ClipTrayCustom/Primary'));
const ClipTrayCustomDisabled = lazy(() => import('./ClipTrayCustom/Disabled'));
const ClipTrayCustomError = lazy(() => import('./ClipTrayCustom/Error'));
const ClipTrayCustomSuccess = lazy(() => import('./ClipTrayCustom/Success'));
const ClipTrayCustomInfo = lazy(() => import('./ClipTrayCustom/Info'));
const ClosedCaptioningDsLight = lazy(() => import('./ClosedCaptioningDs/Light'));
const ClosedCaptioningDsDark = lazy(() => import('./ClosedCaptioningDs/Dark'));
const ClosedCaptioningDsSecondary = lazy(() => import('./ClosedCaptioningDs/Secondary'));
const ClosedCaptioningDsPrimary = lazy(() => import('./ClosedCaptioningDs/Primary'));
const ClosedCaptioningDsDisabled = lazy(() => import('./ClosedCaptioningDs/Disabled'));
const ClosedCaptioningDsError = lazy(() => import('./ClosedCaptioningDs/Error'));
const ClosedCaptioningDsSuccess = lazy(() => import('./ClosedCaptioningDs/Success'));
const ClosedCaptioningDsInfo = lazy(() => import('./ClosedCaptioningDs/Info'));
const ClosedCaptioningLight = lazy(() => import('./ClosedCaptioning/Light'));
const ClosedCaptioningDark = lazy(() => import('./ClosedCaptioning/Dark'));
const ClosedCaptioningSecondary = lazy(() => import('./ClosedCaptioning/Secondary'));
const ClosedCaptioningPrimary = lazy(() => import('./ClosedCaptioning/Primary'));
const ClosedCaptioningDisabled = lazy(() => import('./ClosedCaptioning/Disabled'));
const ClosedCaptioningError = lazy(() => import('./ClosedCaptioning/Error'));
const ClosedCaptioningSuccess = lazy(() => import('./ClosedCaptioning/Success'));
const ClosedCaptioningInfo = lazy(() => import('./ClosedCaptioning/Info'));
const CollectionCustomDsLight = lazy(() => import('./CollectionCustomDs/Light'));
const CollectionCustomDsDark = lazy(() => import('./CollectionCustomDs/Dark'));
const CollectionCustomDsSecondary = lazy(() => import('./CollectionCustomDs/Secondary'));
const CollectionCustomDsPrimary = lazy(() => import('./CollectionCustomDs/Primary'));
const CollectionCustomDsDisabled = lazy(() => import('./CollectionCustomDs/Disabled'));
const CollectionCustomDsError = lazy(() => import('./CollectionCustomDs/Error'));
const CollectionCustomDsSuccess = lazy(() => import('./CollectionCustomDs/Success'));
const CollectionCustomDsInfo = lazy(() => import('./CollectionCustomDs/Info'));
const CollectionCustomLight = lazy(() => import('./CollectionCustom/Light'));
const CollectionCustomDark = lazy(() => import('./CollectionCustom/Dark'));
const CollectionCustomSecondary = lazy(() => import('./CollectionCustom/Secondary'));
const CollectionCustomPrimary = lazy(() => import('./CollectionCustom/Primary'));
const CollectionCustomDisabled = lazy(() => import('./CollectionCustom/Disabled'));
const CollectionCustomError = lazy(() => import('./CollectionCustom/Error'));
const CollectionCustomSuccess = lazy(() => import('./CollectionCustom/Success'));
const CollectionCustomInfo = lazy(() => import('./CollectionCustom/Info'));
const CommentCustomDsLight = lazy(() => import('./CommentCustomDs/Light'));
const CommentCustomDsDark = lazy(() => import('./CommentCustomDs/Dark'));
const CommentCustomDsSecondary = lazy(() => import('./CommentCustomDs/Secondary'));
const CommentCustomDsPrimary = lazy(() => import('./CommentCustomDs/Primary'));
const CommentCustomDsDisabled = lazy(() => import('./CommentCustomDs/Disabled'));
const CommentCustomDsError = lazy(() => import('./CommentCustomDs/Error'));
const CommentCustomDsSuccess = lazy(() => import('./CommentCustomDs/Success'));
const CommentCustomDsInfo = lazy(() => import('./CommentCustomDs/Info'));
const CommentCustomLight = lazy(() => import('./CommentCustom/Light'));
const CommentCustomDark = lazy(() => import('./CommentCustom/Dark'));
const CommentCustomSecondary = lazy(() => import('./CommentCustom/Secondary'));
const CommentCustomPrimary = lazy(() => import('./CommentCustom/Primary'));
const CommentCustomDisabled = lazy(() => import('./CommentCustom/Disabled'));
const CommentCustomError = lazy(() => import('./CommentCustom/Error'));
const CommentCustomSuccess = lazy(() => import('./CommentCustom/Success'));
const CommentCustomInfo = lazy(() => import('./CommentCustom/Info'));
const DeleteClipsCustomDsLight = lazy(() => import('./DeleteClipsCustomDs/Light'));
const DeleteClipsCustomDsDark = lazy(() => import('./DeleteClipsCustomDs/Dark'));
const DeleteClipsCustomDsSecondary = lazy(() => import('./DeleteClipsCustomDs/Secondary'));
const DeleteClipsCustomDsPrimary = lazy(() => import('./DeleteClipsCustomDs/Primary'));
const DeleteClipsCustomDsDisabled = lazy(() => import('./DeleteClipsCustomDs/Disabled'));
const DeleteClipsCustomDsError = lazy(() => import('./DeleteClipsCustomDs/Error'));
const DeleteClipsCustomDsSuccess = lazy(() => import('./DeleteClipsCustomDs/Success'));
const DeleteClipsCustomDsInfo = lazy(() => import('./DeleteClipsCustomDs/Info'));
const DeleteClipsCustomLight = lazy(() => import('./DeleteClipsCustom/Light'));
const DeleteClipsCustomDark = lazy(() => import('./DeleteClipsCustom/Dark'));
const DeleteClipsCustomSecondary = lazy(() => import('./DeleteClipsCustom/Secondary'));
const DeleteClipsCustomPrimary = lazy(() => import('./DeleteClipsCustom/Primary'));
const DeleteClipsCustomDisabled = lazy(() => import('./DeleteClipsCustom/Disabled'));
const DeleteClipsCustomError = lazy(() => import('./DeleteClipsCustom/Error'));
const DeleteClipsCustomSuccess = lazy(() => import('./DeleteClipsCustom/Success'));
const DeleteClipsCustomInfo = lazy(() => import('./DeleteClipsCustom/Info'));
const DestinationTagCustomDsLight = lazy(() => import('./DestinationTagCustomDs/Light'));
const DestinationTagCustomDsDark = lazy(() => import('./DestinationTagCustomDs/Dark'));
const DestinationTagCustomDsSecondary = lazy(() => import('./DestinationTagCustomDs/Secondary'));
const DestinationTagCustomDsPrimary = lazy(() => import('./DestinationTagCustomDs/Primary'));
const DestinationTagCustomDsDisabled = lazy(() => import('./DestinationTagCustomDs/Disabled'));
const DestinationTagCustomDsError = lazy(() => import('./DestinationTagCustomDs/Error'));
const DestinationTagCustomDsSuccess = lazy(() => import('./DestinationTagCustomDs/Success'));
const DestinationTagCustomDsInfo = lazy(() => import('./DestinationTagCustomDs/Info'));
const DestinationTagCustomLight = lazy(() => import('./DestinationTagCustom/Light'));
const DestinationTagCustomDark = lazy(() => import('./DestinationTagCustom/Dark'));
const DestinationTagCustomSecondary = lazy(() => import('./DestinationTagCustom/Secondary'));
const DestinationTagCustomPrimary = lazy(() => import('./DestinationTagCustom/Primary'));
const DestinationTagCustomDisabled = lazy(() => import('./DestinationTagCustom/Disabled'));
const DestinationTagCustomError = lazy(() => import('./DestinationTagCustom/Error'));
const DestinationTagCustomSuccess = lazy(() => import('./DestinationTagCustom/Success'));
const DestinationTagCustomInfo = lazy(() => import('./DestinationTagCustom/Info'));
const DetailsCustomDsLight = lazy(() => import('./DetailsCustomDs/Light'));
const DetailsCustomDsDark = lazy(() => import('./DetailsCustomDs/Dark'));
const DetailsCustomDsSecondary = lazy(() => import('./DetailsCustomDs/Secondary'));
const DetailsCustomDsPrimary = lazy(() => import('./DetailsCustomDs/Primary'));
const DetailsCustomDsDisabled = lazy(() => import('./DetailsCustomDs/Disabled'));
const DetailsCustomDsError = lazy(() => import('./DetailsCustomDs/Error'));
const DetailsCustomDsSuccess = lazy(() => import('./DetailsCustomDs/Success'));
const DetailsCustomDsInfo = lazy(() => import('./DetailsCustomDs/Info'));
const DetailsCustomLight = lazy(() => import('./DetailsCustom/Light'));
const DetailsCustomDark = lazy(() => import('./DetailsCustom/Dark'));
const DetailsCustomSecondary = lazy(() => import('./DetailsCustom/Secondary'));
const DetailsCustomPrimary = lazy(() => import('./DetailsCustom/Primary'));
const DetailsCustomDisabled = lazy(() => import('./DetailsCustom/Disabled'));
const DetailsCustomError = lazy(() => import('./DetailsCustom/Error'));
const DetailsCustomSuccess = lazy(() => import('./DetailsCustom/Success'));
const DetailsCustomInfo = lazy(() => import('./DetailsCustom/Info'));
const DotsDsLight = lazy(() => import('./DotsDs/Light'));
const DotsDsDark = lazy(() => import('./DotsDs/Dark'));
const DotsDsSecondary = lazy(() => import('./DotsDs/Secondary'));
const DotsDsPrimary = lazy(() => import('./DotsDs/Primary'));
const DotsDsDisabled = lazy(() => import('./DotsDs/Disabled'));
const DotsDsError = lazy(() => import('./DotsDs/Error'));
const DotsDsSuccess = lazy(() => import('./DotsDs/Success'));
const DotsDsInfo = lazy(() => import('./DotsDs/Info'));
const DotsLight = lazy(() => import('./Dots/Light'));
const DotsDark = lazy(() => import('./Dots/Dark'));
const DotsSecondary = lazy(() => import('./Dots/Secondary'));
const DotsPrimary = lazy(() => import('./Dots/Primary'));
const DotsDisabled = lazy(() => import('./Dots/Disabled'));
const DotsError = lazy(() => import('./Dots/Error'));
const DotsSuccess = lazy(() => import('./Dots/Success'));
const DotsInfo = lazy(() => import('./Dots/Info'));
const DownloadDsLight = lazy(() => import('./DownloadDs/Light'));
const DownloadDsDark = lazy(() => import('./DownloadDs/Dark'));
const DownloadDsSecondary = lazy(() => import('./DownloadDs/Secondary'));
const DownloadDsPrimary = lazy(() => import('./DownloadDs/Primary'));
const DownloadDsDisabled = lazy(() => import('./DownloadDs/Disabled'));
const DownloadDsError = lazy(() => import('./DownloadDs/Error'));
const DownloadDsSuccess = lazy(() => import('./DownloadDs/Success'));
const DownloadDsInfo = lazy(() => import('./DownloadDs/Info'));
const DownloadLight = lazy(() => import('./Download/Light'));
const DownloadDark = lazy(() => import('./Download/Dark'));
const DownloadSecondary = lazy(() => import('./Download/Secondary'));
const DownloadPrimary = lazy(() => import('./Download/Primary'));
const DownloadDisabled = lazy(() => import('./Download/Disabled'));
const DownloadError = lazy(() => import('./Download/Error'));
const DownloadSuccess = lazy(() => import('./Download/Success'));
const DownloadInfo = lazy(() => import('./Download/Info'));
const EditDsLight = lazy(() => import('./EditDs/Light'));
const EditDsDark = lazy(() => import('./EditDs/Dark'));
const EditDsSecondary = lazy(() => import('./EditDs/Secondary'));
const EditDsPrimary = lazy(() => import('./EditDs/Primary'));
const EditDsDisabled = lazy(() => import('./EditDs/Disabled'));
const EditDsError = lazy(() => import('./EditDs/Error'));
const EditDsSuccess = lazy(() => import('./EditDs/Success'));
const EditDsInfo = lazy(() => import('./EditDs/Info'));
const EditLight = lazy(() => import('./Edit/Light'));
const EditDark = lazy(() => import('./Edit/Dark'));
const EditSecondary = lazy(() => import('./Edit/Secondary'));
const EditPrimary = lazy(() => import('./Edit/Primary'));
const EditDisabled = lazy(() => import('./Edit/Disabled'));
const EditError = lazy(() => import('./Edit/Error'));
const EditSuccess = lazy(() => import('./Edit/Success'));
const EditInfo = lazy(() => import('./Edit/Info'));
const EditDestinationDsLight = lazy(() => import('./EditDestinationDs/Light'));
const EditDestinationDsDark = lazy(() => import('./EditDestinationDs/Dark'));
const EditDestinationDsSecondary = lazy(() => import('./EditDestinationDs/Secondary'));
const EditDestinationDsPrimary = lazy(() => import('./EditDestinationDs/Primary'));
const EditDestinationDsDisabled = lazy(() => import('./EditDestinationDs/Disabled'));
const EditDestinationDsError = lazy(() => import('./EditDestinationDs/Error'));
const EditDestinationDsSuccess = lazy(() => import('./EditDestinationDs/Success'));
const EditDestinationDsInfo = lazy(() => import('./EditDestinationDs/Info'));
const EditDestinationLight = lazy(() => import('./EditDestination/Light'));
const EditDestinationDark = lazy(() => import('./EditDestination/Dark'));
const EditDestinationSecondary = lazy(() => import('./EditDestination/Secondary'));
const EditDestinationPrimary = lazy(() => import('./EditDestination/Primary'));
const EditDestinationDisabled = lazy(() => import('./EditDestination/Disabled'));
const EditDestinationError = lazy(() => import('./EditDestination/Error'));
const EditDestinationSuccess = lazy(() => import('./EditDestination/Success'));
const EditDestinationInfo = lazy(() => import('./EditDestination/Info'));
const EmailDsLight = lazy(() => import('./EmailDs/Light'));
const EmailDsDark = lazy(() => import('./EmailDs/Dark'));
const EmailDsSecondary = lazy(() => import('./EmailDs/Secondary'));
const EmailDsPrimary = lazy(() => import('./EmailDs/Primary'));
const EmailDsDisabled = lazy(() => import('./EmailDs/Disabled'));
const EmailDsError = lazy(() => import('./EmailDs/Error'));
const EmailDsSuccess = lazy(() => import('./EmailDs/Success'));
const EmailDsInfo = lazy(() => import('./EmailDs/Info'));
const EmailLight = lazy(() => import('./Email/Light'));
const EmailDark = lazy(() => import('./Email/Dark'));
const EmailSecondary = lazy(() => import('./Email/Secondary'));
const EmailPrimary = lazy(() => import('./Email/Primary'));
const EmailDisabled = lazy(() => import('./Email/Disabled'));
const EmailError = lazy(() => import('./Email/Error'));
const EmailSuccess = lazy(() => import('./Email/Success'));
const EmailInfo = lazy(() => import('./Email/Info'));
const ErrorIconDsLight = lazy(() => import('./ErrorIconDs/Light'));
const ErrorIconDsDark = lazy(() => import('./ErrorIconDs/Dark'));
const ErrorIconDsSecondary = lazy(() => import('./ErrorIconDs/Secondary'));
const ErrorIconDsPrimary = lazy(() => import('./ErrorIconDs/Primary'));
const ErrorIconDsDisabled = lazy(() => import('./ErrorIconDs/Disabled'));
const ErrorIconDsError = lazy(() => import('./ErrorIconDs/Error'));
const ErrorIconDsSuccess = lazy(() => import('./ErrorIconDs/Success'));
const ErrorIconDsInfo = lazy(() => import('./ErrorIconDs/Info'));
const ErrorIconLight = lazy(() => import('./ErrorIcon/Light'));
const ErrorIconDark = lazy(() => import('./ErrorIcon/Dark'));
const ErrorIconSecondary = lazy(() => import('./ErrorIcon/Secondary'));
const ErrorIconPrimary = lazy(() => import('./ErrorIcon/Primary'));
const ErrorIconDisabled = lazy(() => import('./ErrorIcon/Disabled'));
const ErrorIconError = lazy(() => import('./ErrorIcon/Error'));
const ErrorIconSuccess = lazy(() => import('./ErrorIcon/Success'));
const ErrorIconInfo = lazy(() => import('./ErrorIcon/Info'));
const ExperiencesCustomDsLight = lazy(() => import('./ExperiencesCustomDs/Light'));
const ExperiencesCustomDsDark = lazy(() => import('./ExperiencesCustomDs/Dark'));
const ExperiencesCustomDsSecondary = lazy(() => import('./ExperiencesCustomDs/Secondary'));
const ExperiencesCustomDsPrimary = lazy(() => import('./ExperiencesCustomDs/Primary'));
const ExperiencesCustomDsDisabled = lazy(() => import('./ExperiencesCustomDs/Disabled'));
const ExperiencesCustomDsError = lazy(() => import('./ExperiencesCustomDs/Error'));
const ExperiencesCustomDsSuccess = lazy(() => import('./ExperiencesCustomDs/Success'));
const ExperiencesCustomDsInfo = lazy(() => import('./ExperiencesCustomDs/Info'));
const ExperiencesCustomLight = lazy(() => import('./ExperiencesCustom/Light'));
const ExperiencesCustomDark = lazy(() => import('./ExperiencesCustom/Dark'));
const ExperiencesCustomSecondary = lazy(() => import('./ExperiencesCustom/Secondary'));
const ExperiencesCustomPrimary = lazy(() => import('./ExperiencesCustom/Primary'));
const ExperiencesCustomDisabled = lazy(() => import('./ExperiencesCustom/Disabled'));
const ExperiencesCustomError = lazy(() => import('./ExperiencesCustom/Error'));
const ExperiencesCustomSuccess = lazy(() => import('./ExperiencesCustom/Success'));
const ExperiencesCustomInfo = lazy(() => import('./ExperiencesCustom/Info'));
const FileTextDsLight = lazy(() => import('./FileTextDs/Light'));
const FileTextDsDark = lazy(() => import('./FileTextDs/Dark'));
const FileTextDsSecondary = lazy(() => import('./FileTextDs/Secondary'));
const FileTextDsPrimary = lazy(() => import('./FileTextDs/Primary'));
const FileTextDsDisabled = lazy(() => import('./FileTextDs/Disabled'));
const FileTextDsError = lazy(() => import('./FileTextDs/Error'));
const FileTextDsSuccess = lazy(() => import('./FileTextDs/Success'));
const FileTextDsInfo = lazy(() => import('./FileTextDs/Info'));
const FileTextLight = lazy(() => import('./FileText/Light'));
const FileTextDark = lazy(() => import('./FileText/Dark'));
const FileTextSecondary = lazy(() => import('./FileText/Secondary'));
const FileTextPrimary = lazy(() => import('./FileText/Primary'));
const FileTextDisabled = lazy(() => import('./FileText/Disabled'));
const FileTextError = lazy(() => import('./FileText/Error'));
const FileTextSuccess = lazy(() => import('./FileText/Success'));
const FileTextInfo = lazy(() => import('./FileText/Info'));
const FlashCustomDsLight = lazy(() => import('./FlashCustomDs/Light'));
const FlashCustomDsDark = lazy(() => import('./FlashCustomDs/Dark'));
const FlashCustomDsSecondary = lazy(() => import('./FlashCustomDs/Secondary'));
const FlashCustomDsPrimary = lazy(() => import('./FlashCustomDs/Primary'));
const FlashCustomDsDisabled = lazy(() => import('./FlashCustomDs/Disabled'));
const FlashCustomDsError = lazy(() => import('./FlashCustomDs/Error'));
const FlashCustomDsSuccess = lazy(() => import('./FlashCustomDs/Success'));
const FlashCustomDsInfo = lazy(() => import('./FlashCustomDs/Info'));
const FlashCustomLight = lazy(() => import('./FlashCustom/Light'));
const FlashCustomDark = lazy(() => import('./FlashCustom/Dark'));
const FlashCustomSecondary = lazy(() => import('./FlashCustom/Secondary'));
const FlashCustomPrimary = lazy(() => import('./FlashCustom/Primary'));
const FlashCustomDisabled = lazy(() => import('./FlashCustom/Disabled'));
const FlashCustomError = lazy(() => import('./FlashCustom/Error'));
const FlashCustomSuccess = lazy(() => import('./FlashCustom/Success'));
const FlashCustomInfo = lazy(() => import('./FlashCustom/Info'));
const FlashOffCustomDsLight = lazy(() => import('./FlashOffCustomDs/Light'));
const FlashOffCustomDsDark = lazy(() => import('./FlashOffCustomDs/Dark'));
const FlashOffCustomDsSecondary = lazy(() => import('./FlashOffCustomDs/Secondary'));
const FlashOffCustomDsPrimary = lazy(() => import('./FlashOffCustomDs/Primary'));
const FlashOffCustomDsDisabled = lazy(() => import('./FlashOffCustomDs/Disabled'));
const FlashOffCustomDsError = lazy(() => import('./FlashOffCustomDs/Error'));
const FlashOffCustomDsSuccess = lazy(() => import('./FlashOffCustomDs/Success'));
const FlashOffCustomDsInfo = lazy(() => import('./FlashOffCustomDs/Info'));
const FlashOffCustomLight = lazy(() => import('./FlashOffCustom/Light'));
const FlashOffCustomDark = lazy(() => import('./FlashOffCustom/Dark'));
const FlashOffCustomSecondary = lazy(() => import('./FlashOffCustom/Secondary'));
const FlashOffCustomPrimary = lazy(() => import('./FlashOffCustom/Primary'));
const FlashOffCustomDisabled = lazy(() => import('./FlashOffCustom/Disabled'));
const FlashOffCustomError = lazy(() => import('./FlashOffCustom/Error'));
const FlashOffCustomSuccess = lazy(() => import('./FlashOffCustom/Success'));
const FlashOffCustomInfo = lazy(() => import('./FlashOffCustom/Info'));
const GlobeDsLight = lazy(() => import('./GlobeDs/Light'));
const GlobeDsDark = lazy(() => import('./GlobeDs/Dark'));
const GlobeDsSecondary = lazy(() => import('./GlobeDs/Secondary'));
const GlobeDsPrimary = lazy(() => import('./GlobeDs/Primary'));
const GlobeDsDisabled = lazy(() => import('./GlobeDs/Disabled'));
const GlobeDsError = lazy(() => import('./GlobeDs/Error'));
const GlobeDsSuccess = lazy(() => import('./GlobeDs/Success'));
const GlobeDsInfo = lazy(() => import('./GlobeDs/Info'));
const GlobeLight = lazy(() => import('./Globe/Light'));
const GlobeDark = lazy(() => import('./Globe/Dark'));
const GlobeSecondary = lazy(() => import('./Globe/Secondary'));
const GlobePrimary = lazy(() => import('./Globe/Primary'));
const GlobeDisabled = lazy(() => import('./Globe/Disabled'));
const GlobeError = lazy(() => import('./Globe/Error'));
const GlobeSuccess = lazy(() => import('./Globe/Success'));
const GlobeInfo = lazy(() => import('./Globe/Info'));
const HelpDsLight = lazy(() => import('./HelpDs/Light'));
const HelpDsDark = lazy(() => import('./HelpDs/Dark'));
const HelpDsSecondary = lazy(() => import('./HelpDs/Secondary'));
const HelpDsPrimary = lazy(() => import('./HelpDs/Primary'));
const HelpDsDisabled = lazy(() => import('./HelpDs/Disabled'));
const HelpDsError = lazy(() => import('./HelpDs/Error'));
const HelpDsSuccess = lazy(() => import('./HelpDs/Success'));
const HelpDsInfo = lazy(() => import('./HelpDs/Info'));
const HelpLight = lazy(() => import('./Help/Light'));
const HelpDark = lazy(() => import('./Help/Dark'));
const HelpSecondary = lazy(() => import('./Help/Secondary'));
const HelpPrimary = lazy(() => import('./Help/Primary'));
const HelpDisabled = lazy(() => import('./Help/Disabled'));
const HelpError = lazy(() => import('./Help/Error'));
const HelpSuccess = lazy(() => import('./Help/Success'));
const HelpInfo = lazy(() => import('./Help/Info'));
const HomeDsLight = lazy(() => import('./HomeDs/Light'));
const HomeDsDark = lazy(() => import('./HomeDs/Dark'));
const HomeDsSecondary = lazy(() => import('./HomeDs/Secondary'));
const HomeDsPrimary = lazy(() => import('./HomeDs/Primary'));
const HomeDsDisabled = lazy(() => import('./HomeDs/Disabled'));
const HomeDsError = lazy(() => import('./HomeDs/Error'));
const HomeDsSuccess = lazy(() => import('./HomeDs/Success'));
const HomeDsInfo = lazy(() => import('./HomeDs/Info'));
const HomeLight = lazy(() => import('./Home/Light'));
const HomeDark = lazy(() => import('./Home/Dark'));
const HomeSecondary = lazy(() => import('./Home/Secondary'));
const HomePrimary = lazy(() => import('./Home/Primary'));
const HomeDisabled = lazy(() => import('./Home/Disabled'));
const HomeError = lazy(() => import('./Home/Error'));
const HomeSuccess = lazy(() => import('./Home/Success'));
const HomeInfo = lazy(() => import('./Home/Info'));
const KeyCustomDsLight = lazy(() => import('./KeyCustomDs/Light'));
const KeyCustomDsDark = lazy(() => import('./KeyCustomDs/Dark'));
const KeyCustomDsSecondary = lazy(() => import('./KeyCustomDs/Secondary'));
const KeyCustomDsPrimary = lazy(() => import('./KeyCustomDs/Primary'));
const KeyCustomDsDisabled = lazy(() => import('./KeyCustomDs/Disabled'));
const KeyCustomDsError = lazy(() => import('./KeyCustomDs/Error'));
const KeyCustomDsSuccess = lazy(() => import('./KeyCustomDs/Success'));
const KeyCustomDsInfo = lazy(() => import('./KeyCustomDs/Info'));
const KeyCustomLight = lazy(() => import('./KeyCustom/Light'));
const KeyCustomDark = lazy(() => import('./KeyCustom/Dark'));
const KeyCustomSecondary = lazy(() => import('./KeyCustom/Secondary'));
const KeyCustomPrimary = lazy(() => import('./KeyCustom/Primary'));
const KeyCustomDisabled = lazy(() => import('./KeyCustom/Disabled'));
const KeyCustomError = lazy(() => import('./KeyCustom/Error'));
const KeyCustomSuccess = lazy(() => import('./KeyCustom/Success'));
const KeyCustomInfo = lazy(() => import('./KeyCustom/Info'));
const LikeFilledDsLight = lazy(() => import('./LikeFilledDs/Light'));
const LikeFilledDsDark = lazy(() => import('./LikeFilledDs/Dark'));
const LikeFilledDsSecondary = lazy(() => import('./LikeFilledDs/Secondary'));
const LikeFilledDsPrimary = lazy(() => import('./LikeFilledDs/Primary'));
const LikeFilledDsDisabled = lazy(() => import('./LikeFilledDs/Disabled'));
const LikeFilledDsError = lazy(() => import('./LikeFilledDs/Error'));
const LikeFilledDsSuccess = lazy(() => import('./LikeFilledDs/Success'));
const LikeFilledDsInfo = lazy(() => import('./LikeFilledDs/Info'));
const LikeFilledLight = lazy(() => import('./LikeFilled/Light'));
const LikeFilledDark = lazy(() => import('./LikeFilled/Dark'));
const LikeFilledSecondary = lazy(() => import('./LikeFilled/Secondary'));
const LikeFilledPrimary = lazy(() => import('./LikeFilled/Primary'));
const LikeFilledDisabled = lazy(() => import('./LikeFilled/Disabled'));
const LikeFilledError = lazy(() => import('./LikeFilled/Error'));
const LikeFilledSuccess = lazy(() => import('./LikeFilled/Success'));
const LikeFilledInfo = lazy(() => import('./LikeFilled/Info'));
const LikeUnfilledDsLight = lazy(() => import('./LikeUnfilledDs/Light'));
const LikeUnfilledDsDark = lazy(() => import('./LikeUnfilledDs/Dark'));
const LikeUnfilledDsSecondary = lazy(() => import('./LikeUnfilledDs/Secondary'));
const LikeUnfilledDsPrimary = lazy(() => import('./LikeUnfilledDs/Primary'));
const LikeUnfilledDsDisabled = lazy(() => import('./LikeUnfilledDs/Disabled'));
const LikeUnfilledDsError = lazy(() => import('./LikeUnfilledDs/Error'));
const LikeUnfilledDsSuccess = lazy(() => import('./LikeUnfilledDs/Success'));
const LikeUnfilledDsInfo = lazy(() => import('./LikeUnfilledDs/Info'));
const LikeUnfilledLight = lazy(() => import('./LikeUnfilled/Light'));
const LikeUnfilledDark = lazy(() => import('./LikeUnfilled/Dark'));
const LikeUnfilledSecondary = lazy(() => import('./LikeUnfilled/Secondary'));
const LikeUnfilledPrimary = lazy(() => import('./LikeUnfilled/Primary'));
const LikeUnfilledDisabled = lazy(() => import('./LikeUnfilled/Disabled'));
const LikeUnfilledError = lazy(() => import('./LikeUnfilled/Error'));
const LikeUnfilledSuccess = lazy(() => import('./LikeUnfilled/Success'));
const LikeUnfilledInfo = lazy(() => import('./LikeUnfilled/Info'));
const LinkCustomDsLight = lazy(() => import('./LinkCustomDs/Light'));
const LinkCustomDsDark = lazy(() => import('./LinkCustomDs/Dark'));
const LinkCustomDsSecondary = lazy(() => import('./LinkCustomDs/Secondary'));
const LinkCustomDsPrimary = lazy(() => import('./LinkCustomDs/Primary'));
const LinkCustomDsDisabled = lazy(() => import('./LinkCustomDs/Disabled'));
const LinkCustomDsError = lazy(() => import('./LinkCustomDs/Error'));
const LinkCustomDsSuccess = lazy(() => import('./LinkCustomDs/Success'));
const LinkCustomDsInfo = lazy(() => import('./LinkCustomDs/Info'));
const LinkCustomLight = lazy(() => import('./LinkCustom/Light'));
const LinkCustomDark = lazy(() => import('./LinkCustom/Dark'));
const LinkCustomSecondary = lazy(() => import('./LinkCustom/Secondary'));
const LinkCustomPrimary = lazy(() => import('./LinkCustom/Primary'));
const LinkCustomDisabled = lazy(() => import('./LinkCustom/Disabled'));
const LinkCustomError = lazy(() => import('./LinkCustom/Error'));
const LinkCustomSuccess = lazy(() => import('./LinkCustom/Success'));
const LinkCustomInfo = lazy(() => import('./LinkCustom/Info'));
const LockDsLight = lazy(() => import('./LockDs/Light'));
const LockDsDark = lazy(() => import('./LockDs/Dark'));
const LockDsSecondary = lazy(() => import('./LockDs/Secondary'));
const LockDsPrimary = lazy(() => import('./LockDs/Primary'));
const LockDsDisabled = lazy(() => import('./LockDs/Disabled'));
const LockDsError = lazy(() => import('./LockDs/Error'));
const LockDsSuccess = lazy(() => import('./LockDs/Success'));
const LockDsInfo = lazy(() => import('./LockDs/Info'));
const LockLight = lazy(() => import('./Lock/Light'));
const LockDark = lazy(() => import('./Lock/Dark'));
const LockSecondary = lazy(() => import('./Lock/Secondary'));
const LockPrimary = lazy(() => import('./Lock/Primary'));
const LockDisabled = lazy(() => import('./Lock/Disabled'));
const LockError = lazy(() => import('./Lock/Error'));
const LockSuccess = lazy(() => import('./Lock/Success'));
const LockInfo = lazy(() => import('./Lock/Info'));
const LogoutDsLight = lazy(() => import('./LogoutDs/Light'));
const LogoutDsDark = lazy(() => import('./LogoutDs/Dark'));
const LogoutDsSecondary = lazy(() => import('./LogoutDs/Secondary'));
const LogoutDsPrimary = lazy(() => import('./LogoutDs/Primary'));
const LogoutDsDisabled = lazy(() => import('./LogoutDs/Disabled'));
const LogoutDsError = lazy(() => import('./LogoutDs/Error'));
const LogoutDsSuccess = lazy(() => import('./LogoutDs/Success'));
const LogoutDsInfo = lazy(() => import('./LogoutDs/Info'));
const LogoutLight = lazy(() => import('./Logout/Light'));
const LogoutDark = lazy(() => import('./Logout/Dark'));
const LogoutSecondary = lazy(() => import('./Logout/Secondary'));
const LogoutPrimary = lazy(() => import('./Logout/Primary'));
const LogoutDisabled = lazy(() => import('./Logout/Disabled'));
const LogoutError = lazy(() => import('./Logout/Error'));
const LogoutSuccess = lazy(() => import('./Logout/Success'));
const LogoutInfo = lazy(() => import('./Logout/Info'));
const MasonryCustomDsLight = lazy(() => import('./MasonryCustomDs/Light'));
const MasonryCustomDsDark = lazy(() => import('./MasonryCustomDs/Dark'));
const MasonryCustomDsSecondary = lazy(() => import('./MasonryCustomDs/Secondary'));
const MasonryCustomDsPrimary = lazy(() => import('./MasonryCustomDs/Primary'));
const MasonryCustomDsDisabled = lazy(() => import('./MasonryCustomDs/Disabled'));
const MasonryCustomDsError = lazy(() => import('./MasonryCustomDs/Error'));
const MasonryCustomDsSuccess = lazy(() => import('./MasonryCustomDs/Success'));
const MasonryCustomDsInfo = lazy(() => import('./MasonryCustomDs/Info'));
const MasonryCustomLight = lazy(() => import('./MasonryCustom/Light'));
const MasonryCustomDark = lazy(() => import('./MasonryCustom/Dark'));
const MasonryCustomSecondary = lazy(() => import('./MasonryCustom/Secondary'));
const MasonryCustomPrimary = lazy(() => import('./MasonryCustom/Primary'));
const MasonryCustomDisabled = lazy(() => import('./MasonryCustom/Disabled'));
const MasonryCustomError = lazy(() => import('./MasonryCustom/Error'));
const MasonryCustomSuccess = lazy(() => import('./MasonryCustom/Success'));
const MasonryCustomInfo = lazy(() => import('./MasonryCustom/Info'));
const NotificationsDsLight = lazy(() => import('./NotificationsDs/Light'));
const NotificationsDsDark = lazy(() => import('./NotificationsDs/Dark'));
const NotificationsDsSecondary = lazy(() => import('./NotificationsDs/Secondary'));
const NotificationsDsPrimary = lazy(() => import('./NotificationsDs/Primary'));
const NotificationsDsDisabled = lazy(() => import('./NotificationsDs/Disabled'));
const NotificationsDsError = lazy(() => import('./NotificationsDs/Error'));
const NotificationsDsSuccess = lazy(() => import('./NotificationsDs/Success'));
const NotificationsDsInfo = lazy(() => import('./NotificationsDs/Info'));
const NotificationsLight = lazy(() => import('./Notifications/Light'));
const NotificationsDark = lazy(() => import('./Notifications/Dark'));
const NotificationsSecondary = lazy(() => import('./Notifications/Secondary'));
const NotificationsPrimary = lazy(() => import('./Notifications/Primary'));
const NotificationsDisabled = lazy(() => import('./Notifications/Disabled'));
const NotificationsError = lazy(() => import('./Notifications/Error'));
const NotificationsSuccess = lazy(() => import('./Notifications/Success'));
const NotificationsInfo = lazy(() => import('./Notifications/Info'));
const PaletteDsLight = lazy(() => import('./PaletteDs/Light'));
const PaletteDsDark = lazy(() => import('./PaletteDs/Dark'));
const PaletteDsSecondary = lazy(() => import('./PaletteDs/Secondary'));
const PaletteDsPrimary = lazy(() => import('./PaletteDs/Primary'));
const PaletteDsDisabled = lazy(() => import('./PaletteDs/Disabled'));
const PaletteDsError = lazy(() => import('./PaletteDs/Error'));
const PaletteDsSuccess = lazy(() => import('./PaletteDs/Success'));
const PaletteDsInfo = lazy(() => import('./PaletteDs/Info'));
const PaletteLight = lazy(() => import('./Palette/Light'));
const PaletteDark = lazy(() => import('./Palette/Dark'));
const PaletteSecondary = lazy(() => import('./Palette/Secondary'));
const PalettePrimary = lazy(() => import('./Palette/Primary'));
const PaletteDisabled = lazy(() => import('./Palette/Disabled'));
const PaletteError = lazy(() => import('./Palette/Error'));
const PaletteSuccess = lazy(() => import('./Palette/Success'));
const PaletteInfo = lazy(() => import('./Palette/Info'));
const PersonOutlineDsLight = lazy(() => import('./PersonOutlineDs/Light'));
const PersonOutlineDsDark = lazy(() => import('./PersonOutlineDs/Dark'));
const PersonOutlineDsSecondary = lazy(() => import('./PersonOutlineDs/Secondary'));
const PersonOutlineDsPrimary = lazy(() => import('./PersonOutlineDs/Primary'));
const PersonOutlineDsDisabled = lazy(() => import('./PersonOutlineDs/Disabled'));
const PersonOutlineDsError = lazy(() => import('./PersonOutlineDs/Error'));
const PersonOutlineDsSuccess = lazy(() => import('./PersonOutlineDs/Success'));
const PersonOutlineDsInfo = lazy(() => import('./PersonOutlineDs/Info'));
const PersonOutlineLight = lazy(() => import('./PersonOutline/Light'));
const PersonOutlineDark = lazy(() => import('./PersonOutline/Dark'));
const PersonOutlineSecondary = lazy(() => import('./PersonOutline/Secondary'));
const PersonOutlinePrimary = lazy(() => import('./PersonOutline/Primary'));
const PersonOutlineDisabled = lazy(() => import('./PersonOutline/Disabled'));
const PersonOutlineError = lazy(() => import('./PersonOutline/Error'));
const PersonOutlineSuccess = lazy(() => import('./PersonOutline/Success'));
const PersonOutlineInfo = lazy(() => import('./PersonOutline/Info'));
const RecommendedForCustomDsLight = lazy(() => import('./RecommendedForCustomDs/Light'));
const RecommendedForCustomDsDark = lazy(() => import('./RecommendedForCustomDs/Dark'));
const RecommendedForCustomDsSecondary = lazy(() => import('./RecommendedForCustomDs/Secondary'));
const RecommendedForCustomDsPrimary = lazy(() => import('./RecommendedForCustomDs/Primary'));
const RecommendedForCustomDsDisabled = lazy(() => import('./RecommendedForCustomDs/Disabled'));
const RecommendedForCustomDsError = lazy(() => import('./RecommendedForCustomDs/Error'));
const RecommendedForCustomDsSuccess = lazy(() => import('./RecommendedForCustomDs/Success'));
const RecommendedForCustomDsInfo = lazy(() => import('./RecommendedForCustomDs/Info'));
const RecommendedForCustomLight = lazy(() => import('./RecommendedForCustom/Light'));
const RecommendedForCustomDark = lazy(() => import('./RecommendedForCustom/Dark'));
const RecommendedForCustomSecondary = lazy(() => import('./RecommendedForCustom/Secondary'));
const RecommendedForCustomPrimary = lazy(() => import('./RecommendedForCustom/Primary'));
const RecommendedForCustomDisabled = lazy(() => import('./RecommendedForCustom/Disabled'));
const RecommendedForCustomError = lazy(() => import('./RecommendedForCustom/Error'));
const RecommendedForCustomSuccess = lazy(() => import('./RecommendedForCustom/Success'));
const RecommendedForCustomInfo = lazy(() => import('./RecommendedForCustom/Info'));
const RedoDisabledCustomDsLight = lazy(() => import('./RedoDisabledCustomDs/Light'));
const RedoDisabledCustomDsDark = lazy(() => import('./RedoDisabledCustomDs/Dark'));
const RedoDisabledCustomDsSecondary = lazy(() => import('./RedoDisabledCustomDs/Secondary'));
const RedoDisabledCustomDsPrimary = lazy(() => import('./RedoDisabledCustomDs/Primary'));
const RedoDisabledCustomDsDisabled = lazy(() => import('./RedoDisabledCustomDs/Disabled'));
const RedoDisabledCustomDsError = lazy(() => import('./RedoDisabledCustomDs/Error'));
const RedoDisabledCustomDsSuccess = lazy(() => import('./RedoDisabledCustomDs/Success'));
const RedoDisabledCustomDsInfo = lazy(() => import('./RedoDisabledCustomDs/Info'));
const RedoDisabledCustomLight = lazy(() => import('./RedoDisabledCustom/Light'));
const RedoDisabledCustomDark = lazy(() => import('./RedoDisabledCustom/Dark'));
const RedoDisabledCustomSecondary = lazy(() => import('./RedoDisabledCustom/Secondary'));
const RedoDisabledCustomPrimary = lazy(() => import('./RedoDisabledCustom/Primary'));
const RedoDisabledCustomDisabled = lazy(() => import('./RedoDisabledCustom/Disabled'));
const RedoDisabledCustomError = lazy(() => import('./RedoDisabledCustom/Error'));
const RedoDisabledCustomSuccess = lazy(() => import('./RedoDisabledCustom/Success'));
const RedoDisabledCustomInfo = lazy(() => import('./RedoDisabledCustom/Info'));
const RefreshLight = lazy(() => import('./Refresh/Light'));
const RefreshDark = lazy(() => import('./Refresh/Dark'));
const RefreshSecondary = lazy(() => import('./Refresh/Secondary'));
const RefreshPrimary = lazy(() => import('./Refresh/Primary'));
const RefreshDisabled = lazy(() => import('./Refresh/Disabled'));
const RefreshError = lazy(() => import('./Refresh/Error'));
const RefreshSuccess = lazy(() => import('./Refresh/Success'));
const RefreshDsLight = lazy(() => import('./RefreshDs/Light'));
const RefreshDsDark = lazy(() => import('./RefreshDs/Dark'));
const RefreshDsSecondary = lazy(() => import('./RefreshDs/Secondary'));
const RefreshDsPrimary = lazy(() => import('./RefreshDs/Primary'))
const RefreshDsDisabled = lazy(() => import('./RefreshDs/Disabled'));
const RefreshDsError = lazy(() => import('./RefreshDs/Error'));
const RefreshDsSuccess = lazy(() => import('./RefreshDs/Success'));
const RhinoDsLight = lazy(() => import('./RhinoDs/Light'));
const RhinoDsDark = lazy(() => import('./RhinoDs/Dark'));
const RhinoDsSecondary = lazy(() => import('./RhinoDs/Secondary'));
const RhinoDsPrimary = lazy(() => import('./RhinoDs/Primary'));
const RhinoDsDisabled = lazy(() => import('./RhinoDs/Disabled'));
const RhinoDsError = lazy(() => import('./RhinoDs/Error'));
const RhinoDsSuccess = lazy(() => import('./RhinoDs/Success'));
const RhinoDsInfo = lazy(() => import('./RhinoDs/Info'));
const RhinoLight = lazy(() => import('./Rhino/Light'));
const RhinoDark = lazy(() => import('./Rhino/Dark'));
const RhinoSecondary = lazy(() => import('./Rhino/Secondary'));
const RhinoPrimary = lazy(() => import('./Rhino/Primary'));
const RhinoDisabled = lazy(() => import('./Rhino/Disabled'));
const RhinoError = lazy(() => import('./Rhino/Error'));
const RhinoSuccess = lazy(() => import('./Rhino/Success'));
const RhinoInfo = lazy(() => import('./Rhino/Info'));
const SaveEmptyCustomLight = lazy(() => import('./SaveEmptyCustom/Light'))
const SaveEmptyCustomDark = lazy(() => import('./SaveEmptyCustom/Light'))
const SaveFilledCustomLight = lazy(() => import('./SaveFilledCustom/Light'))
const SaveFilledCustomDark = lazy(() => import('./SaveFilledCustom/Dark'))
const SearchLight = lazy(() => import('./Search/Light'));
const SearchDark = lazy(() => import('./Search/Dark'));
const SearchSecondary = lazy(() => import('./Search/Secondary'));
const SearchPrimary = lazy(() => import('./Search/Primary'));
const SearchDisabled = lazy(() => import('./Search/Disabled'));
const SearchError = lazy(() => import('./Search/Error'));
const SearchSuccess = lazy(() => import('./Search/Success'));
const SearchInfo = lazy(() => import('./Search/Info'));
const SearchIconDsLight = lazy(() => import('./SearchIconDs/Light'));
const SearchIconDsDark = lazy(() => import('./SearchIconDs/Dark'));
const SearchIconDsSecondary = lazy(() => import('./SearchIconDs/Secondary'));
const SearchIconDsPrimary = lazy(() => import('./SearchIconDs/Primary'));
const SearchIconDsDisabled = lazy(() => import('./SearchIconDs/Disabled'));
const SearchIconDsError = lazy(() => import('./SearchIconDs/Error'));
const SearchIconDsSuccess = lazy(() => import('./SearchIconDs/Success'));
const SearchIconDsInfo = lazy(() => import('./SearchIconDs/Info'));
const SelectCustomDsLight = lazy(() => import('./SelectCustomDs/Light'));
const SelectCustomDsDark = lazy(() => import('./SelectCustomDs/Dark'));
const SelectCustomDsSecondary = lazy(() => import('./SelectCustomDs/Secondary'));
const SelectCustomDsPrimary = lazy(() => import('./SelectCustomDs/Primary'));
const SelectCustomDsDisabled = lazy(() => import('./SelectCustomDs/Disabled'));
const SelectCustomDsError = lazy(() => import('./SelectCustomDs/Error'));
const SelectCustomDsSuccess = lazy(() => import('./SelectCustomDs/Success'));
const SelectCustomDsInfo = lazy(() => import('./SelectCustomDs/Info'));
const SelectCustomLight = lazy(() => import('./SelectCustom/Light'));
const SelectCustomDark = lazy(() => import('./SelectCustom/Dark'));
const SelectCustomSecondary = lazy(() => import('./SelectCustom/Secondary'));
const SelectCustomPrimary = lazy(() => import('./SelectCustom/Primary'));
const SelectCustomDisabled = lazy(() => import('./SelectCustom/Disabled'));
const SelectCustomError = lazy(() => import('./SelectCustom/Error'));
const SelectCustomSuccess = lazy(() => import('./SelectCustom/Success'));
const SelectCustomInfo = lazy(() => import('./SelectCustom/Info'));
const SendCustomDsLight = lazy(() => import('./SendCustomDs/Light'));
const SendCustomDsDark = lazy(() => import('./SendCustomDs/Dark'));
const SendCustomDsSecondary = lazy(() => import('./SendCustomDs/Secondary'));
const SendCustomDsPrimary = lazy(() => import('./SendCustomDs/Primary'));
const SendCustomDsDisabled = lazy(() => import('./SendCustomDs/Disabled'));
const SendCustomDsError = lazy(() => import('./SendCustomDs/Error'));
const SendCustomDsSuccess = lazy(() => import('./SendCustomDs/Success'));
const SendCustomDsInfo = lazy(() => import('./SendCustomDs/Info'));
const SendCustomLight = lazy(() => import('./SendCustom/Light'));
const SendCustomDark = lazy(() => import('./SendCustom/Dark'));
const SendCustomSecondary = lazy(() => import('./SendCustom/Secondary'));
const SendCustomPrimary = lazy(() => import('./SendCustom/Primary'));
const SendCustomDisabled = lazy(() => import('./SendCustom/Disabled'));
const SendCustomError = lazy(() => import('./SendCustom/Error'));
const SendCustomSuccess = lazy(() => import('./SendCustom/Success'));
const SendCustomInfo = lazy(() => import('./SendCustom/Info'));
const ShareCustomDsLight = lazy(() => import('./ShareCustomDs/Light'));
const ShareCustomDsDark = lazy(() => import('./ShareCustomDs/Dark'));
const ShareCustomDsSecondary = lazy(() => import('./ShareCustomDs/Secondary'));
const ShareCustomDsPrimary = lazy(() => import('./ShareCustomDs/Primary'));
const ShareCustomDsDisabled = lazy(() => import('./ShareCustomDs/Disabled'));
const ShareCustomDsError = lazy(() => import('./ShareCustomDs/Error'));
const ShareCustomDsSuccess = lazy(() => import('./ShareCustomDs/Success'));
const ShareCustomDsInfo = lazy(() => import('./ShareCustomDs/Info'));
const ShareCustomLight = lazy(() => import('./ShareCustom/Light'));
const ShareCustomDark = lazy(() => import('./ShareCustom/Dark'));
const ShareCustomSecondary = lazy(() => import('./ShareCustom/Secondary'));
const ShareCustomPrimary = lazy(() => import('./ShareCustom/Primary'));
const ShareCustomDisabled = lazy(() => import('./ShareCustom/Disabled'));
const ShareCustomError = lazy(() => import('./ShareCustom/Error'));
const ShareCustomSuccess = lazy(() => import('./ShareCustom/Success'));
const ShareCustomInfo = lazy(() => import('./ShareCustom/Info'));
const ShareIconDsLight = lazy(() => import('./ShareIconDs/Light'));
const ShareIconDsDark = lazy(() => import('./ShareIconDs/Dark'));
const ShareIconDsSecondary = lazy(() => import('./ShareIconDs/Secondary'));
const ShareIconDsPrimary = lazy(() => import('./ShareIconDs/Primary'));
const ShareIconDsDisabled = lazy(() => import('./ShareIconDs/Disabled'));
const ShareIconDsError = lazy(() => import('./ShareIconDs/Error'));
const ShareIconDsSuccess = lazy(() => import('./ShareIconDs/Success'));
const ShareIconDsInfo = lazy(() => import('./ShareIconDs/Info'));
const ShareIconLight = lazy(() => import('./ShareIcon/Light'));
const ShareIconDark = lazy(() => import('./ShareIcon/Dark'));
const ShareIconSecondary = lazy(() => import('./ShareIcon/Secondary'));
const ShareIconPrimary = lazy(() => import('./ShareIcon/Primary'));
const ShareIconDisabled = lazy(() => import('./ShareIcon/Disabled'));
const ShareIconError = lazy(() => import('./ShareIcon/Error'));
const ShareIconSuccess = lazy(() => import('./ShareIcon/Success'));
const ShareIconInfo = lazy(() => import('./ShareIcon/Info'));
const SmsCustomDsLight = lazy(() => import('./SmsCustomDs/Light'));
const SmsCustomDsDark = lazy(() => import('./SmsCustomDs/Dark'));
const SmsCustomDsSecondary = lazy(() => import('./SmsCustomDs/Secondary'));
const SmsCustomDsPrimary = lazy(() => import('./SmsCustomDs/Primary'));
const SmsCustomDsDisabled = lazy(() => import('./SmsCustomDs/Disabled'));
const SmsCustomDsError = lazy(() => import('./SmsCustomDs/Error'));
const SmsCustomDsSuccess = lazy(() => import('./SmsCustomDs/Success'));
const SmsCustomDsInfo = lazy(() => import('./SmsCustomDs/Info'));
const SmsCustomLight = lazy(() => import('./SmsCustom/Light'));
const SmsCustomDark = lazy(() => import('./SmsCustom/Dark'));
const SmsCustomSecondary = lazy(() => import('./SmsCustom/Secondary'));
const SmsCustomPrimary = lazy(() => import('./SmsCustom/Primary'));
const SmsCustomDisabled = lazy(() => import('./SmsCustom/Disabled'));
const SmsCustomError = lazy(() => import('./SmsCustom/Error'));
const SmsCustomSuccess = lazy(() => import('./SmsCustom/Success'));
const SmsCustomInfo = lazy(() => import('./SmsCustom/Info'));
const SoundOffCustomDsLight = lazy(() => import('./SoundOffCustomDs/Light'));
const SoundOffCustomDsDark = lazy(() => import('./SoundOffCustomDs/Dark'));
const SoundOffCustomDsSecondary = lazy(() => import('./SoundOffCustomDs/Secondary'));
const SoundOffCustomDsPrimary = lazy(() => import('./SoundOffCustomDs/Primary'));
const SoundOffCustomDsDisabled = lazy(() => import('./SoundOffCustomDs/Disabled'));
const SoundOffCustomDsError = lazy(() => import('./SoundOffCustomDs/Error'));
const SoundOffCustomDsSuccess = lazy(() => import('./SoundOffCustomDs/Success'));
const SoundOffCustomDsInfo = lazy(() => import('./SoundOffCustomDs/Info'));
const SoundOffCustomLight = lazy(() => import('./SoundOffCustom/Light'));
const SoundOffCustomDark = lazy(() => import('./SoundOffCustom/Dark'));
const SoundOffCustomSecondary = lazy(() => import('./SoundOffCustom/Secondary'));
const SoundOffCustomPrimary = lazy(() => import('./SoundOffCustom/Primary'));
const SoundOffCustomDisabled = lazy(() => import('./SoundOffCustom/Disabled'));
const SoundOffCustomError = lazy(() => import('./SoundOffCustom/Error'));
const SoundOffCustomSuccess = lazy(() => import('./SoundOffCustom/Success'));
const SoundOffCustomInfo = lazy(() => import('./SoundOffCustom/Info'));
const SoundOnCustomDsLight = lazy(() => import('./SoundOnCustomDs/Light'));
const SoundOnCustomDsDark = lazy(() => import('./SoundOnCustomDs/Dark'));
const SoundOnCustomDsSecondary = lazy(() => import('./SoundOnCustomDs/Secondary'));
const SoundOnCustomDsPrimary = lazy(() => import('./SoundOnCustomDs/Primary'));
const SoundOnCustomDsDisabled = lazy(() => import('./SoundOnCustomDs/Disabled'));
const SoundOnCustomDsError = lazy(() => import('./SoundOnCustomDs/Error'));
const SoundOnCustomDsSuccess = lazy(() => import('./SoundOnCustomDs/Success'));
const SoundOnCustomDsInfo = lazy(() => import('./SoundOnCustomDs/Info'));
const SoundOnCustomLight = lazy(() => import('./SoundOnCustom/Light'));
const SoundOnCustomDark = lazy(() => import('./SoundOnCustom/Dark'));
const SoundOnCustomSecondary = lazy(() => import('./SoundOnCustom/Secondary'));
const SoundOnCustomPrimary = lazy(() => import('./SoundOnCustom/Primary'));
const SoundOnCustomDisabled = lazy(() => import('./SoundOnCustom/Disabled'));
const SoundOnCustomError = lazy(() => import('./SoundOnCustom/Error'));
const SoundOnCustomSuccess = lazy(() => import('./SoundOnCustom/Success'));
const SoundOnCustomInfo = lazy(() => import('./SoundOnCustom/Info'));
const StickersCustomDsLight = lazy(() => import('./StickersCustomDs/Light'));
const StickersCustomDsDark = lazy(() => import('./StickersCustomDs/Dark'));
const StickersCustomDsSecondary = lazy(() => import('./StickersCustomDs/Secondary'));
const StickersCustomDsPrimary = lazy(() => import('./StickersCustomDs/Primary'));
const StickersCustomDsDisabled = lazy(() => import('./StickersCustomDs/Disabled'));
const StickersCustomDsError = lazy(() => import('./StickersCustomDs/Error'));
const StickersCustomDsSuccess = lazy(() => import('./StickersCustomDs/Success'));
const StickersCustomDsInfo = lazy(() => import('./StickersCustomDs/Info'));
const StickersCustomLight = lazy(() => import('./StickersCustom/Light'));
const StickersCustomDark = lazy(() => import('./StickersCustom/Dark'));
const StickersCustomSecondary = lazy(() => import('./StickersCustom/Secondary'));
const StickersCustomPrimary = lazy(() => import('./StickersCustom/Primary'));
const StickersCustomDisabled = lazy(() => import('./StickersCustom/Disabled'));
const StickersCustomError = lazy(() => import('./StickersCustom/Error'));
const StickersCustomSuccess = lazy(() => import('./StickersCustom/Success'));
const StickersCustomInfo = lazy(() => import('./StickersCustom/Info'));
const SuccessCircleDsLight = lazy(() => import('./SuccessCircleDs/Light'));
const SuccessCircleDsDark = lazy(() => import('./SuccessCircleDs/Dark'));
const SuccessCircleDsSecondary = lazy(() => import('./SuccessCircleDs/Secondary'));
const SuccessCircleDsPrimary = lazy(() => import('./SuccessCircleDs/Primary'));
const SuccessCircleDsDisabled = lazy(() => import('./SuccessCircleDs/Disabled'));
const SuccessCircleDsError = lazy(() => import('./SuccessCircleDs/Error'));
const SuccessCircleDsSuccess = lazy(() => import('./SuccessCircleDs/Success'));
const SuccessCircleDsInfo = lazy(() => import('./SuccessCircleDs/Info'));
const SuccessCircleLight = lazy(() => import('./SuccessCircle/Light'));
const SuccessCircleDark = lazy(() => import('./SuccessCircle/Dark'));
const SuccessCircleSecondary = lazy(() => import('./SuccessCircle/Secondary'));
const SuccessCirclePrimary = lazy(() => import('./SuccessCircle/Primary'));
const SuccessCircleDisabled = lazy(() => import('./SuccessCircle/Disabled'));
const SuccessCircleError = lazy(() => import('./SuccessCircle/Error'));
const SuccessCircleSuccess = lazy(() => import('./SuccessCircle/Success'));
const SuccessCircleInfo = lazy(() => import('./SuccessCircle/Info'));
const SuccessCustomDsLight = lazy(() => import('./SuccessCustomDs/Light'));
const SuccessCustomDsDark = lazy(() => import('./SuccessCustomDs/Dark'));
const SuccessCustomDsSecondary = lazy(() => import('./SuccessCustomDs/Secondary'));
const SuccessCustomDsPrimary = lazy(() => import('./SuccessCustomDs/Primary'));
const SuccessCustomDsDisabled = lazy(() => import('./SuccessCustomDs/Disabled'));
const SuccessCustomDsError = lazy(() => import('./SuccessCustomDs/Error'));
const SuccessCustomDsSuccess = lazy(() => import('./SuccessCustomDs/Success'));
const SuccessCustomDsInfo = lazy(() => import('./SuccessCustomDs/Info'));
const SuccessCustomLight = lazy(() => import('./SuccessCustom/Light'));
const SuccessCustomDark = lazy(() => import('./SuccessCustom/Dark'));
const SuccessCustomSecondary = lazy(() => import('./SuccessCustom/Secondary'));
const SuccessCustomPrimary = lazy(() => import('./SuccessCustom/Primary'));
const SuccessCustomDisabled = lazy(() => import('./SuccessCustom/Disabled'));
const SuccessCustomError = lazy(() => import('./SuccessCustom/Error'));
const SuccessCustomSuccess = lazy(() => import('./SuccessCustom/Success'));
const SuccessCustomInfo = lazy(() => import('./SuccessCustom/Info'));
const TextDsLight = lazy(() => import('./TextDs/Light'));
const TextDsDark = lazy(() => import('./TextDs/Dark'));
const TextDsSecondary = lazy(() => import('./TextDs/Secondary'));
const TextDsPrimary = lazy(() => import('./TextDs/Primary'));
const TextDsDisabled = lazy(() => import('./TextDs/Disabled'));
const TextDsError = lazy(() => import('./TextDs/Error'));
const TextDsSuccess = lazy(() => import('./TextDs/Success'));
const TextDsInfo = lazy(() => import('./TextDs/Info'));
const TextLight = lazy(() => import('./Text/Light'));
const TextDark = lazy(() => import('./Text/Dark'));
const TextSecondary = lazy(() => import('./Text/Secondary'));
const TextPrimary = lazy(() => import('./Text/Primary'));
const TextDisabled = lazy(() => import('./Text/Disabled'));
const TextError = lazy(() => import('./Text/Error'));
const TextSuccess = lazy(() => import('./Text/Success'));
const TextInfo = lazy(() => import('./Text/Info'));
const TrashDsLight = lazy(() => import('./TrashDs/Light'));
const TrashDsDark = lazy(() => import('./TrashDs/Dark'));
const TrashDsSecondary = lazy(() => import('./TrashDs/Secondary'));
const TrashDsPrimary = lazy(() => import('./TrashDs/Primary'));
const TrashDsDisabled = lazy(() => import('./TrashDs/Disabled'));
const TrashDsError = lazy(() => import('./TrashDs/Error'));
const TrashDsSuccess = lazy(() => import('./TrashDs/Success'));
const TrashDsInfo = lazy(() => import('./TrashDs/Info'));
const TrashLight = lazy(() => import('./Trash/Light'));
const TrashDark = lazy(() => import('./Trash/Dark'));
const TrashSecondary = lazy(() => import('./Trash/Secondary'));
const TrashPrimary = lazy(() => import('./Trash/Primary'));
const TrashDisabled = lazy(() => import('./Trash/Disabled'));
const TrashError = lazy(() => import('./Trash/Error'));
const TrashSuccess = lazy(() => import('./Trash/Success'));
const TrashInfo = lazy(() => import('./Trash/Info'));
const TrashFilledCustomDsLight = lazy(() => import('./TrashFilledCustomDs/Light'));
const TrashFilledCustomDsDark = lazy(() => import('./TrashFilledCustomDs/Dark'));
const TrashFilledCustomDsSecondary = lazy(() => import('./TrashFilledCustomDs/Secondary'));
const TrashFilledCustomDsPrimary = lazy(() => import('./TrashFilledCustomDs/Primary'));
const TrashFilledCustomDsDisabled = lazy(() => import('./TrashFilledCustomDs/Disabled'));
const TrashFilledCustomDsError = lazy(() => import('./TrashFilledCustomDs/Error'));
const TrashFilledCustomDsSuccess = lazy(() => import('./TrashFilledCustomDs/Success'));
const TrashFilledCustomDsInfo = lazy(() => import('./TrashFilledCustomDs/Info'));
const TrashFilledCustomLight = lazy(() => import('./TrashFilledCustom/Light'));
const TrashFilledCustomDark = lazy(() => import('./TrashFilledCustom/Dark'));
const TrashFilledCustomSecondary = lazy(() => import('./TrashFilledCustom/Secondary'));
const TrashFilledCustomPrimary = lazy(() => import('./TrashFilledCustom/Primary'));
const TrashFilledCustomDisabled = lazy(() => import('./TrashFilledCustom/Disabled'));
const TrashFilledCustomError = lazy(() => import('./TrashFilledCustom/Error'));
const TrashFilledCustomSuccess = lazy(() => import('./TrashFilledCustom/Success'));
const TrashFilledCustomInfo = lazy(() => import('./TrashFilledCustom/Info'));
const UndoDisabledCustomDsLight = lazy(() => import('./UndoDisabledCustomDs/Light'));
const UndoDisabledCustomDsDark = lazy(() => import('./UndoDisabledCustomDs/Dark'));
const UndoDisabledCustomDsSecondary = lazy(() => import('./UndoDisabledCustomDs/Secondary'));
const UndoDisabledCustomDsPrimary = lazy(() => import('./UndoDisabledCustomDs/Primary'));
const UndoDisabledCustomDsDisabled = lazy(() => import('./UndoDisabledCustomDs/Disabled'));
const UndoDisabledCustomDsError = lazy(() => import('./UndoDisabledCustomDs/Error'));
const UndoDisabledCustomDsSuccess = lazy(() => import('./UndoDisabledCustomDs/Success'));
const UndoDisabledCustomDsInfo = lazy(() => import('./UndoDisabledCustomDs/Info'));
const UndoDisabledCustomLight = lazy(() => import('./UndoDisabledCustom/Light'));
const UndoDisabledCustomDark = lazy(() => import('./UndoDisabledCustom/Dark'));
const UndoDisabledCustomSecondary = lazy(() => import('./UndoDisabledCustom/Secondary'));
const UndoDisabledCustomPrimary = lazy(() => import('./UndoDisabledCustom/Primary'));
const UndoDisabledCustomDisabled = lazy(() => import('./UndoDisabledCustom/Disabled'));
const UndoDisabledCustomError = lazy(() => import('./UndoDisabledCustom/Error'));
const UndoDisabledCustomSuccess = lazy(() => import('./UndoDisabledCustom/Success'));
const UndoDisabledCustomInfo = lazy(() => import('./UndoDisabledCustom/Info'));
const VisibilityOffDsLight = lazy(() => import('./VisibilityOffDs/Light'));
const VisibilityOffDsDark = lazy(() => import('./VisibilityOffDs/Dark'));
const VisibilityOffDsSecondary = lazy(() => import('./VisibilityOffDs/Secondary'));
const VisibilityOffDsPrimary = lazy(() => import('./VisibilityOffDs/Primary'));
const VisibilityOffDsDisabled = lazy(() => import('./VisibilityOffDs/Disabled'));
const VisibilityOffDsError = lazy(() => import('./VisibilityOffDs/Error'));
const VisibilityOffDsSuccess = lazy(() => import('./VisibilityOffDs/Success'));
const VisibilityOffDsInfo = lazy(() => import('./VisibilityOffDs/Info'));
const VisibilityOffLight = lazy(() => import('./VisibilityOff/Light'));
const VisibilityOffDark = lazy(() => import('./VisibilityOff/Dark'));
const VisibilityOffSecondary = lazy(() => import('./VisibilityOff/Secondary'));
const VisibilityOffPrimary = lazy(() => import('./VisibilityOff/Primary'));
const VisibilityOffDisabled = lazy(() => import('./VisibilityOff/Disabled'));
const VisibilityOffError = lazy(() => import('./VisibilityOff/Error'));
const VisibilityOffSuccess = lazy(() => import('./VisibilityOff/Success'));
const VisibilityOffInfo = lazy(() => import('./VisibilityOff/Info'));
const VisibilityOnDsLight = lazy(() => import('./VisibilityOnDs/Light'));
const VisibilityOnDsDark = lazy(() => import('./VisibilityOnDs/Dark'));
const VisibilityOnDsSecondary = lazy(() => import('./VisibilityOnDs/Secondary'));
const VisibilityOnDsPrimary = lazy(() => import('./VisibilityOnDs/Primary'));
const VisibilityOnDsDisabled = lazy(() => import('./VisibilityOnDs/Disabled'));
const VisibilityOnDsError = lazy(() => import('./VisibilityOnDs/Error'));
const VisibilityOnDsSuccess = lazy(() => import('./VisibilityOnDs/Success'));
const VisibilityOnDsInfo = lazy(() => import('./VisibilityOnDs/Info'));
const VisibilityOnLight = lazy(() => import('./VisibilityOn/Light'));
const VisibilityOnDark = lazy(() => import('./VisibilityOn/Dark'));
const VisibilityOnSecondary = lazy(() => import('./VisibilityOn/Secondary'));
const VisibilityOnPrimary = lazy(() => import('./VisibilityOn/Primary'));
const VisibilityOnDisabled = lazy(() => import('./VisibilityOn/Disabled'));
const VisibilityOnError = lazy(() => import('./VisibilityOn/Error'));
const VisibilityOnSuccess = lazy(() => import('./VisibilityOn/Success'));
const VisibilityOnInfo = lazy(() => import('./VisibilityOn/Info'));
const WidgetsDsLight = lazy(() => import('./WidgetsDs/Light'));
const WidgetsDsDark = lazy(() => import('./WidgetsDs/Dark'));
const WidgetsDsSecondary = lazy(() => import('./WidgetsDs/Secondary'));
const WidgetsDsPrimary = lazy(() => import('./WidgetsDs/Primary'));
const WidgetsDsDisabled = lazy(() => import('./WidgetsDs/Disabled'));
const WidgetsDsError = lazy(() => import('./WidgetsDs/Error'));
const WidgetsDsSuccess = lazy(() => import('./WidgetsDs/Success'));
const WidgetsDsInfo = lazy(() => import('./WidgetsDs/Info'));
const WidgetsLight = lazy(() => import('./Widgets/Light'));
const WidgetsDark = lazy(() => import('./Widgets/Dark'));
const WidgetsSecondary = lazy(() => import('./Widgets/Secondary'));
const WidgetsPrimary = lazy(() => import('./Widgets/Primary'));
const WidgetsDisabled = lazy(() => import('./Widgets/Disabled'));
const WidgetsError = lazy(() => import('./Widgets/Error'));
const WidgetsSuccess = lazy(() => import('./Widgets/Success'));
const WidgetsInfo = lazy(() => import('./Widgets/Info'));

export {
  ActivitiesDsLight,
  ActivitiesDsDark,
  ActivitiesDsSecondary,
  ActivitiesDsPrimary,
  ActivitiesDsDisabled,
  ActivitiesDsError,
  ActivitiesDsSuccess,
  ActivitiesDsInfo,
  ActivitiesLight,
  ActivitiesDark,
  ActivitiesSecondary,
  ActivitiesPrimary,
  ActivitiesDisabled,
  ActivitiesError,
  ActivitiesSuccess,
  ActivitiesInfo,
  AddCustomDsLight,
  AddCustomDsDark,
  AddCustomDsSecondary,
  AddCustomDsPrimary,
  AddCustomDsDisabled,
  AddCustomDsError,
  AddCustomDsSuccess,
  AddCustomDsInfo,
  AddCustomLight,
  AddCustomDark,
  AddCustomSecondary,
  AddCustomPrimary,
  AddCustomDisabled,
  AddCustomError,
  AddCustomSuccess,
  AddCustomInfo,
  AddTextOverlayCustomDsLight,
  AddTextOverlayCustomDsDark,
  AddTextOverlayCustomDsSecondary,
  AddTextOverlayCustomDsPrimary,
  AddTextOverlayCustomDsDisabled,
  AddTextOverlayCustomDsError,
  AddTextOverlayCustomDsSuccess,
  AddTextOverlayCustomDsInfo,
  AddTextOverlayCustomLight,
  AddTextOverlayCustomDark,
  AddTextOverlayCustomSecondary,
  AddTextOverlayCustomPrimary,
  AddTextOverlayCustomDisabled,
  AddTextOverlayCustomError,
  AddTextOverlayCustomSuccess,
  AddTextOverlayCustomInfo,
  AvatarCustomDsLight,
  AvatarCustomDsDark,
  AvatarCustomDsSecondary,
  AvatarCustomDsPrimary,
  AvatarCustomDsDisabled,
  AvatarCustomDsError,
  AvatarCustomDsSuccess,
  AvatarCustomDsInfo,
  AvatarCustomLight,
  AvatarCustomDark,
  AvatarCustomSecondary,
  AvatarCustomPrimary,
  AvatarCustomDisabled,
  AvatarCustomError,
  AvatarCustomSuccess,
  AvatarCustomInfo,
  BookmarkCustomLight,
  BookmarkCustomDark,
  BookmarkCustomSecondary,
  BookmarkCustomPrimary,
  BookmarkCustomDisabled,
  BookmarkCustomError,
  BookmarkCustomSuccess,
  BookmarkCustomDsLight,
  BookmarkCustomDsDark,
  BookmarkCustomDsSecondary,
  BookmarkCustomDsPrimary,
  BookmarkCustomDsDisabled,
  BookmarkCustomDsError,
  BookmarkCustomDsSuccess,
  CalendarCustomDsLight,
  CalendarCustomDsDark,
  CalendarCustomDsSecondary,
  CalendarCustomDsPrimary,
  CalendarCustomDsDisabled,
  CalendarCustomDsError,
  CalendarCustomDsSuccess,
  CalendarCustomDsInfo,
  CalendarCustomLight,
  CalendarCustomDark,
  CalendarCustomSecondary,
  CalendarCustomPrimary,
  CalendarCustomDisabled,
  CalendarCustomError,
  CalendarCustomSuccess,
  CalendarCustomInfo,
  CameraFlipCustomDsLight,
  CameraFlipCustomDsDark,
  CameraFlipCustomDsSecondary,
  CameraFlipCustomDsPrimary,
  CameraFlipCustomDsDisabled,
  CameraFlipCustomDsError,
  CameraFlipCustomDsSuccess,
  CameraFlipCustomDsInfo,
  CameraFlipCustomLight,
  CameraFlipCustomDark,
  CameraFlipCustomSecondary,
  CameraFlipCustomPrimary,
  CameraFlipCustomDisabled,
  CameraFlipCustomError,
  CameraFlipCustomSuccess,
  CameraFlipCustomInfo,
  CameraFocusCustomDsLight,
  CameraFocusCustomDsDark,
  CameraFocusCustomDsSecondary,
  CameraFocusCustomDsPrimary,
  CameraFocusCustomDsDisabled,
  CameraFocusCustomDsError,
  CameraFocusCustomDsSuccess,
  CameraFocusCustomDsInfo,
  CameraFocusCustomLight,
  CameraFocusCustomDark,
  CameraFocusCustomSecondary,
  CameraFocusCustomPrimary,
  CameraFocusCustomDisabled,
  CameraFocusCustomError,
  CameraFocusCustomSuccess,
  CameraFocusCustomInfo,
  CancelCustomDsLight,
  CancelCustomDsDark,
  CancelCustomDsSecondary,
  CancelCustomDsPrimary,
  CancelCustomDsDisabled,
  CancelCustomDsError,
  CancelCustomDsSuccess,
  CancelCustomDsInfo,
  CancelCustomLight,
  CancelCustomDark,
  CancelCustomSecondary,
  CancelCustomPrimary,
  CancelCustomDisabled,
  CancelCustomError,
  CancelCustomSuccess,
  CancelCustomInfo,
  ChatCustomDsLight,
  ChatCustomDsDark,
  ChatCustomDsSecondary,
  ChatCustomDsPrimary,
  ChatCustomDsDisabled,
  ChatCustomDsError,
  ChatCustomDsSuccess,
  ChatCustomDsInfo,
  ChatCustomLight,
  ChatCustomDark,
  ChatCustomSecondary,
  ChatCustomPrimary,
  ChatCustomDisabled,
  ChatCustomError,
  ChatCustomSuccess,
  ChatCustomInfo,
  ChevronDownCustomDsLight,
  ChevronDownCustomDsDark,
  ChevronDownCustomDsSecondary,
  ChevronDownCustomDsPrimary,
  ChevronDownCustomDsDisabled,
  ChevronDownCustomDsError,
  ChevronDownCustomDsSuccess,
  ChevronDownCustomDsInfo,
  ChevronDownCustomLight,
  ChevronDownCustomDark,
  ChevronDownCustomSecondary,
  ChevronDownCustomPrimary,
  ChevronDownCustomDisabled,
  ChevronDownCustomError,
  ChevronDownCustomSuccess,
  ChevronDownCustomInfo,
  ChevronLeftCustomDsLight,
  ChevronLeftCustomDsDark,
  ChevronLeftCustomDsSecondary,
  ChevronLeftCustomDsPrimary,
  ChevronLeftCustomDsDisabled,
  ChevronLeftCustomDsError,
  ChevronLeftCustomDsSuccess,
  ChevronLeftCustomDsInfo,
  ChevronLeftCustomLight,
  ChevronLeftCustomDark,
  ChevronLeftCustomSecondary,
  ChevronLeftCustomPrimary,
  ChevronLeftCustomDisabled,
  ChevronLeftCustomError,
  ChevronLeftCustomSuccess,
  ChevronLeftCustomInfo,
  ChevronRightCustomDsLight,
  ChevronRightCustomDsDark,
  ChevronRightCustomDsSecondary,
  ChevronRightCustomDsPrimary,
  ChevronRightCustomDsDisabled,
  ChevronRightCustomDsError,
  ChevronRightCustomDsSuccess,
  ChevronRightCustomDsInfo,
  ChevronRightCustomLight,
  ChevronRightCustomDark,
  ChevronRightCustomSecondary,
  ChevronRightCustomPrimary,
  ChevronRightCustomDisabled,
  ChevronRightCustomError,
  ChevronRightCustomSuccess,
  ChevronRightCustomInfo,
  ChevronUpCustomDsLight,
  ChevronUpCustomDsDark,
  ChevronUpCustomDsSecondary,
  ChevronUpCustomDsPrimary,
  ChevronUpCustomDsDisabled,
  ChevronUpCustomDsError,
  ChevronUpCustomDsSuccess,
  ChevronUpCustomDsInfo,
  ChevronUpCustomLight,
  ChevronUpCustomDark,
  ChevronUpCustomSecondary,
  ChevronUpCustomPrimary,
  ChevronUpCustomDisabled,
  ChevronUpCustomError,
  ChevronUpCustomSuccess,
  ChevronUpCustomInfo,
  ChipCancelCustomDsLight,
  ChipCancelCustomDsDark,
  ChipCancelCustomDsSecondary,
  ChipCancelCustomDsPrimary,
  ChipCancelCustomDsDisabled,
  ChipCancelCustomDsError,
  ChipCancelCustomDsSuccess,
  ChipCancelCustomDsInfo,
  ChipCancelCustomLight,
  ChipCancelCustomDark,
  ChipCancelCustomSecondary,
  ChipCancelCustomPrimary,
  ChipCancelCustomDisabled,
  ChipCancelCustomError,
  ChipCancelCustomSuccess,
  ChipCancelCustomInfo,
  ClipTrayCustomDsLight,
  ClipTrayCustomDsDark,
  ClipTrayCustomDsSecondary,
  ClipTrayCustomDsPrimary,
  ClipTrayCustomDsDisabled,
  ClipTrayCustomDsError,
  ClipTrayCustomDsSuccess,
  ClipTrayCustomDsInfo,
  ClipTrayCustomLight,
  ClipTrayCustomDark,
  ClipTrayCustomSecondary,
  ClipTrayCustomPrimary,
  ClipTrayCustomDisabled,
  ClipTrayCustomError,
  ClipTrayCustomSuccess,
  ClipTrayCustomInfo,
  ClosedCaptioningDsLight,
  ClosedCaptioningDsDark,
  ClosedCaptioningDsSecondary,
  ClosedCaptioningDsPrimary,
  ClosedCaptioningDsDisabled,
  ClosedCaptioningDsError,
  ClosedCaptioningDsSuccess,
  ClosedCaptioningDsInfo,
  ClosedCaptioningLight,
  ClosedCaptioningDark,
  ClosedCaptioningSecondary,
  ClosedCaptioningPrimary,
  ClosedCaptioningDisabled,
  ClosedCaptioningError,
  ClosedCaptioningSuccess,
  ClosedCaptioningInfo,
  CollectionCustomDsLight,
  CollectionCustomDsDark,
  CollectionCustomDsSecondary,
  CollectionCustomDsPrimary,
  CollectionCustomDsDisabled,
  CollectionCustomDsError,
  CollectionCustomDsSuccess,
  CollectionCustomDsInfo,
  CollectionCustomLight,
  CollectionCustomDark,
  CollectionCustomSecondary,
  CollectionCustomPrimary,
  CollectionCustomDisabled,
  CollectionCustomError,
  CollectionCustomSuccess,
  CollectionCustomInfo,
  CommentCustomDsLight,
  CommentCustomDsDark,
  CommentCustomDsSecondary,
  CommentCustomDsPrimary,
  CommentCustomDsDisabled,
  CommentCustomDsError,
  CommentCustomDsSuccess,
  CommentCustomDsInfo,
  CommentCustomLight,
  CommentCustomDark,
  CommentCustomSecondary,
  CommentCustomPrimary,
  CommentCustomDisabled,
  CommentCustomError,
  CommentCustomSuccess,
  CommentCustomInfo,
  DeleteClipsCustomDsLight,
  DeleteClipsCustomDsDark,
  DeleteClipsCustomDsSecondary,
  DeleteClipsCustomDsPrimary,
  DeleteClipsCustomDsDisabled,
  DeleteClipsCustomDsError,
  DeleteClipsCustomDsSuccess,
  DeleteClipsCustomDsInfo,
  DeleteClipsCustomLight,
  DeleteClipsCustomDark,
  DeleteClipsCustomSecondary,
  DeleteClipsCustomPrimary,
  DeleteClipsCustomDisabled,
  DeleteClipsCustomError,
  DeleteClipsCustomSuccess,
  DeleteClipsCustomInfo,
  DestinationTagCustomDsLight,
  DestinationTagCustomDsDark,
  DestinationTagCustomDsSecondary,
  DestinationTagCustomDsPrimary,
  DestinationTagCustomDsDisabled,
  DestinationTagCustomDsError,
  DestinationTagCustomDsSuccess,
  DestinationTagCustomDsInfo,
  DestinationTagCustomLight,
  DestinationTagCustomDark,
  DestinationTagCustomSecondary,
  DestinationTagCustomPrimary,
  DestinationTagCustomDisabled,
  DestinationTagCustomError,
  DestinationTagCustomSuccess,
  DestinationTagCustomInfo,
  DetailsCustomDsLight,
  DetailsCustomDsDark,
  DetailsCustomDsSecondary,
  DetailsCustomDsPrimary,
  DetailsCustomDsDisabled,
  DetailsCustomDsError,
  DetailsCustomDsSuccess,
  DetailsCustomDsInfo,
  DetailsCustomLight,
  DetailsCustomDark,
  DetailsCustomSecondary,
  DetailsCustomPrimary,
  DetailsCustomDisabled,
  DetailsCustomError,
  DetailsCustomSuccess,
  DetailsCustomInfo,
  DotsDsLight,
  DotsDsDark,
  DotsDsSecondary,
  DotsDsPrimary,
  DotsDsDisabled,
  DotsDsError,
  DotsDsSuccess,
  DotsDsInfo,
  DotsLight,
  DotsDark,
  DotsSecondary,
  DotsPrimary,
  DotsDisabled,
  DotsError,
  DotsSuccess,
  DotsInfo,
  DownloadDsLight,
  DownloadDsDark,
  DownloadDsSecondary,
  DownloadDsPrimary,
  DownloadDsDisabled,
  DownloadDsError,
  DownloadDsSuccess,
  DownloadDsInfo,
  DownloadLight,
  DownloadDark,
  DownloadSecondary,
  DownloadPrimary,
  DownloadDisabled,
  DownloadError,
  DownloadSuccess,
  DownloadInfo,
  EditDsLight,
  EditDsDark,
  EditDsSecondary,
  EditDsPrimary,
  EditDsDisabled,
  EditDsError,
  EditDsSuccess,
  EditDsInfo,
  EditLight,
  EditDark,
  EditSecondary,
  EditPrimary,
  EditDisabled,
  EditError,
  EditSuccess,
  EditInfo,
  EditDestinationDsLight,
  EditDestinationDsDark,
  EditDestinationDsSecondary,
  EditDestinationDsPrimary,
  EditDestinationDsDisabled,
  EditDestinationDsError,
  EditDestinationDsSuccess,
  EditDestinationDsInfo,
  EditDestinationLight,
  EditDestinationDark,
  EditDestinationSecondary,
  EditDestinationPrimary,
  EditDestinationDisabled,
  EditDestinationError,
  EditDestinationSuccess,
  EditDestinationInfo,
  EmailDsLight,
  EmailDsDark,
  EmailDsSecondary,
  EmailDsPrimary,
  EmailDsDisabled,
  EmailDsError,
  EmailDsSuccess,
  EmailDsInfo,
  EmailLight,
  EmailDark,
  EmailSecondary,
  EmailPrimary,
  EmailDisabled,
  EmailError,
  EmailSuccess,
  EmailInfo,
  ErrorIconDsLight,
  ErrorIconDsDark,
  ErrorIconDsSecondary,
  ErrorIconDsPrimary,
  ErrorIconDsDisabled,
  ErrorIconDsError,
  ErrorIconDsSuccess,
  ErrorIconDsInfo,
  ErrorIconLight,
  ErrorIconDark,
  ErrorIconSecondary,
  ErrorIconPrimary,
  ErrorIconDisabled,
  ErrorIconError,
  ErrorIconSuccess,
  ErrorIconInfo,
  ExperiencesCustomDsLight,
  ExperiencesCustomDsDark,
  ExperiencesCustomDsSecondary,
  ExperiencesCustomDsPrimary,
  ExperiencesCustomDsDisabled,
  ExperiencesCustomDsError,
  ExperiencesCustomDsSuccess,
  ExperiencesCustomDsInfo,
  ExperiencesCustomLight,
  ExperiencesCustomDark,
  ExperiencesCustomSecondary,
  ExperiencesCustomPrimary,
  ExperiencesCustomDisabled,
  ExperiencesCustomError,
  ExperiencesCustomSuccess,
  ExperiencesCustomInfo,
  FileTextDsLight,
  FileTextDsDark,
  FileTextDsSecondary,
  FileTextDsPrimary,
  FileTextDsDisabled,
  FileTextDsError,
  FileTextDsSuccess,
  FileTextDsInfo,
  FileTextLight,
  FileTextDark,
  FileTextSecondary,
  FileTextPrimary,
  FileTextDisabled,
  FileTextError,
  FileTextSuccess,
  FileTextInfo,
  FlashCustomDsLight,
  FlashCustomDsDark,
  FlashCustomDsSecondary,
  FlashCustomDsPrimary,
  FlashCustomDsDisabled,
  FlashCustomDsError,
  FlashCustomDsSuccess,
  FlashCustomDsInfo,
  FlashCustomLight,
  FlashCustomDark,
  FlashCustomSecondary,
  FlashCustomPrimary,
  FlashCustomDisabled,
  FlashCustomError,
  FlashCustomSuccess,
  FlashCustomInfo,
  FlashOffCustomDsLight,
  FlashOffCustomDsDark,
  FlashOffCustomDsSecondary,
  FlashOffCustomDsPrimary,
  FlashOffCustomDsDisabled,
  FlashOffCustomDsError,
  FlashOffCustomDsSuccess,
  FlashOffCustomDsInfo,
  FlashOffCustomLight,
  FlashOffCustomDark,
  FlashOffCustomSecondary,
  FlashOffCustomPrimary,
  FlashOffCustomDisabled,
  FlashOffCustomError,
  FlashOffCustomSuccess,
  FlashOffCustomInfo,
  GlobeDsLight,
  GlobeDsDark,
  GlobeDsSecondary,
  GlobeDsPrimary,
  GlobeDsDisabled,
  GlobeDsError,
  GlobeDsSuccess,
  GlobeDsInfo,
  GlobeLight,
  GlobeDark,
  GlobeSecondary,
  GlobePrimary,
  GlobeDisabled,
  GlobeError,
  GlobeSuccess,
  GlobeInfo,
  HelpDsLight,
  HelpDsDark,
  HelpDsSecondary,
  HelpDsPrimary,
  HelpDsDisabled,
  HelpDsError,
  HelpDsSuccess,
  HelpDsInfo,
  HelpLight,
  HelpDark,
  HelpSecondary,
  HelpPrimary,
  HelpDisabled,
  HelpError,
  HelpSuccess,
  HelpInfo,
  HomeDsLight,
  HomeDsDark,
  HomeDsSecondary,
  HomeDsPrimary,
  HomeDsDisabled,
  HomeDsError,
  HomeDsSuccess,
  HomeDsInfo,
  HomeLight,
  HomeDark,
  HomeSecondary,
  HomePrimary,
  HomeDisabled,
  HomeError,
  HomeSuccess,
  HomeInfo,
  KeyCustomDsLight,
  KeyCustomDsDark,
  KeyCustomDsSecondary,
  KeyCustomDsPrimary,
  KeyCustomDsDisabled,
  KeyCustomDsError,
  KeyCustomDsSuccess,
  KeyCustomDsInfo,
  KeyCustomLight,
  KeyCustomDark,
  KeyCustomSecondary,
  KeyCustomPrimary,
  KeyCustomDisabled,
  KeyCustomError,
  KeyCustomSuccess,
  KeyCustomInfo,
  LikeFilledDsLight,
  LikeFilledDsDark,
  LikeFilledDsSecondary,
  LikeFilledDsPrimary,
  LikeFilledDsDisabled,
  LikeFilledDsError,
  LikeFilledDsSuccess,
  LikeFilledDsInfo,
  LikeFilledLight,
  LikeFilledDark,
  LikeFilledSecondary,
  LikeFilledPrimary,
  LikeFilledDisabled,
  LikeFilledError,
  LikeFilledSuccess,
  LikeFilledInfo,
  LikeUnfilledDsLight,
  LikeUnfilledDsDark,
  LikeUnfilledDsSecondary,
  LikeUnfilledDsPrimary,
  LikeUnfilledDsDisabled,
  LikeUnfilledDsError,
  LikeUnfilledDsSuccess,
  LikeUnfilledDsInfo,
  LikeUnfilledLight,
  LikeUnfilledDark,
  LikeUnfilledSecondary,
  LikeUnfilledPrimary,
  LikeUnfilledDisabled,
  LikeUnfilledError,
  LikeUnfilledSuccess,
  LikeUnfilledInfo,
  LinkCustomDsLight,
  LinkCustomDsDark,
  LinkCustomDsSecondary,
  LinkCustomDsPrimary,
  LinkCustomDsDisabled,
  LinkCustomDsError,
  LinkCustomDsSuccess,
  LinkCustomDsInfo,
  LinkCustomLight,
  LinkCustomDark,
  LinkCustomSecondary,
  LinkCustomPrimary,
  LinkCustomDisabled,
  LinkCustomError,
  LinkCustomSuccess,
  LinkCustomInfo,
  LockDsLight,
  LockDsDark,
  LockDsSecondary,
  LockDsPrimary,
  LockDsDisabled,
  LockDsError,
  LockDsSuccess,
  LockDsInfo,
  LockLight,
  LockDark,
  LockSecondary,
  LockPrimary,
  LockDisabled,
  LockError,
  LockSuccess,
  LockInfo,
  LogoutDsLight,
  LogoutDsDark,
  LogoutDsSecondary,
  LogoutDsPrimary,
  LogoutDsDisabled,
  LogoutDsError,
  LogoutDsSuccess,
  LogoutDsInfo,
  LogoutLight,
  LogoutDark,
  LogoutSecondary,
  LogoutPrimary,
  LogoutDisabled,
  LogoutError,
  LogoutSuccess,
  LogoutInfo,
  MasonryCustomDsLight,
  MasonryCustomDsDark,
  MasonryCustomDsSecondary,
  MasonryCustomDsPrimary,
  MasonryCustomDsDisabled,
  MasonryCustomDsError,
  MasonryCustomDsSuccess,
  MasonryCustomDsInfo,
  MasonryCustomLight,
  MasonryCustomDark,
  MasonryCustomSecondary,
  MasonryCustomPrimary,
  MasonryCustomDisabled,
  MasonryCustomError,
  MasonryCustomSuccess,
  MasonryCustomInfo,
  NotificationsDsLight,
  NotificationsDsDark,
  NotificationsDsSecondary,
  NotificationsDsPrimary,
  NotificationsDsDisabled,
  NotificationsDsError,
  NotificationsDsSuccess,
  NotificationsDsInfo,
  NotificationsLight,
  NotificationsDark,
  NotificationsSecondary,
  NotificationsPrimary,
  NotificationsDisabled,
  NotificationsError,
  NotificationsSuccess,
  NotificationsInfo,
  PaletteDsLight,
  PaletteDsDark,
  PaletteDsSecondary,
  PaletteDsPrimary,
  PaletteDsDisabled,
  PaletteDsError,
  PaletteDsSuccess,
  PaletteDsInfo,
  PaletteLight,
  PaletteDark,
  PaletteSecondary,
  PalettePrimary,
  PaletteDisabled,
  PaletteError,
  PaletteSuccess,
  PaletteInfo,
  PersonOutlineDsLight,
  PersonOutlineDsDark,
  PersonOutlineDsSecondary,
  PersonOutlineDsPrimary,
  PersonOutlineDsDisabled,
  PersonOutlineDsError,
  PersonOutlineDsSuccess,
  PersonOutlineDsInfo,
  PersonOutlineLight,
  PersonOutlineDark,
  PersonOutlineSecondary,
  PersonOutlinePrimary,
  PersonOutlineDisabled,
  PersonOutlineError,
  PersonOutlineSuccess,
  PersonOutlineInfo,
  RecommendedForCustomDsLight,
  RecommendedForCustomDsDark,
  RecommendedForCustomDsSecondary,
  RecommendedForCustomDsPrimary,
  RecommendedForCustomDsDisabled,
  RecommendedForCustomDsError,
  RecommendedForCustomDsSuccess,
  RecommendedForCustomDsInfo,
  RecommendedForCustomLight,
  RecommendedForCustomDark,
  RecommendedForCustomSecondary,
  RecommendedForCustomPrimary,
  RecommendedForCustomDisabled,
  RecommendedForCustomError,
  RecommendedForCustomSuccess,
  RecommendedForCustomInfo,
  RedoDisabledCustomDsLight,
  RedoDisabledCustomDsDark,
  RedoDisabledCustomDsSecondary,
  RedoDisabledCustomDsPrimary,
  RedoDisabledCustomDsDisabled,
  RedoDisabledCustomDsError,
  RedoDisabledCustomDsSuccess,
  RedoDisabledCustomDsInfo,
  RedoDisabledCustomLight,
  RedoDisabledCustomDark,
  RedoDisabledCustomSecondary,
  RedoDisabledCustomPrimary,
  RedoDisabledCustomDisabled,
  RedoDisabledCustomError,
  RedoDisabledCustomSuccess,
  RedoDisabledCustomInfo,
  RefreshLight,
  RefreshDark,
  RefreshSecondary,
  RefreshPrimary,
  RefreshDisabled,
  RefreshError,
  RefreshSuccess,
  RefreshDsLight,
  RefreshDsDark,
  RefreshDsSecondary,
  RefreshDsPrimary,
  RefreshDsDisabled,
  RefreshDsError,
  RefreshDsSuccess,
  RhinoDsLight,
  RhinoDsDark,
  RhinoDsSecondary,
  RhinoDsPrimary,
  RhinoDsDisabled,
  RhinoDsError,
  RhinoDsSuccess,
  RhinoDsInfo,
  RhinoLight,
  RhinoDark,
  RhinoSecondary,
  RhinoPrimary,
  RhinoDisabled,
  RhinoError,
  RhinoSuccess,
  RhinoInfo,
  SaveEmptyCustomLight,
  SaveEmptyCustomDark,
  SaveFilledCustomLight,
  SaveFilledCustomDark,
  SearchLight,
  SearchDark,
  SearchSecondary,
  SearchPrimary,
  SearchDisabled,
  SearchError,
  SearchSuccess,
  SearchInfo,
  SearchIconDsLight,
  SearchIconDsDark,
  SearchIconDsSecondary,
  SearchIconDsPrimary,
  SearchIconDsDisabled,
  SearchIconDsError,
  SearchIconDsSuccess,
  SearchIconDsInfo,
  SelectCustomDsLight,
  SelectCustomDsDark,
  SelectCustomDsSecondary,
  SelectCustomDsPrimary,
  SelectCustomDsDisabled,
  SelectCustomDsError,
  SelectCustomDsSuccess,
  SelectCustomDsInfo,
  SelectCustomLight,
  SelectCustomDark,
  SelectCustomSecondary,
  SelectCustomPrimary,
  SelectCustomDisabled,
  SelectCustomError,
  SelectCustomSuccess,
  SelectCustomInfo,
  SendCustomDsLight,
  SendCustomDsDark,
  SendCustomDsSecondary,
  SendCustomDsPrimary,
  SendCustomDsDisabled,
  SendCustomDsError,
  SendCustomDsSuccess,
  SendCustomDsInfo,
  SendCustomLight,
  SendCustomDark,
  SendCustomSecondary,
  SendCustomPrimary,
  SendCustomDisabled,
  SendCustomError,
  SendCustomSuccess,
  SendCustomInfo,
  ShareCustomDsLight,
  ShareCustomDsDark,
  ShareCustomDsSecondary,
  ShareCustomDsPrimary,
  ShareCustomDsDisabled,
  ShareCustomDsError,
  ShareCustomDsSuccess,
  ShareCustomDsInfo,
  ShareCustomLight,
  ShareCustomDark,
  ShareCustomSecondary,
  ShareCustomPrimary,
  ShareCustomDisabled,
  ShareCustomError,
  ShareCustomSuccess,
  ShareCustomInfo,
  ShareIconDsLight,
  ShareIconDsDark,
  ShareIconDsSecondary,
  ShareIconDsPrimary,
  ShareIconDsDisabled,
  ShareIconDsError,
  ShareIconDsSuccess,
  ShareIconDsInfo,
  ShareIconLight,
  ShareIconDark,
  ShareIconSecondary,
  ShareIconPrimary,
  ShareIconDisabled,
  ShareIconError,
  ShareIconSuccess,
  ShareIconInfo,
  SmsCustomDsLight,
  SmsCustomDsDark,
  SmsCustomDsSecondary,
  SmsCustomDsPrimary,
  SmsCustomDsDisabled,
  SmsCustomDsError,
  SmsCustomDsSuccess,
  SmsCustomDsInfo,
  SmsCustomLight,
  SmsCustomDark,
  SmsCustomSecondary,
  SmsCustomPrimary,
  SmsCustomDisabled,
  SmsCustomError,
  SmsCustomSuccess,
  SmsCustomInfo,
  SoundOffCustomDsLight,
  SoundOffCustomDsDark,
  SoundOffCustomDsSecondary,
  SoundOffCustomDsPrimary,
  SoundOffCustomDsDisabled,
  SoundOffCustomDsError,
  SoundOffCustomDsSuccess,
  SoundOffCustomDsInfo,
  SoundOffCustomLight,
  SoundOffCustomDark,
  SoundOffCustomSecondary,
  SoundOffCustomPrimary,
  SoundOffCustomDisabled,
  SoundOffCustomError,
  SoundOffCustomSuccess,
  SoundOffCustomInfo,
  SoundOnCustomDsLight,
  SoundOnCustomDsDark,
  SoundOnCustomDsSecondary,
  SoundOnCustomDsPrimary,
  SoundOnCustomDsDisabled,
  SoundOnCustomDsError,
  SoundOnCustomDsSuccess,
  SoundOnCustomDsInfo,
  SoundOnCustomLight,
  SoundOnCustomDark,
  SoundOnCustomSecondary,
  SoundOnCustomPrimary,
  SoundOnCustomDisabled,
  SoundOnCustomError,
  SoundOnCustomSuccess,
  SoundOnCustomInfo,
  StickersCustomDsLight,
  StickersCustomDsDark,
  StickersCustomDsSecondary,
  StickersCustomDsPrimary,
  StickersCustomDsDisabled,
  StickersCustomDsError,
  StickersCustomDsSuccess,
  StickersCustomDsInfo,
  StickersCustomLight,
  StickersCustomDark,
  StickersCustomSecondary,
  StickersCustomPrimary,
  StickersCustomDisabled,
  StickersCustomError,
  StickersCustomSuccess,
  StickersCustomInfo,
  SuccessCircleDsLight,
  SuccessCircleDsDark,
  SuccessCircleDsSecondary,
  SuccessCircleDsPrimary,
  SuccessCircleDsDisabled,
  SuccessCircleDsError,
  SuccessCircleDsSuccess,
  SuccessCircleDsInfo,
  SuccessCircleLight,
  SuccessCircleDark,
  SuccessCircleSecondary,
  SuccessCirclePrimary,
  SuccessCircleDisabled,
  SuccessCircleError,
  SuccessCircleSuccess,
  SuccessCircleInfo,
  SuccessCustomDsLight,
  SuccessCustomDsDark,
  SuccessCustomDsSecondary,
  SuccessCustomDsPrimary,
  SuccessCustomDsDisabled,
  SuccessCustomDsError,
  SuccessCustomDsSuccess,
  SuccessCustomDsInfo,
  SuccessCustomLight,
  SuccessCustomDark,
  SuccessCustomSecondary,
  SuccessCustomPrimary,
  SuccessCustomDisabled,
  SuccessCustomError,
  SuccessCustomSuccess,
  SuccessCustomInfo,
  TextDsLight,
  TextDsDark,
  TextDsSecondary,
  TextDsPrimary,
  TextDsDisabled,
  TextDsError,
  TextDsSuccess,
  TextDsInfo,
  TextLight,
  TextDark,
  TextSecondary,
  TextPrimary,
  TextDisabled,
  TextError,
  TextSuccess,
  TextInfo,
  TrashDsLight,
  TrashDsDark,
  TrashDsSecondary,
  TrashDsPrimary,
  TrashDsDisabled,
  TrashDsError,
  TrashDsSuccess,
  TrashDsInfo,
  TrashLight,
  TrashDark,
  TrashSecondary,
  TrashPrimary,
  TrashDisabled,
  TrashError,
  TrashSuccess,
  TrashInfo,
  TrashFilledCustomDsLight,
  TrashFilledCustomDsDark,
  TrashFilledCustomDsSecondary,
  TrashFilledCustomDsPrimary,
  TrashFilledCustomDsDisabled,
  TrashFilledCustomDsError,
  TrashFilledCustomDsSuccess,
  TrashFilledCustomDsInfo,
  TrashFilledCustomLight,
  TrashFilledCustomDark,
  TrashFilledCustomSecondary,
  TrashFilledCustomPrimary,
  TrashFilledCustomDisabled,
  TrashFilledCustomError,
  TrashFilledCustomSuccess,
  TrashFilledCustomInfo,
  UndoDisabledCustomDsLight,
  UndoDisabledCustomDsDark,
  UndoDisabledCustomDsSecondary,
  UndoDisabledCustomDsPrimary,
  UndoDisabledCustomDsDisabled,
  UndoDisabledCustomDsError,
  UndoDisabledCustomDsSuccess,
  UndoDisabledCustomDsInfo,
  UndoDisabledCustomLight,
  UndoDisabledCustomDark,
  UndoDisabledCustomSecondary,
  UndoDisabledCustomPrimary,
  UndoDisabledCustomDisabled,
  UndoDisabledCustomError,
  UndoDisabledCustomSuccess,
  UndoDisabledCustomInfo,
  VisibilityOffDsLight,
  VisibilityOffDsDark,
  VisibilityOffDsSecondary,
  VisibilityOffDsPrimary,
  VisibilityOffDsDisabled,
  VisibilityOffDsError,
  VisibilityOffDsSuccess,
  VisibilityOffDsInfo,
  VisibilityOffLight,
  VisibilityOffDark,
  VisibilityOffSecondary,
  VisibilityOffPrimary,
  VisibilityOffDisabled,
  VisibilityOffError,
  VisibilityOffSuccess,
  VisibilityOffInfo,
  VisibilityOnDsLight,
  VisibilityOnDsDark,
  VisibilityOnDsSecondary,
  VisibilityOnDsPrimary,
  VisibilityOnDsDisabled,
  VisibilityOnDsError,
  VisibilityOnDsSuccess,
  VisibilityOnDsInfo,
  VisibilityOnLight,
  VisibilityOnDark,
  VisibilityOnSecondary,
  VisibilityOnPrimary,
  VisibilityOnDisabled,
  VisibilityOnError,
  VisibilityOnSuccess,
  VisibilityOnInfo,
  WidgetsDsLight,
  WidgetsDsDark,
  WidgetsDsSecondary,
  WidgetsDsPrimary,
  WidgetsDsDisabled,
  WidgetsDsError,
  WidgetsDsSuccess,
  WidgetsDsInfo,
  WidgetsLight,
  WidgetsDark,
  WidgetsSecondary,
  WidgetsPrimary,
  WidgetsDisabled,
  WidgetsError,
  WidgetsSuccess,
  WidgetsInfo
}