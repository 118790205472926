import queueFetch from 'services/queue-fetch';
import { buildVersion, devMode } from 'config';
import getPort, {
	clearPort,
	clearShowPermissionsRationale,
	getShouldShowPermissionsRationale,
	init,
	// getAutoPermissions, // for stubbing
	// getZeroState, // for stubbing
} from './get-port';

const formUrl = (url) => {
	const port = getPort();

	return `http://localhost:${port}/${url}`;
};

const logit = (error) => {
	queueFetch('/log/pwa', {
		method: 'POST',
		json: [{
			level: 'error',
			payload: {
				errorMessage: 'Failed android api query',
				stack: error.stack,
				buildVersion,
				environment: devMode ? 'dev' : 'prod',
			},
		}],
		includeToken: true,
	});
};

const fetchWithTimeout = (url, options = {}, timeout = 60000) => Promise.race([
	fetch(url, options),
	new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), timeout)),
]);

const fetchPermissions = async () => {
	try {
		const port = getPort();

		if (!port) {
			return false;
		}

		const response = await fetchWithTimeout(formUrl('permissions'));
		return response.status < 400;
	} catch (err) {
		logit(err);
		clearPort();
		return false;
	}
};

const fetchAlbums = async () => {
	try {
		const response = await fetchWithTimeout(formUrl('albums'));
		const albums = await response.json();
		return albums;
	} catch (err) {
		logit(err);
		clearPort();
		throw err;
	}
};

const fetchMedia = async (album, options = {}) => {
	try {
		const { limit = 36, offset = 0 } = options;
		const response = await fetchWithTimeout(formUrl(`media?album=${encodeURIComponent(album.name)}&limit=${limit}&offset=${offset}`));
		const media = await response.json();
		return media;
	} catch (err) {
		logit(err);
		clearPort();
		throw err;
	}
};

const fetchFile = async (path) => {
	try {
		const response = await fetch(formUrl(`storage?path=${encodeURIComponent(path)}`));
		const blob = await response.blob();
		return blob;
	} catch (err) {
		logit(err);
		clearPort();
		throw err;
	}
};

const getThumbnailPath = (path) => formUrl(`thumbnail?path=${encodeURIComponent(path).replace('(', '%28').replace(')', '%29')}`);
const getFilePath = (path) => formUrl(`storage?path=${encodeURIComponent(path).replace('(', '%28').replace(')', '%29')}`);

window.fetchPermissions = fetchPermissions;
window.fetchAlbums = fetchAlbums;
window.fetchMedia = fetchMedia;
window.fetchFile = fetchFile;

export {
	init as initFromApp,

	getFilePath,
	getThumbnailPath,
	fetchPermissions,
	fetchAlbums,
	fetchMedia,
	fetchFile,

	clearPort,
	getPort,

	clearShowPermissionsRationale,
	getShouldShowPermissionsRationale,
};
