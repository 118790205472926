// const TIMEOUT = 300;
// const SLIDE_TIMEOUT = 200;
// const OPACITY_TIMEOUT = TIMEOUT / 3;

import waitFor from 'utils/wait-for';

// const allTransitionProperties = ['transform', 'width', 'height', 'top', 'left'];
// const quickTransitionProperties = [];

// const quickTransitionPropertyStyles = quickTransitionProperties.map((prop) => `${prop} ${(TIMEOUT / 1000) * 0.95}s ease-in-out`).join(', ');
// const allTransitionPropertyStyles = allTransitionProperties.map((prop) => `${prop} ${TIMEOUT / 1000}s ease-in-out`).join(', ');

// const COMPANION_EXPANSION_TRANSITION = `opacity 50ms ease-in-out 300ms, ${allTransitionPropertyStyles}, ${quickTransitionPropertyStyles}`;
const EXPANSION_TRANSITION = 'all 375ms ease-in-out';
const FORWARD_SLIDE_TRANSITION = 'all 375ms ease-in-out';
const BACK_SLIDE_TRANSITION = 'all 375ms ease-in-out';

export const tickChange = (ref, attributes, waitForTransitionEnd = false) => new Promise((resolve) => {
	const keys = Object.keys(attributes);

	if (!ref.current) {
		return;
	}

	for (let i = 0; i < keys.length; i++) {
		if (!keys[i]) {
			ref.current.style.removeProperty(keys[i]);
		} else {
			ref.current.style[keys[i]] = attributes[keys[i]];
		}
	}

	if (waitForTransitionEnd) {
		ref.current.addEventListener('transitionend', () => {
			window.requestAnimationFrame(resolve);
		});
	} else {
		window.requestAnimationFrame(resolve);
	}
});

export const tickSetup = async (fRef, companionRef, rect, options = {}) => {
	const {
		// companionTransition,
		expansionTransition,
	} = options;
	const viewportWidth = window.innerWidth;
	const viewportHeight = window.innerHeight;
	const scaleX = rect.width / viewportWidth;
	const scaleY = rect.height / viewportHeight;
	const scale = Math.min(scaleX, scaleY);

	const initialTop = rect.top;
	const initialLeft = rect.left;

	await tickChange(fRef, {
		transition: undefined,
	});

	// await tickChange(companionRef, {
	// 	transition: undefined,
	// });

	// await tickChange(companionRef, {
	// 	top: `${initialTop}px`,
	// 	left: `${initialLeft}px`,
	// 	height: `${rect.height}px`,
	// 	width: `${rect.width}px`,
	// 	opacity: 1,
	// });

	await tickChange(fRef, {
		top: `${initialTop}px`,
		left: `${initialLeft}px`,
		transform: `scale(${scale})`,
	});

	// await tickChange(companionRef, {
	// 	transition: companionTransition || COMPANION_EXPANSION_TRANSITION,
	// });

	await tickChange(fRef, {
		transition: expansionTransition || EXPANSION_TRANSITION,
	});
};

export const tickCompanion = async () => {
	// companionRef
	// await tickChange(companionRef, {
	// 	top: 0,
	// 	left: 0,
	// 	height: '100%',
	// 	width: '100%',
	// 	opacity: 1,
	// });

	// companionRef.current.style.opacity = 0;
};

export const tickInstant = async (fRef) => {
	await tickChange(fRef, {
		top: 0,
		left: 0,
		transform: 'scale(1)',
		opacity: 1,
	});

	await waitFor(400);
};

export const tickSetupDirection = async (fRef, direction, waitForTransitions, distance = 85) => {
	const multiplier = direction === 'forward' ? 1 : -1;
	const transform = `translateX(${distance * multiplier}%)`;

	await tickChange(fRef, { transform: undefined }, waitForTransitions);
	await tickChange(fRef, {
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		opacity: 1,
		transform,
	});
};

export const tickSlide = async (fRef, direction) => {
	await tickChange(fRef, { transform: undefined });
	await tickSetupDirection(fRef, direction);
	await tickChange(fRef, {
		transition: direction === 'forward'
			? FORWARD_SLIDE_TRANSITION
			: BACK_SLIDE_TRANSITION,
	});
	await tickChange(fRef, { transform: 'translateX(0)' });
};

export const tickPartialSlide = async (fRef, direction) => {
	await tickChange(fRef, { transform: undefined });
	await tickSetupDirection(fRef, direction, undefined, 100);
	await tickChange(fRef, {
		transition: 'all 100ms ease-in-out',
	});
	await tickChange(fRef, { transform: 'translateX(0)' });
};
