import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';
import { update as updateMyAccountExperiences } from 'app/slices/user-account-v2/my-experiences';
import { requestDeleteMyExperience } from 'app/slices/user-account-v2/my-experiences/thunks';
import { reset as resetComments, requestExperienceComments } from 'app/slices/comments';
import {
	remove as removeUnsavedExperiences,
} from 'app/slices/saves';
import emitter from 'features/ExperienceModal/emitter';
import { notify } from 'app/slices/notifications/notifications';
import waitFor from 'utils/wait-for';

const INCREMENT = 2;

export const ecrReduxMap = {};

export const register = (key, options) => ecrReduxMap[key] = options;

export const updateExperience = createAsyncThunk(
	'updateExperience',
	async (payload, {
		getState,
		dispatch,
		rejectWithValue,
	}) => {
		try {
			const state = getState();
			const { session } = state.session;
			const userId = session?.uuid;
			const experienceUser = payload.user.uuid;
			const slice = state.experienceModal;
			const { selector } = slice;

			const updateAction = ecrReduxMap[selector].update;

			dispatch(update(payload));

			if (userId === experienceUser && selector !== 'getUserAccountExperiences') {
				dispatch(updateMyAccountExperiences(payload));
			}

			dispatch(updateAction(payload));
			return null;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const deleteExperience = createAsyncThunk(
	'deleteExperience',
	async (experience, {
		getState,
		dispatch,
		rejectWithValue,
	}) => {
		try {
			const state = getState();
			const slice = state.experienceModal;
			const { selector } = slice;
			dispatch(requestDeleteMyExperience(experience));
			const deleteAction = ecrReduxMap[selector].deleteMyExperience;
			dispatch(deleteAction(experience));
			return null;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const fetchExperience = createAsyncThunk(
	'fetchExperience',
	async (uid, {
		rejectWithValue,
		dispatch,
	}) => {
		try {
			const response = await api.get(`/experiences/${uid}`);
			return response.data;
		} catch (error) {
			if (error?.response?.status === 404) {
				dispatch(notify({
					severity: 'info',
					description: 'Experience could not be found.',
					autoHideDuration: 2000,
				}));
				// not sure where should we redirect user
				// ios also has a dark screen
				// TODO: if the experience 404s we need to somehow navigate them
				return rejectWithValue(error);
			}
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const prepareExperiences = createAsyncThunk(
	'prepareExperiences',
	async ({
		selector,
		show,
		uid,
		comment,
		find = true,
	}, {
		getState,
		rejectWithValue,
		dispatch,
	}) => {
		try {
			const state = getState();
			const slice = state.experienceModal;
			const { experiences, range } = slice;
			selector = selector || slice.selector;

			const selectorFn = ecrReduxMap[selector].selector;
			const allExperiences = selectorFn(state);

			const commentEId = state.commentsSlice.experienceUid;
			if (comment && commentEId !== uid) {
				dispatch(requestExperienceComments({
					uid,
					comment,
				}));
			}

			const respond = (exps, r) => {
				const d = {
					experiences: exps,
					range: r,
					selector,
					show: typeof show !== 'undefined' ? show : undefined,
					uid,
				};

				try {
					const experience = exps.find((ex) => ex.uid === uid);
					if (!experience?.downloadable) {
						api.post(`/experiences/${uid}/download`);
					}
				} catch (err) {
					// ignore
				}
				return d;
			};

			// if there are no experiences, prepare them:
			if (!experiences.length) {
				// find the uid
				const ei = allExperiences.findIndex((e) => e.uid === uid);

				// load it into memory and unshift experiences onto it
				if (ei === -1) {
					if (find) {
						const loadedExperience = await dispatch(fetchExperience(uid));
						const nextExperiences = allExperiences.slice(0, INCREMENT * 2);
						nextExperiences.unshift(loadedExperience?.payload);
						return respond(nextExperiences, [0, nextExperiences.length]);
					}
					emitter.emit('suspense-dismiss');
					return respond([], [0, 0]);
				}

				// find the spot & craft experiences around it
				const start = Math.max(ei - INCREMENT, 0);
				const end = Math.min(ei + INCREMENT, allExperiences.length);
				const nextExperiences = allExperiences.slice(
					start,
					end,
				);
				return respond(nextExperiences, [start, end]);
			}

			// already have experiences in memory, determine if we need to build the buffer around it
			let index = experiences.findIndex((e) => e.uid === uid);
			let loadedExperience;
			let eLength = experiences.length;
			if (index === -1) {
				index = 0;
				eLength++;
				loadedExperience = await dispatch(fetchExperience(uid));
			}

			const newRange = [...range];

			// if we need to load before
			if (index - INCREMENT < 0) {
				newRange[0] = Math.max(newRange[0] - INCREMENT, 0);
			}

			// if we need to load after
			if (index + INCREMENT > eLength - 1) {
				newRange[1] = Math.min(newRange[1] + INCREMENT + 1, allExperiences.length);
			}

			let nextExperiences = allExperiences.slice(newRange[0], newRange[1]);

			if (loadedExperience) {
				nextExperiences.unshift(loadedExperience?.payload);
			}

			// if we need to slice off before
			const minStart = index - (INCREMENT * 2);
			if (minStart > 0) {
				newRange[0] += minStart;
				nextExperiences = nextExperiences.slice(minStart);
			}

			// if we need to slice off end
			const maxEnd = index + (INCREMENT * 2);
			if (maxEnd < eLength - 1) {
				const sliceOff = (eLength - 1) - maxEnd;
				newRange[1] -= sliceOff;
				nextExperiences = nextExperiences.slice(0, maxEnd);
			}

			return respond(nextExperiences, newRange);
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const dismiss = createAsyncThunk(
	'dismiss',
	async (_, {
		dispatch,
		rejectWithValue,
	}) => {
		try {
			dispatch(resetComments());
			dispatch(removeUnsavedExperiences());
			await waitFor(350);
			return null;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const initialState = {
	errors: null,
	exiting: null,
	experiences: [],
	loaded: false,
	selector: null,
	show: false,
	transitioned: false,
	range: [],
	loading: false,
	loadingSingleExperience: false,
	singleExperienceError: null,
	experienceToModify: null,
	openDirective: null,
};

const experienceModalSlice = createSlice({
	name: 'experienceModal',
	initialState: { ...initialState },
	reducers: {
		show: (state, action) => {
			const { uid, selector } = action.payload;
			state.show = true;
			state.uid = uid;
			state.selector = selector;
		},
		update: (state, action) => {
			const previousState = [...state.experiences];
			const newState = [];

			for (let i = 0; i < previousState.length; i++) {
				if (previousState[i].uid === action.payload.uid) {
					previousState[i] = action.payload;
				}
				newState.push(previousState[i]);
			}
			state.experiences = newState;
		},
		setLoaded: (state, action) => {
			state.loaded = action.payload;
		},
		setExperienceToModify: (state, action) => {
			state.experienceToModify = action.payload;
		},
		setTransitioned: (state, action) => {
			state.transitioned = !!action.payload;
		},
		setOpenDirective: (state, action) => {
			if (!action.payload) {
				state.openDirective = null;
				return;
			}

			const {
				querySelector,
				selectorFn,
				src,
				type,
			} = action.payload;

			state.openDirective = {
				querySelector,
				selectorFn,
				src,
				type: type || 'expansion',
			};
		},
	},
	extraReducers: {
		[dismiss.pending]: (state, action) => {
			const { uid, experiences } = state;
			const index = experiences.findIndex((ex) => ex.uid === uid);

			state.exiting = action.meta.arg
				? null
				: { ...experiences[index] };

			state.uid = null;
		},

		[dismiss.fulfilled]: (state) => {
			state.exiting = null;
			state.transitioned = false;
			if (!state.uid) {
				state.errors = null;
				state.experiences = [];
				state.range = [];
				state.show = false;
				state.selector = null;
			}
		},

		[prepareExperiences.rejected]: (state, action) => {
			state.errors = action.payload;
		},
		[prepareExperiences.pending]: (state) => {
			state.loading = true;
		},

		[prepareExperiences.fulfilled]: (state, action) => {
			const {
				experiences,
				range,
				selector,
				show,
				uid,
			} = action.payload;

			state.errors = null;
			state.experiences = experiences;
			state.range = range || [];
			state.selector = selector;
			state.uid = uid;
			state.loading = false;
			if (typeof show !== 'undefined') {
				state.show = show;
			}
		},
		[fetchExperience.pending]: (state) => {
			state.loadingSingleExperience = true;
		},
		[fetchExperience.rejected]: (state, action) => {
			state.loadingSingleExperience = false;
			state.singleExperienceError = action.payload;
		},
		[fetchExperience.fulfilled]: (state) => {
			state.loadingSingleExperience = false;
		},

	},
});

const { name, reducer, actions } = experienceModalSlice;
const {
	show,
	update,
	setLoaded,
	setExperienceToModify,
	setOpenDirective,
	setTransitioned,
} = actions;

export {
	name,
	show,
	update,
	setLoaded,
	setExperienceToModify,
	setOpenDirective,
	setTransitioned,
};

export const getSlice = (state) => state[name];
export const getSelector = createSelector(getSlice, (slice) => (slice?.selector ? ecrReduxMap[slice.selector].selector : null));
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSingleExperienceError = createSelector(getSlice, (slice) => slice?.singleExperienceError);
export const getUid = createSelector(getSlice, (slice) => slice?.uid);
export const getLoaded = createSelector(getSlice, (slice) => slice?.loaded);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getLoadingSingleExperience = createSelector(getSlice, (slice) => slice?.loadingSingleExperience);
export const getShow = createSelector(getSlice, (slice) => slice?.show);
export const getTransitioned = createSelector(getSlice, (slice) => slice?.transitioned);
export const getRange = createSelector(getSlice, (slice) => slice?.range || []);
export const getExperiences = createSelector(getSlice, (slice) => slice?.experiences || []);
export const getExperienceToModify = createSelector(getSlice, (slice) => slice?.experienceToModify);
export const getOpenDirective = createSelector(getSlice, (slice) => slice?.openDirective);
export const getExitingExperience = createSelector(getSlice, (slice) => slice?.exiting);
export const getActiveExperience = createSelector((state) => state, (state) => {
	const { uid, experiences } = state[name];
	const index = experiences.findIndex((ex) => ex.uid === uid);
	return experiences[index];
});
export const getVisibleExperience = createSelector(
	[getActiveExperience, getExitingExperience],
	(activeExperience, exitingExperience) => {
		if (activeExperience) {
			return activeExperience;
		}

		if (!exitingExperience) {
			return null;
		}

		const e = { ...exitingExperience };
		e.exiting = true;
		return e;
	},
);

injectReducer(name, reducer);
