import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import useRequireSession from 'app/hooks/useRequireSession';
import useOnboarding from 'app/hooks/useOnboarding';
import { getLoading, getSession, getToken } from 'app/slices/authentication/session/session';
import ECRProvider from 'features/ExperienceModal/ECRProvider';
import { getLoaded } from 'app/slices/experience-modal';
import { getRouterCalls } from 'app/slices/layout';
import SwNotification from 'features/SwNotification';
import { makeStyles } from '@material-ui/core';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import { useQueryParam } from 'app/hooks/useQueryParams';
import { useScrollState } from 'routes/RootPage/hooks';
import useDataProvider from './hooks/useDataProvider';
import Background from './Background';
import useIsAuthorized from './hooks/useIsAuthorized';
import useSession from './hooks/useSession';
import { usePageState } from './PageContext';

const useStyles = makeStyles((theme) => ({
	updateBannerExists: {
		position: 'relative',
		top: theme.spacing(12),
	},
	background: {
		background: theme.palette.background.page,
		minHeight: '100vh',
	},
}));

const InnerComponent = React.memo(({
	WrappedComponent: WC,
	showUpdateBanner: sup,
	classes: c,
	props: p,
	loaded: l,
	routeProps: rp,
}) => (
	<div className={sup ? clsx(c.updateBannerExists, c.background) : c.background}>
		{rp.Background && <Background src={rp.Background} />}
		<SwNotification />
		{!l && rp.Skeleton && <rp.Skeleton />}
		{rp.Header && <rp.Header {...p} />}
		{l && (<WC {...p} {...rp} />)}
	</div>
));

/**
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @param {Object} routeProps - Additional props for the route.
 * @returns {React.Component} - The wrapped component.
 */

const withLayout = (WrappedComponent, routeProps = {}) => (props) => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const isAuthorized = useIsAuthorized();
	const { scrollY, setScrollTop } = useScrollState(routeProps.preserveScrollState, pathname);
	const routerCalls = useSelector(getRouterCalls);
	const experienceUid = useQueryParam('experience');

	useSession();
	const session = useSelector(getSession);
	const token = useSelector(getToken);
	const sessionLoading = useSelector(getLoading);
	const experienceModalLoaded = useSelector(getLoaded);

	const onboarding = useOnboarding();
	const { snapNavigate } = usePageState();
	const showUpdateBanner = useSelector(getIsUpdatedVersionAvailable);

	if (routeProps.authorization) {
		useRequireSession();
	}

	const isRootHide = routeProps.root && props?.show === false;

	const loadData = isRootHide
		? []
		: routeProps.loadData || [];

	const { loaded, errorKey } = useDataProvider(
		loadData,
		!routeProps.authorization || !!session,
		pathname,
		routeProps.skipReloads,
	);

	const hideContent = routerCalls < 2 && experienceUid && !experienceModalLoaded;

	useEffect(() => {
		if (routeProps.root && props?.show) {
			setScrollTop(scrollY);
		}

		if (!routeProps.root) {
			setScrollTop(scrollY);
		}
	}, [routeProps.root, props?.show]);

	useEffect(() => {
		if (isRootHide) {
			return;
		}

		const isOnboarding = pathname.slice(0, 11) === '/onboarding';

		if (!token && routeProps.authorization) {
			snapNavigate('/landing');
			return;
		}

		if (sessionLoading === null || (sessionLoading || isOnboarding)) {
			return;
		}

		if (routeProps.authorization) {
			if (!isAuthorized) {
				snapNavigate('/landing');
			}

			const goSplash = !onboarding?.activities && !onboarding?.destinations && !onboarding?.regions;

			if (!isOnboarding) {
				if (goSplash) {
					snapNavigate('/onboarding/splash');
				} else if (!onboarding?.activities) {
					snapNavigate('/onboarding/activities');
				} else if (!onboarding?.destinations) {
					snapNavigate('/onboarding/destinations');
				} else if (!onboarding?.regions) {
					snapNavigate('/onboarding/regions');
				}
			}
		} else if (routeProps.authorization === false && isAuthorized) {
			snapNavigate('/');
		}
	}, [routeProps.authorization, isAuthorized, onboarding, pathname, sessionLoading, snapNavigate, token, isRootHide]);

	if (routeProps.authorization && !session) {
		return null;
	}

	const ErrorComponent = (errorKey && routeProps.errors && routeProps.errors[errorKey]) ? routeProps.errors[errorKey] : null;

	if (ErrorComponent) {
		return <ErrorComponent />;
	}

	if (routeProps.ecrSelectorName) {
		return (
			<ECRProvider disable={isRootHide} isDataLoaded={loaded} experiencesSelectorKey={routeProps.ecrSelectorName}>
				<div className="route-transition" style={{ display: hideContent ? 'none' : 'block' }}>
					<InnerComponent WrappedComponent={WrappedComponent} showUpdateBanner={showUpdateBanner} classes={classes} props={props} loaded={loaded} routeProps={routeProps} />
				</div>
			</ECRProvider>
		);
	}

	return <InnerComponent WrappedComponent={WrappedComponent} showUpdateBanner={showUpdateBanner} classes={classes} props={props} loaded={loaded} routeProps={routeProps} />;
};

export default withLayout;
