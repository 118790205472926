import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import { getLoadingSingleExperience } from 'app/slices/experience-modal';
import WaveSkeleton from 'components/WaveSkeleton';
import Avatar from 'rds/components/Avatar';

const DEFAULT_DESTINATION_IMAGE = 'https://cdn.dev.roamrhino.com/images/d/default-destination_128w.jpg';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	destinationTitle: {
		marginLeft: theme.spacing(2),
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},

}));

export default ({
	destination,
	onClick,
}) => {
	const classes = useStyles();
	const loading = useSelector(getLoadingSingleExperience);

	if (!destination) {
		return null;
	}

	if (loading) {
		return (
			<div
				className={classes.root}
				data-ignoreslider="true"
			>
				<WaveSkeleton style={{ width: 145, height: 32, borderRadius: 4 }} />
			</div>
		);
	}

	return (
		<div
			className={classes.root}
			data-ignoreslider="true"
			onClick={() => {
				if (!destination?.custom && !!destination?.title.length) {
					onClick();
				}
			}}
		>
			<Avatar
				src={destination?.thumbnail?.small?.jpg || DEFAULT_DESTINATION_IMAGE}
				size="xxxSmall"
				alt="destinationAccount"
			/>
			<Typography className={classes.destinationTitle} variant="subtitle2">{destination?.title}</Typography>
		</div>
	);
};
