import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { follow } from 'app/slices/onboarding/follow';
import { getSuggestedDestinations } from 'app/slices/destinations-search/suggested-destinations';
import Item from './Item';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'left',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	list: {
		position: 'absolute',
		overflowY: 'scroll',
		left: 0,
		right: 0,
		paddingRight: theme.spacing(6),
		paddingLeft: theme.spacing(6),
		paddingBottom: theme.spacing(6),
	},

}));

export default ({ headerRef }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const destinations = useSelector(getSuggestedDestinations);

	if (!headerRef?.current) {
		return null;
	}

	const rect = headerRef.current.getBoundingClientRect();
	const px = `${rect.height}px`;
	const top = px;
	const height = `calc(100vh - ${px})`;

	return (
		<div className={classes.root}>
			<div className={classes.list} style={{ top, height }}>
				{ destinations.map((d) => (
					<Item
						key={d.uid}
						onSelect={() => {
							dispatch(follow(d));
						}}
						{...d}
					/>
				)) }

			</div>

		</div>
	);
};
