import React, { useEffect } from 'react';
import Header from './Header';
import Contents from './Contents';

export default () => {
	useEffect(() => {
		navigator.serviceWorker.ready.then((registration) => {
			registration.update();
		});
	}, []);

	return (
		<>
			<Header title="Settings" rootStyle={{ position: 'fixed', zIndex: 2 }} />
			<Contents />
		</>
	);
};
