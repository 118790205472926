import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import successIconImage from 'routes/ContentCreation/Permisions/AudioCamera/assets/success.png';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import { notifyErrors } from 'app/slices/notifications/notifications';
import waitFor from 'utils/wait-for';
import { useParams } from 'react-router-dom';
import Images from './Images';
import Access from '../Access';

const FADE_TIMER = 225;

const useStyles = makeStyles((theme) => ({
	button: {
		textAlign: 'center',
		color: theme.colors.primary.C500,
		marginBottom: theme.spacing(2),
		width: 256,
		height: 44,
	},
	disabled: {
		color: theme.colors.neutral.C500,
	},
	overlay: {
		position: 'fixed',
		background: '#0B1718',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 5,
		transition: `all ${FADE_TIMER / 1000}s`,
		opacity: 0,
		visibility: 'hidden',
	},
	next: {
		opacity: 1,
		visibility: 'visible',
	},
	checkMark: {
		paddingBottom: theme.spacing(0.5),
	},
}));

const getRequest = async (opts) => {
	try {
		const stream = await navigator.mediaDevices.getUserMedia(opts);
		stream.getTracks().forEach((t) => t.stop());
		return true;
	} catch (err) {
		if (err.message === 'Permission denied') {
			const device = opts.audio ? 'microphone' : 'camera';
			notifyErrors([{ title: `Unable to access ${device}`, text: `Go to Site Settings to grant Rhino ${device} permissions.` }]);
		} else {
			notifyErrors(getErrorsFromRejectedRequest(err));
		}
		return false;
	}
};

const requestCameraPermission = async () => {
	const r = await getRequest({
		audio: false,
		video: true,
	});
	return r;
};

const requestMicrophonePermission = async () => {
	const r = await getRequest({
		audio: true,
		video: false,
	});
	return r;
};

export default ({
	camera,
	microphone,
	onChange,
}) => {
	const classes = useStyles();
	const params = useParams();

	const [go, setGo] = useState(false);
	const successIcon = (
		<img
			alt=""
			src={successIconImage}
			className={classes.checkMark}
		/>
	);

	const triggerOnChange = async (kv) => {
		if (camera && microphone) {
			setGo(true);
			await waitFor(FADE_TIMER);
		}
		onChange(kv);
	};

	const overlayClass = go ? clsx(classes.overlay, classes.next) : classes.overlay;

	if (params.tab === 'text') {
		return null;
	}

	return (
		<>
			<Images />
			<Access
				title="Start sharing on Rhino"
				subtitle="Allow access to create and take photos and videos"
			>
				<Button
					onClick={async () => {
						const v = await requestCameraPermission();
						triggerOnChange({ camera: v });
					}}
					disabled={camera}
					className={classes.button}
					classes={{ disabled: classes.disabled }}
				>
					Allow Camera Access
					{camera ? successIcon : null}
				</Button>
				<Button
					onClick={async () => {
						const v = await requestMicrophonePermission();
						triggerOnChange({ microphone: v });
					}}
					disabled={microphone}
					className={classes.button}
					classes={{ disabled: classes.disabled }}
				>
					Allow Microphone Access
					{microphone ? successIcon : null}
				</Button>
				<div className={overlayClass} />
			</Access>
		</>
	);
};
