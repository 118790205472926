import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { getVisibleExperience } from 'app/slices/experience-modal';
import { getAlertMessage } from 'app/slices/saves';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 'calc(50% - 25px)',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		pointerEvents: 'none',
		zIndex: 13,
	},
	message: {
		padding: theme.spacing(4),
		background: theme.colors.neutral.C800,
		borderRadius: 4,
	},

}));

export default ({ active }) => {
	const classes = useStyles();
	const activeExperience = useSelector(getVisibleExperience);
	const alertMessage = useSelector(getAlertMessage);

	if (!alertMessage || activeExperience?.uid !== alertMessage?.id || !active) {
		return null;
	}

	return (
		<div className={classes.root}>
			<Typography className={classes.message} varient="subtitle1">{alertMessage?.message}</Typography>
		</div>
	);
};
