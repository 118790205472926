import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import { openOverflowMenu } from 'app/slices/layout';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		height: 48,
		top: 0,
		left: 0,
		right: 0,
		zIndex: 11,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.colors.neutral.C900,
		color: theme.colors.neutral.C50,
	},
	iconContainer: {
		position: 'absolute',
		right: 0,
	},
	backContainer: {
		position: 'absolute',
		left: 0,
	},
}));

export default ({
	overflow,
	title,
	back,
	onBack,
	disableOverflow,
	className,
	fixPosition = true,
	...rest
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div className={clsx(classes.root, className)} {...rest} data-fix-position-fixed={fixPosition}>
			<Typography
				className={classes.title}
				variant="body1"
			>
				{title}
			</Typography>

			{
				overflow
					? (
						<div className={classes.iconContainer}>
							<IconV3
								icon="Dots"
								size={32}
								onClick={() => {
									if (disableOverflow) {
										return;
									}
									dispatch(openOverflowMenu(overflow));
								}}
							/>
						</div>
					)
					: null
			}

			{
				back ? (
					<div
						className={classes.backContainer}
						onClick={() => {
							if (onBack) {
								onBack();
								return;
							}
							navigate(-1);
						}}
					>
						<IconV3
							icon="ChevronLeft"
							size={32}
							containerSize={48}
						/>
					</div>
				) : null
			}
		</div>
	);
};
