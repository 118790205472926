import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import { getIsUpdatedVersionAvailable } from 'app/slices/app/app';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../SeachBar';

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 14,
		height: 112,
		width: '100%',
		background: theme.palette.background.page,
		overflowX: 'clip',
	},
	filterHeaderClass: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		marginTop: 112,
		marginBottom: 16,
		zIndex: 18,
	},
	rootSecondary: {
		height: 160,
		paddingTop: theme.spacing(12),
	},
	iconContainer: {
		position: 'absolute',
		justifyContent: 'center',
		alignItems: 'center',
		width: 48,
		height: 48,
		top: 0,
		left: 0,
		zIndex: 15,
	},
	inactive: {
		opacity: 0.3,
	},
	header: {
		zIndex: 1,
		overflowX: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#ffffff',
		width: '100%',
		paddingLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		paddingRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		backgroundColor: theme.palette.background.page,
		height: 48,
		position: 'absolute',
	},
	exploreHeader: {
		justifyContent: 'start',
		alignItems: 'end',
	},
	exploreContents: {},
	searchContents: {},
}));

export default ({ isSearching, setIsSearching }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const show = useSelector(getIsUpdatedVersionAvailable);

	const headerContainerClass = show ? clsx(classes.headerContainer, classes.rootSecondary) : clsx(classes.headerContainer);

	return (
		<div className={headerContainerClass}>
			<div className={classes.header}>
				<div
					className={classes.iconContainer}
					onClick={(e) => {
						e.preventDefault();
						setTimeout(() => {
							dispatch(setIsSearching(false));
							navigate(-1);
						}, 10);
					}}
				>
					<IconV3
						icon="ChevronLeft"
						size={32}
						dropShadow
					/>
				</div>
				<Typography className={classes.searchContents} variant="body1">Search</Typography>
			</div>
			<SearchBar
				isSearching={isSearching}
				setIsSearching={setIsSearching}
				searchPage
			/>
		</div>
	);
};
