import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * useDataProvider - A custom React hook tailored to ensure data for a page or component is fetched and loaded
 * before it renders, especially after a navigation event.
 *
 * @param {Array<Function>} asyncFunctions - An array of asynchronous functions. Each function is expected to fetch
 * data when invoked. These functions can dispatch Redux actions and utilize route parameters for dynamic behavior.
 *
 * @param {boolean} isAuthorized - A boolean value indicating if a user is authorized to fetch the data.
 * If the user is unauthorized, the hook will not attempt to fetch data.
 *
 * @param {string} pathname - The current path of the route. Used to detect navigation changes to refresh
 * the data and reset the state.
 *
 * @returns {boolean} - A boolean indicating if the required data has been successfully loaded.
 */
const useDataProvider = (asyncFunctions, isAuthorized, pathname, skipReloads = false) => {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const fetching = useRef(false);

	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [errorKey, setErrorKey] = useState(null);

	useEffect(() => {
		if (!skipReloads) {
			setIsDataLoaded(false);
		}
		setErrorKey(null);
	}, [pathname, !skipReloads]);

	useEffect(() => {
		if (!isAuthorized || fetching.current) {
			return;
		}

		fetching.current = true;

		const fetchData = async () => {
			let tmpErrorKey = null;
			try {
				const promises = asyncFunctions.map((asyncFunc) => asyncFunc({
					dispatch,
					params,
					navigateCallback: (path) => navigate(path, { replace: true }),
				}));

				const responses = await Promise.all(promises);

				for (let i = 0; i < responses.length; i++) {
					tmpErrorKey = responses[i]?.error?.message;
					if (tmpErrorKey) {
						continue;
					}
				}
			} catch (err) {
				debugger;
			} finally {
				setIsDataLoaded(true);
				setErrorKey(tmpErrorKey);
				fetching.current = false;
			}
		};

		fetchData();
	}, [isAuthorized, asyncFunctions, dispatch, params]);

	return { loaded: isDataLoaded, errorKey };
};

export default useDataProvider;
