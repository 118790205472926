import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import Form from 'rds/components/Layouts/Form';
import SubmitButton from 'routes/Auth/components/SubmitButton/SubmitButton';
import queueFetch from 'services/queue-fetch';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import background from 'assets/background.jpg';
import store from 'store';
import { buildVersion, devMode } from 'config';

const CHUNK_ERROR_KEY = 'errors:chunkLoadError';
const CHUNK_EXPIRE = 1000 * 60;

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return {
			error,
			hasError: true,
		};
	}

	componentDidCatch(error, errorInfo) {
		try {
			navigator.serviceWorker.ready.then((registration) => {
				registration.update();

				if (error.message.indexOf('ChunkLoadError') !== -1) {
					const lastChunkError = store.get(CHUNK_ERROR_KEY);

					if (!lastChunkError) {
						store.set(CHUNK_ERROR_KEY, Date.now(), new Date().getTime() + CHUNK_EXPIRE);
						window.location.reload();
					}
				}
			});

			const stackTrace = error.stack.split('\n');
			const fileNameAndLineNumber = stackTrace[1].trim().replace(/^at\s+/, '');

			queueFetch('/log/pwa', {
				method: 'POST',
				json: [{
					level: 'error',
					payload: {
						errorMessage: `ErrorBoundary: ${error.message}`,
						fileNameAndLineNumber,
						stack: error.stack,
						errorInfo,
						buildVersion,
						environment: devMode ? 'dev' : 'prod',
					},
				}],
				includeToken: true,
			});

			return true;
		} catch (err) {
			return getErrorsFromRejectedRequest(err);
		}
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			return (
				<div style={{
					zIndex: 5,
					position: 'fixed',
					top: 0,
					bottom: 0,
					right: 0,
					left: 0,
					backgroundImage: `url(${background})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
				>
					<Form
						style={{
							marginTop: '35%',
						}}
						onSubmit={() => {
							window.location.reload();
						}}
					>
						<Typography
							style={{
								marginBottom: 16,
								textAlign: 'center',
							}}
							variant="h1"
						>
							Something went wrong
						</Typography>
						<Typography
							style={{
								marginBottom: 20,
								textAlign: 'center',
							}}
							variant="subtitle1"
						>
							But we’re getting things back up as soon as possible.

						</Typography>
						<SubmitButton>
							Refresh
						</SubmitButton>
					</Form>
					<Form
						onSubmit={() => {
							window.location.replace('/');
						}}
					>
						<SubmitButton>
							Home
						</SubmitButton>
					</Form>
				</div>
			);
		}

		return children;
	}
}

export default ErrorBoundary;
