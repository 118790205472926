import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';
import uploadQueue from 'lib/upload-queue';
import previewEmitter from '../preview-emitter';

export const requestInProgressExperiences = createAsyncThunk(
	'requestInProgressExperiences',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { inProgressExperiences } = getState().userAcccountV2InProgressExperiences;
			const { uuid } = getState().session.session;

			const uploadExperiences = await uploadQueue.fetchUploads(uuid);

			const [...experiences] = uploadExperiences;

			const uids = experiences.map((e) => e.uid).join(',');

			if (!uids.length) {
				return [];
			}

			const response = await api.get(`experiences/multi/?uids=${uids}`);
			const data = response.data.experiences;

			const ne = [];

			for (let i = 0; i < experiences.length; i++) {
				const exp = { ...experiences[i] };
				ne.push(exp);
				const index = data.findIndex((e) => e.uid === exp.uid);
				const inProgress = inProgressExperiences.find((e) => e.uid === exp.uid);
				const serverExp = data[index];

				let totalTasks = 0;
				let totalTasksDone = 0;

				if (!serverExp?.clips) {
					continue;
				}
				serverExp.clips.forEach((c) => {
					if (c.tasks) {
						c.tasks && c.tasks.forEach((t) => {
							totalTasks++;
							if (t.status === 'completed') {
								totalTasksDone++;
							}
						});
					}
				});

				const uploadPercentage = exp.done ? 75 : ((exp.completed / exp.total) * 75) || 0;
				const taskCompletedPercentage = ((totalTasksDone / totalTasks) * 25) || 0;

				const serverThumbnail = serverExp.clips.find(({ clipUid }) => clipUid === serverExp.coverClip)?.outputs?.thumbnail?.medium?.jpg;
				const serverPhoto = serverExp.clips.find(({ clipUid }) => clipUid === serverExp.coverClip)?.outputs?.photo?.medium?.jpg;

				const thumbnail = inProgress ? inProgress.thumbnail : null;

				if (exp.done) {
					exp.thumbnail = serverThumbnail || serverPhoto;
				}

				if (!exp.thumbnail) {
					if (thumbnail) {
						exp.thumbnail = thumbnail;
						previewEmitter.emit(`update-${exp.uid}`, thumbnail);
					} else {
						exp.thumbnail = exp.cover;
						previewEmitter.emit(`update-${exp.uid}`, exp.cover);
					}
				}

				exp.title = serverExp.title;
				exp.percentage = uploadPercentage + taskCompletedPercentage;
				const done = exp.percentage === 100;

				if (done) {
					uploadQueue.deleteExperience(exp.uid);
					// dispatch(requestInProgressExperiences());
				}
			}

			return ne;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestDeleteInProgressExperience = createAsyncThunk(
	'requestDeleteMyExperience',
	async (uid, { rejectWithValue }) => {
		try {
			const response = await api.delete(`experiences/${uid}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);
