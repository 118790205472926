import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	ccTransitionWrapper: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: '100%',
		width: '100%',
		zIndex: 15,
		transition: 'transform 300ms ease-in-out',
		MsOverflowStyle: 'none',
		scrollbarWidth: 'none',
		whiteSpace: 'nowrap',
		touchAction: 'none',
	},
	ccPane: {
		position: 'relative',
		display: 'inline-block',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
	},
});

const SlideTransitions = ({ components, path }) => {
	const classes = useStyles();
	const [showIndex, setShowIndex] = useState(0);

	const renderComponents = components.map(({ component, path: key }) => (
		<div className={classes.ccPane} key={key}>
			{ component }
		</div>
	));

	useEffect(() => {
		const n = components.findIndex((c) => c.path === path);
		if (n >= 0) {
			setShowIndex(n);
		}
	}, [showIndex, path]);

	return (
		<div
			className={classes.ccTransitionWrapper}
			style={{
				transform: `translateX(${Math.min(-showIndex, 0) * 100}%)`, // Note the negative value here
			}}
		>
			{renderComponents}
		</div>
	);
};

export default SlideTransitions;
