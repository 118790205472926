import React from 'react';
import { makeStyles } from '@material-ui/core';

const stops = [
	'rgba(27, 36, 45, 1) 0%',
	'rgba(17, 23, 29, 1) 25%',
	'rgba(27, 36, 45, .8) 50%',
	'rgba(17, 23, 29, 1) 75%',
	'rgba(27, 36, 45, 1) 100%',
];

const useStyles = makeStyles(() => ({
	skeleton: {
		display: 'block',
		width: '100%',
		height: '100%',
		borderRadius: 2,
		background: `linear-gradient(90deg, ${stops.join(', ')})`,
		backgroundSize: '300% 300%',
		backgroundRepeat: 'repeat-x',
		animation: 'skeleton 6s linear infinite',
	},
}));

export default ({
	...props
}) => {
	const classes = useStyles();

	return <div className={classes.skeleton} {...props} />;
};
