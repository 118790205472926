import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getRoute } from 'app/slices/experience-view';
import { getLoading, reset } from 'app/slices/experience-view/edit';
import LoadingPage from 'rds/components/Spinners/Page';
import EditExperience from '../EditExperience';
import Header from '../EditExperience/Header';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		zIndex: 14,
		display: 'fixed',
		background: theme.palette.background.page,
		overflowY: 'scroll',
	},
	contents: {
		display: 'flex',
		flexDirection: 'column',
	},
}));

export default ({
	uid,
	slider,
	...props
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const route = useSelector(getRoute);
	const loading = useSelector(getLoading);

	useEffect(() => () => {
		dispatch(reset());
	}, [route]);

	return (
		<div className={classes.root} data-ignoreslider="true">
			<div className={classes.contents}>
				<Header uid={uid} {...props} />
				{
					loading
						? <LoadingPage />
						: <EditExperience uid={uid} {...props} />
				}
			</div>
		</div>
	);
};
