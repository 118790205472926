import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	text: {
		color: theme.colors.neutral.C300,
	},

}));
export default () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.text} variant="subtitle1">No media available</Typography>
		</div>
	);
};
