import {
	createAsyncThunk,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';

export const requestUserIdentifiers = createAsyncThunk(
	'requestUserIdentifiers',
	async (_, { rejectWithValue }) => {
		try {
			const response = await api.get('accounts/profile/identifiers');
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

const initialState = {
	errors: null,
	loading: false,
	identifiers: null,
};

export const editExperienceUserIdentifiersSlice = createSlice({
	name: 'editExperienceUserIdentifiers',
	initialState,
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
	},
	extraReducers: {
		[requestUserIdentifiers.pending]: (state) => {
			state.loading = true;
		},

		[requestUserIdentifiers.rejected]: (state, action) => {
			state.errors = action.payload;
		},

		[requestUserIdentifiers.fulfilled]: (state, action) => {
			state.identifiers = action.payload.identifiers;
			state.errors = false;
		},
	},
});

const { name, reducer, actions } = editExperienceUserIdentifiersSlice;
const { reset } = actions;
export { reset };

export const getSlice = (state) => state[name];
export const getReset = createSelector(getSlice, (slice) => slice?.reset);
export const getUserIdentifiers = createSelector(getSlice, (slice) => slice?.identifiers);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);

injectReducer(name, reducer);
