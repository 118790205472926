import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import Icon from 'rds/components/Icon';
import { setCreateNavigation } from 'app/slices/content-creation/create/device-library/state';
import useNavigateCC from 'app/hooks/useNavigateCC';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
	},
	header: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		alignItems: 'center',
		marginRight: theme.spacing(8),
		marginLeft: theme.spacing(8),
	},
	border: {
		height: 1,
		width: '100%',
		background: theme.colors.neutral.C800,
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	texts: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingRight: theme.spacing(10),
		paddingLeft: theme.spacing(10),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		marginBottom: theme.spacing(2),
		color: theme.colors.neutral.C200,
	},
	subtitle: {
		color: theme.colors.information.C400,
	},

}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigateCC = useNavigateCC();

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.border} />
				<Icon
					icon="rhino"
					variant="light"
					containerHeight={15}
					containerWidth={22}
					height={15}
					width={22}
				/>
				<div className={classes.border} />
			</div>
			<div
				className={classes.texts}
				onClick={async () => {
					await dispatch(setCreateNavigation({
						open: true,
						slide: true,
					}));
					navigateCC('create', {
						tab: 'photo',
						subTab: '',
					});
				}}
			>
				<Typography className={classes.title} variant="caption">You are all caught up on the latest adventures.</Typography>
				<Typography className={classes.subtitle} variant="body1">Share your own experience</Typography>
			</div>
		</div>
	);
};
