import longPressScale from 'lib/long-press-scale';
import tray from 'lib/tray';
import createElement from 'utils/create-element';
import trayCreateElement, { trayCreatePlusElement } from './create-element';
import './index.css';

const CONTAINER_CLASS_NAME = 'experience_tray';
const CONTAINER_INNER_CLASS_NAME = 'experience_tray_inner';
const CONTAINER_GRADIENT_CLASS_NAME = 'experience_tray_gradient';
const SELECTED_TIMEOUT = 200;
let addFn;
let trayRef;
let changeFn;
let closeFn;
let selectFn;

const container = createElement({
	tagName: 'div',
	className: 'experience_tray_container',
});

// const toPx = (n) => `${Math.round(n)}px`;
let selectedElementIndex;
const createNx = () => {
	if (trayRef) {
		return trayRef;
	}

	// document.body.appendChild(container);
	let timeoutId = null;
	const onSelect = (item) => {
		if (!item) {
			return;
		}

		if (item.getAttribute('data-type') === 'add') {
			addFn && addFn();
			return;
		}

		selectedElementIndex = trayRef.elements.indexOf(item);

		for (let i = 0; i < trayRef.elements.length; i++) {
			const { el } = trayRef.elements[i];
			if ((item === el)) {
				el.classList.add('selected');
			} else {
				el.classList.remove('selected');
			}
		}
		if (timeoutId !== null) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(() => {
			selectFn && selectFn(item.getAttribute('data-tray-id'));
		}, SELECTED_TIMEOUT);
	};

	trayRef = tray(container, [], {
		boundaryY: 170,
		containerClassName: CONTAINER_CLASS_NAME,
		containerInnerClassName: CONTAINER_INNER_CLASS_NAME,
		gradientClassName: CONTAINER_GRADIENT_CLASS_NAME,
		highlightFunction: longPressScale,
		autoHide: 'true',
		onHighlight: () => {
			// item.querySelector(DICC).style.opacity = 1;
		},
		offHighlight: () => {
			// item.querySelector(DICC).style.opacity = 0;
		},
		onDrag: () => {
			// item.querySelector(DICC).style.opacity = 0;
		},
		onChange: (ids, selected) => {
			changeFn && changeFn({
				ids,
				selected: selected ? selected.getAttribute('data-tray-id') : trayRef.selected,
			});
		},
		onSelect,
	});

	// trayRef.disablePointerEvents();

	trayRef.on('tray-tap', () => {
		// closeFn && closeFn();
	});

	trayRef.on('hide', () => {
		closeFn && closeFn();
	});

	trayRef.hide();

	trayRef.addToEnd(9999999, trayCreatePlusElement());

	return trayRef;
};

export const setFns = ({
	change,
	close,
	select,
	add,
}) => {
	changeFn = change;
	closeFn = close;
	selectFn = select;
	addFn = add;
};

export const disable = () => {
	trayRef?.disablePointerEvents();
};

export const select = () => selectedElementIndex;

export const enable = () => {
	trayRef?.enablePointerEvents();
};

export const place = (el) => {
	createNx();
	if (trayRef) {
		el.appendChild(trayRef.container);
		trayRef.scrollListen();
	}
};

export const disablePlusElement = () => {
	if (!trayRef) {
		return;
	}

	const plusElement = trayRef.container.querySelector('[data-type="add"]');
	if (plusElement) {
		plusElement.style.opacity = 0.3;
	}
};

export const enablePlusElement = () => {
	if (!trayRef) {
		return;
	}

	const plusElement = trayRef.container.querySelector('[data-type="add"]');
	if (plusElement) {
		plusElement.style.opacity = 1;
	}
};

export const show = (options) => {
	trayRef?.show(options);
};

export const hide = () => {
	trayRef?.hide();
};

export const destroy = () => {
	trayRef?.destroy();
	trayRef = null;
};

export const scrollToEnd = () => {
	trayRef?.scrollToEnd();
};

export const add = ({ editingCanvas, id, type }) => {
	createNx();
	const { el, deleteButton } = trayCreateElement({ editingCanvas, id, type });

	deleteButton.addEventListener('touchstart', (e) => {
		e.stopPropagation();
		trayRef.remove(id);
	}, { passive: true });

	trayRef.add(id, el);
};

export const remove = (id) => {
	createNx();

	trayRef.remove(id);
};

export default createNx;
