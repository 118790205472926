/* eslint-disable import/prefer-default-export */
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';

const makeApiRequest = async (options) => {
	try {
		const response = await api(options);
		return response.data;
	} catch (error) {
		const err = handleSliceError(error, {
			notifyUser: options?.notifyUser,
		});

		if (options.rejectWithValue) {
			return options.rejectWithValue(err);
		}
		throw err;
	}
};

export { makeApiRequest };
