import useTheme from 'rds/theme/useRdsTheme';
import React from 'react';
import { neutral } from 'rds/colors';
import getSvg from './get-svg';

const SIZES = {
	xs: 44,
	small: 60,
	medium: 90,
	large: 140,
};

export default ({
	bodyColor,
	legColor,
	gutters = true,
	size = 'medium',
	square = false,
	variant,
	containerStyle,
	...rest
}) => {
	const theme = useTheme();

	if (!SIZES[size]) {
		throw new Error(`unsupported logo size ${size}`);
	}

	const height = SIZES[size] * 0.669921875;
	const width = SIZES[size];

	const defaults = {
		body: theme.palette.type === 'light' ? '#000000' : '#FFFFFF',
		leg: theme.palette.type === 'light' ? neutral.C700 : neutral.C600,
	};

	const background = getSvg({
		bodyColor: bodyColor || defaults.body,
		legColor: legColor || defaults.leg,
	});

	const style = {};

	if (gutters) {
		style.margin = theme.spacing(4);
	}

	const container = containerStyle || {
		margin: '0 auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};

	if (square) {
		container.height = width;
		container.width = width;
	}

	return (
		<div style={container}>
			<img
				height={height}
				width={width}
				style={style}
				src={`data:image/svg+xml;base64,${window.btoa(background)}`}
				alt="Rhino Logo"
				{...rest}
			/>
		</div>
	);
};
