import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import { getSession } from 'app/slices/authentication/session/session';
import { getCreatePermission, set } from 'app/slices/content-creation/permission';
import { requestVerifyEmail } from 'app/slices/account-settings';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		bottom: 0,
		paddingBottom: theme.spacing(6),
		right: theme.spacing(4),
		left: theme.spacing(4),
		background: theme.colors.neutral.C900,
		zIndex: 100,
	},
	background: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 9,
		background: theme.colors.neutral.C900,
		opacity: 0.6,
	},
	title: {
		textAlign: 'center',
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(4),
	},
	subtitle: {
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		marginBottom: theme.spacing(4),
		whiteSpace: 'normal',
		textAlign: 'left',
	},
	button: {
		background: theme.colors.neutral.C000,
		color: theme.colors.neutral.C900,
		padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
		'&:hover': {
			background: theme.colors.neutral.C000,
			color: theme.colors.neutral.C900,
		},
	},
	buttonContainer: {
		width: '100%',
		textAlign: 'center',
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const session = useSelector(getSession);
	const show = useSelector(getCreatePermission);

	if (!show) {
		return null;
	}

	return (
		<>
			<div className={classes.root}>
				<Typography className={classes.title} variant="title2">
					Verify your account
				</Typography>
				<Typography className={classes.subtitle} variant="body1">
					For the safety of our community, please verify your email to create experiences.
					An email was sent to
					<Typography component="span" variant="title2" className={classes.emailStyle}>
						{' '}
						{session?.email}
					</Typography>
					.
				</Typography>
				<div className={classes.buttonContainer}>
					<Button
						className={classes.button}
						size="large"
						disableRipple
						onClick={() => {
							dispatch(requestVerifyEmail());
							dispatch(set({ createPermissionModal: false }));
						}}
					>
						Resend email
					</Button>
				</div>
			</div>
			<div
				className={classes.background}
				onClick={() => {
					dispatch(set({ createPermissionModal: false }));
				}}
			/>
		</>
	);
};
