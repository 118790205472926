import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import { useDispatch } from 'react-redux';
import { reset } from 'app/slices/notifications';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'fixed',
		flexDirection: 'column',
		justifyContent: 'center',
		top: '30%',
		width: '100%',
		textAlign: 'center',
	},
	refresh: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(12.5),
	},
	subtitle: {
		marginRight: theme.spacing(8),
		marginLeft: theme.spacing(8),
		paddingRight: theme.spacing(4),
		paddingLeft: theme.spacing(4),
	},
	noConnection: {
		marginRight: theme.spacing(8),
		marginLeft: theme.spacing(8),
		marginBottom: theme.spacing(2),
	},
	retry: {
		marginLeft: theme.spacing(2),
		color: theme.colors.primary.C400,
	},
	error: {
		color: theme.colors.neutral.C500,
		marginTop: theme.spacing(18),
		marginRight: theme.spacing(12),
		marginLeft: theme.spacing(12),
	},

}));

export default ({ children }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isOnline, setOnline] = useState(true);

	useEffect(() => {
		setOnline(navigator.onLine);
	}, []);

	window.addEventListener('online', () => {
		setOnline(true);
		dispatch(reset());
	});

	window.addEventListener('offline', () => {
		setOnline(false);
	});

	if (isOnline) {
		return children;
	}
	return (
		<div className={classes.root}>
			<Typography className={classes.noConnection} variant="h2">No internet connection</Typography>
			<Typography className={classes.subtitle} variant="h6">Check your internet connection and try again.</Typography>
			<div className={classes.refresh} onClick={() => { window.location.reload(); }}>
				<IconV3
					icon="Refresh"
					color="primaryC600"
					size={20}
					containerSize={20}
				/>
				<Typography className={classes.retry} variant="subtitle1">Retry</Typography>
			</div>
			<Typography className={classes.error} variant="subtitle2"> Internet connection appears to be offline.</Typography>
		</div>
	);
};
