import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Box,
	makeStyles,
	Tab,
	Tabs,
	Typography,
} from '@material-ui/core';
import { getCurrentTab, setTab } from 'app/slices/search-explore/layout';
import clsx from 'clsx';
import WaveSkeleton from 'components/WaveSkeleton';
import EachUserAccount from './EachUserAccount';
import EachDestinationAccount from './EachDestinationAccount';

const useStyles = makeStyles((theme) => ({
	tabs: {
		position: 'fixed',
		left: 0,
		right: 0,
		top: 100,
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		background: theme.palette.background.page,
		zIndex: 14,
		overflowX: 'hidden',
		transition: 'transform 0.3s ease-in-out',
	},
	tab: {
		...theme.typography.body2,
	},
	container: {
		position: 'absolute',
		top: 156,
		left: 0,
		right: 0,
		bottom: 0,
		overscrollBehaviorY: 'none',
		paddingBottom: 400,
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		transition: 'transform 0.3s ease-in-out',
	},
	rootSecondary: {
		top: theme.spacing(37),
	},
	noResult: {
		position: 'fixed',
		top: 156,
	},
}));

const TabPanel = (props) => {
	const classes = useStyles();
	const {
		children, value, index,
	} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			className={classes.tabPanel}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</div>
	);
};

const NoResultFound = ({ loading }) => {
	const classes = useStyles();
	if (loading) {
		return [...Array(12)].map((s, index) => (
			<WaveSkeleton style={{ height: 12, marginBottom: 8 }} key={index} />
		));
	}
	return (
		<Typography
			className={classes.noResult}
			variant="body2"
		>
			No results found.
		</Typography>
	);
};

export default ({
	destinationsAndUsers,
	topResults,
	search,
	onChange,
	loading,
	secondaryPosition,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const tab = useSelector(getCurrentTab);
	const destinations = destinationsAndUsers.filter((d) => d.type === 'destination');
	const users = destinationsAndUsers.filter((d) => d.type === 'user');

	useEffect(() => {
		if (!search.length) {
			return;
		}
		if (search.startsWith('+')) {
			dispatch(setTab(2));
		} else if (search.startsWith('@')) {
			dispatch(setTab(1));
		} else {
			dispatch(setTab(0));
		}
	}, [search]);

	const handleChange = (event, v) => {
		dispatch(setTab(v));

		if (search.startsWith('@') && v === 2) {
			const previousValue = search.slice(1, search.length);
			const newValue = `+${previousValue}`;
			const e = { target: { value: newValue } };
			onChange(e);
		}
		if (search.startsWith('+') && (v === 1)) {
			const previousValue = search.slice(1, search.length);
			const newValue = `@${previousValue}`;
			const e = { target: { value: newValue } };
			onChange(e);
		}

		if ((!search.startsWith('+') || !search.startsWith('@')) && v === 0) {
			const previousValue = search.slice(1, search.length);
			const newValue = `${previousValue}`;
			const e = { target: { value: newValue } };
			onChange(e);
		}
		if ((!search.startsWith('+') && !search.startsWith('@')) && v === 1) {
			const previousValue = search;
			const newValue = `@${previousValue}`;
			const e = { target: { value: newValue } };
			onChange(e);
		}
		if ((!search.startsWith('+') && !search.startsWith('@')) && v === 2) {
			const previousValue = search;
			const newValue = `+${previousValue}`;
			const e = { target: { value: newValue } };
			onChange(e);
		}
	};

	const klass = secondaryPosition ? clsx(classes.tabs, classes.rootSecondary) : clsx(classes.tabs);

	return (
		<Box>
			<Box>
				<Tabs variant="fullWidth" className={klass} centered indicatorColor="primary" value={tab} onChange={handleChange}>
					<Tab className={classes.tab} disableRipple disableFocusRipple label="All" />
					<Tab className={classes.tab} disableRipple disableFocusRipple label="Users" />
					<Tab className={classes.tab} disableRipple disableFocusRipple label="Destinations" />
				</Tabs>
			</Box>
			<div className={classes.container}>
				<TabPanel value={tab} index={0}>
					{topResults.length ? topResults.map((d) => {
						if (d.type === 'user') {
							return (
								<EachUserAccount key={d.entity.uuid} user={d.entity} />
							);
						} if (d.type === 'destination') {
							return (
								<EachDestinationAccount key={d.entity.uid} destination={d.entity} />
							);
						}
						return null;
					}) : <NoResultFound loading={loading} />}
				</TabPanel>
				<TabPanel className={classes.panel} value={tab} index={1}>
					{users.length ? users.map((d) => (
						<EachUserAccount
							key={d.entity.uuid}
							user={d.entity}
						/>
					))
						: <NoResultFound loading={loading} />}
				</TabPanel>
				<TabPanel value={tab} index={2}>
					{destinations.length ? destinations.map((d) => (
						<EachDestinationAccount
							key={d.entity.uid}
							destination={d.entity}
						/>
					)) : <NoResultFound loading={loading} />}
				</TabPanel>
			</div>
		</Box>
	);
};
