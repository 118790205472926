import React from 'react';
import ErrorComponent from 'rds/components/Errors/ErrorComponent';

export default () => (
	<ErrorComponent
		show
		size="small"
		title="Oops"
		subtitle="We’re having trouble loading this page right now."
	/>
);
