import React from 'react';
import { useDispatch } from 'react-redux';
import { dismiss, remove } from 'app/slices/notifications';
import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import LowPri from './LowPri';
import HighPri from './HighPri';

const REMOVE_WAIT = 1000;

const useStyles = makeStyles({
	snackbar: {
		background: 'none',
		zIndex: 1000000,
	},
});

export default ({
	autoHideDuration,
	background,
	buttons,
	description,
	dismissed,
	severity,
	title,
	id,
	disableClose,
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const removeIt = (_id) => () => {
		dispatch(dismiss({ id: _id }));
		setTimeout(() => {
			dispatch(remove({ id: _id }));
		}, REMOVE_WAIT);
	};

	return (
		<Snackbar
			open={!dismissed}
			onClose={disableClose ? null : removeIt(id)}
			autoHideDuration={(disableClose || severity !== 'info') ? null : autoHideDuration}
			className={classes.snackbar}
		>
			{ severity === 'info' ? (
				<LowPri
					title={title}
					description={description}
					id={id}
					severity={severity}
					background={background}
				/>
			) : (
				<HighPri
					buttons={buttons}
					title={title}
					description={description}
					id={id}
					onClose={removeIt(id)}
				/>
			) }
		</Snackbar>
	);
};
