import {
	BIG_CARD_HEIGHT,
	DESTINATION_HEIGHT,
	STANDARD_CARD_HEIGHT,

	BIG_CARD_ASPECT_RATIO,
	BUDDY_CARD_ASPECT_RATIO,
	DESTINATION_ASPECT_RATIO,
	STANDARD_CARD_ASPECT_RATIO,
} from './constants.js';
import destinationBackground from '../Assets/destination-background.png';

const TEMPLATE_TO_DIMENSIONS = {
	buddy: {
		height: STANDARD_CARD_HEIGHT,
		aspectRatio: BUDDY_CARD_ASPECT_RATIO,
	},
	standard: {
		height: STANDARD_CARD_HEIGHT,
		aspectRatio: STANDARD_CARD_ASPECT_RATIO,
	},
	wide: {
		height: DESTINATION_HEIGHT,
		aspectRatio: DESTINATION_ASPECT_RATIO,
	},
	big: {
		height: BIG_CARD_HEIGHT,
		aspectRatio: BIG_CARD_ASPECT_RATIO,
	},
};

export default class Pattern {
	constructor() {
		this.reset();
	}

	get _nextCol() {
		let index = 0;
		let trackHeight = this.cols[0].height;

		for (let i = 1; i < this.cols.length; i++) {
			if (this.cols[i].height < trackHeight) {
				index = i;
				trackHeight = this.cols[i].height;
			}
		}

		return this.cols[index];
	}

	_getThumbnail(item) {
		const thumbnail = item?.clips[0]?.outputs;
		let background;
		if (thumbnail.hasOwnProperty('photo')) {
			background = thumbnail?.photo?.small?.webp;
		} else {
			background = thumbnail?.thumbnail?.small?.webp;
		}
		return background;
	}

	_getEntity(type, item) {
		const buddy = item.buddy ? this._getEntity(type, item.buddy) : undefined;

		if (type === 'destination') {
			return {
				uid: item.uid,
				buddy,
				avatar: item?.thumbnail?.medium?.jpg,
				background: destinationBackground,
				title: item.title,
				subtitle: item.subtitle,
				destinations: [item.uid],
				to: `/destination/${item.uid}`,
				type: 'destination',
			};
		}
		return {
			uid: item.uid,
			buddy,
			avatar: item?.user?.avatar?.medium?.jpg,
			background: this._getThumbnail(item),
			title: item.title,
			destination: item?.destinations[0],
			subtitle: item.destinationsV2[0]?.title,
			destinations: item.destinationsV2.map((d) => d.uid),
			type: 'experience',
		};
	}

	_shiftHelper(col, type, arr, template) {
		if (!arr.length) {
			return false;
		}

		const { aspectRatio, height } = TEMPLATE_TO_DIMENSIONS[template];

		// if it is a buddy template without enough items in the array, abort early - this will keep the experience in the array
		if (template === 'buddy' && arr.length < 2) {
			return false;
		}

		const item = { ...arr.shift() };

		if (template === 'buddy') {
			item.buddy = { ...arr.shift() };
		}

		const entity = this._getEntity(type, item);

		return {
			index: col.index - 1,
			aspectRatio,
			height,
			type,
			template,
			entity,
		};
	}

	getEmptyCols() {
		const arrs = [];

		for (let i = 0; i < this.cols.length; i++) {
			arrs.push([]);
		}

		return arrs;
	}

	shift(experiences, destinations) {
		const col = this._nextCol;

		const entity = this._getNextItem(col, experiences, destinations);

		if (!entity) {
			return false;
		}

		const {
			height,
			type,
		} = entity;

		col.lastType = type;
		col.height += height;

		col.itemCount++;
		if (type === 'buddy') {
			col.itemCount++;
		}

		return entity;
	}
}
