import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	iconButton: {
		position: 'absolute',
		bottom: theme.spacing(2),
		left: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		pointerEvents: 'all',
	},
}));
