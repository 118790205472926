export default (list = [], seenBefore = {}, key) => {
	const deduplicatedList = [];

	for (let i = 0; i < list.length; i++) {
		if (!seenBefore[list[i][key]]) {
			deduplicatedList.push(list[i]);
			seenBefore[list[i][key]] = true;
		}
	}

	return deduplicatedList;
};
