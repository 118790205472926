import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
	body1: {
		width: '100%',
		fontSize: '16px',
		fontWeight: 500,
		lineHeight: 1.4,
		letterSpacing: -0.08,

		'@media (max-width: 599px)': {
			fontSize: '14px',
		},
	},
});

export default ({ children, ...props }) => <Typography {...props} classes={useStyles()} variant="body1">{children}</Typography>;
