import React from 'react';
import { useDispatch } from 'react-redux';
import {
	Button,
	makeStyles,
	Typography,
} from '@material-ui/core';
import CircularProgress from 'rds/components/CircularProgress';
import IconV3 from 'rds/components/IconV3';
import { requestDeleteInProgressExperience, requestInProgressExperiences } from 'app/slices/user-account-v2/in-progress';
import uploadQueue from 'lib/upload-queue';
import notifyErrors from 'app/slices/notifications/notifications';
import ClipThumbnail from './ClipThumbnail';
import PublishingLabel from './PublishingLabel';

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: theme.spacing(4),
	},
	thumbnail: {
		marginRight: theme.spacing(2),
		width: 27,
		height: 48,
	},
	left: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: theme.spacing(2),
	},
	title: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		maxWidth: '65vw',
	},
}));

export default ({
	title,
	uid,
	failed,
	status,
	percentage,
	chunksRemaining,
	totalChunks,
	total,
	completed,
	thumbnail,
	showDetails,
	onItemClick,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<div>
			<div className={classes.item} onClick={onItemClick}>
				<div className={classes.left}>
					<div className={classes.thumbnail}>
						<ClipThumbnail src={thumbnail} experienceUid={uid} />
					</div>
					<div className={classes.details}>
						{title && <Typography className={classes.title} variant="body1">{title}</Typography>}
						<PublishingLabel status={failed ? 'failed' : status} percentage={percentage} />
					</div>
				</div>
				{failed || status === 'failed' ? (
					<IconV3
						icon="Cancel"
						onClick={(e) => {
							e.preventDefault();
							dispatch(requestDeleteInProgressExperience(uid));
						}}
					/>
				) : <CircularProgress size="36px" value={percentage} />}

			</div>
			{
				showDetails && (
					!chunksRemaining?.length ? (
						<div className={classes.item}>
							<Typography variant="subtitle2">
								{uid}
								{' '}
								Finished uploading
							</Typography>
						</div>
					)
						: (
							<div className={classes.item}>
								<div>
									<Typography variant="subtitle2">UID</Typography>
									{uid}
								</div>
								<div>
									<Typography variant="subtitle2">Chunks</Typography>
									{totalChunks - chunksRemaining?.length}
									{' '}
									/
									{' '}
									{totalChunks}
								</div>

								<div>
									<Typography variant="subtitle2">Bytes</Typography>
									{completed}
									{' '}
									/
									{' '}
									{total}
								</div>

								<Button
									variant="contained"
									onClick={async () => {
										try {
											await uploadQueue.deleteExperience(uid);
											await dispatch(requestInProgressExperiences());
										} catch (err) {
											notifyErrors([{ text: err.message }]);
										}
									}}
								>
									Delete
								</Button>
							</div>
						)
				)
			}
		</div>
	);
};
