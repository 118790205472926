import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import requestFormRequest from 'services/request-form-request';
import { injectReducer } from 'services/store';
import { getFormPayload, setErrors } from './form';

export const requestResetPassword = createAsyncThunk(
	'requestResetPassword',
	async ({ token }, options) => {
		const [response] = await requestFormRequest(options, {
			method: 'PUT',
			route: `/accounts/grant-password/reset/${token}`,
			getFormPayload,
			setErrors,
		});

		return response.data;
	},
);

export const resetPasswordSlice = createSlice({
	name: 'resetPassword',
	initialState: {
		succeed: false,
		errors: null,
		loading: false,
	},
	extraReducers: {
		[requestResetPassword.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestResetPassword.fulfilled]: (state) => {
			state.succeed = true;
			state.loading = false;
			state.errors = null;
		},

		[requestResetPassword.rejected]: (state, action) => {
			state.succeed = false;
			state.loading = false;
			state.errors = action.payload;
		},
	},

});

const { name, reducer } = resetPasswordSlice;
export { name };

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);

injectReducer(name, reducer);
