import {
	createSelector,
	createSlice,
	createAsyncThunk,
} from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api, { CancelToken } from 'services/api';
import store from 'store';
import handleSliceError from 'utils/handle-slice-error';

export const requestSession = createAsyncThunk(
	'requestSession',
	async (_, {
		signal, rejectWithValue,
	}) => {
		const source = CancelToken.source();

		signal.addEventListener('abort', () => {
			source.cancel();
		});

		try {
			const response = await api.get('/accounts/session?include=missingFields,onboarding,permissions,email,emailVerification', {
				cancelToken: source.token,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	}, {
		condition: (options, { getState }) => {
			if (options?.force) {
				return true;
			}

			if (getState().session?.session) {
				return false;
			}

			return true;
		},
	},
);

const initialState = {
	token: store.get('token'),
	session: null,
	loading: null,
	errors: null,
};

export const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		reset: () => {
			localStorage.clear();
			store.remove('token');
			return {
				...initialState,
				token: null,
			};
		},
		setToken: (state, action) => {
			const token = action.payload;
			state.token = token;
		},
		updateAvatar: (state, action) => {
			if (state?.session) {
				state.session.avatar = action.payload;
			}
		},
		updateOnboarding: (state, action) => {
			if (state?.session) {
				state.session.onboarding = action.payload;
			}
		},
		extend: (state, action) => {
			state.session = {
				...state.session,
				...action.payload,
			};
		},
		clearMissingFields: (state) => {
			if (state?.session) {
				state.session.missingFields = [];
			}
		},
		setSessionAvatar: (state, action) => {
			state.session.avatar = action.payload;
		},
		updateSession: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state.session[key] = action.payload[key];
			});
		},
		change: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
	},
	extraReducers: {
		[requestSession.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestSession.fulfilled]: (state, action) => {
			state.session = action.payload;
			state.loading = false;
			state.errors = null;
		},
		[requestSession.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer, actions } = sessionSlice;

const {
	clearMissingFields,
	reset,
	setToken,
	extend,
	updateAvatar,
	updateOnboarding,
	setSessionAvatar,
	updateSession,
	change,
} = actions;

export {
	clearMissingFields,
	reset,
	setToken,
	extend,
	updateAvatar,
	updateOnboarding,
	setSessionAvatar,
	updateSession,
	change,
};

export const startSession = ({ token, redirect }) => (
	dispatch,
) => {
	store.set('token', token);
	dispatch(setToken(token));
	return dispatch(requestSession(redirect));
};

export const endSession = () => (dispatch) => {
	// TODO: send the user back home
	// dispatch(push('/landing'));
	dispatch(reset());
};

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);
export const getSession = createSelector(getSlice, (slice) => slice?.session);
export const getSessionPermissions = createSelector(
	getSession,
	(session) => session?.permissions ?? {},
);
export const getMissingFields = createSelector(
	getSession,
	(session) => session?.missingFields ?? [],
);
export const getOnboarding = createSelector(
	getSession,
	(session) => {
		const onboarding = session?.onboarding ? { ...session.onboarding } : {};

		if (typeof onboarding.firstVisit === 'undefined') {
			onboarding.firstVisit = true;
		}

		return onboarding;
	},
);
export const getIsOnboardingCompleted = createSelector(
	getSession,
	(session) => {
		const onboarding = session?.onboarding;
		return (onboarding?.activities && onboarding?.destinations && onboarding?.regions);
	},
);
export const getToken = createSelector(getSlice, (slice) => slice?.token);
export const hasSession = createSelector(getSession, (session) => !!session);
export const getProp = (state, permissions) => permissions ?? [];
export const hasPermissions = createSelector(
	getSessionPermissions,
	getProp,
	(permissions, propPermissions) => {
		let access = true;

		propPermissions.forEach((permission) => {
			if (!permissions[permission]) {
				access = false;
			}
		});

		return access;
	},
);

export const hasVerifiedType = createSelector(
	getSession,
	(state, type) => type,
	(session, type) => {
		switch (type) {
		case 'email':
			return session?.emailIsVerified ?? false;
		case 'phone':
			return session?.phoneIsVerified ?? false;
		default:
			return false;
		}
	},
);

export const isSessionAuthorized = createSelector(getToken, (token) => !!token);
export const getSessionAvatar = createSelector(
	getSession,
	(session) => session?.avatarUrl ?? null,
);
export const getSessionUsername = createSelector(
	getSession,
	(session) => session?.username ?? null,
);
export const getSessionHasRole = createSelector(
	getSessionPermissions,
	getProp,
	(permissions, role) => {
		switch (role) {
		case 'admin':
			return !!permissions['edit-broadcasters'];
		case 'mentor':
			return !!permissions['manage-subscriptions'];
		default:
			return false;
		}
	},
);
export const getSessionUUID = createSelector(
	getSession,
	(session) => session?.uuid ?? null,
);

injectReducer(name, reducer);
