import { registerRoute } from 'Router/registry';
import exploreEcr from 'app/slices/search-explore/explore-ecr';
import { register } from 'features/ExperienceModal/ECRProvider';
import Skeleton from 'rds/components/Skeletons/SecondaryPage';
import { requestExploreContents } from 'app/slices/search-explore/explore';
import store from 'services/store';
import Explore from './Explore/Explore';
import Search from './Search/Search';
import ErrorPage from './Grid/ErrorPage';

register('explore', exploreEcr);

const exploreRequiredData = [
	({ dispatch }) => {
		const { items } = store.getState().searchExploreExplore;
		for (let i = 0; i < items.length; i++) {
			if (items[0].length) {
				return new Promise((resolve) => resolve());
			}
		}
		return dispatch(requestExploreContents());
	},
];

registerRoute({
	authorization: true,
	Component: Search,
	routeOptions: {
		path: '/search',
	},
	transition: 'slide',
	navigationMenu: '/explore',
});

registerRoute({
	authorization: true,
	Component: Explore,
	Skeleton,
	ecrSelectorName: 'explore',
	routeOptions: {
		path: '/explore',
	},
	errors: {
		Rejected: ErrorPage,
	},
	skipReloads: true,
	loadData: exploreRequiredData,
	transition: 'slide',
	navigationMenu: '/explore',
	root: true,
});
