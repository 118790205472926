import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { getDestinations, reset, getLoading } from 'app/slices/destinations-search';
import { changeDestinationLayout } from 'app/slices/destinations-search/layout';
import { resetForm } from 'app/slices/destinations-search/form';
import { change } from 'app/slices/content-creation/editor/layout';
import { useTheme } from '@material-ui/styles';
import WaveSkeleton from 'components/WaveSkeleton';
import { requestNotifyServerOnDestinationSelection } from 'app/slices/notify-server';
import CustomDestination from './CustomDestination';
import Result from './Result';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'left',
	},
	header: {
		paddingBottom: theme.spacing(4),
		paddingTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

export default ({
	onClick,
	isUserTyping,
	showSelected,
	disableCustomDestination,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const destinations = useSelector(getDestinations);
	const loading = useSelector(getLoading);
	const theme = useTheme();
	const handleSelect = () => {
		dispatch(reset());
		dispatch(resetForm());
		dispatch(changeDestinationLayout({ modal: false }));
		dispatch(change({ showControls: true }));
	};

	const style = {};
	if (showSelected) {
		style.marginTop = theme.spacing(50);
	} else {
		style.marginTop = theme.spacing(37.5);
	}

	return (
		<div className={classes.root} style={style}>
			<Typography className={classes.spacer} />
			{!loading
				&& destinations?.map((d) => (
					<Result
						key={d.uid}
						{...d}
						onMouseDown={async () => {
							const res = await dispatch(requestNotifyServerOnDestinationSelection(d.uid));
							onClick(res?.payload);
							handleSelect();
						}}
					/>
				))}
			{!loading && !isUserTyping && !disableCustomDestination && (
				<CustomDestination
					onSelect={(d, custom) => {
						onClick(d, custom);
						handleSelect();
					}}
				/>
			)}
			{loading
			&& (
				[...Array(5)].map((s, index) => (
					<WaveSkeleton style={{ height: 12, marginBottom: 8 }} key={index} />
				))
			)}
		</div>
	);
};
