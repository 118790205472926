export default (value) => {
	if (!value) {
		return null;
	}

	const { length } = value;
	if (length < 3 || length > 30) {
		return { field: 'username', text: 'Username must be between 3 and 30 characters' };
	}

	return null;
};
