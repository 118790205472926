import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import api from 'services/api';

export const requestSendReadState = createAsyncThunk(
	'requestSendReadState',
	async (_, { rejectWithValue, getState }) => {
		const { session } = getState().session;
		const { queue } = getState().appNotificationsReadState;

		if (!queue.length) {
			return [];
		}

		try {
			await api.patch(`notifications/${session.uuid}`, {
				read: queue,
			});
			return queue;
		} catch (error) {
			// no need to handle errors
			return rejectWithValue(getErrorsFromRejectedRequest(error));
		}
	},
);

const initialState = {
	queue: [],
	loading: false,
	errors: null,
};

const appNotificationsReadStateSlice = createSlice({
	name: 'appNotificationsReadState',
	initialState: { ...initialState },
	reducers: {
		addToQueue: (state, action) => {
			state.queue.push(action.payload);
		},
	},
	extraReducers: {
		[requestSendReadState.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestSendReadState.rejected]: (state, action) => {
			state.errors = action.payload;
		},
		[requestSendReadState.fulfilled]: (state, action) => {
			state.queue = state.queue.filter((i) => action.payload.indexOf(i) === -1);
			state.loading = false;
			state.errors = null;
		},
	},
});

const { name, reducer, actions } = appNotificationsReadStateSlice;
const { addToQueue } = actions;
export { name, addToQueue };

export const getSlice = (state) => state[name];
export const getQueue = createSelector(getSlice, (slice) => slice?.queue);
export default appNotificationsReadStateSlice;

injectReducer(name, reducer);
