import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestAllNotifications } from 'app/slices/app-notifications/notifications';
import { getNotifications } from 'app/slices/notifications';
import { broadcast } from 'features/webpush-notifications';
import { getSession } from 'app/slices/authentication/session/session';
import Notification from './Notification';

export default () => {
	const dispatch = useDispatch();
	const notifications = useSelector(getNotifications);
	const session = useSelector(getSession);

	let shouldEnableNotifications = false;
	if (session) {
		const { activities, destinations, regions } = session.onboarding;
		shouldEnableNotifications = (activities && destinations && regions);
	}

	useEffect(() => {
		navigator.setAppBadge && navigator.setAppBadge(0);

		broadcast.onmessage = () => {
			dispatch(requestAllNotifications());
		};

		return () => {
			broadcast.onmessage = null;
		};
	}, [shouldEnableNotifications]);

	return (
		<>
			{notifications.map((props) => <Notification key={props.id} {...props} />)}
		</>
	);
};
