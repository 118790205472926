import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import {
	getDestinationSubtitle,
	getDestinationTitle,
	getDestinationParent,
	getLoading,
} from 'app/slices/destination-account-v2/accounts/selectors';
import WaveSkeleton from 'components/WaveSkeleton';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: theme.spacing(3),
		paddingTop: theme.spacing(21),
		position: 'relative',
		zIndex: 1,
	},
	title: {
		overflow: 'hidden',
		wordBreak: 'break-word',
		textAlign: 'left',
		textOverflow: 'ellipsis',
		lineClamp: 2,
		display: 'box',
		boxOrient: 'vertical',
	},
	subtitle: {
		marginTop: theme.spacing(2),
		whiteSpace: 'normal',
		wordBreak: 'break-all',
		color: theme.colors.neutral.C200,
	},
	emptySubtitle: {
		height: theme.spacing(5),
		marginTop: theme.spacing(2),
	},
}));

export default () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { uid } = useParams();
	const title = useSelector(getDestinationTitle(uid));
	const subtitle = useSelector(getDestinationSubtitle(uid));
	const loading = useSelector(getLoading);
	const parentDestination = useSelector(getDestinationParent(uid));

	const handleClick = () => {
		if (parentDestination) {
			navigate(`/destination/${parentDestination?.uid}`);
		}
	};

	return (
		<div className={classes.root}>
			{loading ? (
				<WaveSkeleton
					style={{
						width: 108,
						height: 14,
						borderRadius: 4,
						marginBottom: 8,
					}}
				/>
			) : (
				<Typography className={classes.title} variant="h1">
					{title}
				</Typography>
			)}
			{loading ? (
				<WaveSkeleton
					style={{
						width: 108,
						height: 14,
						borderRadius: 4,
						marginBottom: 8,
					}}
				/>
			) : subtitle ? (
				<Typography className={classes.subtitle} variant="subtitle2" onClick={handleClick}>
					{subtitle}
				</Typography>
			) : (
				<div className={classes.emptySubtitle} />
			)}
		</div>
	);
};
