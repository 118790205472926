import _debounce from 'lodash.debounce';
import { UPDATE_CANVAS } from './event-types';

const TYPES = ['ideal', 'strict'];

const getNodeDetails = (nodes) => {
	let isVideo = false;
	let maxDuration = 0;

	for (let i = 0; i < nodes.length; i++) {
		if (nodes[i].type === 'video') {
			maxDuration = Math.max(nodes[i].el.duration, maxDuration);
			isVideo = true;
		}

		if (nodes[i].type === 'gif') {
			isVideo = true;
		}
	}

	return [maxDuration, isVideo];
};

/**
 *
autoCompile(editingCanvas, {
	time: {
		range: [0, 3],
		type: 'ideal',
	}
})
 * @param {object} editingCanvas the editing canvas to autocompile
 * @param {object} options
 * @param {number} options.debounce compile debounce time in ms [250ms]
 * @param {object} options.time
 * @param {array} options.time.range desired time range
 * @param {string} options.time.type ideal or strict
 * @return {function} turn off the autocompiler
 */
export default (editingCanvas, options) => {
	const debounce = options.debounce || 250;
	let range; let
		type;

	try {
		range = options.time.range;
		type = options.time.type;
	} catch (err) {
		throw new Error('Autocompile must be called as follows: autocompile(canvas, { time: { range: [0, 3], type: "ideal" } }); ');
	}

	const [start, end] = range;
	if (Number.isNaN(start) || Number.isNaN(end)) {
		throw new Error('Time range must be numbers {range: {0, 3}}');
	}

	if (TYPES.indexOf(type) === -1) {
		throw new Error(`Time type must be one of the following: ${TYPES.join(', ')}`);
	}

	if (Number.isNaN(debounce)) {
		throw new Error('Debounce must be a number {debounce: 250}');
	}

	const compile = _debounce(async () => {
		if (type === 'strict') {
			editingCanvas.compile(start, end);
			return;
		}

		const [maxDuration, isVideo] = getNodeDetails(editingCanvas.canvas.nodes);

		if (isVideo) {
			await editingCanvas.compilePreview();
		}

		if (!maxDuration || maxDuration > end) {
			editingCanvas.compile(start, end);
			return;
		}

		editingCanvas.compile(start, maxDuration);
	}, 250);

	editingCanvas.on(UPDATE_CANVAS, compile);

	return () => {
		editingCanvas.removeListener(UPDATE_CANVAS, compile);
	};
};
