import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectClips } from 'app/slices/content-creation/experience';
import { change } from 'app/slices/content-creation/editor/layout';
import { GIF, IMAGE } from 'lib/editing-canvas/src/types';

// const useStyles = makeStyles((theme) => ({
//	 tag: {
//		 background: theme.colors.neutral.C700,
//		 borderRadius: 2,
//		 padding: theme.spacing(0.5),
//	 },
// }));

const ImageSticker = ({
	onClick,
	src,
	...props
}) => {
	const ref = useRef();

	useEffect(async () => {
		const response = await fetch(src);
		const blob = await response.blob();
		const image = new Image();

		image.onload = () => {
			if (ref?.current) {
				ref.current.innerHTML = '';
				image.style.objectFit = 'cover';
				image.style.width = '100%';
				image.style.height = '100%';
				ref.current.appendChild(image);
				ref.current.blob = blob;
			}
		};
		image.src = URL.createObjectURL(blob);

		return () => {
			if (ref?.current) {
				ref.current.innerHTML = '';
			}
		};
	}, [ref]);

	return (
		<div
			ref={ref}
			onClick={async () => {
				if (!ref?.current?.blob) {
					return;
				}

				const ab = await ref.current.blob.arrayBuffer();
				onClick(ab, URL.createObjectURL(ref.current.blob));
			}}
			{...props}
		/>
	);
};

const EachSticker = ({ type, entity, params }) => {
	const dispatch = useDispatch();
	const clips = useSelector(selectClips);
	const clipIndex = clips.findIndex((c) => parseInt(c.id, 10) === parseInt(params.id, 10));
	const clip = clips[clipIndex];

	let imageSrc;
	if (type === 'emoji') {
		imageSrc = entity.urls.png;
	} else if (type === 'giphy') {
		imageSrc = entity.url;
	}

	const addGifi = async (arrayBuffer, src) => {
		await clip.editingCanvas.add(GIF, {
			arrayBuffer,
			src,
			attributes: {
				nodeType: GIF,
			},
		}, {
			top: `${(window.innerHeight / 2) - 50}px`,
			left: `${(window.innerWidth / 2) - 50}px`,
			width: '100px',
			height: '100px',
			objectFit: 'contain',
		});
		clip.editingCanvas.compilePreview();
	};

	const addEmoji = async (arrayBuffer, src) => {
		await clip.editingCanvas.add(IMAGE, {
			arrayBuffer,
			src,
			attributes: {
				nodeType: IMAGE,
			},
		}, {
			top: `${(window.innerHeight / 2) - 50}px`,
			left: `${(window.innerWidth / 2) - 50}px`,
			width: '100px',
			height: '100px',
			objectFit: 'contain',
		});
		clip.editingCanvas.compilePreview();
	};

	// const addMentionTag = async () => {
	//	 const node = await clip.editingCanvas.add('html', {
	//		 tagName: 'div',
	//		 attributes: {
	//			 contenteditable: 'true',
	//			 autocomplete: 'off',
	//			 placeholder: 'mention',
	//			 nodeType: 'action',
	//			 class: 'mentionTag',
	//			 backgroundColorId: 0,
	//		 },
	//		 ignoreStyles: ['width'],
	//	 }, {
	//		 ...COMMON_STYLES,
	//		 top: 'calc(50% - 60px)',
	//		 background: neutral.C900,
	//		 paddingLeft: '8px',
	//		 paddingRight: '8px',
	//	 });
	//	 const el = await clip.editingCanvas.waitForEl(node.item.id);
	//	 el.focus();
	//	 dispatch(
	//		 setMentionTag({
	//			 el,
	//			 id: node.item.id,
	//		 }),
	//	 );
	//	 dispatch(change({ showControls: false }));
	// };

	// if (type === 'action') {
	//	 return (
	//		 <Typography
	//			 className={classes.tag}
	//			 onClick={() => {
	//				 addMentionTag();
	//				 dispatch(change({ plugins: false }));
	//				 // dispatch add and close modal
	//			 }}
	//		 >
	//			 {entity?.title?.toUpperCase()}
	//		 </Typography>
	//	 );
	// }

	if (type === 'emoji') {
		return (
			<ImageSticker
				src={imageSrc}
				onClick={(ab, src) => {
					addEmoji(ab, src);
					dispatch(change({ plugins: false }));
				}}
			/>
		);
	}

	return (
		<ImageSticker
			src={imageSrc}
			onClick={(ab, src) => {
				addGifi(ab, src);
				dispatch(change({ plugins: false }));
			}}
		/>
	);
};

export default EachSticker;
