import { createFormSlice } from 'services/slice';
import passwordValidator from 'validators/password';

export const resetPasswordFormSlice = createFormSlice({
	name: 'resetPasswordFormSlice',
	initialState: {
		password: '',
	},
	validators: {
		password: passwordValidator,
	},
});

const {
	// slice
	control, reducer,
	// actions
	resetErrors, resetForm, setErrors, setFormField,
	// selectors
	getFormField, getFormErrors, getFormFieldError, getFormPayload,
} = resetPasswordFormSlice;

export {
	control, reducer,
	resetErrors, resetForm, setErrors, setFormField,
	getFormField, getFormErrors, getFormFieldError, getFormPayload,
};

export default reducer;
