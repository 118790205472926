import { notify } from 'app/slices/notifications';

export const MAX_CLIPS = {
	title: 'Max 10 clips reached',
	description: 'Delete a clip or press “Next” to continue.',
};

export default (dispatch, title, description, disableClose) => {
	dispatch(notify({
		severity: 'error',
		title,
		description: `${description}`,
		autoHideDuration: 2000,
		disableClose,
	}));
};
