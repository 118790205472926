import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { getSections } from 'app/slices/onboarding/regions';
import Category from './Category';

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginTop: theme.spacing(7),
	},
	name: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		height: 34,
	},
	category: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(-4),
	},
}));

export default ({
	region,
}) => {
	const classes = useStyles();
	const sections = useSelector(getSections(region));
	return (
		<div className={classes.root}>
			<div className={classes.name}>
				{ sections.map((s) => (
					<div key={s.name} style={{ textAlign: 'center', width: '100%' }}>
						<Typography variant="body1">
							{s.name}
						</Typography>
					</div>
				)) }
			</div>
			<div className={classes.category}>
				{ sections.map((s) => (
					<Category key={s.name} {...s} />
				))}
			</div>

		</div>
	);
};
