import getWorker from './get-worker';

const trimVideo = ({
	name,
	data,
	log,
	start,
	stop,
	type,
	worker,
}) => new Promise((resolve, reject) => {
	const args = [];

	args.push(
		'-ss', `${start}s`,
		'-i', name,
		'-c', 'copy',
		'-map', '0',
		'-map', '-0:d',
		'-t', `${stop}s`,
		`out.${type}`,
	);

	worker.onmessage = (e) => {
		if (e.data.type === 'done') {
			worker.onmessage = null;

			const output = e.data.data.MEMFS[0];
			if (!output) {
				reject(new Error('no video output'));
				return;
			}

			resolve(output);
		} else if ((e.data.type === 'stderr' || e.data.type === 'stdout') && log) {
			// console.log(e.data.data);
		}
	};

	worker.postMessage({
		type: 'run',
		MEMFS: [{ name, data }],
		arguments: args,
	});
});

export default async ({
	name,
	data,
	start,
	stop,
	type,
	log = true,
}) => {
	const path = type === 'mp4'
		? '/js/ffmpeg-worker-mp4.js'
		: '/js/ffmpeg-worker-webm.js';

	let worker = await getWorker(path);
	const o = await trimVideo({
		name,
		data,
		start,
		stop,
		type,
		worker,
		log,
	});

	worker.terminate();
	worker = null;

	return o;
};
