import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, makeStyles, useTheme } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import Avatar from 'rds/components/Avatar';
import { getIsMe } from 'app/slices/user-account-v2/accounts';
import { requestFollowUnFollowDestinationAccount } from 'app/slices/user-account-v2/following-actions';

const useStyles = makeStyles((theme) => ({
	subtitle: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		width: 120,
		textOverflow: 'ellipsis',
		color: theme.colors.neutral.C400,
	},
	info: {
		display: 'flex',
		flexDirection: 'row',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: theme.spacing(3),
		justifyContent: 'space-evenly',
		textAlign: 'left',
	},
	actions: {
		width: 86,
	},
	button: {
		height: 36,
		width: 86,
		backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
			color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		},
	},
}));

export default ({
	title,
	subtitle,
	thumbnail,
	uid,
	interactions,
	onClose,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { username } = useParams();
	const isMe = useSelector((state) => getIsMe(state, username)) || false;

	const FollowingStyle = {
		background: 'none',
		color: theme.colors.neutral.C000,
		border: `1px solid ${theme.colors.neutral.C000}`,
	};
	return (
		<>
			<div
				className={classes.info}
				onClick={() => {
					navigate(`/destination/${uid}`);
					onClose();
				}}
			>
				<Avatar size="small" alt={title} src={thumbnail?.small?.jpg} />
				<div className={classes.details}>
					<Typography variant="body1">{title}</Typography>
					<Typography className={classes.subtitle} variant="caption">{subtitle}</Typography>
				</div>
			</div>

			<div className={classes.actions}>
				<Button
					variant="contained"
					disableRipple
					fullWidth
					style={interactions?.callerIsFollowing ? FollowingStyle : null}
					disableFocusRipple
					size="small"
					className={classes.button}
					onClick={() => {
						if (interactions?.callerIsFollowing) {
							dispatch(requestFollowUnFollowDestinationAccount({ uid, type: 'unfollow', isMe }));
						} else if (!interactions?.callerIsFollowing) {
							dispatch(requestFollowUnFollowDestinationAccount({ uid, type: 'follow', isMe }));
						}
					}}
				>
					{interactions?.callerIsFollowing ? 'Following' : 'Follow' }
				</Button>
			</div>
		</>
	);
};
