import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getActivities, requestActivities, requestPatchActivity } from 'app/slices/onboarding/activities';
import Rows from 'rds/components/Hexagon/Honeycomb/Rows';
import assets from 'routes/Onboarding/assets/activities';

const ROW_SIZE = 5;

const useStyles = makeStyles((theme) => ({
	root: {
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		marginTop: theme.spacing(10),
		paddingTop: theme.spacing(2),
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},

}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const activities = useSelector(getActivities);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		dispatch(requestActivities());
	}, []);

	useEffect(() => {
		if (!activities) {
			return;
		}
		const newRows = [];
		for (let i = 0; i < activities.length; i += ROW_SIZE) {
			newRows.push(activities.slice(i, i + ROW_SIZE));
		}
		setRows(newRows);
	}, [activities]);

	const onClick = (uid) => {
		const activity = activities && activities.find((act) => act.uid === uid);
		dispatch(requestPatchActivity({ uid, remove: !!activity.selected }));
	};

	return (
		<div className={classes.root}>
			<Rows
				rows={rows}
				onClick={onClick}
				images={assets}
			/>
		</div>
	);
};
