import React, { useRef } from 'react';
import {
	Standard,
	Wide,
	Big,
	Buddy,
} from '../Templates';

const TEMPLATES = {
	standard: Standard,
	wide: Wide,
	big: Big,
	buddy: Buddy,
};

const getAspectRatioStyle = (aspectRatio) => ({ paddingBottom: `${aspectRatio * 100}%` });

export default ({
	aspectRatio,
	classes,
	template,
	entity,
}) => {
	const ref = useRef();
	const Component = TEMPLATES[template];

	return (
		<div ref={ref} className={classes.item}>
			<div
				className={classes.entity}
				style={getAspectRatioStyle(aspectRatio)}
			>
				<div className={classes.entityContainer}>
					<Component {...entity} />
				</div>
			</div>
		</div>
	);
};
