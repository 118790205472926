import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Filters from 'rds/components/Filters';
import StickyContainer from 'rds/components/StickyContainer/StickyContainer';
import FiltersModal from 'rds/components/Filters/FiltersModal';
import {
	getFilters,
	getOpeningFilterState,
	getFilterHasBeenChanged,
	getAllExperiencesByDestination,
	getErrors,
} from 'app/slices/destination-account-v2/experiences/selectors';
import {
	addFilters,
	removeFilters,
	resetToPreviousState,
	setOpenModalState,
} from 'app/slices/destination-account-v2/experiences/slice';
import { requestAllExperiencesByDestination } from 'app/slices/destination-account-v2/experiences/thunks';
import isObjEmpty from 'utils/is-obj-empty';
import { useParams } from 'react-router-dom';

export default () => {
	const { uid } = useParams();
	const dispatch = useDispatch();
	const [modalOptions, setModalOptions] = useState(null);
	const filters = useSelector(getFilters(uid));
	const openingFilterState = useSelector(getOpeningFilterState);
	const filterHasBeenChanged = useSelector(getFilterHasBeenChanged);
	const isFiltersEmpty = isObjEmpty(filters);
	const experiences = useSelector(getAllExperiencesByDestination);
	const error = useSelector(getErrors);

	const handleSelect = (filter) => {
		if (!filters[modalOptions?.title].some((f) => f.slug === filter.slug)) {
			dispatch(addFilters({ group: modalOptions?.title, filter, uid }));
		} else {
			dispatch(removeFilters({ group: modalOptions?.title, filter, uid }));
		}
	};

	if (isFiltersEmpty && !experiences.length && !filterHasBeenChanged && !error) {
		return null;
	}

	return (
		<StickyContainer isOpen={!!modalOptions}>
			<Filters
				modalOptions={modalOptions}
				setModalOptions={setModalOptions}
				filters={filters}
				onOpen={setOpenModalState}
				onReset={() => dispatch(resetToPreviousState(uid))}
			/>
			<FiltersModal
				show={!!modalOptions}
				title={modalOptions?.title}
				options={modalOptions?.options}
				filters={filters}
				openingFilterState={openingFilterState}
				filterHasBeenChanged={filterHasBeenChanged}
				onClose={() => {
					setModalOptions(null);
				}}
				onSubmit={() => {
					dispatch(requestAllExperiencesByDestination(uid));
				}}
				handleSelect={handleSelect}
				onReset={() => dispatch(resetToPreviousState(uid))}
			/>
		</StickyContainer>
	);
};
