import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ProgressiveThumbnail from 'rds/components/ProgressiveThumbnail';
import useClick from './useClick';
import ExperienceInfo from './ExperienceInfo';

const useStyles = makeStyles((theme) => ({
	expansionContainer: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
	},
	root: {
		position: 'absolute',
		bottom: theme.spacing(),
		left: 0,
		right: 0,
		zIndex: 2,
	},
}));

export default ({
	avatar,
	background,
	destination,
	title,
	uid,
	type,
}) => {
	const classes = useStyles();
	const [loaded, setLoaded] = useState(false);
	const onClick = useClick(uid, background, type);

	return (
		<div className={classes.expansionContainer} data-experience-modal-id={uid} onClick={onClick}>
			<ProgressiveThumbnail
				absolute
				src={background}
				animation="wave"
				gradient
				onLoad={() => { setLoaded(true); }}
			/>
			<div className={classes.root}>
				<ExperienceInfo
					avatar={avatar}
					avatarSize="xxxSmall"
					title={title}
					titleVariant="subtitle2"
					destination={destination}
					destinationVariant="body2"
					rootStyle={{ alignItems: 'start' }}
					skeletonWidth={24}
					skeletonHeight={24}
					pattern="standard"
					loaded={loaded}
				/>
			</div>
		</div>
	);
};
