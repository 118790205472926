import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chip from 'rds/components/Chip';
import { makeStyles } from '@material-ui/core';
import {
	selectExperienceDestination,
	setExperienceDestination,
} from 'app/slices/content-creation/experience';
import { changeDestinationLayout } from 'app/slices/destinations-search/layout';
import { useTheme } from '@material-ui/styles';
import IconV3 from 'rds/components/IconV3/IconV3';
import Avatar from 'rds/components/Avatar';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		display: 'flex',
		justifyContent: 'center',
	},
}));

const DEFAULT_DESTINATION_IMAGE = 'https://cdn.dev.roamrhino.com/images/d/default-destination_128w.jpg';

const DestinationComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const destination = useSelector(selectExperienceDestination);
	const theme = useTheme();

	const handleDestinationClick = () => {
		if (!destination) {
			dispatch(changeDestinationLayout({ modal: true }));
		}
	};

	const handleIconClick = () => {
		dispatch(destination?.title.length ? setExperienceDestination(null) : changeDestinationLayout({ modal: true }));
	};

	return (
		<div className={classes.root} data-ignoreslider="true" onClick={handleDestinationClick}>
			<Avatar src={destination?.thumbnail?.small?.jpg || DEFAULT_DESTINATION_IMAGE} size="xxxSmall" alt="destinationAccount" />
			<Chip
				data-ignoreslider="true"
				contents={destination ? destination?.title : 'Add a destination'}
				style={{
					background: theme.colors.neutral.C900,
					marginLeft: theme.spacing(2),
					border: 'none',
				}}
				labelStyle={{
					paddingRight: theme.spacing(),
				}}
				actionicon={(
					<IconV3
						icon={destination?.title.length ? 'Cancel' : 'Plus'}
						size={20}
						containerSize={32}
						onClick={handleIconClick}
					/>
				)}
				noBorder
			/>
		</div>
	);
};

export default DestinationComponent;
