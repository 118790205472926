import React, { useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import {
	getTodayNotifications,
	getRecentNotifications,
	getPreviousNotifications,
	getAllNotifications,
	getNextLinkOfNotifications,
	requestLoadMoreNotifications,
	getLoading,
} from 'app/slices/app-notifications/notifications';
import { prepareExperiences } from 'app/slices/experience-modal';
import { useQueryParam } from 'app/hooks/useQueryParams';
import EachNotification from './EachNotification';

const useStyles = makeStyles((theme) => ({
	emptyContent: {
		position: 'absolute',
		top: 'calc(50% - 60px)',
		marginRight: theme.spacing(9),
		marginLeft: theme.spacing(9),
		textAlign: 'center',
		color: '#ffffff',
	},
	list: {
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginBottom: theme.spacing(10.5),
	},
	subtitle: {
		marginBottom: theme.spacing(2.5),
	},
	end: {
		marginTop: -theme.spacing(10),
	},
}));

export default ({ shouldRender, hide, Skeleton }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const endRef = useRef();
	const allNotifications = useSelector(getAllNotifications);
	const todaysNotifications = useSelector(getTodayNotifications);
	const recentNotifications = useSelector(getRecentNotifications);
	const previousNotifications = useSelector(getPreviousNotifications);
	const nextLink = useSelector(getNextLinkOfNotifications);
	const loading = useSelector(getLoading);
	const uid = useQueryParam('experience');
	const comment = useQueryParam('comment');

	useEffect(() => {
		if (uid && shouldRender) {
			dispatch(prepareExperiences({
				selector: 'solo',
				show: true,
				uid,
				comment,
			}));
		}
	}, [shouldRender, uid]);

	const observer = useMemo(
		() => new IntersectionObserver(([intersection]) => {
			if (!intersection.isIntersecting || !nextLink || loading) {
				return;
			}

			dispatch(requestLoadMoreNotifications());
		}),
		[nextLink, loading],
	);

	useEffect(() => {
		observer.observe(endRef.current);
		return () => observer.disconnect();
	}, [endRef, observer]);

	const notificationTypes = [
		'follow',
		'experience-comment-mention',
		'experience-comment-by-followed',
		'experience-comment',
		'experience-mention',
		'experience-mention-by-followed',
		'experience-comment-mention-by-followed',
	];

	const getFilteredNotifications = (notifications) => notifications.filter((n) => notificationTypes.includes(n.type));

	const todaysFilteredNotifications = getFilteredNotifications(todaysNotifications || []);
	const recentFilteredNotifications = getFilteredNotifications(recentNotifications || []);
	const previousFilteredNotifications = getFilteredNotifications(previousNotifications || []);
	const allFilteredNotifications = getFilteredNotifications(allNotifications || []);

	return (
		<>
			{ hide && <Skeleton /> }
			<div style={{ display: hide ? 'none' : 'block' }}>
				{!!todaysFilteredNotifications.length
					&& (
						<div className={classes.list}>
							<Typography className={classes.subtitle} variant="subtitle1">Today</Typography>
							{todaysNotifications && todaysNotifications.map((n, i) => (
								<EachNotification key={i} notification={n} />
							)) }
						</div>
					)}
				{!!recentFilteredNotifications.length
					&& (
						<div className={classes.list}>
							<Typography className={classes.subtitle} variant="subtitle1">Recent</Typography>
							{recentNotifications && recentNotifications.map((n, i) => (
								<EachNotification key={i} notification={n} />
							)) }
						</div>
					)}
				{!!previousFilteredNotifications.length
					&& (
						<div className={classes.list}>
							<Typography className={classes.subtitle} variant="subtitle1">Previous</Typography>
							{previousNotifications && previousNotifications.map((n, i) => (
								<EachNotification key={i} notification={n} />
							)) }
						</div>
					)}
				{!allFilteredNotifications.length && !loading
					&& (
						<Typography variant="h5" className={classes.emptyContent}>
							You have no new notifications
						</Typography>
					)}
				<div className={classes.end} ref={endRef} />
			</div>
		</>
	);
};
