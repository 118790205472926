import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	captureContainer: {
		position: 'fixed',
		zIndex: 10,
		bottom: 80,
		height: 72,
		width: 72,
		left: '50%',
		transform: 'translate(-50%, 0)',
	},
	captureButton: {
		position: 'absolute',
		backgroundColor: '#fff',
		transition: 'all 0.25s',
		borderRadius: '100%',
		height: 60,
		width: 60,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	pressed: {
		backgroundColor: theme.colors.neutral.C200,
	},
	outerRing: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		borderRadius: '100%',
		borderWidth: 4,
		borderStyle: 'solid',
		borderColor: '#ffffff40',
	},
}));

export default ({
	onSelect,
}) => {
	const classes = useStyles();
	const [pressed, setPressed] = useState(false);

	const klass = pressed ? clsx(classes.captureButton, classes.pressed) : classes.captureButton;

	return (
		<div
			className={classes.captureContainer}
			onTouchStart={() => {
				setPressed(true);
			}}
			onTouchEnd={() => {
				onSelect();
			}}
		>
			<div className={klass} />
			<div className={classes.outerRing} />
		</div>
	);
};
