import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Avatar from 'rds/components/Avatar';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		paddingTop: theme.spacing(4.5),
		paddingBottom: theme.spacing(4.5),
		alignItems: 'center',
	},
	avatarContainer: {
		marginRight: theme.spacing(2),
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'left',
		textAlign: 'left',
	},
	name: {
		color: theme.colors.neutral.C200,
	},

}));

export default ({
	user,
}) => {
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<div
			className={classes.root}
			onClick={() => { navigate(`/${user.username}`); }}
		>
			<div className={classes.avatarContainer}>
				<Avatar
					alt="user"
					size="large"
					src={user.avatar.medium.jpg}
				/>
			</div>
			<div className={classes.info}>
				<Typography variant="subtitle2">{user.displayName}</Typography>
				<Typography className={classes.name} variant="caption">{user.username}</Typography>
			</div>
		</div>
	);
};
