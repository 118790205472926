import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'fixed',
		flexDirection: 'column',
		justifyContent: 'center',
		top: '30%',
		width: '100%',
		textAlign: 'center',
	},
	subtitle: {
		marginRight: theme.spacing(8),
		marginLeft: theme.spacing(8),
	},
	oops: {
		marginRight: theme.spacing(8),
		marginLeft: theme.spacing(8),
		marginBottom: theme.spacing(2),
	},
	refresh: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(12.5),
	},
	retry: {
		marginLeft: theme.spacing(2),
		color: theme.colors.primary.C400,
	},
	homeButton: {
		marginTop: theme.spacing(12.5),
		color: theme.colors.primary.C400,
	},
	error: {
		color: theme.colors.neutral.C500,
		marginTop: theme.spacing(18),
		marginRight: theme.spacing(12),
		marginLeft: theme.spacing(12),
	},

}));

export default ({ errors }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.oops} variant="h2">Oops</Typography>
			<Typography className={classes.subtitle} variant="h6">We’re having trouble loading this page right now.</Typography>
			<div className={classes.refresh} onClick={() => { window.location.reload(); }}>
				<IconV3
					icon="Refresh"
					size={20}
					containerSize={20}
				/>
				<Typography className={classes.retry} variant="subtitle1">Retry</Typography>
			</div>
			{errors.map((error) => <Typography className={classes.error} variant="subtitle2">{error.text}</Typography>)}
		</div>
	);
};
