import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUsername } from 'app/slices/user-account-v2/accounts/selectors';
import UserAccountHeader from './Header';

export const MySubHeader = () => <UserAccountHeader back isMe fixPosition={false} />;

export const TheirSubHeader = () => {
	const username = useSelector(getCurrentUsername);

	if (!username) {
		return null;
	}

	return <UserAccountHeader back isMe={false} fixPosition={false} />;
};
