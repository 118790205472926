import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import clsx from 'clsx';
import { getThumbnailPath } from 'app/android-service';
import { select, getSelected, getTotalClipCount } from 'app/slices/content-creation/create/device-library/media';
import { CLIPS_THRESHOLD, DURATION_THRESHOLD } from 'app/slices/content-creation/experience';
import { notify } from 'app/slices/notifications/notifications';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
	emptyBadge: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		width: 36,
		height: 36,
		borderRadius: '50%',
		color: theme.colors.neutral.C000,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: `2px solid ${theme.colors.neutral.C000}`,
		opacity: 0.5,
	},
	videoDuration: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(2),
	},
	filledBadge: {
		background: theme.colors.primary.C500,
		opacity: 1,
	},
	label: {
		textAlign: 'left',
		marginTop: theme.spacing(2),
	},
	thumbnailContainer: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		marginTop: theme.spacing(0.5),
	},
	thumbnail: {
		height: '100%',
		width: '100%',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		marginTop: theme.spacing(0.5),
		objectFit: 'cover',
	},
	number: {
		userSelect: 'none',
	},
}));

const Badge = ({
	iof,
}) => {
	const classes = useStyles();

	return (
		<div className={iof === -1 ? clsx(classes.emptyBadge) : clsx(classes.emptyBadge, classes.filledBadge)}>
			<Typography className={classes.number} variant="subtitle2">
				{iof === -1 ? '' : iof + 1}
			</Typography>
		</div>
	);
};

export default ({
	media,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const selected = useSelector(getSelected);
	const totalClipCount = useSelector(getTotalClipCount);
	const index = selected.findIndex(({ fileUri }) => fileUri === media.fileUri);
	const mediaDuration = dayjs(Number(media.duration)).format('mm:ss');

	const onClick = useCallback(() => {
		if (index !== -1) {
			dispatch(select(media));
			return;
		}

		if (totalClipCount >= CLIPS_THRESHOLD) {
			dispatch(notify({
				severity: 'info',
				description: 'Max clips reached.',
			}));
			return;
		}

		if (media.mediaType === 3) {
			const duration = parseInt(media.duration, 10);
			if (!Number.isNaN(duration)) {
				const incomingClipCount = Math.ceil(duration / DURATION_THRESHOLD);
				if (incomingClipCount + totalClipCount > CLIPS_THRESHOLD) {
					dispatch(notify({
						severity: 'info',
						title: 'Full video not included',
						description: 'Max clips reached.',
					}));
				}
			}
		}
		dispatch(select(media));
	}, [totalClipCount, media, index]);

	return (
		<div
			key={media.fileUri}
			className={
				classes.thumbnailContainer
			}
			onClick={onClick}
		>
			<div
				className={classes.thumbnail}
				style={{
					backgroundImage: `url(${getThumbnailPath(media.fileUri)})`,
				}}
			/>
			<Badge iof={index} />
			{media.mediaType === 3 && <Typography variant="body2" className={classes.videoDuration}>{mediaDuration}</Typography>}
		</div>
	);
};
