const createFontStyleTag = (f) => {
	const style = document.createElement('style');
	style.setAttribute('type', 'text/css');
	style.innerHTML = `
    @font-face {
      font-family: ${f.family};
      src: url("data:application/x-font-ttf;base64,${f.encoded}") format('truetype');
    }
  `;

	return style;
};

const getShadowContainer = (el) => {
	const container = el.parentElement.cloneNode(true);
	container.style.visibility = 'hidden';
	document.body.appendChild(container);
	return container;
};

const svgToDataURL = async (svg) => {
	const str = new XMLSerializer().serializeToString(svg);
	const html = encodeURIComponent(str);
	return `data:image/svg+xml;charset=utf-8,${html}`;
};

const htmlToSvg = async (el, { fonts = [], width, height }) => {
	const clone = el.cloneNode(true);
	const body = document.createElement('body');
	const xmlns = 'http://www.w3.org/2000/svg';
	const svg = document.createElementNS(xmlns, 'svg');
	const foreignObject = document.createElementNS(xmlns, 'foreignObject');

	svg.setAttribute('width', `${width}`);
	svg.setAttribute('height', `${height}`);
	svg.setAttribute('viewBox', `0 0 ${width} ${height}`);

	foreignObject.setAttribute('width', '100%');
	foreignObject.setAttribute('height', '100%');
	foreignObject.setAttribute('x', '0');
	foreignObject.setAttribute('y', '0');
	foreignObject.setAttribute('externalResourcesRequired', 'true');

	for (let i = 0; i < fonts.length; i++) {
		foreignObject.appendChild(createFontStyleTag(fonts[i]));
	}

	const style = document.createElement('style');
	style.setAttribute('type', 'text/css');
	style.innerHTML = `
    body {
      margin: 0;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .ec_ma_text_writable_div {
      line-height: 1.2;
      font-size: 18pt;
      font-weight: 500;
    }

    .cc-mention {
      display: inline-block;
      position: relative;
    }

    .cc-mentions__underline {
      position: absolute;
      height: 2px;
      background-color: #fff;
      left: 32px;
      right: 0;
      bottom: 0px;
    }
  `;
	foreignObject.appendChild(style);

	document.body.querySelectorAll('style').forEach((s) => {
		foreignObject.appendChild(s.cloneNode(true));
	});

	svg.appendChild(foreignObject);
	body.appendChild(clone);
	foreignObject.appendChild(body);

	return svgToDataURL(svg);
};

const svgToPng = (svg, { width, height, secondTimeAround }) => new Promise((resolve, reject) => {
	const image = new Image();
	image.decoding = 'sync';
	image.width = Math.ceil(width);
	image.height = Math.ceil(height);
	image.onerror = reject;
	image.onload = () => {
		if (!secondTimeAround) {
			resolve(svgToPng(svg, { width, height, secondTimeAround: true }));
			return;
		}

		resolve(image);

		defer(1).then(() => {
			try {
				document.body.removeChild(image);
			} catch (_) {
				// safe to ignore
			}
		});
	};
	image.style.zIndex = -1;
	image.style.position = 'fixed';
	image.style.visibility = 'hidden';
	document.body.appendChild(image);
	image.src = svg;
});

const convertHtmlToPng = async (el, { fonts }) => {
	const clone = el.cloneNode(true);
	const shadowContainer = getShadowContainer(el);
	// clone.style.background = 'red';
	shadowContainer.appendChild(clone);

	if (clone.clientWidth) {
		clone.style.width = `${clone.clientWidth + 4}px`;
	}

	clone.setAttribute('content-editable', false);
	// clone.style.removeProperty('position');
	clone.style.removeProperty('left');
	clone.style.removeProperty('top');
	const dimensions = clone.getBoundingClientRect();
	const svg = await htmlToSvg(clone, {
		width: dimensions.width,
		height: dimensions.height,
		fonts,
	});

	const image = await svgToPng(svg, dimensions);
	clone.remove();
	shadowContainer.remove();

	return image;
};

export {
	createFontStyleTag,
	convertHtmlToPng,
	svgToPng,
	svgToDataURL,
};

const defer = (ms) => new Promise((resolve) => {
	setTimeout(resolve, ms);
});
