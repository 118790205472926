import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { cancel } from 'app/slices/content-creation/create/device-library/media';
import { clearPercentage, getDisableCancel, getPercentage } from 'app/slices/content-creation/create/device-library/state';
import FixedTopControls from '../../../Components/Layout/FixedTopControls';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		height: 145,
		background: theme.colors.neutral.C800,
		zIndex: 10,
	},
	darkLayout: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: theme.colors.neutral.C900,
		opacity: 0.6,
		zIndex: 1,
	},
	loading: {
		textAlign: 'center',
		color: theme.colors.neutral.C300,
		marginTop: theme.spacing(3.5),
	},
	linearProgress: {
		marginTop: theme.spacing(7.5),
		marginRight: theme.spacing(3),
		marginLeft: theme.spacing(3),
		height: 8,
		borderRadius: 8,
	},
	bar1Determinate: {
		transition: 'transform 0.5s linear',
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const disableCancel = useSelector(getDisableCancel);
	const percentage = useSelector(getPercentage);

	return (
		<>
			<div className={classes.root}>
				<FixedTopControls
					buttons={[{
						text: 'Cancel',
						ds: true,
						disabled: disableCancel,
						onClick: () => {
							dispatch(cancel());
							dispatch(clearPercentage(0));
						},
					}]}
				/>
				<Typography variant="body1" className={classes.loading}>Loading media.... </Typography>
				<LinearProgress
					classes={{
						bar1Determinate: classes.bar1Determinate,
					}}
					className={classes.linearProgress}
					variant="determinate"
					value={percentage}
				/>
			</div>
			<div className={classes.darkLayout} />
		</>
	);
};
