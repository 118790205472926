import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';
import { getFormPayload } from './search-stickers-form';

const editingMentionMap = {};

export const requestSearchStickers = createAsyncThunk(
	'requestSearchStickers',
	async (_, { getState, rejectWithValue }) => {
		const pl = getFormPayload(getState());
		try {
			const response = await api.get(`content/v0/overlays/search/?q=${pl.search}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestSuggestedStickers = createAsyncThunk(
	'requestSuggestedStickers',
	async () => {
		try {
			const response = await api.get('content/v0/overlays/initial');
			return response.data;
		} catch (error) {
			return getErrorsFromRejectedRequest(error);
		}
	},
);

export const setMentionTag = createAsyncThunk(
	'setMentionTag',
	async (payload, { rejectWithValue }) => {
		try {
			editingMentionMap[payload.id] = payload;
			return payload.id;
		} catch (err) {
			return rejectWithValue(handleSliceError(err));
		}
	},
);

export const stickersSlice = createSlice({
	name: 'stickers',
	initialState: {
		searchedStickers: null,
		suggestedStickers: null,
		errors: null,
		loading: false,
		mentionTag: null,
		editingMentionId: null,
	},
	reducers: {
		closeModal: (state) => {
			state.editingMentionId = null;
			delete editingMentionMap[state.editingMentionId];
		},
		removeMention: (state, action) => {
			state.editingMentionId = null;
			const { id } = action.payload;
			delete editingMentionMap[id];
		},
	},
	extraReducers: {
		[requestSearchStickers.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestSearchStickers.fulfilled]: (state, action) => {
			state.searchedStickers = action.payload;
			state.loading = false;
			state.errors = null;
		},

		[requestSearchStickers.rejected]: (state, action) => {
			state.searchedStickers = [];
			state.loading = false;
			state.errors = action.payload;
		},
		[requestSuggestedStickers.fulfilled]: (state, action) => {
			state.suggestedStickers = action.payload;
			state.loading = false;
			state.errors = null;
		},

		[requestSuggestedStickers.rejected]: (state, action) => {
			state.suggestedStickers = [];
			state.loading = false;
			state.errors = action.payload;
		},
		[setMentionTag.fulfilled]: (state, action) => {
			if (action.payload) {
				state.editingMentionId = action.payload;
			}
		},
		[setMentionTag.rejected]: (state, action) => {
			state.editingMentionId = null;
			state.errors = action.payload;
		},
	},

});

const { name, reducer, actions } = stickersSlice;
const { closeModal, removeMention } = actions;
export { name, closeModal, removeMention };

export const getSlice = (state) => state[name];
export const getSearchedStickers = createSelector(getSlice, (slice) => slice?.searchedStickers);
export const getSuggestedStickers = createSelector(getSlice, (slice) => slice?.suggestedStickers);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);
export const selectMentionTag = createSelector(getSlice, (slice) => editingMentionMap[slice?.editingMentionId]);
injectReducer(name, reducer);
