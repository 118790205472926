export const trimAtSymbol = (username) => (username.startsWith('@') ? username.slice(1) : username);

export const trimAtSymbolSelector = (_, username) => (username
	? trimAtSymbol(username)
	: undefined);

export const updateAccount = (state, username, data) => {
	if (!state.accounts[username]) {
		state.accounts[username] = {};
	}
	Object.assign(state.accounts[username], data);
};
