import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { endSession } from 'app/slices/authentication/session/session';
import { reset as resetProfile } from 'app/slices/user-account-v2/accounts';
import { clearTargetId } from 'app/slices/account-settings/notifications/push-notifications';
import { notify } from 'app/slices/notifications';

export default () => {
	const dispatch = useDispatch();

	useEffect(async () => {
		await dispatch(endSession());
		await dispatch(resetProfile());
		await dispatch(clearTargetId());

		// TODO: @alireza add this as a flash message on "/landing?="
		dispatch(notify({
			severity: 'info',
			description: 'You successfully logged out.',
		}));

		window.location.href = '/landing';
	}, [dispatch]);

	return null;
};
