import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import SubmitButton from 'routes/Auth/components/SubmitButton/SubmitButton';
import { useTheme } from '@material-ui/styles';
import {
	requestChangeUsername,
	getRequesting,
	requestUserPrivateProfile,
} from 'app/slices/account-settings';
import { getMyUsername } from 'app/slices/user-account-v2/accounts/selectors';
import IconV3 from 'rds/components/IconV3';
import api, { CancelToken } from 'services/api';
import { username as usernameRE } from 'utils/regexes';
import { control, setErrors, resetForm } from 'app/slices/account-settings/form';
import TextField from 'rds/components/TextField';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import useResizeHandler from 'app/hooks/useResizeHandler';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		top: theme.spacing(6),
		marginRight: theme.spacing(4),
		marginLeft: theme.spacing(4),
		color: theme.colors.neutral.C300,
	},
	subtitle: {
		color: theme.colors.neutral.C300,
	},
	username: {
		position: 'relative',
		marginTop: theme.spacing(5),
		color: theme.colors.neutral.C400,
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
	},
	textfieldContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	textfield: {
		background: 'none',
	},
	iconContainer: {
		position: 'relative',
		right: 0,
		top: 6,
	},
	temporaryError: {
		position: 'relative',
		color: theme.colors.neutral.C000,
		background: theme.colors.error.C700,
	},

}));

export default () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const ref = useRef();
	const asyncValidatorRef = useRef();
	const requesting = useSelector(getRequesting);
	const [loading, setLoading] = useState(false);
	const profileUsername = useSelector(getMyUsername);
	const fields = control.get(dispatch);
	const [username, usernameErr, usernameTmpErr] = fields.username.values;
	const [focused, setFocused] = useState(false);

	const actions = {
		onKeyboardClose: () => {
			ref.current.blur();
		},
	};

	useResizeHandler(actions);

	const checkDisabled = () => {
		if (!!usernameErr || !!usernameTmpErr
				|| loading || !username.length
				|| username === profileUsername
				|| requesting) {
			return true;
		}

		return false;
	};

	const disabled = checkDisabled();

	const setupWait = (ms) => {
		let to;

		const promise = new Promise((resolve) => {
			to = setTimeout(() => {
				resolve();
			}, ms);
		});

		return {
			promise,
			clearTimeout: () => { clearTimeout(to); },
		};
	};

	useEffect(() => {
		if (!profileUsername) {
			dispatch(requestUserPrivateProfile());
		}
		return () => {
			dispatch(resetForm());
		};
	}, [profileUsername]);

	useEffect(() => {
		const e = { target: { value: profileUsername } };
		fields.username.change(e);
	}, [profileUsername]);

	const onSubmit = async () => {
		const response = await dispatch(requestChangeUsername({ username }));
		if (response.meta.requestStatus === 'fulfilled') {
			navigate(-1);
		}
	};

	const asyncValidator = profileUsername === username
		? null
		: async () => {
			if (usernameErr) {
				return false;
			}
			setLoading(true);
			if (asyncValidatorRef.current) {
				asyncValidatorRef.current.cancel();
			}

			const source = CancelToken.source();

			const wait = setupWait(250);
			asyncValidatorRef.current = {
				cancel: () => {
					source.cancel();
					wait.clearTimeout();
				},
			};

			await wait.promise;
			try {
				await api.post('accounts/profile/validate', {
					username,
				}, {
					cancelToken: source.token,
				});

				return true;
			} catch (error) {
				if (error.__CANCEL__) {
					return false;
				}
				const errors = getErrorsFromRejectedRequest(error);
				if (!usernameErr) {
					dispatch(setErrors(errors));
				}
				return false;
			} finally {
				setLoading(false);
			}
		};

	const style = {};

	if (disabled) {
		style.background = theme.colors.neutral.C200;
		style.color = theme.colors.neutral.C600;
	}

	return (
		<>
			<Header title="Username" />
			<div className={classes.container}>
				<Typography
					className={classes.subtitle}
					variant="body2"
				>
					Your username is unique and represents your account on Rhino.
				</Typography>
				<Typography
					className={classes.username}
					variant="body2"
				>
					Username
				</Typography>
				<div className={classes.form}>
					<TextField
						theme={theme}
						value={username}
						showTmpErr
						inputRef={ref}
						err={usernameErr}
						tmpErr={usernameTmpErr}
						onChange={fields.username.change}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
						pattern={usernameRE}
						flashSuccess={focused && username !== profileUsername}
						inputProps={{
							autoComplete: 'none',
							style: {
								background: 'none',
								border: 'none',
								paddingLeft: 0,
								paddingRight: 12,
								paddingTop: 8,
								paddingBottom: 8,
							},
						}}
						name="username"
						variant="glass"
						maxLength="30"
						minLength="2"
						autoComplete="username"
						fullWidth
						formItem
						asyncValidator={asyncValidator}
						right={(!focused && username === profileUsername
							? (
								<div
									className={classes.iconContainer}
									onClick={() => {
										ref?.current?.focus();
									}}
								>
									<IconV3
										icon="EditPen"
										size={20}
										containerSize={20}
									/>
								</div>
							)
							: null)}
					/>
					<SubmitButton
						disabled={disabled}
						style={style}
						size="medium"
						onClick={onSubmit}
						loading={requesting}
					>
						Submit
					</SubmitButton>
				</div>
			</div>
		</>
	);
};
