export default (torch, stream) => {
	const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
	const track = stream.getVideoTracks()[0];
	if (supportedConstraints.torch) {
		track.applyConstraints({
			advanced: [{ torch }],
			/* eslint-disable-next-line */
		}).catch((_) => {
			// safe to ignore
			// const errors = getErrorsFromRejectedRequest(error);
			// notifyErrors(errors);
		});
	}
};
