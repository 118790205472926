import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		zIndex: 11,
		left: 0,
		right: 0,
		top: 0,
		height: 48,
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	title: {
		textAlign: 'center',
		paddingLeft: theme.spacing(),
		paddingRight: theme.spacing(),
		maxWidth: 180,
		margin: '0 auto',
		whiteSpace: 'nowrap',
	},
}));

export default ({
	text,
	zIndex,
}) => {
	const classes = useStyles();
	const style = { zIndex: zIndex - 1 };

	return (
		<div className={classes.root} style={style}>
			<Typography variant="body1" className={classes.title}>
				{text}
			</Typography>
		</div>
	);
};
