import React from 'react';
import useRdsTheme from 'rds/theme/useRdsTheme';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../components/layout/PageLayout';
import ForgotPasswordForm from './ForgotPasswordForm';

export default () => {
	const theme = useRdsTheme('dark');
	const navigate = useNavigate();
	return (
		<PageLayout
			theme={theme}
			title="Forgot password?"
			caption="Enter the email associated with your account"
			close={() => {
				navigate(-1);
			}}
		>
			<ForgotPasswordForm />
		</PageLayout>
	);
};
