import React, { useRef } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import IconV3 from 'rds/components/IconV3';
import scrollEmitter from 'layout/scrollEmitter';

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		position: 'absolute',
		zIndex: 17,
		right: theme.spacing(3),
		left: theme.spacing(3),
		paddingBottom: theme.spacing(12),
		touchAction: 'none',
	},
	background: {
		position: 'fixed',
		zIndex: 5,
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		opacity: 0.8,
		height: '100vh',
		background: theme.palette.background.page,
		touchAction: 'none',
	},
	optionsContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		zIndex: 6,
		background: theme.colors.neutral.C900,
		borderRadius: 2,
		position: 'relative',
	},
	hide: {
		display: 'none',
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		border: `1px solid ${theme.colors.primary.C900}`,
		height: 40,
		color: theme.colors.primary.C600,
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(4),
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		paddingRight: theme.spacing(4),
		paddingLeft: theme.spacing(4),
	},
	active: {
		color: theme.colors.neutral.C000,
		background: theme.colors.primary.C600,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: theme.spacing(5),
		paddingRight: theme.spacing(6),
		paddingLeft: theme.spacing(6),
	},
	options: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(6),
		marginRight: theme.spacing(6),
		marginLeft: theme.spacing(6),
	},
	apply: {
		background: theme.colors.neutral.C000,
		color: theme.colors.neutral.C900,
		marginTop: theme.spacing(),
		marginRight: theme.spacing(4),
		marginLeft: theme.spacing(4),
		marginBottom: theme.spacing(4),
		height: 38,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': {
			background: theme.colors.neutral.C000,
			color: theme.colors.neutral.C900,
		},
	},
	reset: {
		color: theme.colors.primary.C600,
		padding: theme.spacing(3, 6, 3, 6),
	},
	disabled: {
		background: theme.colors.neutral.C200,
		color: theme.colors.neutral.C600,
		'&:hover': {
			background: theme.colors.neutral.C200,
			color: theme.colors.neutral.C600,
		},
	},

}));

const checkFiltersState = (array1, array2) => {
	if (array1.length !== array2.length) {
		return false;
	}

	for (let i = 0; i < array1.length; i++) {
		if (array1[i].slug !== array2[i].slug) {
			return false;
		}
	}

	return true;
};

export default ({
	show,
	title = '',
	options = [],
	onClose,
	handleSelect,
	filters,
	openingFilterState,
	filterHasBeenChanged = false,
	onReset,
	onSubmit,
}) => {
	const classes = useStyles();
	const containerRef = useRef();
	const optionsList = [...options];
	const sortedOptions = optionsList?.sort((a,
		b) => a.slug.localeCompare(b.slug));

	let isThereAnyChanges = false;
	if (title.length) {
		isThereAnyChanges = checkFiltersState(openingFilterState[title], filters[title]);
	}

	const containerClass = show ? classes.rootContainer : clsx(classes.rootContainer, classes.hide);
	const backgroundStyle = {};

	return (
		<>
			<div ref={containerRef} className={containerClass}>
				<div className={classes.optionsContainer}>
					<div className={classes.header}>
						<Typography variant="subtitle1">{title}</Typography>
						<IconV3
							icon="Cancel"
							size={24}
							containerSize={24}
							dropShadow
							onClick={() => {
								if (filterHasBeenChanged) {
									onReset();
								}
								onClose();
							}}
						/>
					</div>
					<div className={classes.options}>
						{sortedOptions.map((o, i) => {
							const klass = (filters && filters[title].some((f) => f.slug === o.slug)) ? clsx(classes.button, classes.active) : classes.button;
							return (
								<Typography
									key={i}
									variant="subtitle2"
									className={klass}
									onClick={() => { handleSelect(o); }}
								>
									{o.title}
								</Typography>
							);
						})}
					</div>
					<Typography
						variant="subtitle2"
						className={isThereAnyChanges ? clsx(classes.apply, classes.disabled) : classes.apply}
						onClick={() => {
							if (!isThereAnyChanges) {
								onSubmit();
								onClose();
								scrollEmitter.emit('scrollToTop');
							}
						}}
					>
						Apply
					</Typography>
				</div>
			</div>
			{show && (
				<div
					className={classes.background}
					style={backgroundStyle}
					onClick={() => {
						onReset();
						onClose();
					}}
				/>
			)}
		</>
	);
};
