import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	emptyPane: {
		position: 'relative',
		display: 'inline-block',
		height: '100%',
		background: theme.colors.neutral.C900,
	},
}));

export default ({ size }) => {
	const classes = useStyles();

	const percentage = size * 100;
	const style = {};

	if (size) {
		style.width = `${percentage}%`;
	}

	return (
		<div className={classes.emptyPane} style={style} />
	);
};
