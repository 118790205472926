import { createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

export const backgroundSlice = createSlice({
	name: 'layout',
	initialState: {
		errors: null,
		direction: null,
		style: {},
		overflowMenu: null,
		showNavbar: true,
		showHeader: true,
		routerCalls: 0,
	},
	reducers: {
		setDirection: (state, action) => {
			state.direction = action.payload;
		},
		openOverflowMenu: (state, action) => {
			state.overflowMenu = action.payload;
		},
		closeOverflowMenu: (state) => {
			state.overflowMenu = null;
		},
		countRouterCall: (state) => {
			state.routerCalls += 1;
		},
	},
});

const { name, reducer, actions } = backgroundSlice;
export const {
	setDirection,
	openOverflowMenu,
	closeOverflowMenu,
	countRouterCall,
} = actions;

export const getSlice = (state) => state[name];
export const getDirection = createSelector(getSlice, (slice) => slice?.direction);
export const getOverflowMenu = createSelector(getSlice, (slice) => slice?.overflowMenu);
export const getShowNavbar = createSelector(getSlice, (slice) => slice?.showNavbar);
export const getShowHeader = createSelector(getSlice, (slice) => slice?.showHeader);
export const getRouterCalls = createSelector(getSlice, (slice) => slice?.routerCalls);

injectReducer(name, reducer);
