import {
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import store, { injectReducer } from 'services/store';

export const notifyErrors = (errors) => {
	errors.forEach((err) => {
		store.dispatch(notify({
			severity: err?.severity || 'error',
			title: err?.title,
			description: err?.text,
		}));
	});
};

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: {
		notifications: [],
	},
	reducers: {
		notify: (state, { payload }) => {
			/**
			 * Example Payload
			 * {
			 *		severity: 'info',
			 *		title: 'Enable flash player',
			 *		description: 'Requires Adobe Flash Player version 11.0 or above',
			 * }
			*/
			if (state.notifications.length) {
				state.notifications = [];
			}
			const {
				autoHideDuration = 5000,
				severity,
				title,
				description,
				disableClose,
				background,
				buttons,
			} = payload;

			const notification = {
				id: Math.round(Date.now() * Math.random()),
				buttons,
				dismissed: false,
				autoHideDuration,
				severity,
				title,
				description,
				disableClose,
				background,
			};

			state.notifications = [notification, ...state.notifications.slice(0, 3)];
		},

		dismiss: (state, action) => {
			const { id, max = 3 } = action.payload;

			state.notifications = [...state.notifications.slice(0, max)];

			if (id && state.notifications.length) {
				const index = state.notifications.findIndex((notification) => notification.id === id);
				if (state.notifications[index]) {
					state.notifications[index].dismissed = true;
				}
				return;
			}

			const index = state.notifications.slice().reverse().findIndex((notification) => !notification.dismissed);

			const count = state.notifications.length - 1;
			const finalIndex = index >= 0 ? count - index : index;

			if (finalIndex !== -1) {
				state.notifications[finalIndex].dismissed = true;
			}
		},

		remove: (state, action) => {
			const { id } = action.payload;

			const notifications = [...state.notifications];
			const index = notifications.findIndex((notification) => notification.id === id);
			notifications.splice(index, 1);
			state.notifications = notifications;
		},
		reset: (state) => {
			state.notifications = [];
		},
	},
});

const { name, actions, reducer } = notificationsSlice;
const {
	notify, dismiss, remove, reset,
} = actions;
export {
	notify, dismiss, remove, reset,
};

export const getSlice = (state) => state[name];
export const getNotifications = createSelector(
	getSlice,
	(slice) => slice?.notifications ?? [],
);
export const getActiveNotifications = createSelector(
	getNotifications,
	(notifications) => notifications.filter((notification) => !notification.dismissed),
);

injectReducer(name, reducer);

export default reducer;
