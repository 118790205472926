import {
	isObject,
	isLocked,
} from './utils';

export default (canvas, store) => (e) => {
	e.preventDefault();

	if (canvas.ignoreGestures) { return; }

	e.moveTarget = e.target;

	if (!isObject(e) || isLocked(e)) {
		e.moveTarget = canvas.container.querySelector(`[data-object-id="${canvas.store.root}"]`);
	}

	if (!e.moveTarget) {
		// no root;
		return;
	}

	e.moveTarget.lastDistance = undefined;
	e.moveTarget.startAngle = null;
	e.moveTarget.lastDistance = null;
	e.moveTarget.offset = e.moveTarget.rotation;
	e.moveTarget.rotations = 0;
	store.addGesture(e.moveTarget, e);
	store.downEvent = e;
};
