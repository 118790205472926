import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getDestination,
	getNotFound,
} from 'app/slices/destination-by-share';
import { useNavigate } from 'react-router-dom';
import { notify } from 'app/slices/notifications/notifications';

const ByCode = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const sharedDestination = useSelector(getDestination);
	const notFound = useSelector(getNotFound);

	useEffect(() => {
		let to;

		if (notFound) {
			to = '/explore';
			dispatch(notify({
				severity: 'info',
				description: 'Destinations not found.',
			}));
		} else {
			to = `/destination/${sharedDestination?.uid}`;
		}

		navigate(to);
	}, [sharedDestination, notFound, dispatch, navigate]);

	return null;
};

export default ByCode;
