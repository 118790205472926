import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeCaption,
	changeTitle,
	getEditedTitle,
	getEditedCaption,
	set,
} from 'app/slices/experience-view/edit';
import { makeStyles } from '@material-ui/core';
import TextModal from 'components/TextModal';

const TITLE_MAX_LENGTH = 60;
const CAPTION_MAX_LENGTH = 2000;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(6, 3),
	},
}));

export default ({ captionRtml, title }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const nt = useSelector(getEditedTitle);
	const nc = useSelector(getEditedCaption);

	useEffect(() => {
		let caption = '';

		try { caption = JSON.parse(captionRtml).text; } catch (_) {
			//
		}
		dispatch(set({
			caption,
			title,
		}));
	}, [title, captionRtml]);

	return (
		<div className={classes.root}>
			<TextModal
				type="title"
				value={nt || ''}
				title="Add Title"
				placeholder="Add an experience title"
				modalPlaceHolder="Add a title to this experience"
				maxLength={TITLE_MAX_LENGTH}
				addAction={changeTitle}
			/>
			<TextModal
				type="caption"
				value={nc || ''}
				title="Add Caption"
				placeholder="Add a caption"
				modalPlaceHolder="Add a caption or other information regarding the experience. (Ex. links, things to bring, best time to go, recommended length of time to visit)."
				maxLength={CAPTION_MAX_LENGTH}
				addAction={changeCaption}
			/>
		</div>
	);
};
