import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';
import api from 'services/api';
import dayjs from 'dayjs';

// Ensures that we don't make excessive requests and return cached (or empty) data when necessary
const THROTTLE_TIME = 60000;
const lastRequestCache = {
	time: null,
	response: null,
};

function wasRecentRequestMade() {
	return lastRequestCache.time
        && (window.performance.now() - lastRequestCache.time < THROTTLE_TIME);
}

export const requestAllNotifications = createAsyncThunk(
	'requestAllNotifications',
	async (_, { rejectWithValue, getState }) => {
		try {
			lastRequestCache.time = window.performance.now();
			lastRequestCache.response = null;

			const { session } = getState();
			const response = await api.get(`notifications/${session.session.uuid}`);

			lastRequestCache.response = response.data;
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorsFromRejectedRequest(error));
		}
	},
	{
		condition: () => {
			// If a recent request was made, don't run the payload creator
			if (wasRecentRequestMade()) {
				return false;
			}
			return true;
		},
	},
);

export const requestLoadMoreNotifications = createAsyncThunk(
	'requestLoadMoreNotifications',
	async (_, { rejectWithValue, getState }) => {
		const { nextLinkOfNotifications } = getState().appNotifications;

		if (nextLinkOfNotifications) {
			try {
				const response = await api.get(`notifications/${nextLinkOfNotifications}`);
				return response.data;
			} catch (error) {
				return rejectWithValue(getErrorsFromRejectedRequest(error));
			}
		}

		return null;
	},
);

const addIt = (item, array) => {
	const found = array.some((n) => n.id === item.id);

	if (!found) {
		array.push(item);
	}
};

const initialState = {
	allNotifications: null,
	todayNotifications: [],
	recentNotifications: [],
	previousNotifications: [],
	lastRequestTime: null,
	nextLinkOfNotifications: null,
	loading: false,
	errors: null,
};

const appNotificationsSlice = createSlice({
	name: 'appNotifications',
	initialState: { ...initialState },
	reducers: {
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		markRead: (state, action) => {
			const id = action.payload;

			[
				state.allNotifications,
				state.todayNotifications,
				state.recentNotifications,
				state.previousNotifications,
			].forEach((list) => {
				if (list) {
					list.forEach((i) => {
						if (i.id === id) {
							i.unread = false;
						}
					});
				}
			});
		},
	},
	extraReducers: {
		[requestAllNotifications.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestAllNotifications.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[requestAllNotifications.fulfilled]: (state, action) => {
			const { notifications } = action.payload;
			const link = action.payload?._links?.next?.href;
			state.allNotifications = notifications;
			for (let i = 0; i < notifications.length; i++) {
				const now = dayjs();
				const date = dayjs(notifications[i].created);
				const difference = now.diff(date, 'hours', true);
				if (difference > 72) {
					addIt(notifications[i], state.previousNotifications);
				} else if (difference < 24) {
					addIt(notifications[i], state.todayNotifications);
					state.todayNotifications.sort((a, b) => dayjs(b.created).diff(dayjs(a.created)));
				} else {
					addIt(notifications[i], state.recentNotifications);
				}
			}
			if (link) {
				const nextUrl = link.substring(link.indexOf('notifications') + 14);
				state.nextLinkOfNotifications = nextUrl;
			} else {
				state.nextLinkOfNotifications = null;
			}
			state.loading = false;
			state.errors = null;
		},
		[requestLoadMoreNotifications.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},
		[requestLoadMoreNotifications.fulfilled]: (state, action) => {
			const { notifications } = action.payload;

			if (!notifications) {
				return;
			}

			const link = action.payload?._links?.next?.href;
			state.allNotifications = [...state.allNotifications, notifications];

			for (let i = 0; i < notifications.length; i++) {
				const now = dayjs();
				const date = dayjs(notifications[i].created);
				const difference = now.diff(date, 'hours', true);
				if (difference > 72) {
					state.previousNotifications = [...state.previousNotifications, notifications[i]];
				} else if (difference < 24) {
					state.todayNotifications = [...state.todayNotifications, notifications[i]];
				} else {
					state.recentNotifications = [...state.recentNotifications, notifications[i]];
				}
			}

			if (link) {
				const nextUrl = link.substring(link.indexOf('notifications') + 14);
				state.nextLinkOfNotifications = nextUrl;
			} else {
				state.nextLinkOfNotifications = null;
			}
			state.loading = false;
			state.errors = null;
		},
		[requestLoadMoreNotifications.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},

	},
});

const { name, reducer, actions } = appNotificationsSlice;
const { set, reset, markRead } = actions;

export {
	name, set, reset, markRead,
};

export const getSlice = (state) => state[name];
export const getAllNotifications = createSelector(getSlice, (slice) => slice?.allNotifications);
export const getTodayNotifications = createSelector(getSlice, (slice) => slice?.todayNotifications);
export const getRecentNotifications = createSelector(getSlice, (slice) => slice?.recentNotifications);
export const getPreviousNotifications = createSelector(getSlice, (slice) => slice?.previousNotifications);
export const getNextLinkOfNotifications = createSelector(getSlice, (slice) => slice?.nextLinkOfNotifications);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export default appNotificationsSlice;

injectReducer(name, reducer);
