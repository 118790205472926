module.exports = {
	C000: '#FFDBDB',
	C100: '#FFB3B3',
	C200: '#FF8F8F',
	C300: '#F85D5D',
	C400: '#EF4343',
	C500: '#E12330',
	C600: '#C70F21',
	C700: '#A1081B',
	C800: '#810318',
	C900: '#680217',
};
