import React from 'react';
import TopButton from 'rds/components/Layouts/FixedTopBar/TopButton';
import useRdsTheme from 'rds/theme/useRdsTheme';
import { useNavigate } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import PageLayout from '../components/layout/PageLayout';

export default () => {
	const theme = useRdsTheme('dark');
	const navigate = useNavigate();

	return (
		<PageLayout
			theme={theme}
			close={() => {
				navigate(-1);
			}}
			title={(
				<div>
					explore.
					<br />
					{' '}
					connect. discover.
				</div>
			)}
		>
			<TopButton
				variant="glass"
				close={() => {
					navigate('/');
				}}
				closeIconPosition="left"
			/>
			<SignUpForm theme={theme} />

		</PageLayout>

	);
};
