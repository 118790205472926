import { createAsyncThunk } from '@reduxjs/toolkit';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';

export const requestAllExperiencesByDestination = createAsyncThunk(
	'requestAllExperiencesByDestination',
	async (uid, { getState, rejectWithValue }) => {
		const { filters } = getState().destinationAccountExperiences;
		const allFilters = JSON.parse(window.sessionStorage.getItem(`destinationFilters-${uid}`)) || filters;

		const qparams = {
			destination: uid,
			limit: 12,
			trusted: true,
			activities: allFilters.Activity.map((f) => f.slug).join(','),
			recommendedFor: allFilters['Recommended for'].map((f) => f.slug).join(','),
			identifiers: allFilters.Community.map((f) => f.slug).join(','),
		};

		const parts = [];
		Object.keys(qparams).forEach((key) => {
			if (qparams[key]) {
				parts.push(`${key}=${encodeURIComponent(qparams[key])}`);
			}
		});
		const qs = parts.length ? `?${parts.join('&')}` : '';

		try {
			const response = await api.get(`experiences${qs}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error, { notifyUser: false }));
		}
	},
);

export const requestLoadMoreExperiencesByDestination = createAsyncThunk(
	'requestLoadMoreExperiencesByDestination',
	async (_, { rejectWithValue, getState }) => {
		const { next } = getState().destinationAccountExperiences;
		let response;
		if (next) {
			try {
				response = await api.get(`${next}`);
			} catch (error) {
				return rejectWithValue(handleSliceError(error));
			}
		}
		return response.data;
	},
);
