import React from 'react';
import { makeStyles } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import Typography from 'rds/components/Typography';
import EachRecommendedFor from './EachRecommendedFor';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(7.5),
	},
	title: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		marginBottom: theme.spacing(3),
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
	},
	icon: {
		marginRight: theme.spacing(3),
	},
	container: {
		display: 'flex',
		paddingLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
	},
}));

export default ({ recommendedFor }) => {
	const classes = useStyles();

	if (recommendedFor && !recommendedFor.length) {
		return null;
	}

	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<IconV3
					icon="CircleCheck"
					style={{
						marginRight: 12,
					}}
					size={20}
					containerSize={20}
				/>
				<Typography variant="subtitle1">Recommended for</Typography>
			</div>
			<div className={classes.container}>
				{recommendedFor && recommendedFor.map((r) => (
					<div key={r.uid}>
						<EachRecommendedFor key={r.uid} group={r} />
					</div>
				))}
			</div>
		</div>
	);
};
