import createElement from '../../utils/create-element';
import HTMLNode from './html';
import { FILTER } from '../../types';

export default class FilterCollectionNode extends HTMLNode {
	constructor(canvas, root, collection) {
		super(canvas);

		this.drawn = null;
		this.el = null;
		this.compileEl = null;

		this.canvas = canvas;
		this.collection = collection;
		this.root = root;
		this.type = FILTER;
		this.el = this.collection.selected.filterBox.cloneNode(true);
	}

	get z() {
		return this.collection.z;
	}

	async drawFrame() {
		if (!this.collection.selected) {
			return;
		}

		if (this.drawn && this.drawn.key === this.collection.selected.key) {
			return;
		}

		if (this.drawn) {
			this.el.innerHTML = '';
		}
		this.el.appendChild(this.collection.selected.filterBox);
	}

	async setupDraw(isCompile) {
		const el = createElement({
			tagName: 'div',
		});

		if (isCompile) {
			this.compileEl = el;
		} else {
			this.el = el;
		}

		this.canvas.container.appendChild(el);
	}
}
