import {
	useEffect,
	useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParam } from 'app/hooks/useQueryParams';
import {
	register,
	ecrReduxMap,
	prepareExperiences,
	getLoaded,
	setLoaded,
} from 'app/slices/experience-modal';

const LOAD_MORE_BUFFER = 3;

export default ({
	children,
	experiencesSelectorKey,
	isDataLoaded,
	requestParams,
	disable,
	prepareOverrides = {},
}) => {
	const dispatch = useDispatch();
	const trustedCreator = useQueryParam('trusted-creator');
	const experience = useQueryParam('experience');
	const comment = useQueryParam('comment');
	const loaded = useSelector(getLoaded);
	const last = useRef();

	const {
		reset,
		requestExperiences,
		requestMore,
		loadingSelector,
		selector,
		hasMoreToLoad,
	} = ecrReduxMap[experiencesSelectorKey];

	const experiences = useSelector(selector) || [];
	const loading = useSelector(loadingSelector);
	const hasMoreContentToLoad = useSelector(hasMoreToLoad);

	useEffect(() => {
		if (disable) {
			return;
		}

		if (!isDataLoaded) {
			return;
		}

		if (!experience && experiences.length && reset) {
			dispatch(reset());
		}

		if (experience && !loading && !experiences.length) {
			dispatch(requestExperiences({
				trustedCreator,
				...requestParams,
			}));
		}
	}, [disable, isDataLoaded, experience, trustedCreator]);

	useEffect(async () => {
		if (disable) {
			last.current = null;
			return;
		}

		if (last.current === experience) {
			return;
		}

		if (experience) {
			if (experiences && experiences.length) {
				await dispatch(prepareExperiences({
					selector: experiencesSelectorKey,
					show: true,
					uid: experience,
					comment,
					...prepareOverrides,
				}));

				last.current = experience;
			}
		} else {
			last.current = experience;
			if (!loaded) {
				dispatch(setLoaded(true));
			}
		}

		if (experiences) {
			const cur = experiences.findIndex((e) => e.uid === experience);
			const loadMore = hasMoreContentToLoad && (experiences.length - 1) - cur <= LOAD_MORE_BUFFER && requestMore && !loading;
			let contentLoaded = false;

			if (loadMore) {
				const moreResponse = await dispatch(requestMore());
				contentLoaded = contentLoaded || (moreResponse.payload?.experiences?.length > 0);
			}

			if (contentLoaded) {
				await dispatch(prepareExperiences({
					selector: experiencesSelectorKey,
					show: true,
					uid: experience,
					comment,
					...prepareOverrides,
				}));
				contentLoaded = false;
			}
		}
	}, [disable, experience, experiences, loading, hasMoreToLoad]);

	return children;
};

export { register };
