import eventEmitter from 'events';
import getDefaultOptions from '../utils/get-default-options';
import {
	ADD_OBJECT,
	BACKGROUND_OBJECT,
	LOCK_OBJECT,
	REMOVE_OBJECT,
	ROOT_OBJECT,
	UNLOCK_OBJECT,
	UPDATE_OBJECT,
} from '../event-types';

export default class Store extends eventEmitter {
	constructor() {
		super();

		this.ID_SERIAL = 1;
		this.Z_INDEX_SERIAL = 10;

		this.background = null;
		this.root = null;
		this.objects = {};
	}

	/**
	 * Public getter to retrieve items in order of zindex
	 *
	 * todo - cache
	 *
	 * @returns {array} items array
	 */
	get items() {
		const items = [];

		Object.keys(this.objects).forEach((k) => {
			if (this.objects) {
				items.push(this.objects[k]);
			}
		});

		return items.sort((a, b) => parseInt(b.z, 10) > parseInt(a.z, 10));
	}

	/**
	 * Add a new object to the store
	 *
	 * @param {string} type type of object
	 * @param {any} data object data
	 * @param {object} [styles] data styles
	 * @returns {object} returns object
	 */
	add(type, data, styles) {
		const id = this.ID_SERIAL++;
		const z = ++this.Z_INDEX_SERIAL;

		const o = {
			data,
			id,
			styles: styles || {},
			type,
			z,
		};

		o.isBackground = () => !!o.data.background;
		o.isRoot = () => !!o.data.root;

		styles['z-index'] = z;
		this.objects[id] = o;
		this.emit(ADD_OBJECT, id, o);

		return {
			item: o,
			lock: () => { this.lock(id); },
			makeBackground: () => { this.makeBackground(id); },
			makeRoot: () => { this.makeRoot(id); },
			unlock: () => { this.unlock(id); },
			update: (d, s) => { this.update(id, d, s); },
		};
	}

	setObjectZ(id, z) {
		this.objects[id].z = z;
		this.objects[id].styles['z-index'] = z;
	}

	/**
	 * Makes an object the root object
	 * @param {string} id of the item to make root
	 */
	makeRoot(id) {
		if (this.root) {
			throw new Error('Cannot have two root items');
		}

		this.root = id;
		this.setObjectZ(id, 2);
		this.objects[id].data.root = true;
		this.emit(ROOT_OBJECT, id);
		this.emit(UPDATE_OBJECT, id, this.objects[id]);
	}

	makeBackground(id) {
		this.background = id;
		this.setObjectZ(id, 1);
		this.objects[id].data.background = true;
		this.emit(BACKGROUND_OBJECT, id);
		this.lock(id);
		this.emit(UPDATE_OBJECT, id, this.objects[id]);
	}

	/**
	 * Locks an item to the canvas
	 *
	 * @param {string} id of the item to lock
	 */
	lock(id) {
		this.objects[id].data.locked = true;
		this.emit(LOCK_OBJECT, id);
	}

	/**
	 * Unlocks an item from the canvas
	 *
	 * @param {string} id of the item to unlock
	 */
	unlock(id) {
		this.objects[id].data.locked = false;
		this.emit(UNLOCK_OBJECT, id);
	}

	/**
	 * Returns the object for provided id
	 *
	 * @param {number} id id of the object
	 * @returns {object} object
	 */
	get(id) {
		return this.objects[id];
	}

	/**
	 * bump z index
	 */
	bumpZ() {
		const z = ++this.Z_INDEX_SERIAL;
		return z;
	}

	/**
	 * Update an objects style
	 * @param {number} id
	 * @param {string} key
	 * @param {string} value
	 */
	updateStyle(id, key, value) {
		if (!this.objects[id]) {
			return;
		}

		this.objects[id].styles[key] = value;
	}

	/**
	 * Update an object
	 *
	 * @param {number} id id of the object
	 * @param {any} data object data
	 * @param {object} [styles] data styles
	 * @returns {object} object
	 */
	update(id, data, styles) {
		if (!this.objects[id]) {
			return;
		}

		if (id !== this.background && id !== this.root && this.objects[id].z !== this.Z_INDEX_SERIAL) {
			const z = ++this.Z_INDEX_SERIAL;
			this.objects[id].z = z;
			this.objects[id].styles['z-index'] = z;
		}

		if (typeof data !== 'undefined') {
			Object.keys(data).forEach((key) => {
				this.objects[id].data[key] = data[key];
			});
		}

		if (typeof styles !== 'undefined') {
			this.objects[id].styles = getDefaultOptions(styles, this.objects[id].styles);
		}

		this.emit(UPDATE_OBJECT, id, this.objects[id]);
	}

	/**
	 * Remove an object
	 *
	 * @param {number} id id of the object
	 */
	remove(id) {
		if (id === this.root) {
			this.root = null;
		}
		delete this.objects[id];
		this.emit(REMOVE_OBJECT, id);
	}

	destroy() { }
}
