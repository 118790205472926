/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import DestinationTag from './DestinationTag';
import Header from './ThumbnailHeader';

const useStyles = makeStyles((theme) => ({
	card: {
		position: 'relative',
		cursor: 'pointer',
		textAlign: 'center',
		height: '80%',
		marginBottom: theme.spacing(2),
		marginRight: theme.spacing(3),
		'@media (min-width: 390px)': {
			width: 'calc((60vh - 20px) * 9 / 20)',
		},
		'@media (max-width: 389px)': {
			width: 'calc((60vh - 25px) * 9 / 25)',
		},
		minHeight: '200px',
		minWidth: '115px',
	},
	videoContainer: {
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundPosition: 'center',
		position: 'relative',
		borderRadius: 4,
	},
	view: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		opacity: 0.9,
	},
	destination: {
		position: 'absolute',
		bottom: theme.spacing(3),
		left: theme.spacing(3),
		zIndex: 2,
	},
	destinationV2: {
		position: 'absolute',
		bottom: theme.spacing(),
		left: theme.spacing(),
		zIndex: 2,
	},
	topGredient: {
		position: 'absolute',
		background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00255271) 8.9%, rgba(0, 0, 0, 0.00998942) 16.22%, rgba(0, 0, 0, 0.021978) 22.27%, rgba(0, 0, 0, 0.0381864) 27.32%, rgba(0, 0, 0, 0.0582825) 31.66%, rgba(0, 0, 0, 0.0819341) 35.6%, rgba(0, 0, 0, 0.108809) 39.4%, rgba(0, 0, 0, 0.138575) 43.37%, rgba(0, 0, 0, 0.170901) 47.79%, rgba(0, 0, 0, 0.205454) 52.95%, rgba(0, 0, 0, 0.241902) 59.14%, rgba(0, 0, 0, 0.279912) 66.65%, rgba(0, 0, 0, 0.319153) 75.78%, rgba(0, 0, 0, 0.359293) 86.79%, rgba(0, 0, 0, 0.4) 100%)',
		right: 0,
		left: 0,
		top: 0,
		height: '20%',
		zIndex: 1,
		transform: 'rotate(180deg)',
	},
	bottomGredient: {
		position: 'absolute',
		background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00255271) 8.9%, rgba(0, 0, 0, 0.00998942) 16.22%, rgba(0, 0, 0, 0.021978) 22.27%, rgba(0, 0, 0, 0.0381864) 27.32%, rgba(0, 0, 0, 0.0582825) 31.66%, rgba(0, 0, 0, 0.0819341) 35.6%, rgba(0, 0, 0, 0.108809) 39.4%, rgba(0, 0, 0, 0.138575) 43.37%, rgba(0, 0, 0, 0.170901) 47.79%, rgba(0, 0, 0, 0.205454) 52.95%, rgba(0, 0, 0, 0.241902) 59.14%, rgba(0, 0, 0, 0.279912) 66.65%, rgba(0, 0, 0, 0.319153) 75.78%, rgba(0, 0, 0, 0.359293) 86.79%, rgba(0, 0, 0, 0.4) 100%)',
		right: 0,
		left: 0,
		bottom: 0,
		height: '33%',
	},
	play: {
		position: 'absolute',
		zIndex: 3,
	},
	header: {
		position: 'absolute',
		right: 0,
		left: 0,
		top: 0,
		height: '20%',
		zIndex: 1,
	},
}));

const EachThumbnail = ({ experience, onPlay, isPlaying }) => {
	const classes = useStyles();
	const { outputs } = experience;
	const { thumbnail, video } = outputs;
	const videoRef = useRef(null);
	const isLargeScreen = useMediaQuery('(min-width: 390px)');
	const thumbnailRef = useRef();

	const toggleVideoPlay = (e) => {
		e.stopPropagation();
		onPlay(experience.uid, thumbnailRef);
	};

	useEffect(() => {
		const videoElement = videoRef.current;
		if (!videoElement) return;

		if (isPlaying) {
			videoElement.play();
		} else {
			videoElement.pause();
		}
	}, [isPlaying]);

	return (
		<div ref={thumbnailRef} className={classes.card} onClick={toggleVideoPlay}>
			<div className={classes.header} />
			<Header user={experience.user} isLargeScreen={isLargeScreen} />

			<div className={classes.videoContainer}>
				<div className={classes.topGredient} />

				<video
					src={video.large.mp4}
					playsInline
					className={classes.view}
					loop
					poster={thumbnail.large.webp}
					type="video/mp4"
					ref={videoRef}
				/>
				{!isPlaying && (
					<div className={classes.play}>
						<IconV3
							icon="PlayFilled"
							color="neutralC000"
							size={32}
							containerSize={32}
						/>
					</div>
				)}
				<div className={isLargeScreen ? classes.destination : classes.destinationV2}>
					<DestinationTag
						destination={experience.destinationsV2[0]}
						onClick={() => { }}
						isCreator={false}
						isLargeScreen={isLargeScreen}
					/>
				</div>
				<div className={classes.bottomGredient} />
			</div>
		</div>
	);
};

export default EachThumbnail;
