import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import { notify } from '../notifications/notifications';

export const requestExperienceByCode = createAsyncThunk(
	'requestExperienceByCode',
	async ({ code }, { dispatch }) => {
		try {
			const response = await api.get(`experiences/by-share/${code}`);
			return response.data;
		} catch (error) {
			dispatch(notify({
				severity: 'info',
				description: 'Experience not available.',
			}));
			return { error, code: 404 };
		}
	},
);

const initialState = {
	experience: null,
	loading: false,
	errors: null,
	notFound: false,
};

const experienceByCodeSlice = createSlice({
	name: 'experienceByCode',
	initialState: { ...initialState },
	reducers: {
		reset: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
	},
	extraReducers: {
		[requestExperienceByCode.pending]: (state) => {
			state.errors = null;
			state.loading = true;
			state.notFound = false;
		},

		[requestExperienceByCode.rejected]: (state) => {
			state.loading = false;
			state.notFound = true;
		},

		[requestExperienceByCode.fulfilled]: (state, action) => {
			state.loading = false;
			state.errors = null;
			state.experience = action.payload;
			state.notFound = action.payload.code === 404;
		},
	},
});

const { name, reducer, actions } = experienceByCodeSlice;
const {
	reset,
} = actions;

export {
	reset,
};

export const getSlice = (state) => state[name];
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getNotFound = createSelector(getSlice, (slice) => slice?.notFound);
export const getExperience = createSelector(getSlice, (slice) => slice?.experience);

export default experienceByCodeSlice;

injectReducer(name, reducer);
