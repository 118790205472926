import React, { useEffect, useRef } from 'react';
import ProgressiveImage from 'react-progressive-image';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { applyContextMenu } from 'app/slices/app/context-menu';
import useExpandECR from 'features/ExperienceModal/useExpandECR';
import WaveSkeleton from 'components/WaveSkeleton';
import Avatar from 'rds/components/Avatar';

const TAP_TIME = 400;
const BORDER_GREDIENT = 'linear-gradient(180deg, #20D2B2 0%, #FB7848 50%, #CDBB0A 100%)';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		paddingTop: theme.spacing(),
		marginRight: theme.spacing(),
		width: '100%',
		height: '100%',
		textAlign: 'center',
	},
	item: {
		height: '100%',
	},
	gutterLeft: {
		paddingLeft: theme.spacing(1.5),
	},
	gutterRight: {
		paddingRight: theme.spacing(1.5),
	},
	image: {
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		objectFit: 'cover',
		width: '100%',
		height: '100%',
		borderRadius: 4,
	},
	title: {
		overflow: 'hidden',
		wordBreak: 'break-word',
		textAlign: 'left',
		textOverflow: 'ellipsis',
		lineClamp: 2,
		display: 'box',
		boxOrient: 'vertical',
	},
	title2: {
		overflow: 'hidden',
		wordBreak: 'break-word',
		textAlign: 'left',
		textOverflow: 'ellipsis',
		lineClamp: 2,
		display: 'box',
		boxOrient: 'vertical',
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
		marginRight: theme.spacing(),
		hyphens: 'auto',
	},
	container: {
		position: 'absolute',
		bottom: 4,
		left: 8,
		right: 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		marginLeft: theme.spacing(),
		width: '85%',
	},
	border: {
		width: 32,
		height: 32,
		borderRadius: '50%',
	},
	imageSkeleton: {
		background: 'linear-gradient(90deg, rgba(51, 59, 67, 0) 0%, #333B43 100%)',
	},
	titleSkeleton: {
		background: 'linear-gradient(90deg, rgba(51, 59, 67, 0) 0%, #333B43 100%)',
		marginTop: theme.spacing(),
	},
	gradient: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00255271) 8.9%, rgba(0, 0, 0, 0.00998942) 16.22%, rgba(0, 0, 0, 0.021978) 22.27%, rgba(0, 0, 0, 0.0381864) 27.32%, rgba(0, 0, 0, 0.0582825) 31.66%, rgba(0, 0, 0, 0.0819341) 35.6%, rgba(0, 0, 0, 0.108809) 39.4%, rgba(0, 0, 0, 0.138575) 43.37%, rgba(0, 0, 0, 0.170901) 47.79%, rgba(0, 0, 0, 0.205454) 52.95%, rgba(0, 0, 0, 0.241902) 59.14%, rgba(0, 0, 0, 0.279912) 66.65%, rgba(0, 0, 0, 0.319153) 75.78%, rgba(0, 0, 0, 0.359293) 86.79%, rgba(0, 0, 0, 0.4) 100%)',
		opacity: 0.8,
		marginLeft: theme.spacing(1.5),
		marginRight: theme.spacing(1.5),
		marginTop: theme.spacing(),
		borderRadius: 4,
	},
	avatarContainer: {
		position: 'absolute',
	},

}));

export default ({
	size,
	src,
	gutterLeft = true,
	gutterRight = true,
	longPressedOptions,
	uid,
	onClick,
	gutters,
	onTap,
	title,
	myIdentifiers,
	user,
	titleSkeleton,
	destination,
	...rest
}) => {
	const classes = useStyles();
	const expandECR = useExpandECR(uid, src);

	const ref = useRef();
	let klass = classes.root;

	if (gutterLeft) {
		klass = clsx(klass, classes.gutterLeft);
	}

	if (gutterRight) {
		klass = clsx(klass, classes.gutterRight);
	}

	useEffect(() => {
		if (ref?.current) {
			if (longPressedOptions) {
				const options = longPressedOptions.map((o) => ({
					label: o.label,
					onClick: () => {
						o.action(uid);
					},
				}));
				applyContextMenu(ref.current, options, {
					done: ({ ms }) => {
						if (ms < TAP_TIME) {
							onTap && onTap(uid);
						}
					},
				});
			} else if (onTap) {
				ref.current.ontouchend = () => {
					onTap && onTap(uid);
				};
			}
		}
	}, [ref]);

	if (destination) {
		let shared = false;
		myIdentifiers?.forEach(({ slug }) => {
			if (!Array.isArray(user?.identifiers)) {
				return;
			}

			const found = user.identifiers.findIndex(({ slug: uaSlug, rhino }) => uaSlug === slug && rhino === true) !== -1;
			if (found) {
				shared = true;
			}
		});

		const avatarSize = (user?.partner || shared) ? 'destination' : 'small';
		const avatarStyle = {};

		if (user?.partner || shared) {
			avatarStyle.left = '3px';
			avatarStyle.top = '3px';
		}

		return (
			<div className={klass} data-experience-modal-id={uid} onClick={expandECR} style={{ height: `${size}%`, paddingLeft: gutters?.gutterLeft, paddingRight: gutters?.gutterRight }} ref={ref} {...rest}>
				<ProgressiveImage src={src} placeholder={src}>
					{(s, loading) => (
						<>
							{loading
								? <WaveSkeleton />
								: (
									<>
										<img
											className={classes.image}
											src={s}
											alt=""
										/>
										<div className={classes.gradient} />
										<div className={classes.container}>
											<div>
												<div className={classes.avatarContainer}>
													<Avatar
														src={user?.avatar?.large?.jpg}
														size={avatarSize}
														alt="Username"
														style={avatarStyle}
													/>
												</div>
												<div
													className={classes.border}
													style={{
														background: user?.partner
															? '#59CCC8'
															: shared ? BORDER_GREDIENT : 'none',
													}}
												/>
											</div>
											<Typography className={classes.title2} variant="subtitle2">
												{title}
											</Typography>
										</div>
									</>
								)}

						</>
					)}
				</ProgressiveImage>
			</div>
		);
	}

	return (
		<div
			className={klass}
			onClick={expandECR}
			style={{
				height: `${size}%`,
				paddingLeft: gutters?.gutterLeft,
				paddingRight: gutters?.gutterRight,
			}}
			ref={ref}
			{...rest}
		>
			<div
				data-experience-modal-id={uid}
				className={classes.item}
			>
				<ProgressiveImage src={src} placeholder={src}>
					{(s, loading) => (
						<>
							{loading
								? <WaveSkeleton />
								: (
									<>
										<img
											className={classes.image}
											src={s}
											alt=""
										/>
									</>
								)}
							{titleSkeleton && loading
								? <WaveSkeleton style={{ marginTop: 4, height: 15 }} />
								: title ? (
									<Typography className={classes.title} variant="body2">
										{title}
									</Typography>
								) : null}
						</>
					)}
				</ProgressiveImage>
			</div>
		</div>
	);
};
