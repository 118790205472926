import React from 'react';
// import { useDispatch } from 'react-redux';
import {
	getProfile,
	requestV0Profile,
	getErrors,
	getLoading as getProfileLoading,
} from 'app/slices/profile';
import RhinoSkeleton from 'rds/components/Skeletons/PrimaryPage';
import RequestProvider from 'rds/providers/Request';
import useRdsTheme from 'rds/theme/useRdsTheme';
// import { endSession } from 'app/slices/authentication/session/session';
import SignUpCompletionForm from './CompletionForm';
import PageLayout from '../components/layout/PageLayout';

export default () => {
	// const dispatch = useDispatch();
	const theme = useRdsTheme('dark');

	return (
		<RequestProvider
			requests={{
				profile: {
					selector: getProfile,
					action: requestV0Profile,
					loading: getProfileLoading,
					errors: getErrors,
				},
			}}
			Skeleton={RhinoSkeleton}
		>
			<PageLayout
				theme={theme}
				// close={() => dispatch(endSession())}
				title={(
					<>
						explore.
						<br />
						connect. discover.
					</>
				)}
			>
				<SignUpCompletionForm theme={theme} />
			</PageLayout>
		</RequestProvider>
	);
};
