import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import ProgressiveThumbnail from 'rds/components/ProgressiveThumbnail';
import useClick from './useClick';
import ExperienceInfo from './ExperienceInfo';

const useStyles = makeStyles({
	expansionContainer: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
	},
	root: {
		position: 'absolute',
		zIndex: 2,
	},
});

export default ({
	avatar,
	background,
	destination,
	title,
	type,
	uid,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const [loaded, setLoaded] = useState(false);
	const onClick = useClick(uid, background, type);

	return (
		<div className={classes.expansionContainer} data-experience-modal-id={uid} onClick={onClick}>
			<ProgressiveThumbnail
				src={background}
				animation="wave"
				gradient
				onLoad={() => { setLoaded(true); }}
			/>
			<div className={classes.root}>
				<ExperienceInfo
					avatar={avatar}
					avatarSize="small"
					title={title}
					titleVariant="subtitle2"
					destination={destination}
					destinationVariant="body2"
					rootStyle={{
						alignItems: 'center',
						paddingLeft: theme.spacing(2),
						paddingRight: theme.spacing(2),
					}}
					skeletonWidth={32}
					skeletonHeight={32}
					loaded={loaded}
					pattern="big"
				/>
			</div>
		</div>
	);
};
