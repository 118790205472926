import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import requestFormRequest from 'services/request-form-request';
import { updateSession, clearMissingFields } from 'app/slices/authentication/session/session';
import { injectReducer } from 'services/store';
import ageGate from 'services/age-gate';
import { getFormPayload, setErrors } from './form';

const requestCompletion = createAsyncThunk(
	'requestCompletion',
	async (_, options) => {
		const state = options.getState();
		const formPayload = getFormPayload(state);
		const d = {};

		const [response, success] = await requestFormRequest(options, {
			method: 'PATCH',
			route: '/accounts/profile',
			getFormPayload: () => {
				if (formPayload.dateOfBirth) {
					if (ageGate(formPayload.dateOfBirth)) {
						return null;
					}
					d.dateOfBirth = formPayload.dateOfBirth;
				}

				if (formPayload.email) {
					d.email = formPayload.email;
				}

				if (formPayload.username) {
					d.username = formPayload.username.toLowerCase();
				}
				return d;
			},
			setErrors,
		});

		if (success) {
			await options.dispatch(clearMissingFields());
			await options.dispatch(updateSession(response.data));
		}

		return response.data;
	},
);
export const requestUpdate = requestCompletion;

export const completionSlice = createSlice({
	name: 'completion',
	initialState: {
		completed: false,
		errors: null,
		loading: false,
	},
	extraReducers: {
		[requestCompletion.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestCompletion.fulfilled]: (state) => {
			state.completed = true;
			state.loading = false;
			state.errors = null;
		},

		[requestCompletion.rejected]: (state, action) => {
			state.completed = false;
			state.loading = false;
			state.errors = action.payload;
		},
	},

});

const { name, reducer } = completionSlice;
export { name };

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);

injectReducer(name, reducer);
