import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	h4: {
		width: '100%',
		fontSize: '24px',
		fontWeight: 600,
		lineHeight: 1.25,
		letterSpacing: -0.08,
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(2),
		color: theme.colors.primary.C400,
		'&:hover': {
			color: theme.colors.primary.C600,
		},
	},
}));

export default ({ children, onClick }) => (
	<Typography
		classes={useStyles()}
		variant="h4"
		onClick={onClick}
	>
		{children}
	</Typography>
);
