/**
 * Get the duration of a media file (specifically WebM) in seconds.
 * This function works around an issue where the 'duration' property
 * of video tags is always 'Infinity' for media recorded WebM files.
 *
 * @param {HTMLMediaElement} mediaNode - The media node (either video or audio) to get the duration from.
 * @returns {Promise<number>} A Promise that resolves with the duration in seconds.
 */
function getWebmDuration(mediaNode) {
	return new Promise((resolve, reject) => {
		// Check if the provided node is either video or audio
		if (!(mediaNode instanceof HTMLVideoElement) && !(mediaNode instanceof HTMLAudioElement)) {
			reject(new Error('Invalid media node: must be an instance of HTMLVideoElement or HTMLAudioElement.'));
			return;
		}

		if (mediaNode.duration !== Infinity) {
			resolve(mediaNode.duration);
			return;
		}

		// Set the media node's volume to 0 to prevent any sound from being played
		const v = mediaNode.volume;
		mediaNode.volume = 0;

		const onceSeek = () => {
			// Check if the duration is not Infinity
			if (mediaNode.duration !== Infinity) {
				// Resolve the Promise with the duration
				resolve(mediaNode.duration);
				mediaNode.removeEventListener('seeked', onceSeek);
				mediaNode.pause();
				mediaNode.currentTime = 0;
				mediaNode.volume = v;
			}
		};

		// Add an event listener for the 'seek' event
		mediaNode.addEventListener('seeked', onceSeek, false);

		// Load the media file
		mediaNode.load();

		// Set a fake big time to trigger
		mediaNode.currentTime = 24 * 60 * 60;

		// Start playing the media file
		mediaNode.play();
	});
}

export default getWebmDuration;
