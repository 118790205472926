import React from 'react';
import ExperienceInfo from '../Components/ExperienceInfo';
import Activities from './Activities';
import RecommendedFor from './RecommendedFor';
import UserIdentities from './UserIdentities';

export default ({ ...props }) => (
	<>
		<ExperienceInfo experience={{ ...props }} showAvatar />
		<UserIdentities {...props} />
		<Activities {...props} />
		<RecommendedFor {...props} />
	</>
);
