/* eslint-disable no-constant-condition */
import storage from 'store';
import { apiHost, buildVersion } from 'config';

export default async (url, inputOptions = {}) => {
	const token = storage.get('token');
	const { requestType = 'log', includeToken, ...options } = inputOptions;

	if (url.slice(0, 4) !== 'http') {
		if (url[0] === '/' && apiHost[apiHost.length - 1] === '/') {
			url = url.slice(1);
		}
		url = `${apiHost}${url}`;
	}

	options.headers = options.headers || {};

	options.headers['x-timestamp'] = Date.now();

	options.headers['x-service-worker-request'] = requestType;

	if (includeToken && token && !options.headers.Authorization) {
		options.headers.Authorization = `Bearer ${token}`;
	} else if (token) {
		options.headers['x-deleteme'] = JSON.stringify({
			apiHost,
			token,
		});
	}

	if (options.json) {
		options.headers.Accept = options.headers.Accept || 'application/json';
		options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';

		if (Array.isArray(options.json)) {
			options.json = options.json.map((j) => ({
				buildVersion,
				...j,
			}));
		} else {
			options.json.buildVersion = buildVersion;
		}

		options.body = JSON.stringify(options.json);
		delete options.json;
	}
	try {
		await fetch(url, options);
	} catch (err) {
		console.error(err);
	}
};
