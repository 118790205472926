import React from 'react';
import useRdsTheme from 'rds/theme/useRdsTheme';
import Form from 'rds/components/Layouts/Form';
import { Link } from 'react-router-dom';
import PageLayout from '../components/layout/PageLayout';
import SubmitButton from '../components/SubmitButton/SubmitButton';

export default () => {
	const theme = useRdsTheme('dark');
	return (
		<PageLayout
			theme={theme}
			title="This link has expired"
		>
			<Form>
				<Link to="/landing">
					<SubmitButton>
						OK
					</SubmitButton>
				</Link>
			</Form>
		</PageLayout>
	);
};
