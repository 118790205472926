import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dot: {
		position: 'absolute',
		display: 'block',
		background: theme.colors.error.C400,
		borderRadius: '100%',
		top: 10,
		right: 22,
		height: 6,
		width: 6,
		zIndex: 12,
	},
}));

export default () => {
	const classes = useStyles();

	return <div className={classes.dot} />;
};
