import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

const dummyReducer = (state = {}) => state;

const rootReducer = {
	dummy: dummyReducer,
};

const store = configureStore({
	reducer: rootReducer,
	middleware: [
		...getDefaultMiddleware({
			serializableCheck: false,
		}),
		createLogger({
			collapsed: true,
		}),
	],
});

export const injectReducer = (key, reducer) => {
	rootReducer[key] = reducer;
	store.replaceReducer(combineReducers(rootReducer));
};

export default store;
