import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { notify } from 'app/slices/notifications';
import useNavigateReportModal from 'app/hooks/useNavigateReportModal';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		padding: theme.spacing(2),
	},
	middle: {
		position: 'fixed',
		display: 'flex',
		left: '50%',
		transform: 'translate(-50%, 0)',
		zIndex: 11,
	},
}));

export default ({
	label,
	submit,
	onSubmit,
	notificationTitle,
	disableSubmit,
	type,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const topLeftControl = submit ? 'Back' : 'Cancel';
	const reportNavigate = useNavigateReportModal();

	return (
		<div className={classes.root}>
			<Button
				disableRipple
				disableFocusRipple
				onClick={() => {
					if (submit) {
						reportNavigate('categories');
					} else {
						reportNavigate.exit(type);
					}
				}}
			>
				{topLeftControl}
			</Button>
			<Button>
				{label}
			</Button>
			<Button
				disableRipple
				disableFocusRipple
				disabled={disableSubmit}
				onClick={() => {
					if (disableSubmit) {
						return;
					}
					onSubmit();
					dispatch(notify({
						severity: 'info',
						description: notificationTitle,
					}));
					reportNavigate.exit();
				}}
			>
				{submit ? 'Submit' : undefined}
			</Button>
		</div>
	);
};
