import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';

export const requestSuggestedDestinations = createAsyncThunk(
	'requestGetSuggestedDestinations',
	async (_, { rejectWithValue }) => {
		try {
			const response = await api.get('destinations/suggested');
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},

);

const randomize = (arr) => {
	const ret = [];

	while (arr.length) {
		const no = Math.floor(Math.random() * arr.length);
		const v = arr.splice(no, 1);
		ret.push(v[0]);
	}

	return ret;
};

export const suggestedDestinationsSlice = createSlice({
	name: 'suggestedDestinations',
	initialState: {
		destinations: [],
		errors: null,
		loading: false,
	},
	extraReducers: {
		[requestSuggestedDestinations.pending]: (state) => {
			state.loading = true;
			state.errors = null;
		},

		[requestSuggestedDestinations.fulfilled]: (state, action) => {
			state.destinations = randomize(action.payload.destinations);
			state.loading = false;
			state.errors = null;
		},

		[requestSuggestedDestinations.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

const { name, reducer } = suggestedDestinationsSlice;

export const getSlice = (state) => state[name];
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);
export const getErrors = createSelector(getSlice, (slice) => slice?.errors);
export const getSuccess = createSelector(getSlice, (slice) => slice?.success);

export const getSuggestedDestinations = createSelector((state) => state, (state) => {
	const exceptions = state?.follow?.destinations?.map((d) => d.uid) || [];
	if (!state[name].destinations) {
		return null;
	}
	return state[name].destinations
		.filter((d) => exceptions.indexOf(d.uid) === -1);
});

injectReducer(name, reducer);
