import Store from './store';
import down from './down';
import move from './move';
import up from './up';

export default (canvas) => {
	const store = new Store();

	const embeddedDown = down(canvas, store);
	const embeddedMove = move(canvas, store);
	const embeddedUp = up(canvas, store);

	canvas.container.addEventListener('touchstart', embeddedDown, { passive: false });
	canvas.container.addEventListener('touchmove', embeddedMove, { passive: false });
	canvas.container.addEventListener('touchend', embeddedUp, { passive: false });
	canvas.container.addEventListener('touchcancel', embeddedUp);

	return [store, () => {
		canvas.container.removeEventListener('touchstart', embeddedDown);
		canvas.container.removeEventListener('touchmove', embeddedMove);
		canvas.container.removeEventListener('touchend', embeddedUp);
		canvas.container.removeEventListener('touchcancel', embeddedUp);
	}];
};
