module.exports = {
	C000: '#DAF9F5',
	C100: '#BAF6EE',
	C200: '#95F4EA',
	C300: '#6EE8E1',
	C400: '#59CCC8',
	C500: '#1BB6B6',
	C600: '#0F9599',
	C700: '#097D85',
	C800: '#07646E',
	C900: '#054751',
};
