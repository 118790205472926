import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import ThumbnailGrid from 'rds/components/ThumbnailGrid';
import {
	getAllExperiencesByDestination,
	getLoadingMore,
	getNextLink,
	getErrors,
} from 'app/slices/destination-account-v2/experiences/selectors';
import {
	requestLoadMoreExperiencesByDestination,
	requestAllExperiencesByDestination,
} from 'app/slices/destination-account-v2/experiences/thunks';
import { getMyIdentifiers } from 'app/slices/user-account-v2/accounts/selectors';
import useExperiencesThumbnail from 'app/hooks/useExperiencesThumbnail';
import ErrorComponent from './ErrorComponent';

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft:	theme.spacing(1.5),
		marginRight: theme.spacing(1.5),
		marginTop: theme.spacing(3),
		paddingBottom: theme.spacing(20),
	},
	errorRoot: {
		marginLeft:	theme.spacing(3),
		marginRight: theme.spacing(3),
	},
	spacer: {
		height: 0,
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const data = useSelector(getAllExperiencesByDestination);
	const identifiers = useSelector(getMyIdentifiers);
	const experiences = useExperiencesThumbnail(data);
	const nextLink = useSelector(getNextLink);
	const loading = useSelector(getLoadingMore);
	const error = useSelector(getErrors);

	if (error) {
		return (
			<div className={classes.errorRoot}>
				<ErrorComponent
					show={error}
					title="Unable to load experiences"
					onRetry={() => { dispatch(requestAllExperiencesByDestination()); }}
					size="large"
				/>
			</div>
		);
	}
	if (!experiences.length && !error) {
		return null;
	}

	return (
		<div className={classes.root}>
			<ThumbnailGrid
				items={experiences}
				onLongPress={() => {}}
				loading={loading}
				requestLoadMore={nextLink ? requestLoadMoreExperiencesByDestination : null}
				destination
				myIdentifiers={identifiers}
				title
			/>
		</div>
	);
};
