import { createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

let theme = 'dark';

if (window.localStorage && window.localStorage.getItem('theme')) {
	theme = window.localStorage.getItem('theme');
}

const themeProviderSlice = createSlice({
	name: 'theme',
	initialState: {
		theme,
	},
	reducers: {
		change: (state, action) => {
			state.theme = action.payload;
			window.localStorage.setItem('theme', state.theme);
		},
	},
});

const { name, reducer, actions } = themeProviderSlice;
const { change } = actions;

export { name, change };

export const getSlice = (state) => state[name];
export const getTheme = createSelector(getSlice, (slice) => slice?.theme);

export const THEMES = ['light', 'dark'];

export default themeProviderSlice;

injectReducer(name, reducer);
