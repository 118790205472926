import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useState } from 'react';

const TIME_OUT = 100;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginBottom: theme.spacing(6),
		marginTop: theme.spacing(8),
	},
	form: {
		height: 48,
		border: '1px solid #3E454C',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
	},
	menu: {
		position: 'absolute',
		left: theme.spacing(4),
		right: theme.spacing(4),
		background: theme.colors.neutral.C900,
		zIndex: 2,
	},
	menuItem: {
		display: 'flex',
		alignItems: 'center',
		height: 48,
		paddingLeft: theme.spacing(4),
		'&:hover, &:focus': {
			background: theme.colors.neutral.C700,
		},
	},
}));

export default ({ value, options, onChange }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	const backgroundColor = open ? theme.colors.neutral.C900 : null;

	return (
		<div className={classes.root}>
			<div
				className={classes.form}
				style={{ background: backgroundColor }}
				onClick={() => {
					setOpen(!open);
				}}
			>
				<Typography variant="subtitle2">
					{value?.title}
				</Typography>
				{open
					? <ExpandLess />
					: <ExpandMore />}
			</div>
			{open
				&& (
					<div className={classes.menu}>
						{options.map((o, i) => (
							<div
								className={classes.menuItem}
								key={i}
								onClick={() => {
									onChange(o);
									setTimeout(() => {
										setOpen(false);
									}, TIME_OUT);
								}}
							>
								<Typography variant="subtitle2">
									{o.title}
								</Typography>
							</div>
						))}
					</div>
				)}
		</div>
	);
};
