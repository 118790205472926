import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import * as iconsIndex from './iconsindex';

const useStyles = makeStyles((theme) => {
	const classes = {
		ds: {
			filter: 'drop-shadow(0px 1px 2px rgba(50, 56, 62, 0.25))',
		},
	};

	Object.keys(theme.colors).forEach((color) => {
		Object.keys(theme.colors[color]).forEach((shade) => {
			classes[`${color}${shade}`] = {
				'& path': {
					fill: theme.colors[color][shade],
				},
			};
		});
	});

	return classes;
});

export default ({
	icon,
	color,
	dropShadow = false,
	gredient = false,
	size,
}) => {
	const classes = useStyles();
	const Component = iconsIndex[icon];

	if (!Component) {
		return <>no icon</>;
	}

	const klass = dropShadow
		? clsx(classes[color], classes.ds)
		: gredient ? {} : classes[color];

	return (
		<Component
			className={klass}
			height={size}
			width={size}
		/>
	);
};
