import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import handleSliceError from 'utils/handle-slice-error';

export const requestMyExperiences = createAsyncThunk(
	'requestMyExperiences',
	async (_, { rejectWithValue, getState }) => {
		try {
			const { session } = getState();
			const response = await api.get(`experiences/users/${session.session.uuid}?limit=${12}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestMergeMyExperiences = createAsyncThunk(
	'requestMergeMyExperiences',
	async (_, { rejectWithValue, getState }) => {
		try {
			const { session } = getState();
			const response = await api.get(`experiences/users/${session.session.uuid}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestLoadMoreMyExperiences = createAsyncThunk(
	'requestLoadMoreMyExperiences',
	async (_, { rejectWithValue, getState }) => {
		const { next } = getState().userAccountV2MyExperiences;

		if (!next) {
			return null;
		}

		try {
			const response = await api.get(`${next}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestDeleteMyExperience = createAsyncThunk(
	'requestDeleteMyExperience',
	async (experience, { rejectWithValue }) => {
		try {
			const response = await api.delete(`experiences/${experience.uid}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);
