import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import WaveSkeleton from 'components/WaveSkeleton';
import { getLoadingSingleExperience } from 'app/slices/experience-modal';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'end',
		zIndex: 15,
		position: 'absolute',
		right: 0,
		left: 0,
		bottom: 0,
	},
	comment: {
		...theme.typography.body1,
		'&::placeholder': {
			color: '#00000',
		},
		outline: 'none',
		whiteSpace: 'pre-wrap',
		minHeight: 44,
		borderRadius: 24,
		width: '100%',
		background: theme.colors.neutral.C900,
		position: 'relative',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(6),
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginRight: window.innerWidth < 390 ? theme.spacing(15) : theme.spacing(18),
		padding: '11px 36px 11px 12px',
	},

}));

export default ({ openPanel }) => {
	const classes = useStyles();
	const loading = useSelector(getLoadingSingleExperience);

	return (
		<div
			className={clsx(classes.root, 'messages', 'ecr-dismiss-quickfade')}
			data-ignoreslider="true"
		>
			{loading
				? (
					<WaveSkeleton
						style={{
							height: 44,
							borderRadius: 22,
							marginRight: 24,
							marginLeft: 24,
							marginBottom: 24,
						}}
					/>
				)
				: (
					<div
						placeholder="Comment"
						className={clsx('comment', classes.comment)}
						onClick={() => openPanel('add-comments')}
					/>
				)}
		</div>
	);
};
