import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMatch } from 'utils/look';
import textFit from 'utils/text-fit';
import {
	close,
	removeNode,
	set,
	selectEditingNode,
	selectRecommendedValue,
	selectRecommendationTriggers,
} from 'app/slices/content-creation/create/text-component';
import { selectEditingCanvas } from 'app/slices/content-creation/editing-canvas';
import Composer from 'lib/composer';
import { change } from 'app/slices/content-creation/editor/layout';
import { getRegions } from 'lib/editing-canvas-compile';
import { FOCUS_STYLES } from '../Create/TextComponent/styles';

const TRANSITION_TIMER = 500;
const TIME_OUT = 100;
// const TRANSITION = '0.5s';
const IGNORE_STYLES = ['transition', 'height', 'width', 'color'];

const getWidthOfInput = (el) => {
	const temporaryElement = el.cloneNode(true);
	temporaryElement.style.position = 'relative';
	temporaryElement.style.display = 'inline-block';
	temporaryElement.style.removeProperty('width');
	temporaryElement.style.visibility = 'hidden';
	el.parentElement.appendChild(temporaryElement);
	const { width, height } = temporaryElement.getBoundingClientRect();
	try {
		el.parentElement.removeChild(temporaryElement);
	} catch (_) {
		// Safe to ignore error
	}

	return { width: Math.ceil(width), height: Math.ceil(height) };
};

export default ({
	editingCanvas,
}) => {
	const dispatch = useDispatch();
	editingCanvas = editingCanvas || useSelector(selectEditingCanvas);
	const node = useSelector(selectEditingNode);
	const recommendationTriggers = useSelector(selectRecommendationTriggers);
	const recommendedValue = useSelector(selectRecommendedValue);
	const containerRef = useRef();

	useEffect(async () => {
		if (node) {
			const { el, id } = node;

			if (!el.composer) {
				el.composer = new Composer(el);
			}

			clearTimeout(editingCanvas.backgroundTo);
			clearTimeout(editingCanvas.closeTo);

			dispatch(change({ showControls: false }));

			const localStyles = {};

			Object.keys(FOCUS_STYLES).forEach((key) => {
				if (IGNORE_STYLES.indexOf(key) === -1) {
					localStyles[key] = el.style[key];
				}
				el.style[key] = FOCUS_STYLES[key];
			});

			const removeTextFit = textFit(el, {
				fontSize: 32,
				maxHeight: () => window.innerHeight - 72,
			});

			el.removeAttribute('disabled');

			editingCanvas.disableGestures();

			const overlay = await editingCanvas.find(editingCanvas.overlay.item.id);
			overlay.style.display = 'block';
			overlay.style.zIndex = editingCanvas.store.Z_INDEX_SERIAL - 1;
			overlay.ontouchend = () => {
				closeIt();
			};

			editingCanvas.container.ontouchend = (e) => {
				if (e.target !== el) {
					closeIt();
				}
			};

			el.onkeydown = (e) => {
				if (e.which === 13) {
					e.preventDefault();
					closeIt();
				}
			};

			el.onkeyup = () => {
				const match = getMatch(
					el.textContent,
					el.textContent.length - 1,
					recommendationTriggers,
					/\s/,
				);

				dispatch(set({
					match: match || null,
				}));
			};

			const closeIt = (e) => {
				el.onblur = null;
				if (!e) {
					el.blur();
				}
				removeTextFit();
				overlay.style.display = 'none';

				el.innerHTML = el.innerHTML.trim();
				const trimmedContents = el.textContent.trim();

				if (trimmedContents) {
					const newWidth = getWidthOfInput(el).width;
					const left = Number(localStyles.left.slice(0, -2));
					if (left + newWidth > window.innerWidth) {
						const viewPortDiff = left + newWidth - window.innerWidth;
						localStyles.left = `${left - viewPortDiff}px`;
					}
					el.style.width = `${newWidth}px`;
				}

				editingCanvas.enableGestures();
				window.onresize = null;
				el.onblur = null;
				el.onfocus = null;
				el.onkeydown = null;
				editingCanvas.container.ontouchend = null;

				if (!trimmedContents) {
					editingCanvas.remove(id);
					dispatch(removeNode({ id }));
					dispatch(change({ showControls: true }));
					return;
				}

				const specialNodes = el.composer ? el.composer.specialNodes : undefined;
				editingCanvas.update(id, { el, specialNodes });
				const { width } = getWidthOfInput(el);
				Object.keys(localStyles).forEach((key) => {
					if (key === 'z-index' || key === 'height' || key === 'width') {
						return;
					}
					if (!!trimmedContents && !localStyles.left) {
						localStyles.left = `calc(50% - ${width / 2}px`;
					}
					el.style[key] = localStyles[key];
				});

				editingCanvas.backgroundTo = setTimeout(() => {
					dispatch(change({ showControls: true }));
				}, TIME_OUT);

				editingCanvas.closeTo = setTimeout(() => {
					el.style.removeProperty('transition');
					el.style['z-index'] = localStyles['z-index'];
					el.setAttribute('disabled', true);
					dispatch(close());
					window.ec = editingCanvas;
				}, TRANSITION_TIMER);
				dispatch(close());
			};

			const onblur = closeIt;

			el.onblur = onblur;
			el.focus();
			const onResize = () => {
				el.style.transition = '0.1s top linear';
				el.triggerTextFit();
				setTimeout(() => {
					el.style.removeProperty('transition');
				}, 100);
				window.visualViewport.removeEventListener('resize', onResize);
			};
			window.visualViewport.addEventListener('resize', onResize);
		}
	}, [node, containerRef.current]);

	useEffect(() => {
		if (!recommendedValue || !node) {
			return;
		}

		const { el } = node;
		if (!el.composer) {
			el.composer = new Composer(el);
		}

		const { start, end } = recommendedValue.match.position;
		let value = el.textContent[end] !== ' ' ? recommendedValue.value : recommendedValue.value;

		const trailing = el.textContent.slice(end);
		if (!trailing.length) {
			value += ' ';
		} else if (trailing[0] !== ' ') {
			value += ' ';
		}

		const str = `${el.textContent.slice(0, start)}${value}${trailing}`;

		if (el.composer) {
			el.composer.string = str;
			el.composer.addNode({
				type: 'ccMention',
				contents: `${recommendedValue.match.prefix}${recommendedValue.value}`,
				data: { uuid: recommendedValue.uuid },
			});

			el.composer.placeCursorPosition(start + value.length);
			window.getRegions = () => getRegions(editingCanvas, true);
		}
		el.triggerTextFit();

		dispatch(set({
			recommendedValue: null,
			match: null,
		}));
	}, [node, recommendedValue]);

	return (
		<div />
	);
};
