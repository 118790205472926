import React from 'react';
import fonts from 'utils/fonts-array';

export default ({ children }) => (
	<>
		{children}
		{
			fonts.map((f, i) => (
				<style type="text/css" key={i}>
					{
						`@font-face {
					font-family: "${f.family}";
					src: url("data:application/x-font-ttf;base64,${f.encoded}")
				}`
					}
				</style>
			))
		}
	</>
);
