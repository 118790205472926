import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const getViewportHeight = () => window.innerHeight * 0.01;

const useStyles = makeStyles({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
});

export default ({ src }) => {
	const classes = useStyles();
	const viewportHeight = getViewportHeight();

	useEffect(() => {
		document.documentElement.style.setProperty('--viewport-height', `${viewportHeight}px`);
	}, [viewportHeight]);

	return (
		<div
			className={classes.root}
			style={{
				backgroundImage: `url(${src})`,
				height: 'calc(var(--viewport-height) * 100)',
			}}
		/>
	);
};
