import React from 'react';
import * as formSlice from 'app/slices/authentication/signup/form';
import * as apiSlice from 'app/slices/authentication/signup/register';
import Form from './Form';

export default ({ theme, ...rest }) => (
	<Form
		theme={theme}
		apiSlice={apiSlice}
		formSlice={formSlice}
		disabledCheck={({
			email, emailErr,
			username, usernameErr,
			password, passwordErr,
			dateOfBirth,
		}) => !(email && !emailErr
			&& username && !usernameErr
			&& password && !passwordErr
			&& dateOfBirth
		)}
		{...rest}
	/>
);
