const event = (params) => {
	if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
		if (params.action && params.eventKey) {
			window.gtag('event', params.action, {
				event_category: params.event_category || 'Affiliate Link',
				event_label: params.eventKey,
				value: params.value,
			});
		} else if (params.name) {
			const eventLabel = params.uid ? `uid: ${params.uid}` : (params.uuid ? `uuid: ${params.uuid}` : 'No ID');
			window.gtag('event', params.name, {
				event_category: 'Custom Event',
				event_label: eventLabel,
				value: params.value,
			});
		}
	}
};

export default event;
