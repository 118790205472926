import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import Notifications from 'features/Notifications';
import localStore from 'store';
import expirePlugin from 'store/plugins/expire';
import ErrorBoundary from 'rds/components/ErrorBoundary';
import ThemeProvider from 'rds/theme';
import { initFromApp } from 'app/android-service';
import UseFonts from 'app/hooks/useFonts';
import store from 'services/store';
import Router from 'Router';
import ExperienceModal from 'features/ExperienceModal/ExperienceModal';
import NoConnectionWrapper from 'components/NoConnectionWrapper';
import * as serviceWorkerRegistration from 'service-worker-registration';
import ReportModal from 'features/ReportModal';
import { NavbarStateProvider } from 'app/hooks/useNavbarState';
import AccountOverflowMenus from 'components/AccountOverflowMenus';
import 'index.css';
import DeveloperBanner from 'components/DeveloperBanner';
import VerificationError from 'components/Navigation/VerificationError';
import GoogleAnalytics from 'components/GoogleAnalytics';

initFromApp();
localStore.addPlugin(expirePlugin);

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s',
		s: 'now',
		m: '1m',
		mm: '%dm',
		h: '1h',
		hh: '%dh',
		d: '1d',
		dd: '%dd',
		M: '1mo',
		MM: '%dmo',
		y: '1y',
		yy: '%dy',
	},
});

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider>
			<CssBaseline />
			<UseFonts>
				<MuiPickersUtilsProvider utils={dayjsUtils}>
					<NoConnectionWrapper>
						<NavbarStateProvider>
							<ErrorBoundary>
								<Suspense fallback={null}>
									<BrowserRouter>
										<Router />
										<ExperienceModal />
										<AccountOverflowMenus />
										<ReportModal />
										<DeveloperBanner />
										<VerificationError />
									</BrowserRouter>
									<Notifications />
									<GoogleAnalytics />
								</Suspense>
							</ErrorBoundary>
						</NavbarStateProvider>
					</NoConnectionWrapper>
				</MuiPickersUtilsProvider>
			</UseFonts>
		</ThemeProvider>
	</Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const onUpdate = async () => {
	if ('caches' in window) {
		localStorage.setItem('latest_version_deployed', Date.now());
		const names = await caches.keys();
		const keys = Object.keys(names);
		for (let i = 0; i < keys.length; i++) {
			await caches.delete(names[keys[i]]);
		}
	}
	// can show something to say app is updated
};

serviceWorkerRegistration.register({
	onSuccess: onUpdate,
	onUpdate,
});
