import React, {
	useRef, useEffect, useState, createRef,
} from 'react';
import { matchRoutes, useNavigate } from 'react-router-dom';
import { usePageState } from 'Router/PageContext';
import { getRegisteredRoutes, getRegisteredRootRoutes } from 'Router/registry';
import NavigationMenu from 'components/Navigation/Menu';
import { useQueryParam } from 'app/hooks/useQueryParams';
import ShowHide from './ShowHide';

const rootRoutes = getRegisteredRootRoutes();

/**
 * RootRouter Component
 *
 * This component serves as the primary router for handling "root pages" within the application.
 *
 * Key Features:
 *
 * 1. Persistent Components: It uses a "renderMap" to persistently store root page components.
 *    This ensures that, when navigating between root pages, their state (and importantly, their scroll position)
 *    is preserved and they don't need to be re-rendered from scratch.
 *
 * 2. Scroll Position Memory: The component memorizes the last scroll position of each root page.
 *    This is achieved using the `useScrollState` hook. When navigating back to a previously visited root page,
 *    the component automatically scrolls to the last known position, providing a smoother user experience.
 *
 * 3. Conditional Rendering: Although all visited root page components are stored in memory, only the
 *    currently active page is visibly rendered using the `ShowHide` component. Other pages, although loaded,
 *    are hidden from view, ensuring efficient performance.
 *
 * 4. Navigation Menu Handling: Depending on which route is matched, the corresponding navigation menu is
 *    displayed to the user. This is handled via a combination of `rootMatch` and `otherMatch`.
 *
 * Usage & Assumptions:
 * - Root pages are a distinct subset of pages within the app that require special handling, as detailed above.
 * - The component uses hooks from the './hooks' directory to acquire necessary data like matched routes and scroll state.
 * - It assumes that all routes provided have a 'navigationMenu' property to determine menu rendering.
 *
 */

const MemoizedRenderedComponents = React.memo(({ renderMap, renderKey, rootMatch }) => (
	<>
		{Object.keys(renderMap.current).map((key) => {
			const shouldRender = rootMatch && key === renderKey;

			const {
				// scrollState,
				Component,
				transitionRef,
			} = renderMap.current[key];

			return (
				<ShowHide key={key} transitionRef={transitionRef} show={shouldRender}>
					{React.createElement(Component, {
						show: !!(shouldRender),
					})}
				</ShowHide>
			);
		})}
	</>
));

export default function RootPages({
	transition,
	// transitionDuration,
}) {
	const navigate = useNavigate();
	// const { scrollY, setScrollTop } = useScrollState();
	const transitionContainerRef = useRef();
	const [lastActiveString, setLastActiveString] = useState();

	const { pathname } = usePageState();

	const routes = matchRoutes(rootRoutes, pathname);
	const rootMatch = routes ? routes[0] : null;

	const otherRoutes = getRegisteredRoutes();
	const otherMatch = matchRoutes(otherRoutes, pathname);
	const experienceUid = useQueryParam('experience');

	// This ref is used to store Components and their respective scroll states.
	const renderMap = useRef({});

	// State to keep track of the currently rendered root page.
	const [renderKey, setRenderKey] = useState(null);

	// Determine which navigation menu to show.
	const navigationMenu = rootMatch
		? rootMatch.route.navigationMenu
		: otherMatch ? otherMatch[0]?.route?.navigationMenu : false;

	useEffect(() => {
		if (!rootMatch && otherMatch && otherMatch[0]?.route?.rootRedirect) {
			navigate(otherMatch[0]?.route?.rootRedirect, {
				replace: true,
			});
		}
	}, []);

	const hideAll = () => {
		const all = Object.keys(renderMap.current);

		all.forEach((key) => {
			if (lastActiveString === key) {
				return;
			}

			const { transitionRef } = renderMap.current[key];
			transitionRef.current.style.opacity = '0%';

			setTimeout(() => {
				if (transitionRef.current) {
					transitionRef.current.style.height = '0';
					transitionRef.current.style.width = '0';
					transitionRef.current.style.removeProperty('min-height');
				}
			}, 100);
			transitionRef.current.style.overflow = 'hidden';
			transitionRef.current.style.pointerEvents = 'none';
			renderMap.current[key].showing = false;
		});
	};

	const show = (rk) => {
		const all = Object.keys(renderMap.current);

		all.forEach((key) => {
			const { transitionRef, showing, scrollState } = renderMap.current[key];
			//
			if (transitionRef.current) {
				if (key === rk) {
					if (!showing) {
						transitionRef.current.style.opacity = '100%';
						transitionRef.current.style.pointerEvents = 'auto';
						transitionRef.current.style.removeProperty('height');
						transitionRef.current.style.removeProperty('width');
						transitionRef.current.style.removeProperty('overflow');
						transitionRef.current.style.minHeight = `${scrollState + window.innerHeight}px`;

						// setScrollTop(scrollState);
						// window.scrollTo(0, scrollState);
						renderMap.current[key].showing = true;
						setTimeout(() => {
							transitionRef.current.style.removeProperty('min-height');
						}, 100);
					}
				} else if (showing) {
					transitionRef.current.style.opacity = '0%';
					transitionRef.current.style.height = '0';
					transitionRef.current.style.width = '0';
					transitionRef.current.style.removeProperty('min-height');
					transitionRef.current.style.overflow = 'hidden';
					transitionRef.current.style.pointerEvents = 'none';
					renderMap.current[key].showing = false;
				}
			}
		});
	};

	useEffect(() => {
		// If the current page is not a root page, do nothing.
		if (!rootMatch) {
			hideAll();
			return;
		}

		// Store the scroll state of the previous root page.
		// if (renderKey && renderMap.current[renderKey]) {
		// 	renderMap.current[renderKey].scrollState = scrollY;
		// }

		const newRenderKey = rootMatch?.route?.path;

		// If the page has not been rendered before, initialize it in the renderMap.
		if (!renderMap.current[newRenderKey]) {
			renderMap.current[newRenderKey] = {
				Component: rootMatch.route.Component,
				// scrollState: 0,
				transitionRef: createRef(),
			};
		}

		// Update the renderKey if it has changed.
		if (newRenderKey !== renderKey) {
			setRenderKey(newRenderKey);
		}
		show(newRenderKey);
	}, [renderKey, rootMatch]);

	useEffect(() => {
		if (navigationMenu && typeof navigationMenu === 'string') {
			setLastActiveString(navigationMenu);
		}
	}, [navigationMenu, setLastActiveString]);

	useEffect(() => {
		const node = transitionContainerRef.current;
		let to;

		if (node) {
			const className = transition === 'dismiss'
				? 'page-root'
				: rootMatch
					? 'page-root root-transition'
					: `page-root root-transition ${transition}-exit ${transition}-exit-active`;

			node.setAttribute('class', className);

			if (experienceUid && transition === 'slide') {
				node.style.display = 'none';
				setTimeout(() => {
					node.style.display = 'block';
				}, 600);
			}

			const fixedNodes = node.querySelectorAll('[data-fix-position-fixed="true"]');
			if (fixedNodes.length) {
				const pr = document.querySelector('.page-root');

				fixedNodes.forEach((f) => {
					if (transition === 'slide' && rootMatch) {
						f.style.top = 0;
					} else if (transition === 'slide') {
						f.style.top = `${pr.scrollTop}px`;
					} else {
						f.style.top = 0;
					}
				});
			}

			// Clear the transition if its a root
			clearTimeout(to);
			if (rootMatch && transition) {
				to = setTimeout(() => {
					node.setAttribute('class', 'page-root');
				}, 400);
			}
		}
	}, [rootMatch, transition, transitionContainerRef, experienceUid]);

	return (
		<>
			<NavigationMenu active={navigationMenu} lastActiveString={lastActiveString} show={!!navigationMenu} />
			<div ref={transitionContainerRef}>
				<MemoizedRenderedComponents renderMap={renderMap} renderKey={renderKey} rootMatch={rootMatch} />
			</div>
		</>
	);
}
