import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	loadGapis,
	googleSignIn,
	getLoading,
	getRequesting,
} from 'app/slices/authentication/google/google';
import SocialButton from 'rds/components/Button/SocialButton';
import logo from '../assets/social/google/g.svg';

export default ({ variant }) => {
	const dispatch = useDispatch();
	const loading = useSelector(getLoading);
	const requesting = useSelector(getRequesting);

	useEffect(() => {
		dispatch(loadGapis());
	}, []);

	const isDisabled = requesting || loading;

	return (
		<SocialButton
			disabled={isDisabled}
			variant={variant}
			onClick={() => {
				if (isDisabled) {
					return;
				}
				dispatch(googleSignIn({ redirect: '/' }));
			}}
			icon={logo}
		>
			Google
		</SocialButton>
	);
};
