import { AUDIO } from '../../types';
import BaseNode from './base';

export default class AudioNode extends BaseNode {
	constructor(canvas, item) {
		super(canvas, item);
		this.type = AUDIO;
		this.ext = 'mp3';
	}

	get audio() {
		return this.data.blob;
	}

	get dto() {
		return this.data.blob;
	}

	drawFrame(t) {
		if (t === 0) {
			this.el.play();
		}

		if (t >= this.drawContext.nextReset) {
			this.drawContext.nextReset += this.data.resetTimer;
			this.el.currentTime = 0;
			this.el.play();
		}
	}

	pauseAudio() {
		this.el.pause();
	}

	playAudio(currentTime) {
		if (typeof currentTime !== 'undefined') {
			this.el.currentTime = currentTime;
		}
		this.el.play();
	}

	setupDraw() {
		return new Promise((resolve) => {
			this.drawContext = {
				nextReset: this.data.resetTimer,
			};

			this.el = new Audio(URL.createObjectURL(this.data.blob));
			this.el.loop = true;
			this.el.oncanplaythrough = () => {
				resolve();
			};
		});
	}

	renderFrame() { }
}
