import dayjs from 'dayjs';

const YEARS = 13;

export default (dateOfBirth) => {
	const diff = dayjs().diff(dateOfBirth, 'year');

	if (diff < YEARS) {
		window.location.href = '/signup/failure?code=3';
		return true;
	}

	return false;
};
