import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import ProgressiveImage from 'react-progressive-image';

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 2,
		background: 'linear-gradient(#B4DFDC 0%, #9FD0CC 81.86%, #B4DFDC 96.43%);',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		width: '100%',
		paddingBottom: '20.5%',
		position: 'relative',
		paddingTop: theme.spacing(0.5),
		marginBottom: theme.spacing(3),
		filter: 'drop-shadow(0px 4px 8px rgba(35, 47, 46, 0.25))',
	},
	title: {
		position: 'absolute',
		bottom: theme.spacing(5),
		left: theme.spacing(2),
		zIndex: 2,
		color: theme.colors.neutral.C000,
	},
	subtitle: {
		position: 'absolute',
		left: theme.spacing(2),
		bottom: theme.spacing(),
		zIndex: 2,
		color: theme.colors.neutral.C000,
	},
	cover: {
		background: 'radial-gradient(102.94% 2428.57% at 101.47% 50%, rgba(27, 36, 45, 0) 0%, rgba(27, 36, 45, 0.2) 36.55%, rgba(27, 36, 45, 0.4) 67.99%, rgba(27, 36, 45, 0.6) 100%)',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
		borderRadius: 2,
	},
	background: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		transition: 'opacity 0.3s ease-in-out',
		position: 'absolute',
		top: 0,
	},
}));

export default ({
	title,
	subtitle,
	onSelect,
	suggestedThumbnail,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root} onClick={onSelect}>
			<Typography ds variant="subtitle2" className={classes.title}>
				{title}
			</Typography>
			<Typography ds variant="caption" className={classes.subtitle}>
				{subtitle}
			</Typography>
			<div className={classes.cover} />
			<ProgressiveImage src={suggestedThumbnail?.large.jpg} placeholder="">
				{(src, loading) => (
					<img
						src={src}
						alt=""
						className={classes.background}
						style={{
							opacity: loading ? 0 : 1,
						}}
					/>
				)}
			</ProgressiveImage>
		</div>
	);
};
