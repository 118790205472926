import { GOP } from '../../types';
import createElement from '../../utils/create-element';
import BaseNode from './base';

export default class GOPNode extends BaseNode {
	constructor(canvas, item) {
		super(canvas, item);

		this.images = [];

		this.type = GOP;
		this.el = createElement({
			tagName: 'div',
		});
		this.el.style.position = 'absolute';
		this.el.style.zIndex = item.z;

		Object.keys(this.styles).forEach((k) => {
			this.el.style[k] = this.styles[k];
		});

		for (let i = 0; i < this.data.canvases.length; i++) {
			this.data.canvases[i].style.height = '100%';
			this.data.canvases[i].style.width = '100%';
		}
		this.setAttributes(this.el);
	}

	get audio() {
		return this.data.audio;
	}

	get dto() {
		return this.data.canvases;
	}

	get _framerate() {
		return 1000 / this.data.fps;
	}

	_selectIndex(t) {
		return Math.floor(t / this._framerate) % this.data.canvases.length;
	}

	drawFrame(t) {
		const index = this._selectIndex(t);
		const image = this.data.canvases[index];

		this.el.innerHTML = '';
		this.el.appendChild(image);

		if (index === 0 && this.audioEl && this.audioEl.currentTime > 0.25) {
			this.audioEl.currentTime = 0;
		}

		if (!this.audioEl.playing) {
			this.audioEl.play();
		}

		if (this.drawn) {
			return;
		}
		this.el.setAttribute('data-object-id', this.id);
		this.canvas.container.appendChild(this.el);
		this.drawn = true;
	}

	async setupDraw() {
		return new Promise((resolve) => {
			if (!this.data.audio) {
				resolve();
				return;
			}

			this.drawContext = {
				nextReset: this.data.resetTimer,
			};

			this.audioEl = new Audio(URL.createObjectURL(this.data.audio));
			this.audioEl.loop = true;
			this.audioEl.oncanplaythrough = () => {
				resolve();
			};
		});
	}

	renderFrame(t) {
		const index = this._selectIndex(t);
		const image = this.data.canvases[index];
		const { context, dimensions, offset } = this.compileContext;
		const { x, y } = offset;
		const { height, width } = dimensions;

		context.drawImage(image,
			x,
			y,
			width,
			height);
	}

	setupCompile(canvas, context, shadowContainer) {
		return new Promise((resolve) => {
			this.data.canvases[0].toBlob((blob) => {
				const img = new Image();
				img.onload = () => {
					const el = this.el.cloneNode(true);
					shadowContainer.appendChild(el);
					el.appendChild(img);
					const { x, y } = el.getBoundingClientRect();
					const { height, width } = img.getBoundingClientRect();

					this.compileContext = {
						canvas,
						context,
						dimensions: { height, width },
						offset: { x, y },
					};
					resolve();
				};
				img.style.height = '100%';
				img.style.width = '100%';
				img.src = URL.createObjectURL(blob);
			}, 'image/png');
		});
	}
}
