import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import {
	requestLoadMoreIndexExperiences,
	getLoading,
	getNext,
	getIndexExperiences,
	getHeaderText,
} from 'app/slices/index';
import useExperiencesThumbnail from 'app/hooks/useExperiencesThumbnail';
import { requestPatchOnboarding } from 'app/slices/onboarding/onboarding';
import useOnboarding from 'app/hooks/useOnboarding';
import Masonry from './Masonry';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(12),
	},
	spacer: {
		paddingTop: theme.spacing(4),
	},
}));

const Home = ({ show }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const next = useSelector(getNext);
	const data = useSelector(getIndexExperiences);
	const loading = useSelector(getLoading);
	const experiences = useExperiencesThumbnail(data);
	const headerText = useSelector(getHeaderText);
	const onboarding = useOnboarding();

	useEffect(() => {
		if (
			show
			&& pathname !== '/my-account'
			&& onboarding.firstVisit
			&& onboarding.activities
			&& onboarding.destinations
			&& onboarding.regions
		) {
			dispatch(requestPatchOnboarding({
				firstVisit: false,
			}));
		}
	}, [show, pathname, onboarding]);

	return (
		<div
			className={classes.root}
			style={{
				paddingTop: headerText ? 160 : 35,
			}}
		>
			<Masonry
				items={experiences}
				hasNext={next}
				reachedEnd={() => {
					dispatch(requestLoadMoreIndexExperiences());
				}}
				loading={loading}
			/>
		</div>
	);
};

export default Home;
