/**
 * @class CustomPosition
 */
class CustomPosition {
	constructor(options = {}) {
		this.elOffset = options.elOffset || 0;
		this.totalOffset = options.totalOffset || 0;
		this.textNode = options.textNode || null;
	}
}

/**
 * returns true if the element is a descendent of the container
 *
 * @param {node} container
 * @param {node} el
 * @returns boolean
 */
const containsEl = (container, el) => {
	if (!container) {
		return false;
	}

	if (el === container) {
		return true;
	}

	if (!el.parentElement) {
		return false;
	}

	return containsEl(container, el.parentElement);
};

/**
 *
 * @returns cursor range
 */
const getCursorRange = () => {
	const sel = window.getSelection();
	const range = sel.getRangeAt(0);
	return range;
};

// to find the real cursor position, first we want to get all of the text nodes into a list
// use a depth first traversal to build this list
const getTextNodesInOrder = (node, includeBr, textNodes = []) => {
	if (node.nodeName === '#text') {
		if (!node.parentElement.getAttribute('data-style-span')) {
			textNodes.push(node);
		}
	}

	if (includeBr && node.nodeName === 'BR') {
		node.parentElement.isBr = true;
		textNodes.push(node.parentElement);
	}

	// node.classList.contains('text')

	for (let i = 0; i < node.childNodes.length; i++) {
		getTextNodesInOrder(node.childNodes[i], includeBr, textNodes);
	}

	return textNodes;
};

// go deep first, we want to find the leaf nodes and put the
//
const findCursorHelper = (container, range) => {
	const customRange = {
		start: new CustomPosition(),
		end: new CustomPosition(),
	};

	const textNodes = getTextNodesInOrder(container, true);

	let totalOffset = 0;

	for (let i = 0; i < textNodes.length; i++) {
		const node = textNodes[i];

		if (node === range.startContainer) {
			customRange.start.elOffset = i;
			customRange.start.totalOffset = range.startOffset ? totalOffset + range.startOffset : totalOffset;
			customRange.start.textNode = node;

			if (node.isBr) {
				customRange.start.elOffset++;
				customRange.start.totalOffset++;
				customRange.start.isBr = true;
			}
		}

		if (node === range.endContainer) {
			customRange.end.elOffset = i;
			customRange.end.totalOffset = range.endOffset ? totalOffset + range.endOffset : totalOffset;
			customRange.end.textNode = node;

			if (node.isBr) {
				customRange.end.elOffset++;
				customRange.end.totalOffset++;
				customRange.end.isBr = true;
			}
		}

		if (node.length) {
			totalOffset += node.length;
		}
	}

	return customRange;
};

/**
 * returns a custom range object that represents the start and end of the range
 *
 * @param {node} container
 * @returns {object}
 */
export const findCursor = (container) => {
	const range = getCursorRange();

	// check to make sure the range is in the container
	if (!containsEl(container, range.startContainer) || !containsEl(container, range.endContainer)) {
		throw new Error('invalid range');
	}

	const customRange = findCursorHelper(
		container,
		range,
	);

	return customRange;
};

/**
 * places the cursor in the container according to a range object with start/end custompositions
 *
 * @param {node} container
 */
export const placeCursor = (container, customRange) => {
	if (!container.innerText.length) {
		return;
	}

	const { start, end } = customRange;
	const textNodes = getTextNodesInOrder(container);

	let totalOffset = 0;

	let startPos;
	let endPos;

	for (let i = 0; i < textNodes.length; i++) {
		const node = textNodes[i];

		const nextOffset = totalOffset + node.length;

		if (start.totalOffset >= totalOffset) {
			startPos = { node, offset: start.totalOffset - totalOffset };
		}

		if (end.totalOffset >= totalOffset) {
			endPos = { node, offset: end.totalOffset - totalOffset };
		}

		totalOffset = nextOffset;
	}

	if (!startPos || !endPos) {
		return;
	}

	try {
		const range = document.createRange();
		range.setStart(startPos.node, startPos.offset);
		range.setEnd(endPos.node, endPos.offset);

		const sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	} catch (_) {
		// ignore this error
	}
};

export const placeCursorAtPosition = (container, position) => {
	const textNodes = getTextNodesInOrder(container);

	let total = 0;
	let textNode = null;
	let elOffset = 0;
	for (let i = 0; i < textNodes.length; i++) {
		textNode = textNodes[i];

		total += textNode.length;
		if (total >= position) {
			break;
		}
		elOffset++;
	}

	const customRange = {
		start: new CustomPosition({
			elOffset,
			totalOffset: position,
			textNode,
		}),
		end: new CustomPosition({
			elOffset,
			totalOffset: position,
			textNode,
		}),
	};

	placeCursor(container, customRange);
	return customRange;
};
