import React from 'react';
import { makeStyles } from '@material-ui/core';
import ProgressiveImage from 'react-progressive-image';
import WaveSkeleton from 'components/WaveSkeleton';
import clsx from 'clsx';

const useStyles = makeStyles({
	img: {
		width: '100%',
		height: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		objectFit: 'cover',
		display: 'inline-block',
		borderRadius: 5,
	},
	absolute: {
		position: 'absolute',
	},
	gradient: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: 'linear-gradient(180deg, rgba(27, 36, 45, 0) 0%, rgba(27, 36, 45, 0.00255271) 8.9%, rgba(27, 36, 45, 0.00998942) 16.22%, rgba(27, 36, 45, 0.021978) 22.27%, rgba(27, 36, 45, 0.0381864) 27.32%, rgba(27, 36, 45, 0.0582825) 31.66%, rgba(27, 36, 45, 0.0819341) 35.6%, rgba(27, 36, 45, 0.108809) 39.4%, rgba(27, 36, 45, 0.138575) 43.37%, rgba(27, 36, 45, 0.170901) 47.79%, rgba(27, 36, 45, 0.205454) 52.95%, rgba(27, 36, 45, 0.241902) 59.14%, rgba(27, 36, 45, 0.279912) 66.65%, rgba(27, 36, 45, 0.319153) 75.78%, rgba(27, 36, 45, 0.359293) 86.79%, rgba(27, 36, 45, 0.4) 100%)',
		borderRadius: 5,
		zIndex: 1,
	},
	homeGradient: {
		background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00255271) 8.9%, rgba(0, 0, 0, 0.00998942) 16.22%, rgba(0, 0, 0, 0.021978) 22.27%, rgba(0, 0, 0, 0.0381864) 27.32%, rgba(0, 0, 0, 0.0582825) 31.66%, rgba(0, 0, 0, 0.0819341) 35.6%, rgba(0, 0, 0, 0.108809) 39.4%, rgba(0, 0, 0, 0.138575) 43.37%, rgba(0, 0, 0, 0.170901) 47.79%, rgba(0, 0, 0, 0.205454) 52.95%, rgba(0, 0, 0, 0.241902) 59.14%, rgba(0, 0, 0, 0.279912) 66.65%, rgba(0, 0, 0, 0.319153) 75.78%, rgba(0, 0, 0, 0.359293) 86.79%, rgba(0, 0, 0, 0.4) 100%)',
	},
});

export default ({
	absolute = false,
	src,
	style,
	onLoad,
	gradient,
	home,
	...props
}) => {
	const classes = useStyles();

	const klass = absolute
		? clsx(classes.absolute, classes.img)
		: classes.img;

	return (
		<>
			{gradient && <div className={home ? clsx(classes.gradient, classes.homeGradient) : classes.gradient} />}
			<ProgressiveImage src={src} placeholder={src}>
				{(s, loading) => (
					loading ? <WaveSkeleton style={style} {...props} />
						: (
							<img
								className={klass}
								src={s}
								alt=""
								style={style}
								onLoad={onLoad}
							/>
						)
				)}
			</ProgressiveImage>
		</>
	);
};
