import React, { useEffect } from 'react';
import RequestProvider from 'rds/providers/Request';
import {
	requestAlbums,
	getAlbums,
	getLoading,
	getErrors,
	reset,
} from 'app/slices/content-creation/create/device-library/albums';
import { useSelector } from 'react-redux';
import { notifyErrors } from 'app/slices/notifications/notifications';
import useNavigateCC from 'app/hooks/useNavigateCC';
import Contents from './Contents';
import Header from './Header';
import Modal from './Modal';

export default ({
	show,
}) => {
	const errors = useSelector(getErrors);
	const navigateCC = useNavigateCC();

	// if an error occurs while fetching library
	// the port is cleared and we fallback to legacy
	// show an error & go "back"

	useEffect(() => {
		if (errors?.length) {
			notifyErrors([{ text: 'Failed to load device library. Please try again' }]);
			navigateCC(null, {
				subTab: null,
			});
		}
	}, [errors]);

	return (
		<RequestProvider
			requests={{
				library: {
					selector: getAlbums,
					action: requestAlbums,
					errors: getErrors,
					loading: getLoading,
					reset,
					resetConditional: () => false,
				},
			}}
		>
			<Modal show={show}>
				<Header />
				<Contents />
			</Modal>
		</RequestProvider>
	);
};
