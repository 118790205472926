import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import GAEvent from 'components/GoogleAnalytics/event';
import EachThumbnail from './EachThumbnail';
import experiencesData from './experiences.json';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		overflowY: 'hidden',
		overflowX: 'scroll',
		display: 'box',
		boxAlign: 'center !important',
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(8),
		'@media (min-width: 390px)': {
			marginBottom: theme.spacing(8),
		},
		alignItems: 'center',
		'&::-webkit-scrollbar': {
			display: 'none',
			width: 0,
		},
	},
}));

const Contents = () => {
	const classes = useStyles();
	const [playingVideoId, setPlayingVideoId] = useState(null);
	const containerRef = useRef(null);

	const handleVideoPlay = (id, thumbnailRef) => {
		if (playingVideoId === id) {
			setPlayingVideoId(null);
		} else {
			setPlayingVideoId(id);
			scrollToThumbnail(thumbnailRef);
			GAEvent({ name: 'pwa_registration_page_video', uid: id });
		}
	};

	const scrollToThumbnail = (thumbnailRef) => {
		const container = containerRef.current;
		const thumbnail = thumbnailRef.current;

		const containerRect = container.getBoundingClientRect();
		const thumbnailRect = thumbnail.getBoundingClientRect();

		if (thumbnailRect.left < containerRect.left || thumbnailRect.right > containerRect.right) {
			thumbnail.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'center',
			});
		}
	};

	return (
		<div className={classes.root} ref={containerRef}>
			{experiencesData.experiences.map((exp) => (
				<EachThumbnail
					key={exp.uid}
					experience={exp}
					onPlay={handleVideoPlay}
					isPlaying={playingVideoId === exp.uid}
				/>
			))}
		</div>
	);
};

export default Contents;
