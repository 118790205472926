import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import { getFilters, getLoading } from 'app/slices/destination-account-v2/experiences/selectors';
import isObjEmpty from 'utils/is-obj-empty';
import PaginationSkeleton from 'rds/components/Skeletons/PaginationSkeleton';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(16),
	},
	info: {
		width: 224,
		marginTop: theme.spacing(2),
		whiteSpace: 'normal',
		textAlign: 'center',
		marginBottom: theme.spacing(6),
		color: theme.colors.neutral.C200,
	},
	button: {
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5),
		marginRight: theme.spacing(2.5),
		marginLeft: theme.spacing(2.5),
		border: `1px solid ${theme.colors.neutral.C000}`,
		background: 'none',
		color: theme.colors.neutral.C000,
		'&:hover': {
			borderColor: theme.colors.neutral.C300,
			color: theme.colors.neutral.C300,
			background: 'none',
		},
	},
	noResult: {
		paddingTop: theme.spacing(4),
		marginLeft: theme.spacing(4),
	},

}));

function EmptyContent() {
	const classes = useStyles();
	const { uid } = useParams();
	const filters = useSelector(getFilters(uid));
	const loadingAllExperience = useSelector(getLoading);
	const isFiltersEmpty = isObjEmpty(filters);

	if (loadingAllExperience) {
		return <PaginationSkeleton />;
	}

	if (!isFiltersEmpty) {
		return (
			<Typography className={classes.noResult} variant="body2">No filtered results found.</Typography>
		);
	}

	return (
		<div className={classes.root}>
			<Typography variant="subtitle1">No experiences yet.</Typography>
			<Typography className={classes.info} variant="body1">Create an experience and tag this destination to start sharing.</Typography>
		</div>
	);
}

export default EmptyContent;
