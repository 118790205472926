import React from 'react';
import { useSelector } from 'react-redux';
import { selectExperienceActivities, selectClips } from 'app/slices/content-creation/experience';
import { getActive } from 'app/slices/content-creation/experience/queue';
import { makeStyles } from '@material-ui/core';
import useNavigateCC from 'app/hooks/useNavigateCC';
import FixedTopControls from '../Components/Layout/FixedTopControls';

const useStyles = makeStyles((theme) => ({
	publishing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	publishingText: {
		marginLeft: theme.spacing(2),
	},
	root: {
		position: 'absolute',
		width: '100%',
		height: 48,
		background: theme.palette.background.page,
		zIndex: 4,
	},
}));

export default ({ onClick }) => {
	const classes = useStyles();
	const navigateCC = useNavigateCC();
	const activities = useSelector(selectExperienceActivities);
	const queueActive = useSelector(getActive);
	const clips = useSelector(selectClips);
	const disabled = !activities.length || !!queueActive || !clips.length;

	return (
		<div className={classes.root}>
			<FixedTopControls
				position="absolute"
				buttons={[{
					text: 'Exit',
					disabled: queueActive,
					onClick: () => {
						navigateCC.exit();
					},
				}, {
					text: queueActive ? 'Publishing' : 'Publish',
					spinner: queueActive,
					disabled,
					onClick,
				}]}
			/>
		</div>
	);
};
