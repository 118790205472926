import React from 'react';
import FixedContent from 'rds/components/Layouts/FixedContent';
import FixedTopBar from 'rds/components/Layouts/FixedTopBar';
import BottomBar from './BottomBar';
import Header from './Header';
import Indicators from './Indicators';

export default ({
	bottom,
	header,
	children,
	top,
}) => (
	<FixedContent>
		<FixedTopBar {...top} />
		<BottomBar {...bottom} />
		<Header>{header}</Header>
		{children}
		<Indicators />
	</FixedContent>
);
