import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import md from 'md';
import { Button, makeStyles } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	button: {
		marginLeft: theme.spacing(6),
		'@media (max-width: 599px)': {
			marginLeft: theme.spacing(0),
			width: '100%',
			background: 'none',
			'&:hover': {
				background: theme.colors.neutral.C700,
			},
		},
	},

	menu: {
		display: 'flex',
		justifyContent: 'right',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},

	mobileMenuButton: {
		display: 'none',
		'@media (max-width: 599px)': {
			display: 'flex',
			justifyContent: 'right',
			position: 'relative',
		},
	},

	mobileMenuButtonIcon: {
		color: theme.colors.neutral.C000,
	},

	textPrimary: {
		color: theme.colors.primary.C300,
		'&:hover': {
			color: theme.colors.primary.C500,
			background: 'none',
		},
	},

	mobileMenu: {
		flexDirection: 'column',
		width: '100%',
		height: 0,
		position: 'fixed',
		top: theme.spacing(19),
		right: 0,
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		zIndex: 3,
		background: theme.colors.neutral.C900,
		transition: 'height 0.1s linear',
		overflow: 'hidden',
		display: 'none',
		'@media (max-width: 600px)': {
			display: 'flex',
		},
	},
	showMobileMenu: {
		'@media (max-width: 600px)': {
			height: 112,
		},
	},
	menuContents: {
		position: 'relative',
		zIndex: 2,
	},
	gradient: {
		position: 'absolute',
		zIndex: 1,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: 'linear-gradient(to bottom, rgba(0,0,0,0.25) 30%, rgba(0,0,0,0) 100%)',
	},
}));

const MenuButton = ({
	children,
	primary,
	...props
}) => {
	const classes = useStyles();
	const overrideClasses = {};

	if (primary) {
		overrideClasses.textPrimary = classes.textPrimary;
	}

	return (
		<Button
			disableRipple
			disableTouchRipple
			size="small"
			color={primary ? 'primary' : 'default'}
			classes={overrideClasses}
			className={classes.button}
			component={props.to ? Link : undefined}
			{...props}
		>
			{children}
		</Button>
	);
};

export default ({ onChange }) => {
	const classes = useStyles();
	const [showMobileMenu, setMobileMenu] = useState(false);

	const mmKlass = showMobileMenu
		? clsx(classes.mobileMenu, classes.showMobileMenu)
		: classes.mobileMenu;

	const onClickDownloadApp = () => {
		if (md.mobile()) {
			window.location.href = `${window.location.origin}/apps/get-rhino`;
			return;
		}

		setMobileMenu(false);
	};

	return (
		<>
			<div className={classes.menu}>
				<div className={classes.menuContents}>
					<MenuButton to="/about">About Us</MenuButton>
					<MenuButton to="/partner-program">Partner Program</MenuButton>
					<MenuButton onClick={() => onClickDownloadApp()} primary>Download App</MenuButton>
				</div>
				<div className={classes.gradient} />
			</div>

			<div className={classes.mobileMenuButton}>
				<IconV3
					icon="MenuHamburger"
					color="neutralC00"
					size={20}
					containerSize={48}
					onClick={() => {
						const next = !showMobileMenu;
						setMobileMenu(next);
						onChange(next);
					}}
				/>

				<div className={mmKlass}>
					<MenuButton to="/about">About Us</MenuButton>
					<MenuButton to="/partner-program">Partner Program</MenuButton>
					<MenuButton onClick={() => onClickDownloadApp()} primary>Download App</MenuButton>
				</div>
			</div>
		</>
	);
};
