import React, {
	useRef,
	useMemo,
	useEffect,
} from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { useFormUrl } from 'app/hooks/useQueryParams';
import clsx from 'clsx';
import PaginationSkeleton from 'rds/components/Skeletons/PaginationSkeleton';
import EndOfContents from 'rds/components/EndOfContents';
import Thumbnail from './Thumbnail';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		zIndex: 1,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: window.innerWidth < 390 ? theme.spacing(2.5) : theme.spacing(4.5),
		marginRight: window.innerWidth < 390 ? theme.spacing(2.5) : theme.spacing(4.5),
	},
	item: {
		display: 'flex',
		padding: 0,
		borderRadius: 5,
		marginBottom: theme.spacing(4),
	},
	small: {
		width: '33.333%',
		height: 188,
	},
	medium: {
		width: '33.333%',
		height: 210,
	},
	endContentsContainer: {
		marginTop: theme.spacing(9),
		marginBottom: theme.spacing(25),
	},
	smallCenter: {
		marginTop: theme.spacing(5.5),
	},
	oddRow: {
		marginBottom: theme.spacing(9),
	},
}));

const Item = ({
	classes,
	itemLength,
	item,
	lastExperienceRef,
	id,
	i,
}) => {
	const formUrl = useFormUrl();
	const ref = useRef();

	let s;
	if (i % 3 === 1) {
		if (i % 2 === 0) {
			s = 'small';
		} else {
			s = 'medium';
		}
	} else if (i % 2 === 0) {
		s = 'small';
	} else {
		s = 'medium';
	}

	const to = formUrl({ params: { experience: item.uid } });
	const oddRow = Math.floor(i / 3) % 2 !== 0;
	const smallCenter = oddRow && i % 2 === 0;

	const k = smallCenter
		? clsx(classes.item, classes[`${s}`], classes.smallCenter)
		: clsx(classes.item, classes[`${s}`]);

	const klass = oddRow
		? clsx(k, classes.oddRow)
		: k;

	if (itemLength === i + 1) {
		return (
			<div className={klass} key={i} ref={lastExperienceRef}>
				<span ref={ref} />
				<Thumbnail
					user={item.user}
					src={item.image}
					title={item.title}
					destination={item.destination}
					size={s}
					to={to}
					id={id}
					oddRow={Math.floor(i / 3) % 2 !== 0}
					smallCenter={Math.floor(i / 3) % 2 !== 0 && i % 2 === 0}
				/>
			</div>
		);
	}
	return (
		<div key={i} className={klass}>
			<span ref={ref} />
			<Thumbnail
				user={item.user}
				src={item.image}
				title={item.title}
				destination={item.destination}
				size={s}
				to={to}
				id={id}
				oddRow={Math.floor(i / 3) % 2 !== 0}
				smallCenter={Math.floor(i / 3) % 2 !== 0 && i % 2 === 0}
			/>
		</div>
	);
};

const Masonry = ({
	items,
	hasNext = false,
	loading,
	reachedEnd,
}) => {
	const theme = useTheme();
	const classes = useStyles();
	const endRef = useRef();

	const observer = useMemo(
		() => new IntersectionObserver(([intersection]) => {
			if (!intersection.isIntersecting || !hasNext || loading) {
				return;
			}
			reachedEnd && reachedEnd();
		}),
		[hasNext, loading],
	);

	useEffect(() => {
		observer.observe(endRef.current);
		return () => observer.disconnect();
	}, [endRef, observer]);

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				{items.map((item, i) => (
					<Item
						key={item.uid}
						classes={classes}
						itemLength={items.length}
						item={item}
						i={i}
						id={item.uid}
					/>
				))}
			</div>
			<div ref={endRef} />
			{loading
				&& (
					<PaginationSkeleton
						style={{
							marginTop: !items.length ? '30vh' : theme.spacing(10.5),
							marginBottom: theme.spacing(20),
						}}
					/>
				)}

			{!hasNext && !loading && !!items.length
				&& (
					<div className={classes.endContentsContainer}>
						<EndOfContents />
					</div>
				)}
		</div>
	);
};

const areEqual = (prevProps, nextProps) => {
	const areItemsEqual = prevProps.items.length === nextProps.items.length
		&& prevProps.items.every((item, index) => item.uid === nextProps.items[index].uid);

	const equal = (areItemsEqual
		&& prevProps.hasNext === nextProps.hasNext
		&& prevProps.loading === nextProps.loading);

	return equal;
};

const MemoizedMasonry = React.memo(Masonry, areEqual);

export default MemoizedMasonry;
