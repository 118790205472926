import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'inline-block',
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingLeft: theme.spacing(3),
		pointerEvents: 'all',
		userSelect: 'none',
	},
}));

export default ({
	color,
	label,
	onClick,
	selected,
	spacing,
}) => {
	const classes = useStyles();

	const style = {
		color,
		display: 'inline',
		whiteSpace: 'nowrap',
		paddingLeft: spacing,
		paddingRight: spacing,
	};

	if (!selected) {
		style.opacity = 0.7;
	}

	return (
		<div className={classes.root} onClick={onClick}>
			{
				typeof label === 'string'
					? (
						<Typography data-selected={selected} variant="subtitle2" style={style}>
							{label}
						</Typography>
					)
					: label
			}
		</div>
	);
};
