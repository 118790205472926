import React from 'react';
import { useDispatch } from 'react-redux';
import { remove } from 'app/slices/notifications';
import { makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	alert: {
		textAlign: 'center',
		display: 'flex !important',
		flexDirection: 'row !important',
		justifyContent: 'flex-start !important',
		width: 'calc(100vw - 20px)',
	},
	cancelIcon: {
		position: 'absolute',
		top: -2,
		right: 0,
	},
	title: {
		textAlign: 'left',
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(6),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(),
		color: theme.colors.neutral.C100,
	},
	description: {
		textAlign: 'left',
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(6),
		color: theme.colors.neutral.C200,
	},
}));

export default ({
	background,
	description,
	severity,
	title,
	id,
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	return (
		<Alert
			className={classes.alert}
			severity={severity}
			style={{ background }}
			action={(
				severity === 'info'
					? (
						<div className={classes.cancelIcon}>
							<IconV3
								icon="Cancel"
								size={20}
								onClick={(e) => {
									e.preventDefault();
									dispatch(remove({ id }));
								}}
							/>
						</div>

					) : null
			)}
		>
			{title ? <Typography className={classes.title} variant="subtitle2">{title}</Typography> : null}
			{description ? <Typography className={classes.description} variant="body1">{description}</Typography> : null}
		</Alert>
	);
};
