import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNavigateCC from 'app/hooks/useNavigateCC';
import Tabs from 'rds/components/Tabs';
import { makeStyles } from '@material-ui/core';
import {
	getRecordingStartTime,
	reset,
} from 'app/slices/content-creation/create/capture';
import {
	CLIPS_THRESHOLD,
	selectShowControls,
	selectClipTrayState,
	selectClipCount,
} from 'app/slices/content-creation/experience';
import { setCreateNavigation } from 'app/slices/content-creation/create/device-library/state';
import { lockDisplay, unlockDisplay } from 'lib/wake-lock';
import Content from './Content';
import TopControls from '../../Components/Layout/TopControls';

const useStyles = makeStyles(() => ({
	tabContainer: {
		position: 'absolute',
		zIndex: 1,
		bottom: 0,
		left: 0,
		right: 0,
		top: 0,
	},
}));

export default ({ params }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigateCC = useNavigateCC();
	const [permissions, setPermissions] = useState();
	const recording = useSelector(getRecordingStartTime);
	const showControls = useSelector(selectShowControls);
	const { show: showClipTray } = useSelector(selectClipTrayState);
	const clipCount = useSelector(selectClipCount);
	const showTabs = !recording && showControls && !showClipTray;
	const reachedMax = clipCount === CLIPS_THRESHOLD;
	const hasPermissions = permissions?.camera && permissions?.microphone;

	useEffect(() => {
		lockDisplay();

		return () => {
			dispatch(reset());
			unlockDisplay();
		};
	}, [params.tab]);

	const onSelect = useCallback((i) => {
		const tab = i === 0
			? 'photo'
			: 'text';

		navigateCC('create', {
			tab,
			subTab: params.subTab,
		});
	}, [params]);

	useEffect(() => {
		if (reachedMax && !params.subTab) {
			dispatch(setCreateNavigation({
				open: false,
				slide: false,
			}));

			navigateCC('details', {
				qs: { start: true },
			});
		}
	}, [reachedMax, params]);

	const showTopControls = !recording && showControls && params.subTab !== 'library' && params.subTab !== 'library-gateway';

	return (
		<div className={classes.tabContainer}>
			{showTopControls && (
				<TopControls
					camera={params.tab === 'photo'}
					hasPermissions={hasPermissions}
					tray={params.subTab === 'tray'}
				/>
			)}
			<Content
				params={params}
				hasPermissions={hasPermissions}
				permissions={permissions}
				setPermissions={setPermissions}
			/>
			<div>
				<Tabs
					showTabs={showTabs}
					labels={['Photo / Video', 'Text']}
					onSelect={onSelect}
					justifyContent="center"
					selected={
						params.tab === 'text'
							? 1
							: 0
					}
					disabledUnderline
				/>
			</div>
		</div>
	);
};
