import getWebMDuration from './get-webm-duration';

const MAX_DURATION = 15;
const DEFAULT_DURATION = 2;

// Helper function to clamp the duration between 0 and MAX_DURATION or return DEFAULT_DURATION if the duration is not valid
const clamp = (value) => (value ? Math.max(Math.min(value, MAX_DURATION), 0) : DEFAULT_DURATION);

/**
 * Get the duration of a media file in seconds, from an editing canvas with a root node
 * that is a media node (either a WebM or a video with a valid duration).
 *
 * @param {object} editingCanvas - The editing canvas containing the media node.
 * @returns {Promise<number>} A Promise that resolves with the duration in seconds.
 */
const getDuration = async (editingCanvas) => {
	// Extract the root node from the editing canvas
	const { rootNode } = editingCanvas;

	if (!rootNode) {
		return DEFAULT_DURATION;
	}

	const rootNoteData = editingCanvas.store.get(editingCanvas.root).data;

	const { start, end } = rootNoteData;
	if (typeof start !== 'undefined' && typeof end !== 'undefined') {
		return end - start;
	}

	// Check if the root node is an audio or video node
	if (rootNode instanceof HTMLVideoElement || rootNode instanceof HTMLAudioElement) {
		// If the duration is valid (not Infinity), return the clamped duration
		if (rootNode.duration !== Infinity && !Number.isNaN(rootNode.duration)) {
			return clamp(rootNode.duration);
		}

		// If the duration is invalid (Infinity), use the getWebMDuration function
		const duration = await getWebMDuration(rootNode);
		return clamp(duration);
	}

	// If the root node is not an audio or video node, return the default duration
	return DEFAULT_DURATION;
};

export default getDuration;
