import { deployedOnDev } from 'config';


const spas_wellness = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/4285b075-87be-4f9c-8955-2b761c6d6007" : "https://cdn.roamrhino.com/static/d8727e5b-f4fc-491c-8b23-805296525316"
const shopping = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/3035497d-0c23-4653-80e5-dbd6d21db2ae" : "https://cdn.roamrhino.com/static/254d9e47-7c3a-4515-9a7c-cd839e1a3d8f"
const performances = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/5eedba7d-81eb-445a-9d2a-57a97f4298dd" : "https://cdn.roamrhino.com/static/a3af4de9-ac72-43f6-aec7-1275b92f7a6c"
const nightlife = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/ae044bf3-88ed-4303-9c4f-a3d4a1190902" : "https://cdn.roamrhino.com/static/66c65de5-f050-46cc-9a75-875ee33f9604"
const local_attractions = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/87c94d9d-49bc-4213-a705-09a7839ed3fb" : "https://cdn.roamrhino.com/static/5e0e7ee7-32ed-4cd4-8ff7-f335a1d6fed9"
const drinks_eats = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/e2bced95-9be4-475b-b96a-dcb8560603e7" : "https://cdn.roamrhino.com/static/d4761bf6-cea7-47a3-a064-5c900b357540"
const beaches = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/3d491856-efc7-49c8-9157-ee6f918ea96c" : "https://cdn.roamrhino.com/static/6e846bd5-628c-46ec-b9e2-89fa752c1653"
const art_museums = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/92ca1f7c-c200-4c85-a822-f8d02322d25d" : "https://cdn.roamrhino.com/static/91e63ab2-67fd-445f-856a-41f28a0a6d8f"
const active_outdoors = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/237a36c6-82aa-4752-8f76-a88571d49048" : "https://cdn.roamrhino.com/static/c5de0b39-2d2a-4596-a572-ba944cf26772"
const other = deployedOnDev ? "https://cdn.dev.roamrhino.com/static/d24324a1-df0f-4c7b-8db5-fabd50380f10" : "https://cdn.roamrhino.com/static/c95fc253-51ac-4a02-a91a-e1c3ced4ab36"



export default {
	active_outdoors,
	art_museums,
	beaches,
	drinks_eats,
	local_attractions,
	nightlife,
	performances,
	shopping,
	spas_wellness,
};


