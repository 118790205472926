let largerDimension = Math.max(window.innerWidth, window.innerHeight);
let initialInnerHeight = window.innerHeight;

window.addEventListener('resize', () => {
	if (window.innerHeight < initialInnerHeight) {
		largerDimension = Math.max(window.innerWidth, initialInnerHeight);
	} else {
		initialInnerHeight = window.innerHeight;
		largerDimension = Math.max(window.innerWidth, window.innerHeight);
	}
});

const getLargerScreenDimension = () => largerDimension;

export default getLargerScreenDimension;
