import {
	isBackground,
	isObject,
	isRoot,
	isNodeLocked,
} from './utils';

const CLICK_THRESHOLD = 350;
const FILTER_THRESHOLD = 30;

const isClick = (e, store) => store.start
	&& Date.now() - store.start <= CLICK_THRESHOLD;

export default (canvas, store) => (e) => {
	if (canvas.ignoreGestures) { return; }

	e.preventDefault();

	e.moveTarget = e.target;

	if (!isObject(e) || isNodeLocked(e.moveTarget)) {
		e.moveTarget = canvas.container.querySelector(`[data-object-id="${canvas.store.root}"]`);
	}

	canvas.grid.hide();
	if (isObject(e)) {
		canvas.onObjectUp(e, {
			el: e.moveTarget,
			event: e,
			lastEvent: store.lastEvent,
		});
	}

	let filterMove = false;
	if (store.lastEvent && store.lastEvent.filterMove && e.touches.length === 0) {
		if (
			Date.now() - store.lastEvent.now <= 250 && (
				canvas.filterCollection.left > FILTER_THRESHOLD
				|| canvas.filterCollection.left < -FILTER_THRESHOLD)
		) {
			filterMove = true;
		} else {
			canvas.filterCollection.setCurrent();
		}
	}
	///
	e.lastEvent = store.lastEvent;

	if (!filterMove && !store.hasInteracted && isClick(e, store)) {
		if (isBackground(e)) {
			const id = store.getId(e.target);
			canvas.onSelect(e, {
				id,
				el: e.target,
				node: canvas.store.get(id),
				background: true,
			});
		} else if (isObject(e) && !isRoot(e) && !isBackground(e)) {
			const id = store.getId(e.moveTarget);
			canvas.onSelect(e, {
				id,
				el: e.moveTarget,
				node: canvas.store.get(id),
			});
		} else {
			canvas.onSelect(e);
		}

		store.removeGesture();
		return;
	}

	if (filterMove) {
		const downX = store.downEvent.touches[0].clientX;
		const upX = store.lastEvent.touches[0].clientX;
		const { dx } = store.lastEvent;

		if (dx > 0 && upX > downX && canvas.filterCollection.left > FILTER_THRESHOLD) {
			canvas.filterCollection.setPrevious();
		} else if (dx < 0 && downX > upX && canvas.filterCollection.left < -FILTER_THRESHOLD) {
			canvas.filterCollection.setNext();
		} else {
			canvas.filterCollection.setCurrent();
		}
	}

	store.removeGesture();
};
