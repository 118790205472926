import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	section: {
		position: 'relative',
		width: '100%',
		padding: theme.spacing(0, 3, 3),
	},
	sectionContents: {
		position: 'relative',
		zIndex: 3,
	},
	sectionBackdrop: {
		position: 'absolute',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
		opacity: 0.8,
	},
	sectionFade: {
		position: 'absolute',
		background: `linear-gradient(
      180deg, rgba(27, 36, 45, 0) 40%, ${theme.colors.neutral.C900} 100%
    )`,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
	},
}));

export default ({
	backgroundImage,
	dark = false,
	mirror = false,
	faded,
	children,
	...props
}) => {
	const classes = useStyles();

	const style = {};

	if (backgroundImage) {
		style.backgroundImage = `url("${backgroundImage}")`;
	}

	if (mirror) {
		style.transform = 'scaleX(-1)';
	}

	if (dark) {
		style.opacity = 0.50;
	}

	return (
		<div className={classes.section} {...props}>
			<div className={classes.sectionContents}>
				{children}
			</div>
			{faded && <div className={classes.sectionFade} /> }
			<div className={classes.sectionBackdrop} style={style} />
		</div>
	);
};
