import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core';
import ThumbnailGrid from 'rds/components/ThumbnailGrid';
import useExperiencesThumbnail from 'app/hooks/useExperiencesThumbnail';
import {
	requestLoadMoreSavedExperiences,
	getUserSavedExperiences,
	getNextLinkOnSavedExperiences,
	getShowEmptyContent,
	getLoadingMore,
	getErrors,
	reset,
} from 'app/slices/saves';
import ErrorPage from 'rds/components/Errors/ErrorPage';
import EmptyState from './EmptyState';

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		marginRight: window.innerWidth < 390 ? theme.spacing(4) : theme.spacing(6),
		paddingTop: theme.spacing(20),
		marginBottom: theme.spacing(8),
		zIndex: 15,
	},
	spacer: {
		paddingTop: theme.spacing(4),
	},
}));

export default () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const data = useSelector(getUserSavedExperiences);
	const experiences = useExperiencesThumbnail(data);
	const loading = useSelector(getLoadingMore);
	const nextLink = useSelector(getNextLinkOnSavedExperiences);
	const errors = useSelector(getErrors);
	const showEmptyContent = useSelector(getShowEmptyContent);

	useEffect(() => () => {
		dispatch(reset());
	}, []);

	if (showEmptyContent && !loading) {
		return <EmptyState />;
	}

	if (errors) {
		return <ErrorPage errors={errors} />;
	}

	const items = experiences && experiences.filter((ex) => ex.saved);

	return (
		<div className={classes.root}>
			<ThumbnailGrid
				items={items}
				onLongPress={() => {}}
				loading={loading}
				gutters={{ gutterLeft: theme.spacing(1.5), gutterRight: theme.spacing(1.5) }}
				requestLoadMore={nextLink ? requestLoadMoreSavedExperiences : null}
				title
				titleSkeleton
				avatar
			/>
		</div>
	);
};
