import EventEmitter from 'events';
import { vapidKey } from 'config';
import store from 'services/store';
import api from 'services/api';

const ee = new EventEmitter();
export const broadcast = new BroadcastChannel('notifications');

const urlBase64ToUint8Array = (base64String) => {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
};

const requestPermissions = async (registration) => {
	const applicationServerKey = urlBase64ToUint8Array(vapidKey);

	const permissionResult = await registration.pushManager.permissionState({
		applicationServerKey,
		userVisibleOnly: true,
	});

	if (permissionResult === 'prompt') {
		const r = await window.Notification.requestPermission();
		return r;
	}

	return permissionResult;
};
// await api.delete(`/notifications/${uuid}/preferences/target/9bf50c63-eeb1-4bcb-b36e-55f25dbb55e5`);
const getTargetId = async (uuid, pushSubscription) => {
	try {
		const response = await api.post(`notifications/${uuid}/lookup-target`, {
			targetType: 'pwa_app',
			token: JSON.stringify(pushSubscription),
		});

		if (response?.data?.targetId) {
			return response?.data?.targetId;
		}
	} catch (_) {
		// ignore error
	}

	return null;
};

const createTargetId = async (uuid, pushSubscription) => {
	const response = await api.post(`notifications/${uuid}/preferences`, {
		label: 'Android',
		targetType: 'pwa_app',
		token: JSON.stringify(pushSubscription),
	});

	return response.data.id;
};

const subscribe = async (registration, activeSubscription) => {
	const state = store.getState();

	if (!state.session.session) {
		return null;
	}

	const { uuid } = state.session.session;

	let pushSubscription;
	try {
		pushSubscription = await registration.pushManager.subscribe({
			applicationServerKey: urlBase64ToUint8Array(vapidKey),
			userVisibleOnly: true,
		});
	} catch (err) {
		if (activeSubscription) {
			await activeSubscription.unsubscribe();
			return subscribe(registration);
		}
		throw err;
	}

	let targetId = await getTargetId(uuid, pushSubscription);
	if (!targetId) {
		targetId = await createTargetId(uuid, pushSubscription);
	}
	return targetId;
};

export const disableNotifications = async (uuid, targetId) => {
	broadcast.postMessage(JSON.stringify({
		message: 'unregisterNotifications',
	}));

	if (uuid && targetId) {
		await api.delete(`notifications/${uuid}/preferences/target/${targetId}`);
	}
};

export const enableNotifications = (accountUuid) => new Promise((resolve, reject) => {
	if (!navigator.serviceWorker) {
		reject(new Error('no service worker'));
		return;
	}

	navigator.serviceWorker.ready.then((registration) => {
		if (accountUuid) {
			broadcast.postMessage(JSON.stringify({
				message: 'registerNotifications',
				context: {
					accountUuid,
				},
			}));
		} else if (!localStorage.getItem('token')) {
			broadcast.postMessage(JSON.stringify({
				message: 'unregisterNotifications',
			}));
			resolve();
			return;
		}

		registration.pushManager.getSubscription().then(async (subscription) => {
			if (subscription) {
				const result = await requestPermissions(registration);
				if (result !== 'granted') {
					reject(new Error('permission denied'));
					// disableNotifications();
					// await window.Notification.requestPermission();
					return;
				}
			}

			const status = await navigator.permissions.query({
				name: 'notifications',
			});

			status.onchange = () => {
				if (status.state !== 'granted') {
					ee.emit('revoke');
				}
			};

			try {
				const targetId = await subscribe(registration, subscription);
				resolve(targetId);
			} catch (err) {
				reject(err);
			}
		});
	});
});

export default ee;
