import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMatch } from 'utils/look';
import textFit from 'utils/text-fit';
import {
	set,
	selectRecommendedValue,
	selectRecommendationTriggers,
} from 'app/slices/content-creation/create/text-component';
import { selectEditingCanvas } from 'app/slices/content-creation/editing-canvas';
import { change } from 'app/slices/content-creation/editor/layout';
import { selectMentionTag, removeMention } from 'app/slices/content-creation/editor/stickers';

const TRANSITION_TIMER = 500;
const TIME_OUT = 100;
const TRANSITION = '0.5s';

const getWidthOfInput = (el) => {
	const temporaryElement = el.cloneNode(true);
	temporaryElement.style.removeProperty('width');
	temporaryElement.style.visibility = 'hidden';
	temporaryElement.style.wordBreak = 'break-all';
	document.body.appendChild(temporaryElement);
	const { width, height } = temporaryElement.getBoundingClientRect();
	try {
		el.parentElement.removeChild(temporaryElement);
	} catch (_) {
		// Safe to ignore error
	}
	return { width: Math.ceil(width) + 2, height: Math.ceil(height) };
};

const moveTheCursorToTheEnd = (contentEditableElement) => {
	let range;
	let selection;
	if (document.createRange) {
		range = document.createRange();
		range.selectNodeContents(contentEditableElement);
		range.collapse(false);
		selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
	} else if (document.selection) {
		range = document.body.createTextRange();
		range.moveToElementText(contentEditableElement);
		range.collapse(false);
		range.select();
	}
};

export default ({
	editingCanvas,
}) => {
	const dispatch = useDispatch();
	editingCanvas = editingCanvas || useSelector(selectEditingCanvas);
	const recommendationTriggers = useSelector(selectRecommendationTriggers);
	const recommendedValue = useSelector(selectRecommendedValue);
	const mentionTag = useSelector(selectMentionTag);

	useEffect(async () => {
		if (mentionTag) {
			const { el, id } = mentionTag;

			clearTimeout(editingCanvas.backgroundTo);
			clearTimeout(editingCanvas.closeTo);

			el.removeAttribute('disabled');
			editingCanvas.disableGestures();
			el.style.removeProperty('width');

			const { width } = getWidthOfInput(el);
			el.style.left = `calc(50% - ${width / 2}px`;
			el.focus();
			const removeTextFit = textFit(el, {
				fontSize: 32,
				maxHeight: () => window.innerHeight - 72,
			});
			const overlay = await editingCanvas.find(editingCanvas.overlay.item.id);
			overlay.style.display = 'block';
			overlay.style.zIndex = editingCanvas.store.Z_INDEX_SERIAL - 1;
			overlay.ontouchend = () => {
				el.blur();
			};
			el.innerHTML = '@';
			moveTheCursorToTheEnd(el);
			const span = document.createElement('span');
			span.style.color = '#93A0AE';
			span.innerHTML = 'mention';
			el.appendChild(span);
			editingCanvas.container.ontouchend = (e) => {
				if (e.target !== el) {
					el.blur();
				}
			};

			el.onkeydown = (e) => {
				if (e.which === 8 || e.which === 46) {
					if (el.textContent.length === 1 || e.target.children[0]) {
						e.preventDefault();
					}
				}
				if (e.which === 13) {
					e.preventDefault();
					el.blur();
				}
				if (e.target.children[0]) {
					e.target.children[0].remove();
				}
				const newWidth = getWidthOfInput(el).width;
				el.style.left = `calc(50% - ${newWidth / 2}px`;
				el.edited = true;
			};

			el.onkeyup = () => {
				const match = getMatch(
					el.textContent,
					el.textContent.length - 1,
					recommendationTriggers,
					/\s/,
				);

				dispatch(set({
					match: match || null,
				}));
			};

			const onblur = () => {
				removeTextFit();
				overlay.style.display = 'none';
				el.style.transition = TRANSITION;
				el.innerHTML = el.innerHTML.trim();
				editingCanvas.enableGestures();
				window.onresize = null;
				el.onblur = null;
				el.onfocus = null;
				el.onkeydown = null;
				const w = getWidthOfInput(el).width;
				el.style.width = `${w}px`;
				el.style.left = `calc(50% - ${w / 2}px`;
				editingCanvas.container.ontouchend = null;

				if (!el.edited || el.textContent.length === 1 || !el.recommendedValue) {
					editingCanvas.remove(id);
					dispatch(removeMention({ id }));
				} else {
					editingCanvas.update(id, {
						el,
						specialNodes: [{
							div: el,
							data: { uuid: el.recommendedValue.uuid },
							type: 'mention',
							id,
						}],
					});
				}

				dispatch(set({
					recommendedValue: null,
					match: null,
				}));
				el.typed = false;

				editingCanvas.backgroundTo = setTimeout(() => {
					dispatch(change({ showControls: true }));
				}, TIME_OUT);

				editingCanvas.closeTo = setTimeout(() => {
					el.style.removeProperty('transition');
					el.setAttribute('disabled', true);
					window.ec = editingCanvas;
				}, TRANSITION_TIMER);
			};
			el.onblur = onblur;
		}
	}, [mentionTag]);

	useEffect(() => {
		if (!recommendedValue || !mentionTag) {
			return;
		}

		const { el } = mentionTag;
		el.innerHTML = `@${recommendedValue.value}`;
		el.recommendedValue = recommendedValue;

		el.blur();
	}, [recommendedValue, mentionTag]);

	return (
		<div />
	);
};
