import { createSelector } from '@reduxjs/toolkit';

export const getSlice = (state) => state.userAccountV2MyExperiences;

export const getMyExperiences = createSelector(getSlice, (slice) => slice?.experiences);

export const getLoadingMyExperiences = createSelector(getSlice, (slice) => slice?.loadingExperiences);

export const getNextLink = createSelector(getSlice, (slice) => slice?.next);

export const getHasMoreToLoad = createSelector(getSlice, (slice) => !!slice?.next);
