import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { selectClipCount } from 'app/slices/content-creation/experience';
import { getRoute } from 'app/slices/content-creation/navigation';
import GenieTransition from 'components/GenieTransition';
import clsx from 'clsx';
import SlideTransitions from 'rds/components/SlideTransitions';
import ExperienceDetails from './Details';
import CreateTabRouter from './Create/CreateTabRouter';
import ExitGateway from './ExitGateway';
import ClipEditor from './Editor/ClipEditor';
import DestinationSearchModal from './Editor/DestinationSearchModal';
import PermissionsGate from './Create/Camera/DeviceLibrary/PermissionsGate';

const useStyles = makeStyles((theme) => ({
	tabContainer: {
		position: 'fixed',
		zIndex: 30,
		bottom: 0,
		left: 0,
		right: 0,
		top: 0,
		backgroundColor: theme.palette.background.page,
	},
	scrollable: {
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			display: 'none',
			width: 0,
		},
	},
}));

const ContentCreationRouter = () => {
	const classes = useStyles();
	const {
		enteringLibrary,
		exiting,
		path,
		params,
	} = useSelector(getRoute);
	const clipCount = useSelector(selectClipCount);
	const transitionIn = path === 'create' || path === 'details' || path === 'editor';

	return (
		<>
			<GenieTransition in={transitionIn} className={path === 'details' ? clsx(classes.tabContainer, classes.scrollable) : classes.tabContainer}>
				<SlideTransitions
					components={[{
						path: 'create',
						component: path === 'create' ? <CreateTabRouter params={params} /> : <div />,
					}, {
						path: 'details',
						component: path === 'details' ? <ExperienceDetails params={params} /> : <div />,
					}]}
					path={path}
				/>
				<ClipEditor params={params} path={path} />

				{enteringLibrary && <PermissionsGate />}
				{exiting && <ExitGateway clipCount={clipCount} />}
			</GenieTransition>
			<DestinationSearchModal path={path} />
		</>
	);
};

export default ContentCreationRouter;
