import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';
import useTheme from 'rds/theme/useRdsTheme';
import { getIsMe } from 'app/slices/user-account-v2/accounts';
import ProgressiveImage from 'react-progressive-image';
import WaveSkeleton from 'components/WaveSkeleton';

const SIZES = {
	xxxxSmall: [20, 20, 4, {
		containerWidth: 20,
		containerHeight: 20,
		width: 16,
		height: 16,
	}],
	xxxSmall: [24, 24, 4, {
		containerWidth: 24,
		containerHeight: 24,
		width: 20,
		height: 20,
	}],
	xxSmall: [28, 28, 4, {
		containerWidth: 28,
		containerHeight: 28,
		width: 20,
		height: 20,
	}],
	xSmall: [30, 30, 4, {
		containerWidth: 30,
		containerHeight: 30,
		width: 18,
		height: 18,
	}],
	small: [32, 32, 2, {
		containerWidth: 32,
		containerHeight: 32,
		width: 20,
		height: 20,
	}],
	medium: [36, 36, 4, {
		containerWidth: 36,
		containerHeight: 36,
		width: 24,
		height: 24,
	}],
	large: [44, 44, 4, {
		containerWidth: 44,
		containerHeight: 44,
		width: 32,
		height: 32,
	}],
	xLarge: [70, 70, 6, {
		containerWidth: 70,
		containerHeight: 70,
		width: 44,
		height: 44,
	}],
	xxLarge: [116, 116, 6, {
		containerWidth: 116,
		containerHeight: 116,
		width: 70,
		height: 70,
	}],
	xxxLarge: [173, 173, 6, {
		containerWidth: 173,
		containerHeight: 173,
		width: 116,
		height: 116,
	}],
	destination: [26, 26, 2, {
		containerWidth: 26,
		containerHeight: 26,
		width: 20,
		height: 20,
	}],
};

const getSize = (key) => {
	const copy = { ...SIZES };

	return copy[key];
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexShrink: 0,
		lineHeight: 1,
		overflow: 'hidden',
		userSelect: 'none',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		position: 'relative',
		zIndex: 3,
		background: theme.colors.neutral.C900,
	},
	img: {
		width: '100%',
		height: '100%',
		textAlign: 'center',
		objectFit: 'cover',
		color: 'transparent',
		textIndent: 10000,
		borderRadius: '50%',
		border: `0.5px solid ${theme.colors.neutral.C800}`,
	},
	border: {
		position: 'absolute',
		zIndex: 2,
		backgroundSize: 'cover',
	},
	addContainer: {
		width: 24,
		height: 24,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: 0,
		right: 0,
		zIndex: 4,
	},
	rootAddIcon: {
		width: 18,
		height: 18,
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	backgroundAddIcon: {
		width: '100%',
		height: '100%',
		borderRadius: '50%',
		zIndex: 1,
		background: 'linear-gradient(180deg, #20D2B2 0%, #FB7848 50%, #CDBB0A 100%)',
	},
	icon: {
		position: 'absolute',
		width: 16,
		height: 16,
		borderRadius: '50%',
		zIndex: 2,
		background: theme.colors.neutral.C900,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	waveSkeleton: {
		borderRadius: '50%',
	},
}));

const Avatar = ({
	active = false,
	alt,
	size,
	src,
	fallback = true,
	variant = 'default',
	onClick,
	addIcon,
	border,
	...rest
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const isMe = useSelector(getIsMe);
	const background = theme.palette.background[variant];

	const borderColor = theme.palette.text[variant];

	if (!background) {
		throw new Error(`invalid variant ${variant}`);
	}

	const [height, width, props] = getSize(size);
	const iconProps = { ...props };

	if (!height || !width) {
		throw new Error(`invalid size ${size}`);
	}

	if (!alt) {
		throw new Error(`avatar without alt text: ${src}`);
	}

	const containerStyle = {
		background,
		height,
		width,
	};

	containerStyle.borderColor = borderColor;

	const rootStyle = {};

	if (border) {
		rootStyle.border = border;
	}

	const borderStyle = {
		height,
		width,
	};

	return (
		<div className={classes.root} style={rootStyle} {...rest}>
			<div className={classes.container} onClick={onClick} style={containerStyle}>
				<ProgressiveImage src={src} placeholder={src}>
					{(s, loading) => (
						<>
							{
								loading
									? <WaveSkeleton className={classes.img} />
									: src ? <img className={classes.img} src={src} alt={alt} /> : fallback && (
										<IconV3
											icon="Avatar"
											{...iconProps}
										/>
									)
							}
						</>
					)}
				</ProgressiveImage>
			</div>
			{isMe && addIcon
				&& (
					<div className={classes.addContainer}>
						<div>
							<div className={classes.rootAddIcon} onClick={onClick}>
								<div className={classes.backgroundAddIcon} />
								<div className={classes.icon}>
									<IconV3
										icon="Plus"
										size={16}
										containerSize={16}
										dropShadow
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			{
				active ? (
					<div
						className={classes.border}
						style={borderStyle}
					/>
				)
					: null
			}
		</div>
	);
};
export default React.memo(Avatar);
