import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPushTargetGroup, patchPushGroup } from 'app/slices/account-settings/notifications';
import Header from '../../Header';
import DataLoader from '../DataLoader';
import SubGroup from './SubGroup';

const Group = ({ match }) => {
	const dispatch = useDispatch();

	const {
		targetId,
		title,
		subGroups,
		slug,
	} = useSelector(getPushTargetGroup(match.params.group));

	const onChange = useCallback((option, group, previous) => {
		dispatch(patchPushGroup({
			targetId,
			option,
			group,
			slug,
			previous,
		}));
	});

	return (
		<DataLoader>
			<Header title={title} />
			{
				subGroups.map((subGroup) => (
					<SubGroup key={subGroup.slug} {...subGroup} onChange={onChange} />
				))
			}
		</DataLoader>
	);
};

export default ({ match }) => (
	<DataLoader>
		<Group match={match} />
	</DataLoader>
);
