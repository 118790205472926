import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import Typography from 'rds/components/Typography';
import {
	getSelectedActivities,
	addActivities,
	removeActivities,
	set,
	getShowActivitiesRequiredError,
} from 'app/slices/experience-view/edit';

const useStyles = makeStyles((theme) => ({
	root: {
		whiteSpace: 'nowrap',
		marginBottom: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	checked: {
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
			color: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		},
	},
	unChecked: {
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C800 : theme.colors.neutral.C000,
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C200 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C800 : theme.colors.neutral.C000,
			color: theme.palette.type === 'dark' ? theme.colors.neutral.C200 : theme.colors.neutral.C900,
		},
	},
}));

export default ({
	activity,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const activities = useSelector(getSelectedActivities);
	const selected = activities.map((a) => a.title);
	const showActivitiesRequiredError = useSelector(getShowActivitiesRequiredError);
	const klass = selected.includes(activity.title) ? classes.checked : classes.unChecked;

	const handleSelect = () => {
		if (!selected.includes(activity.title)) {
			dispatch(addActivities(activity));
			if (showActivitiesRequiredError) {
				dispatch(set({ showActivitiesRequiredError: false }));
			}
		} else {
			dispatch(removeActivities(activity));
		}
	};

	return (
		<div
			className={classes.root}
		>
			<Button
				variant="contained"
				color="secondary"
				disableRipple
				disableFocusRipple
				className={clsx(classes.button, klass)}
				fullWidth
				onClick={handleSelect}
			>
				<Typography variant="subtitle2">{activity.title}</Typography>
			</Button>
		</div>
	);
};
