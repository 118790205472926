import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getLoading as getAppLoading,
	getConstants,
	getErrors,
	requestApp,
} from 'app/slices/app/app';
import RequestProvider from 'rds/providers/Request';
import {
	set,
	selectShowBackgroundPicker,
	selectTextBackground,
} from 'app/slices/content-creation/create/text-component';
import {
	createEditingCanvas,
	selectEditingCanvas,

} from 'app/slices/content-creation/editing-canvas';
import {
	toggleControls,
} from 'app/slices/content-creation/experience';
import {
	CANVAS_OBJECT_MOVE,
	CANVAS_OBJECT_UP,
} from 'lib/editing-canvas/src/event-types';
import CreateText from 'routes/ContentCreation/Create/TextComponent/CreateText';
import EditText from 'routes/ContentCreation/Create/TextComponent/EditText';
import EditingCanvas from 'routes/ContentCreation/Create/EditingCanvas';
import PageLayout from 'routes/ContentCreation/Components/Layout/PageLayout';
import { makeStyles } from '@material-ui/styles';
import BackgroundPicker from './BackgroundPicker';

const OBJECT_Y_MAX = window.innerHeight - 32;

const clampToBounds = (el, rect) => {
	const bottomBorder = rect.y;

	if (bottomBorder > OBJECT_Y_MAX - rect.height) {
		el.style.top = `${OBJECT_Y_MAX - rect.height}px`;
	}
};

const useStyles = makeStyles({
	bkgimage: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
	},
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
	},
});

export default ({
	create,
	params,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const backgroundPicker = useSelector(selectShowBackgroundPicker);
	const editingCanvas = useSelector(selectEditingCanvas);
	const backgroundImage = useSelector(selectTextBackground);

	useEffect(() => {
		if (!editingCanvas) {
			dispatch(createEditingCanvas());
		}
	}, [editingCanvas]);

	// canvas move events
	const onCanvasObjectMove = (e, qparams) => {
		const { toggled } = e.target;

		const { el, rect } = qparams;
		clampToBounds(el, rect);

		if (!toggled) {
			e.target.toggled = true;

			dispatch(toggleControls({
				showControls: false,
				showTrashCan: !create,
			}));

			if (backgroundPicker) {
				dispatch(set({
					showBackgroundPicker: false,
				}));
			}
		}
	};

	const onCanvasObjectUp = (e) => {
		if (!backgroundPicker) {
			dispatch(set({ showBackgroundPicker: true }));
		}

		if (e.target.toggled) {
			dispatch(toggleControls({ showControls: true, showTrashCan: false }));
		}
		e.target.toggled = false;
	};

	useEffect(() => {
		if (editingCanvas) {
			editingCanvas.addListener(CANVAS_OBJECT_MOVE, onCanvasObjectMove);
			editingCanvas.addListener(CANVAS_OBJECT_UP, onCanvasObjectUp);
		}

		return () => {
			if (editingCanvas) {
				editingCanvas.removeListener(CANVAS_OBJECT_MOVE, onCanvasObjectMove);
				editingCanvas.removeListener(CANVAS_OBJECT_UP, onCanvasObjectUp);
			}
		};
	}, [editingCanvas]);

	if (!editingCanvas) {
		return (
			<div
				className={classes.bkgimage}
				style={{
					backgroundImage: `url('${backgroundImage.image}')`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
				}}
			/>
		);
	}

	return (
		<RequestProvider
			requests={{
				constants: {
					action: requestApp,
					selector: getConstants,
					loading: getAppLoading,
					errors: getErrors,
				},
			}}
		>
			<PageLayout
				currentTab={1}
				captureMode="text"
				params={params}
				bottomControl={(
					<BackgroundPicker />
				)}
			>
				<div className={classes.container}>
					<EditingCanvas />
				</div>
				{ create ? <CreateText /> : <EditText />}
			</PageLayout>
		</RequestProvider>
	);
};
