import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import HRNumbers from 'human-readable-numbers';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		userSelect: 'none',
		paddingRight: theme.spacing(1.5),
		paddingLeft: theme.spacing(1.5),
		width: '100%',
		overflow: 'hidden',
		justifyContent: 'center',
		textDecoration: 'none',
		color: 'inherit',
	},
	count: {
		display: 'inline',
		fontWeight: 600,
		marginRight: theme.spacing(),
	},
	label: {
		display: 'inline',
		color: theme.colors.neutral.C300,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textAlign: 'center',
	},
}));

export default ({
	stat,
	label,
	to,
}) => {
	const classes = useStyles();
	const string = stat ? HRNumbers.toHumanString(stat.robot) : '-';

	const Content = (
		<>
			<Typography variant="body1" className={classes.count}>
				{string}
				{' '}
			</Typography>
			<Typography variant="body1" className={classes.label}>{label}</Typography>
		</>
	);

	if (to) {
		return (
			<Link to={to} className={classes.root}>
				{Content}
			</Link>
		);
	}

	return (
		<div className={classes.root}>
			{Content}
		</div>
	);
};
