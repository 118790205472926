import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(7),
		color: theme.colors.neutral.C000,
	},
	value: {
		color: theme.colors.neutral.C300,
		width: 224,
		textAlign: 'right',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		paddingRight: theme.spacing(5),
		marginRight: theme.spacing(-3),
	},
	right: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}));

const Container = ({
	children,
	style,
	to,
}) => {
	const classes = useStyles();

	if (to) {
		return <Link to={to} className={classes.root} style={style}>{ children }</Link>;
	}

	return <div className={classes.root} style={style}>{ children }</div>;
};

export default ({
	title,
	value,
	marginTop,
	to,
}) => {
	const classes = useStyles();
	const theme = useTheme();

	const style = {};
	if (marginTop) {
		style.marginTop = theme.spacing(6.5);
	}

	return (
		<Container to={to} style={style}>
			<Typography
				className={classes.title}
				variant="body1"
			>
				{title}
			</Typography>
			<div className={classes.right}>
				<Typography
					className={classes.value}
					variant="body2"
				>
					{value}
				</Typography>
				<IconV3
					icon="ChevronRight"
					containerSize={24}
					style={{ opacity: to ? 1 : 0 }}
				/>
			</div>
		</Container>
	);
};
