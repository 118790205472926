class ECRMemory {
	constructor() {
		this.store = {};
	}

	_dto(uid, obj = {}) {
		return {
			uid,
			clip: obj.clip || 0,
			time: obj.time || 0,
		};
	}

	get(uid) {
		return this._dto(uid, this.store[uid]);
	}

	set(uid, values) {
		this.store[uid] = this._dto(uid, {
			...this.get(uid),
			...values,
		});
	}

	reset() {
		this.store = {};
	}
}

export default new ECRMemory();
