import { useDispatch } from 'react-redux';
import {
	changeRoute,
	set,
	reset,
} from 'app/slices/reports/layout';
import emEmitter from 'features/ExperienceModal/emitter';

const useNavigateReportModal = () => {
	const dispatch = useDispatch();

	const navigate = (route) => {
		dispatch(changeRoute(route));
	};

	navigate.enter = async ({
		type,
		label,
		title,
		id,
		reportedComment,
	}) => {
		if (type === 'experience' || type === 'comment') {
			emEmitter.emit('pause');
		}
		await dispatch(set({
			type,
			label,
			route: 'categories',
			open: true,
			title,
			reportedComment,
			id,
		}));
	};

	navigate.exit = (type) => {
		dispatch(reset());
		if (type === 'experience' || type === 'comment') {
			emEmitter.emit('play');
		}
	};

	return navigate;
};

export default useNavigateReportModal;
