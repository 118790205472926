const MAX_DURATION = 250;
const DISTANCE_TIME_MODIFIER = 3;

const easeInOutCubic = (x) => (x < 0.5 ? 4 * x * x * x : 1 - ((-2 * x + 2) ** 3) / 2);

class Animation {
	constructor(container, target) {
		this._container = container;
		this._target = target;
	}

	async tick(options, done) {
		const {
			container,
			startTime,
			duration,
			destination,
			start,
		} = options;

		if (this._destroyed) {
			return;
		}

		const now = window.performance.now() - startTime;

		if (now >= duration) {
			container.scrollLeft = destination;
			done && done();
			return;
		}

		const p = easeInOutCubic(now / duration);

		const diff = (destination - start) * p;
		container.scrollLeft = start + diff;

		requestAnimationFrame(() => {
			this.tick(options, done);
		});
	}

	destroy() {
		this._destroyed = true;
	}
}

const calculate = (container, target, offset) => {
	const containerWidth = container.getBoundingClientRect().width;
	const containerCenter = ((containerWidth + offset) / 2);
	const { x, width } = target.getBoundingClientRect();
	const elementCenter = x + (width / 2) + container.scrollLeft;
	const destination = elementCenter - containerCenter;

	return {
		distance: destination - container.scrollLeft,
		destination,
	};
};

export default (container, target, offset, done) => {
	const {
		distance,
		destination,
	} = calculate(container, target, offset);

	const duration = Math.min(Math.abs(distance * DISTANCE_TIME_MODIFIER), MAX_DURATION);
	const animation = new Animation(container, target);

	animation.tick({
		container,
		startTime: window.performance.now(),
		duration,
		destination,
		start: container.scrollLeft,
	}, done);

	return () => {
		animation?.destroy();
	};
};
