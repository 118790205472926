import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import getErrorsFromRejectedRequest from 'utils/get-errors-from-rejected-request';

const getRequest = async (opts) => {
	const stream = await navigator.mediaDevices.getUserMedia(opts);
	return stream;
};

const clean = (stream) => {
	stream.getTracks().forEach((t) => t.stop());
};

const constraints = {
	video: {
		aspectRatio: 1.7777777777777777,
		frameRate: 24,
		height: { min: 576, ideal: 720, max: 1080 },
		resizeMode: 'none',
		width: { min: 1024, ideal: 1280, max: 1920 },
	},
};

export const requestCameraPermission = createAsyncThunk(
	'requestCameraPermission',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const stream = await getRequest({
				audio: false,
				...constraints,
			});
			dispatch(set({ camera: true }));
			clean(stream);
		} catch (err) {
			rejectWithValue(getErrorsFromRejectedRequest(err));
		}
	},
);

export const requestMicrophonePermission = createAsyncThunk(
	'requestMicrophonePermission',
	async (_, { dispatch }) => {
		const stream = await getRequest({
			audio: true,
			video: false,
		});
		dispatch(set({ microphone: true }));
		clean(stream);
	},
);

export const permissionsSlice = createSlice({
	name: 'permissions',
	initialState: {
		microphone: null,
		camera: null,
		permissions: null,
		createPermissionModal: false,
	},

	reducers: {
		set: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
		},
	},

});

export default permissionsSlice;

const { name, reducer, actions } = permissionsSlice;
const { set } = actions;
export { set };

const getSlice = (state) => state[name];
export const getCamera = createSelector(getSlice, (slice) => slice?.camera);
export const getMicrophone = createSelector(getSlice, (slice) => slice?.microphone);
export const getPermissions = createSelector(getSlice, (slice) => slice?.permissions);
export const getCreatePermission = createSelector(getSlice, (slice) => slice?.createPermissionModal);
injectReducer(name, reducer);
