import React, {
	useCallback,
	useState,
	useEffect,
	useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ECRSlider from 'lib/ecr-slider';
import { set } from 'app/slices/comments';
import {
	getExperiences,
	dismiss,
	getRange,
	getVisibleExperience,
	getLoading,
	getOpenDirective,
	getSingleExperienceError,
	setExperienceToModify,
} from 'app/slices/experience-modal';
import { getDirection } from 'app/slices/layout';
import { requestUserIdentifiers } from 'app/slices/content-creation/experience';
import { useFormUrl, useQueryParam } from 'app/hooks/useQueryParams';
import { useSuspended } from 'components/Suspense/Suspense';
import { lockDisplay, unlockDisplay } from 'lib/wake-lock';
import SearchModal from 'routes/DestinationSearchModal/Search/SearchModal';
import { getModal } from 'app/slices/destinations-search/layout';
import { changeDestination } from 'app/slices/experience-view/edit';
import { getAlertMessage, updateAlertMessage } from 'app/slices/saves';
import scrollEmitter from 'layout/scrollEmitter';
import { removeSharedExperienceInEcr } from 'app/slices/search-explore/explore';
import SliderModal from 'rds/components/SliderModal';
import ECRTransition from './ECRTransition';
import emEmitter from './emitter';
import EmptyPane from './EmptyPane';
import Pane from './Pane';
import OverflowMenus from './ExperienceView/OverflowMenus';

const ACTIVE_THROTTLE = 400;
const DELAY = 350;
const TIME_OUT = 2000;
let { innerHeight } = window;

export default ({
	uid,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const suspended = useSuspended();
	const ref = useRef();
	const rendered = useRef(false);
	const errors = useSelector(getSingleExperienceError);
	const range = useSelector(getRange);
	const experiences = useSelector(getExperiences);
	const navDirection = useSelector(getDirection);
	const formUrl = useFormUrl();
	const commentId = useQueryParam('comment');
	const qparamUid = useQueryParam('experience');
	const panelParam = useQueryParam('panel');
	const isItshared = useQueryParam('shared');
	const [panelPane, setPanelPane] = useState(panelParam);
	const [panelOverlay, setPanelOverlay] = useState(false);
	const [playActive, setPlayActive] = useState(false);
	const [currentCommentId, setCurrentCommentId] = useState(commentId);
	const [slider, setSlider] = useState(null);
	const visibleExperience = useSelector(getVisibleExperience);
	const isDestinationSearchModalOpen = useSelector(getModal);
	const alertMessage = useSelector(getAlertMessage);
	const loading = useSelector(getLoading);
	const openDirective = useSelector(getOpenDirective) || {};

	useEffect(() => {
		if (openDirective?.type) {
			ref.current.openDirective = openDirective;
			if (ref.current?.ecrSlider) {
				ref.current.ecrSlider.openDirective = openDirective;
			}
		}
	}, [openDirective, ref]);

	useEffect(() => {
		dispatch(requestUserIdentifiers());
	}, []);

	useEffect(() => {
		scrollEmitter.emit('remember');
		lockDisplay();
		return () => unlockDisplay();
	}, []);

	useEffect(() => {
		if (!qparamUid) {
			dispatch(
				dismiss(ref.current.ecrSlider.dismissed),
			);
		}

		return () => {
			unlockDisplay();
		};
	}, [qparamUid]);

	useEffect(() => {
		let to;
		if (alertMessage) {
			to = setTimeout(() => {
				dispatch(updateAlertMessage(null));
			}, TIME_OUT);
		}
		return () => {
			clearTimeout(to);
		};
	}, [alertMessage]);

	useEffect(() => {
		let timeOut;
		if (commentId && ref && ref.current) {
			dispatch(set({ open: true }));

			timeOut = setTimeout(() => {
				ref?.current?.ecrSlider?.openBottomPane({
					instant: navDirection === 'back',
				});
			}, navDirection === 'back' ? 0 : DELAY);
		}
		return () => {
			clearTimeout(timeOut);
		};
	}, [ref, ref.current, commentId, navDirection]);

	useEffect(() => {
		// event emitter
		const eeDismiss = (bypass) => {
			if (ref?.current?.ecrSlider) {
				ref.current.ecrSlider.dismiss(bypass);
			}
		};

		// experience end event emitter
		const eeeeDismiss = (direction) => {
			if (ref?.current?.block) {
				return;
			}
			if (isItshared) {
				eeDismiss();
				return;
			}

			if (ref?.current?.ecrSlider) {
				if (direction === 'backward') {
					ref.current.ecrSlider.previous();
				} else {
					ref.current.ecrSlider.next(false);
				}
			}
		};

		if (ref?.current) {
			ref.current.ecrSlider = new ECRSlider(ref?.current, {
				startId: uid,
				height: innerHeight,
			});
			ref.current.ecrSlider.openDirective = openDirective;
			setSlider(ref.current.ecrSlider);

			ref.current.ecrSlider.on('touch-start', () => {
				emEmitter.emit('touch-start');
			});

			ref.current.ecrSlider.on('dismiss', async () => {
				const url = formUrl({
					params: {
						comment: undefined,
						experience: undefined,
						shared: undefined,
						trustedCreator: undefined,
					},
				});
				navigate(url, { replace: true, replaceState: true });
				dispatch(removeSharedExperienceInEcr());
			});

			ref.current.ecrSlider.on('play', () => {
				if (ref.current.ecrSlider.bottomPaneOpen || ref.current.block) {
					return;
				}
				emEmitter.emit('play');
			});

			ref.current.ecrSlider.on('pause', () => {
				emEmitter.emit('pause');
			});

			ref.current.ecrSlider.on('set-pane', async ({ id }) => {
				if (ref?.current?.ecrSlider && !ref.current.ecrSlider._locked) {
					const url = formUrl({
						params: {
							experience: id,
							shared: undefined,
						},
					});
					await setPlayActive(false);
					navigate(url, { replace: true });
				}
			});

			ref.current.ecrSlider.on('tap', ({ side }) => {
				emEmitter.emit('tap', { side });
			});
		}

		const bpFill = () => {
			ref.current.ecrSlider.bottomPaneFill();
		};

		const bpUnfill = () => {
			ref.current.ecrSlider.bottomPaneUnfill();
		};

		emEmitter.addListener('bp-unfill', bpUnfill);
		emEmitter.addListener('bp-fill', bpFill);
		emEmitter.addListener('dismiss', eeDismiss);
		emEmitter.addListener('experience-end', eeeeDismiss);

		return () => {
			if (ref?.current?.ecrSlider) {
				ref?.current?.ecrSlider.destroy();
			}
			emEmitter.removeListener('bp-unfill', bpUnfill);
			emEmitter.removeListener('bp-fill', bpFill);
			emEmitter.removeListener('dismiss', eeDismiss);
			emEmitter.removeListener('experience-end', eeeeDismiss);
		};
	}, [ref]);

	useEffect(() => {
		const onPanelClose = () => {
			if (ref?.current?.block) {
				ref.current.block = true;
			}
			if (ref?.current?.ecrSlider) {
				ref.current.ecrSlider.disableGesture();
			}
			emEmitter.emit('play');
			setCurrentCommentId(null);
			setPanelOverlay(false);
		};

		const onPanelCloseFinished = () => {
			if (ref?.current?.block) {
				ref.current.block = false;
			}
			if (ref?.current?.ecrSlider) {
				ref.current.ecrSlider.enableGesture();
			}
			setPanelPane(null);
			dispatch(set({ activeComment: null }));
			const url = formUrl({
				params: {
					panel: undefined,
					comment: undefined,
				},
			});
			// use window history to track changes, we dont need to send this through redux
			window.history.replaceState(null, null, url);
		};

		const onPanelOpen = () => {
			if (ref?.current?.block) {
				ref.current.block = true;
			}
			if (ref?.current?.ecrSlider) {
				ref.current.ecrSlider.disableGesture();
			}
			setPanelOverlay(true);
			emEmitter.emit('pause');
		};

		const onPanelOpenFinished = () => {
			if (ref?.current?.ecrSlider) {
				ref.current.ecrSlider.enableGesture();
			}
		};

		const onTouchEnd = (mode) => {
			if (!panelPane && (mode === 'panemove' || mode === null)) {
				emEmitter.emit('play');
			}
		};

		if (ref.current?.ecrSlider) {
			ref.current.ecrSlider.addListener('bottom-panel:close', onPanelClose);
			ref.current.ecrSlider.addListener('bottom-panel:close:finished', onPanelCloseFinished);
			ref.current.ecrSlider.addListener('bottom-panel:open', onPanelOpen);
			ref.current.ecrSlider.addListener('bottom-panel:open:finished', onPanelOpenFinished);
			ref.current.ecrSlider.addListener('touch-end', onTouchEnd);
		}

		return () => {
			if (ref.current?.ecrSlider) {
				ref.current.ecrSlider.removeListener('bottom-panel:close', onPanelClose);
				ref.current.ecrSlider.removeListener('bottom-panel:close:finished', onPanelCloseFinished);
				ref.current.ecrSlider.removeListener('bottom-panel:open', onPanelOpen);
				ref.current.ecrSlider.removeListener('bottom-panel:open:finished', onPanelOpenFinished);
				ref.current.ecrSlider.removeListener('touch-end', onTouchEnd);
			}
		};
	}, [ref, panelPane]);

	useEffect(() => {
		if (!ref?.current?.ecrSlider) {
			return;
		}

		const es = ref.current.ecrSlider;
		const isVisible = !!ref.current.getClientRects().length;

		if (!suspended && isVisible && qparamUid) {
			es.lockPane(qparamUid);
		}
	}, [ref, suspended, panelPane, qparamUid, navDirection, range]);

	useEffect(() => {
		if (slider) {
			emEmitter.emit(isDestinationSearchModalOpen ? 'pause' : 'play');
		}
	}, [isDestinationSearchModalOpen, slider]);

	useEffect(() => {
		const time = rendered.current ? ACTIVE_THROTTLE : 0;
		const t = setTimeout(() => {
			setPlayActive(true);

			if (time > 0) {
				rendered.current = false;
			}
		}, time);

		rendered.current = true;

		if (visibleExperience) {
			dispatch(setExperienceToModify(visibleExperience));
		}

		return () => clearTimeout(t);
	}, [visibleExperience]);

	const openPanel = useCallback((panel) => {
		emEmitter.emit('pause');
		if (ref?.current) {
			ref.current.block = true;
		}

		setPanelPane(panel);
		ref?.current?.ecrSlider.openBottomPane();
	}, [visibleExperience]);

	if (!experiences) {
		return null;
	}

	const handleSelect = useCallback((destination, custom) => {
		if (visibleExperience) {
			dispatch(changeDestination({
				custom,
				destination,
				experience: visibleExperience,
			}));
		}
	}, [visibleExperience]);

	const onClose = useCallback(() => {
		ref?.current?.ecrSlider?.closeBottomPane();
	}, []);

	if (window.innerHeight > innerHeight) {
		innerHeight = window.innerHeight;
	}

	return (
		<>
			<OverflowMenus slider={slider} />
			<ECRTransition fRef={ref}>
				<EmptyPane size={1} />
				<EmptyPane size={range[0]} />
				{experiences.map((e) => (
					<Pane
						key={e.uid}
						active={playActive && e.uid === uid}
						isShown={e.uid === uid}
						errors={errors}
						panel={{
							pane: panelPane,
							overlay: panelOverlay,
							context: { comment: currentCommentId },
						}}
						{...e}
						onClose={onClose}
						slider={slider}
						openPanel={openPanel}
						alertMessage={alertMessage}
						loading={loading}
						height={innerHeight}
					/>
				))}
				<EmptyPane size={1} />
			</ECRTransition>
			<SliderModal open={isDestinationSearchModalOpen}>
				<SearchModal
					onSelect={handleSelect}
					onRemoveSelected={handleSelect}
					onClose={() => emEmitter.emit('play')}
					onOpen={() => emEmitter.emit('pause')}
					title="Add Destination"
					showSelected
				/>
			</SliderModal>
		</>
	);
};
