import { createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import deduplicateList from 'utils/deduplicate-List';
import {
	requestECRExperiences,
	requestECRMore,
	requestDeleteMyEcrExperience,
} from './thunks';

const initialState = {
	loading: false,
	errors: null,
	ecrExperiences: [],
	ecr: {
		next: null,
		prev: null,
	},
	currentCreator: null,
};

const name = 'destinationEcrExperiences';

const destinationEcrExperiencesSlice = createSlice({
	name,
	initialState,
	reducers: {
		update: (state, action) => {
			const previousState = [...state.ecrExperiences];
			const newState = [];
			for (let i = 0; i < previousState.length; i++) {
				if (previousState[i].uid === action.payload.uid) {
					previousState[i] = action.payload;
				}
				newState.push(previousState[i]);
			}
			state.ecrExperiences = newState;
		},
		resetECR: (state) => {
			state.ecrExperiences = [];
			state.ecr.next = null;
			state.ecr.prev = null;
			state.currentCreator = null;
		},
		setCurrentCreator: (state, action) => {
			state.currentCreator = action.payload;
		},
	},
	extraReducers: {
		[requestECRExperiences.fulfilled]: (state, action) => {
			if (action.payload) {
				const { experiences, next, prev } = action.payload;
				state.ecrExperiences = experiences;
				state.ecr.next = next?.href ? next.href : next;
				state.ecr.prev = prev?.href ? prev.href : prev;
			}
		},
		[requestECRMore.fulfilled]: (state, action) => {
			if (action.payload) {
				const { experiences, next } = action.payload;
				const newExperiences = experiences || [];
				state.ecrExperiences = deduplicateList([...state.ecrExperiences, ...newExperiences], {}, 'uid');
				state.ecr.next = next?.href ? next.href : next;
			}
		},
		[requestDeleteMyEcrExperience.fulfilled]: (state, action) => {
			const { destinations, uid } = action.payload;
			if (destinations.length && destinations[0].uid === state.uid) {
				state.ecrExperiences = state.ecrExperiences.filter((exp) => exp.uid !== uid);
			}
		},
	},
});

export const {
	update,
	resetECR,
	setCurrentCreator,
} = destinationEcrExperiencesSlice.actions;
export default destinationEcrExperiencesSlice.reducer;

injectReducer(name, destinationEcrExperiencesSlice.reducer);
