import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectClipCount } from 'app/slices/content-creation/experience';
import { getPort, fetchPermissions } from 'app/android-service';
import Legacy from './Legacy';
import Integrated from './Integrated';

export default ({
	autoOpen,
	params,
}) => {
	const clipCount = useSelector(selectClipCount);
	const [proceed, setProceed] = useState(null);
	const [disabled, setDisabled] = useState(0);

	useEffect(async () => {
		const port = getPort();

		// if anything is in the clip tray, use whatever current permissions are
		if (clipCount) {
			const go = await fetchPermissions();
			setProceed(go);
			return;
		}

		// if the port is not null & > 0 fine
		setProceed(!!port);
	}, []);

	if (proceed === null) {
		return null;
	}

	if (!disabled && proceed === true) {
		return (
			<Integrated
				autoOpen={autoOpen}
				onQuickDisable={() => setDisabled(Date.now())}
				params={params}
			/>
		);
	}

	return <Legacy disabled={disabled} />;
};
