let wakeLock = null;

/**
 *
 * @usage

import { lockDisplay, unlockDisplay } from 'lib/wake-lock';
useEffect(() => {
	lockDisplay();
	return () => unlockDisplay();
}, []);

 */

export const unlockDisplay = async () => {
	if (!wakeLock) {
		return false;
	}

	try {
		await wakeLock.release();
		wakeLock = null;
	} catch (_) {
		return false;
	}
	return true;
};

// idempotent locking and unlocking;
export const lockDisplay = async () => {
	if (wakeLock) {
		return true;
	}

	try {
		wakeLock = await navigator.wakeLock.request('screen');
	} catch (err) {
		return false;
	}
	return true;
};
