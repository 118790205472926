import { createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import {
	requestDestinationAccount,
	requestFollowDestinationAccount,
	requestUnfollowDestinationAccount,
} from './thunks';

import updateAccount from '../utils';

const updateFollowerInteraction = (state, uid, isFollowing, followerChange) => {
	state.accounts[uid].interactions.callerIsFollowing = isFollowing;
	const currentFollowers = Number(state.accounts[uid].stats.followers.robot);
	state.accounts[uid].stats.followers.robot = currentFollowers + followerChange;
};

const initialState = {
	accounts: {},
	loading: false,
	errors: null,
	notFound: false,
};

const name = 'destinationAccounts';

const destinationAccountsSlice = createSlice({
	name,
	initialState,
	reducers: {
		change: (state, action) => {
			Object.assign(state, action.payload);
		},
		reset: (state) => {
			state.notFound = false;
			state.errors = null;
		},
		updateExperienceCount: (state, action) => {
			const { uid, countChange } = action.payload;
			state.accounts[uid].stats.experienceCount.robot += countChange;
		},
	},
	extraReducers: {
		[requestDestinationAccount.pending]: (state) => {
			state.loading = true;
			state.errors = null;
			state.notFound = false;
		},
		[requestDestinationAccount.fulfilled]: (state, action) => {
			const {
				title,
				subtitle,
				uid,
				thumbnail,
				interactions,
				stats,
				parent,
			} = action.payload;
			updateAccount(state, uid, {
				title,
				subtitle,
				uid,
				thumbnail,
				interactions,
				stats,
				parent,
			});
			state.errors = null;
			state.loading = false;
		},
		[requestDestinationAccount.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
			state.notFound = true;
		},
		[requestFollowDestinationAccount.pending]: (state, action) => {
			const uid = action.meta.arg;
			updateFollowerInteraction(state, uid, true, 1);
		},
		[requestFollowDestinationAccount.fulfilled]: (state) => {
			state.errors = null;
		},
		[requestFollowDestinationAccount.rejected]: (state, action) => {
			const uid = action.meta.arg;
			updateFollowerInteraction(state, uid, false, -1);
			state.errors = action.payload;
		},
		[requestUnfollowDestinationAccount.pending]: (state, action) => {
			const uid = action.meta.arg;
			updateFollowerInteraction(state, uid, false, -1);
		},
		[requestUnfollowDestinationAccount.fulfilled]: (state) => {
			state.errors = null;
		},
		[requestUnfollowDestinationAccount.rejected]: (state, action) => {
			const uid = action.meta.arg;
			updateFollowerInteraction(state, uid, true, 1);
			state.errors = action.payload;
		},

	},
});

export const {
	change,
	reset,
	updateExperienceCount,
} = destinationAccountsSlice.actions;

injectReducer(name, destinationAccountsSlice.reducer);

export default destinationAccountsSlice.reducer;
