import { createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import colors from './index';

const initialColors = { ...colors };

if (window.localStorage && window.localStorage.getItem('colors')) {
	try {
		const storedColors = window.JSON.parse(window.localStorage.getItem('colors'));
		Object.keys(storedColors).forEach((key) => {
			initialColors[key] = storedColors[key];
		});
	} catch (err) {
		// default color
	}
}

const colorsSlice = createSlice({
	name: 'colors',
	initialState: {
		colors: initialColors,
	},
	reducers: {
		change: (state, action) => {
			const { key, colors: newColors } = action.payload;
			state.colors[key] = newColors;
		},
		reset: {},
	},
});

const { name, reducer, actions } = colorsSlice;
const { change } = actions;

export { name, change };

export const getSlice = (state) => state[name];
export const getColors = createSelector(getSlice, (slice) => slice?.colors);

export default colorsSlice;

injectReducer(name, reducer);
