import { parseGIF, decompressFrames } from 'gifuct-js';

export const loadGifDataFromArrayBuffer = async (buffer) => {
	const gif = await parseGIF(buffer);
	const frames = await decompressFrames(gif, true);
	return frames;
};

export default async (url) => {
	const response = await fetch(url);
	const buffer = await response.arrayBuffer();
	return loadGifDataFromArrayBuffer(buffer);
};
