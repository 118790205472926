import React, {
	useState, useCallback, useEffect, useRef,
} from 'react';
import ProgressiveImage from 'react-progressive-image';
import { makeStyles } from '@material-ui/core';
import Timer from 'utils/Timer';
import ErrorPane from 'features/ExperienceModal/ErrorPane';
import getTouchState from 'lib/touch-tracker';
import emitter from '../../emitter';

const useStyles = makeStyles((theme) => ({
	view: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		touchAction: 'none',
	},
	spinnerContainer: {
		position: 'absolute',
		top: 'calc(50% - 25px)',
		left: 'calc(50% - 25px)',
	},
	darkOverlay: {
		position: 'absolute',
		left: 0,
		top: 0,
		zIndex: 1,
		bottom: 0,
		right: 0,
		width: '100%',
		height: '100%',
		background: theme.colors.neutral.C900,
		opacity: 0.4,
	},
}));

const DURATION = 2000;
export default ({
	active,
	activeClip,
	play,
	src,
	onEnd,
	time = 0,
	playCount,
	placeHolder,
	onTimeUpdate,
	onLoad,
	onError,
}) => {
	const classes = useStyles();
	const ref = useRef();
	const [error, setError] = useState(null);
	const [retries, setRetries] = useState([0, 300, 900, 5000]);
	const [loaded, setLoaded] = useState(false);

	const reloadImage = () => {
		const timestamp = new Date().getTime();
		ref.current.src = `${src}?t=${timestamp}`;
	};

	useEffect(() => {
		if (active && activeClip) {
			emitter.addListener('reloadClip', reloadImage);
		}
		return () => {
			emitter.removeListener('reloadClip', reloadImage);
		};
	}, [active, activeClip]);

	const loadCallback = useCallback(() => {
		if (!ref.current) {
			return;
		}
		setLoaded(true);
		emitter.emit('active', {
			height: ref.current.naturalHeight,
			width: ref.current.naturalWidth,
		});
	}, [ref, active]);

	useEffect(() => {
		if (ref?.current?.t && time === 0) {
			ref.current.t.currentTime = 0;
		}
	}, [ref, time]);

	useEffect(() => {
		let t;
		if (ref?.current) {
			if (!ref?.current?.t) {
				ref.current.t = new Timer({
					duration: DURATION,
					time,
					onload: (done) => {
						ref.current.onload = () => {
							onLoad && onLoad();
							done();
						};
					},
					isLoaded: () => ref.current.complete && ref.current.naturalHeight !== 0,
				});

				t = ref.current.t;
			} else {
				t = ref.current.t;
			}

			if (active) {
				emitter.emit('active', {
					height: ref.current.naturalHeight,
					width: ref.current.naturalWidth,
				});

				if (play) {
					if (playCount && playCount !== t.playCount) {
						t.currentTime = 0;
					}

					t.play();
					t.playCount = playCount;
				} else {
					t.pause();
				}
			} else {
				t.pause();
				t.currentTime = 0;
			}

			t.on('timeupdate', () => {
				onTimeUpdate && onTimeUpdate(t.currentTime, t.duration, t);

				if (getTouchState()) {
					emitter.emit('pause');
				}
			});

			t.on('end', () => {
				t.currentTime = 0;
				onEnd && onEnd();
			});
		}

		return () => {
			if (t) {
				t.removeAllListeners('end');
				t.removeAllListeners('timeupdate');
			}
		};
	}, [ref, src, active, play, playCount]);

	const handleError = useCallback(() => {
		const image = ref.current;
		const timeout = retries.shift();
		if (typeof timeout === 'undefined') {
			setError([{ text: 'Something went wrong, Refresh the page' }]);
			onError();
			return;
		}
		setTimeout(() => {
			if (!image) {
				setError([{ text: 'Something went wrong, Refresh the page' }]);
				return;
			}
			const imageSrc = image.src;
			image.src = null;
			image.src = imageSrc;
			setRetries(retries);
		}, timeout);
	}, [retries, ref]);

	return (
		<>
			<ProgressiveImage src={src} placeholder={placeHolder}>
				{(s) => (
					<>
						<img
							style={{ opacity: activeClip && loaded ? 1 : 0 }}
							alt=""
							src={s}
							onError={handleError}
							onLoad={loadCallback}
							className={classes.view}
							ref={ref}
						/>
					</>
				)}
			</ProgressiveImage>
			{active && error
				&& <ErrorPane errors={error} />}
		</>
	);
};
