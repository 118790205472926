import {
	getExperiences as theirGetExperiences,
	update as theirUpdate,
	getLoadingExperiences as theirGetLoadingExperiences,
	requestUserExperiences as theirRequestUserExperiences,
	requestLoadMoreExperiences as theirRequestLoadMoreExperiences,
	getHasMoreToLoad as theirGetHasMoreToLoad,
} from '../experiences';

import {
	getMyExperiences,
	getLoadingMyExperiences,
	requestMyExperiences,
	requestLoadMoreMyExperiences,
	getHasMoreToLoad as getMyHasMoreToLoad,
	requestDeleteMyExperience,
	update,
} from '../my-experiences';

export const theirUaEcr = {
	selector: theirGetExperiences,
	requestExperiences: theirRequestUserExperiences,
	requestMore: theirRequestLoadMoreExperiences,
	loadingSelector: theirGetLoadingExperiences,
	hasMoreToLoad: theirGetHasMoreToLoad,
	update: theirUpdate,
};

export const myUaEcr = {
	selector: getMyExperiences,
	requestExperiences: requestMyExperiences,
	requestMore: requestLoadMoreMyExperiences,
	loadingSelector: getLoadingMyExperiences,
	hasMoreToLoad: getMyHasMoreToLoad,
	update,
	deleteMyExperience: requestDeleteMyExperience,
};
