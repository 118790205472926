import { registerRoute } from 'Router/registry';
import registerRedirects from 'Router/register-redirects';
import savedECR from 'app/slices/saves/ecr';
import { register } from 'features/ExperienceModal/ECRProvider';
import Skeleton from 'rds/components/Skeletons/SecondaryPage';
import { requestUserSavedExperiences } from 'app/slices/saves';
import Header from './Header';
import Page from './Page';

register('saves', savedECR);

registerRedirects([
	['/:@username/saved', '/my-account/saved'],
	['/:username/saved', '/my-account/saved'],
]);

const loadData = [
	({ dispatch }) => dispatch(requestUserSavedExperiences()),
];

registerRoute({
	authorization: true,
	Component: Page,
	Header,
	loadData,
	Skeleton,
	ecrSelectorName: 'saves',
	routeOptions: {
		path: '/my-account/saved',
	},
	transition: 'slide',
	navigationMenu: '/my-account',
	root: false,
});

export default Page;
