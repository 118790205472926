import React from 'react';
import {
	makeStyles,
	Button,
} from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		whiteSpace: 'nowrap',
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	button: {
		height: 28,
		backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
			color: theme.palette.type === 'dark' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		},
	},
	title: {
		color: theme.colors.neutral.C100,
	},
}));

export default ({
	activity,
}) => {
	const classes = useStyles();

	return (
		<div
			className={classes.root}
		>
			<Button
				variant="contained"
				color="primary"
				disableRipple
				disableFocusRipple
				className={classes.button}
				fullWidth
			>
				<Typography className={classes.title} variant="body1">{activity?.title}</Typography>
			</Button>
		</div>
	);
};
