import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(7),
		color: theme.colors.neutral.C000,
	},
}));

const Container = ({
	children,
	marginTop,
	onClick,
	to,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const style = {};

	if (marginTop) {
		style.marginTop = theme.spacing(6.5);
	}

	if (to) {
		return <Link to={to} className={classes.root} style={style}>{ children }</Link>;
	}

	return <div className={classes.root} onClick={onClick} style={style}>{ children }</div>;
};

export default ({
	title, onClick, to, marginTop,
}) => {
	const classes = useStyles();

	return (
		<Container to={to} onClick={onClick} marginTop={marginTop}>
			<Typography
				className={classes.title}
				variant="body1"
			>
				{title}
			</Typography>
			<IconV3
				icon="ChevronRight"
				containerSize={24}
			/>
		</Container>
	);
};
