import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
	Routes,
	Route,
	useLocation,
	matchRoutes,
} from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import clsx from 'clsx';
import ContextMenu from 'components/ContextMenu';
import ContentCreationRouter from 'routes/ContentCreation/Router';
import useCountRouterCalls from 'app/hooks/useCountRouterCalls';
import './config';
import RootPages from 'routes/RootPage/RootPages';
import { setDirection } from 'app/slices/layout';
import { usePageScrollEvents } from 'routes/RootPage/hooks';
import { addKeyToKeys, determineDirection } from './hooks/useNavigation';
import { PageStateProvider } from './PageContext';
import { getRegisteredRootRoutes, getRegisteredRoutes } from './registry';

const RootPage = () => <div />;

const routes = getRegisteredRoutes().map((route) => ({
	...route,
	Component: route.Component,
}));

const rootRoutes = getRegisteredRootRoutes().map((route) => ({
	...route,
	Component: RootPage,
}));

function TransitionGroups() {
	const dispatch = useDispatch();
	const direction = useRef('forward');
	const timeout = useRef(0);
	const last = useRef(null);
	const location = useLocation();
	const transitionRef = useRef();
	const deferredTransitionRef = useRef('none');
	usePageScrollEvents();
	useCountRouterCalls();

	useEffect(() => {
		dispatch(setDirection(direction?.current));
	}, [location, direction]);

	if (location.key !== last.current) {
		direction.current = determineDirection(last.current, location.key);
		last.current = location.key;
	}
	addKeyToKeys(location.key);

	const m = matchRoutes(routes, location);
	const isRoot = m ? m[0]?.route?.root : false;

	transitionRef.current = m ? m[0]?.route?.transition : 'none';
	timeout.current = m ? m[0]?.route?.timeout : 0;
	if (isRoot && direction.current === 'forward') {
		// If going forward and root, we want to dismiss always
		transitionRef.current = 'dismiss';
	} else if (direction.current !== 'forward' && deferredTransitionRef.ref) {
		// If going back, we might want to take the last route's transition
		transitionRef.current = deferredTransitionRef.ref;
	}

	deferredTransitionRef.current = m ? m[0]?.route?.transition : 'none';

	const handleClassToggle = (state) => (node) => {
		if (!node) {
			return;
		}

		if (transitionRef.current === 'dismiss') {
			node.style.display = 'none';
			return;
		}

		const fixedNodes = node.querySelectorAll('[data-fix-position-fixed="true"]');
		if (fixedNodes.length) {
			const pr = document.querySelector('.page-root');
			fixedNodes.forEach((f) => {
				if (state === 'exiting') {
					f.style.top = `${pr.scrollTop}px`;
				}
				if (state === 'entering') {
					f.style.top = 0;
				}
			});
		}

		node.style['transition-duration'] = `${timeout.current}ms`;

		if (state === 'entering' || state === 'exiting') {
			const initKlass = state === 'entering' ? 'enter' : 'exit';
			const activeKlass = state === 'entering' ? 'enter-active' : 'exit-active';

			node.setAttribute('class', clsx('route-transition', `${transitionRef.current}-${initKlass}`, direction.current));
			setTimeout(() => {
				node.setAttribute('class', clsx('route-transition', `${transitionRef.current}-${activeKlass}`, direction.current));
				setTimeout(() => {
					node.setAttribute('class', 'route-transition');
					node.style.removeProperty('transition-duration');
				}, timeout.current);
			}, 1);
		}
	};

	return (
		<div style={{ position: 'relative' }}>
			<TransitionGroup>
				<Transition
					key={location.pathname}
					timeout={timeout.current}
					onEnter={(node) => handleClassToggle('entering')(node)}
					onEntered={(node) => handleClassToggle('entered')(node)}
					onExit={(node) => handleClassToggle('exiting')(node)}
					onExited={(node) => handleClassToggle('exited')(node)}
				>
					<Routes location={location}>
						{routes.map(({
							options,
							routeOptions,
							Component,
						}) => (
							<Route
								key={routeOptions.path}
								path={routeOptions.path}
								element={(
									<Component {...options} />
								)}
							/>
						))}
						{rootRoutes.map(({
							options,
							routeOptions,
							Component,
						}) => (
							<Route
								key={routeOptions.path}
								path={routeOptions.path}
								element={(
									<Component {...options} />
								)}
							/>
						))}
					</Routes>
				</Transition>
			</TransitionGroup>
			<ContextMenu />
			<ContentCreationRouter />
			<RootPages
				transition={transitionRef.current}
			/>
		</div>
	);
}

const Router = () => (
	<PageStateProvider>
		<TransitionGroups />
	</PageStateProvider>
);

export default Router;
