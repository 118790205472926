import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		textAlign: 'left',
		marginLeft: 2,
	},
});

export default ({ children }) => {
	const classes = useStyles();

	return <div className={classes.root}>{children}</div>;
};
