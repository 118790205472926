import React from 'react';
import { makeStyles } from '@material-ui/core';
import images from './assets';

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		paddingBottom: '32%',
		borderRadius: 2,
	},
}));

export default ({ picture }) => {
	const classes = useStyles();

	const style = {};

	style.backgroundImage = `url('${images[`image${picture}`]}')`;

	return <div className={classes.root} style={style} />;
};
