import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	isSessionAuthorized, hasSession, hasPermissions,
} from 'app/slices/authentication/session/session';

const useRequireSession = (options = {}) => {
	const {
		authorizePage = '/signin',
		redirect = true,
		requiredPermissions = [],
		forbiddenPermissions = [],
	} = options;
	const dispatch = useDispatch();

	const session = useSelector(hasSession);
	const required = useSelector((state) => hasPermissions(state, requiredPermissions));
	const forbidden = useSelector((state) => hasPermissions(state, forbiddenPermissions));
	const authorized = useSelector(isSessionAuthorized);

	useEffect(() => {
		if (session) {
			return;
		}

		if (authorized) {
			return;
		}

		if (redirect) {
			let redirectTo;

			if (window?.location?.pathname && window.location.pathname !== authorizePage) {
				redirectTo = window.location.pathname;
			}

			if (!redirectTo) {
				// navigate(authorizePage);
				// return;
			}

			// navigate(`${authorizePage}?redirect=${redirectTo}`);
		}
	}, [
		session,
		dispatch,
		redirect,
		authorized,
	]);

	const loading = useMemo(() => authorized && !session, [
		session,
		authorized,
	]);

	return useMemo(() => ([
		loading,
		required,
		forbidden,
	]), [
		loading,
		required,
		forbidden,
	]);
};

export default useRequireSession;
