import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	headerText: {
		marginTop: theme.spacing(12),
		color: theme.colors.neutral.C300,
		textAlign: 'center',
	},
	item: {
		position: 'relative',
		display: 'inline-block',
		height: 140,
		width: 80,
		marginLeft: 6,
		marginRight: 6,
		userSelect: 'none',
		flex: '0 0 auto',
	},
	image: {
		position: 'relative',
		height: '100%',
		width: '100%',
		borderRadius: 2,
		boxShadow: 'inset 0 0 0 1px #93A0AE',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundColor: theme.colors.neutral.C900,
	},
	outerContainer: {
		width: '100%',
		height: 140,
		position: 'relative',
		overflowX: 'scroll',
		overflowY: 'hidden',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(3),
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	innerContainer: {
		userSelect: 'none',
		overflow: 'visible',
	},
	layer: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		background: theme.colors.neutral.C900,
		opacity: 0.6,
		zIndex: 2,
	},
}));

export default ({ clips }) => {
	const classes = useStyles();
	const adjustment = clips.length * 44;
	const paddingLeft = `calc(50vw - ${adjustment}px)`;

	return (
		<>
			<Typography className={classes.headerText} variant="body1" />
			<div className={classes.outerContainer} style={{ paddingLeft }}>
				<div className={classes.innerContainer}>
					{
						clips.map((c) => {
							const src = c?.outputs?.thumbnail?.small?.jpg || c?.outputs?.photo?.small?.jpg;
							return (
								<div className={classes.item} key={c.clipUid}>
									<div className={classes.image} style={{ backgroundImage: `url(${src})` }} />
									<div className={classes.layer} />
								</div>
							);
						})
					}
				</div>
			</div>
		</>
	);
};
