import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Hexagon from 'rds/components/Hexagon';
import { useSelector } from 'react-redux';
import useTheme from 'rds/theme/useRdsTheme';
import IconV3 from 'rds/components/IconV3';
import {
	getTheirInteractions,
	getMyIdentifiers,
	getTheirIdentifiers,
	getMyCustomIdentifiers,
	getTheirCustomIdentifiers,
	getMyLoadingProfile,
	getTheirLoadingProfile,
	getFirstVisit,
} from 'app/slices/user-account-v2/accounts';
import WaveSkeleton from 'components/WaveSkeleton';
import AddModal from './AddModal';
import EditModal from './EditModal';
import SelectedIdentifier from './SelectedIdentifier';
import HoneyComb from './HoneyComb';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	spacer: {
		paddingRight: theme.spacing(6),
	},
	container: {
		marginRight: theme.spacing(2),
	},
	identifier: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'absolute',
		height: '92%',
		width: '90%',
		whiteSpace: 'normal',
		overflow: 'hidden',
		marginLeft: '5%',
		zIndex: 3,
	},
	honeyComb: {
		width: '100%',
		textAlign: 'center',
		marginBottom: theme.spacing(5),
	},

}));

export default ({ isMe }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [openModal, setOpenModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [editingValue, setEditingValue] = useState(null);
	const firstVisit = useSelector(getFirstVisit);
	const interactions = useSelector(getTheirInteractions);
	const blocked = interactions?.callerIsBlocking;
	const identifiers = useSelector(isMe ? getMyIdentifiers : getTheirIdentifiers);
	const customIdentifiers = useSelector(isMe ? getMyCustomIdentifiers : getTheirCustomIdentifiers);
	const allIdentifiers = identifiers.concat(customIdentifiers);
	const orderedIdentifiers = allIdentifiers.sort((a, b) => a.title.localeCompare(b.title));
	const loading = useSelector(isMe ? getMyLoadingProfile : getTheirLoadingProfile);

	if (blocked) {
		return null;
	}

	const editIdentifier = (identifier) => {
		setEditingValue(identifier);
		setEditModal(!editModal);
	};

	return (
		<>
			<div className={classes.root}>
				{
					isMe && !firstVisit && !loading
						? (
							<div className={classes.container}>
								<Hexagon
									drawLabel={() => (
										<div className={classes.identifier}>
											<IconV3
												icon="Plus"
											/>
										</div>
									)}
									onClick={() => {
										setOpenModal(!openModal);
									}}
									width={78}
									height={92}
									hexagonStyles={{
										background: theme.palette.background.page,
									}}
									selectedBorder={
										theme.colors.neutral.C000
									}
								/>
							</div>
						)
						: null
				}
				{
					!firstVisit && !loading && orderedIdentifiers && orderedIdentifiers.map((identifier) => (
						<SelectedIdentifier onEdit={editIdentifier} identifier={identifier} key={identifier.title} />
					))
				}
				{isMe && firstVisit && (
					<div className={classes.honeyComb}>
						<HoneyComb
							openModal={() => {
								setOpenModal(!openModal);
							}}
						/>
					</div>
				)}
				{loading
				&& (
					<WaveSkeleton style={{ height: 100 }} />
				)}
			</div>
			<AddModal
				open={openModal}
				onClose={() => {
					setOpenModal(false);
				}}
			/>
			<EditModal
				open={editModal}
				editingIdentifier={editingValue}
				onClose={() => {
					setEditModal(false);
					setEditingValue(null);
				}}
			/>
		</>
	);
};
