import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import emitter from '../../emitter';

const TIME_OUT = 150;
const useStyles = makeStyles({
	left: {
		touchAction: 'auto',
		position: 'absolute',
		top: 0,
		left: 0,
	},
	right: {
		touchAction: 'auto',
		position: 'absolute',
		top: 0,
		right: 0,
	},
});

export default ({ back, title, onClose }) => {
	const classes = useStyles();

	const handleIconClick = (e) => {
		e.stopPropagation();
		if (back) {
			onClose();
			emitter.emit('pause');
			setTimeout(() => {
				emitter.emit('dismiss');
			}, TIME_OUT);
		} else if (onClose) {
			onClose();
		}
	};

	return (
		<>
			<div
				data-ignoreslider="true"
				className={classes.right}
				onClick={handleIconClick}
			>
				<IconV3
					icon="Cancel"
					size={24}
					containerSize={48}
				/>
			</div>
			<Typography variant="body1">{title}</Typography>
		</>
	);
};
