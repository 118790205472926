import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	change, selectActiveTrashCan, selectFullTrashCan, selectShowTrashCan,
} from 'app/slices/content-creation/editor/layout';
import emptyTrashCan from './empty_trash.png';
import fullTrashCan from './full_trash.png';

const useStyles = makeStyles({
	root: {
		position: 'absolute',
		left: 'calc(50% - 25px)',
		width: '50px',
		height: 70,
		bottom: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	img: {
		width: 24,
		height: 26,
	},
});

function TrashCan() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const active = useSelector(selectActiveTrashCan);
	const isItFull = useSelector(selectFullTrashCan);
	const trashRef = useRef();
	const showTrashCan = useSelector(selectShowTrashCan);

	useEffect(() => {
		const {
			top,
			bottom,
			left,
			right,
			width,
			height,
		} = trashRef.current ? trashRef.current.getBoundingClientRect() : null;

		if (width && height) {
			dispatch(change({
				trashRegion: {
					top,
					bottom,
					left,
					right,
					width,
					height,
				},
			}));
		}
	}, [trashRef && trashRef.current]);

	return (
		<div
			className={classes.root}
			ref={trashRef}
			style={{
				visibility: showTrashCan ? 'visible' : 'hidden',
			}}
		>
			<img
				className={classes.img}
				alt=""
				src={!isItFull ? emptyTrashCan : fullTrashCan}
				style={{
					transition: !isItFull || active ? null : '0.5s',
					transform: active ? 'scale(1.4)' : null,
				}}
			/>
		</div>
	);
}

export default TrashCan;
