import React from 'react';
import { makeStyles } from '@material-ui/core';
import Account from './Account';
import AppVersion from './AppVersion';
import PrivacyAndSecurity from './PrivacyAndSecurity';
import SupportAndAbout from './SupportAndAbout';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		top: theme.spacing(12),
	},
}));

export default () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Account />
			<PrivacyAndSecurity />
			<SupportAndAbout />
			<AppVersion />
		</div>
	);
};
