import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRouterCalls } from 'app/slices/layout';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		height: 48,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.background.page,
	},
	backContainer: {
		position: 'absolute',
		left: 0,
	},
}));

export default ({ title, rootStyle }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const historyLength = useSelector(getRouterCalls);

	const handleClick = () => {
		if (historyLength > 1) {
			navigate(-1);
		} else {
			navigate('/my-account');
		}
	};

	return (
		<div className={classes.root} style={{ ...rootStyle }}>
			<Typography
				className={classes.title}
				variant="body1"
			>
				{title}
			</Typography>
			<div className={classes.backContainer}>
				<IconV3
					icon="ChevronLeft"
					size={32}
					onClick={handleClick}
				/>
			</div>
		</div>
	);
};
