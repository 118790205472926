module.exports = {
	C000: '#EEF1F7',
	C100: '#DEE2E7',
	C200: '#C3CCD5',
	C300: '#93A0AE',
	C400: '#748190',
	C500: '#6C757D',
	C600: '#545C63',
	C700: '#3E454C',
	C800: '#32383E',
	C900: '#1B242D',
};
