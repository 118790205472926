import React from 'react';
import { useSelector } from 'react-redux';
import { getProfile } from 'app/slices/profile';
import * as apiSlice from 'app/slices/authentication/signup/completion';
import * as formSlice from 'app/slices/authentication/signup/form';
import useMissingFields from 'app/hooks/useMissingFields';
import Form from './Form';

export default ({ theme }) => {
	const profile = useSelector(getProfile);
	const missingFields = useMissingFields();

	return (
		<Form
			theme={theme}
			apiSlice={apiSlice}
			formSlice={formSlice}
			profile={{
				dateOfBirth: missingFields.indexOf('dateOfBirth') !== -1 ? null : profile.dateOfBirth,
				email: missingFields.indexOf('email') !== -1 ? null : profile?.email?.email,
				username: missingFields.indexOf('username') !== -1 ? null : profile.username,
			}}
			disabledCheck={({
				email, emailErr,
				username, usernameErr,
				dateOfBirth,
			}) => !(
				((email && !emailErr) || profile.email)
				&& ((username && !usernameErr) || profile.username)
				&& (dateOfBirth || profile.dateOfBirth)
			)}
			disablePassword
			setUpLaterButton
		/>
	);
};
