import { createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';
import { requestInProgressExperiences, requestDeleteInProgressExperience } from './thunks';

const initialState = {
	loading: false,
	response: null,
	errors: null,
	expecting: [],
	failedExperiences: [],
	inProgressExperiences: [],
};

const name = 'userAcccountV2InProgressExperiences';

const inProgressExperiencesSlice = createSlice({
	name,
	initialState: { ...initialState },
	reducers: {
		markFailed: (state, action) => {
			const experiences = [...state.inProgressExperiences];
			const index = experiences.findIndex((e) => e.uid === action.payload);
			const exp = experiences.splice(index, 1);
			const failedExperiences = [...state.failedExperiences].concat(exp);
			state.failedExperiences = failedExperiences;
			state.inProgressExperiences = experiences;
		},
	},
	extraReducers: {
		[requestInProgressExperiences.fulfilled]: (state, action) => {
			state.inProgressExperiences = action.payload;
		},
		[requestDeleteInProgressExperience.pending]: (state, payload) => {
			const experiences = [...state.inProgressExperiences];
			const failedExperiences = [...state.failedExperiences];

			const index = experiences.findIndex((e) => e.uid === payload.meta.arg);
			const failedIndex = failedExperiences.findIndex((e) => e.uid === payload.meta.arg);

			if (index !== -1) {
				experiences.splice(index, 1);
			}

			if (failedIndex !== -1) {
				failedExperiences.splice(failedIndex, 1);
			}

			state.inProgressExperiences = experiences;
			state.failedExperiences = failedExperiences;
		},
	},
});

export const { markFailed } = inProgressExperiencesSlice.actions;
export default inProgressExperiencesSlice.reducer;

injectReducer(name, inProgressExperiencesSlice.reducer);
