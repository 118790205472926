import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
	endStream,
	getFacingMode,
	getStream,
	setFacingMode,
	requestUserMedia,
} from 'app/slices/user-media';
import {
	requestStopRecording,
} from 'app/slices/content-creation/create/capture';
import { getCreateNavigation } from 'app/slices/content-creation/create/device-library/state';
import PageLayout from '../../Components/Layout/PageLayout';
import SelectFromLibrary from './SelectFromLibrary';

const useStyles = makeStyles({
	video: {
		position: 'absolute',
		right: 0,
		left: 0,
		bottom: 0,
		top: 0,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},

});

export default ({
	params,
	doNotRender,
	hasPermissions,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const videoRef = useRef();
	const stream = useSelector(getStream);
	const facingMode = useSelector(getFacingMode);
	const navigation = useSelector(getCreateNavigation);
	const [mirror, setMirror] = useState(false);

	const cleanup = (() => () => {
		dispatch(setFacingMode());
		dispatch(requestStopRecording());
		dispatch(endStream());
	});

	useEffect(() => {
		if (facingMode === 'user') {
			setMirror(true);
		} else {
			setMirror(false);
		}
		if (hasPermissions) {
			dispatch(requestUserMedia());
		}
	}, [facingMode, hasPermissions]);

	useEffect(() => cleanup(), []);

	useEffect(() => {
		if (!stream) {
			videoRef.current.srcObject = null;
		} else if (videoRef?.current) {
			videoRef.current.srcObject = stream;

			const p = videoRef.current.play();
			if (p.catch) {
				/* eslint-disable-next-line */
				p.catch((_) => {
					// this means that the play call was interrupted
					// ignore it
				});
			}
		}
		return () => {
			if (videoRef?.current && videoRef?.current.srcObject) {
				videoRef.current.srcObject = null;
			}
		};
	}, [videoRef, stream, facingMode]);

	return (
		<div style={{
			display: doNotRender ? 'none' : 'block',
		}}
		>
			<PageLayout
				currentTab={0}
				captureMode="photo"
				videoRef={videoRef}
				params={params}
				bottomControl={(
					<SelectFromLibrary
						params={params}
						autoOpen={
							params.tab === 'library' || (params.tab === 'photo' && navigation.open)
						}
					/>
				)}
				hasPermissions={hasPermissions}
			>
				<video
					className={classes.video}
					autoPlay
					playsInline
					muted
					ref={videoRef}
					data-mirror={mirror}
					style={{ transform: mirror ? 'rotateY(180deg)' : null }}
				/>
			</PageLayout>
		</div>
	);
};
