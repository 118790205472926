import { createAsyncThunk } from '@reduxjs/toolkit';
import handleSliceError from 'utils/handle-slice-error';
import api from 'services/api';
import { deleteMyExperience } from '../experiences/slice';
import { updateExperienceCount } from '../accounts/slice';

export const requestECRExperiences = createAsyncThunk(
	'requestECRExperiences',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { trustedExperiences } = getState().trustedCreators;
			const {	currentCreator } = getState().destinationEcrExperiences;
			const { experiences, next } = getState().destinationAccountExperiences;

			if (!currentCreator) {
				return {
					experiences,
					next,
				};
			}

			if (!experiences?.length || !trustedExperiences?.length) {
				return null;
			}

			return {
				experiences: trustedExperiences,
			};
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestECRMore = createAsyncThunk(
	'requestECRMore',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { currentCreator } = getState().trustedCreators;
			const { ecr } = getState().destinationEcrExperiences;
			const { next } = ecr;

			if (!next || currentCreator) {
				return null;
			}

			const response = await api.get(next);

			return {
				experiences: response?.data?.experiences,
				next: response.data._links?.next?.href,
			};
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);

export const requestDeleteMyEcrExperience = createAsyncThunk(
	'requestDeleteMyEcrExperience',
	async (experience, { dispatch, rejectWithValue }) => {
		const { destinations, uid } = experience;
		try {
			dispatch(deleteMyExperience({ destinations, uid }));
			dispatch(updateExperienceCount({ uid: destinations[0].uid, countChange: -1 }));
			return { destinations, uid };
		} catch (error) {
			return rejectWithValue(handleSliceError(error));
		}
	},
);
