import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import useTheme from './useRdsTheme';

export default ({ children }) => {
	const theme = useTheme();
	return (
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	);
};
