import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import TopBar from 'rds/components/Layouts/FixedTopBar';
import useTheme from 'rds/theme/useRdsTheme';

const ACCEPTED_VARIANTS = ['primary', 'glass', 'contrast'];

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2140000003,
		overflowY: 'auto',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
	},
	contents: {
		marginTop: theme.spacing(16),
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		paddingBottom: theme.spacing(12),
	},
}));

export default ({
	children,
	headerBackground,
	top,
	open = true,
	gutters = true,
	variant = 'glass',
	divRef,
	disableClose,
	contentsStyle,
	...rest
}) => {
	const classes = useStyles();
	const theme = useTheme();

	const bg = theme.palette.background.page;
	const bgcolor = `${variant}.main`;
	const color = `${variant}.contrastText`;

	useEffect(() => {
		if (!open && !divRef) {
			return;
		}
		divRef?.current?.focus();
	}, [open, divRef]);

	if (ACCEPTED_VARIANTS.indexOf(variant) === -1) {
		throw new Error(`Supported modal variants ${ACCEPTED_VARIANTS.join(', ')}`);
	}

	const style = {
		background: bg,
	};
	const { bgblur } = theme.palette[variant];
	if (bgblur) {
		style.backdropFilter = `blur(${bgblur}px)`;
	}

	if (!open) {
		style.visibility = 'hidden';
	}

	const contentsS = {
		...contentsStyle,
	};

	if (!gutters) {
		contentsS.marginLeft = 0;
		contentsS.marginRight = 0;
	}

	return (
		<Box
			bgcolor={bgcolor}
			color={color}
			className={classes.root}
			style={style}
			data-ignoreslider="true"
			{...rest}
			onMouseDown={(e) => {
				if (disableClose) {
					e.preventDefault();
				}
			}}
		>
			<TopBar
				variant={variant}
				zIndex={13}
				{...top}
			/>
			<div
				className={classes.contents}
				style={contentsS}
				onClick={(e) => {
					e.preventDefault();
				}}
			>
				{children}
			</div>
		</Box>
	);
};
