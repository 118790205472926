import React from 'react';
import { useSelector } from 'react-redux';
import {
	getIsSearching,
	setIsSearching,
} from 'app/slices/search-explore/layout';
import Header from './Header';
import SearchResults from './SearchResults';

export default () => {
	const isSearching = useSelector(getIsSearching);

	return (
		<>
			<Header
				isSearching={isSearching}
				setIsSearching={setIsSearching}
			/>
			<SearchResults
				isSearching={isSearching}
			/>
		</>
	);
};
