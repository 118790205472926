import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';

const useStyles = makeStyles((theme) => ({
	contents: {
		marginTop: theme.spacing(16),
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		paddingBottom: theme.spacing(12),
	},
	username: {
		textAlign: 'center',
		width: '100%',
		marginBottom: theme.spacing(4),
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'left',
		alignItems: 'center',
	},
	text: {
		marginTop: theme.spacing(4),
		marginLeft: theme.spacing(4),
	},
}));

export default ({
	children,
	title,
	user,
	contentStyle,
	type,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.contents} style={contentStyle}>
			<Typography variant="subtitle2" className={classes.username}>
				{type === 'destination' ? '+' : '@' }
				{title || user?.username}
			</Typography>
			<div className={classes.container}>
				{children}
			</div>
		</div>
	);
};
