import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import useRdsTheme from 'rds/theme/useRdsTheme';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		maxWidth: 480,
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: theme.spacing(1),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		zIndex: 4,
	},
	content: {
		margin: '0 auto',
		marginBottom: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
	},
	main: {
		marginTop: theme.spacing(12),
		marginBottom: theme.spacing(6),
		zIndex: 3,
	},
	image: {
		textAlign: 'center',
		marginBottom: theme.spacing(3),
	},
	subtitle: {
		marginTop: theme.spacing(2),
	},
	title: {
		maxWidth: 320,
		marginTop: theme.spacing(2),
	},
	caption: {
		marginTop: theme.spacing(2),
		display: 'block',
	},
}));

export default ({
	image,
	subtitle,
	title,
	caption,
	children,
	theme,
}) => {
	const classes = useStyles();
	theme = theme || useRdsTheme();
	return (
		<div className={classes.root}>
			<div className={classes.main}>
				{
					image ? <div className={classes.image}>{image}</div> : null
				}

				{
					title
						? <Typography ds variant="h3" className={classes.title} theme={theme}>{title}</Typography>
						: null
				}

				{
					subtitle
						? <Typography ds variant="h3" className={classes.subtitle}>{subtitle}</Typography>
						: null
				}

				{
					caption
						? <Typography variant="body1" className={classes.caption} theme={theme}>{caption}</Typography>
						: null
				}
			</div>
			{children
				? (
					<div className={classes.content}>
						{children }
					</div>
				)

				: null }

		</div>
	);
};
