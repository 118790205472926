import { makeStyles } from '@material-ui/core';
import Hexagon from 'rds/components/Hexagon';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsMe, requestPatchIdentifiers } from 'app/slices/user-account-v2/accounts';
import CancelAlert from 'rds/components/CancelAlert';
import { useTheme } from '@material-ui/styles';

const DARK_ACTIVE_GRADIENT = 'linear-gradient(180deg, #20D2B2 0%, #FB7848 50%, #CDBB0A 100%)';
const TIME_OUT = 300;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		textAlign: 'left',
		paddingTop: theme.spacing(2),
	},
	spacer: {
		paddingRight: theme.spacing(6),
	},
	container: {
		marginRight: theme.spacing(2),
	},
	identifier: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'absolute',
		height: '92%',
		width: '88%',
		whiteSpace: 'normal',
		overflow: 'hidden',
		wordBreak: 'break-word',
		marginLeft: '5%',
		zIndex: 3,
	},
	honeyComb: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	odd: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(-4),
	},
	even: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(-4),
	},
}));

export default ({ identifier, onEdit }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isMe = useSelector(getIsMe);
	const [open, setOpen] = useState();
	const theme = useTheme();

	return (
		<div>
			<div className={classes.container}>
				<Hexagon
					drawLabel={() => (
						<div className={classes.identifier}>
							{identifier.title}
						</div>
					)}
					width={78}
					height={92}
					hexagonStyles={{
						background: theme.palette.background.page,
					}}
					selected
					longpress={isMe}
					onlongPressed={[
						{
							label: 'Edit',
							onClick: () => {
								onEdit(identifier);
							},
						},
						{
							label: 'Delete',
							onClick: () => {
								setTimeout(() => {
									setOpen(!open);
								}, TIME_OUT);
							},
							color: theme.colors.error.C300,
						},
					]}
					selectedBorder={DARK_ACTIVE_GRADIENT}
				/>
			</div>
			<CancelAlert
				title="Are you sure?"
				open={open}
				onClick={() => {
					dispatch(requestPatchIdentifiers({ newIdentifier: identifier, remove: true }));
					setOpen(!open);
				}}
				leftButton="Cancel"
				rightButton="Delete"
				onClose={() => { setOpen(!open); }}
			/>
		</div>
	);
};
