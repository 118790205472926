import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextField from 'rds/components/TextField';
import { control } from 'app/slices/destinations-search/form';
import { changeDestinationLayout } from 'app/slices/destinations-search/layout';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	textField: {
		fontSize: '18px !important',
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const fields = control.get(dispatch);
	const search = useSelector(fields.search.selector);
	const searchErr = useSelector(fields.search.err);

	return (
		<div className={classes.root}>
			<TextField
				value={search}
				err={searchErr}
				classes={[classes.textField]}
				onChange={fields.search.change}
				variant="glass"
				formItem
				onFocus={() => {
					dispatch(changeDestinationLayout({
						modal: true,
					}));
				}}
				icon={{
					icon: 'Search',
					size: 20,
				}}
				placeholder="Search destinations"
			/>
		</div>
	);
};
