import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, makeStyles, useTheme } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import { getTheirUsername } from 'app/slices/user-account-v2/accounts';
import { openBottomMenu } from 'app/slices/user-account-v2/layout/slice';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		bottom: 0,
		paddingBottom: theme.spacing(3),
		right: theme.spacing(4),
		left: theme.spacing(4),
		background: theme.colors.neutral.C900,
		zIndex: 100,
	},
	background: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 9,
		background: theme.colors.neutral.C900,
		opacity: 0.8,
	},
	title: {
		textAlign: 'center',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(4),
	},
	subtitle: {
		paddingLeft: theme.spacing(6),
		marginBottom: theme.spacing(4),
		whiteSpace: 'normal',
		textAlign: 'left',
	},
	button: {
		paddingLeft: theme.spacing(12),
		paddingRight: theme.spacing(12),
	},
	buttonContainer: {
		width: '100%',
		textAlign: 'center',
	},
}));

export default ({
	title,
	subtitle,
	onClick,
	label,
	buttonVariant = 'contained',
	buttonColor = 'secondary',
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const username = useSelector(getTheirUsername);
	const theme = useTheme();

	const style = {};
	if (buttonColor === 'error') {
		style.background = theme.colors.error.C700;
		style.color = theme.colors.neutral.C000;
	}

	return (
		<>
			<div className={classes.root}>
				<Typography className={classes.title} variant="subtitle1">
					{title}
					{' '}
					@
					{username}
					?
				</Typography>

				<Typography className={classes.subtitle} variant="body1">
					{subtitle}
				</Typography>
				<div className={classes.buttonContainer}>
					<Button
						className={classes.button}
						size="medium"
						variant={buttonVariant}
						onClick={() => {
							dispatch(openBottomMenu(null));
							onClick();
						}}
						style={style}
					>
						{label}
					</Button>

				</div>

			</div>
			<div className={classes.background} onClick={() => { dispatch(openBottomMenu(null)); }} />
		</>
	);
};
