/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getConstants } from 'app/slices/app/app';
import { selectShowBackgroundPicker, selectTextBackground, setTextBackground } from 'app/slices/content-creation/create/text-component';
import { makeStyles } from '@material-ui/styles';
import DrawerPicker from 'rds/components/DrawerPicker/DrawerPicker';
import { toggleControls } from 'app/slices/content-creation/experience';
import { updateRootNode } from 'app/slices/content-creation/editing-canvas';
import { Typography } from '@material-ui/core';

const FADE_TIME = 1000;
const INTERVAL_TIME = 200;
const SPACING = 16;
const useStyles = makeStyles((theme) => ({
	background: {
		position: 'fixed',
		bottom: 0,
		width: '100%',
		top: 0,
		left: 0,
		right: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		pointerEvents: 'all',
	},
	circle: {
		borderRadius: '50%',
		width: 28,
		height: 28,
		border: `2px solid ${theme.colors.neutral.C000}`,
		backgroundSize: 'cover',
	},
	square: {
		width: 48,
		height: 48,
		position: 'absolute',
		right: theme.spacing(2),
		bottom: theme.spacing(2),
		pointerEvents: 'all',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	drawerContainer: {
		position: 'absolute',
		bottom: SPACING,
		width: '100%',
		zIndex: 2140000002,
		pointerEvents: 'all',
	},
	hide: {
		visibility: 'hidden',
	},
	label: {
		position: 'fixed',
		width: '100%',
		textAlign: 'center',
		top: theme.spacing(22),
		transition: '0.5s opacity',
		textShadow: '0px 2px 4px rgba(50, 56, 62, 0.25)',
	},
	gradient: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		height: 72,
		background: 'linear-gradient(180deg, rgba(27, 36, 45, 0) -9.13%, rgba(27, 36, 45, 0.2) 26.08%, rgba(27, 36, 45, 0.4) 62.03%, rgba(27, 36, 45, 0.6) 96.51%, rgba(27, 36, 45, 0.8) 131.73%)',
		zIndex: 2140000001,
	},

}));

let fade;
let timer;

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const ref = useRef();
	const refLabel = useRef();
	const constants = useSelector(getConstants);
	const background = useSelector(selectTextBackground);
	const show = useSelector(selectShowBackgroundPicker);
	const getItems = () => {
		if (!constants) {
			return;
		}
		const myArray = [];
		constants.backgrounds.forEach((g) => {
			g.items.forEach((k) => {
				myArray.push(k);
			});
		});
		return myArray;
	};

	const fadeOut = (element) => {
		let op = 1;

		clearInterval(timer);
		timer = setInterval(() => {
			if (!element) {
				return;
			}

			if (op <= 0) {
				clearInterval(timer);
				element.innerHTML = '';
			}
			element.style.opacity = op;
			op -= 0.1;
		}, INTERVAL_TIME);
	};

	useEffect(() => {
		const backgroundsArray = getItems();

		ref.current.picker = new DrawerPicker(ref.current, backgroundsArray, 'color', 0, {
			selectedId: background.id,
		});

		ref.current.picker.on('selected', (item) => {
			const image = item.image.medium.jpg;
			const ids = item.uid;
			const { label } = item;
			dispatch(updateRootNode({ src: image }));
			dispatch(setTextBackground({ id: ids, image, label }));
		});

		return () => {
			if (ref.current) {
				ref.current.picker.destroy();
				ref.current.picker = null;
			}
		};
	}, [ref]);

	useEffect(() => {
		if (!background.label) {
			return;
		}
		if (background && background.label) {
			refLabel.current.innerHTML = `${background.label}`;
			refLabel.current.style.opacity = 1;
		}

		clearTimeout(fade);
		clearInterval(timer);
		fade = setTimeout(() => {
			fadeOut(refLabel.current);
		}, FADE_TIME);

		return () => {
			if (refLabel.current) {
				clearTimeout(fade);
				refLabel.current.innerHTML = '';
			}
		};
	}, [background]);

	return (
		<div>
			{open && (
				<div
					className={classes.background}
					onClick={() => {
						dispatch(toggleControls({ showControls: true }));
						setOpen(false);
					}}
				/>
			)}
			<Typography variant="h1" ref={refLabel} className={classes.label} />

			<div
				className={!open && show ? classes.square : classes.hide}
				onMouseDown={() => {
					dispatch(toggleControls({ showControls: false }));
					setOpen(!open);
				}}
			>
				<div
					className={classes.circle}
					style={{ backgroundImage: `url(${background.image})` }}
				/>
			</div>

			<div className={!open ? classes.hide : classes.drawerContainer}>
				<div ref={ref} />
			</div>
			<div className={!open ? classes.hide : classes.gradient} />
		</div>

	);
};
