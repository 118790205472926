import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Buffering from './ExperienceView/View/Buffering';

const useStyles = makeStyles((theme) => ({
	ecrLoading: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 48,
		zIndex: 15,
		overflow: 'hidden',
		MsOverflowStyle: 'none',
		scrollbarWidth: 'none',
		whiteSpace: 'nowrap',
		touchAction: 'none',
		opacity: 0,
		backgroundColor: theme.palette.background.page,
		transition: 'transform 0.15s ease-in-out',
	},
	show: {
		opacity: 1,
	},
}));

const ECRLoading = () => {
	const classes = useStyles();
	const [show, setShow] = useState(false);

	useEffect(() => {
		setTimeout(() => setShow(true), 400);
	});

	const ecrClass = show ? clsx(classes.ecrLoading, classes.show) : classes.ecrLoading;

	return (
		<div className={ecrClass}>
			<Buffering forceShow={show} />
		</div>
	);
};

export default ECRLoading;
