import { createSelector, createSlice } from '@reduxjs/toolkit';
import { injectReducer } from 'services/store';

export const ccNavigationRouterSlice = createSlice({
	name: 'ccNavigationRouter',
	initialState: {
		exiting: false,
		enteringLibrary: false,
		path: null,
		params: {},
	},
	reducers: {
		exitCC: (state) => {
			state.exiting = true;
		},
		stayCC: (state) => {
			state.exiting = false;
		},
		enterLibrary: (state) => {
			state.enteringLibrary = true;
		},
		navigateCC: (state, action) => {
			if (!action.payload) {
				state.path = null;
				state.params = {};
				return;
			}

			const currentParams = state.params || {};

			const { path, params } = action.payload;
			state.exiting = false;
			state.enteringLibrary = true;
			state.path = path !== null ? path : state.path;
			const np = {
				...currentParams,
				...(params || {}),
			};
			state.params = np;
		},
	},
});

const { name, reducer, actions } = ccNavigationRouterSlice;
export const {
	exitCC,
	enterLibrary,
	stayCC,
	navigateCC,
} = actions;

export const getSlice = (state) => state[name];
export const getRoute = createSelector(getSlice, (slice) => slice);

injectReducer(name, reducer);
