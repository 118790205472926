import EventEmitter from 'events';

export default class Recorder extends EventEmitter {
	constructor(stream, options) {
		super();
		this.chunks = [];
		options.mimeType = options.mimeType || 'video/webm';
		this.recorder = new window.MediaRecorder(stream, options);
		this.recorder.ondataavailable = (e) => {
			this.chunks.push(e.data);
			this.emit('timeelapsed');
		};
		this.recorder.onstop = () => {
			this.emit('end',
				new Blob(this.chunks, {
					type: 'video/webm',
				}));
		};
	}

	start(time) {
		this.recorder.start(time);
	}

	stop() {
		this.recorder.stop();
	}
}
