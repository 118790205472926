import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getExperience,
	getNotFound,
} from 'app/slices/experience-by-share';
import { useNavigate, useParams } from 'react-router-dom';
import { notify } from 'app/slices/notifications/notifications';

const ByCode = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const sharedExperience = useSelector(getExperience);
	const notFound = useSelector(getNotFound);
	const { code } = useParams();

	useEffect(() => {
		let to;
		if (sharedExperience?.user?.interactions?.callerIsBlocking || notFound) {
			to = '/explore';
			dispatch(notify({
				severity: 'info',
				description: 'Experience not available.',
			}));
		} else {
			to = `/${sharedExperience.user.username}/${code}?shared=true`;
		}

		navigate(to);
	}, [sharedExperience, notFound, dispatch, navigate]);

	return null;
};

export default ByCode;
