import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import {
	getDestinationInteractions,
	getLoading,
} from 'app/slices/destination-account-v2/accounts/selectors';
import { requestFollowDestinationAccount, requestUnfollowDestinationAccount } from 'app/slices/destination-account-v2/accounts/thunks';
import WaveSkeleton from 'components/WaveSkeleton';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexGrow: 1,
	},
	button: {
		width: '100%',
		height: 38,
		backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
		color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		'&:hover': {
			backgroundColor: theme.palette.type === 'light' ? theme.colors.neutral.C900 : theme.colors.neutral.C000,
			color: theme.palette.type === 'light' ? theme.colors.neutral.C000 : theme.colors.neutral.C900,
		},
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { uid } = useParams();
	const theme = useTheme();
	const interactions = useSelector(getDestinationInteractions(uid));
	const following = interactions?.callerIsFollowing;
	const loading = useSelector(getLoading);

	const style = {};

	if (following) {
		style.background = 'none';
		style.color = theme.colors.neutral.C000;
		style.border = `1px solid ${theme.colors.neutral.C000}`;
	}

	const label = following ? 'Following' : 'Follow';

	return (
		<div className={classes.root}>
			{loading ? (
				<WaveSkeleton
					style={{
						width: 130,
						height: 36,
						borderRadius: 4,
					}}
				/>
			)
				: (
					<Button
						variant="contained"
						disableRipple
						disableFocusRipple
						className={classes.button}
						style={style}
						fullWidth
						onClick={() => {
							if (!following) {
								dispatch(requestFollowDestinationAccount(uid));
							} else {
								dispatch(requestUnfollowDestinationAccount(uid));
							}
						}}
					>
						{label}
					</Button>
				)}
		</div>
	);
};
