const touchTracker = {
	isTouching: false,

	updateTouchState(isTouching) {
		this.isTouching = isTouching;
	},
};

window.addEventListener('touchstart', () => touchTracker.updateTouchState(true));
window.addEventListener('touchend', () => touchTracker.updateTouchState(false));

const getTouchState = () => touchTracker.isTouching;

export default getTouchState;
