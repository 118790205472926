import React from 'react';
import Header from '../Header';
import Content from '../Content';

export default () => (
	<>
		<Header />
		<Content />
	</>
);
