import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import ButtonSpinner from 'rds/components/Spinners/Button';
import Typography from 'rds/components/Typography';
import IconV3 from 'rds/components/IconV3';
import FormItem from 'rds/components/FormItem';
import useTheme from 'rds/theme/useRdsTheme';
import clsx from 'clsx';
import SuccessIcon from 'rds/icons/SuccessCustom/success.svg';
import poweredByGoogleDark from './assets/powered_by_google_on_non_white.png';
import poweredByGoogleLight from './assets/powered_by_google_on_white.png';

const FLASH_SUCCESS_TIMER = 2000;

const useStyles = makeStyles((theme) => {
	const classes = {
		root: {
			display: 'flex',
			flexDirection: 'column',
			...theme?.rhino?.textField?.main?.root,
		},
		subtleRoot: {
			...theme?.rhino?.textField?.main?.subtleRoot,
		},
		icon: {
			...theme?.rhino?.textField?.main?.icon,
		},
		right: {
			...theme?.rhino?.textField?.main?.right,
		},
		error: {
			...theme?.rhino?.textField?.main?.error,
		},
	};

	Object.keys(theme?.rhino?.textField.variants).forEach((v) => {
		classes[`${v}InputRoot`] = {
			...theme?.rhino?.textField?.main?.inputRoot,
			...theme?.rhino?.textField?.variants[v]?.inputRoot,
		};
		classes[`${v}ErrorInputRoot`] = {
			...theme?.rhino?.textField?.main?.errorInputRoot,
			...theme?.rhino?.textField?.variants[v]?.errorInputRoot,
		};
		classes[`${v}SubtleInputRoot`] = {
			...theme?.rhino?.textField?.main?.inputRoot,
			...theme?.rhino?.textField?.variants[v]?.inputRoot,
			...theme?.rhino?.textField?.main?.subtleInputRoot,
			...theme?.rhino?.textField?.variants[v]?.subtleInputRoot,
		};

		classes[`${v}Input`] = {
			...theme?.rhino?.textField?.main?.input,
			...theme?.rhino?.textField?.variants[v]?.input,
		};
		classes[`${v}SubtleInput`] = {
			...theme?.rhino?.textField?.main?.input,
			...theme?.rhino?.textField?.variants[v]?.input,
			...theme?.rhino?.textField?.main?.subtleInput,
			...theme?.rhino?.textField?.variants[v]?.subtle,
		};

		classes[`${v}Underline`] = {
			...theme?.rhino?.textField?.main?.underline,
			...theme?.rhino?.textField?.variants[v]?.underline,
		};
		classes[`${v}SubtleUnderline`] = {
			...theme?.rhino?.textField?.main?.underline,
			...theme?.rhino?.textField?.variants[v]?.underline,
			...theme?.rhino?.textField?.main?.subtleUnderline,
			...theme?.rhino?.textField?.variants[v]?.subtleUnderline,
		};
	});
	classes.disabled = {
		color: theme.colors.neutral.C400,
	};
	classes.spinner = {
		marginTop: theme.spacing(),
		marginRight: theme.spacing(2),
	};
	classes.poweredByGoogle = {
		width: 102,
		height: 14,
		marginTop: theme.spacing(),
	};
	classes.successIconContainer = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 32,
		height: 24,
	};
	classes.successIcon = {
		width: 16,
		height: 14,
	};
	classes.rootError = {
		border: `1px solid ${theme.colors.error.C700}`,
	};
	classes.focused = {
		border: `1px solid ${theme.colors.neutral.C200} !important`,
	};
	classes.searchField = {
		paddingRight: theme.spacing(8),
		fontSize: 12,
	};
	classes.searchFieldFocused = {
		marginRight: theme.spacing(38),
	};
	return classes;
});

export default ({
	asyncValidator,
	classes,
	disabled,
	err,
	flashSuccess,
	formItem,
	icon,
	inputProps,
	label,
	maxLength,
	minLength,
	onChange,
	pattern,
	right,
	subtle = false,
	requesting,
	tmpErr,
	value,
	searchField,
	searchFieldFocused,
	theme,
	name,
	poweredByGoogle,
	variant = 'default',
	forwardRef,
	showTmpErr,
	onBlurCb,
	onFocusCb,
	...rest
}) => {
	const localClasses = useStyles();
	theme = theme || useTheme();
	const mergedClasses = { ...localClasses, ...classes };
	const [flash, setFlash] = useState(false);
	const [loading, setLoading] = useState(false);
	const [stateTmpErr, setTmpErr] = useState(null);
	const [focused, setFocused] = useState(false);
	const timeout = useRef();
	const ref = forwardRef || useRef();

	useEffect(async () => {
		clearTimeout(timeout.current);

		if (disabled) {
			setFlash(false);
			return;
		}

		if (tmpErr) {
			setFlash(false);
			return;
		}

		if (flashSuccess) {
			if ((asyncValidator && value.length) || (value.length && !tmpErr && !flash)) {
				if (asyncValidator) {
					setFlash(false);
					setLoading(true);
					const proceed = await asyncValidator(value);
					setLoading(false);
					if (proceed) {
						setFlash(true);
						return;
					}
					return;
				}

				setFlash(true);
				timeout.current = setTimeout(() => {
					setFlash(false);
				}, FLASH_SUCCESS_TIMER);
			}
		}
	}, [flashSuccess, tmpErr, value]);

	const showTmpError = showTmpErr ? tmpErr : null;

	const showErr = err || stateTmpErr || showTmpError;

	const classKey = subtle ? `${variant}Subtle` : variant;
	const inputClasses = {
		root: showErr ? clsx(mergedClasses[`${classKey}InputRoot`], localClasses.rootError)
			: (focused && !searchField) || (focused && searchFieldFocused) ? clsx(mergedClasses[`${classKey}InputRoot`], localClasses.focused) : clsx(mergedClasses[`${classKey}InputRoot`]),
		input: searchField && searchFieldFocused ? clsx(mergedClasses[`${classKey}Input`], localClasses.searchField, localClasses.searchFieldFocused) : searchField ? clsx(mergedClasses[`${classKey}Input`], localClasses.searchField) : clsx(mergedClasses[`${classKey}Input`]),
		underline: mergedClasses[`${classKey}Underline`],
		disabled: localClasses.disabled,
	};

	let FieldIcon;
	if (icon) {
		const iconProps = {
			...{
				size: 24,
				containerSize: 24,
			},
			...icon,
		};
		FieldIcon = <IconV3 {...iconProps} />;
	}

	if (searchFieldFocused) {
		localClasses.searchField = { paddingRight: theme.spacing(32) };
	}

	const textField = (
		<div
			className={mergedClasses.root}
			style={{
				marginBottom: showErr ? theme.spacing(6) : 0,
			}}
		>
			{ FieldIcon && <div className={mergedClasses.icon}>{FieldIcon}</div> }
			<TextField
				disabled={disabled}
				ref={ref}
				inputProps={{
					maxLength,
					minLength,
				}}
				InputProps={{
					classes: inputClasses,
					...inputProps,
				}}
				fullWidth={!subtle}
				FormHelperTextProps={{
					classes: {
						root: clsx(mergedClasses.error, mergedClasses[`${classKey}ErrorInputRoot`]),
					},
				}}
				helperText={
					!disabled && showErr
						? showErr.text
						: null
				}
				onBlur={() => {
					setTmpErr(tmpErr);
					setFocused(false);
					onBlurCb && onBlurCb();
				}}
				onFocus={() => {
					setFocused(true);
					onFocusCb && onFocusCb();
				}}
				onChange={(e) => {
					e.preventDefault();
					if (pattern) {
						e.target.value = e.target.value.replace(pattern, '');
					}
					if (!tmpErr && stateTmpErr) {
						setTmpErr(null);
					}
					setFlash(false);
					onChange && onChange(e);
				}}
				value={value}
				{...rest}
			/>
			<div className={mergedClasses.right}>
				{
					!disabled && flash && flashSuccess && !err && !showErr ? (
						<div
							className={localClasses.successIconContainer}
						>
							<img className={localClasses.successIcon} src={SuccessIcon} alt="success" />
						</div>
					)
						: !disabled && (loading || requesting) ? (
							<div className={localClasses.spinner}>
								<ButtonSpinner size={12} />
							</div>
						)
							: null
				}
				{poweredByGoogle && searchFieldFocused && (
					<img
						alt="powered by google"
						src={poweredByGoogle === 'dark' ? poweredByGoogleDark : poweredByGoogleLight}
						className={localClasses.poweredByGoogle}
						onMouseDown={(e) => { e.preventDefault(); }}
					/>
				)}
				{right}
			</div>
		</div>
	);

	if (formItem) {
		return (
			<FormItem>
				{
					label
						? <Typography>{label}</Typography>
						: null
				}
				{textField}
			</FormItem>
		);
	}

	return textField;
};
