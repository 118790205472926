import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change } from 'app/slices/experience-view';
import { Button, makeStyles } from '@material-ui/core';
import {
	requestUpdateExperience,
	getSelectedActivities,
	getDestination,
	set,
	getHasBeenEdited,
	requestUpdateExperienceDestination,
} from 'app/slices/experience-view/edit';

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 10,
		position: 'fixed',
		top: 0,
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		background: theme.palette.background.page,
	},
	button: {
		height: 48,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
}));

export default ({ uid, clips }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const destination = useSelector(getDestination);
	const newActivities = useSelector(getSelectedActivities);
	const hasBeenEdited = useSelector(getHasBeenEdited);

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				onClick={() => {
					dispatch(change({ route: null }));
					dispatch(set({ hasBeenEdited: false }));
				}}
				disableRipple
				disableFocusRipple
			>
				Cancel
			</Button>
			<Button
				className={classes.button}
				disabled={!newActivities.length || !hasBeenEdited}
				onClick={() => {
					if (!hasBeenEdited) {
						return;
					}
					if (!newActivities.length) {
						dispatch(set({ showActivitiesRequiredError: true }));
						return;
					}
					if (destination) {
						dispatch(requestUpdateExperienceDestination(destination));
					} else {
						dispatch(requestUpdateExperience({ uid, clips }));
					}

					dispatch(set({ hasBeenEdited: false }));
				}}
				disableRipple
				disableFocusRipple
			>
				Save
			</Button>
		</div>
	);
};
