import store from 'services/store';
import { reset as resetCapture } from 'app/slices/content-creation/create/capture';
import { reset as resetCanvas } from 'app/slices/content-creation/editing-canvas';
import { reset as resetTextComponentSlice } from 'app/slices/content-creation/create/text-component';
import { reset as resetNotifications } from 'app/slices/notifications/notifications';
import { reset as resetExperience, resetAll as resetExperienceDetails } from 'app/slices/content-creation/experience';
import { setCreateNavigation } from 'app/slices/content-creation/create/device-library/state';

export default async () => {
	await store.dispatch(resetCapture());
	await store.dispatch(resetCanvas());
	await store.dispatch(resetTextComponentSlice());
	await store.dispatch(resetNotifications());
	await store.dispatch(setCreateNavigation({
		slide: true,
		open: true,
	}));
	await store.dispatch(resetExperience());
	await store.dispatch(resetExperienceDetails());
};
