import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Typography from 'rds/components/Typography';
import toMmss from 'utils/to-mmss';

const useStyles = makeStyles({
	timer: {
		position: 'absolute',
		top: 60,
		right: 18,
		zIndex: 3,
		touchAction: 'none',
		textShadow: '0px 2px 1px rgba(0, 0, 0, 0.4)',
	},
});

export default ({
	remaining,
}) => {
	const classes = useStyles();

	if (remaining < 0) {
		return null;
	}

	return (
		<div className={clsx(classes.timer, 'ecr-dismiss-quickfade')}>
			<Typography variant="body1">
				{remaining ? toMmss(remaining) : ''}
			</Typography>
		</div>
	);
};
