import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextField from 'rds/components/TextField';
import { control, resetForm } from 'app/slices/content-creation/editor/search-stickers-form';
import { change } from 'app/slices/content-creation/editor/layout';
import IconV3 from 'rds/components/IconV3';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		right: theme.spacing(4),
		left: theme.spacing(4),
		top: theme.spacing(14),
	},
	background: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		height: 100,
		background: theme.colors.neutral.C900,
	},
}));

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const fields = control.get(dispatch);
	const search = useSelector(fields.search.selector);
	const searchErr = useSelector(fields.search.err);
	const ref = useRef();
	const [focused, setFocused] = useState(false);

	return (
		<div
			className={classes.root}
			onClick={() => {
				if (ref && ref.current) {
					ref.current.focus();
				}
			}}
		>
			<div className={classes.background} />
			<form ref={ref} className={classes.form}>
				<TextField
					classes={[classes.textField]}
					value={search}
					err={searchErr}
					formItem
					variant="glass"
					autoFocus
					name="searchStickers"
					onChange={(e) => {
						fields.search.change(e);
						dispatch(change({ focused: !!e.target.value }));
					}}
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							e.preventDefault();
							e.target.blur();
						}
					}}
					icon={{
						icon: 'Search',
						size: 20,
					}}
					placeholder="Search"
					onFocusCb={() => {
						setFocused(true);
					}}
					onBlurCb={() => {
						setFocused(false);
					}}
					right={(
						focused ? (
							<div
								onMouseDown={(e) => {
									e.preventDefault();
									dispatch(resetForm());
								}}
							>
								<IconV3
									icon="XCircleFilledLayers"
									size={20}
									containerSize={22}
									gredient
								/>
							</div>
						) : null
					)}
				/>
			</form>
		</div>

	);
};
