import React from 'react';
import Modal from 'rds/components/Modal';
import FollowersTab from './FollowersTab';

export default ({
	onClose,
	username,
}) => (
	<Modal
		open="open"
		top={{
			close: () => {
				onClose();
			},
			title: { text: username },
			closeIconPosition: 'left',
		}}
	>
		<FollowersTab />
	</Modal>
);
